import React, { useCallback, useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FilterDefault = ({ column: { filterValue, setFilter, defaultCanFilter, canFilter } }): JSX.Element => {
    const [currentValue, setCurrentValue] = useState<string>(filterValue)
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)

    const handleChange = useCallback(
        (e) => {
            const inputValue = e.target.value
            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }

            setCurrentValue(inputValue)
            const timeoutId = setTimeout(() => {
                setFilter(inputValue || undefined)
            }, 400)
            setChangeTimeoutId(timeoutId)
        },
        [changeTimeoutId, setChangeTimeoutId, setFilter]
    )

    useEffect(() => {
        setCurrentValue(filterValue)
    }, [setCurrentValue, filterValue])

    if (!defaultCanFilter || !canFilter) {
        return <></>
    }

    return (
        <div className="filter">
            <div className="form-group">
                <FormControl type="text" value={currentValue || ''} onChange={handleChange} />
                <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} className="app-icon" />
            </div>
        </div>
    )
}

export default FilterDefault
