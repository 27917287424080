/*
 *
 * Products
 *
 */

import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

interface IProps {
    itemsPerPage: number
}

function Placeholder({ itemsPerPage }: IProps): JSX.Element {
    const components: Array<JSX.Element> = []
    for (let i = 1; i <= itemsPerPage; i++) {
        components.push(
            <div className={'store-quantity placeholder'} key={`placeholder_col_item_${i}`}>
                <Row>
                    <Col md={'auto'}>
                        <TextBlock rows={1} color={'gray'} className={'product-name-placeholder'} />
                        <TextBlock rows={1} color={'gray'} className={'product-reference-placeholder'} />
                    </Col>
                    <Col md={16}>
                        <RectShape color={'gray'} className={'product-quantity-placeholder'} />
                    </Col>
                </Row>
            </div>
        )
    }
    return <div className={'store-quantity-list'}>{components}</div>
}

Placeholder.defaultProps = {} as Partial<IProps>

export default memo(Placeholder)
