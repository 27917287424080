/*
 *
 * Classification actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import {
    IApiCategoryResponse,
    IAttributeDefinitionCollection,
    IFamilyTreeCollection,
    IMenuItemCollection,
} from '../../services/api/service/classification/types'
import { IAppErrorTypes } from '../app/types'
import {
    FamilyTreeKeys,
    IClassificationCategoryProcessPayload,
    IFamilyTreeFailurePayload,
    IFamilyTreeProcessPayload,
    IFamilyTreeRefreshPayload,
    IFamilyTreeResetPayload,
    IFamilyTreeSuccessPayload,
    IFavoriteDepartmentAssociateProcessPayload,
    IFavoriteDepartmentDeleteProcessPayload,
    IFavoriteDepartmentEditProcessPayload,
    IFavoriteDepartmentEditSuccessPayload,
    IFavoriteDepartmentListFailurePayload,
    IFavoriteDepartmentListSuccessPayload,
} from './types'
import {
    IFavoriteDepartmentAssociateData,
    IFavoriteDepartmentCollection,
    IFavoriteDepartmentEditData,
} from '../../services/api/service/favorite/types'
import { IProductListUserSelectionCollection } from '../products/types'

export const classificationStoreMenuAction = (menu: IMenuItemCollection) =>
    action<ActionTypes.STORE_MENU_ACTION, IMenuItemCollection>(ActionTypes.STORE_MENU_ACTION, menu)

export const classificationStoreAttributeDefinitionAction = (attributes: IAttributeDefinitionCollection) =>
    action<ActionTypes.STORE_ATTRIBUTE_ACTION, IAttributeDefinitionCollection>(
        ActionTypes.STORE_ATTRIBUTE_ACTION,
        attributes
    )

export const classificationResetAction = () =>
    action<ActionTypes.RESET_CLASSIFICATION_ACTION>(ActionTypes.RESET_CLASSIFICATION_ACTION)

// DETAIL
export const classificationCategoryProcessAction = (slug: string) =>
    action<ActionTypes.CATEGORY_PROCESS_ACTION, IClassificationCategoryProcessPayload>(
        ActionTypes.CATEGORY_PROCESS_ACTION,
        { slug }
    )
export const classificationCategorySuccessAction = (response: IApiCategoryResponse) =>
    action<ActionTypes.CATEGORY_SUCCESS_ACTION, { response: IApiCategoryResponse }>(
        ActionTypes.CATEGORY_SUCCESS_ACTION,
        {
            response,
        }
    )
export const classificationCategorySuccessFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.CATEGORY_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.CATEGORY_FAILURE_ACTION, {
        error,
    })

export const classificationCategoryResetAction = () =>
    action<ActionTypes.CATEGORY_RESET_ACTION>(ActionTypes.CATEGORY_RESET_ACTION)

export const classificationFamilyTreeProcessAction = (mode: FamilyTreeKeys) =>
    action<ActionTypes.FAMILY_TREE_PROCESS_ACTION, IFamilyTreeProcessPayload>(ActionTypes.FAMILY_TREE_PROCESS_ACTION, {
        mode,
    })
export const classificationFamilyTreeSuccessAction = (mode: FamilyTreeKeys, tree: IFamilyTreeCollection) =>
    action<ActionTypes.FAMILY_TREE_SUCCESS_ACTION, IFamilyTreeSuccessPayload>(ActionTypes.FAMILY_TREE_SUCCESS_ACTION, {
        mode,
        tree,
    })
export const classificationFamilyTreeFailureAction = (mode: FamilyTreeKeys, error: IAppErrorTypes) =>
    action<ActionTypes.FAMILY_TREE_FAILURE_ACTION, IFamilyTreeFailurePayload>(ActionTypes.FAMILY_TREE_FAILURE_ACTION, {
        mode,
        error,
    })

export const classificationFamilyTreeResetAction = (mode?: FamilyTreeKeys) =>
    action<ActionTypes.FAMILY_TREE_RESET_ACTION, IFamilyTreeResetPayload>(ActionTypes.FAMILY_TREE_RESET_ACTION, {
        mode,
    })

export const classificationFamilyTreeRefreshAction = (mode: FamilyTreeKeys) =>
    action<ActionTypes.FAMILY_TREE_REFRESH_ACTION, IFamilyTreeRefreshPayload>(ActionTypes.FAMILY_TREE_REFRESH_ACTION, {
        mode,
    })

export const classificationMenuProcessAction = () =>
    action<ActionTypes.MENU_PROCESS_ACTION>(ActionTypes.MENU_PROCESS_ACTION)

export const classificationFavoriteDepartmentListProcessAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_LIST_PROCESS_ACTION>(ActionTypes.FAVORITE_DEPARTMENT_LIST_PROCESS_ACTION)
export const classificationFavoriteDepartmentListSuccessAction = (items: IFavoriteDepartmentCollection) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_LIST_SUCCESS_ACTION, IFavoriteDepartmentListSuccessPayload>(
        ActionTypes.FAVORITE_DEPARTMENT_LIST_SUCCESS_ACTION,
        { items }
    )

export const classificationFavoriteDepartmentListResetAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_LIST_RESET_ACTION>(ActionTypes.FAVORITE_DEPARTMENT_LIST_RESET_ACTION)

export const classificationFavoriteDepartmentListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_LIST_FAILURE_ACTION, IFavoriteDepartmentListFailurePayload>(
        ActionTypes.FAVORITE_DEPARTMENT_LIST_FAILURE_ACTION,
        {
            error,
        }
    )

export const classificationFavoriteDepartmentEditProcessAction = (
    data: IFavoriteDepartmentEditData,
    identifier?: string,
    products?: IProductListUserSelectionCollection
) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_EDIT_PROCESS_ACTION, IFavoriteDepartmentEditProcessPayload>(
        ActionTypes.FAVORITE_DEPARTMENT_EDIT_PROCESS_ACTION,
        { data, identifier, products }
    )

export const classificationFavoriteDepartmentEditSuccessAction = (item: IFavoriteDepartmentCollection[0]) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_EDIT_SUCCESS_ACTION, IFavoriteDepartmentEditSuccessPayload>(
        ActionTypes.FAVORITE_DEPARTMENT_EDIT_SUCCESS_ACTION,
        { item }
    )

export const classificationFavoriteDepartmentEditResetAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_EDIT_RESET_ACTION>(ActionTypes.FAVORITE_DEPARTMENT_EDIT_RESET_ACTION)

export const classificationFavoriteDepartmentEditFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_EDIT_FAILURE_ACTION, IFavoriteDepartmentListFailurePayload>(
        ActionTypes.FAVORITE_DEPARTMENT_EDIT_FAILURE_ACTION,
        {
            error,
        }
    )

export const classificationFavoriteDepartmentAssociateProcessAction = (data: IFavoriteDepartmentAssociateData) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_PROCESS_ACTION, IFavoriteDepartmentAssociateProcessPayload>(
        ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_PROCESS_ACTION,
        { data }
    )

export const classificationFavoriteDepartmentAssociateSuccessAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_SUCCESS_ACTION>(
        ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_SUCCESS_ACTION
    )

export const classificationFavoriteDepartmentAssociateResetAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_RESET_ACTION>(
        ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_RESET_ACTION
    )

export const classificationFavoriteDepartmentAssociateFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_FAILURE_ACTION, IFavoriteDepartmentListFailurePayload>(
        ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_FAILURE_ACTION,
        {
            error,
        }
    )

export const classificationFavoriteDepartmentDeleteProcessAction = (identifier: string) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_DELETE_PROCESS_ACTION, IFavoriteDepartmentDeleteProcessPayload>(
        ActionTypes.FAVORITE_DEPARTMENT_DELETE_PROCESS_ACTION,
        { identifier }
    )

export const classificationFavoriteDepartmentDeleteSuccessAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_DELETE_SUCCESS_ACTION>(ActionTypes.FAVORITE_DEPARTMENT_DELETE_SUCCESS_ACTION)

export const classificationFavoriteDepartmentDeleteResetAction = () =>
    action<ActionTypes.FAVORITE_DEPARTMENT_DELETE_RESET_ACTION>(ActionTypes.FAVORITE_DEPARTMENT_DELETE_RESET_ACTION)

export const classificationFavoriteDepartmentDeleteFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAVORITE_DEPARTMENT_DELETE_FAILURE_ACTION, IFavoriteDepartmentListFailurePayload>(
        ActionTypes.FAVORITE_DEPARTMENT_DELETE_FAILURE_ACTION,
        {
            error,
        }
    )
