/*
 *
 * Localization constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Localization/RESET',
    COUNTRY_LIST_PROCESS_ACTION = 'app/Localization/Country/REQUEST_PROCESS',
    COUNTRY_LIST_SUCCESS_ACTION = 'app/Localization/Country/REQUEST_SUCCESS',
    COUNTRY_LIST_FAILURE_ACTION = 'app/Localization/Country/REQUEST_FAILURE',
    COUNTRY_LIST_RESET_ACTION = 'app/Localization/Country/RESET',
}

export default ActionTypes
