/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { OptionalExceptFor } from '../../../types/common'
import { ProductClickCallback } from '../../../types/productCallback'
import FlatIcon from '../../Icon/FlatIcon'
import { default as ProductEan } from './Ean'
import { AlreadyOrdered } from './index'
import { default as ProductPacking } from './Packing'
import { default as ProductReference } from './Reference'
import ShippingLocation from '../../../containers/ShippingLocation/ShippingLocation'

interface IProps {
    product: OptionalExceptFor<IProductCatalog, 'ean13' | 'reference' | 'name' | '@id' | 'id'>
    errors?: Array<string>
    errorIcon?: string
    warnings?: Array<string>
    className?: string
    showReference: boolean
    showEan: boolean
    showPacking: boolean
    showPackingLabel: boolean
    showReferenceLabel: boolean
    showEanLabel: boolean
    showAlreadyOrdered: boolean
    showShippingLocation: boolean
    showShippingLocationLabel: boolean
    onProductClick?: ProductClickCallback
    productNameAs: React.ElementType
    productNameClassName?: string
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
function Heading<As extends React.ElementType = 'h2'>({
    product,
    errors,
    errorIcon,
    className,
    showReference,
    showReferenceLabel,
    showEan,
    showEanLabel,
    onProductClick,
    productNameClassName,
    productNameAs,
    showPackingLabel,
    showPacking,
    showAlreadyOrdered,
    showShippingLocation,
    showShippingLocationLabel,
}: IProps): JSX.Element {
    const handleOnClick = () => {
        if (onProductClick) {
            onProductClick(product)
        }
    }

    // formattage des erreurs si besoin
    let Errors = <></>
    const hasErrors = errors && errors.length > 0
    if (hasErrors) {
        Errors = (
            <div className={'cart-item-errors'}>
                {errors!.map((error) => (
                    <p key={`cart_item_${product['@id']}_error_${error}`} className={'invalid-feedback'}>
                        {error}
                    </p>
                ))}
            </div>
        )
    }

    const showBaseInfo = useMemo(() => {
        return showReference || showEan || showShippingLocationLabel || showPacking
    }, [showReference, showEan, showShippingLocationLabel, showPacking])

    return (
        <div
            className={classNames(
                'product-heading',
                { 'has-errors': hasErrors, 'already-ordered': showAlreadyOrdered && product.already_ordered },
                className
            )}
            onClick={handleOnClick}
        >
            <div className={'product-heading-inner'}>
                {hasErrors && errorIcon && <FlatIcon icon={errorIcon} />}
                {showAlreadyOrdered && product.already_ordered && (
                    <AlreadyOrdered productId={product['@id']} date={product.last_order_date} />
                )}
                {React.createElement(
                    productNameAs || 'h2',
                    {
                        className: classNames('product-name', productNameClassName),
                    },
                    product.name
                )}
            </div>
            {Errors}
            {showBaseInfo && (
                <div className="product-detail-base-info">
                    {showReference && <ProductReference reference={product.reference} showLabel={showReferenceLabel} />}
                    {showEan && <ProductEan ean13={product.ean13} showLabel={showEanLabel} />}
                    {showShippingLocation && product.shipping_location && (
                        <ShippingLocation
                            shippingLocationId={product.shipping_location}
                            showLabel={showShippingLocationLabel}
                        />
                    )}
                    {showPacking && product.packing && (
                        <ProductPacking
                            className={'product-field'}
                            packing={product.packing}
                            unitOfSale={product.unit_of_sale}
                            showLabel={showPackingLabel}
                            abbreviateLabels={false}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

Heading.defaultProps = {
    showReference: false,
    showEan: false,
    showReferenceLabel: false,
    showEanLabel: false,
    showPackingLabel: false,
    showPacking: false,
    showAlreadyOrdered: false,
    showShippingLocation: false,
    showShippingLocationLabel: false,
    productNameAs: 'h2',
    errorIcon: 'danger',
} as Partial<IProps>

const areEqual = (prevProps: IProps, nextProps: IProps) => {
    return (
        prevProps.product['@id'] === nextProps.product['@id'] &&
        prevProps.product.already_ordered === nextProps.product.already_ordered &&
        prevProps.showEanLabel === nextProps.showEanLabel &&
        prevProps.showAlreadyOrdered === nextProps.showAlreadyOrdered &&
        prevProps.showEan === nextProps.showEan &&
        prevProps.showReference === nextProps.showReference &&
        prevProps.showPacking === nextProps.showPacking &&
        prevProps.showReferenceLabel === nextProps.showReferenceLabel &&
        prevProps.showPackingLabel === nextProps.showPackingLabel &&
        prevProps.showShippingLocationLabel === nextProps.showShippingLocationLabel &&
        prevProps.showShippingLocation === nextProps.showShippingLocation &&
        prevProps.productNameAs === nextProps.productNameAs &&
        prevProps.onProductClick === nextProps.onProductClick &&
        prevProps.warnings === nextProps.warnings &&
        prevProps.errors === nextProps.errors
    )
}

export default memo(Heading, areEqual)
