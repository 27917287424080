/**
 *
 * OrderState
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { OrderStates } from '../../services/api/service/orders/types'
import FlatIcon from '../Icon/FlatIcon'
import OrderState from './OrderState'
import { getPublicOrderStates } from '../../utils/order'

interface IProps {
    state: OrderStates
}

function OrderStepper({ state }: IProps): JSX.Element {
    const orderStates = useMemo(() => {
        return getPublicOrderStates()
    }, [])
    const activeIndex = orderStates.findIndex((itm) => itm === state)

    return (
        <div className={'order-step'}>
            <div className={'order-step-inner'}>
                <ul className={'list-unstyled order-step-list'}>
                    {orderStates.map((state, index) => (
                        <li
                            key={`order_step_item_${state}`}
                            className={classNames('order-step-item', {
                                done: index <= activeIndex,
                                next: index === activeIndex + 1,
                                first: index === 0,
                                last: index === orderStates.length - 1,
                            })}
                        >
                            <div className={'order-step-item-inner'}>
                                <div className={'order-step-item-content'}>
                                    <span className={'order-step-item-icon'}>
                                        <FlatIcon icon={'check'} />
                                    </span>
                                    <span className={'order-step-item-label'}>
                                        <OrderState state={state} />
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

OrderStepper.defaultProps = {} as Partial<IProps>

export default memo(OrderStepper)
