import React from 'react'
import { matchPath } from 'react-router'
import { IRoute } from './types'
import routes from './routes'
import Config from '../config'

const findRouteName = (pathname: string): string | null => {
    for (const routeName in routes) {
        const route = routes[routeName]
        const paths = typeof route.path === 'string' ? [route.path] : Object.values(route.path!)
        for (const idx in paths) {
            const path = paths[idx]
            const match = matchPath(pathname, {
                path: path,
                exact: true,
            })
            if (match) {
                return routeName
            }
        }
    }

    return null
}

const findRoute = (pathname: string): IRoute | null => {
    for (const routeName in routes) {
        const route = routes[routeName]
        const paths = typeof route.path === 'string' ? [route.path] : Object.values(route.path!)
        for (const idx in paths) {
            const path = paths[idx]
            const match = matchPath(pathname, {
                path: path,
                exact: true,
            })
            if (match) {
                return route
            }
        }
    }

    return null
}

const getRoute = (routeName: string): IRoute => {
    return routes[routeName]
}

const getRouteMatch = (name: string): string => {
    if (routes[name]) {
        const route: IRoute = routes[name]
        return route.match
    }
    return '#'
}

const getPath = (name: string, locale: string): string => {
    if (routes[name]) {
        const route: IRoute = routes[name]
        if (typeof route.path === 'string') {
            return route.path
        }

        if (typeof route.path === 'object') {
            if (route.path[locale]) {
                return route.path[locale]
            } else if (route.path[Config.I18N.FALLBACK_LOCALE]) {
                return route.path[Config.I18N.FALLBACK_LOCALE]
            }
        }
    }

    return '#'
}

const getBreadcrumbRoutes = (): Array<{ path: string; breadcrumb: string | React.ElementType | undefined }> => {
    const broutes: Array<{ path: string; breadcrumb: string | React.ElementType | undefined }> = []
    for (const routeName in routes) {
        const route = routes[routeName]
        if (!route.path) {
            continue
        }
        if (typeof route.path === 'string') {
            broutes.push({
                path: route.path,
                breadcrumb: route.breadcrumb || undefined,
            })
        } else if (typeof route.path === 'object') {
            for (const locale in route.path) {
                broutes.push({
                    path: route.path[locale] || route.path[Config.I18N.FALLBACK_LOCALE] || '',
                    breadcrumb: route.breadcrumb || undefined,
                })
            }
        }
    }
    return broutes
}

export { getPath, findRoute, findRouteName, getRoute, getBreadcrumbRoutes, getRouteMatch }
