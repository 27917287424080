/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { IShippingLocation } from '../../../services/api/service/classification/types'
import { ProductUnitType } from '../../../services/api/service/products/types'
import ShippingLocation from '../../Classification/ShippingLocation'
import FlatIcon from '../../Icon/FlatIcon'
import PackingDisplayValue from './PackingDisplayValue'

interface IProps {
    packing?: number | null
    unitOfSale?: ProductUnitType
    boxWeight?: number | null
    shippingLocation?: IShippingLocation | null
    className?: string
}

function LogisticInformation({ packing, boxWeight, shippingLocation, className, unitOfSale }: IProps): JSX.Element {
    const { formatMessage } = useIntl()

    const packingLabel = useMemo(() => {
        if (unitOfSale === ProductUnitType.Meter) {
            return formatMessage({ id: 'default.metering' })
        }
        return formatMessage({ id: 'product.packing' })
    }, [unitOfSale, formatMessage])

    return (
        <div className={classNames('product-attributes-section', className)}>
            <div className={'heading'}>
                <h3 className={'h2 hv'}>
                    <FlatIcon icon={'box'} />
                    <FormattedMessage id={'product.logistic_information'} />
                </h3>
            </div>
            <div className={'content'}>
                <Table striped hover size={'md'}>
                    <tbody>
                        {packing && (
                            <tr className={'packing'}>
                                <th>{packingLabel}</th>
                                <td>
                                    <PackingDisplayValue
                                        packing={packing}
                                        unitOfSale={unitOfSale}
                                        abbreviate={false}
                                        suffix={true}
                                    />
                                </td>
                            </tr>
                        )}
                        {unitOfSale === ProductUnitType.Unit && boxWeight && (
                            <tr className={'box_weight'}>
                                <th>
                                    <FormattedMessage id={'product.box_weight'} />
                                </th>
                                <td>
                                    <FormattedMessage
                                        id={'product.attribute.weight_value'}
                                        values={{ value: boxWeight * 1000, weight: boxWeight }}
                                    />
                                </td>
                            </tr>
                        )}
                        <tr className={'logistic_location'}>
                            <th>
                                <FormattedMessage id={'product.logistic_location'} />
                            </th>
                            <td>
                                {shippingLocation && <ShippingLocation shippingLocation={shippingLocation} />}
                                {!shippingLocation && '-'}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

LogisticInformation.defaultProps = {
    unitOfSale: ProductUnitType.Unit,
} as Partial<IProps>

export default memo(LogisticInformation)
