/*
 *
 * Products
 *
 */

import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { OverlayInjectedProps } from 'react-bootstrap/Overlay'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ButtonApp from '../../../../components/Buttons/Button'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import { IAttributeDefinitionCollection } from '../../../../services/api/service/classification/types'
import { IApplicationRootState } from '../../../../store'
import { cartBulkCartQuantityResetAction } from '../../../../store/carts/actions'
import { makeSelectCartBulkCartQuantityFetching } from '../../../../store/carts/selectors'
import { makeSelectClassificationAttributeDefinition } from '../../../../store/classification/selectors'
import { productListMassAddToCartEligibleFilters } from '../../../../store/classification/utils'
import Modal from '../Modal/Loadable'

interface IProps {
    enabled: boolean
    show: boolean
}

const stateSelector = createStructuredSelector<any, any>({
    fetching: makeSelectCartBulkCartQuantityFetching(),
    definition: makeSelectClassificationAttributeDefinition(),
})

function Button({ enabled, show }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { fetching, definition } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            definition: IAttributeDefinitionCollection
        }
    >(stateSelector)

    const [modalShow, setModalShow] = useState<boolean>(false)
    const eligibleFilters = useMemo(() => {
        return productListMassAddToCartEligibleFilters(definition)
    }, [definition])

    const handleOnModalExited = useCallback(() => {
        dispatch(cartBulkCartQuantityResetAction())
        setModalShow(false)
    }, [setModalShow, dispatch])

    const handleButtonClick = useCallback(() => {
        setModalShow(true)
    }, [setModalShow])

    let PopoverButton: React.ReactElement<OverlayInjectedProps> | undefined = undefined
    if (!enabled) {
        PopoverButton = (
            <Popover id={`popover-mass-add-to-cart`}>
                <Popover.Content>
                    <p className={'mass-add-to-cart-message-above'}>
                        <FormattedMessage id={'products.mass_add_to_cart_disabled_info_above'} />
                    </p>
                    <p className={'mass-add-to-cart-message-under'}>
                        <FormattedMessage id={'products.mass_add_to_cart_disabled_info_under'} />
                    </p>
                    <ul className="list-unstyled">
                        {eligibleFilters.map((filter) => (
                            <li key={`mass_add_to_cart_filter_${filter.code}`}>
                                <FormattedMessage id={'products.filter_name'} values={{ name: filter.name }} />
                            </li>
                        ))}
                    </ul>
                </Popover.Content>
            </Popover>
        )
    }

    const AddToCartButton = (
        <ButtonApp
            className={'btn-sm btn-mass-add-to-cart'}
            variant={enabled ? 'primary' : 'secondary'}
            disabled={!enabled || fetching === true}
            onClick={handleButtonClick}
            style={{ pointerEvents: enabled ? 'initial' : 'none' }}
        >
            <FlatIcon icon={'shopping-cart'} className={'app-icon-shopping-cart'} />
            <FormattedMessage id={'products.add_all_to_cart'} />
        </ButtonApp>
    )

    return (
        <div className={classNames('mass-add-to-cart-section', { 'd-none': !show })}>
            {PopoverButton && (
                <OverlayTrigger placement={'auto'} trigger={['hover', 'focus']} overlay={PopoverButton}>
                    <span className="btn-container">{AddToCartButton}</span>
                </OverlayTrigger>
            )}
            {!PopoverButton && AddToCartButton}
            <Modal onExit={handleOnModalExited} show={modalShow} />
        </div>
    )
}

Button.defaultProps = {
    enabled: false,
    show: false,
} as Partial<IProps>

export default memo(Button)
