/*
 *
 * Orders actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import {
    IApiOrderListPagination,
    IApiOrderDocumentResponse,
    IOrderDocumentType,
    IOrderListParameters,
    IOrderMode,
} from '../../services/api/service/orders/types'
import { IAppErrorTypes } from '../app/types'
import { AxiosError } from 'axios'
import {
    IOrderLastProcessPayload,
    IOrderListProcessPayload,
    IOrdersCustomerFailurePayload,
    IOrdersCustomerProcessPayload,
    IOrdersCustomerSuccessPayload,
    IOrdersDocumentFailurePayload,
    IOrdersDocumentProcessPayload,
    IOrdersDocumentResetSinglePayload,
    IOrdersDocumentSuccessPayload,
    IOrdersPersistParameters,
    IOrdersPersistSettingsPayload,
    IOrdersSwitchOrderModePayload,
} from './types'
import { ICustomer } from '../../services/api/service/customers/types'

export const ordersListProcessAction = (params: IOrderListParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IOrderListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })
export const ordersListSuccessAction = (response: IApiOrderListPagination) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IApiOrderListPagination>(ActionTypes.LIST_SUCCESS_ACTION, response)
export const ordersListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const ordersResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const ordersListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)
export const ordersLastResetAction = () => action<ActionTypes.LAST_RESET_ACTION>(ActionTypes.LAST_RESET_ACTION)
export const ordersResetCollectionAction = () =>
    action<ActionTypes.RESET_COLLECTION_ACTION>(ActionTypes.RESET_COLLECTION_ACTION)

export const ordersLastProcessAction = (params: IOrderListParameters) =>
    action<ActionTypes.LAST_PROCESS_ACTION, IOrderLastProcessPayload>(ActionTypes.LAST_PROCESS_ACTION, {
        params,
    })
export const ordersLastSuccessAction = (response: IApiOrderListPagination) =>
    action<ActionTypes.LAST_SUCCESS_ACTION, IApiOrderListPagination>(ActionTypes.LAST_SUCCESS_ACTION, response)
export const ordersLastFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LAST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LAST_FAILURE_ACTION, { error })

export const ordersDocumentProcessAction = (orderId: string, documentType: IOrderDocumentType) =>
    action<ActionTypes.DOCUMENT_PROCESS_ACTION, IOrdersDocumentProcessPayload>(ActionTypes.DOCUMENT_PROCESS_ACTION, {
        orderId,
        documentType,
    })
export const ordersDocumentSuccessAction = (
    orderId: string,
    documentType: IOrderDocumentType,
    response: IApiOrderDocumentResponse
) =>
    action<ActionTypes.DOCUMENT_SUCCESS_ACTION, IOrdersDocumentSuccessPayload>(ActionTypes.DOCUMENT_SUCCESS_ACTION, {
        orderId,
        documentType,
        response,
    })
export const ordersDocumentFailureAction = (orderId: string, documentType: IOrderDocumentType, error: AxiosError) =>
    action<ActionTypes.DOCUMENT_FAILURE_ACTION, IOrdersDocumentFailurePayload>(ActionTypes.DOCUMENT_FAILURE_ACTION, {
        orderId,
        documentType,
        error,
    })

export const ordersDocumentResetAction = () =>
    action<ActionTypes.DOCUMENT_RESET_ACTION>(ActionTypes.DOCUMENT_RESET_ACTION)

export const ordersDocumentResetSingleAction = (orderId: string, documentType: IOrderDocumentType) =>
    action<ActionTypes.DOCUMENT_RESET_SINGLE_ACTION, IOrdersDocumentResetSinglePayload>(
        ActionTypes.DOCUMENT_RESET_SINGLE_ACTION,
        {
            orderId,
            documentType,
        }
    )

export const ordersPersistParamsAction = (params: IOrdersPersistParameters, merge = false) =>
    action<ActionTypes.PERSIST_PARAMS_ACTION, IOrdersPersistSettingsPayload>(ActionTypes.PERSIST_PARAMS_ACTION, {
        params,
        merge,
    })

export const ordersPersistedParamsAction = () =>
    action<ActionTypes.PERSISTED_PARAMS_ACTION>(ActionTypes.PERSISTED_PARAMS_ACTION)

export const ordersSwitchOrderModeAction = (mode: IOrderMode) =>
    action<ActionTypes.SWITCH_ORDER_MODE_ACTION, IOrdersSwitchOrderModePayload>(ActionTypes.SWITCH_ORDER_MODE_ACTION, {
        mode,
    })

export const ordersCustomerProcessAction = (customer: string) =>
    action<ActionTypes.CUSTOMER_PROCESS_ACTION, IOrdersCustomerProcessPayload>(ActionTypes.CUSTOMER_PROCESS_ACTION, {
        customer,
    })

export const ordersCustomerSuccessAction = (customer: ICustomer) =>
    action<ActionTypes.CUSTOMER_SUCCESS_ACTION, IOrdersCustomerSuccessPayload>(ActionTypes.CUSTOMER_SUCCESS_ACTION, {
        customer,
    })

export const ordersCustomerFailureAction = (customer: string, error: IAppErrorTypes) =>
    action<ActionTypes.CUSTOMER_FAILURE_ACTION, IOrdersCustomerFailurePayload>(ActionTypes.CUSTOMER_FAILURE_ACTION, {
        customer,
        error,
    })
