/*
 *
 * TopBar
 *
 */

import React from 'react'
import { Container } from 'react-bootstrap'
import StorePicker, { StorePickerProps } from './Partial/StorePicker'

type IProps = StorePickerProps

function CustomerTopBar({ customer, store, onStoreChange }: IProps): JSX.Element {
    return (
        <section className={'member-top-bar customer-top-bar'}>
            <Container>
                <StorePicker customer={customer} store={store} onStoreChange={onStoreChange} />
            </Container>
        </section>
    )
}

export default CustomerTopBar
