/**
 *
 * ArrivalInfo
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import isString from 'lodash/isString'

type IProps = {
    labelId?: string
    arrivalDate: string | null
}

function ArrivalInfo({ labelId, arrivalDate }: IProps): JSX.Element {
    const { formatDate, formatMessage } = useIntl()

    const arrivalDateValue = useMemo(() => {
        if (!arrivalDate) {
            return null
        }

        // get current day
        const momentDate = moment(arrivalDate)

        let labelId = 'arrival_stocks.first_half_of_month'
        if (momentDate.date() > 15) {
            labelId = 'arrival_stocks.second_half_of_month'
        }

        return formatMessage(
            { id: labelId },
            {
                month: formatDate(arrivalDate, {
                    month: 'long',
                    day: undefined,
                    year: undefined,
                    minute: undefined,
                    second: undefined,
                }),
            }
        )
    }, [arrivalDate, formatMessage, formatDate])

    if (!isString(arrivalDate) || arrivalDate.length === 0) {
        return <></>
    }

    return (
        <div className={classNames('product-member-info', 'product-customer-arrival', 'product-field', 'has-date')}>
            <span className={'lbl'}>{formatMessage({ id: labelId || 'arrival_stocks.next_arrival' })}</span>
            <span className={'value'}> {arrivalDateValue}</span>
        </div>
    )
}

export default memo(ArrivalInfo)
