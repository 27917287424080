import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flatpickr from 'react-flatpickr'
import filter from 'lodash/filter'
import { getFlatpickrDateFormat, getFlatpickrTranslation } from '../../../i18n'
import { useIntl } from 'react-intl'

const DateRangeFilter = ({ column: { filterValue, setFilter, defaultCanFilter, canFilter } }): JSX.Element => {
    const { locale } = useIntl()

    const flatpickrTranslations = useMemo(() => {
        return getFlatpickrTranslation(locale)
    }, [locale])

    const flatpickrDateFormat = useMemo(() => {
        return getFlatpickrDateFormat(locale)
    }, [locale])

    const handleChange = useCallback(
        (selectedDates: Array<Date>) => {
            if (selectedDates.length < 2) {
                return
            }

            setFilter({
                after: selectedDates[0] ? selectedDates[0] : undefined,
                before: selectedDates[1] ? selectedDates[1] : undefined,
            })
        },
        [setFilter]
    )

    const dateValue = useMemo(() => {
        if (!filterValue) {
            return []
        }
        const vals = filter(Object.values(filterValue), (val) => {
            return val !== null && typeof val !== 'undefined'
        })
        return vals.length > 0 ? Object.values(filterValue) : []
    }, [filterValue])

    if (!defaultCanFilter || !canFilter) {
        return <></>
    }

    return (
        <div className="filter filter-date-range">
            <Flatpickr
                placeholder=""
                value={dateValue}
                onChange={handleChange}
                options={{
                    dateFormat: flatpickrDateFormat,
                    locale: flatpickrTranslations,
                    mode: 'range',
                }}
            />
            <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} className="app-icon" />
        </div>
    )
}

export default DateRangeFilter
