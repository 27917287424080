import { ICustomer, ICustomerIdentity, ICustomerType } from '../../services/api/service/customers/types'
import { ICustomerMenuItemType, ICustomerMenuItemTypeCollection, ICustomerMenuLocation } from '../../types/customer'
import prop from '../../utils/prop'
import isUndefined from 'lodash/isUndefined'
import { isOrderAvailable } from '../carts/utils'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../salesmens/utils'
import { StrictCartMode } from '../carts/types'
import { ISalesmanProspect } from '../../services/api/service/prospects/types'
import isArray from 'lodash/isArray'

export function isCustomerResource(obj: any): obj is ICustomer {
    return typeof obj === 'object' && typeof obj['@type'] === 'string' && obj['@type'] === 'Customer'
}

export function isCustomer(obj: any): obj is ICustomer {
    return isCustomerResource(obj) && obj.prospect === false
}

export function hasPendingCarts(obj: ICustomer) {
    return isArray(obj.alert_cart_in_progress) && obj.alert_cart_in_progress.length > 0
}

export function isProspect(obj: any): obj is ISalesmanProspect {
    return isCustomerResource(obj) && obj.prospect === true
}

export function isCustomerIdentityResource(obj: any): obj is ICustomerIdentity {
    return isCustomerResource(obj) && typeof obj === 'object' && typeof obj['account_type'] === 'undefined'
}

export function canUseFavorites(customer: ICustomer, me: IMe, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    return isOrderAvailable(customer, store, cartMode) && !isSalesmanResource(me)
}

export function customerShowPrices(customer?: ICustomer, me?: IMe, store?: ICustomer): boolean {
    return customer?.hide_prices === false
}

// est-ce que le client peut lister les paniers dans son espace client
export function customerCanListCarts(customer?: ICustomer, me?: IMe, store?: ICustomer): boolean {
    return customer?.account_type === ICustomerType.MultiStore
}

export function customerShowFilters(customer?: ICustomer, me?: IMe, store?: ICustomer): boolean {
    // quelque soit la personne connectée, elle peux filtrer
    return true
    // return isSalesmanResource(me)
}

export function canManageFavoriteClassification(
    customer: ICustomer,
    me: IMe,
    store?: ICustomer,
    cartMode?: StrictCartMode
): boolean {
    return canUseFavorites(customer, me, store, cartMode)
}
export function canViewFavorites(customer: ICustomer, me: IMe, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    return isOrderAvailable(customer, store, cartMode)
}
export function canImport(customer: ICustomer, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    return isOrderAvailable(customer, store, cartMode) || customer.account_type === ICustomerType.MultiStore
}

export function canExport(customer: ICustomer, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    if (customer.account_type !== ICustomerType.MultiStore && isOrderAvailable(customer, store, cartMode)) {
        return true
    }

    return isOrderAvailable(customer, store, cartMode) && !isUndefined(store)
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
export function hasVirtualCart(customer: ICustomer, store?: ICustomer): boolean {
    return customer.has_virtual_cart === true
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
export function canViewStores(customer: ICustomer, store?: ICustomer): boolean {
    return typeof customer !== 'undefined' && customer.account_type === ICustomerType.MultiStore
}

export function canViewInvoices(customer: ICustomer, store?: ICustomer, me?: IMe, cartMode?: StrictCartMode): boolean {
    if (
        !customerShowPrices(customer, me, store) ||
        isProspect(customer) ||
        (isCustomer(customer) && !customer.allow_access_invoices)
    ) {
        return false
    }

    // if (customer.account_type !== ICustomerType.MultiStore) {
    return true
    // }

    // if (isOrderAvailable(customer, store, cartMode)) {
    //     return true
    // } else if (!isUndefined(store)) {
    //     return true
    // }
    //
    // return false
}

export function canViewOrders(customer: ICustomer, store?: ICustomer, me?: IMe, cartMode?: StrictCartMode): boolean {
    if (!customerShowPrices(customer, me, store)) {
        return false
    }

    // if (customer.account_type !== ICustomerType.MultiStore) {
    return true
    // }

    // if (isOrderAvailable(customer, store, cartMode)) {
    //     return true
    // } else if (!isUndefined(store)) {
    //     return true
    // }

    // return false
}

export function getExcludedCustomerMenuItems(
    customer: ICustomer,
    location: ICustomerMenuLocation,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
): ICustomerMenuItemTypeCollection | undefined {
    const items: ICustomerMenuItemTypeCollection = []
    if (location === ICustomerMenuLocation.Dropdown) {
        items.push(ICustomerMenuItemType.FavoriteDepartment)
        items.push(ICustomerMenuItemType.PictureExport)
    }
    if (location === ICustomerMenuLocation.Dropdown || isSalesmanResource(me)) {
        items.push(ICustomerMenuItemType.Logout)
    }
    if (!canViewStores(customer, store)) {
        items.push(ICustomerMenuItemType.Stores)
    }
    if (me && !canViewFavorites(customer, me, store, cartMode)) {
        items.push(ICustomerMenuItemType.Favorites)
    }
    if (!canImport(customer, store, cartMode)) {
        items.push(ICustomerMenuItemType.Imports)
    }
    if (!canExport(customer, store, cartMode)) {
        items.push(ICustomerMenuItemType.Exports)
    }
    if (!canViewInvoices(customer, store, me, cartMode)) {
        items.push(ICustomerMenuItemType.Invoices)
    }
    if (!canViewOrders(customer, store, me)) {
        items.push(ICustomerMenuItemType.Orders)
    }

    return items.length > 0 ? items : undefined
}

export function getRestrictedCustomerMenuItems(
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    customer: ICustomer,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    location: ICustomerMenuLocation,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    store?: ICustomer,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
    me?: IMe
): ICustomerMenuItemTypeCollection | undefined {
    return undefined
}

export function isShowProductCustomerInfo(customer: ICustomer): boolean {
    return customer && customer.account_type === ICustomerType.Simple
}

type CustomerKeys = keyof ICustomer
export function findCustomerStoreBy(customer: ICustomer, propertyName: CustomerKeys, value: any): ICustomer {
    return customer.stores?.find((store) => prop(store, propertyName) === value) as ICustomer
}

export function findCustomerStore(customer: ICustomer, storeId: string): ICustomer {
    return customer.stores?.find((store) => store['@id'] === storeId) as ICustomer
}
