import { ContainerActions, ContainerState, IPictureExportListState, IPictureExportProcessState } from './types'
import ActionTypes from './constants'

export const initialListState: IPictureExportListState = {
    fetching: false,
    params: {
        page: 1,
    },
}

export const initialProcessState: IPictureExportProcessState = {
    creating: false,
    refreshing: false,
}

export const initialState: ContainerState = {
    process: initialProcessState,
    list: initialListState,
}

function pictureExportReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LIST_RESET_ACTION:
            return {
                ...state,
                list: {
                    ...initialListState,
                },
            }
        case ActionTypes.PROCESS_RESET_ACTION:
            return {
                ...state,
                process: {
                    ...initialProcessState,
                },
            }
        case ActionTypes.PROCESS_CREATE_PROCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...initialProcessState,
                    creating: true,
                    params: action.payload.params,
                },
            }
        }
        case ActionTypes.PROCESS_CREATE_SUCCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...initialProcessState,
                    item: action.payload.item,
                    creating: false,
                },
            }
        }
        case ActionTypes.PROCESS_CREATE_FAILURE_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    creating: false,
                    createError: action.payload.error,
                },
            }
        }
        case ActionTypes.PROCESS_REFRESH_PROCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    refreshing: true,
                },
            }
        }
        case ActionTypes.PROCESS_REFRESH_SUCCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    refreshing: false,
                    item: {
                        ...state.process.item,
                        ...action.payload.item,
                    },
                },
            }
        }
        case ActionTypes.PROCESS_REFRESH_FAILURE_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    refreshing: false,
                    refreshError: action.payload.error,
                },
            }
        }
        case ActionTypes.LIST_PROCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: undefined,
                    params: action.payload.params,
                },
            }
        case ActionTypes.LIST_SUCCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.payload.response.data['hydra:member'],
                    totalItems: action.payload.response.data['hydra:totalItems'],
                    nextPageUrl: action.payload.response.data['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload.response.data['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload.response.data['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload.response.data['hydra:view']['hydra:last'],
                },
            }
        }
        case ActionTypes.LIST_FAILURE_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: action.payload.error,
                },
            }
        default:
            return state
    }
}

export default pictureExportReducer
