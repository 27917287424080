import { AxiosResponse, CancelToken } from 'axios'
import { IApiResource, IBasePaginationParameters, IApiFileLink, IPaginationApiResponse } from '../../types'
import { IBaseProduct } from '../products/types'
import { IInvoiceDetail } from '../invoices/types'
import { Overwrite } from 'utility-types'
import { ICustomerIdentity } from '../customers/types'
import { IShippingLocationLight } from '../classification/types'

/*******************************
 * ORDER
 * ****************************/
export type IOrderProduct = Omit<IBaseProduct, 'favorite' | 'packing' | 'main_pictures'>

export interface IOrderItem {
    readonly id: string
    readonly product: IOrderProduct
    readonly quantity: number
    readonly shipped_quantity: number
    readonly packing: number
    readonly unit_price: number
    readonly total: number
    readonly can_declare_anomaly: boolean
    readonly defective_count: number
    readonly missing_count: number
    readonly shipping_quantity: number
}

export enum OrderStates {
    Pending = 'pending',
    Processing = 'processing',
    Preparing = 'preparing', // Preparing => En cours de préparation
    Shipped = 'shipped',
    Closed = 'closed',
}

export enum IOrderType {
    Order = 'order',
    Asset = 'asset',
}

export enum IOrderMode {
    Order = 'order', // mode classique
    Asset = 'asset', // mode devis
}

export enum IOrderDocumentType {
    PurchaseOrderPdf = 'purchase_order_pdf',
    PurchaseOrderExcel = 'purchase_order_excel',
    DeliveryNoteExcel = 'delivery_note_excel',
    DeliveryRemaindersExcel = 'delivery_remainders_excel',
}

export type IOrderItemCollection = Array<IOrderItem>

export type IResourceOrder = 'Order'
export const RESOURCE_ORDER_TYPE: IResourceOrder = 'Order'

export type IProductOrderInProgress = {
    readonly id: string
    readonly number: string
    readonly date: string
    readonly quantity: number
}

export type ProductOrderInProgressCollection = Array<IProductOrderInProgress>

export interface IOrderList extends IApiResource<IResourceOrder> {
    readonly id: string
    readonly date: string
    readonly number: string
    readonly customer: ICustomerIdentity
    readonly shipping_location: IShippingLocationLight
    readonly state: OrderStates
    readonly total_packing: number
    readonly order_by_unit: boolean
    readonly total: number
    readonly type: IOrderType
}

export interface IOrderDetail extends IOrderList {
    readonly requested_delivery_date?: string
    readonly comment?: string
    readonly total_volume?: number
    readonly items: IOrderItemCollection
    readonly invoices: Array<IInvoiceDetail>
    readonly total_quantity?: number
    readonly total_shipped_quantity?: number
    readonly can_declare_anomaly: boolean
}

export interface IOrderCreation extends IOrderDetail {
    readonly minimum_order_amount: number
}

//##### TEMP
export type IOrderAnomalyItem = {
    readonly product: string
    readonly missing_count: number
    readonly defective_count: number
    readonly comment: string
    readonly attachments: Array<{ upload: string }>
}

export type IOrderAnomalyItemCollection = Array<IOrderAnomalyItem>

export interface IOrderAnomalyResume extends IApiResource {
    readonly number: string
}

export interface IOrderAnomaly extends IApiResource {
    readonly order: string
    readonly comment: string
    readonly items: IOrderAnomalyItemCollection
}

export type IOrderAnomalyEditableFields = Pick<IOrderAnomaly, 'comment' | 'order'> & {
    items: Array<
        Overwrite<
            IOrderAnomalyItem,
            {
                readonly missing_count: number | string
                readonly defective_count: number | string
            }
        >
    >
}
//\\##### TEMP

export type IOrderDocumentLink = IApiFileLink

export type IOrdersCreateCollection = Array<IOrderCreation>
export type IOrdersCreatePagination = IPaginationApiResponse<IOrdersCreateCollection>
export type IOrderListCollection = Array<IOrderList>
export type IOrderListParameters = IBasePaginationParameters & {
    all_customers?: 1 | 0
    all_activity?: 1 | 0
    type?: IOrderType
}

export type IApiOrderListPagination = IPaginationApiResponse<Array<IOrderList>>
export type IApiOrderListResponse = AxiosResponse<IApiOrderListPagination>
export type IApiOrderResponse = AxiosResponse<IOrderDetail>
export type IApiOrderDocumentResponse = AxiosResponse<IOrderDocumentLink>
export type IApiOrdersCreateResponse = AxiosResponse<IOrdersCreatePagination>

export interface IOrdersService {
    list(parameters: IOrderListParameters, cancelToken?: CancelToken): Promise<IApiOrderListResponse>
    get(orderId: string, cancelToken?: CancelToken): Promise<IApiOrderResponse>
    document(
        orderId: string,
        documentType: IOrderDocumentType,
        cancelToken?: CancelToken
    ): Promise<IApiOrderDocumentResponse>
    create(
        cartId: string,
        comment?: string,
        requestedDeliveryDate?: string,
        validateOnlyMinimumAmountOrders?: boolean,
        paymentMode?: string
    ): Promise<IApiOrdersCreateResponse>
}
