/*
 *
 * Cms constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Salesman/RESET',
    CUSTOMER_PROCESS_ACTION = 'app/Salesmen/Customers/REQUEST_PROCESS',
    CUSTOMER_SUCCESS_ACTION = 'app/Salesmen/Customers/REQUEST_SUCCESS',
    CUSTOMER_FAILURE_ACTION = 'app/Salesmen/Customers/REQUEST_FAILURE',
    PROSPECT_PROCESS_ACTION = 'app/Salesmen/Prospects/REQUEST_PROCESS',
    PROSPECT_SUCCESS_ACTION = 'app/Salesmen/Prospects/REQUEST_SUCCESS',
    PROSPECT_FAILURE_ACTION = 'app/Salesmen/Prospects/REQUEST_FAILURE',
    PROSPECT_REFRESH_ACTION = 'app/Salesmen/Prospects/REFRESH',
    PROSPECT_EDIT_PROCESS_ACTION = 'app/Salesmen/Prospects/Edit/REQUEST_PROCESS',
    PROSPECT_EDIT_SUCCESS_ACTION = 'app/Salesmen/Prospects/Edit/REQUEST_SUCCESS',
    PROSPECT_EDIT_FAILURE_ACTION = 'app/Salesmen/Prospects/Edit/REQUEST_FAILURE',
    PROSPECT_EDIT_RESET_ACTION = 'app/Salesmen/Prospects/Edit/RESET',
}

export default ActionTypes
