import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiProductCollectionResponse,
    IApiProductGridProductsResponse,
    IApiProductGridCollectionResponse,
    IApiProductGroupResponse,
    IApiProductListFilterResponse,
    IApiProductListResponse,
    IApiProductResponse,
    IApiProductSearchByImageResponse,
    IApiViewedProductsResponse,
    IProductListFiltersParameters,
    IProductListParameters,
    IProductsService,
} from './types'
import { IApiBooleanType } from '../../types'

const create = (instance: AxiosInstance): IProductsService => {
    /*****************************
     * METHODS
     *****************************/
    const searchByImage = (
        data: any,
        onUploadProgress?: (progressEvent: any) => void,
        cancelToken?: CancelToken,
        headers?: any
    ): Promise<IApiProductSearchByImageResponse> => {
        return instance.post('/search-by-image', data, { onUploadProgress, cancelToken, headers })
    }

    const list = (parameters: IProductListParameters, cancelToken?: CancelToken): Promise<IApiProductListResponse> => {
        let params = { ...parameters }
        if (params.filters) {
            delete params['filters']
        }
        if (parameters['filters']) {
            params = { ...params, ...parameters.filters }
        }
        return instance.get('/products', { params: params, cancelToken })
    }

    const filters = (
        parameters: IProductListFiltersParameters,
        cancelToken?: CancelToken
    ): Promise<IApiProductListFilterResponse> => {
        let params = { ...parameters }
        if (params.filters) {
            delete params['filters']
        }
        if (parameters['filters']) {
            params = { ...params, ...parameters.filters }
        }
        return instance.get('/product-filters', { params: params, cancelToken })
    }

    const get = (productId: string, cancelToken?: CancelToken): Promise<IApiProductResponse> => {
        return instance.get(`/products/${productId}`, { cancelToken })
    }

    const productGroup = (
        productGroupId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductGroupResponse> => {
        return instance.get(`${productGroupId}`, { cancelToken, params: { listed_only } })
    }

    const productCollection = (
        productCollectionId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductCollectionResponse> => {
        return instance.get(`/product-collections/${productCollectionId}`, { cancelToken, params: { listed_only } })
    }

    const productCrossSell = (
        productId: string,
        params: any,
        cancelToken?: CancelToken
    ): Promise<IApiProductListResponse> => {
        return instance.get(`/products/${productId}/cross-sell`, { cancelToken, params })
    }

    const productTheme = (
        productThemeId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductCollectionResponse> => {
        return instance.get(`/product-themes/${productThemeId}`, { cancelToken })
    }

    const viewedProducts = (): Promise<IApiViewedProductsResponse> => {
        return instance.get(`/products-viewed`)
    }

    const productGrids = (): Promise<IApiProductGridCollectionResponse> => {
        return instance.get('/product-grids')
    }
    const productGridProducts = (
        gridId: string,
        parameters: IProductListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiProductGridProductsResponse> => {
        const paths = gridId.split('/')
        const identifier = paths[paths.length - 1]
        let params = { ...parameters }
        if (params.filters) {
            delete params['filters']
        }
        if (parameters['filters']) {
            params = { ...params, ...parameters.filters }
        }
        return instance.get(`/product-grids/${identifier}/products`, { params: params, cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        get,
        productGroup,
        filters,
        viewedProducts,
        productCollection,
        productTheme,
        productCrossSell,
        searchByImage,
        productGrids,
        productGridProducts,
    }
}

export default {
    create,
}
