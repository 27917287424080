/*
 *
 * Pagination
 *
 */

import Qs from 'qs'
import React, { memo, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Component from '../../../components/Products/Content/ItemsPerPage'
import Config from '../../../config'
import { ProductListQueryName } from '../../../services/api/service/products/types'
import rtrim from '../../../utils/rtrim'

interface IProps {
    loading: boolean
    count?: number
}

function ItemsPerPage({ loading, count }: IProps): JSX.Element {
    const history = useHistory()
    const { search, pathname } = useLocation()

    const defaultValue = useMemo(() => {
        const params = new URLSearchParams(search)
        const param = params.get(ProductListQueryName.ItemsPerPage)
        const itemsPerPage = param !== null && param.length > 0 ? parseInt(param) : Config.PRODUCT_LIST.ITEMS_PER_PAGE
        return isNaN(itemsPerPage) ? Config.PRODUCT_LIST.ITEMS_PER_PAGE : itemsPerPage
    }, [search])

    const handleChange = useCallback(
        (selectedItemsPerPage: number) => {
            const parsed = Qs.parse(search.substring(1))
            parsed[ProductListQueryName.ItemsPerPage] = String(selectedItemsPerPage)
            delete parsed[ProductListQueryName.Page]
            const stringified = Qs.stringify(parsed)
            const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
            const baseUrl = rtrim(pathname, '/')
            history.push(`${baseUrl}${qstring}`)
        },
        [search, history, pathname]
    )

    const show = useMemo(() => {
        return loading || (typeof count === 'number' && count > 0)
    }, [count, loading])

    return <Component show={show} disabled={loading} defaultValue={defaultValue} onChange={handleChange} />
}

ItemsPerPage.defaultProps = {} as Partial<IProps>

export default memo(ItemsPerPage)
