import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { createSelector } from 'reselect'

const selectPictureExportDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.pictureExport || initialState
}
const selectPictureExportProcessDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.pictureExport.process || initialState.process
}
const selectPictureExportListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.pictureExport.list || initialState.list
}

const makeSelectPictureExportProcessCreateFetching = () =>
    createSelector(selectPictureExportProcessDomain, (state) => {
        return state.creating
    })

const makeSelectPictureExportProcessCreateError = () =>
    createSelector(selectPictureExportProcessDomain, (state) => {
        return state.createError
    })

const makeSelectPictureExportProcessItem = () =>
    createSelector(selectPictureExportProcessDomain, (state) => {
        return state.item
    })

const makeSelectPictureExportProcessRefreshFetching = () =>
    createSelector(selectPictureExportProcessDomain, (state) => {
        return state.refreshing
    })

const makeSelectPictureExportProcessRefreshError = () =>
    createSelector(selectPictureExportProcessDomain, (state) => {
        return state.refreshError
    })

const makeSelectPictureExportListFetching = () =>
    createSelector(selectPictureExportListDomain, (state) => {
        return state.fetching
    })
const makeSelectPictureExportListError = () =>
    createSelector(selectPictureExportListDomain, (state) => {
        return state.error
    })
const makeSelectPictureExportListItems = () =>
    createSelector(selectPictureExportListDomain, (state) => {
        return state.items
    })

const makeSelectPictureExportListTotalItems = () =>
    createSelector(selectPictureExportListDomain, (state) => {
        return state.totalItems
    })

export default selectPictureExportDomain

export {
    makeSelectPictureExportProcessCreateFetching,
    makeSelectPictureExportProcessCreateError,
    makeSelectPictureExportProcessItem,
    makeSelectPictureExportProcessRefreshFetching,
    makeSelectPictureExportProcessRefreshError,
    makeSelectPictureExportListFetching,
    makeSelectPictureExportListError,
    makeSelectPictureExportListItems,
    makeSelectPictureExportListTotalItems,
}
