import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiPictureExportResponse,
    IPictureExportService,
    IPictureExportParams,
    IApiPictureExportListParameters,
    IApiPictureExportListResponse,
} from './types'

const create = (instance: AxiosInstance): IPictureExportService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (
        params: IApiPictureExportListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiPictureExportListResponse> => {
        return instance.get('/picture-exports', { params, cancelToken })
    }
    const create = (params: IPictureExportParams, cancelToken?: CancelToken): Promise<IApiPictureExportResponse> => {
        return instance.post('/picture-exports', params, { cancelToken })
    }

    const get = (pictureExportId: string): Promise<IApiPictureExportResponse> => {
        const paths = pictureExportId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.get(`/picture-exports/${identifier}`)
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        create,
        get,
    }
}

export default {
    create,
}
