/*
 *
 * App constants
 *
 */

enum ActionTypes {
    APP_BOOTSTRAP_ACTION = 'app/App/BOOTSTRAP',
    APP_READY_ACTION = 'app/App/READY',
    APP_RELOADING_ACTION = 'app/App/RELOADING',
    APP_RELOADED_ACTION = 'app/App/RELOADED',
    APP_RELOAD_FAIL_ACTION = 'app/App/RELOADING_FAIL',
    APP_ERROR_ACTION = 'app/App/ERROR',
    APP_ERROR_CLEAR_ACTION = 'app/App/ERROR_CLEAR',
}

export default ActionTypes
