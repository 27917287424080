/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { IFamily } from '../../../services/api/service/classification/types'
import { ISearchSuggestionFamily } from '../../../services/api/service/search/types'

interface IProps {
    family: IFamily | ISearchSuggestionFamily
    onClick: (family: IFamily | ISearchSuggestionFamily) => void
}

function Suggestion({ family, onClick }: IProps): JSX.Element {
    const handleOnClick = useCallback(() => {
        if (onClick) {
            onClick(family)
        }
    }, [family, onClick])
    return (
        <div onClick={handleOnClick} className={classNames('family', 'family-card', 'family-suggestion')}>
            <span className={classNames('value')}>{family.label}</span>
        </div>
    )
}

Suggestion.defaultProps = {} as Partial<IProps>

export default memo(Suggestion)
