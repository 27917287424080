import { call, put, takeLatest, getContext, cancelled } from 'redux-saga/effects'
import InvoicesActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    invoicesListFailureAction,
    invoicesListSuccessAction,
    invoicesDocumentFailureAction,
    invoicesDocumentSuccessAction,
} from './actions'
import { IInvoicesListProcessAction, IInvoicesDocumentProcessAction } from './types'
import { IApiInvoiceListResponse, IApiInvoicePdfResponse } from '../../services/api/service/invoices/types'
import { formatAppError } from '../app/saga'

// TOKEN
import axios from 'axios'
const CancelToken = axios.CancelToken

function* processInvoicesRequest(action: IInvoicesListProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const { params } = action.payload
        const response: IApiInvoiceListResponse = yield call({ context: api.invoices, fn: 'list' }, params)
        yield put(invoicesListSuccessAction(response.data))
    } catch (e) {
        yield put(invoicesListFailureAction(e))
    }
}

function* processInvoicesPdfRequest(action: IInvoicesDocumentProcessAction) {
    const source = CancelToken.source()
    const api: IApiClient = yield getContext('api')
    const { invoiceId } = action.payload

    try {
        const response: IApiInvoicePdfResponse = yield call(
            { context: api.invoices, fn: 'pdf' },
            invoiceId,
            source.token
        )
        yield put(invoicesDocumentSuccessAction(invoiceId, response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'invoice.document.error')
        yield put(invoicesDocumentFailureAction(invoiceId, error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(InvoicesActionTypes.LIST_PROCESS_ACTION, processInvoicesRequest),
    takeLatest(InvoicesActionTypes.DOCUMENT_PROCESS_ACTION, processInvoicesPdfRequest),
]
