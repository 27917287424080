import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { IReOrderParameters } from '../../../services/api/service/carts/types'
import { cartReOrderProcessAction, cartReOrderResetAction } from '../../../store/carts/actions'
import { useDispatch, useSelector } from 'react-redux'
import { IOrderDetail, IOrderMode } from '../../../services/api/service/orders/types'
import HttpError from '../../../store/app/httpError'
import HttpStatusCode from '../../../store/http/codes'

import {
    makeSelectCartsReOrderData,
    makeSelectCartsReOrderErrors,
    makeSelectCartsReOrderFetching,
    makeSelectCartsReOrderSuccess,
    makeSelectCartsReOrderWarnings,
} from '../../../store/carts/selectors'
import { IApplicationRootState } from '../../../store'
import { IAppErrorTypes } from '../../../store/app/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { getPath } from '../../../routes'
import { default as ReOrderFactory } from '../../../components/Carts/ReOrder/CartReOrderFactory'
import { createStructuredSelector } from 'reselect'

import { ICustomer } from '../../../services/api/service/customers/types'
import { OrderDetailAction } from './type'
import { IReOrderData } from '../../../store/carts/types'
import { makeSelectOrderCustomerWithId } from '../../../store/orders/selectors'
import { makeSelectAuthMe } from '../../../store/auth/selectors'
import { IMe } from '../../../services/api/service/me/types'
import { isSalesmanResource } from '../../../store/salesmens/utils'
import { ordersSwitchOrderModeAction } from '../../../store/orders/actions'
import { customerSetCurrent } from '../../../store/customers/actions'

type Props = OrderDetailAction & {
    order: IOrderDetail
    useCartType?: boolean
}

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

const ReOrder = ({ order, useCartType, label, icon, block, size }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
        }
    >(stateSelector)
    const [showReOrderError, setShowReOrderError] = useState<boolean>(false)
    const [showReOrderSuccess, setShowReOrderSuccess] = useState<boolean>(false)
    const selectReOrderFetching = useMemo(makeSelectCartsReOrderFetching, [])
    const selectReOrderError = useMemo(makeSelectCartsReOrderErrors, [])
    const selectReOrderWarning = useMemo(makeSelectCartsReOrderWarnings, [])
    const selectReOrderSuccess = useMemo(makeSelectCartsReOrderSuccess, [])
    const selectReOrderData = useMemo(makeSelectCartsReOrderData, [])
    const selectOrderCustomer = useMemo(makeSelectOrderCustomerWithId, [])

    const reorderFetching = useSelector<IApplicationRootState, boolean>((state) =>
        selectReOrderFetching(state, order.id)
    )
    const reorderSuccess = useSelector<IApplicationRootState, boolean>((state) => selectReOrderSuccess(state, order.id))
    const reorderWarnings: Array<string> | undefined = useSelector<IApplicationRootState, Array<string> | undefined>(
        (state) => selectReOrderWarning(state, order.id)
    )
    const reorderError: IAppErrorTypes | undefined = useSelector<IApplicationRootState, IAppErrorTypes | undefined>(
        (state) => selectReOrderError(state, order.id)
    )
    const reorderData: IReOrderParameters | undefined = useSelector<
        IApplicationRootState,
        IReOrderParameters | undefined
    >((state) => selectReOrderData(state, order.id))
    const customerOrder: ICustomer | undefined = useSelector<IApplicationRootState, ICustomer | undefined>((state) =>
        selectOrderCustomer(state, order.customer.id)
    )

    const cartUrl = useMemo(() => generatePath(getPath('cart', locale), { lang: locale }), [locale])
    const violations = useMemo(() => {
        if (!reorderError || !reorderError.isHttpError) {
            return undefined
        }

        if (reorderError.isHttpError) {
            if (
                String((reorderError as HttpError).status) === HttpStatusCode.BAD_REQUEST.toString() &&
                (reorderError as HttpError).violations
            ) {
                let errors: Array<string> = []
                for (const key in (reorderError as HttpError).violations) {
                    // @ts-ignore
                    const itemErrors = (reorderError as HttpError).violations[key]
                    errors = [...errors, ...itemErrors]
                }
                return errors
            }
        }

        return undefined
    }, [reorderError])

    const handleReOrderAlertConfirm = useCallback(() => {
        setShowReOrderError(false)
    }, [setShowReOrderError])

    const handleReOrderAlertSuccessCancel = useCallback(() => {
        setShowReOrderSuccess(false)
        if (order) {
            dispatch(cartReOrderResetAction(order.id))
        }
    }, [setShowReOrderSuccess, order, dispatch])

    const handleReOrderAlertSuccessConfirm = useCallback(() => {
        // on cache la modal
        setShowReOrderSuccess(false)

        // dans le cas où c'est un client normal, on ne fait que le rediriger
        if (!isSalesmanResource(me)) {
            // on redirige vers le paneir
            history.push(cartUrl)

            // reset si necessaire
            if (order) {
                dispatch(cartReOrderResetAction(order.id))
            }

            return
        }

        // sinon, on a un peu plus de code à écrire
        if (reorderData?.cart_type) {
            dispatch(ordersSwitchOrderModeAction(reorderData.cart_type))
        }

        if (customerOrder) {
            dispatch(customerSetCurrent(customerOrder.id, true, cartUrl))
        }
    }, [history, cartUrl, order, dispatch, customerOrder, me, reorderData])

    const handleReOrderSubmit = useCallback(
        (data: IReOrderData) => {
            if (!order || !customerOrder) {
                return
            }
            dispatch(
                cartReOrderProcessAction(
                    order.id,
                    {
                        ...data,
                        order: order['@id'],
                    },
                    customerOrder.id
                )
            )
            setShowReOrderSuccess(false)
            setShowReOrderError(false)
        },
        [order, dispatch, setShowReOrderSuccess, setShowReOrderError, customerOrder]
    )

    useEffect(() => {
        setShowReOrderError(typeof reorderError !== 'undefined')
    }, [reorderError, setShowReOrderError])

    useEffect(() => {
        setShowReOrderSuccess(reorderSuccess)
    }, [reorderSuccess, setShowReOrderSuccess])

    // if (!order.shipping_location) {
    //     return <></>
    // }

    return (
        <>
            {customerOrder && (
                <ReOrderFactory
                    order={order}
                    customer={customerOrder}
                    useCartType={useCartType}
                    shippingLocation={order.shipping_location}
                    fetching={reorderFetching}
                    error={reorderError}
                    done={typeof reorderError !== 'undefined' || reorderSuccess}
                    onReOrderSubmit={handleReOrderSubmit}
                    block={block}
                    size={size}
                    label={label}
                    icon={icon}
                />
            )}
            <SweetAlert
                show={showReOrderError}
                error
                title={formatMessage({
                    id: 'default.error',
                })}
                onConfirm={handleReOrderAlertConfirm}
            >
                {violations?.map((violation) => (
                    <p className={'mb-0'} key={violation}>
                        {violation}
                    </p>
                ))}
                {(!violations || violations.length === 0) && reorderError?.message}
            </SweetAlert>
            <SweetAlert
                show={showReOrderSuccess}
                showCancel
                customClass={'add-to-cart-alert'}
                success={reorderWarnings && reorderWarnings.length === 0}
                warning={reorderWarnings && reorderWarnings.length > 0}
                cancelBtnText={formatMessage({ id: 'default.continue_my_shopping' })}
                confirmBtnText={formatMessage({
                    id: reorderData?.cart_type === IOrderMode.Asset ? 'default.finalize_asset' : 'default.place_order',
                })}
                title={formatMessage(
                    {
                        id: `order.detail.re_order${reorderData?.cart_type ? '.cart_type' : ''}.${
                            reorderData && reorderData.erase_cart ? 'erase_cart' : 'merge_cart'
                        }_title`,
                    },
                    {
                        shipping_location: order.shipping_location ? order.shipping_location.label : '',
                        cart_type: reorderData?.cart_type
                            ? formatMessage({ id: `order_mode.${reorderData?.cart_type}` })
                            : '',
                    }
                )}
                onCancel={handleReOrderAlertSuccessCancel}
                onConfirm={handleReOrderAlertSuccessConfirm}
            >
                <FormattedMessage
                    id={`order.detail.re_order${reorderData?.cart_type ? '.cart_type' : ''}.success_${
                        reorderData && reorderData.erase_cart ? 'erase_cart' : 'merge_cart'
                    }_message`}
                    values={{
                        shipping_location: order.shipping_location ? order.shipping_location.label : '',
                        cart_type: reorderData?.cart_type
                            ? formatMessage({ id: `order_mode.${reorderData?.cart_type}` })
                            : '',
                    }}
                />
                {reorderWarnings && reorderWarnings.length > 0 && (
                    <>
                        {' '}
                        <FormattedMessage id={`order.detail.re_order.warning_message`} />
                        <br />
                        <br />
                        {reorderWarnings.join('\n')}
                    </>
                )}
            </SweetAlert>
        </>
    )
}

export default memo(ReOrder)
