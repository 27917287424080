/**
 *
 * Product Prices
 *
 */
import classNames from 'classnames'
import React, { memo, ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProductUnitType } from '../../../services/api/service/products/types'
import { default as PriceComp } from '../../Price/Price'

interface IProps {
    price?: number
    strikePrice?: number
    retailPrice?: number
    className?: string
    abbreviateLabels: boolean
    showRetailPrice?: boolean
    unitOfSale?: ProductUnitType
    children?: ReactNode
}

function Price({
    price,
    strikePrice,
    retailPrice,
    children,
    className,
    abbreviateLabels,
    showRetailPrice,
    unitOfSale,
}: IProps): JSX.Element {
    const { formatMessage } = useIntl()

    const buyingPriceLabelId = useMemo(() => {
        if (unitOfSale === ProductUnitType.Meter && !abbreviateLabels) {
            return 'price.buying_price_meter'
        }
        return abbreviateLabels ? 'price.buying_price_abbr' : 'price.buying_price'
    }, [abbreviateLabels, unitOfSale])

    const buyingPriceTitleIdentifier = useMemo(() => {
        if (unitOfSale === ProductUnitType.Meter) {
            return 'price.buying_price_meter'
        }
        return 'price.buying_price'
    }, [unitOfSale])

    return (
        <div className={classNames('product-price-list', className)}>
            <span className={classNames('product-price', 'product-price-buying')}>
                <span className={'lbl'} title={formatMessage({ id: buyingPriceTitleIdentifier })}>
                    <FormattedMessage id={buyingPriceLabelId} />
                </span>
                {typeof price === 'number' ? (
                    <span className={classNames('price', 'value', 'defined')}>
                        {typeof strikePrice === 'number' && (
                            <PriceComp price={strikePrice} className={'strike-price'} />
                        )}
                        <PriceComp price={price} className={'selling-price'} />
                    </span>
                ) : (
                    <span className={classNames('price', 'value', 'not-defined')}>
                        <FormattedMessage id={'price.not_defined'} />
                    </span>
                )}
            </span>
            {showRetailPrice && (
                <span className={classNames('product-price', 'product-price-recommended-retail')}>
                    <span className={classNames('lbl')}>
                        <FormattedMessage
                            id={
                                abbreviateLabels
                                    ? 'price.recommended_retail_price_abbr'
                                    : 'price.recommended_retail_price'
                            }
                        />
                    </span>
                    {retailPrice && <PriceComp price={retailPrice} className={'value'} />}
                    {!retailPrice && (
                        <span className={classNames('price', 'value', 'not-defined')}>
                            <FormattedMessage id={'price.not_defined'} />
                        </span>
                    )}
                </span>
            )}
            {children}
        </div>
    )
}

Price.defaultProps = {
    abbreviateLabels: false,
    showRetailPrice: false,
    unitOfSale: ProductUnitType.Unit,
} as Partial<IProps>

export default memo(Price)
