import pictureAction from './type/picture'
import arrivalAction from './type/arrival'
import { managerFavoriteAction, manageFavoriteDepartmentAction } from './type/favorite'
import { IPickerActionCollection } from '../../../types/productPicker'
import { ProductsListDisplayMode, ProductsListMode } from '../../api/service/products/types'
import { IMe } from '../../api/service/me/types'
import { canExportPictures } from '../../../store/pictureExport/utils'
import { ICustomer } from '../../api/service/customers/types'
import { canManageFavoriteClassification } from '../../../store/customers/utils'
import { StrictCartMode } from '../../../store/carts/types'

export function getPickerActions(
    productListMode: ProductsListMode,
    productListDisplayMode: ProductsListDisplayMode,
    me: IMe,
    customer: ICustomer,
    cartMode?: StrictCartMode
): IPickerActionCollection {
    const actions: IPickerActionCollection = {}

    if (canExportPictures(me)) {
        actions[pictureAction.code] = pictureAction
    }

    if (productListMode === ProductsListMode.ArrivalStocks) {
        actions[arrivalAction.code] = arrivalAction
    }

    if (
        productListMode === ProductsListMode.Favorite &&
        canManageFavoriteClassification(customer, me, undefined, cartMode)
    ) {
        actions[managerFavoriteAction.code] = managerFavoriteAction
        actions[manageFavoriteDepartmentAction.code] = manageFavoriteDepartmentAction
    }

    return actions
}
