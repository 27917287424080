import React, { useEffect, useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { generatePath } from 'react-router-dom'
import Breadcrumb from '../containers/Breadcrumb/Breadcrumb'
import Confirmation from '../containers/Confirmation/Confirmation'
import { getPath } from '../routes'
import { cartsBannerHideAction } from '../store/carts/actions'

export default function ConfirmationPage(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()

    const items = useMemo(() => {
        return [
            {
                label: 'page.cart',
                href: generatePath(getPath('cart', locale), { lang: locale }),
            },
            {
                label: 'page.confirmation',
            },
        ]
    }, [locale])

    useEffect(() => {
        dispatch(cartsBannerHideAction())
    })

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.confirmation.title' })}</title>
                <meta name="description" content={formatMessage({ id: 'seo.confirmation.description' })} />
            </Helmet>
            <Breadcrumb items={items} />
            <Container className={'container-boxed'} fluid>
                <Confirmation />
            </Container>
        </>
    )
}
