import { useMediaQueries } from '@react-hook/media-query'
import classNames from 'classnames'
import initials from 'initialism'
import React, { memo, MouseEvent, ReactChild, useCallback, useState } from 'react'
import { Card, Col, Dropdown, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import truncateMiddle from 'truncate-middle'
import { getPath } from '../../routes'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { getExcludedCustomerMenuItems, getRestrictedCustomerMenuItems } from '../../store/customers/utils'
import {
    CustomerStoreCallback,
    ICustomerMenuItem,
    ICustomerMenuItemClickCallback,
    ICustomerMenuItemType,
    ICustomerMenuItemTypeCollection,
    ICustomerMenuLocation,
} from '../../types/customer'
import breakpoints from '../../utils/breakpoints'
import Button from '../Buttons/Button'
import { default as CustomerMenu } from '../Customer/Menu'
import FlatIcon from '../Icon/FlatIcon'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'

interface IProps {
    customer: ICustomer
    store?: ICustomer
    me?: IMe
    onLogoutClick: () => void
    onStoreChange?: CustomerStoreCallback
    onCustomerMenuItemClick?: ICustomerMenuItemClickCallback
}

interface IStoreButtonProps {
    account: ICustomer
    principal: boolean
    onStoreClick: (store: ICustomer, principal: boolean) => void
}

function AccountButton({ account, principal, onStoreClick }: IStoreButtonProps) {
    const handleStoreClick = useCallback(() => {
        if (onStoreClick) {
            onStoreClick(account, principal)
        }
    }, [account, principal, onStoreClick])

    // TODO: Remplacer par le composant ResumeCard
    let AddressComp = <></>
    if (account.address1 || account.postcode || account.city || account.country_name) {
        AddressComp = (
            <div className={'account-address'}>
                {account.address1 && <span className={'address-1'}>{account.address1}</span>}
                {account.address2 && <span className={'address-2'}>{account.address2}</span>}
                <span className={'city-country'}>
                    {account.postcode && <span className={'postcode'}>{account.postcode}</span>}
                    {account.postcode && account.city && ' '}
                    {account.city && <span className={'city'}>{account.city}</span>}
                    {account.country_name && (account.postcode || account.city) && ' '}
                    {account.country_name && <span className={'country-name'}>{account.country_name}</span>}
                </span>
            </div>
        )
    }

    return (
        <Button
            block
            variant={'link'}
            key={account.id}
            onClick={handleStoreClick}
            data-id={account.id}
            className={'btn-store'}
        >
            <span className={'business-name'}>
                {principal && <FormattedMessage id={'default.principal_account'} />}
                {!principal && account.business_name}
            </span>
            {AddressComp}
        </Button>
    )
}

function Account({ customer, store, onCustomerMenuItemClick, onStoreChange, me }: IProps): JSX.Element {
    const { matches } = useMediaQueries(breakpoints)
    const mobile = matches.mobile
    const [isMultiStoreView, setIsMultiStoreView] = useState<boolean>(false)
    const [dropdownShow, setDropdownShow] = useState<boolean>(false)
    const history = useHistory()
    const { locale } = useIntl()

    // eslint-disable-next-line no-useless-escape
    const letters = initials((customer.business_name || customer.code).replace(/[\[\]\(\)']+/g, ''), 3)
    const multiStoreViewAllowed =
        customer.account_type === ICustomerType.MultiStore && customer.stores && customer.stores.length > 0 && mobile
    const customerMenuExclude: ICustomerMenuItemTypeCollection | undefined = customer
        ? getExcludedCustomerMenuItems(customer, ICustomerMenuLocation.Dropdown, store, me)
        : undefined
    const customerMenuOnly: ICustomerMenuItemTypeCollection | undefined = customer
        ? getRestrictedCustomerMenuItems(customer, ICustomerMenuLocation.Dropdown, store, me)
        : undefined

    const handleCustomerMenuItemClick = useCallback(
        (e: MouseEvent, item: ICustomerMenuItem, url?: string) => {
            setDropdownShow(false)
            if (onCustomerMenuItemClick) {
                onCustomerMenuItemClick(e, item, url)
            }
        },
        [onCustomerMenuItemClick, setDropdownShow]
    )
    const handleCustomerMenuHeadingClick = useCallback(() => {
        history.push(generatePath(getPath('customerArea', locale), { lang: locale }))
        setDropdownShow(false)
    }, [locale, setDropdownShow, history])

    const handleDisplayStoreChoiceList = useCallback(() => {
        if (!multiStoreViewAllowed || isMultiStoreView) {
            setIsMultiStoreView(false)
            return
        }
        setIsMultiStoreView(true)
    }, [isMultiStoreView, multiStoreViewAllowed])
    const handleStoreClick = useCallback(
        (clickedStore: ICustomer, clickedStorePrincipal: boolean) => {
            if (onStoreChange) {
                if (clickedStorePrincipal) {
                    onStoreChange(customer)
                } else {
                    onStoreChange(customer, clickedStore, clickedStore.id)
                }
            }
        },
        [customer, onStoreChange]
    )

    const handleDropdownToggleShow = useCallback(
        (isOpen: boolean) => {
            setDropdownShow(isOpen)
        },
        [setDropdownShow]
    )
    const AccountResumeHeader = (
        <>
            <span className={'main-account'}>{customer.business_name || customer.code || '-'}</span>
            {multiStoreViewAllowed && store && <span className={'selected-store'}>{store?.business_name}</span>}
            {multiStoreViewAllowed && !store && (
                <span className={'selected-store principal'}>
                    <FormattedMessage id={'default.principal_account'} />
                </span>
            )}
            {!customer.prospect && (
                <>
                    <span className={'current-account-code'}>
                        <FormattedMessage id={'customer.code'} values={{ code: store ? store.code : customer.code }} />
                    </span>
                    <span className={'current-account-address-code'}>
                        <FormattedMessage
                            id={'customer.address_code'}
                            values={{ code: store ? store.address_code : customer.address_code }}
                        />
                    </span>
                </>
            )}
            {multiStoreViewAllowed && <FlatIcon icon={isMultiStoreView ? 'chevron-left' : 'chevron-right'} />}
        </>
    )

    const PopoverExplain: ReactChild = (
        <Popover id={`top-account-popover-helper`}>
            <Popover.Content>
                <FormattedMessage id="default.my_personal_place" />
            </Popover.Content>
        </Popover>
    )

    return (
        <Dropdown
            className={classNames('nav-item', 'account', `account-type-${customer.account_type}`)}
            show={dropdownShow}
            onToggle={handleDropdownToggleShow}
        >
            <OverlayTrigger placement={'auto'} trigger={['hover', 'focus']} overlay={PopoverExplain}>
                <Dropdown.Toggle variant={'link'} id={`account-dropdown-${mobile ? 'mobile' : 'desktop'}`}>
                    <FlatIcon icon={'user'} />
                    {customer && (
                        <span className={'lbl'}>
                            <small>{customer.business_name || customer.code || '-'}</small>
                            {store && (
                                <small className={'selected-store'}>
                                    {truncateMiddle(store?.business_name, 6, 4, '...')}
                                </small>
                            )}
                        </span>
                    )}
                    {!customer && (
                        <span className={'lbl'}>
                            <FormattedMessage id="default.account" />
                        </span>
                    )}
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu>
                <Card className={'logged-account-card'}>
                    <Card.Header>
                        <Row>
                            <Col xs={'auto'}>
                                <span className={'logged-account-preview'}>
                                    <span className={'logged-account-preview-initials'}>{letters}</span>
                                </span>
                            </Col>
                            <Col>
                                {multiStoreViewAllowed && mobile && (
                                    <Button
                                        block
                                        variant={'link'}
                                        onClick={handleDisplayStoreChoiceList}
                                        className={classNames({ enabled: multiStoreViewAllowed })}
                                    >
                                        {AccountResumeHeader}
                                    </Button>
                                )}
                                {(!multiStoreViewAllowed || !mobile) && AccountResumeHeader}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <div className={'logged-account-body-inner'}>
                            <div
                                className={classNames('logged-account-part-list', {
                                    'part-active-multi-store': isMultiStoreView,
                                    'part-active-account': !isMultiStoreView,
                                })}
                            >
                                <div className={'logged-account-part-list-inner'}>
                                    <div className={'logged-account-part logged-account-part-account'}>
                                        <div className={classNames('logged-account-menu')}>
                                            <p
                                                className={'logged-account-part-heading'}
                                                onClick={handleCustomerMenuHeadingClick}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <FormattedMessage
                                                    id={
                                                        isSalesmanResource(me)
                                                            ? 'default.customer_area'
                                                            : 'default.my_personal_place'
                                                    }
                                                />
                                            </p>
                                            <CustomerMenu
                                                me={me}
                                                customer={customer}
                                                onItemClick={handleCustomerMenuItemClick}
                                                exclude={customerMenuExclude}
                                                only={customerMenuOnly}
                                            />
                                        </div>
                                    </div>
                                    {multiStoreViewAllowed && (
                                        <div className={'logged-account-part logged-account-part-multi-store'}>
                                            <div className={'multi-store-section'}>
                                                <p className={'logged-account-part-heading'}>
                                                    <FormattedMessage id={'default.stores'} />
                                                </p>
                                                <div className={'multi-store-list'}>
                                                    {store && (
                                                        <div className={'multi-store-row'}>
                                                            <AccountButton
                                                                account={customer}
                                                                principal
                                                                onStoreClick={handleStoreClick}
                                                            />
                                                        </div>
                                                    )}
                                                    {customer.stores!.map((store: ICustomer) => {
                                                        return (
                                                            <div className={'multi-store-row'} key={store.id}>
                                                                <AccountButton
                                                                    account={store}
                                                                    onStoreClick={handleStoreClick}
                                                                    principal={false}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <CustomerMenu
                            onItemClick={onCustomerMenuItemClick}
                            only={[ICustomerMenuItemType.Logout]}
                            me={me}
                            customer={customer}
                        />
                    </Card.Footer>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    )
}

Account.defaultProps = {
    mobile: false,
} as Partial<IProps>

export default memo(Account)
