import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiShopImportCartCreateResponse,
    IApiShopImportCartListResponse,
    IApiShopImportCartResponse,
    IShopImportCartListParameters,
    IShopImportCartService,
} from './types'

const create = (instance: AxiosInstance): IShopImportCartService => {
    /*****************************
     * METHODS
     *****************************/
    const create = (
        formData: FormData,
        onUploadProgress?: (progressEvent: any) => void,
        cancelToken?: CancelToken,
        headers?: any
    ): Promise<IApiShopImportCartCreateResponse> => {
        return instance.post('/cart-imports', formData, { onUploadProgress, cancelToken, headers })
    }

    const list = (
        params: IShopImportCartListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiShopImportCartListResponse> => {
        return instance.get('/cart-imports', { params: params, cancelToken })
    }

    const get = (shopImportCartId: string, cancelToken?: CancelToken): Promise<IApiShopImportCartResponse> => {
        return instance.get(`${shopImportCartId}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        create,
        list,
        get,
    }
}

export default {
    create,
}
