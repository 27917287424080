/**
 *
 * Product Card
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, ReactChild } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

interface IProps {
    productId: string
    date?: string | null
    showLabel?: boolean
    popover?: boolean
    className?: string
}

function AlreadyOrdered({ productId, date, showLabel, className, popover }: IProps): JSX.Element {
    const { formatDate } = useIntl()

    const PopoverAlreadyOrdered: ReactChild = (
        <Popover id={`popover-already-ordered-${productId}`}>
            <Popover.Content>
                {typeof date !== 'string' && <FormattedMessage id={'products.already_ordered'} />}
                {typeof date === 'string' && (
                    <FormattedMessage id={'products.already_ordered_with_date'} values={{ date: formatDate(date) }} />
                )}
            </Popover.Content>
        </Popover>
    )

    if (!popover) {
        return (
            <div className={classNames('product-already-ordered', className)}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} className={'app-icon'} />
                {showLabel && ' '}
                {showLabel && !date && <FormattedMessage id={'products.already_ordered'} />}
                {showLabel && date && (
                    <FormattedMessage id={'products.already_ordered_with_date'} values={{ date: formatDate(date) }} />
                )}
            </div>
        )
    }
    return (
        <div className={classNames('product-already-ordered', className)}>
            <OverlayTrigger placement={'auto'} trigger={['hover', 'focus']} overlay={PopoverAlreadyOrdered}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} className={'app-icon'} />
            </OverlayTrigger>
        </div>
    )
}

AlreadyOrdered.defaultProps = {
    showLabel: false,
    popover: true,
    date: null,
} as Partial<IProps>

export default memo(AlreadyOrdered)
