import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { default as ShippingLocationComponent } from '../../components/Classification/ShippingLocation'
import { IShippingLocation } from '../../services/api/service/classification/types'
import { IApplicationRootState } from '../../store'
import { Value as ClassValue } from 'classnames'
// LIBRARIES
import { makeSelectShippingLocationById } from '../../store/classification/selectors'

interface IProps {
    className?: ClassValue
    labelId?: string
    shippingLocationId: string
    showLabel?: boolean
    showIcon?: boolean
}

function ShippingLocation({ labelId, shippingLocationId, showIcon, showLabel, className }: IProps): JSX.Element {
    const selectShippingLocationWithId = useMemo(makeSelectShippingLocationById, [])
    const shippingLocation: IShippingLocation | null = useSelector<IApplicationRootState, IShippingLocation | null>(
        (state) => selectShippingLocationWithId(state, shippingLocationId)
    )
    return (
        <>
            {shippingLocation && (
                <ShippingLocationComponent
                    className={className}
                    shippingLocation={shippingLocation}
                    showIcon={showIcon}
                    showLabel={showLabel}
                    labelId={labelId}
                />
            )}
        </>
    )
}

ShippingLocation.defaultProps = {
    labelId: 'product.logistic_location',
    showLabel: false,
    showIcon: false,
} as Partial<IProps>

export default ShippingLocation
