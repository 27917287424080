/*
 *
 * Products
 *
 */

import classNames from 'classnames'
import React, { memo } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

function Placeholder(): JSX.Element {
    return (
        <div className={'order-detail order-detail-placeholder'}>
            <Row>
                <Col>
                    <Card className={classNames('member-card', 'resume')}>
                        <Card.Header>
                            <h3 className={'hv'}>
                                <FormattedMessage id={'order.single'} />
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <Table striped hover responsive>
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormattedMessage id={'orders.date'} />
                                        </td>
                                        <td>
                                            <TextBlock rows={1} color={'gray'} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage id={'orders.state'} />
                                        </td>
                                        <td>
                                            <TextBlock rows={1} color={'gray'} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage id={'order.detail.requested_delivery_date'} />
                                        </td>
                                        <td>
                                            <TextBlock rows={1} color={'gray'} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage id={'order.detail.comment'} />
                                        </td>
                                        <td>
                                            <TextBlock rows={1} color={'gray'} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={10}>
                    <Card className={classNames('member-card', 'actions')}>
                        <Card.Header>
                            <h3 className={'hv'}>
                                <FormattedMessage id={'order.detail.actions'} />
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <RectShape className={'btn-fake'} />
                            <RectShape className={'btn-fake'} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default memo(Placeholder)
