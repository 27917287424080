import React from 'react'
import { useParams } from 'react-router-dom'
import OrderDetail from '../containers/OrderDetail/OrderDetail'

export default function CustomerOrderPage(): JSX.Element {
    const { orderId } = useParams<{ orderId: string }>()

    return (
        <>
            <OrderDetail orderId={orderId} />
        </>
    )
}
