import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductsPage from './ProductsPage'
import PageLoader from '../components/Loader/PageLoader'
import { ProductsListMode } from '../services/api/service/products/types'
import { classificationFamilyTreeProcessAction } from '../store/classification/actions'
import { makeSelectClassificationFamilyTreeByMode } from '../store/classification/selectors'
import { Undefinable } from 'tsdef'
import { IFamilyTreeCollection } from '../services/api/service/classification/types'
import { IApplicationRootState } from '../store'

interface IProps {
    mode: ProductsListMode
}

export default function ProductsLoaderPage({ mode }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const familyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category) {
            return selectFamilyTreeWithMode(state, mode)
        }
        return undefined
    })

    useEffect(() => {
        if (mode !== ProductsListMode.Category && typeof familyTree === 'undefined') {
            dispatch(classificationFamilyTreeProcessAction(mode))
        }
    }, [dispatch, mode, familyTree])

    if (typeof familyTree !== 'undefined') {
        return <ProductsPage mode={mode} />
    }

    return <PageLoader />
}
