import { AxiosInstance, AxiosResponse } from 'axios'
import {
    IApiFavoriteEditDepartmentResponse,
    IApiFavoriteListDepartmentResponse,
    IFavoriteDepartmentAssociateData,
    IFavoriteDepartmentEditData,
    IFavoriteService,
} from './types'

const create = (instance: AxiosInstance): IFavoriteService => {
    /*****************************
     * METHODS
     *****************************/
    const add = (productId: string): Promise<AxiosResponse> => {
        const paths = productId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.post(`/favorite-products`, { product: `/products/${identifier}` })
    }
    const remove = (productId: string): Promise<AxiosResponse> => {
        const paths = productId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.delete(`/favorite-products/${identifier}`)
    }

    const bulkRemove = (products: Array<string>): Promise<AxiosResponse> => {
        return instance.delete(`/favorite-products/bulk`, {
            data: {
                bulk: {
                    products,
                },
            },
        })
    }
    const createDepartment = (data: IFavoriteDepartmentEditData): Promise<IApiFavoriteEditDepartmentResponse> => {
        return instance.post(`/favorite-departments`, data)
    }
    const editDepartment = (
        departmentId: string,
        data: IFavoriteDepartmentEditData
    ): Promise<IApiFavoriteEditDepartmentResponse> => {
        const paths = departmentId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.put(`/favorite-departments/${identifier}`, data)
    }
    const removeDepartment = (productId: string): Promise<AxiosResponse> => {
        const paths = productId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.delete(`/favorite-departments/${identifier}`)
    }
    const listDepartment = (): Promise<IApiFavoriteListDepartmentResponse> => {
        return instance.get(`/favorite-departments`)
    }
    const associateDepartment = (data: IFavoriteDepartmentAssociateData): Promise<AxiosResponse> => {
        return instance.post(`/favorite-products/bulk`, {
            bulk: data,
        })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        add,
        remove,
        createDepartment,
        editDepartment,
        removeDepartment,
        listDepartment,
        associateDepartment,
        bulkRemove,
    }
}

export default {
    create,
}
