/**
 *
 * ProductCarouselTabbed
 *
 */
import React, { memo, MouseEvent } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { ICmsWidgetLeaderBoardSlide } from '../../../../services/api/service/cms/types'
import { IComponentLazyProps } from '../../../../types/common'
import { LeaderboardSlideClickCallback } from '../../../../types/leaderboard'

interface IProps extends IComponentLazyProps {
    slide: ICmsWidgetLeaderBoardSlide
    onClick?: LeaderboardSlideClickCallback
}

const slidePlaceholder = (
    <div className="my-awesome-placeholder">
        <RectShape color={'gray'} className={'show-loading-animation'} style={{ width: 1200, height: 441 }} />
    </div>
)

function Slide({ ready, slide, onClick }: IProps): JSX.Element {
    const handleOnClick = (evt: MouseEvent) => {
        evt.preventDefault()
        if (onClick) {
            onClick(slide)
        }
    }

    return (
        <div className={'app-slider-slide leaderboard-slide'}>
            <ReactPlaceholder ready={ready} customPlaceholder={slidePlaceholder}>
                <button onClick={handleOnClick}>
                    <img src={slide.image.default} alt={slide.title} className={'swiper-lazy'} />
                </button>
            </ReactPlaceholder>
        </div>
    )
}

Slide.defaultProps = {
    ready: false,
} as Partial<IProps>

export default memo(Slide)
