/*
 *
 * Product constants
 *
 */

enum ActionTypes {
    LOAD_ACTION = 'app/Product/LOAD',
    LOADED_ACTION = 'app/Product/LOADED',
    LOAD_FAIL_ACTION = 'app/Product/LOAD_FAIL',
    RESET_ACTION = 'app/Product/RESET',
    DETAIL_PROCESS_ACTION = 'app/Product/Detail/REQUEST_PROCESS',
    DETAIL_SUCCESS_ACTION = 'app/Product/Detail/REQUEST_SUCCESS',
    DETAIL_FAILURE_ACTION = 'app/Product/Detail/REQUEST_FAILURE',
    DETAIL_RESET_ACTION = 'app/Product/Detail/RESET',
    CROSS_SELLING_RESET_ACTION = 'app/Product/CrossSelling/RESET',
    CROSS_SELLING_PROCESS_ACTION = 'app/Product/CrossSelling/REQUEST_PROCESS',
    CROSS_SELLING_SUCCESS_ACTION = 'app/Product/CrossSelling/REQUEST_SUCCESS',
    CROSS_SELLING_FAILURE_ACTION = 'app/Product/CrossSelling/REQUEST_FAILURE',
    GROUP_ATTRIBUTE_PROCESS_ACTION = 'app/Product/GroupAttributes/REQUEST_PROCESS',
    GROUP_ATTRIBUTE_SUCCESS_ACTION = 'app/Product/GroupAttributes/REQUEST_SUCCESS',
    GROUP_ATTRIBUTE_FAILURE_ACTION = 'app/Product/GroupAttributes/REQUEST_FAILURE',
    GROUP_ATTRIBUTE_RESET_ACTION = 'app/Product/GroupAttributes/RESET',
    COLLECTION_PROCESS_ACTION = 'app/Product/Collection/REQUEST_PROCESS',
    COLLECTION_SUCCESS_ACTION = 'app/Product/Collection/REQUEST_SUCCESS',
    COLLECTION_FAILURE_ACTION = 'app/Product/Collection/REQUEST_FAILURE',
    COLLECTION_RESET_ACTION = 'app/Product/Collection/RESET',
    ADD_TO_FAVORITE_ACTION = 'app/Product/Favorite/ADD',
    REMOVE_TO_FAVORITE_ACTION = 'app/Product/Favorite/REMOVE',
}

export default ActionTypes
