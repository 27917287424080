import { AxiosInstance, CancelToken } from 'axios'
import { IApiStockAlertResponse, IStockAlertService } from './types'

const create = (instance: AxiosInstance): IStockAlertService => {
    /*****************************
     * METHODS
     *****************************/
    const addStockAlert = (productId: string, cancelToken?: CancelToken): Promise<IApiStockAlertResponse> => {
        return instance.put(`/stock-alerts/${productId}`, { active: true }, { cancelToken })
    }
    const removeStockAlert = (productId: string, cancelToken?: CancelToken): Promise<IApiStockAlertResponse> => {
        return instance.put(`/stock-alerts/${productId}`, { active: false }, { cancelToken })
    }
    const addBulkStockAlert = (productIds: Array<string>, cancelToken?: CancelToken): Promise<any> => {
        return instance.post(`/stock-alerts/bulk`, { bulk_products: productIds, active: true }, { cancelToken })
    }
    const removeBulkStockAlert = (productIds: Array<string>, cancelToken?: CancelToken): Promise<any> => {
        return instance.delete(`/stock-alerts/bulk`, { cancelToken, data: { bulk_products: productIds } })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        addStockAlert,
        removeStockAlert,
        addBulkStockAlert,
        removeBulkStockAlert,
    }
}

export default {
    create,
}
