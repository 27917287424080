export type PaginationCallback = (page: number) => void

export enum PaginationQueryName {
    Page = 'page',
    ItemsPerPage = 'itemsPerPage',
    Search = 'search',
}

export interface IPaginationQueryParameters {
    page: number
    itemsPerPage: number
    search?: string
}
