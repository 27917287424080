import { Location } from 'history'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { generatePath, Redirect, Route } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { ICustomer } from '../services/api/service/customers/types'
import { IApplicationRootState } from '../store'
import { makeSelectCustomer, makeSelectCustomerStore } from '../store/customers/selectors'
import { getPath } from './index'
import { VoterFunc, VoterReturnType } from './voters'
import { makeSelectAuthLoggedIn, makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { makeSelectCartMode } from '../store/carts/selectors'
import { StrictCartMode } from '../store/carts/types'
import { makeSelectAppReload } from '../store/app/selectors'

interface IProps {
    exact?: boolean
    path: string
    component: React.ComponentType<any>
    componentProps?: any
    voter?: VoterFunc
    location?: Location
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
    cartMode: makeSelectCartMode(),
    loggedIn: makeSelectAuthLoggedIn(),
    reloading: makeSelectAppReload(),
})

function LoggedInRoute({ component: Component, voter, componentProps, location, ...rest }: IProps): JSX.Element {
    const { locale } = useIntl()
    const { customer, store, loggedIn, me, cartMode, reloading } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store: ICustomer
            loggedIn: boolean
            me?: IMe
            cartMode: StrictCartMode
            reloading: boolean
        }
    >(stateSelector)

    if (reloading) {
        return <></>
    }

    return (
        <>
            <Route
                {...rest}
                render={(props): JSX.Element => {
                    if (!loggedIn) {
                        return (
                            <Redirect
                                to={{
                                    pathname: generatePath(getPath('login', locale), { lang: locale }),
                                    state: {
                                        from: location,
                                    },
                                }}
                            />
                        )
                    }

                    if (typeof voter === 'function') {
                        const res: VoterReturnType = voter(customer, store, me, cartMode)
                        if (res === false) {
                            return (
                                <Redirect
                                    to={{
                                        pathname: generatePath(getPath('customerArea', locale), { lang: locale }),
                                    }}
                                />
                            )
                        } else if (res !== true) {
                            let routerParams = { lang: locale }
                            if (res.params) {
                                routerParams = { ...res.params }
                            }

                            return (
                                <Redirect
                                    to={{
                                        pathname: generatePath(getPath(res.route, locale), routerParams),
                                    }}
                                />
                            )
                        }
                    }

                    return <Component {...componentProps} {...props} />
                }}
            />
        </>
    )
}

// LoggedInRoute.defaultProps = {
// } as Partial<IProps>

export default LoggedInRoute
