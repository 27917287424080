/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { IProductAttributeValue } from '../../../services/api/service/products/types'

interface IProps {
    brand: IProductAttributeValue
    className?: string
}

function Brand({ brand, className }: IProps): JSX.Element {
    return (
        <div className={classNames('product-brand-section', className)}>
            {brand.image && (
                <img
                    className={classNames('product-brand', 'product-brand-image')}
                    src={brand.image}
                    alt={brand.label || undefined}
                />
            )}
            {!brand.image && <h2 className={classNames('product-brand', 'product-brand-text')}>{brand.label}</h2>}
        </div>
    )
}

Brand.defaultProps = {} as Partial<IProps>

export default memo(Brand)
