/*
 *
 * Classification reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'

export const initialState: ContainerState = {
    tree: {},
    menu: [],
    attributes: [],
    category: {
        fetching: false,
    },
    favoriteDepartments: {
        associate: {
            fetching: false,
        },
        edit: {
            fetching: false,
        },
        delete: {
            fetching: false,
        },
        list: {
            fetching: false,
        },
    },
}

function classificationReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_CLASSIFICATION_ACTION:
            return initialState
        case ActionTypes.STORE_ATTRIBUTE_ACTION:
            return { ...state, attributes: action.payload }
        case ActionTypes.STORE_MENU_ACTION:
            return { ...state, menu: action.payload }
        case ActionTypes.CATEGORY_RESET_ACTION:
            return { ...state, category: { fetching: false } }
        case ActionTypes.CATEGORY_PROCESS_ACTION:
            return {
                ...state,
                category: {
                    ...state.category,
                    tms: Date.now(),
                    fetching: true,
                },
            }
        case ActionTypes.CATEGORY_FAILURE_ACTION:
            return {
                ...state,
                category: {
                    ...state.category,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                    detail: undefined,
                },
            }
        case ActionTypes.CATEGORY_SUCCESS_ACTION:
            return {
                ...state,
                category: {
                    ...state.category,
                    detail: action.payload.response.data,
                    error: undefined,
                },
            }
        case ActionTypes.FAMILY_TREE_PROCESS_ACTION:
            return {
                ...state,
                tree: {
                    ...state.tree,
                    [action.payload.mode]: {
                        ...(state.tree[action.payload.mode] || {}),
                        tms: Date.now(),
                        fetching: true,
                    },
                },
            }
        case ActionTypes.FAMILY_TREE_SUCCESS_ACTION:
            return {
                ...state,
                tree: {
                    ...state.tree,
                    [action.payload.mode]: {
                        ...(state.tree[action.payload.mode] || {}),
                        tms: Date.now(),
                        fetching: false,
                        error: undefined,
                        tree: action.payload.tree,
                    },
                },
            }
        case ActionTypes.FAMILY_TREE_FAILURE_ACTION:
            return {
                ...state,
                tree: {
                    ...state.tree,
                    [action.payload.mode]: {
                        ...(state.tree[action.payload.mode] || {}),
                        tms: Date.now(),
                        fetching: false,
                        error: action.payload.error,
                        tree: undefined,
                    },
                },
            }
        case ActionTypes.FAMILY_TREE_RESET_ACTION:
            if (!action.payload.mode) {
                return {
                    ...state,
                    tree: {},
                }
            }

            return {
                ...state,
                tree: {
                    [action.payload.mode]: {
                        fetching: false,
                    },
                },
            }
        case ActionTypes.FAVORITE_DEPARTMENT_LIST_PROCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...initialState.favoriteDepartments.list,
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_LIST_SUCCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...state.favoriteDepartments.list,
                        items: action.payload.items,
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_LIST_FAILURE_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...state.favoriteDepartments.list,
                        error: action.payload.error,
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_LIST_RESET_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...initialState.favoriteDepartments.list,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_EDIT_PROCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    edit: {
                        ...initialState.favoriteDepartments.edit,
                        data: action.payload.data,
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_EDIT_SUCCESS_ACTION: {
            const { item: currentItem } = action.payload
            let items = [...(state.favoriteDepartments.list.items || [])]
            const idx = items.findIndex((item) => item['@id'] === currentItem['@id'])
            if (idx === -1 || items.length === 0) {
                items.push(currentItem)
            } else {
                items = items
                    .slice(0, idx)
                    .concat([currentItem])
                    .concat(state.favoriteDepartments.list.items!.slice(idx + 1))
            }

            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...state.favoriteDepartments.list,
                        items,
                    },
                    edit: {
                        ...initialState.favoriteDepartments.edit,
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_EDIT_FAILURE_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    edit: {
                        ...initialState.favoriteDepartments.edit,
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_EDIT_RESET_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    edit: {
                        ...initialState.favoriteDepartments.edit,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_PROCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    associate: {
                        ...initialState.favoriteDepartments.associate,
                        fetching: true,
                        data: action.payload.data,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_SUCCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    associate: {
                        ...state.favoriteDepartments.associate,
                        fetching: false,
                        success: true,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_FAILURE_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    associate: {
                        ...initialState.favoriteDepartments.associate,
                        fetching: false,
                        error: action.payload.error,
                        success: false,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_RESET_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    associate: {
                        ...initialState.favoriteDepartments.associate,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_DELETE_PROCESS_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    delete: {
                        ...initialState.favoriteDepartments.delete,
                        identifier: action.payload.identifier,
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_DELETE_SUCCESS_ACTION: {
            const items = [...(state.favoriteDepartments.list.items || [])].filter(
                (item) => item['@id'] !== state.favoriteDepartments.delete.identifier
            )

            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    list: {
                        ...state.favoriteDepartments.list,
                        items,
                    },
                    delete: {
                        ...initialState.favoriteDepartments.delete,
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_DELETE_FAILURE_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    delete: {
                        ...initialState.favoriteDepartments.delete,
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.FAVORITE_DEPARTMENT_DELETE_RESET_ACTION: {
            return {
                ...state,
                favoriteDepartments: {
                    ...state.favoriteDepartments,
                    delete: {
                        ...initialState.favoriteDepartments.delete,
                    },
                },
            }
        }
        default:
            return state
    }
}

export default classificationReducer
