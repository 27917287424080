/**
 *
 * Icon
 *
 */
import classNames from 'classnames'
import React, { CSSProperties, memo } from 'react'

interface IProps {
    icon: string
    base: string
    prefix: string
    className?: string
    style?: CSSProperties
}

function FlatIcon(props: IProps): JSX.Element {
    return (
        <>
            <i
                className={classNames(`app-icon ${props.base} ${props.prefix}-${props.icon}`, props.className)}
                style={props.style}
            />
        </>
    )
}

FlatIcon.defaultProps = {
    base: 'fi',
    prefix: 'flaticon',
    pulse: false,
    inverse: false,
} as Partial<IProps>

export default memo(FlatIcon)
