/**
 *
 * CmsWidgetFactory
 *
 */
import React, { memo } from 'react'
import { InView } from 'react-intersection-observer'
import {
    CmsWidgetType,
    IBaseCmsWidget,
    ICmsWidgetDivider,
    ICmsWidgetHighlightCategory,
    ICmsWidgetHighlightCollection,
    ICmsWidgetInspiration,
    ICmsWidgetInteractiveSlider,
    ICmsWidgetLeaderBoard,
    ICmsWidgetReinsurance,
    ICmsWidgetSimpleHighlight,
    ICmsWidgetVideo,
    ICmsWidgetWysiwyg,
} from '../../services/api/service/cms/types'
import { CmsWidgetCallbackList } from '../../types/widget'
import { default as CollectionCarousel } from './Type/Collection/Carousel/Carousel'
import Divider from './Type/Divider/Divider'
import { default as InspirationCarousel } from './Type/Inspiration/Carousel'
import Leaderboard from './Type/Leaderboard/Leaderboard'
import TabbedCarousel from '../../containers/CmsWidget/Type/Product/TabbedCarousel'
import Reinsurance from './Type/Reinsurance/Reinsurance'
import SimpleHighlight from './Type/SimpleHighlight/SimpleHighlight'
import Wysiwyg from './Type/Wysiwyg/Wysiwyg'
import Video from './Type/Video/Video'
import InteractiveSlider from '../../containers/CmsWidget/Type/InteractiveSlider/Slider'

type IProps = CmsWidgetCallbackList & {
    widget: IBaseCmsWidget
}

function WidgetFactory({
    widget,
    onCarouselProductClick,
    onCmsWidgetLinkClick,
    onLeaderBoardSlideClick,
    onInspirationItemClick,
    onCollectionItemClick,
    onInteractiveSliderClick,
}: IProps): JSX.Element {
    switch (widget.widget) {
        case CmsWidgetType.Divider:
            return <Divider widget={widget as ICmsWidgetDivider} />
        case CmsWidgetType.LeaderBoard:
            return (
                <Leaderboard
                    widget={widget as ICmsWidgetLeaderBoard}
                    onSlideClick={onLeaderBoardSlideClick}
                    onLinkClick={onCmsWidgetLinkClick}
                />
            )
        case CmsWidgetType.InteractiveSlide:
            return (
                <InteractiveSlider
                    widget={widget as ICmsWidgetInteractiveSlider}
                    onSlideClick={onInteractiveSliderClick}
                    onLinkClick={onCmsWidgetLinkClick}
                />
            )
        case CmsWidgetType.HighlightCategory:
            return (
                <TabbedCarousel
                    widget={widget as ICmsWidgetHighlightCategory}
                    onProductClick={onCarouselProductClick}
                    onLinkClick={onCmsWidgetLinkClick}
                />
            )
        case CmsWidgetType.Inspiration:
            return (
                <InspirationCarousel
                    widget={widget as ICmsWidgetInspiration}
                    onItemClick={onInspirationItemClick}
                    onLinkClick={onCmsWidgetLinkClick}
                />
            )
        case CmsWidgetType.HighlightCollection:
            return (
                <CollectionCarousel
                    widget={widget as ICmsWidgetHighlightCollection}
                    onCollectionClick={onCollectionItemClick}
                    onLinkClick={onCmsWidgetLinkClick}
                />
            )
        case CmsWidgetType.SimpleHighlight:
            return <SimpleHighlight widget={widget as ICmsWidgetSimpleHighlight} onLinkClick={onCmsWidgetLinkClick} />
        case CmsWidgetType.Reinsurance:
            return <Reinsurance widget={widget as ICmsWidgetReinsurance} />
        case CmsWidgetType.Wysiwyg:
            return <Wysiwyg widget={widget as ICmsWidgetWysiwyg} onLinkClick={onCmsWidgetLinkClick} />
        case CmsWidgetType.Video:
            return <Video widget={widget as ICmsWidgetVideo} onLinkClick={onCmsWidgetLinkClick} />
        default:
            return <></>
    }
}

interface ICmsWidgetFactoryProps extends IProps {
    lazy?: boolean
    threshold?: number
}

function CmsWidgetFactory({
    lazy = true,
    threshold = 0.1,
    onVisibilityChangeCallback,
    ...rest
}: ICmsWidgetFactoryProps): JSX.Element {
    if (!lazy) {
        return <WidgetFactory {...rest} />
    }

    return (
        <InView onChange={onVisibilityChangeCallback} threshold={threshold} triggerOnce>
            <WidgetFactory {...rest} />
        </InView>
    )
}

export default memo(CmsWidgetFactory)
