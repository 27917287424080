/**
 *
 * ListItem
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Nav } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import ItemIcon from '../ItemIcon'
import { LinkItemCallbacks } from '../type'

type IProps = LinkItemCallbacks & {
    item: any
    itemType?: string
    className?: string
    subItems?: Array<any>
    showSubItems?: boolean
    basePath?: string
}

function LinkItem({
    basePath,
    item,
    itemType,
    subItems,
    showSubItems,
    className,
    onClick,
    onMouseLeave,
    onMouseEnter,
}: IProps): JSX.Element {
    const [entered, setEntered] = useState<boolean>(false)
    const location = useLocation()
    const [active, setActive] = useState<boolean>(false)

    const handleOnclick = useCallback(
        (e: MouseEvent): void => {
            setEntered(false)
            if (!item.url) {
                e.stopPropagation()
                e.preventDefault()
                return
            }
            if (onClick) {
                onClick(e, item, itemType)
            }
        },
        [item, onClick, itemType]
    )

    const handleOnSubClick = useCallback(
        (e: React.MouseEvent, subItem: any, subItemType?: string) => {
            setEntered(false)
            if (!subItem.url) {
                e.stopPropagation()
                e.preventDefault()
                return
            }
            if (onClick) {
                onClick(e, subItem, subItemType)
            }
        },
        [onClick]
    )

    const handleMouseEnter = useCallback(
        (e: React.MouseEvent) => {
            setEntered(true)
            if (onMouseEnter) {
                onMouseEnter(e, item, itemType)
            }
        },
        [onMouseEnter, item, itemType]
    )

    const handleMouseLeave = useCallback(
        (e: React.MouseEvent) => {
            setEntered(false)
            if (onMouseLeave) {
                onMouseLeave(e, item, itemType)
            }
        },
        [onMouseLeave, item, itemType]
    )

    const itemBadge = useMemo(() => {
        return item.badge || null
    }, [item])

    const linkUrl = useMemo(() => {
        return item.url ? `${basePath || ''}${item.url}` : '#'
    }, [basePath, item])

    const noUrl = useMemo(() => {
        return typeof item.url === 'undefined' || (typeof item.url === 'string' && item.url.length === 0)
    }, [item])

    useEffect(() => {
        setActive(noUrl ? false : location.pathname.indexOf(item.url) > -1)
    }, [location.pathname, item, noUrl])

    return (
        <>
            <Nav.Item
                className={classNames(
                    className,
                    `nav-item-${itemType || item.type || 'default'}`,
                    { 'has-children': subItems && subItems.length > 0 },
                    { 'no-url': noUrl },
                    { 'with-badge': typeof itemBadge === 'string' },
                    { open: entered, active }
                )}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Nav.Link as={NavLink} to={linkUrl} className={classNames({ 'no-url': noUrl })} onClick={handleOnclick}>
                    {itemBadge && (
                        <Badge pill variant="primary">
                            {itemBadge}
                        </Badge>
                    )}
                    <ItemIcon type={itemType || item.type} />
                    <span>{item.label || item.name}</span>
                </Nav.Link>
                {showSubItems && subItems && subItems.length > 0 && (
                    <div className={'link-item-children'}>
                        {subItems.map((subItem: any) => {
                            const children = subItem.children || subItem.sub_families
                            return (
                                <LinkItem
                                    key={`${item.type}_${item['@id']}_${subItem.type}_${subItem['@id']}`}
                                    item={subItem}
                                    subItems={children}
                                    onClick={handleOnSubClick}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                />
                            )
                        })}
                    </div>
                )}
            </Nav.Item>
        </>
    )
}

LinkItem.defaultProps = {
    mobile: false,
    noChildren: false,
    prefixKey: '',
    showSubItems: true,
    basePath: '',
} as Partial<IProps>

export default memo(LinkItem)
