import React, { MouseEvent, useCallback, useMemo } from 'react'
import Table, { ITableProps } from '../../../components/Table/Table'
import { Cell, Column, TableState } from 'react-table'
import { IOrderDocumentType, IOrderList, IOrderType, OrderStates } from '../../../services/api/service/orders/types'
import Price from '../../../components/Price/Price'
import { FormattedDate, useIntl } from 'react-intl'
import OrderState from '../../../components/Order/OrderState'
import { generatePath, useHistory } from 'react-router-dom'
import { getPath } from '../../../routes'
import DocumentButton from '../../OrderDetail/Action/Document'
import ViewButton from '../../OrderDetail/Action/View'
import { Form } from 'react-bootstrap'
import { canShowAllActivitySelector, canViewAllCustomers } from '../../../store/salesmens/utils'
import { IMe } from '../../../services/api/service/me/types'
import ResumeCard from '../../../components/Customer/ResumeCard'
import DateRangeFilter from '../../../components/Table/Filter/DateRangeFilter'
import StateFilter from './Filter/StateFilter'

type Props<T extends object = IOrderList> = Pick<
    ITableProps<T>,
    'fetchError' | 'fetching' | 'fetchData' | 'totalItems' | 'data'
> & {
    me?: IMe
    pageSize: number
    paginate?: boolean
    detailRouteName?: string
    type: IOrderType
}

export default function OrderList<T extends IOrderList = IOrderList>({
    pageSize,
    paginate = true,
    detailRouteName = 'salesmanOrder',
    type = IOrderType.Order,
    me,
    ...rest
}: Props<T>): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const history = useHistory()

    const initialState: Partial<TableState<IOrderList>> = useMemo(() => {
        return { pageIndex: 0, pageSize, hiddenColumns: type === IOrderType.Asset ? ['state'] : [] }
    }, [pageSize, type])

    const redirectToOrderDetail = useCallback(
        (item: T) => {
            const orderDetailUrl = generatePath(getPath(detailRouteName, locale), {
                lang: locale,
                orderId: item.id,
            })
            history.push(orderDetailUrl)
        },
        [history, locale, detailRouteName]
    )

    const handleCellClick = useCallback(
        (event: MouseEvent<HTMLElement>, item: T, cell: Cell<T>) => {
            if (cell.column.clickable) {
                redirectToOrderDetail(item)
            }
        },
        [redirectToOrderDetail]
    )

    const columns: Array<Column<T>> = useMemo(() => {
        return [
            {
                Header: 'all_customers',
                defaultCanFilter: canViewAllCustomers(me),
                filterOnly: true,
                Filter: ({ column: { filterValue, setFilter, id } }) => {
                    return (
                        <Form.Check
                            type={'switch'}
                            id={id}
                            checked={filterValue !== 1}
                            label={formatMessage({ id: 'salesman.my_portfolio_only' })}
                            onChange={() => {
                                setFilter(filterValue === 1 ? 0 : 1)
                            }}
                            value={0}
                        />
                    )
                },
            },
            {
                Header: 'all_activity',
                defaultCanFilter: canShowAllActivitySelector(me),
                filterOnly: true,
                Filter: ({ column: { filterValue, setFilter, id } }) => {
                    return (
                        <Form.Check
                            type={'switch'}
                            id={id}
                            checked={filterValue !== 1}
                            label={formatMessage({ id: 'salesman.my_activity_only' })}
                            onChange={() => {
                                setFilter(!filterValue ? 1 : 0)
                            }}
                            value={0}
                        />
                    )
                },
            },
            {
                Header: formatMessage({ id: 'orders.number' }),
                accessor: 'number',
                className: 'order-number',
                clickable: true,
                defaultCanSort: false,
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.customer' }),
                clickable: true,
                accessor: 'customer',
                defaultCanFilter: true,
                Cell: ({ value }) => {
                    return <ResumeCard showStoreCode showAddress={false} customer={value} />
                },
            },
            {
                Header: formatMessage({ id: 'orders.date' }),
                Filter: DateRangeFilter,
                accessor: 'date',
                clickable: true,
                defaultCanSort: true,
                defaultCanFilter: true,
                Cell: ({ value }) => {
                    return <FormattedDate value={value} month={'long'} day={'numeric'} year={'numeric'} />
                },
            },
            {
                Header: formatMessage({ id: 'orders.state' }),
                Filter: StateFilter,
                accessor: 'state',
                clickable: true,
                defaultCanSort: false,
                defaultCanFilter: true,
                Cell: ({ value }) => {
                    return <OrderState state={value} />
                },
            },
            {
                Header: formatMessage({ id: 'orders.total_excluding_taxes' }),
                clickable: true,
                accessor: 'total',
                Cell: ({ value }) => {
                    return <Price price={value} />
                },
            },
            {
                id: 'actions',
                accessor: 'id',
                className: 'actions',
                hidden: type === IOrderType.Asset,
                Cell: ({ data, cell }) => {
                    const order = data[cell.row.id]

                    return (
                        <>
                            <DocumentButton
                                orderId={order.id}
                                orderNumber={order.number}
                                type={IOrderDocumentType.PurchaseOrderPdf}
                                label={null}
                            />
                            <DocumentButton
                                orderId={order.id}
                                orderNumber={order.number}
                                type={IOrderDocumentType.PurchaseOrderExcel}
                                label={null}
                            />
                            {order.state === OrderStates.Shipped && (
                                <DocumentButton
                                    orderId={order.id}
                                    orderNumber={order.number}
                                    type={IOrderDocumentType.DeliveryNoteExcel}
                                    label={null}
                                />
                            )}
                            <ViewButton order={order} label={null} routeName={detailRouteName} />
                        </>
                    )
                },
            },
        ]
    }, [detailRouteName, formatMessage, me, type])

    return (
        <Table<T>
            {...rest}
            className="order-table"
            columns={columns}
            initialState={initialState}
            onCellClick={handleCellClick}
            paginate={paginate}
        />
    )
}
