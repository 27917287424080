/**
 *
 * CmsWidgetFactory
 *
 */
import React from 'react'
import { ICustomerType } from '../../../services/api/service/customers/types'
import { ICartReOrderFactoryProps } from '../../../types/cart'
import MultiStore from './Type/MultiStore/Component'
import Simple from './Type/Simple/Component'

function CartReOrderWidgetFactory({ customer, ...rest }: ICartReOrderFactoryProps): JSX.Element {
    switch (customer.account_type) {
        case ICustomerType.MultiStore:
            return <MultiStore customer={customer} {...rest} key={customer['@id']} />
        case ICustomerType.Simple:
            return <Simple customer={customer} {...rest} key={customer['@id']} />
        default:
            return <></>
    }
}

CartReOrderWidgetFactory.defaultProps = {} as Partial<ICartReOrderFactoryProps>

export default CartReOrderWidgetFactory
