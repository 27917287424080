/*
 *
 * NotFound
 *
 */

import classNames from 'classnames'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Gears from '../../components/Gears/Gears'

interface IProps {
    className?: string
    title?: string
    message?: string
}

function NotFound({ className, title, message }: IProps): JSX.Element {
    const history = useHistory()

    return (
        <div className={classNames('app-warning', 'error-section', 'not-found-section', className)}>
            <div className={'app-error-head'}>
                <h1 className={'hv'}>
                    <FormattedMessage id={title || 'notfound.alert_message'} />
                </h1>
                {message && <p className={'message'}>{message}</p>}
                <Gears />

                <Button variant={'primary'} onClick={() => history.goBack()}>
                    <FormattedMessage id={'default.go_back'} />
                </Button>
            </div>
        </div>
    )
}

NotFound.defaultProps = {} as Partial<IProps>

export default NotFound
