/*
 *
 * Customers reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'
import { $PropertyType } from 'utility-types'

export const initialModifyLoginState = {
    fetching: false,
}

export const initialCustomerSalesmenState: $PropertyType<ContainerState, 'customerSalesmen'> = {
    fetching: false,
}

export const initialProductsState = {
    list: {
        fetching: false,
    },
    invalidated: true,
}

export const initialState: ContainerState = {
    products: initialProductsState,
    modifyLogin: initialModifyLoginState,
    customerSalesmen: initialCustomerSalesmenState,
}

function customersReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.SUCCESS_GET_ACTION:
            return {
                ...state,
                storeId: action.payload.storeId,
                customerId: action.payload.customer['@id'],
                customer: action.payload.customer,
            }
        case ActionTypes.SET_CURRENT_CUSTOMER_ACTION: {
            const ste = {
                ...state,
                customerId: action.payload.customerId,
            }

            return ste
        }
        case ActionTypes.RESET_CURRENT_CUSTOMER_ACTION: {
            const ste = {
                ...state,
            }

            // suppression !
            delete ste['storeId']
            delete ste['customerId']
            delete ste['customer']

            return ste
        }
        case ActionTypes.SWITCH_STORE_ACTION:
            return {
                ...state,
                storeId: action.payload.storeId,
            }
        case ActionTypes.FAILURE_GET_ACTION:
        case ActionTypes.PROCESS_GET_ACTION:
            return {
                ...state,
            }
        case ActionTypes.SUCCESS_VIEWED_PRODUCTS_ACTION:
            return {
                ...state,
                products: {
                    ...state.products,
                    invalidated: false,
                    list: {
                        ...state.products.list,
                        items: action.payload['hydra:member'],
                        totalItems: action.payload['hydra:totalItems'],
                        nextPageUrl: action.payload['hydra:view']
                            ? action.payload['hydra:view']['hydra:next']
                            : undefined,
                        prevPageUrl: action.payload['hydra:view']
                            ? action.payload['hydra:view']['hydra:prev']
                            : undefined,
                        firstPageUrl: action.payload['hydra:view']
                            ? action.payload['hydra:view']['hydra:first']
                            : undefined,
                        lastPageUrl: action.payload['hydra:view']
                            ? action.payload['hydra:view']['hydra:last']
                            : undefined,
                    },
                },
            }
        case ActionTypes.VIEWED_PRODUCTS_INVALIDATE_ACTION: {
            let invalidated = true
            if (state.products.list && state.products.list.items) {
                const idx = state.products.list.items.findIndex((obj) => obj['@id'] === action.payload.productId)
                invalidated = idx === -1
            }

            return {
                ...state,
                products: {
                    ...state.products,
                    invalidated: invalidated,
                },
            }
        }
        case ActionTypes.PROCESS_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    done: false,
                },
            }
        }
        case ActionTypes.FAILURE_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    tms: Date.now(),
                    fetching: false,
                    error: action.payload.error,
                },
            }
        }
        case ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: {
                    ...state.modifyLogin,
                    done: true,
                },
            }
        }
        case ActionTypes.RESET_MODIFY_LOGIN_ACTION: {
            return {
                ...state,
                modifyLogin: initialModifyLoginState,
            }
        }
        case ActionTypes.RESET_ACTION: {
            return {
                ...initialState,
                storeId: state.storeId,
                customerId: state.customer ? state.customer['@id'] : undefined,
                customer: state.customer,
            }
        }
        case ActionTypes.RESET_ALL_ACTION: {
            return {
                ...initialState,
            }
        }
        case ActionTypes.SALESMEN_FAILURE_ACTION:
            return {
                ...state,
                customerSalesmen: {
                    ...initialCustomerSalesmenState,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.SALESMEN_PROCESS_ACTION:
            return {
                ...state,
                customerSalesmen: {
                    ...initialCustomerSalesmenState,
                    fetching: true,
                },
            }
        case ActionTypes.SALESMEN_SUCCESS_ACTION:
            return {
                ...state,
                customerSalesmen: {
                    ...state.customerSalesmen,
                    tms: Date.now(),
                    items: action.payload.items,
                    fetching: false,
                },
            }
        default:
            return state
    }
}

export default customersReducer
