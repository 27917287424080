/**
 *
 * DuplicateModal
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MultiStoreModal from '../../components/Carts/ReOrder/Type/MultiStore/Modal'
import { ICartDuplicateParameters, IReOrderParameters } from '../../services/api/service/carts/types'
import { IShippingLocation } from '../../services/api/service/classification/types'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import HttpError from '../../store/app/httpError'
import { IAppErrorTypes } from '../../store/app/types'
import { cartDuplicateProcessAction, cartReOrderResetAction } from '../../store/carts/actions'
import {
    makeSelectCartsDuplicateData,
    makeSelectCartsDuplicateErrors,
    makeSelectCartsDuplicateFetching,
    makeSelectCartsDuplicateSuccess,
    makeSelectCartsDuplicateWarnings,
} from '../../store/carts/selectors'
import { IDuplicateData, IReOrderErrorsType } from '../../store/carts/types'
import HttpStatusCode from '../../store/http/codes'
import { CollectionMap } from '../../types/common'

interface IProps {
    cartId: string
    customer: ICustomer
    currentStore?: ICustomer
    shippingLocation: IShippingLocation
    show: boolean
    onDuplicateModalExit: () => void
    onDuplicateProcessDone: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    duplicatesFetching: makeSelectCartsDuplicateFetching(),
    duplicatesSuccess: makeSelectCartsDuplicateSuccess(),
    duplicatesData: makeSelectCartsDuplicateData(),
    duplicatesErrors: makeSelectCartsDuplicateErrors(),
    duplicatesWarnings: makeSelectCartsDuplicateWarnings(),
})

function DuplicateModal({
    cartId,
    customer,
    currentStore,
    shippingLocation,
    show,
    onDuplicateModalExit,
    onDuplicateProcessDone,
}: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()

    const [showDuplicateError, setShowDuplicateError] = useState<boolean>(false)
    const [showDuplicateSuccess, setShowDuplicateSuccess] = useState<boolean>(false)
    const { duplicatesData, duplicatesErrors, duplicatesFetching, duplicatesSuccess, duplicatesWarnings } = useSelector<
        IApplicationRootState,
        {
            duplicatesFetching: CollectionMap<boolean>
            duplicatesSuccess: CollectionMap<boolean>
            duplicatesErrors: IReOrderErrorsType
            duplicatesWarnings: CollectionMap<Array<string>>
            duplicatesData: CollectionMap<IReOrderParameters>
        }
    >(stateSelector)

    // récupération du fetching, data, warnings, toussa toussa
    let duplicateFetching = false
    let duplicateSuccess = false
    let duplicateWarnings: Array<string> | undefined = undefined
    let duplicateError: IAppErrorTypes | undefined = undefined
    let duplicateData: IDuplicateData | undefined = undefined
    const duplicateKey = cartId ? `${cartId}` : null
    if (duplicateKey) {
        duplicateFetching = duplicatesFetching[duplicateKey] || false
        duplicateSuccess = duplicatesSuccess[duplicateKey] || false
        duplicateWarnings = duplicatesWarnings[duplicateKey] || undefined
        duplicateError = duplicatesErrors[duplicateKey] || undefined
        duplicateData = duplicatesData[duplicateKey] || undefined
    }

    const handleDuplicateSubmit = useCallback(
        (data: IDuplicateData) => {
            setShowDuplicateError(false)
            setShowDuplicateSuccess(false)
            dispatch(
                cartDuplicateProcessAction(cartId, {
                    ...data,
                    cart: cartId,
                })
            )
        },
        [cartId, dispatch, setShowDuplicateError, setShowDuplicateSuccess]
    )

    const handleDuplicateCancel = useCallback(() => onDuplicateModalExit(), [onDuplicateModalExit])

    const handleDuplicateAlertConfirm = useCallback(() => {
        setShowDuplicateError(false)
        onDuplicateProcessDone()
    }, [onDuplicateProcessDone, setShowDuplicateError])

    const handleDuplicateAlertSuccessCancel = useCallback(() => {
        setShowDuplicateSuccess(false)
        onDuplicateProcessDone()
        dispatch(cartReOrderResetAction(cartId))
    }, [cartId, dispatch, onDuplicateProcessDone, setShowDuplicateSuccess])

    const handleDuplicateAlertSuccessConfirm = useCallback(() => {
        setShowDuplicateSuccess(false)
        onDuplicateProcessDone()
    }, [onDuplicateProcessDone, setShowDuplicateSuccess])

    const excludedCustomerIds = useMemo(() => {
        const excluded = currentStore ? [currentStore['@id']] : [customer['@id']]
        if (customer.account_type === ICustomerType.MultiStore && !customer.can_order_on_main_store) {
            excluded.push(customer['@id'])
        }
        return excluded
    }, [customer, currentStore])

    useEffect(() => {
        setShowDuplicateError(typeof duplicateError !== 'undefined')
        onDuplicateModalExit()
    }, [duplicateError, setShowDuplicateError, onDuplicateModalExit])

    useEffect(() => {
        setShowDuplicateSuccess(duplicateSuccess)
        onDuplicateModalExit()
    }, [duplicateSuccess, setShowDuplicateSuccess, onDuplicateModalExit])

    const violations = useMemo(() => {
        if (!duplicateError || !duplicateError.isHttpError) {
            return undefined
        }

        if (duplicateError.isHttpError) {
            if (
                String((duplicateError as HttpError).status) === HttpStatusCode.BAD_REQUEST.toString() &&
                (duplicateError as HttpError).violations
            ) {
                let errors: Array<string> = []
                for (const key in (duplicateError as HttpError).violations) {
                    // @ts-ignore
                    const itemErrors = (duplicateError as HttpError).violations[key]
                    errors = [...errors, ...itemErrors]
                }
                return errors
            }
        }

        return undefined
    }, [duplicateError])

    return (
        <>
            <MultiStoreModal<ICartDuplicateParameters>
                className={'duplicate-modal'}
                customer={customer}
                fetching={duplicateFetching}
                show={show}
                onReOrderSubmit={handleDuplicateSubmit}
                onReOrderReset={handleDuplicateCancel}
                excludedCustomerIds={excludedCustomerIds}
                baseI18nKey={'cart.duplicate'}
            />
            <SweetAlert
                show={showDuplicateError}
                error
                title={formatMessage({
                    id: 'default.error',
                })}
                onConfirm={handleDuplicateAlertConfirm}
            >
                {violations?.map((violation) => (
                    <p className={'mb-0'} key={violation}>
                        {violation}
                    </p>
                ))}
                {(!violations || violations.length === 0) && duplicateError?.message}
            </SweetAlert>
            <SweetAlert
                showCancel={false}
                show={showDuplicateSuccess}
                customClass={'add-to-cart-alert'}
                success={duplicateWarnings && duplicateWarnings.length === 0}
                warning={duplicateWarnings && duplicateWarnings.length > 0}
                cancelBtnText={formatMessage({ id: 'default.continue_my_shopping' })}
                confirmBtnText={formatMessage({ id: 'default.ok' })}
                title={formatMessage(
                    {
                        id: `cart.duplicate.${
                            duplicateData && duplicateData.erase_cart ? 'erase_cart' : 'merge_cart'
                        }_title`,
                    },
                    {
                        shipping_location: shippingLocation ? shippingLocation.label : '',
                    }
                )}
                onCancel={handleDuplicateAlertSuccessCancel}
                onConfirm={handleDuplicateAlertSuccessConfirm}
            >
                <FormattedMessage
                    id={`cart.duplicate.success_${
                        duplicateData && duplicateData.erase_cart ? 'erase_cart' : 'merge_cart'
                    }_message`}
                    values={{
                        shipping_location: shippingLocation ? shippingLocation.label : '',
                    }}
                />
                {duplicateWarnings && duplicateWarnings.length > 0 && (
                    <>
                        <FormattedMessage id={`cart.duplicate.warning_message`} />
                        <br />
                        <br />
                        {duplicateWarnings.join('\n')}
                    </>
                )}
            </SweetAlert>
        </>
    )
}

DuplicateModal.defaultProps = {} as Partial<IProps>

export default memo(DuplicateModal)
