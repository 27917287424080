import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiOrderExportCreateResponse,
    IApiOrderExportListResponse,
    IApiOrderExportResponse,
    IOrderExportCreateParameters,
    IOrderExportListParameters,
    IOrderExportService,
} from './types'

const create = (instance: AxiosInstance): IOrderExportService => {
    /*****************************
     * METHODS
     *****************************/
    const create = (parameters: IOrderExportCreateParameters): Promise<IApiOrderExportCreateResponse> => {
        return instance.post('/order-exports', parameters)
    }

    const list = (
        params: IOrderExportListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiOrderExportListResponse> => {
        return instance.get('/order-exports', { params: params, cancelToken })
    }

    const get = (orderExportId: string, cancelToken?: CancelToken): Promise<IApiOrderExportResponse> => {
        return instance.get(`${orderExportId}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        create,
        list,
        get,
    }
}

export default {
    create,
}
