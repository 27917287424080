import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Config from '../../../../config'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'

import {
    makeSelectStatisticsTopProductsError,
    makeSelectStatisticsTopProductsFetching,
    makeSelectStatisticsTopProductsItems,
    makeSelectStatisticsTopProductsTotalItems,
} from '../../../../store/statistics/selectors'
import { IMemberStatisticsFilters, MemberStatisticsContext } from '../../../../store/statistics/types'
import { makeSelectAuthMe } from '../../../../store/auth/selectors'
import { IMe } from '../../../../services/api/service/me/types'
import { ICustomer } from '../../../../services/api/service/customers/types'
import { IOrderList } from '../../../../services/api/service/orders/types'
import { Filters, SortingRule } from 'react-table'
import List from './Partial/List'

import { makeSelectCustomer } from '../../../../store/customers/selectors'
import FiltersResume from '../Filters/Partial/Resume'
import {
    IApiMeTopProductsEntry,
    IMeStatisticsTopProductsParameters,
} from '../../../../services/api/service/stats/types'
import {
    statisticsCustomerTopProductsProcessAction,
    statisticsCustomerTopProductsResetAction,
} from '../../../../store/statistics/actions'
import { TableHandleType } from '../../../../components/Table/Table'
import { convertStatisticFiltersToApiParams } from '../Filters/utils'
import { forEach } from 'lodash'

type IProps = {
    filters?: IMemberStatisticsFilters
    context?: MemberStatisticsContext
}

const stateSelector = createStructuredSelector<any, any>({
    listFetching: makeSelectStatisticsTopProductsFetching(),
    listItems: makeSelectStatisticsTopProductsItems(),
    listTotalItems: makeSelectStatisticsTopProductsTotalItems(),
    listError: makeSelectStatisticsTopProductsError(),
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
})

function Statistics({ filters: globalFilters, context = MemberStatisticsContext.Customer }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const tableRef = useRef() as React.MutableRefObject<TableHandleType<IApiMeTopProductsEntry>>
    const { listFetching, listItems, listError, listTotalItems } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
            customer?: ICustomer
            listFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IApiMeTopProductsEntry>
            listError?: IAppErrorTypes | undefined
        }
    >(stateSelector)

    const handleFetchData = useCallback(
        (page: number, pageSize: number, filters: Filters<IOrderList>, sortBy: Array<SortingRule<IOrderList>>) => {
            const orderBy = sortBy.length ? sortBy[0].id : undefined
            const orderDirection = sortBy.length ? (sortBy[0].desc ? 'desc' : 'asc') : undefined

            const parameters: IMeStatisticsTopProductsParameters = {
                page,
                itemsPerPage: pageSize,
                order: orderBy,
                order_direction: orderDirection,
            }

            filters.forEach((filter) => (parameters[filter.id] = filter.value))
            dispatch(statisticsCustomerTopProductsProcessAction(parameters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (!globalFilters) {
            dispatch(statisticsCustomerTopProductsResetAction())
            return
        }
    }, [dispatch, globalFilters])

    useEffect(() => {
        // on converti en valeurs api
        const fltrs = convertStatisticFiltersToApiParams(globalFilters) || {}
        forEach(fltrs, (val, key) => {
            tableRef.current.setFilter(key, val)
        })
        tableRef.current.gotoPage(1)
    }, [tableRef, globalFilters])

    return (
        <Card
            className={classNames(
                'member-card',
                'statistics-card',
                'statistics-data-card',
                'statistics-top-products-card',
                `statistics-context-${context}`
            )}
        >
            <Card.Header>
                <h3 className={'h6'}>
                    <FormattedMessage id={'default.my_top_products'} />
                </h3>
                {globalFilters && <FiltersResume filters={globalFilters} />}
            </Card.Header>
            <Card.Body>
                <div className={'top-products-list-section'}>
                    <div className={'top-products-list-content'}>
                        <List
                            mRef={tableRef}
                            context={context}
                            totalItems={listTotalItems}
                            data={listItems || []}
                            fetchData={handleFetchData}
                            fetching={listFetching}
                            fetchError={listError}
                            pageSize={Config.STATISTICS_TOP_PRODUCTS.ITEMS_PER_PAGE}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default memo(Statistics)
