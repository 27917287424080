import { AxiosError } from 'axios'

import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { IApiErrorCollection } from './types'

export function isAxiosError(error: any): error is AxiosError {
    return typeof (error as AxiosError).isAxiosError === 'boolean' && (error as AxiosError).isAxiosError
}

export function isAxiosTimeout(error: any): boolean {
    return error && isString(error.code) && error.code === 'ECONNABORTED'
}

export function isConstraintViolationList(data: any): data is IApiErrorCollection {
    return (
        isObject(data) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !isUndefined(data['@type']) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data['@type'] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isString(data['@type']) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data['@type'] === 'ConstraintViolationList'
    )
}
