/**
 *
 * InProgressOrderQuantity
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface IProps {
    labelId?: string
    quantity: number
}

function InProgressOrderQuantity({ quantity, labelId }: IProps): JSX.Element {
    return (
        <div className={classNames('product-member-info', 'product-order-in-progress-quantity', 'product-field')}>
            <span className={'lbl'}>
                <FormattedMessage id={labelId} />
            </span>
            <span className={'value'}>{quantity}</span>
        </div>
    )
}

InProgressOrderQuantity.defaultProps = {
    quantity: 0,
    labelId: 'default.order_in_progress',
} as Partial<IProps>

export default memo(InProgressOrderQuantity)
