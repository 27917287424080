/* --- STATE --- */
import { IRequestPaginationProcessState, IRequestProcessState } from '../http/types'
import {
    IApiMeTopProductsEntries,
    IMeStatisticsTopProductsParameters,
    IMemberTopSalesEntries,
    IMeYearlyStatEntries,
    IStatisticsFamilyTreeCollection,
    IMeStatisticsTopSalesParameters,
} from '../../services/api/service/stats/types'
import { Action } from 'redux'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IApplicationRootState } from '../index'

export enum MemberStatisticsContext {
    Salesman = 'salesman',
    Customer = 'customer',
}

export interface IMemberYearlyStatsState extends IRequestProcessState {
    data?: IMeYearlyStatEntries
}

export interface IMemberTopSalesState extends IRequestProcessState {
    params?: IMeStatisticsTopSalesParameters
    data?: IMemberTopSalesEntries
}

export type IMemberTopProducts = IRequestPaginationProcessState<IApiMeTopProductsEntries>

export interface IMemberClassification extends IRequestProcessState {
    filters?: IMemberStatisticsClassificationFilters
    tree?: IStatisticsFamilyTreeCollection
}

export interface IStatisticsState {
    classification?: IMemberClassification
    top_sales: IMemberTopSalesState
    top_products: IMemberTopProducts
    yearly_stats: IMemberYearlyStatsState
}

export type IMemberStatisticsFilters = {
    readonly from: Date
    readonly to: Date
    readonly classification: {
        readonly departments?: Array<string>
        readonly families?: Array<string>
        readonly subFamilies?: Array<string>
    }
    readonly all_customers?: 1 | 0
    readonly all_activity?: 1 | 0
}
export type IMemberStatisticsTopSalesFilters = IMemberStatisticsFilters

export type IMemberStatisticsClassificationFilters = Omit<IMemberStatisticsFilters, 'classification'>

/* --- ACTIONS --- */
export interface IMemberClassificationProcessPayload {
    filters: IMemberStatisticsClassificationFilters
}

export interface IMemberTopSalesProcessPayload {
    params: IMeStatisticsTopSalesParameters
}

export interface IMemberTopProductsProcessPayload {
    params: IMeStatisticsTopProductsParameters
}

export interface IMemberClassificationProcessAction extends Action {
    payload: IMemberClassificationProcessPayload
}

export interface IMemberTopSalesProcessAction extends Action {
    payload: IMemberTopSalesProcessPayload
}

export interface IMemberTopProductsProcessAction extends Action {
    payload: IMemberTopProductsProcessPayload
}

type StatisticsActions = ActionType<typeof actions>

/* --- EXPORTS --- */
type RootState = IApplicationRootState
type ContainerState = IStatisticsState
type ContainerActions = StatisticsActions

// @ts-ignore
export { RootState, ContainerState, ContainerActions }
