/**
 *
 * ResumeCard
 *
 */
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React, { memo, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ICustomer, ICustomerIdentity } from '../../services/api/service/customers/types'
import { isCustomerResource } from '../../store/customers/utils'

interface IProps {
    customer: ICustomer | ICustomerIdentity
    principal?: boolean
    addressInline?: boolean
    hideAddressLines?: boolean
    showStoreCode?: boolean
    showAddress?: boolean
    hideCity?: boolean
    as?: React.ElementType
    className?: string
}

function ResumeCard({
    customer,
    className,
    as = 'div',
    showStoreCode = false,
    showAddress = true,
    hideCity = false,
    principal = false,
    addressInline = false,
    hideAddressLines = false,
}: IProps): JSX.Element {
    const AddressComp: JSX.Element = useMemo(() => {
        if (!isCustomerResource(customer) || !showAddress) {
            return <></>
        }

        if (customer.address1 || customer.postcode || customer.city || customer.country_name) {
            if (addressInline) {
                // @ts-ignore
                const lines: Array<string> = [customer.address1, customer.address2].filter((val) => !isEmpty(val))
                // @ts-ignore
                const cities: Array<string> = [customer.postcode, customer.city].filter((val) => !isEmpty(val))
                // @ts-ignore
                const countries: Array<string> = [customer.country_name].filter((val) => !isEmpty(val))

                return (
                    <span className={'account-address'}>
                        {!hideAddressLines && lines.join(' ')}
                        {!hideAddressLines && lines.length > 0 && cities.length > 0 && !hideCity ? ', ' : ''}
                        {!hideCity && cities.join(' ')}
                        {cities.length > 0 && !hideCity && countries.length > 0 ? ' ' : ''}
                        {countries.join(' ')}
                    </span>
                )
            } else {
                return (
                    <span className={'account-address'}>
                        {!hideAddressLines && customer.address1 && (
                            <span className={'address-1'}>{customer.address1}</span>
                        )}
                        {!hideAddressLines && customer.address2 && (
                            <span className={'address-2'}>{customer.address2}</span>
                        )}
                        <span className={'city-country'}>
                            {!hideCity && customer.postcode && <span className={'postcode'}>{customer.postcode}</span>}
                            {!hideCity && customer.postcode && customer.city && ' '}
                            {!hideCity && customer.city && <span className={'city'}>{customer.city}</span>}
                            {(customer.postcode || customer.city) && !hideCity && customer.country_name && ' '}
                            {customer.country_name && <span className={'country-name'}>{customer.country_name}</span>}
                        </span>
                    </span>
                )
            }
        }

        return <></>
    }, [addressInline, customer, hideAddressLines, hideCity, showAddress])

    const Child = useMemo(
        () => (
            <>
                <span className={'business-name'}>
                    {principal && (
                        <span className="bdge">
                            <FormattedMessage id={'default.principal_account'} />
                        </span>
                    )}
                    <span className="name">{customer.business_name}</span>
                </span>
                {showStoreCode && customer.store_code && <span className="code">{customer.store_code}</span>}
                {AddressComp}
            </>
        ),
        [principal, customer.business_name, customer.store_code, showStoreCode, AddressComp]
    )

    return React.createElement(
        as || 'div',
        {
            className: classNames('account', 'account-card', { principal }, className),
        },
        Child
    )
}

export default memo(ResumeCard)
