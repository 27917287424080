/**
 *
 * LangItem
 *
 */
import classNames from 'classnames'
import { getLangNameFromCode } from 'language-name-map'
import React, { memo } from 'react'
import Config from '../../config'
import { CollectionMap } from '../../types/common'
import FlagIcon from '../Icon/FlagIcon'
import { LangItemParts, LangItemPartType } from './types'

interface IProps {
    parts: LangItemParts
    locale: string
    className?: string
}

const flags = Config.I18N.FLAGS
function LangItem({ locale, className, parts }: IProps): JSX.Element {
    const item = getLangNameFromCode(locale)

    const items: CollectionMap<JSX.Element> = {
        name: (
            <span className={'name'} key={'name'}>
                {item ? item.name : locale}
            </span>
        ),
        native: (
            <span className={'native'} key={'native'}>
                {item ? item.native : locale}
            </span>
        ),
        abbr: (
            <span className={'abbr'} key={'abbr'}>
                {locale}
            </span>
        ),
        flag: <FlagIcon code={flags[locale]} size={'lg'} key={'flag'} squared />,
    }

    return (
        <span className={classNames('locale-item', `locale-item-${locale.toLowerCase()}`, className)}>
            {parts.map((val) => items[val])}
        </span>
    )
}

LangItem.defaultProps = {
    parts: [LangItemPartType.Flag, LangItemPartType.Native],
} as Partial<IProps>

export default memo(LangItem)
