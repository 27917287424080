/**
 *
 * QuantitySelector
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, InputGroup, Overlay, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Config from '../../config'
import ApplicationHelper from '../../utils/applicationHelper'
import { normalizePackingQuantity, normalizeQuantity } from '../../utils/productHelper'
import Button from '../Buttons/Button'
import FlatIcon from '../Icon/FlatIcon'
import Price from '../Price/Price'
import ReStockAlert, { RestockAlertMode, RestockAlertSubscriptionChangeCallback } from './Partial/ReStockAlert'
import isNumber from 'lodash/isNumber'
import { useCustomer } from '../../utils/hook/useCustomer'

const uniqIdGenerator = require('uniqid')
const APP_ROOT_NODE = document.getElementById(Config.APP_ROOT_HTML_ID) as HTMLElement

export type QuantitySelectorCallback = (quantity: number, oldQuantity: number, packing: number) => void
export type QuantitySelectorClick = (disabled: boolean, saving: boolean, multiple: boolean, locked: boolean) => void
export enum QuantityAlertDisplayMode {
    None = 'none',
    Tooltip = 'tooltip',
    Insert = 'insert',
}
export enum QuantityOutOfStockReason {
    OutOfStock = 'out_of_stock',
    InsufficientStock = 'insufficient_stock',
    MaxReached = 'max_reached',
    InvalidQuantity = 'invalid_quantity',
}

interface IProps {
    min: number
    max?: number
    maxLength?: number
    currentValue?: number
    packing?: number
    showPacking: boolean
    showTotalPrice: boolean
    unitPrice?: number
    className?: string
    onQuantityChange?: QuantitySelectorCallback
    onSelectorClick?: QuantitySelectorClick
    throttle: number
    disabled: boolean
    outOfStock: boolean
    outOfStockLabel?: string
    saving: boolean
    multiple: boolean
    canIncrement: boolean
    deleteOnly: boolean
    locked?: boolean
    multipleButtonLabel?: string
    errors?: Array<string>
    remainingStock: number | null
    arrivalStock: number | null
    orderInProgressQuantity?: number
    popoverContainerRef?: React.MutableRefObject<HTMLDivElement>
    onRestockAlertSubscriptionChange?: RestockAlertSubscriptionChangeCallback
    quantityAlertDisplayMode?: QuantityAlertDisplayMode
    restockAlertOutOfStockButtonOnly?: boolean
    restockAlertSubscribed?: boolean
    restockAlertEnabled?: boolean
    uniqId?: string
    tabIndex?: number
    showPrices?: boolean
}

function QuantitySelector({
    min,
    max,
    currentValue,
    remainingStock,
    className,
    packing,
    showPacking,
    throttle,
    disabled,
    locked,
    outOfStock,
    saving,
    multiple,
    multipleButtonLabel,
    onQuantityChange,
    onSelectorClick,
    showTotalPrice,
    unitPrice,
    errors,
    popoverContainerRef,
    uniqId,
    canIncrement,
    quantityAlertDisplayMode,
    restockAlertOutOfStockButtonOnly,
    restockAlertSubscribed,
    restockAlertEnabled,
    onRestockAlertSubscriptionChange,
    tabIndex,
    orderInProgressQuantity,
    showPrices,
    maxLength,
}: IProps): JSX.Element {
    const customer = useCustomer()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const componentRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const isShowTotalPrice = showTotalPrice && typeof unitPrice === 'number'
    const productPacking = useMemo(() => {
        return customer?.order_by_unit ? 1 : packing || 1
    }, [packing, customer])
    const showProductPacking = useMemo(() => {
        return !customer?.order_by_unit && showPacking && isNumber(packing)
    }, [packing, showPacking, customer])
    const [quantity, setQuantity] = useState<number>(normalizeQuantity(currentValue || 0, productPacking))
    const [totalPrice, setTotalPrice] = useState<number>(isShowTotalPrice ? unitPrice! * (currentValue || 0) : 0.0)
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)
    const [packingQuantity, setPackingQuantity] = useState<number>(
        normalizePackingQuantity(currentValue || 0, productPacking)
    )
    const [showPopoverInfo, setShowPopoverInfo] = useState<boolean>(false)

    const disableQuantityInput = useMemo(() => {
        return disabled || locked || outOfStock
    }, [disabled, locked, outOfStock])
    const disableDecrement = useMemo(() => {
        return quantity <= min || disabled || locked
    }, [quantity, min, disabled, locked])
    const disableIncrement = useMemo(() => {
        return (max !== null && max! <= quantity) || !canIncrement || outOfStock || disabled || locked
    }, [max, quantity, canIncrement, outOfStock, disabled, locked])
    const showTrashPrevIcon = useMemo(() => {
        return quantity - productPacking === 0 || (outOfStock && quantity > 0)
    }, [quantity, productPacking, outOfStock])
    const isQuantityAlertTooltip = useMemo(() => {
        return quantityAlertDisplayMode === QuantityAlertDisplayMode.Tooltip
    }, [quantityAlertDisplayMode])
    const showWarningIcon = useMemo(() => {
        return !isQuantityAlertTooltip && errors && errors.length > 0 && !locked && !saving
    }, [errors, isQuantityAlertTooltip, saving, locked])
    const needDisplayReStockAlertComp = useMemo(() => {
        if (typeof errors !== 'undefined' && errors.length > 0) {
            return true
        }
        if (locked || !showPrices) {
            return false
        }
        return outOfStock || disableIncrement
    }, [errors, outOfStock, disableIncrement, locked, showPrices])
    const hasPopoverDisplayable = useMemo(() => {
        return (
            needDisplayReStockAlertComp &&
            typeof popoverContainerRef !== 'undefined' &&
            quantityAlertDisplayMode !== QuantityAlertDisplayMode.None
        )
    }, [needDisplayReStockAlertComp, popoverContainerRef, quantityAlertDisplayMode])

    const outOfStockReason = useMemo(() => {
        if (errors && errors.length > 0 && !canIncrement) {
            return QuantityOutOfStockReason.InsufficientStock
        } else if (outOfStock) {
            return QuantityOutOfStockReason.OutOfStock
        } else if (!canIncrement) {
            return QuantityOutOfStockReason.MaxReached
        } else {
            return QuantityOutOfStockReason.InvalidQuantity
        }
    }, [errors, outOfStock, canIncrement])

    const decrementQuantity = useCallback(() => {
        if (disableDecrement) {
            return
        }

        let nextQuantity = !outOfStock ? quantity - productPacking : 0
        let nextPackingQuantity = Math.ceil(nextQuantity / productPacking)
        if (nextQuantity % productPacking !== 0) {
            nextQuantity = Math.ceil(nextQuantity / productPacking) * productPacking
            nextPackingQuantity = nextQuantity / productPacking
        }
        if (nextQuantity >= min) {
            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }
            setQuantity(nextQuantity)
            setPackingQuantity(nextPackingQuantity)
            if (showTotalPrice && typeof unitPrice !== 'undefined') {
                setTotalPrice(unitPrice * nextQuantity)
            }
            const timeoutId = setTimeout(() => {
                if (onQuantityChange) {
                    onQuantityChange(nextQuantity, currentValue || 0, productPacking)
                }
            }, throttle)
            setChangeTimeoutId(timeoutId)
        }
    }, [
        quantity,
        currentValue,
        setQuantity,
        setPackingQuantity,
        setTotalPrice,
        onQuantityChange,
        showTotalPrice,
        unitPrice,
        changeTimeoutId,
        setChangeTimeoutId,
        min,
        packing,
        throttle,
        outOfStock,
        disableDecrement,
    ])

    const incrementQuantity = useCallback(() => {
        if (disableIncrement) {
            return
        }

        let nextQuantity = quantity + productPacking
        let nextPackingQuantity = Math.ceil(nextQuantity / productPacking)
        if (nextQuantity % productPacking !== 0) {
            nextQuantity = Math.ceil(nextQuantity / productPacking) * productPacking
            nextPackingQuantity = nextQuantity / productPacking
        }
        if (!max || nextQuantity <= max) {
            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }
            setQuantity(nextQuantity)
            setPackingQuantity(nextPackingQuantity)
            if (showTotalPrice && typeof unitPrice !== 'undefined') {
                setTotalPrice(unitPrice * nextQuantity)
            }
            const timeoutId = setTimeout(() => {
                if (onQuantityChange) {
                    onQuantityChange(nextQuantity, currentValue || 0, productPacking)
                }
            }, throttle)
            setChangeTimeoutId(timeoutId)
        }
    }, [
        disableIncrement,
        currentValue,
        quantity,
        max,
        changeTimeoutId,
        showTotalPrice,
        unitPrice,
        throttle,
        onQuantityChange,
        productPacking,
    ])

    const handleOnInputBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            let nextQty = parseInt(e.currentTarget.value)
            const strlen = String(nextQty).length

            if (strlen >= maxLength!) {
                if (inputRef.current) {
                    // on remet la value
                    inputRef.current.value = String(currentValue)
                    const event = new CustomEvent('maxLengthExceeded', {
                        detail: { value: currentValue, wanted: nextQty, maxLength },
                    })
                    inputRef.current.dispatchEvent(event)
                }
                return
            }

            if (nextQty % productPacking !== 0) {
                nextQty = Math.ceil(nextQty / productPacking) * productPacking
            }

            if (showTotalPrice && typeof unitPrice !== 'undefined') {
                setTotalPrice(unitPrice * nextQty)
            }

            if (onQuantityChange && nextQty !== currentValue) {
                onQuantityChange(nextQty, currentValue || 0, productPacking)
            }

            if (nextQty !== currentValue) {
                setQuantity(nextQty)
                const nextPackingQuantity = Math.ceil(nextQty / productPacking)
                setPackingQuantity(nextPackingQuantity)
            }

            if (inputRef.current) {
                inputRef.current.value = String(nextQty)
            }
        },
        [onQuantityChange, showTotalPrice, unitPrice, productPacking, currentValue, inputRef, maxLength]
    )

    const handleOnKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === '+') {
                e.preventDefault()
                e.stopPropagation()
                if (!disableIncrement) {
                    incrementQuantity()
                }
                return
            }

            if (e.key === '-') {
                e.preventDefault()
                e.stopPropagation()
                if (!disableDecrement) {
                    decrementQuantity()
                }
                return
            }

            if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()

                if (inputRef.current) {
                    inputRef.current.blur()
                }

                return
            }

            const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode
            const value = Number(e.currentTarget.value + e.key) || 0

            if (
                (keyCode >= 37 && keyCode <= 40) ||
                keyCode == 8 ||
                keyCode == 9 ||
                keyCode == 13 ||
                (keyCode >= 48 && keyCode <= 57)
            ) {
                return 1 <= value && String(value).length <= 13
            }

            e.preventDefault()
            e.stopPropagation()
        },
        [inputRef, disableDecrement, disableIncrement, incrementQuantity, decrementQuantity]
    )

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setQuantity(e.currentTarget.value.length === 0 ? 0 : parseInt(e.currentTarget.value))
        },
        [setQuantity]
    )

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const handleOnSelectorClick = useCallback(() => {
        if (onSelectorClick) {
            onSelectorClick(disabled || locked || false, saving || false, multiple || false, locked || false)
        }
    }, [multiple, saving, disabled, locked, onSelectorClick])

    const handleMouseEnter = useCallback(() => {
        setShowPopoverInfo(hasPopoverDisplayable === true)
    }, [setShowPopoverInfo, hasPopoverDisplayable])

    const handleMouseLeave = useCallback(() => {
        setShowPopoverInfo(false)
    }, [setShowPopoverInfo])

    const PopoverInfo = useMemo(() => {
        if (!hasPopoverDisplayable) {
            return undefined
        }

        const popoverId = uniqId ? uniqId : uniqIdGenerator()

        return (
            <Popover
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                id={`popover-quantity-selector-${popoverId}`}
                className={classNames('quantity-selector-popover', 'quantity-selector-info', {
                    'out-of-stock': outOfStock,
                    'max-reached': disableIncrement,
                    'has-errors': errors && errors.length > 0,
                    'restock-alert': isQuantityAlertTooltip,
                })}
            >
                <Popover.Content>
                    <div className="quantity-selector-info-content">
                        <ReStockAlert
                            mode={RestockAlertMode.Insert}
                            subscribed={restockAlertSubscribed}
                            reason={outOfStockReason}
                            readonly={!isQuantityAlertTooltip || restockAlertEnabled === false || locked || !showPrices}
                            onChange={onRestockAlertSubscriptionChange}
                            errors={errors}
                        />
                    </div>
                </Popover.Content>
            </Popover>
        )
    }, [
        locked,
        showPrices,
        errors,
        uniqId,
        outOfStock,
        isQuantityAlertTooltip,
        disableIncrement,
        hasPopoverDisplayable,
        restockAlertSubscribed,
        onRestockAlertSubscriptionChange,
        handleMouseLeave,
        handleMouseEnter,
        restockAlertEnabled,
        outOfStockReason,
    ])

    useEffect(() => {
        if (typeof currentValue !== 'number') {
            return
        }
        setQuantity(currentValue)
        setPackingQuantity(currentValue / productPacking)
        if (inputRef.current) {
            inputRef.current.value = String(currentValue)
        }
        if (showTotalPrice && typeof unitPrice !== 'undefined') {
            setTotalPrice(unitPrice * currentValue)
        }
    }, [currentValue, unitPrice, showTotalPrice, inputRef, productPacking, setQuantity, setPackingQuantity])

    const handleIconClick = useCallback(() => {
        if (!ApplicationHelper.isTouchScreen() || !hasPopoverDisplayable) {
            return
        }
        setShowPopoverInfo((state) => !state)
    }, [setShowPopoverInfo, hasPopoverDisplayable])

    const componentClassNames = classNames(
        'quantity-selector',
        `alert-mode-${quantityAlertDisplayMode}`,
        {
            'with-packing': showProductPacking,
            'with-price': showTotalPrice,
            'quantity-exceeded':
                typeof remainingStock === 'number' && typeof currentValue === 'number' && remainingStock < currentValue,
            'order-in-progress-quantity': typeof orderInProgressQuantity === 'number' && orderInProgressQuantity > 0,
            'has-errors':
                (errors && errors.length > 0) ||
                (outOfStock && QuantityAlertDisplayMode.None === quantityAlertDisplayMode),
            'out-of-stock': outOfStock,
            'restock-alert-subscribed': restockAlertSubscribed,
            disabled: (!canIncrement || disableIncrement) && disableDecrement,
            saving: saving,
            multiple: multiple,
            locked: locked,
        },
        className
    )

    if (restockAlertOutOfStockButtonOnly && restockAlertEnabled && outOfStock) {
        if (locked || !showPrices) {
            return <></>
        }

        return (
            <div className={classNames(componentClassNames, 'restock-alert-only')}>
                <ReStockAlert
                    mode={outOfStock ? RestockAlertMode.Button : RestockAlertMode.Insert}
                    readonly={!restockAlertEnabled || locked || !showPrices}
                    subscribed={restockAlertSubscribed}
                    onChange={onRestockAlertSubscriptionChange}
                />
            </div>
        )
    }

    return (
        <div className={componentClassNames}>
            <Form
                onSubmit={handleOnSubmit}
                onClick={handleOnSelectorClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {PopoverInfo && (
                    <Overlay
                        placement={'top'}
                        container={APP_ROOT_NODE}
                        target={componentRef!.current}
                        show={showPopoverInfo}
                    >
                        {PopoverInfo}
                    </Overlay>
                )}
                <Form.Group className={'quantity-selector-quantity'} ref={componentRef}>
                    <InputGroup>
                        {!multiple && (
                            <InputGroup.Prepend>
                                <Button
                                    className={classNames('button-decrement', { inactive: disableDecrement })}
                                    variant={disableDecrement ? 'secondary' : 'primary'}
                                    onClick={decrementQuantity}
                                    tabIndex={-1}
                                >
                                    {!showTrashPrevIcon && <FlatIcon icon={'minus'} />}
                                    {showTrashPrevIcon && <FlatIcon icon={'trash-alt'} />}
                                </Button>
                            </InputGroup.Prepend>
                        )}
                        <div className={'input-group-content'}>
                            <div className={'app-icon-inner'} onClick={handleIconClick}>
                                {showWarningIcon && (
                                    <FontAwesomeIcon
                                        icon={['fal', 'exclamation-triangle']}
                                        className={'app-icon app-icon-error'}
                                    />
                                )}
                                {!saving && !multiple && !locked && !showWarningIcon && (
                                    <FontAwesomeIcon
                                        icon={['fal', 'shopping-cart']}
                                        className={'app-icon app-icon-shopping-cart'}
                                    />
                                )}
                                {multiple && !saving && !showWarningIcon && (
                                    <FontAwesomeIcon icon={['fal', 'edit']} className={'app-icon app-icon-multiple'} />
                                )}
                                {saving && (
                                    <FontAwesomeIcon
                                        className={'app-icon app-icon-cart'}
                                        icon={['fal', 'circle-notch']}
                                        spin={true}
                                    />
                                )}
                                {locked && !multiple && (
                                    <FontAwesomeIcon
                                        icon={['fal', 'lock']}
                                        className={'app-icon app-icon-shopping-cart'}
                                    />
                                )}
                            </div>
                            {multiple ? (
                                <span className="form-control-plaintext">{quantity}</span>
                            ) : (
                                <Form.Control
                                    type="text"
                                    pattern="[0-9]+"
                                    inputMode="decimal"
                                    value={quantity}
                                    onKeyPress={handleOnKeyPress}
                                    onBlur={handleOnInputBlur}
                                    onChange={handleOnChange}
                                    disabled={disableQuantityInput}
                                    ref={inputRef}
                                    readOnly={multiple}
                                    tabIndex={tabIndex}
                                />
                            )}
                        </div>
                        {!multiple && (
                            <InputGroup.Append>
                                <Button
                                    className={classNames('button-increment', { inactive: disableIncrement })}
                                    variant={disableIncrement ? 'secondary' : 'primary'}
                                    onClick={incrementQuantity}
                                    tabIndex={-1}
                                >
                                    <FlatIcon icon={'plus'} />
                                </Button>
                            </InputGroup.Append>
                        )}
                    </InputGroup>
                    {multiple && multipleButtonLabel && (
                        <Button
                            block
                            className={'btn-multiple'}
                            variant={'link'}
                            loading={saving}
                            onClick={handleOnSelectorClick}
                        >
                            {!saving && <FontAwesomeIcon icon={'edit'} className={'app-icon'} />}
                            {multipleButtonLabel && <FormattedMessage id={multipleButtonLabel} />}
                        </Button>
                    )}
                </Form.Group>
                {showProductPacking && (
                    <div className={'quantity-selector-packing'}>
                        <span className={'app-icon equal'}>=</span>
                        <FlatIcon icon={'box'} />
                        <span className={'value'}>{packingQuantity}</span>
                    </div>
                )}
                {showTotalPrice && (
                    <Form.Group className={'quantity-selector-price'}>
                        <InputGroup>
                            <div className={'form-control'}>
                                <Price price={totalPrice} className={'product-quantity-total-amount'} />
                            </div>
                        </InputGroup>
                    </Form.Group>
                )}
            </Form>
            {needDisplayReStockAlertComp && quantityAlertDisplayMode === QuantityAlertDisplayMode.Insert && (
                <div className="quantity-selector-alerts">
                    <ReStockAlert
                        mode={RestockAlertMode.Insert}
                        reason={outOfStockReason}
                        readonly={!restockAlertEnabled || locked || !showPrices}
                        subscribed={restockAlertSubscribed}
                        onChange={onRestockAlertSubscriptionChange}
                        errors={errors}
                    />
                </div>
            )}
        </div>
    )
}

QuantitySelector.defaultProps = {
    min: 0,
    packing: 1,
    showPacking: false,
    throttle: 250,
    currentValue: 0,
    disabled: false,
    locked: false,
    outOfStock: false,
    showPrices: true,
    multiple: false,
    showTotalPrice: false,
    saving: false,
    multipleButtonLabel: undefined,
    canIncrement: true,
    outOfStockLabel: 'product.out_of_stock',
    restockAlertSubscribed: false,
    maxLength: 13,
    restockAlertOutOfStockButtonOnly: false,
    quantityAlertDisplayMode: QuantityAlertDisplayMode.None,
} as Partial<IProps>

export default memo(QuantitySelector)
