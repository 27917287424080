/**
 *
 * Divider
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { CmsWidgetTheme, ICmsWidgetReinsurance } from '../../../../services/api/service/cms/types'

interface IProps {
    widget: ICmsWidgetReinsurance
}

function Reinsurance({ widget }: IProps): JSX.Element {
    return (
        <div
            className={classNames(
                'cms-widget',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                {widget.configuration.title && (
                    <div className={'heading'}>
                        <p className={'h1 hv'}>{widget.configuration.title}</p>
                    </div>
                )}
                <div className={`cms-widget-${widget.widget}-item-list`}>
                    {widget.configuration.items.map((item, index) => (
                        <div
                            className={`cms-widget-${widget.widget}-item`}
                            key={`cms-widget-${widget.widget}-${widget.id}-item-${index}`}
                        >
                            {item.icon && <img src={item.icon} alt={item.title || ''} className={'reinsurance-icon'} />}
                            {item.title && <p className={'title'}>{item.title}</p>}
                            {item.subtitle && <div className={'content'}>{ReactHtmlParser(item.subtitle)}</div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

Reinsurance.defaultProps = {} as Partial<IProps>

export default memo(Reinsurance)
