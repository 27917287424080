import Component, { Props as SidebarProps } from '../../../components/Products/Sidebar/Sidebar'
import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProductListSidebarMode } from '../../../store/products/utils'
import useProductsListDisplayMode from '../../../utils/hook/useProductsListDisplayMode'
import { IApplicationRootState } from '../../../store'
import { makeSelectProductsListSidebarMode } from '../../../store/products/selectors'
import { Undefinable } from 'tsdef'
import { productListSidebarChangeMode } from '../../../store/products/actions'

const Sidebar: React.FC<Omit<SidebarProps, 'onToggleSidebarVisibility' | 'mode'>> = function ({ ...rest }) {
    const dispatch = useDispatch()
    const { displayMode } = useProductsListDisplayMode()
    const selectSidebarModeWithDisplayMode = useMemo(makeSelectProductsListSidebarMode, [])
    const mode: Undefinable<ProductListSidebarMode> = useSelector<
        IApplicationRootState,
        Undefinable<ProductListSidebarMode>
    >((state) => selectSidebarModeWithDisplayMode(state, displayMode))

    const sidebarMode = useMemo(() => {
        return mode || ProductListSidebarMode.Collapsed
    }, [mode])

    const handleClick = useCallback(() => {
        const newSidebarMode =
            ProductListSidebarMode.Expanded === sidebarMode
                ? ProductListSidebarMode.Collapsed
                : ProductListSidebarMode.Expanded
        dispatch(productListSidebarChangeMode(displayMode, newSidebarMode, true))
    }, [dispatch, displayMode, sidebarMode])

    return <Component {...rest} onToggleSidebarVisibility={handleClick} mode={sidebarMode} />
}

export default memo(Sidebar)
