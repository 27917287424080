/**
 *
 * Leaderboard
 *
 */
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions } from 'swiper/types/modules/navigation'
import { PaginationOptions } from 'swiper/types/modules/pagination'
// import Swiper core and required components
import SwiperClass from 'swiper/types/swiper-class'
import {
    CmsWidgetTheme,
    CmsWidgetType,
    ICmsWidgetLeaderBoard,
    ICmsWidgetLeaderBoardSlide,
} from '../../../../services/api/service/cms/types'
import { LeaderboardSlideClickCallback } from '../../../../types/leaderboard'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import isExternalRegexClosure from '../../../../utils/location'
import FlatIcon from '../../../Icon/FlatIcon'
import Slide from './LeaderboardSlide'

// install Swiper components
SwiperCore.use([Controller, Navigation, Pagination, A11y, Autoplay])

interface IProps {
    widget: ICmsWidgetLeaderBoard
    onSlideClick?: LeaderboardSlideClickCallback
    onLinkClick?: CmsWidgetLinkClickCallback
}

function Leaderboard({ widget, onSlideClick, onLinkClick }: IProps): JSX.Element {
    const [previousSlide, setPreviousSlide] = useState<ICmsWidgetLeaderBoardSlide | null>(null)
    const [nextSlide, setNextSlide] = useState<ICmsWidgetLeaderBoardSlide | null>(null)
    const currentRef = useRef<SwiperClass | undefined>(undefined)
    const slideCount = widget.configuration.slides.length
    const activeLoop = slideCount > 1
    const showNavigation = slideCount > 1
    const cmsIdentifierKey = `cms-widget-${widget.widget}-${widget.id}`

    const handleOnSlideClick = useCallback(
        (slide: ICmsWidgetLeaderBoardSlide) => {
            if (onSlideClick) {
                onSlideClick(slide)
            }
            if (onLinkClick) {
                onLinkClick({
                    widget: CmsWidgetType.LeaderBoard,
                    url: slide.link?.url || null,
                    text: slide.title,
                    internal:
                        slide.link?.internal || (slide.link?.url ? !isExternalRegexClosure(slide.link?.url) : false),
                    blank: slide.link?.blank || false,
                    extra: slide,
                })
            }
        },
        [onSlideClick, onLinkClick]
    )

    const handleSlideInit = useCallback(() => {
        if (slideCount > 1) {
            setPreviousSlide(widget.configuration.slides[slideCount - 1])
            setNextSlide(widget.configuration.slides[1])
        }
    }, [widget, setPreviousSlide, setNextSlide, slideCount])

    const handleSlideChange = useCallback(() => {
        const currentInstance = currentRef.current
        if (!currentInstance) {
            return
        }
        const realIndex = currentInstance.realIndex
        setPreviousSlide(
            widget.configuration.slides[realIndex - 1]
                ? widget.configuration.slides[realIndex - 1]
                : widget.configuration.slides[widget.configuration.slides.length - 1]
        )
        setNextSlide(
            widget.configuration.slides[realIndex + 1]
                ? widget.configuration.slides[realIndex + 1]
                : widget.configuration.slides[0]
        )
    }, [widget, setPreviousSlide, setNextSlide, currentRef])

    const navOptions: NavigationOptions | false = useMemo(() => {
        if (slideCount <= 1 || !showNavigation) {
            return false
        }
        return {
            prevEl: `.swiper-app-slider-prev`,
            nextEl: `.swiper-app-slider-next`,
        }
    }, [slideCount, showNavigation])

    const paginationOptions: PaginationOptions | false = useMemo(() => {
        if (!showNavigation) {
            return false
        }
        return {
            clickable: true,
            el: `.swiper-pagination`,
            type: 'bullets',
        }
    }, [showNavigation])

    const autoplayOptions = useMemo(() => {
        return { delay: 5000, disableOnInteraction: true }
    }, [])

    if (widget.configuration.slides.length === 0) {
        return <></>
    }

    return (
        <div
            id={cmsIdentifierKey}
            className={classNames(
                'cms-widget',
                'cms-widget-app-slider',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                { 'app-slider-slide-full-width': slideCount === 1 },
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <Swiper
                    navigation={navOptions}
                    pagination={paginationOptions}
                    onSwiper={(instance) => {
                        currentRef.current = instance
                    }}
                    onInit={handleSlideInit}
                    onSlideChange={handleSlideChange}
                    slidesPerView={'auto'}
                    slidesPerGroup={1}
                    spaceBetween={30}
                    autoplay={autoplayOptions}
                    loop={activeLoop}
                    centeredSlides={slideCount > 1}
                >
                    {widget.configuration.slides.map((item: ICmsWidgetLeaderBoardSlide, index: number) => {
                        return (
                            <SwiperSlide key={`leaderboard_slide_${index}`}>
                                <Slide slide={item} ready={true} onClick={handleOnSlideClick} />
                            </SwiperSlide>
                        )
                    })}
                    <div slot="container-end">
                        {showNavigation && (
                            <Container className={'app-slider-navigation'}>
                                <button
                                    style={{
                                        backgroundColor: `${
                                            previousSlide?.banner_background_color
                                                ? previousSlide?.banner_background_color
                                                : '#3b3c41'
                                        }`,
                                        color: `${
                                            nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                        }`,
                                    }}
                                    className={classNames('swiper-button-prev', 'swiper-app-slider-prev', {
                                        disabled: !previousSlide,
                                    })}
                                >
                                    <span
                                        style={{
                                            color: `${
                                                nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                            }`,
                                        }}
                                    >
                                        {previousSlide?.title}
                                    </span>
                                    <FlatIcon
                                        icon={'arrow-left'}
                                        style={{
                                            color: `${
                                                nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                            }`,
                                        }}
                                    />
                                </button>
                                <button
                                    style={{
                                        backgroundColor: `${
                                            nextSlide?.banner_background_color
                                                ? nextSlide?.banner_background_color
                                                : '#3b3c41'
                                        }`,
                                        color: `${
                                            nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                        }`,
                                    }}
                                    className={classNames('swiper-button-next', 'swiper-app-slider-next', {
                                        disabled: !nextSlide,
                                    })}
                                >
                                    <span
                                        style={{
                                            color: `${
                                                nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                            }`,
                                        }}
                                    >
                                        {nextSlide?.title}
                                    </span>
                                    <FlatIcon
                                        icon={'arrow-right'}
                                        style={{
                                            color: `${
                                                nextSlide?.banner_text_color ? nextSlide?.banner_text_color : '#FFFFFF'
                                            }`,
                                        }}
                                    />
                                </button>
                                <div className="swiper-pagination" />
                            </Container>
                        )}
                    </div>
                </Swiper>
            </div>
        </div>
    )
}

Leaderboard.defaultProps = {} as Partial<IProps>

export default Leaderboard
