import { ICustomerMenu, ICustomerMenuItemType } from '../types/customer'
import { IMe } from '../services/api/service/me/types'
import { ICustomer } from '../services/api/service/customers/types'
import { isSalesmanResource, isStatsMode } from '../store/salesmens/utils'
import { canExportPictures } from '../store/pictureExport/utils'
import { customerCanListCarts, customerShowPrices } from '../store/customers/utils'
import { ISalesmanStatsMode } from '../services/api/service/salesman/types'

export default function getCustomerMenu(me: IMe, customer: ICustomer): ICustomerMenu {
    const isSalesman = isSalesmanResource(me)

    let items: ICustomerMenu = [
        {
            type: ICustomerMenuItemType.Imports,
            label: isSalesman ? 'salesman_customer.imports' : 'customer.imports',
            route: 'customerImports',
            icon: 'import',
            position: 30,
        },
        {
            type: ICustomerMenuItemType.Exports,
            label: isSalesman ? 'salesman_customer.exports' : 'customer.exports',
            route: 'customerExports',
            icon: 'export',
            position: 40,
        },
        {
            type: ICustomerMenuItemType.Stores,
            label: isSalesman ? 'salesman_customer.stores' : 'customer.stores',
            route: 'customerStores',
            icon: 'shopping-store',
            position: 50,
        },
        {
            type: ICustomerMenuItemType.Salesmen,
            label: 'default.sales_contact',
            route: 'customerSalesmen',
            icon: 'contact',
            position: 60,
        },
        {
            type: ICustomerMenuItemType.Modify_Login,
            label: 'default.password',
            route: 'customerModifyLogin',
            icon: 'settings',
            position: 70,
        },
        {
            type: ICustomerMenuItemType.Assistance,
            label: 'customer.assistance',
            icon: 'question',
            position: 80,
        },
        // {
        //     type: ICustomerMenuItemType.FavoriteDepartment,
        //     label: 'customer.favorite_departments',
        //     icon: 'tree-structure',
        //     position: 100,
        // },
        {
            type: ICustomerMenuItemType.Logout,
            label: 'default.logout',
            route: 'logout',
            icon: 'logout',
            position: 110,
        },
    ]

    if (customerCanListCarts(customer, me)) {
        items = [
            ...items,
            {
                type: ICustomerMenuItemType.Carts,
                label: 'customer.carts',
                route: 'customerCarts',
                icon: 'shopping-cart',
                position: 51,
            },
        ]
    }

    if (!isSalesman || !isStatsMode(me, ISalesmanStatsMode.NoStats)) {
        items = [
            ...items,
            {
                type: ICustomerMenuItemType.Dashboard,
                label: 'customer.dashboard',
                route: 'customerDashboard',
                icon: 'statistics',
                position: 0,
            },
        ]
    }

    if (customerShowPrices(customer, me) && (!isSalesman || !isStatsMode(me, ISalesmanStatsMode.NoStats))) {
        items = [
            ...items,
            {
                type: ICustomerMenuItemType.Orders,
                label: isSalesman ? 'salesman_customer.orders' : 'customer.orders',
                route: 'customerOrders',
                icon: 'list',
                sub_icon: 'check',
                position: 10,
            },
            {
                type: ICustomerMenuItemType.Invoices,
                label: isSalesman ? 'salesman_customer.invoices' : 'customer.invoices',
                route: 'customerInvoices',
                icon: 'receipt',
                position: 20,
            },
        ]
    }

    if (canExportPictures(me)) {
        items = [
            ...items,
            {
                type: ICustomerMenuItemType.PictureExport,
                label: 'customer.picture_export',
                route: 'customerPictureExports',
                icon: 'download',
                position: 90,
            },
        ]
    }

    if (isSalesman && !isStatsMode(me, ISalesmanStatsMode.NoStats)) {
        items = [
            ...items,
            {
                type: ICustomerMenuItemType.Assets,
                label: 'order_mode.assets',
                route: 'customerAssets',
                icon: 'budget',
                position: 11,
            },
        ]
    }

    return items.sort((a, b) => {
        if (a.position < b.position) {
            return -1
        } else if (a.position > b.position) {
            return 1
        } else {
            return 0
        }
    })
}
