import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import {
    IApiMeTopProductsPagination,
    IMeStatisticsTopProductsParameters,
    IMemberTopSalesEntries,
    IMeYearlyStatEntries,
    IStatisticsFamilyTreeCollection,
    IMeStatisticsTopSalesParameters,
} from '../../services/api/service/stats/types'
import {
    IMemberClassificationProcessPayload,
    IMemberStatisticsClassificationFilters,
    IMemberTopProductsProcessPayload,
    IMemberTopSalesProcessPayload,
} from './types'
import { IAppErrorTypes } from '../app/types'

export const statisticsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const statisticsCustomerClassificationProcessAction = (filters: IMemberStatisticsClassificationFilters) =>
    action<ActionTypes.CLASSIFICATION_PROCESS_ACTION, IMemberClassificationProcessPayload>(
        ActionTypes.CLASSIFICATION_PROCESS_ACTION,
        {
            filters,
        }
    )
export const statisticsCustomerClassificationResetAction = () =>
    action<ActionTypes.CLASSIFICATION_RESET_ACTION>(ActionTypes.CLASSIFICATION_RESET_ACTION)
export const statisticsCustomerClassificationSuccessAction = (familyTree: IStatisticsFamilyTreeCollection) =>
    action<ActionTypes.CLASSIFICATION_SUCCESS_ACTION, IStatisticsFamilyTreeCollection>(
        ActionTypes.CLASSIFICATION_SUCCESS_ACTION,
        familyTree
    )

export const statisticsCustomerClassificationFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.CLASSIFICATION_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.CLASSIFICATION_FAILURE_ACTION,
        { error }
    )

export const statisticsCustomerTopSalesProcessAction = (params: IMeStatisticsTopSalesParameters) =>
    action<ActionTypes.TOP_SALES_PROCESS_ACTION, IMemberTopSalesProcessPayload>(ActionTypes.TOP_SALES_PROCESS_ACTION, {
        params,
    })
export const statisticsCustomerTopSalesSuccessAction = (entries: IMemberTopSalesEntries) =>
    action<ActionTypes.TOP_SALES_SUCCESS_ACTION, IMemberTopSalesEntries>(ActionTypes.TOP_SALES_SUCCESS_ACTION, entries)

export const statisticsCustomerTopSalesFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.TOP_SALES_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.TOP_SALES_FAILURE_ACTION, {
        error,
    })

export const statisticsCustomerTopSalesResetAction = () =>
    action<ActionTypes.TOP_SALES_RESET_ACTION>(ActionTypes.TOP_SALES_RESET_ACTION)

export const statisticsCustomerTopProductsProcessAction = (params: IMeStatisticsTopProductsParameters) =>
    action<ActionTypes.TOP_PRODUCTS_PROCESS_ACTION, IMemberTopProductsProcessPayload>(
        ActionTypes.TOP_PRODUCTS_PROCESS_ACTION,
        {
            params,
        }
    )
export const statisticsCustomerTopProductsSuccessAction = (response: IApiMeTopProductsPagination) =>
    action<ActionTypes.TOP_PRODUCTS_SUCCESS_ACTION, IApiMeTopProductsPagination>(
        ActionTypes.TOP_PRODUCTS_SUCCESS_ACTION,
        response
    )

export const statisticsCustomerTopProductsFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.TOP_PRODUCTS_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.TOP_PRODUCTS_FAILURE_ACTION,
        { error }
    )

export const statisticsCustomerTopProductsResetAction = () =>
    action<ActionTypes.TOP_PRODUCTS_RESET_ACTION>(ActionTypes.TOP_PRODUCTS_RESET_ACTION)

export const statisticsYearlyStatsResetAction = () =>
    action<ActionTypes.YEARLY_STATS_RESET_ACTION>(ActionTypes.YEARLY_STATS_RESET_ACTION)

export const statisticsYearlyStatsProcessAction = () =>
    action<ActionTypes.YEARLY_STATS_PROCESS_ACTION>(ActionTypes.YEARLY_STATS_PROCESS_ACTION)
export const statisticsYearlyStatsSuccessAction = (entries: IMeYearlyStatEntries) =>
    action<ActionTypes.YEARLY_STATS_SUCCESS_ACTION, IMeYearlyStatEntries>(
        ActionTypes.YEARLY_STATS_SUCCESS_ACTION,
        entries
    )

export const statisticsYearlyStatsFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.YEARLY_STATS_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.YEARLY_STATS_FAILURE_ACTION,
        { error }
    )
