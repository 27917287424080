/**
 *
 * Product Carousel
 *
 */
import React, { memo, useCallback } from 'react'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { IBreakpointMap } from '../../../types/common'
import { ProductClickCallback } from '../../../types/productCallback'
import { CmsWidgetLinkClickCallback, ICmsWidgetLink } from '../../../types/widget'
import Button from '../../Buttons/Button'
import { default as CarouselApp } from '../../Carousel/Carousel'
import { default as CarouselWrapper } from '../../Carousel/Wrapper'
import { default as ProductCarouselItem } from './Carouseltem'

interface IProps {
    title?: string
    subTitle?: string
    loop: boolean
    showPrices: boolean
    detail?: ICmsWidgetLink
    autoplay: boolean
    autoplayDelay: number
    products: Array<IProductCatalog>
    onProductClick?: ProductClickCallback
    onDetailButtonClick?: CmsWidgetLinkClickCallback
    breakpoints: IBreakpointMap
}

function Carousel({
    products,
    showPrices,
    loop,
    autoplay,
    autoplayDelay,
    onProductClick,
    onDetailButtonClick,
    title,
    subTitle,
    detail,
    breakpoints,
}: IProps): JSX.Element {
    const handleOnProductClick = useCallback(
        (product: IProductCatalog) => {
            if (onProductClick) {
                onProductClick(product)
            }
        },
        [onProductClick]
    )

    const handleOnBtnDetailClick = useCallback(() => {
        if (onDetailButtonClick) {
            onDetailButtonClick(detail!)
        }
    }, [detail, onDetailButtonClick])

    let Footer: JSX.Element | null = null
    if (detail) {
        Footer = (
            <div className={'product-carousel-footer'}>
                <Button variant={'primary'} className={'btn-detail'} size={'lg'} onClick={handleOnBtnDetailClick}>
                    {detail?.text}
                </Button>
            </div>
        )
    }

    if (!products || products.length === 0) {
        return <></>
    }

    return (
        <CarouselWrapper className={'product-carousel'} title={title} subTitle={subTitle} footer={Footer}>
            <CarouselApp
                slidesPerView={2}
                slidesPerGroup={1}
                spaceBetween={15}
                autoplay={autoplay ? { delay: autoplayDelay, disableOnInteraction: true } : undefined}
                loop={loop}
                breakpoints={breakpoints}
                centerInsufficientSlides={true}
            >
                {products.map((product: IProductCatalog) => {
                    return (
                        <div key={`product_carousel_item_${product['@id']}`}>
                            <ProductCarouselItem
                                onProductClick={handleOnProductClick}
                                product={product}
                                showPrices={showPrices}
                            />
                        </div>
                    )
                })}
            </CarouselApp>
        </CarouselWrapper>
    )
}

Carousel.defaultProps = {
    loop: false,
    autoplay: true,
    showPrices: true,
    autoplayDelay: 5000,
    breakpoints: {
        // when window width is >= 480px
        720: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        // when window width is >= 640px
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
} as Partial<IProps>

export default memo(Carousel)
