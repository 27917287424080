/**
 *
 * CmsPage
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ICmsBlock } from '../../services/api/service/cms/types'
import { IAppErrorTypes } from '../../store/app/types'
import { CmsWidgetCallbackList, ICmsWidgetLink } from '../../types/widget'
import redirectCmsWidgetLink from '../../utils/widget'
import CmsWidgetList from '../CmsWidget/CmsWidgetList'

type IProps = CmsWidgetCallbackList & {
    item: ICmsBlock
    fetching: boolean
    error?: IAppErrorTypes
    className?: string
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
function CmsBlock({ item, fetching, className, error, ...callbacks }: IProps): JSX.Element {
    const history = useHistory()
    const location = useLocation()

    const handleCmsWidgetLinkClick = useCallback(
        (link: ICmsWidgetLink) => {
            if (callbacks.onCmsWidgetLinkClick) {
                callbacks.onCmsWidgetLinkClick(link)
            }
            redirectCmsWidgetLink(link, history, location)
        },
        [callbacks, history, location]
    )

    return (
        <>
            <div
                className={classNames(
                    'cms-block',
                    { 'cms-block-loading': fetching },
                    { 'cms-block-loaded': !fetching && item },
                    `cms-page-${item ? item.code : 'load'}`,
                    className
                )}
            >
                {item && (
                    <div className={'cms-block-content'}>
                        <CmsWidgetList
                            widgets={item.parts}
                            {...callbacks}
                            onCmsWidgetLinkClick={handleCmsWidgetLinkClick}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

CmsBlock.defaultProps = {
    fetching: true,
} as Partial<IProps>

export default memo(CmsBlock)
