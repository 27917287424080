import { IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { AxiosResponse, CancelToken } from 'axios'
import { ICustomerType } from '../customers/types'
import { IProductCatalog } from '../products/types'
import { $PropertyType } from 'utility-types'

export enum IApiShopImportStateList {
    Wait = 'wait',
    Processing = 'processing',
    Error = 'error',
    Completed = 'completed',
}

export enum ShopImportType {
    Cart = 'cart',
    CustomerStock = 'customer_stock',
}

export enum ShopImportListType {
    Global = 'global',
    Cart = 'cart',
    CustomerStock = 'customer_stock',
}

export enum ShopImportEntityType {
    CartImport = 'CartImport',
    CustomerStockImport = 'CustomerStockImport',
}

export interface IApiBaseCreateShopImportParameters {}

export interface IApiShopImportList<T = ShopImportEntityType> extends IApiResource<T> {
    readonly state: IApiShopImportStateList
    readonly created_at: string
    readonly error_lines_count?: number
    readonly success_lines_count?: number
}

export interface IShopImportError {
    readonly line: number | null
    readonly message: string
    readonly warning: boolean
    readonly product_reference?: $PropertyType<IProductCatalog, 'reference'>
    readonly product?: Pick<IProductCatalog, '@id' | '@type' | 'can_add_stock_alert' | 'has_stock_alert' | 'id'>
}

export type ShopImportErrorCollection = Array<IShopImportError>
export interface IApiShopImportDetail<T = ShopImportEntityType> extends IApiShopImportList<T> {
    readonly customer?: string
    errors: ShopImportErrorCollection
}

export type IApiShopImportListCollection = Array<IApiShopImportList>
export type IApiShopImportListParameters = IBasePaginationParameters
export type IApiShopImportListPagination = IPaginationApiResponse<IApiShopImportListCollection>
export type IApiShopImportListResponse = AxiosResponse<IApiShopImportListPagination>

export interface IShopImportService {
    list(parameters: IApiShopImportListParameters, cancelToken?: CancelToken): Promise<IApiShopImportListResponse>
    getImportFileUrl(locale: string, shopImportType: ShopImportType, customerType: ICustomerType): string
}
