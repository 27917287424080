import { IApiResource, ICollectionApiResponse } from '../../types'
import { AxiosResponse } from 'axios'
import { IShippingLocation } from '../classification/types'

export enum ISalesmanStatsMode {
    NoStats = 'no_stats', // pas d'accès aux stats
    OnlyMyActivity = 'only_my_activity', // accès à son activité seulement
    AllActivity = 'all_activity', // accès à toute l'activité de ses clients
    Both = 'both', // permet de choisir s'il veut voir son activité seulement ou toute l'activité de ses clients
}

export interface ISalesman extends IApiResource {
    readonly gender?: string
    readonly name: string
    readonly telephone?: string
    readonly email?: string
    readonly image?: string
    readonly shipping_location?: string | null
    readonly allow_all_customers: boolean
    readonly is_manager: boolean
    readonly stats_mode: ISalesmanStatsMode
}

export interface IManagerSalesman extends IApiResource {
    readonly name: string
}

export interface ICustomerSalesman extends IApiResource {
    readonly shipping_location?: Pick<IShippingLocation, '@id' | 'full_name'>
    readonly salesman: Omit<ISalesman, 'id'>
}

export type ISalesmenCollection = Array<ISalesman>
export type ICustomerSalesmenCollection = Array<ICustomerSalesman>
export type IApiCustomerSalesmenResponse = AxiosResponse<ICollectionApiResponse<ICustomerSalesmenCollection>>

export interface ISalesmanService {
    getCustomerSalesmen(): Promise<IApiCustomerSalesmenResponse>
}
