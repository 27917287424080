import { call, getContext, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import ClassificationActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    IClassificationCategoryProcessAction,
    IClassificationTreeState,
    IFamilyTreeRequestProcessAction,
    IFavoriteDepartmentAssociateProcessAction,
    IFavoriteDepartmentDeleteProcessAction,
    IFavoriteDepartmentEditProcessAction,
} from './types'
import {
    classificationCategorySuccessAction,
    classificationCategorySuccessFailureAction,
    classificationFamilyTreeFailureAction,
    classificationFamilyTreeProcessAction,
    classificationFamilyTreeSuccessAction,
    classificationFavoriteDepartmentAssociateFailureAction,
    classificationFavoriteDepartmentAssociateProcessAction,
    classificationFavoriteDepartmentAssociateSuccessAction,
    classificationFavoriteDepartmentDeleteFailureAction,
    classificationFavoriteDepartmentDeleteSuccessAction,
    classificationFavoriteDepartmentEditFailureAction,
    classificationFavoriteDepartmentEditSuccessAction,
    classificationFavoriteDepartmentListFailureAction,
    classificationFavoriteDepartmentListSuccessAction,
    classificationStoreMenuAction,
} from './actions'
import { formatApiError, formatAppError } from '../app/saga'
import { formatFamilyTree, formatMenu, formatSideMenuItems } from './utils'
import {
    IProductListPersistParameters,
    ProductListStaticFilterCodes,
    ProductsListMode,
} from '../../services/api/service/products/types'
import { makeSelectProductsListPersistSettings } from '../products/selectors'
import {
    IApiFamiliesTreeResponse,
    IApiMenuResponse,
    IClassificationParameters,
} from '../../services/api/service/classification/types'
import ProductsActionTypes from '../products/constants'
import { makeSelectClassificationFamilyTree, makeSelectClassificationFamilyTreeDefault } from './selectors'
import { $enum } from 'ts-enum-util'
import { selectLocale } from '../intl/selectors'
import { generatePath } from 'react-router'
import { getPath } from '../../routes'
import { sideMenuStoreItemsAction } from '../sidemenu/actions'
import {
    IApiFavoriteEditDepartmentResponse,
    IApiFavoriteListDepartmentResponse,
    IFavoriteDepartmentAssociateData,
} from '../../services/api/service/favorite/types'
import { productsListRefreshAction } from '../products/actions'

function* processClassificationCategoryRequest(action: IClassificationCategoryProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const { slug } = action.payload
        const response = yield call({ context: api.classification, fn: 'getCategoryBySlug' }, slug)
        if (response) {
            yield put(classificationCategorySuccessAction(response))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'category.page.error')
        yield put(classificationCategorySuccessFailureAction(error))
    }
}

function* processClassificationFamilyTreeRefresh(action: IFamilyTreeRequestProcessAction) {
    yield put(classificationFamilyTreeProcessAction(action.payload.mode))
}

export function* processClassificationFamilyTreeRequest(action: IFamilyTreeRequestProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        // LISTED ONLY ?
        const settings: IProductListPersistParameters | undefined = yield select(
            makeSelectProductsListPersistSettings()
        )
        let params: IClassificationParameters = { [action.payload.mode]: 1 }
        if (settings && settings.listed_only) {
            params = { ...params, [ProductListStaticFilterCodes.ListedOnly]: 1 }
        }
        const response: IApiFamiliesTreeResponse = yield call(
            { context: api.classification, fn: 'familiesTree' },
            params
        )
        if (response) {
            // formattage page best sellers
            const familyTree = formatFamilyTree(response.data['hydra:member'])
            yield put(classificationFamilyTreeSuccessAction(action.payload.mode, familyTree))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'category.page.error')
        yield put(classificationFamilyTreeFailureAction(action.payload.mode, error))
    }
}

export function* processClassificationMenu() {
    const api: IApiClient = yield getContext('api')
    const locale = yield select(selectLocale)
    try {
        const tree = yield select(makeSelectClassificationFamilyTreeDefault())

        // LISTED ONLY ?
        const settings: IProductListPersistParameters | undefined = yield select(
            makeSelectProductsListPersistSettings()
        )
        let params: IClassificationParameters = {}
        if (settings && settings.listed_only) {
            params = { ...params, [ProductListStaticFilterCodes.ListedOnly]: 1 }
        }
        const response: IApiMenuResponse = yield call({ context: api.classification, fn: 'menu' }, params)

        // formattage du menu
        const menu = formatMenu(response.data['hydra:member'], locale)

        // formattage du menu mobile
        const baseMobilePath = generatePath(getPath('family', locale), { lang: locale })
        const mobileMenu = tree ? formatSideMenuItems(tree, menu, baseMobilePath) : []

        // stockage de toutes les informations
        yield put(classificationStoreMenuAction(menu))
        yield put(sideMenuStoreItemsAction(mobileMenu))
    } catch (e) {
        console.error(e)
    }
}

function* processClassificationRefreshFamilyTrees() {
    const tree: IClassificationTreeState = yield call(makeSelectClassificationFamilyTree)
    const modes = Object.keys(tree)
    for (const mode of modes) {
        if ($enum(ProductsListMode).isValue(mode) && mode !== ProductsListMode.Category) {
            yield put(classificationFamilyTreeProcessAction(mode))
        }
    }
}

function* processClassificationFavoriteDepartmentAssociate(action: IFavoriteDepartmentAssociateProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { data } = action.payload

    try {
        yield call({ context: api.favorite, fn: 'associateDepartment' }, data)

        // stockage de toutes les informations
        yield put(classificationFavoriteDepartmentAssociateSuccessAction())
    } catch (e) {
        console.error(e)
        const error = yield call(formatApiError, e, `favorite_department.associate.error`)
        yield put(classificationFavoriteDepartmentAssociateFailureAction(error.error))
    }
}

function* processClassificationFavoriteDepartmentDelete(action: IFavoriteDepartmentDeleteProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { identifier } = action.payload

    try {
        yield call({ context: api.favorite, fn: 'removeDepartment' }, identifier)

        // success !
        yield put(classificationFavoriteDepartmentDeleteSuccessAction())

        // refresh !
        yield put(productsListRefreshAction())
    } catch (e) {
        console.error(e)
        const error = yield call(formatApiError, e, `favorite_department.delete.error`)
        yield put(classificationFavoriteDepartmentDeleteFailureAction(error.error))
    }
}

function* processClassificationFavoriteDepartmentEdit(action: IFavoriteDepartmentEditProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { identifier, data, products } = action.payload

    try {
        let response: IApiFavoriteEditDepartmentResponse
        if (identifier) {
            response = yield call({ context: api.favorite, fn: 'editDepartment' }, identifier, data)
        } else {
            response = yield call({ context: api.favorite, fn: 'createDepartment' }, data)
        }

        // stockage de toutes les informations
        yield put(classificationFavoriteDepartmentEditSuccessAction(response.data))

        if (products && products.length > 0) {
            const data: IFavoriteDepartmentAssociateData = {
                department: response.data['@id'],
                products,
            }
            yield put(classificationFavoriteDepartmentAssociateProcessAction(data))
        }
    } catch (e) {
        console.error(e)
        const error = yield call(formatApiError, e, `favorite_department.${identifier ? 'edit' : 'create'}.error`)
        yield put(classificationFavoriteDepartmentEditFailureAction(error.error))
    }
}

function* processClassificationFavoriteDepartmentList() {
    const api: IApiClient = yield getContext('api')
    try {
        const response: IApiFavoriteListDepartmentResponse = yield call({ context: api.favorite, fn: 'listDepartment' })

        // stockage de toutes les informations
        yield put(classificationFavoriteDepartmentListSuccessAction(response.data['hydra:member']))
    } catch (e) {
        console.error(e)
        const error = yield call(formatApiError, e, `favorite_department.retrieve_list.error`)
        yield put(classificationFavoriteDepartmentListFailureAction(error.error))
    }
}

export default [
    takeLatest(ClassificationActionTypes.CATEGORY_PROCESS_ACTION, processClassificationCategoryRequest),
    takeEvery(ClassificationActionTypes.FAMILY_TREE_PROCESS_ACTION, processClassificationFamilyTreeRequest),
    takeLatest(ClassificationActionTypes.FAMILY_TREE_REFRESH_ACTION, processClassificationFamilyTreeRefresh),
    takeLatest(ClassificationActionTypes.MENU_PROCESS_ACTION, processClassificationMenu),
    takeLatest(
        ClassificationActionTypes.FAVORITE_DEPARTMENT_LIST_PROCESS_ACTION,
        processClassificationFavoriteDepartmentList
    ),
    takeLatest(ProductsActionTypes.PERSISTED_PARAMS_ACTION, processClassificationRefreshFamilyTrees),
    takeLatest(
        ClassificationActionTypes.FAVORITE_DEPARTMENT_EDIT_PROCESS_ACTION,
        processClassificationFavoriteDepartmentEdit
    ),
    takeLatest(
        ClassificationActionTypes.FAVORITE_DEPARTMENT_DELETE_PROCESS_ACTION,
        processClassificationFavoriteDepartmentDelete
    ),
    takeLatest(
        ClassificationActionTypes.FAVORITE_DEPARTMENT_ASSOCIATE_PROCESS_ACTION,
        processClassificationFavoriteDepartmentAssociate
    ),
]
