import React, { memo, useCallback, useMemo } from 'react'
import {
    IProductGridDataResponse,
    IProductList,
    IProductsListGridDefinition,
} from '../../../../services/api/service/products/types'
import { IProps as ProductItemListProps, ProductListMode } from '../../../../components/Product/List/List'
import { $PropertyType } from 'utility-types'
import { default as ProductItemList } from '../../Partial/Item'
import classNames, { Value as ClassValue } from 'classnames'
import { Col, Row } from 'react-bootstrap'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import ButtonApp from '../../../../components/Buttons/Button'
import { BulkCartQuantityAction } from '../../../../store/carts/types'
import { $enum } from 'ts-enum-util'

const { contrastColor } = require('contrast-color')

type Props = Omit<
    ProductItemListProps,
    | 'className'
    | 'product'
    | 'picked'
    | 'showFavorite'
    | 'excludeBadges'
    | 'productCustomerInfoParts'
    | 'mode'
    | 'disabledPicker'
> & {
    className: ClassValue
    rows: $PropertyType<IProductGridDataResponse, 'rows'>
    columns: $PropertyType<IProductGridDataResponse, 'columns'>
    items: $PropertyType<IProductGridDataResponse, 'items'>
    bulkAddingToCart?: boolean
    onMassBulkCartQuantityClick?: (action: BulkCartQuantityAction, definition?: IProductsListGridDefinition) => void
}

function Products({
    definition,
    parentKey,
    items,
    ...rest
}: {
    definition: IProductsListGridDefinition
    parentKey: string
} & Omit<Props, 'className' | 'rows' | 'columns'>): JSX.Element {
    const subs = useMemo(() => {
        return definition.children.length > 0 ? definition.children : [definition]
    }, [definition])

    return (
        <>
            {subs.map((sub) => {
                // récupération des produits
                const finalId = [parentKey, definition.key, definition.key === sub.key ? undefined : sub.key]
                    .filter((value) => typeof value === 'string')
                    .join('/')
                const products = items[finalId] || []
                return (
                    <div className="column products" key={`${definition.key}_${sub.key}`}>
                        <Row>
                            {products.map((product: IProductList) => (
                                <Col xs={24} key={`items_${finalId}_product_item_${product['@id']}`}>
                                    <ProductItemList
                                        className={'products-list-item'}
                                        product={product}
                                        excludeBadges={undefined}
                                        mode={ProductListMode.Light}
                                        disabledPicker={true}
                                        showFavorite={false}
                                        {...rest}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                )
            })}
        </>
    )
}

function Definition({
    className,
    definition,
    onBulkCartQuantityClick,
    disabledBulkCartQuantity,
}: {
    className: ClassValue
    definition: IProductsListGridDefinition
    disabledBulkCartQuantity?: boolean
    onBulkCartQuantityClick?: (action: BulkCartQuantityAction, definition: IProductsListGridDefinition) => void
}): JSX.Element {
    const handleBulkCartQuantityClick = useCallback(
        (e) => {
            const action = e.currentTarget.dataset.action
            if (!$enum(BulkCartQuantityAction).isValue(action)) {
                return
            }
            if (onBulkCartQuantityClick) {
                onBulkCartQuantityClick(action, definition)
            }
        },
        [onBulkCartQuantityClick, definition]
    )

    return (
        <div
            className={classNames('column', 'def', className)}
            style={{
                backgroundColor: definition.color || undefined,
                color: definition.color
                    ? contrastColor({
                          bgColor: definition.color,
                          threshold: 75,
                      })
                    : undefined,
            }}
        >
            <ButtonApp
                className={'btn-sm'}
                variant={'secondary'}
                data-action={BulkCartQuantityAction.Remove}
                disabled={disabledBulkCartQuantity}
                onClick={handleBulkCartQuantityClick}
            >
                <FlatIcon icon={'minus'} />
            </ButtonApp>
            <ButtonApp
                className={'btn-sm'}
                variant={'primary'}
                data-action={BulkCartQuantityAction.Add}
                disabled={disabledBulkCartQuantity}
                onClick={handleBulkCartQuantityClick}
            >
                <FlatIcon icon={'plus'} />
            </ButtonApp>
            <span className="lbl">{definition.label}</span>
        </div>
    )
}

function List({
    className,
    rows,
    columns,
    items,
    onMassBulkCartQuantityClick,
    bulkAddingToCart,
    ...rest
}: Props): JSX.Element {
    const atLeastOneColumnHasChildren = useMemo(() => {
        return Object.values(columns).reduce((sum, next) => sum || next.children.length > 0, false)
    }, [columns])
    const atLeastOneRowHasChildren = useMemo(() => {
        return Object.values(rows).reduce((sum, next) => sum || next.children.length > 0, false)
    }, [rows])

    const handleBulkCartQuantityClick = useCallback(
        (action: BulkCartQuantityAction, definition?: IProductsListGridDefinition) => {
            if (onMassBulkCartQuantityClick) {
                onMassBulkCartQuantityClick(action, definition)
            }
        },
        [onMassBulkCartQuantityClick]
    )

    const handleBulkCartQuantityAllClick = useCallback(
        (e) => {
            const action = e.currentTarget.dataset.action
            if (!$enum(BulkCartQuantityAction).isValue(action)) {
                return
            }

            if (onMassBulkCartQuantityClick) {
                onMassBulkCartQuantityClick(action)
            }
        },
        [onMassBulkCartQuantityClick]
    )

    const columnCount = useMemo(() => {
        let cnt = 0
        columns.forEach((column) => {
            cnt += column.children.length || 1
        })
        return cnt
    }, [columns])

    const rowWidth = useMemo(() => {
        return `${columnCount * 210 + (atLeastOneRowHasChildren ? 120 : 60)}px`
    }, [columnCount, atLeastOneRowHasChildren])

    return (
        <div className={classNames('product-list-grid-container', className)}>
            <div className="product-list-grid">
                <div className="grid">
                    <div
                        style={{ width: rowWidth }}
                        className={`grid-row grid-row-def ${atLeastOneColumnHasChildren ? 'has-subs' : 'no-subs'}`}
                    >
                        <div className={`def-row ${atLeastOneRowHasChildren ? 'multiple' : 'single'}`}>
                            <ButtonApp
                                variant={'secondary'}
                                className="mr-2"
                                disabled={bulkAddingToCart}
                                data-action={BulkCartQuantityAction.Remove}
                                onClick={handleBulkCartQuantityAllClick}
                            >
                                <FlatIcon icon={'minus'} />
                            </ButtonApp>
                            <ButtonApp
                                variant={'primary'}
                                disabled={bulkAddingToCart}
                                data-action={BulkCartQuantityAction.Add}
                                onClick={handleBulkCartQuantityAllClick}
                            >
                                <FlatIcon icon={'plus'} />
                            </ButtonApp>
                        </div>
                        {columns.map((column) => {
                            return (
                                <>
                                    {column.children.length === 0 ? (
                                        <>
                                            <Definition
                                                className="def-col"
                                                definition={column}
                                                key={column.key}
                                                onBulkCartQuantityClick={handleBulkCartQuantityClick}
                                                disabledBulkCartQuantity={bulkAddingToCart}
                                            />
                                        </>
                                    ) : (
                                        <div className="def-sub-col-content">
                                            <Definition
                                                className="def-col"
                                                definition={column}
                                                key={column.key}
                                                onBulkCartQuantityClick={handleBulkCartQuantityClick}
                                                disabledBulkCartQuantity={bulkAddingToCart}
                                            />
                                            <div className="def-sub-col">
                                                {column.children.map((child) => (
                                                    <Definition
                                                        className="def-col"
                                                        definition={child}
                                                        key={`${column.key}_${child.key}`}
                                                        onBulkCartQuantityClick={handleBulkCartQuantityClick}
                                                        disabledBulkCartQuantity={bulkAddingToCart}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                    {rows.map((row) => {
                        return (
                            <>
                                <div className="grid-row" key={row.key} style={{ width: rowWidth }}>
                                    <Definition
                                        className="def-row"
                                        definition={row}
                                        onBulkCartQuantityClick={handleBulkCartQuantityClick}
                                        disabledBulkCartQuantity={bulkAddingToCart}
                                    />
                                    {row.children.length === 0 ? (
                                        <>
                                            {columns.map((column) => {
                                                return (
                                                    <Products
                                                        key={`${row.key}_${column.key}`}
                                                        definition={column}
                                                        parentKey={row.key}
                                                        items={items}
                                                        {...rest}
                                                    />
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <div className="def-sub-row">
                                            {row.children.map((child) => (
                                                <div className="grid-row" key={`${row.key}_${child.key}`}>
                                                    <Definition
                                                        className="def-row"
                                                        definition={child}
                                                        onBulkCartQuantityClick={handleBulkCartQuantityClick}
                                                        disabledBulkCartQuantity={bulkAddingToCart}
                                                    />
                                                    {columns.map((column) => {
                                                        return (
                                                            <Products
                                                                key={`${row.key}_${child.key}_${column.key}`}
                                                                definition={column}
                                                                parentKey={`${row.key}/${child.key}`}
                                                                items={items}
                                                                {...rest}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default memo(List)
