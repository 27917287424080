/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { MouseEvent, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import ShippingLocation from '../../../containers/ShippingLocation/ShippingLocation'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { ProductClickCallback } from '../../../types/productCallback'
import Card from '../Card/Card'
import { default as ProductHeading } from '../Partial/Heading'
import { default as ProductPacking } from '../Partial/Packing'
import { default as ProductPrice } from '../Partial/Price'

interface IProps {
    product: IProductCatalog
    className?: string
    showBadges?: boolean
    showPrices?: boolean
    onProductClick?: ProductClickCallback
}

function LightList({ product, className, onProductClick, showBadges = true, showPrices = true }: IProps): JSX.Element {
    const handleProductClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            if (onProductClick) {
                onProductClick(product)
            }
        },
        [onProductClick, product]
    )

    const Overlay = (
        <div className={classNames('product-content', 'product-overlay')} onClick={handleProductClick}>
            <div className={'product-overlay-inner'}>
                <ProductHeading product={product} />
                <ProductPacking packing={product.packing} unitOfSale={product.unit_of_sale} abbreviateLabels />
                {showPrices && (
                    <ProductPrice
                        price={product.price}
                        strikePrice={product.strike_price}
                        retailPrice={product.retail_price}
                        unitOfSale={product.unit_of_sale}
                        abbreviateLabels
                    />
                )}
                <ShippingLocation shippingLocationId={product.shipping_location} showIcon />
                <button className={'show-detail'} onClick={handleProductClick}>
                    <FormattedMessage id="default.see_detail" />
                </button>
            </div>
        </div>
    )

    return (
        <Card
            product={product}
            overlay={Overlay}
            showBadges={showBadges}
            showPrices={showPrices}
            className={classNames('product-carousel-item', className)}
            loadEagerly
        />
    )
}

export default LightList
