import React from 'react'
import Maintenance from '../containers/Maintenance/Maintenance'

export default function MaintenancePage(): JSX.Element {
    return (
        <>
            <Maintenance />
        </>
    )
}
