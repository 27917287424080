/*
 *
 * Search actions
 *
 */

import { action } from 'typesafe-actions'
import { Action } from 'redux'

import { ISearchSuggestionProcessPayload } from './types'

import ActionTypes from './constants'
import {
    ISearchSuggestionFamilyCollection,
    ISearchSuggestionParameters,
    ISearchSuggestionProductCollection,
} from '../../services/api/service/search/types'
import { IAppErrorTypes } from '../app/types'
import { CancelTokenSource } from 'axios'

export interface ISearchProcessAction extends Action {
    payload?: any | null
}
export const searchSuggestionProcessAction = (params: ISearchSuggestionParameters) =>
    action<ActionTypes.PROCESS_ACTION, ISearchSuggestionProcessPayload>(ActionTypes.PROCESS_ACTION, { params })
export const searchSuggestionSuccessAction = (
    families: ISearchSuggestionFamilyCollection,
    products: ISearchSuggestionProductCollection
) =>
    action<
        ActionTypes.SUCCESS_ACTION,
        { families: ISearchSuggestionFamilyCollection; products: ISearchSuggestionProductCollection }
    >(ActionTypes.SUCCESS_ACTION, { families, products })
export const searchSuggestionFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.FAILURE_ACTION, { error })
export const searchSuggestionResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const searchSuggestionStoreToken = (token: CancelTokenSource) =>
    action<ActionTypes.STORE_CANCEL_TOKEN, { token: CancelTokenSource }>(ActionTypes.STORE_CANCEL_TOKEN, { token })
export const searchSuggestionStoreSearch = (search: string) =>
    action<ActionTypes.STORE_SEARCH, { search: string }>(ActionTypes.STORE_SEARCH, { search })
