/*
 *
 * Products
 *
 */

import classNames from 'classnames'
import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

function Placeholder(): JSX.Element {
    return (
        <div className={classNames('product-card', 'product-detail', 'product-detail-placeholder')}>
            <Row className={'product-list'}>
                <Col xs={24} md={12}>
                    <div className={classNames('product-image', 'product-image-list')}>
                        <div className={'product-image-container'}>
                            <div className={'product-image-inner'}>
                                <RectShape />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className={'product-detail-inner-section'}>
                        <RectShape color={'gray'} className={'product-brand-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <TextBlock rows={2} color={'gray'} className={'product-name-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <TextBlock rows={1} color={'gray'} className={'product-reference-placeholder'} />
                        <TextBlock rows={1} color={'gray'} className={'product-ean-placeholder'} />
                        <TextBlock rows={1} color={'gray'} className={'product-shipping-location-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <RectShape color={'gray'} className={'product-price-placeholder'} />
                        <RectShape color={'gray'} className={'product-price-placeholder'} />
                    </div>
                    <div className={'product-detail-inner-section'}>
                        <RectShape color={'gray'} className={'product-quantity-placeholder'} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default memo(Placeholder)
