import { AxiosInstance } from 'axios'
import { IApiCmsBlockResponse, IApiCmsPageResponse, ICmsService } from './types'

const create = (instance: AxiosInstance): ICmsService => {
    /*****************************
     * METHODS
     *****************************/
    const getPageBySlug = (slug: string): Promise<IApiCmsPageResponse> => {
        return instance.get(`/cms-pages/by-slug${slug}`)
    }

    const getPageByCode = (code: string): Promise<IApiCmsPageResponse> => {
        return instance.get(`/cms-pages/by-code/${code}`)
    }

    const getPreviewPage = (identifier: string): Promise<IApiCmsPageResponse> => {
        return instance.get(`/cms-pages/preview/${identifier}`)
    }

    const getBlockByCode = (code: string): Promise<IApiCmsBlockResponse> => {
        return instance.get(`/cms-blocks/by-code/${code}`)
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        getPageBySlug,
        getPageByCode,
        getBlockByCode,
        getPreviewPage,
    }
}

export default {
    create,
}
