import React from 'react'
import { ProductListFilterMode } from '../../../services/api/service/products/types'
import { IFactoryProps } from './type'
import TypeOptions from './Type/Options'
import TypeOrder from './Type/Order'
import TypeRange from './Type/Range'

function FilterFactory(props: IFactoryProps): JSX.Element {
    switch (props.filter.mode) {
        case ProductListFilterMode.Option:
            return <TypeOptions {...props} />
        case ProductListFilterMode.Order:
            return <TypeOrder {...props} />
        case ProductListFilterMode.Range:
            return <TypeRange {...props} />
        default:
            return <></>
    }
}

export default FilterFactory
