import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerLogin from '../containers/CustomerLogin/CustomerLogin'

function CustomerLoginPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'modify_login.update_login' })}</title>
            </Helmet>
            <div className={'customer-login-page'} id={'customer-login-page'}>
                <CustomerLogin />
            </div>
        </>
    )
}

export default CustomerLoginPage
