import { AxiosInstance } from 'axios'
import {
    ICustomersService,
    IApiCustomerGetResponse,
    ICustomerModifyLoginParameters,
    IApiCustomerListParameters,
    IApiCustomerListResponse,
    IApiCustomerListCollection,
} from './types'

const create = (instance: AxiosInstance): ICustomersService => {
    /*****************************
     * METHODS
     *****************************/
    const get = (customerId: string): Promise<IApiCustomerGetResponse> => {
        const paths = customerId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.get(`/customers/${identifier}`)
    }

    const changePassword = (params: ICustomerModifyLoginParameters): Promise<IApiCustomerGetResponse> => {
        return instance.put('/customers/me/change-password', params)
    }

    const list = <T = IApiCustomerListCollection>(
        params: IApiCustomerListParameters,
        cancelToken
    ): Promise<IApiCustomerListResponse<T>> => {
        return instance.get('/customers', { params, cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        get,
        changePassword,
        list,
    }
}

export default {
    create,
}
