/*
 *
 * Config constants
 *
 */

enum ActionTypes {
    STORE_REMOTE_ACTION = 'app/Config/Remote/STORE',
    SET_SALESMAN_CART_ALERT_DISPLAY_ACTION = 'app/Config/Salesman/CartAlert/SET_DISPLAY',
    RESET_ACTION = 'app/Config/RESET',
}

export default ActionTypes
