import React, { MouseEvent, useCallback, useMemo } from 'react'
import Table, { ITableProps, TableHandleRef } from '../../../../../components/Table/Table'
import { Cell, Column, TableState } from 'react-table'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { IApiMeTopProductsEntry } from '../../../../../services/api/service/stats/types'
import Price from '../../../../../components/Price/Price'
import { Heading as ProductHeading, MainPicture as ProductMainPicture } from '../../../../../components/Product/Partial'
import { MemberStatisticsContext } from '../../../../../store/statistics/types'
import { generateProductUrl } from '../../../../../utils/productHelper'

type Props<T extends object = IApiMeTopProductsEntry> = Pick<
    ITableProps<T>,
    'fetchError' | 'fetching' | 'fetchData' | 'totalItems' | 'data' | 'deps'
> & {
    pageSize: number
    paginate?: boolean
    mRef?: TableHandleRef<T>
    context?: MemberStatisticsContext
}

export default function List<T extends IApiMeTopProductsEntry = IApiMeTopProductsEntry>({
    pageSize,
    paginate = true,
    mRef,
    context,
    ...rest
}: Props<T>): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const initialState: Partial<TableState<IApiMeTopProductsEntry>> = useMemo(() => {
        return {
            pageIndex: 0,
            pageSize,
            sortBy: [
                {
                    id: 'amount',
                    desc: true,
                },
            ],
        }
    }, [pageSize])

    const handleCellClick = useCallback(
        (event: MouseEvent<HTMLElement>, item: T, cell: Cell<T>) => {
            if (context === MemberStatisticsContext.Salesman || !cell.column.clickable) {
                return
            }

            const product = item

            const finalUrl = generateProductUrl(product.id, locale, true)
            // @ts-ignore
            if (location.state && location.state.background) {
                // @ts-ignore
                history.push(finalUrl, { background: location.state.background })
            } else {
                history.push(finalUrl, { background: location })
            }
        },
        [context, locale, location, history]
    )

    const columns: Array<Column<T>> = useMemo(() => {
        return [
            {
                id: 'all_customers',
                Header: 'all_customers',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'all_activity',
                Header: 'all_activity',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'from',
                Header: 'from',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'to',
                Header: 'to',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'departments',
                Header: 'departments',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'families',
                Header: 'families',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                id: 'sub_families',
                Header: 'sub_families',
                filterOnly: true,
                Filter: () => {
                    return <></>
                },
            },
            {
                accessor: 'rank',
                className: 'rank',
                clickable: true,
                Header: formatMessage({ id: 'default.ranking' }),
                Cell: ({ value }) => {
                    return <span className="value">#{value}</span>
                },
            },
            {
                defaultCanFilter: true,
                id: 'search',
                accessor: 'name',
                className: 'details',
                clickable: true,
                Header: formatMessage({ id: 'default.ordered_products' }),
                Cell: ({ data, cell }) => {
                    const item = data[cell.row.id]
                    return (
                        <>
                            <div className="image">
                                <div className="product-card">
                                    <ProductMainPicture product={item} />
                                </div>
                            </div>
                            <ProductHeading
                                product={item}
                                showEan
                                showReference
                                showReferenceLabel
                                showEanLabel
                                productNameAs={'p'}
                                showPacking={false}
                                showPackingLabel={false}
                            />
                        </>
                    )
                },
            },
            {
                defaultCanSort: true,
                sortDescFirst: true,
                Header: formatMessage({ id: 'default.ordered_units' }),
                id: 'quantity',
                accessor: 'ordered_quantity',
                className: 'ordered-quantity',
                clickable: true,
            },
            {
                defaultCanSort: true,
                sortDescFirst: true,
                Header: formatMessage({ id: 'cart.total_price_excluding_taxes' }),
                id: 'amount',
                accessor: 'ordered_amount',
                className: 'ordered-amount',
                clickable: true,
                Cell: ({ value }) => {
                    return <Price price={value} />
                },
            },
        ]
    }, [formatMessage])

    return (
        <Table<T>
            {...rest}
            mRef={mRef}
            className="top-products-table"
            columns={columns}
            initialState={initialState}
            onCellClick={handleCellClick}
            paginate={paginate}
            syncWithHistory={false}
        />
    )
}
