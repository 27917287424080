/*
 *
 * Payment Modes reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'

export const initialListState = {
    fetching: false,
    items: [],
}

export const initialState: ContainerState = {
    list: initialListState,
}

function paymentModesReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return { ...initialState }
        case ActionTypes.LIST_SUCCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.payload['hydra:member'],
                },
            }
        case ActionTypes.LIST_RESET_ACTION: {
            return {
                ...state,
                list: {
                    ...initialListState,
                },
            }
        }
        default:
            return state
    }
}

export default paymentModesReducer
