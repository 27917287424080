/**
 *
 * Placeholder
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { Pagination as MultiPage } from 'react-bootstrap'
import { RectShape } from 'react-placeholder/lib/placeholders'

function Placeholder(): JSX.Element {
    const pages: Array<JSX.Element> = []
    for (let i = 1; i <= 10; i++) {
        pages.push(
            <MultiPage.Item disabled key={`pitem_${i}`}>
                <RectShape
                    key={`prect_${i}`}
                    color={'gray'}
                    className={'show-loading-animation'}
                    style={{ width: 30, height: 30, marginRight: 0 }}
                />
            </MultiPage.Item>
        )
    }
    return (
        <div className={classNames('pagination-section', 'pagination-placeholder-section')}>
            <MultiPage className={'pagination-placeholder'}>{pages}</MultiPage>
        </div>
    )
}

export default memo(Placeholder)
