/**
 *
 * List
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Button from '../../../components/Buttons/Button'
import {
    IAttributeDefinitionCollection,
    IFamilyAttributeDefinition,
} from '../../../services/api/service/classification/types'
import {
    IProductListFilter,
    IProductListFilterCollection,
    IProductListParameters,
} from '../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectClassificationAttributeDefinition } from '../../../store/classification/selectors'
import {
    makeSelectProductsListFetching,
    makeSelectProductsListFilters,
    makeSelectProductsListParams,
} from '../../../store/products/selectors'
import { customerFilterHasSelectedValues } from '../../../store/products/utils'
import { CollectionMap } from '../../../types/common'
import Factory from './Factory'

interface IProps {
    className?: string
    filterPortalId?: string
    onFilterToggleOpen?: (filter: IProductListFilter, open: boolean) => void
    onReset?: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    loading: makeSelectProductsListFetching(),
    filters: makeSelectProductsListFilters(),
    params: makeSelectProductsListParams(),
    definition: makeSelectClassificationAttributeDefinition(),
})

function List({ className, filterPortalId, onFilterToggleOpen }: IProps): JSX.Element {
    const history = useHistory()
    const { pathname } = useLocation()
    const { loading, filters, params, definition } = useSelector<
        IApplicationRootState,
        {
            loading: boolean
            filters?: IProductListFilterCollection
            params: IProductListParameters
            definition: IAttributeDefinitionCollection
        }
    >(stateSelector)

    const keys: Array<string> = useMemo(() => {
        if (!filters) {
            return []
        }
        const publicKeys: Array<string> = []
        for (const k in filters) {
            const filter = filters[k]
            if (filter.public) {
                publicKeys.push(k)
            }
        }
        return publicKeys
    }, [filters])

    const hasFilters = keys.length === 0
    const configuration: CollectionMap<IFamilyAttributeDefinition> = useMemo(() => {
        const final: CollectionMap<IFamilyAttributeDefinition> = {}
        definition.forEach((single) => (final[single.code] = single))
        return final
    }, [definition])

    const hasSelectedValues = useMemo(() => {
        return customerFilterHasSelectedValues(filters, params)
    }, [filters, params])

    const handleReset = useCallback(() => {
        history.push(pathname)
    }, [history, pathname])

    return (
        <>
            <div
                className={classNames('product-filter-list', className, {
                    empty: hasFilters,
                    fulfilled: hasSelectedValues,
                    disabled: loading,
                })}
            >
                <div className={'product-filter-list-inner'}>
                    <div className={'title'}>
                        <span>
                            <FormattedMessage id={'products.filter'} />
                        </span>
                    </div>
                    {keys.map((key) => {
                        const value = params && params.filters && params.filters[key] ? params.filters[key] : undefined
                        return (
                            <Factory
                                key={key}
                                filter={filters![key]}
                                value={value}
                                showPreview={
                                    key === 'classification' || (configuration[key] && configuration[key].show_preview)
                                }
                                swatch={configuration[key] && configuration[key].swatch}
                                filterPortalId={filterPortalId}
                                onToggleOpen={onFilterToggleOpen}
                            />
                        )
                    })}
                    {hasSelectedValues && (
                        <div className={'reset-all'}>
                            <Button className={'btn-reset-all'} onClick={handleReset} type={'button'} variant={'link'}>
                                <FormattedMessage id={'products.remove_filters'} />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

List.defaultProps = {
    desktop: true,
} as Partial<IProps>

export default memo(List)
