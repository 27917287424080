/*
 *
 * Order reducer
 *
 */

import ActionTypes from './constants'
import { ContainerState, ContainerActions } from './types'

export const initialOrderDetailState = {
    fetching: false,
}

export const initialState: ContainerState = {
    orderId: undefined,
    detail: initialOrderDetailState,
}

function orderReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.DETAIL_SUCCESS_ACTION:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: action.payload.response.data,
                },
            }
        case ActionTypes.DETAIL_PROCESS_ACTION:
            return {
                ...state,
                orderId: action.payload.orderId,
                detail: {
                    ...state.detail,
                    detail: state.orderId === action.payload.orderId ? state.detail.detail : undefined,
                },
            }
        default:
            return state
    }
}

export default orderReducer
