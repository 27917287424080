import React, { MouseEvent, useCallback, useMemo } from 'react'
import Table, { ITableProps } from '../../../components/Table/Table'
import { Cell, Column, TableState } from 'react-table'
import Price from '../../../components/Price/Price'
import { FormattedDate, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { getPath } from '../../../routes'
import { IInvoiceDetail, InvoiceDetailType } from '../../../services/api/service/invoices/types'
import InvoiceType from '../../../components/Invoice/InvoiceType'
import Document from '../Action/Document'
import { Form } from 'react-bootstrap'
import { IMe } from '../../../services/api/service/me/types'
import { canShowAllActivitySelector } from '../../../store/salesmens/utils'

type Props<T extends object = IInvoiceDetail> = Pick<
    ITableProps<T>,
    'fetchError' | 'fetching' | 'fetchData' | 'totalItems' | 'data' | 'syncWithHistory'
> & {
    pageSize: number
    paginate?: boolean
    me?: IMe
}

export default function InvoiceList<T extends IInvoiceDetail = IInvoiceDetail>({
    pageSize,
    paginate = true,
    me,
    ...rest
}: Props<T>): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const history = useHistory()

    const initialState: Partial<TableState<IInvoiceDetail>> = useMemo(() => {
        return { pageIndex: 0, pageSize }
    }, [pageSize])

    const redirectToOrderDetail = useCallback(
        (item: { id: string }) => {
            const orderDetailUrl = generatePath(getPath('customerOrder', locale), {
                lang: locale,
                orderId: item.id,
            })
            history.push(orderDetailUrl)
        },
        [history, locale]
    )

    const handleCellClick = useCallback(
        (event: MouseEvent<HTMLElement>, item: T, cell: Cell<T>) => {
            if (cell.column.id === 'order' && item.order) {
                redirectToOrderDetail(item.order)
            }
        },
        [redirectToOrderDetail]
    )

    const columns: Array<Column<T>> = useMemo(() => {
        return [
            {
                Header: 'all_activity',
                defaultCanFilter: canShowAllActivitySelector(me),
                filterOnly: true,
                Filter: ({ column: { filterValue, setFilter, id } }) => {
                    return (
                        <Form.Check
                            type={'switch'}
                            id={id}
                            checked={filterValue !== 1}
                            label={formatMessage({ id: 'salesman.my_activity_only' })}
                            onChange={() => {
                                setFilter(!filterValue ? 1 : 0)
                            }}
                            value={0}
                        />
                    )
                },
            },
            {
                Header: formatMessage({ id: 'document.type' }),
                accessor: 'type',
                Cell: ({ value }) => {
                    return <InvoiceType type={value} />
                },
            },
            {
                Header: formatMessage({ id: 'document.number' }),
                accessor: 'number',
            },
            {
                Header: formatMessage({ id: 'orders.date' }),
                accessor: 'date',
                Cell: ({ value }) => {
                    return <FormattedDate value={value} month={'long'} day={'numeric'} year={'numeric'} />
                },
            },
            {
                Header: formatMessage({ id: 'orders.total_excluding_taxes' }),
                accessor: 'amount',
                Cell: ({ value }) => {
                    return <Price price={value} />
                },
            },
            {
                Header: formatMessage({ id: 'orders.total_including_taxes' }),
                accessor: 'amount_including_tax',
                Cell: ({ value }) => {
                    return <Price price={value} />
                },
            },
            {
                Header: formatMessage({ id: 'order.single' }),
                accessor: 'order',
                clickable: true,
                Cell: ({ value }) => {
                    if (value?.number) {
                        return <span className="link">{value?.number}</span>
                    }
                    return '-'
                },
            },
            {
                Header: formatMessage({ id: 'invoices.state' }),
                accessor: 'state_label',
                Cell: ({ value, data, cell }) => {
                    const invoice = data[cell.row.id]
                    if (invoice.type === InvoiceDetailType.Asset) {
                        return <span className={`invoice-state`}>{'-'}</span>
                    }

                    return <span className={`invoice-state ${invoice.state}`}>{value}</span>
                },
            },
            {
                id: 'actions',
                accessor: 'id',
                className: 'actions',
                Cell: ({ data, cell }) => {
                    const invoice = data[cell.row.id]
                    return <Document invoiceId={invoice.id} invoiceNumber={invoice.number} label={null} />
                },
            },
        ]
    }, [formatMessage, me])

    return (
        <Table<T>
            {...rest}
            className="invoice-table"
            columns={columns}
            initialState={initialState}
            onCellClick={handleCellClick}
            paginate={paginate}
        />
    )
}
