import { IApiBooleanType, IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { AxiosResponse, CancelToken } from 'axios'
import { IResourceCart } from '../carts/types'
import { IResourceOrder } from '../orders/types'
import { IShippingLocation } from '../classification/types'

export enum IApiExportProductStateList {
    Wait = 'wait',
    Processing = 'processing',
    Error = 'error',
    Ready = 'ready',
    Expired = 'expired',
}

export type ExportProductResourceType = IResourceOrder | IResourceCart
export enum ExportProductType {
    OrderExport = 'OrderExport',
    CartExport = 'CartExport',
}

export interface IApiBaseCreateExportProductParameters {
    append_picture: IApiBooleanType
    all_stores: IApiBooleanType
}

export interface IApiExportProductCreateParameters {
    resourceType: ExportProductResourceType
    resourceId: string
    appendPicture: IApiBooleanType
    allStores: IApiBooleanType
    shippingLocationId?: string
}

export interface IApiExportProductList<T = ExportProductType> extends IApiResource<T> {
    readonly state: IApiExportProductStateList
    readonly append_picture: IApiBooleanType
    readonly validity_date: string
    readonly created_at: string
    readonly shipping_location?: IShippingLocation
    readonly order_number?: string
    readonly order_id?: string
}

export interface IApiExportProductDetail<T = ExportProductType> extends IApiExportProductList<T> {
    readonly url?: string
}

export type IApiExportProductListCollection = Array<IApiExportProductList>
export type IApiExportProductListParameters = IBasePaginationParameters
export type IApiExportProductListPagination = IPaginationApiResponse<IApiExportProductListCollection>
export type IApiExportProductListResponse = AxiosResponse<IApiExportProductListPagination>
export type IApiExportProductResponse = AxiosResponse<IApiExportProductDetail<ExportProductType>>

export interface IExportService {
    list(parameters: IApiExportProductListParameters, cancelToken?: CancelToken): Promise<IApiExportProductListResponse>
    get(productExportId: string, cancelToken?: CancelToken): Promise<IApiExportProductResponse>
}
