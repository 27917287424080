import { AxiosInstance, CancelToken } from 'axios'
import {
    BulkCartQuantityParameters,
    IApiBulkCartQuantityResponse,
    IApiCartItemDeleteResponse,
    IApiCartItemResponse,
    IApiCartLightListResponse,
    IApiCartListResponse,
    IAPiCartLockResponse,
    IApiCartResponse,
    IApiMultiStoreCartResponse,
    IApiReOrderResponse,
    IApiSalesmanCartListResponse,
    ICartDuplicateParameters,
    ICartService,
    IMultiStoreCartsParameters,
    IReOrderParameters,
    ISalesmanCartListParameters,
} from './types'

const create = (instance: AxiosInstance): ICartService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (): Promise<IApiCartListResponse> => {
        return instance.get('/carts')
    }

    const get = (cartId: string): Promise<IApiCartResponse> => {
        return instance.get(`${cartId}`)
    }

    const lock = (cartId: string): Promise<IAPiCartLockResponse> => {
        return instance.put(`${cartId}/lock`, { locked: true })
    }

    const unlock = (cartId: string): Promise<IAPiCartLockResponse> => {
        return instance.put(`${cartId}/lock`, { locked: false })
    }

    const storesCartList = (
        params: IMultiStoreCartsParameters,
        cancelToken?: CancelToken
    ): Promise<IApiMultiStoreCartResponse> => {
        return instance.get(`/stores-carts`, { params, cancelToken })
    }

    const salesmanCartList = (
        params: ISalesmanCartListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiSalesmanCartListResponse> => {
        return instance.get(`/salesman-carts`, { params, cancelToken })
    }

    const storeCartList = (parentCartId: string): Promise<IApiCartLightListResponse> => {
        return instance.get(`${parentCartId}/store-carts`)
    }

    const clean = (cartId: string): Promise<IApiCartResponse> => {
        return instance.put(`${cartId}/clean`, {})
    }

    const addItem = (productId: string, quantity: number, storeId?: string): Promise<IApiCartItemResponse> => {
        const apiUrl = storeId ? `/carts/${storeId}/items/${productId}` : `/carts/items/${productId}`
        return instance.put(apiUrl, { quantity })
    }

    const getItem = (productId: string): Promise<IApiCartItemResponse> => {
        return instance.get(`/carts/items/${productId}`)
    }

    const deleteItem = (productId: string, storeId?: string): Promise<IApiCartItemDeleteResponse> => {
        const apiUrl = storeId ? `/carts/${storeId}/items/${productId}` : `/carts/items/${productId}`
        return instance.delete(apiUrl)
    }

    const reOrder = (data: IReOrderParameters, headers?: Record<string, string>): Promise<IApiReOrderResponse> => {
        return instance.post(`/carts/reorder`, data, {
            headers,
        })
    }

    const duplicate = (data: ICartDuplicateParameters): Promise<IApiReOrderResponse> => {
        return instance.post(`/carts/duplicate`, data)
    }

    const addBulk = (params: BulkCartQuantityParameters): Promise<IApiBulkCartQuantityResponse> => {
        return instance.post(`/carts/items/bulk`, params)
    }

    const bulkMove = (params: BulkCartQuantityParameters): Promise<IApiBulkCartQuantityResponse> => {
        return instance.post(`/carts/items/move-quantity`, params)
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        get,
        salesmanCartList,
        storesCartList,
        storeCartList,
        addItem,
        deleteItem,
        reOrder,
        getItem,
        clean,
        addBulk,
        bulkMove,
        duplicate,
        lock,
        unlock,
    }
}

export default {
    create,
}
