/*
 *
 * App actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import { IAppErrorTypes, IAppFailPayload, IAppErrorPayload, IAppReloadingPayload } from './types'

export const bootstrapAction = () => action(ActionTypes.APP_BOOTSTRAP_ACTION)
export const reloadingAction = (localeUpdated?: boolean, redirectUrl?: string) =>
    action<ActionTypes.APP_RELOADING_ACTION, IAppReloadingPayload>(ActionTypes.APP_RELOADING_ACTION, {
        localeUpdated,
        redirectUrl,
    })
export const reloadedAction = () => action(ActionTypes.APP_RELOADED_ACTION)
export const readyAction = () => action(ActionTypes.APP_READY_ACTION)
export const reloadFailAction = (error: IAppErrorTypes) =>
    action<ActionTypes.APP_RELOAD_FAIL_ACTION, IAppFailPayload>(ActionTypes.APP_RELOAD_FAIL_ACTION, { error })
export const appErrorAction = (error: IAppErrorTypes, origin?: string) =>
    action<ActionTypes.APP_ERROR_ACTION, IAppErrorPayload>(ActionTypes.APP_ERROR_ACTION, {
        error,
        origin,
    })

export const clearErrorAction = () => action<ActionTypes.APP_ERROR_CLEAR_ACTION>(ActionTypes.APP_ERROR_CLEAR_ACTION)
