/*
 *
 * Maintenance
 *
 */

import classNames from 'classnames'
import Qs from 'qs'
import React, { memo, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { default as FamilySuggestion } from '../../components/Classification/Search/Suggestion'
import FlatIcon from '../../components/Icon/FlatIcon'
import { default as ProductSuggestion } from '../../components/Product/Search/Suggestion'
import { getPath } from '../../routes'
import {
    FamilyType,
    IClassificationTypes,
    IFamilyTreeCollection,
} from '../../services/api/service/classification/types'
import {
    ISearchSuggestionFamily,
    ISearchSuggestionFamilyCollection,
    ISearchSuggestionParameters,
    ISearchSuggestionProduct,
    ISearchSuggestionProductCollection,
} from '../../services/api/service/search/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { makeSelectClassificationFamilyTreeDefault } from '../../store/classification/selectors'
import { findDepartmentBy, findFamilyBy } from '../../store/classification/utils'
import {
    searchSuggestionProcessAction,
    searchSuggestionResetAction,
    searchSuggestionStoreSearch,
} from '../../store/search/actions'
import {
    makeSelectSearchSuggestionError,
    makeSelectSearchSuggestionFamilies,
    makeSelectSearchSuggestionFetching,
    makeSelectSearchSuggestionParams,
    makeSelectSearchSuggestionProducts,
} from '../../store/search/selectors'
import { generateProductUrl } from '../../utils/productHelper'
import rtrim from '../../utils/rtrim'
import ProductImageSearch from './Image/Loadable'
import ProductBarcodeSearch from './Barcode/Loadable'
import CONFIG from '../../config'
import { Undefinable } from 'tsdef'

interface IProps {
    throttle: number
    minInputLength: number
    onProductSearchStateChange: (open: boolean) => void
    isOpen: boolean
}

const stateSelector = createStructuredSelector<any, any>({
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    families: makeSelectSearchSuggestionFamilies(),
    products: makeSelectSearchSuggestionProducts(),
    fetching: makeSelectSearchSuggestionFetching(),
    params: makeSelectSearchSuggestionParams(),
    error: makeSelectSearchSuggestionError(),
})

function ProductSearch({ onProductSearchStateChange, isOpen, minInputLength, throttle }: IProps): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const parsed = Qs.parse(window.location.search.substring(1))
    const [defaultKeywords, setDefaultKeywords] = useState('')

    useEffect(() => {
        if (parsed.search && typeof parsed.search === 'string' && parsed.search.length > 0) {
            setDefaultKeywords(parsed.search)
            dispatch(searchSuggestionStoreSearch(defaultKeywords))
        }
    }, [defaultKeywords, dispatch, parsed.search, setDefaultKeywords])

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(true)
    const formRef = useRef<HTMLFormElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)
    const [showResults, setShowResults] = useState<boolean>(false)
    const [hideCloseButton, setHideCloseButton] = useState<boolean>(defaultKeywords.length === 0)
    const { fetching, families, products, error, familyTree, params } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            families: ISearchSuggestionFamilyCollection
            products: ISearchSuggestionProductCollection
            params: ISearchSuggestionParameters
            error?: IAppErrorTypes | undefined
            familyTree: IFamilyTreeCollection
        }
    >(stateSelector)

    useEffect(() => {
        if (isOpen) {
            document.documentElement.classList.add('search-opened')
        } else {
            document.documentElement.classList.remove('search-opened')
        }

        return () => {
            document.documentElement.classList.remove('search-opened')
        }
    }, [isOpen])

    useEffect(() => {
        if (!inputRef.current) {
            return
        }
        if (params.search !== inputRef.current.value) {
            inputRef.current.value = params.search || ''
            setHideCloseButton(typeof params.search === 'undefined' || params.search.length === 0)
            setShowResults(false)
        }
    }, [inputRef, params])

    useEffect(() => {
        setShowResults(!fetching && typeof error === 'undefined' && (products.length > 0 || families.length > 0))
    }, [products, families, setShowResults, fetching, error])

    const redirectToProductsPage = useCallback(() => {
        if (!inputRef.current) {
            return
        }
        if (changeTimeoutId) {
            clearTimeout(changeTimeoutId)
        }
        const keywords = inputRef.current.value
        if (!keywords || keywords.length === 0) {
            return
        }

        if (inputRef.current) {
            inputRef.current.blur()
        }

        // generation de l'url
        const productsUrl = generatePath(getPath('family', locale), { lang: locale })
        const stringified = Qs.stringify({ search: keywords })
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const searchUrl = `${productsUrl}${qstring}`
        history.push(searchUrl)
        if (onProductSearchStateChange) {
            onProductSearchStateChange(false)
        }
        setShowResults(false)
    }, [inputRef, changeTimeoutId, locale, history, onProductSearchStateChange])

    const handleOutsideClick = useCallback(() => {
        setShowResults(false)
        if (onProductSearchStateChange) {
            onProductSearchStateChange(false)
        }
    }, [setShowResults, onProductSearchStateChange])

    const handleOnSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            redirectToProductsPage()
        },
        [redirectToProductsPage]
    )

    const resetProductSearch = useCallback(() => {
        setHideCloseButton(true)
        if (inputRef.current) {
            inputRef.current!.value = ''
        }
        const parsed = Qs.parse(window.location.search.substring(1))
        delete parsed.search
        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(window.location.pathname, '/')
        history.push(`${baseUrl}${qstring}`)

        // reset
        dispatch(searchSuggestionResetAction())
    }, [setHideCloseButton, inputRef, history, dispatch])

    const handleOnReset = useCallback(() => {
        resetProductSearch()
    }, [resetProductSearch])

    const handleOnFocus = useCallback(() => {
        if (
            !fetching &&
            families.length === 0 &&
            products.length === 0 &&
            inputRef.current &&
            inputRef.current.value.length >= minInputLength
        ) {
            const params = {
                page: 1,
                search: inputRef.current.value,
            }
            dispatch(searchSuggestionProcessAction(params))
        } else if (families.length > 0 || products.length > 0) {
            setShowResults(true)
        }
    }, [dispatch, fetching, families, products, inputRef, minInputLength, setShowResults])

    const handleOnKeyPress = useCallback(() => {
        const timeoutId = setTimeout(() => {
            if (!inputRef.current) {
                return
            }
            const inputElement = inputRef.current as HTMLInputElement
            const inputValue = inputElement.value
            setSubmitButtonDisabled(inputElement.value.length === 0)
            setHideCloseButton(inputElement.value.length === 0)
            if (inputValue.length === 0) {
                resetProductSearch()
                dispatch(searchSuggestionResetAction())
                return
            }

            if (inputValue.length >= minInputLength) {
                const params = {
                    page: 1,
                    search: inputValue,
                }
                dispatch(searchSuggestionProcessAction(params))
            }
        }, throttle)
        setChangeTimeoutId(timeoutId)
    }, [dispatch, throttle, minInputLength, setChangeTimeoutId, inputRef, resetProductSearch])

    const handleButtonSearch = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (isOpen) {
                // reset
                dispatch(searchSuggestionResetAction())
                if (inputRef.current) {
                    inputRef.current!.value = ''
                }
            }
            if (onProductSearchStateChange) {
                onProductSearchStateChange(!isOpen)
            }
        },
        [dispatch, onProductSearchStateChange, isOpen]
    )

    const handleOnFamilyClick = useCallback(
        (family: ISearchSuggestionFamily) => {
            // récupération du notre
            const item = findFamilyBy(familyTree, 'id', family.id)
            // reset comp
            if (inputRef.current) {
                inputRef.current!.value = ''
            }

            setHideCloseButton(true)
            setShowResults(false)

            if (onProductSearchStateChange) {
                onProductSearchStateChange(false)
            }

            // reset
            dispatch(searchSuggestionResetAction())

            const productsUrl = generatePath(getPath('family', locale), { lang: locale })
            if (item) {
                history.push(`${productsUrl}${item.url}`)
                return
            }
            history.push(productsUrl)
        },
        [dispatch, familyTree, inputRef, history, locale, onProductSearchStateChange]
    )

    const handleProductClick = useCallback(
        (product: ISearchSuggestionProduct) => {
            const finalUrl = generateProductUrl(product.id, locale, true)
            // @ts-ignore
            if (location.state && location.state.background) {
                // @ts-ignore
                history.push(finalUrl, { background: location.state.background })
            } else {
                history.push(finalUrl, { background: location })
            }
        },
        [location, locale, history]
    )

    const handleClassificationItemClick = useCallback(
        (product: ISearchSuggestionProduct, familyType: FamilyType) => {
            let item: Undefinable<IClassificationTypes> = undefined
            if (familyType === FamilyType.Department) {
                item = findDepartmentBy(familyTree, '@id', product.department['@id'])
            } else if (familyType === FamilyType.Family) {
                item = findFamilyBy(familyTree, '@id', product.family['@id'])
            } else if (familyType === FamilyType.SubFamily) {
                item = findFamilyBy(familyTree, '@id', product.sub_family['@id'])
            }

            if (!item) {
                return
            }

            const baseUrl = generatePath(getPath('family', locale), { lang: locale })
            history.push(`${baseUrl}${item.url}`)
        },
        [locale, history, familyTree]
    )

    return (
        <div
            className={classNames('product-search', {
                open: isOpen,
                'has-products': products.length > 0,
                'has-families': families.length > 0,
            })}
        >
            <button className={'btn-product-search-toggle'} onClick={handleButtonSearch}>
                <FlatIcon icon={'search'} className={'search'} />
                <span className={'app-icon close'} aria-hidden="true">
                    ×
                </span>
            </button>
            <OutsideClickHandler
                onOutsideClick={handleOutsideClick}
                // @ts-ignore
                disabled={!showResults || typeof location.state.background !== 'undefined'}
            >
                <div className={'product-search-inner'}>
                    <Form onSubmit={handleOnSubmit} onReset={handleOnReset} ref={formRef}>
                        <Form.Row className={'align-items-center no-gutters'}>
                            <Col>
                                <Form.Control
                                    ref={inputRef}
                                    type="text"
                                    placeholder={formatMessage({ id: 'product_search.placeholder' })}
                                    onChange={handleOnKeyPress}
                                    onFocus={handleOnFocus}
                                    defaultValue={defaultKeywords}
                                />
                            </Col>
                            <Col xs={'auto'} className={classNames({ 'd-none': hideCloseButton })}>
                                <button className={'btn btn-reset'} type={'reset'}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </Col>
                            <Col xs={'auto'} className={classNames('d-none', { 'd-block': fetching })}>
                                <span className={'btn btn-submit'}>
                                    <Spinner
                                        as="span"
                                        animation={'grow'}
                                        size={'sm'}
                                        variant={'secondary'}
                                        role={'loading'}
                                        aria-hidden="true"
                                    />
                                </span>
                            </Col>
                            <Col xs={'auto'} className={classNames({ 'd-none': fetching })}>
                                <button className={'btn btn-submit'} type={'submit'} disabled={submitButtonDisabled}>
                                    <FlatIcon icon={'search'} />
                                </button>
                            </Col>
                        </Form.Row>
                        <Form.Row className={'align-items-center search-by-buttons'}>
                            {CONFIG.PRODUCT_LIST.SEARCH_BY_IMAGE && (
                                <Col xs={true}>
                                    <ProductImageSearch disabled={fetching} />
                                </Col>
                            )}
                            {CONFIG.PRODUCT_LIST.SEARCH_BY_BARCODE && (
                                <Col xs={true}>
                                    <ProductBarcodeSearch disabled={fetching} />
                                </Col>
                            )}
                        </Form.Row>
                    </Form>

                    {showResults && (
                        <div className={'product-search-result'}>
                            <div className={'product-search-result-inner'}>
                                <Row>
                                    {families.length > 0 && (
                                        <Col md={8} xl={10}>
                                            <div
                                                className={classNames(
                                                    'product-search-result-list',
                                                    'product-search-result-list-family'
                                                )}
                                            >
                                                <h4 className={'hv'}>
                                                    <FormattedMessage id={'categories.sidebar.title'} />
                                                </h4>
                                                <div className={'product-search-result-family-inner'}>
                                                    {families.map((family) => (
                                                        <FamilySuggestion
                                                            key={family.id}
                                                            onClick={handleOnFamilyClick}
                                                            family={family}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                    {products.length > 0 && (
                                        <Col>
                                            <div
                                                className={classNames(
                                                    'product-search-result-list',
                                                    'product-search-result-list-product'
                                                )}
                                            >
                                                <h4 className={'hv'}>
                                                    <FormattedMessage id={'products.sidebar.title'} />
                                                </h4>
                                                <div className={'product-search-result-product-inner'}>
                                                    {products.map((product) => (
                                                        <ProductSuggestion
                                                            noGutters={false}
                                                            key={product.id}
                                                            product={product}
                                                            onProductClick={handleProductClick}
                                                            onClassificationItemClick={handleClassificationItemClick}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    )
}

ProductSearch.defaultProps = {
    throttle: 150,
    minInputLength: 3,
} as Partial<IProps>

export default memo(ProductSearch)
