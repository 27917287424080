/**
 *
 * AppLoader
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { AnimationEvent, memo, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Logo from '../Logo/Logo'

interface IProps {
    animated?: boolean
    show?: boolean
    showLabel?: boolean
    white?: boolean
}

function AppLoader({ animated, show, showLabel, white }: IProps): JSX.Element {
    useEffect(() => {
        if (show) {
            document.documentElement.classList.add('app-loading')
        } else {
            document.documentElement.classList.remove('app-loading')
        }

        return () => {
            document.documentElement.classList.remove('app-loading')
        }
    }, [show])

    const handleEndAnimation = (event: AnimationEvent) => {
        setTimeout(() => {
            if (event.currentTarget && event.currentTarget.classList) {
                event.currentTarget.classList.add('d-none')
            }
        }, 200)
    }

    return (
        <div
            className={classNames('loader-page', {
                'animate__animated animated_fadeIn': animated && show,
                'd-none': !animated && !show,
                'animate__animated animated__fadeOut': animated && !show,
                'theme-white': white,
            })}
            onAnimationEnd={handleEndAnimation}
        >
            <Logo white={white} />
            <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} />
            {showLabel && (
                <h5 className={'title'}>
                    <FormattedMessage id={'default.loading'} />
                </h5>
            )}
        </div>
    )
}

AppLoader.defaultProps = {
    animated: true,
    show: true,
    showLabel: false,
    white: false,
} as Partial<IProps>
export default memo(AppLoader)
