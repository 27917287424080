/**
 *
 * Picker Button
 *
 */
import React, { memo } from 'react'
import { IPickerActionComponentProps } from '../../../types/productPicker'
import { FormattedMessage } from 'react-intl'

function PickerCounter({ items, action }: IPickerActionComponentProps): JSX.Element {
    return (
        <span className="counter">
            <FormattedMessage id="product_picker.counter" values={{ total: items.length }} />
            {action.options.max && (
                <>
                    <span className="separator">/</span>
                    <FormattedMessage id="product_picker.counter_max" values={{ max: action.options.max }} />
                </>
            )}
        </span>
    )
}

export default memo(PickerCounter)
