import React, { useEffect, useLayoutEffect } from 'react'
// routes de l'application
import { RouteComponent, routes } from '../../routes/'

//redux + actions
import { createStructuredSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, withRouter, RouteComponentProps } from 'react-router-dom'
import {
    makeSelectAppBootstrap,
    makeSelectAppError,
    makeSelectAppFatal,
    makeSelectAppReady,
} from '../../store/app/selectors'
import { bootstrapAction } from '../../store/app/actions'
import { makeSelectSplashScreenAnimated, makeSelectSplashScreenShow } from '../../store/splashScreen/selectors'
import SplashScreen from '../../components/SplashScreen/SplashScreen'
import { Helmet } from 'react-helmet'
import { makeSelectLocale } from '../../store/intl/selectors'
import { ScrollTo } from 'scroll-to-position'
import ErrorPage from '../../pages/ErrorPage'
import Config from '../../config'
import AppError from '../../store/app/error'
import { setLocale as setYupLocale } from 'yup'
import * as yupLocales from 'yup-locales'
import { IApplicationRootState } from '../../store'

const stateSelector = createStructuredSelector<any, any>({
    bootstrap: makeSelectAppBootstrap(),
    fatal: makeSelectAppFatal(),
    error: makeSelectAppError(),
    ready: makeSelectAppReady(),
    splashShow: makeSelectSplashScreenShow(),
    splashAnimated: makeSelectSplashScreenAnimated(),
    locale: makeSelectLocale(),
})

// @ts-ignore
const ScrollToTop = withRouter(({ children, location }: RouteComponentProps) => {
    useLayoutEffect(() => {
        // @ts-ignore
        if (!location.state || (!location.state.background && !location.state.no_scroll)) {
            // @ts-ignore
            if (location.state && location.state.prev) {
                // @ts-ignore
                const matchPath2 = matchPath<{ lang?: string }>(location.state.prev.pathname, {
                    path: routes.product.match,
                })
                if (matchPath2?.isExact) {
                    return
                }
            }
            ScrollTo(document.body, {
                duration: [200, 1500],
            })
        }
    }, [location.pathname, location.search, location.state])
    return children || null
})

function App(): JSX.Element {
    const { bootstrap, ready, splashShow, splashAnimated, locale, error, fatal } = useSelector<
        IApplicationRootState,
        {
            bootstrap: boolean
            ready: boolean
            splashShow: boolean
            splashAnimated: boolean
            locale: string
            error: AppError
            fatal: boolean
        }
    >(stateSelector)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!ready && !bootstrap && !fatal) {
            dispatch(bootstrapAction())
        }
    }, [ready, bootstrap, fatal, error, dispatch])

    // on set les locales de la librairie yup (validateur de formulaire)
    useEffect(() => {
        if (!locale) {
            return
        }
        // on set les locales
        try {
            // @ts-ignore
            if (yupLocales[locale]) {
                // @ts-ignore
                setYupLocale(yupLocales[locale])
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
    }, [locale])

    return (
        <>
            <Helmet titleTemplate={`%s - ${Config.APP_NAME}`} defaultTitle={`${Config.APP_NAME}`}>
                <html lang={locale} />
                <meta name="description" content={`${Config.APP_NAME}`} />
            </Helmet>
            <SplashScreen show={splashShow} animated={splashAnimated} />
            <ScrollToTop>
                {fatal && error && <ErrorPage error={error} />}
                {ready && <RouteComponent />}
            </ScrollToTop>
        </>
    )
}

export default App
