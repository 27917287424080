/**
 *
 * Product Card
 *
 */
import classNames, { Value as ClassValue } from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IShippingLocation, IShippingLocationLight } from '../../services/api/service/classification/types'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    className?: ClassValue
    labelId?: string
    iconId?: string
    shippingLocation: IShippingLocationLight | IShippingLocation
    showLabel?: boolean
    showIcon?: boolean
}

function ShippingLocation({ shippingLocation, showLabel, showIcon, labelId, iconId, className }: IProps): JSX.Element {
    return (
        <div
            className={classNames(
                'product-shipping-location',
                { 'with-label': showLabel },
                { 'with-icon': showIcon },
                className
            )}
        >
            {(showLabel || showIcon) && (
                <span className={'lbl'}>
                    {showIcon && <FlatIcon icon={iconId} />}
                    {showLabel && (
                        <span>
                            <FormattedMessage id={labelId} />
                        </span>
                    )}
                </span>
            )}
            <span className={classNames('value', 'product-shipping-location')}>{shippingLocation.full_name}</span>
        </div>
    )
}

ShippingLocation.defaultProps = {
    labelId: 'classification.shipping_location',
    iconId: 'warehouse',
    // iconId: 'delivery-truck',
    labelled: false,
    showLabel: false,
    showIcon: false,
} as Partial<IProps>

export default ShippingLocation
