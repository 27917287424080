/**
 *
 * Product Carousel
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import {
    CmsWidgetTheme,
    CmsWidgetType,
    ICmsWidgetHighlightCollection,
    ICmsWidgetHighlightCollectionItem,
} from '../../../../../services/api/service/cms/types'
import { IProductCatalog } from '../../../../../services/api/service/products/types'
import { CollectionClickCallback } from '../../../../../types/collection'
import { CmsWidgetLinkClickCallback } from '../../../../../types/widget'
import { default as CarouselApp } from '../../../../Carousel/Carousel'
import { default as CarouselWrapper } from '../../../../Carousel/Wrapper'
import CarouselItem from './CarouselItem'

interface IProps {
    widget: ICmsWidgetHighlightCollection
    onLinkClick?: CmsWidgetLinkClickCallback
    onCollectionClick?: CollectionClickCallback
    loop?: boolean
    autoplay?: boolean
    autoplayDelay?: number
    prefixTitle?: boolean
    showPrices?: boolean
}

/*

Carousel.defaultProps = {
    loop: false,
    autoplay: false,
    autoplayDelay: 5000,
    prefixTitle: false,
} as Partial<IProps>

 */
function Carousel({
    widget,
    loop = false,
    autoplay = false,
    autoplayDelay = 5000,
    prefixTitle = false,
    showPrices = true,
    onCollectionClick,
    onLinkClick,
}: IProps): JSX.Element {
    const totalProductsCount = widget.configuration.items
        .map((item) => item.count)
        .reduce((prev, next) => prev + next, 0)
    const items = widget.configuration.items.filter((item) => item.products.length > 0)
    const handleOnCollectionClick = useCallback(
        (
            collection: ICmsWidgetHighlightCollectionItem,
            product?: IProductCatalog,
            index?: number
        ): boolean | undefined => {
            if (onCollectionClick) {
                const res = onCollectionClick(collection, product, index)
                if (typeof res === 'boolean' && !res) {
                    return
                }
            }

            if (onLinkClick) {
                onLinkClick({
                    widget: CmsWidgetType.HighlightCollection,
                    url: collection.url,
                    internal: true,
                    blank: false,
                    text: collection.label,
                    extra: {
                        collection,
                        product,
                    },
                })
            }
        },
        [onCollectionClick, onLinkClick]
    )

    if (totalProductsCount === 0) {
        return <></>
    }

    return (
        <div
            className={classNames(
                'cms-widget',
                'cms-widget-spacing',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <CarouselWrapper
                    className={'collection-carousel'}
                    title={widget.configuration.title}
                    subTitle={widget.configuration.subtitle}
                >
                    <CarouselApp
                        preloadImages={true}
                        watchSlidesProgress={true}
                        slidesPerView={1}
                        spaceBetween={0}
                        autoplay={autoplay ? { delay: autoplayDelay, disableOnInteraction: true } : undefined}
                        loop={loop}
                    >
                        {items.map((collection: ICmsWidgetHighlightCollectionItem, index: number) => {
                            return (
                                <div key={`collection_carousel_item_${index}`}>
                                    <CarouselItem
                                        showPrices={showPrices}
                                        onCollectionClick={handleOnCollectionClick}
                                        collection={collection}
                                        prefixTitle={prefixTitle}
                                    />
                                </div>
                            )
                        })}
                    </CarouselApp>
                </CarouselWrapper>
            </div>
        </div>
    )
}

export default memo(Carousel)
