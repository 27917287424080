import * as Sentry from '@sentry/react'
import { ConnectedRouter } from 'connected-react-router'
// Load the .htaccess file
// eslint-disable-next-line import/no-unresolved
import 'file-loader?name=.htaccess!./.htaccess'
import 'intersection-observer'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
// @ts-ignore
import { MediaQueryProvider } from 'react-media-query-hoc'
import { Provider } from 'react-redux'
// styles fonts ...
import './assets/scss/index.scss'
import Config from './config'
import App from './containers/App/App'
import LanguageProvider from './containers/LanguageProvider/LanguageProvider'
// @ts-ignore
// import browserUpdate from 'browser-update'
// Import i18n messages
import { IMessageCollection, translationMessages } from './i18n'
import configureStore from './store/configureStore'
import createFontObserver from './utils/fontObserver'
import history from './utils/history'
import { createFontAwesomeLibrary } from './utils/icon/FontAwesome'

// WHY DID YOU RENDER
if (Config.DEBUG.WHY_DID_YOU_RENDER) {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    const ReactRedux = require('react-redux')
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackExtraHooks: [[ReactRedux, 'useSelector']],
    })
}

// add font awesome
createFontAwesomeLibrary()
// register fonts observer
createFontObserver(Config.FONTS)

// disable contextMenu on pictures
// disablePictureContextMenu()

// Create redux store with history
const initialState = {
    intl: {
        locale: Config.I18N.DEFAULT_LOCALE,
        messages: translationMessages[Config.I18N.DEFAULT_LOCALE],
    },
}

const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById(Config.APP_ROOT_HTML_ID) as HTMLElement

// logger
if (Config.LOG_ROCKET.ACTIVE) {
    // @ts-ignore
    LogRocket.init(Config.LOG_ROCKET.IDENTIFIER, {
        dom: {
            textSanitizer: 'true',
            inputSanitizer: 'true',
        },
        network: {
            requestSanitizer(request) {
                // scrub header value from request
                if (request.headers['Authorization']) {
                    request.headers['Authorization'] = ''
                }
                return request
            },
            responseSanitizer: (response) => {
                if (response.headers['Authorization']) {
                    // removes all response data
                    return null
                }

                // scrubs response body
                response.body = undefined
                return response
            },
        },
    })
    setupLogRocketReact(LogRocket)
}

// sentry
if (Config.SENTRY.ACTIVE) {
    Sentry.init({ dsn: Config.SENTRY.IDENTIFIER })
    if (Config.LOG_ROCKET.ACTIVE) {
        Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', LogRocket.sessionURL)
        })
    }
}

// analytics
if (Config.GOOGLE.ANALYTICS.ACTIVE) {
    ReactGA.initialize(Config.GOOGLE.ANALYTICS.IDENTIFIER, {
        debug: Config.DEBUG.ACTIVE,
        testMode: Config.DEBUG.ACTIVE,
    })
    ReactGA.plugin.require('ecommerce', { debug: Config.DEBUG.ACTIVE })
    ReactGA.pageview(window.location.pathname + window.location.search)
    history.listen((location) => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
    })
}

// hotjar
if (Config.HOTJAR.ACTIVE) {
    hotjar.initialize(Config.HOTJAR.IDENTIFIER, Config.HOTJAR.VERSION)
}

// Dans le cas où on doit notifier que le navigateur n'est pas à jour : browser update
// browserUpdate({
//     required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
//     insecure: true,
// })

// get element
const render = (messages: IMessageCollection, AppComponent: React.ComponentType = App) => {
    ReactDOM.render(
        <Sentry.ErrorBoundary showDialog>
            <Provider store={store}>
                <LanguageProvider messages={messages}>
                    <ConnectedRouter history={history}>
                        <MediaQueryProvider>
                            <AppComponent />
                        </MediaQueryProvider>
                    </ConnectedRouter>
                </LanguageProvider>
            </Provider>
        </Sentry.ErrorBoundary>,
        MOUNT_NODE
    )
}

if (module.hot && Config.DEBUG.ACTIVE) {
    module.hot.accept(['./i18n', './containers/App/App'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        const NextApp = require('./containers/App/App').default
        render(translationMessages, NextApp)
    })
}

render(translationMessages, App)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
