/*
 *
 * Products actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import {
    IApiShopImportCheckStateFailurePayload,
    IApiShopImportCheckStateProcessPayload,
    IApiShopImportCheckStateResetPayload,
    IApiShopImportCheckStateSuccessPayload,
    IApiShopImportCreateFailurePayload,
    IApiShopImportCreateProcessPayload,
    IApiShopImportCreateProcessProgressPayload,
    IApiShopImportCreateResetPayload,
    IApiShopImportCreateSuccessPayload,
    IApiShopImportDetailFailurePayload,
    IApiShopImportDetailProcessPayload,
    IApiShopImportDetailSuccessPayload,
    IApiShopImportListFailurePayload,
    IApiShopImportListProcessPayload,
    IApiShopImportListResetPayload,
    IApiShopImportListSuccessPayload,
    IApiShopImportResourceTypes,
} from './types'
import {
    IApiShopImportDetail,
    IApiShopImportListPagination,
    ShopImportEntityType,
    ShopImportListType,
    ShopImportType,
} from '../../services/api/service/imports/types'
import { CollectionMap } from '../../types/common'

// GLOBAL
export const shopImportResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const shopImportListProcessAction = (
    shopImportListType: ShopImportListType,
    page: number,
    itemsPerPage?: number,
    search?: string
) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IApiShopImportListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        shopImportListType,
        parameters: {
            page,
            itemsPerPage,
            search,
        },
    })

export const shopImportListSuccessAction = (
    shopImportListType: ShopImportListType,
    response: IApiShopImportListPagination
) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IApiShopImportListSuccessPayload>(ActionTypes.LIST_SUCCESS_ACTION, {
        shopImportListType,
        response,
    })
export const shopImportListFailureAction = (shopImportListType: ShopImportListType, error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, IApiShopImportListFailurePayload>(ActionTypes.LIST_FAILURE_ACTION, {
        shopImportListType,
        error,
    })
export const shopImportListResetAction = (shopImportListType: ShopImportListType) =>
    action<ActionTypes.LIST_RESET_ACTION, IApiShopImportListResetPayload>(ActionTypes.LIST_RESET_ACTION, {
        shopImportListType,
    })

export const shopImportCheckStateProcessAction = (item: IApiShopImportResourceTypes) =>
    action<ActionTypes.CHECK_STATE_PROCESS_ACTION, IApiShopImportCheckStateProcessPayload>(
        ActionTypes.CHECK_STATE_PROCESS_ACTION,
        {
            item,
        }
    )
export const shopImportCheckStateSuccessAction = (item: IApiShopImportDetail) =>
    action<ActionTypes.CHECK_STATE_SUCCESS_ACTION, IApiShopImportCheckStateSuccessPayload>(
        ActionTypes.CHECK_STATE_SUCCESS_ACTION,
        item
    )

export const shopImportCheckStateFailureAction = (itemId: string, error: IAppErrorTypes) =>
    action<ActionTypes.CHECK_STATE_FAILURE_ACTION, IApiShopImportCheckStateFailurePayload>(
        ActionTypes.CHECK_STATE_FAILURE_ACTION,
        { itemId, error }
    )
export const shopImportCheckStateReset = (itemType: ShopImportEntityType) =>
    action<ActionTypes.CHECK_STATE_RESET_ACTION, IApiShopImportCheckStateResetPayload>(
        ActionTypes.CHECK_STATE_RESET_ACTION,
        { itemType }
    )

export const shopImportCreateProcessAction = (shopImportType: ShopImportType, data: FormData, storeId?: string) =>
    action<ActionTypes.CREATE_PROCESS_ACTION, IApiShopImportCreateProcessPayload>(ActionTypes.CREATE_PROCESS_ACTION, {
        shopImportType,
        data,
        storeId,
    })

export const shopImportCreateProcessProgressAction = (shopImportType: ShopImportType, progress: number) =>
    action<ActionTypes.CREATE_PROCESS_PROGRESS_ACTION, IApiShopImportCreateProcessProgressPayload>(
        ActionTypes.CREATE_PROCESS_PROGRESS_ACTION,
        {
            shopImportType,
            progress,
        }
    )

export const shopImportCreateSuccessAction = (shopImportType: ShopImportType, item: IApiShopImportDetail) =>
    action<ActionTypes.CREATE_SUCCESS_ACTION, IApiShopImportCreateSuccessPayload>(ActionTypes.CREATE_SUCCESS_ACTION, {
        shopImportType,
        item,
    })
export const shopImportCreateFailureAction = (shopImportType: ShopImportType, error: IAppErrorTypes) =>
    action<ActionTypes.CREATE_FAILURE_ACTION, IApiShopImportCreateFailurePayload>(ActionTypes.CREATE_FAILURE_ACTION, {
        shopImportType,
        error,
    })

export const shopImportCreateResetAction = (shopImportType: ShopImportType) =>
    action<ActionTypes.CREATE_RESET_ACTION, IApiShopImportCreateResetPayload>(ActionTypes.CREATE_RESET_ACTION, {
        shopImportType,
    })

export const shopImportDetailProcessAction = (itemId: string) =>
    action<ActionTypes.DETAIL_PROCESS_ACTION, IApiShopImportDetailProcessPayload>(ActionTypes.DETAIL_PROCESS_ACTION, {
        itemId,
    })

export const shopImportDetailSuccessAction = (item: IApiShopImportDetail) =>
    action<ActionTypes.DETAIL_SUCCESS_ACTION, IApiShopImportDetailSuccessPayload>(ActionTypes.DETAIL_SUCCESS_ACTION, {
        item,
    })

export const shopImportDetailFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.DETAIL_FAILURE_ACTION, IApiShopImportDetailFailurePayload>(ActionTypes.DETAIL_FAILURE_ACTION, {
        error,
    })

export const shopImportDetailResetAction = () =>
    action<ActionTypes.DETAIL_RESET_ACTION>(ActionTypes.DETAIL_RESET_ACTION)

export const shopImportStoreMatrixUrlsAction = (urls: CollectionMap<string>) =>
    action<ActionTypes.STORE_MATRIX_URLS_ACTION, { urls: CollectionMap<string> }>(
        ActionTypes.STORE_MATRIX_URLS_ACTION,
        { urls }
    )

export const shopImportFormatMatrixUrlsAction = () =>
    action<ActionTypes.FORMAT_MATRIX_URLS_ACTION>(ActionTypes.FORMAT_MATRIX_URLS_ACTION)
