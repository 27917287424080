import { AxiosInstance, CancelToken } from 'axios'
import { IApiSearchSuggestionResponse, ISearchSuggestionParameters, ISearchSuggestionService } from './types'

const create = (instance: AxiosInstance): ISearchSuggestionService => {
    /*****************************
     * METHODS
     *****************************/
    const search = (
        params: ISearchSuggestionParameters,
        cancelToken?: CancelToken
    ): Promise<IApiSearchSuggestionResponse> => {
        return instance.get('/search-suggestions', { params: params, cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        search,
    }
}

export default {
    create,
}
