import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cartLockerProcessAction, cartLockerResetAction } from '../../store/carts/actions'
import { ICart, ICartLight } from '../../services/api/service/carts/types'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { IApplicationRootState } from '../../store'
import { IMe } from '../../services/api/service/me/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { CollectionMap } from '../../types/common'
import { ICartsLockerErrorsType } from '../../store/carts/types'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectCartsLockerErrors,
    makeSelectCartsLockerFetching,
    makeSelectCartsLockerSuccess,
} from '../../store/carts/selectors'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import Portal from '../../components/Portal/Portal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Overlay, Popover } from 'react-bootstrap'
import Button from '../../components/Buttons/Button'
import classNames, { Value as ClassValue } from 'classnames'

interface IProps<T = ICart | ICartLight> {
    cart: T
    reloadCarts?: boolean
    customer: ICustomer
    className?: ClassValue
    showLabel?: boolean
    showPopover?: boolean
    onDone?: (cart: T) => void
    onFail?: (cart: T) => void
    onMouseEnter?: () => void
    onPopoverMouseEnter?: () => void
    onPopoverMouseLeave?: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    lockersFetching: makeSelectCartsLockerFetching(),
    lockersErrors: makeSelectCartsLockerErrors(),
    lockersSuccess: makeSelectCartsLockerSuccess(),
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
})

function CartLocker({
    cart,
    className,
    onMouseEnter,
    onPopoverMouseEnter,
    onPopoverMouseLeave,
    onDone,
    onFail,
    reloadCarts,
    showPopover = false,
    showLabel = false,
}: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const { lockersErrors, lockersSuccess } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            customer: ICustomer
            lockersFetching: CollectionMap<boolean>
            lockersSuccess: CollectionMap<boolean>
            lockersErrors: ICartsLockerErrorsType
        }
    >(stateSelector)
    const cartLightRef = useRef() as React.MutableRefObject<HTMLElement>
    const [showUnlockSweetAlert, setShowUnlockSweetAlert] = useState<boolean>(false)
    const [showUnlockPopover, setShowUnlockPopover] = useState<boolean>(false)
    const [showUnlockSweetAlertSuccess, setShowUnlockSweetAlertSuccess] = useState<boolean>(false)
    const [showUnlockSweetAlertError, setShowUnlockSweetAlertError] = useState<boolean>(false)

    const handleLockerMouseEnter = useCallback(() => {
        if (onMouseEnter) {
            onMouseEnter()
        }
    }, [onMouseEnter])

    const handlePopoverMouseEnter = useCallback(() => {
        setShowUnlockPopover(true)
        if (onPopoverMouseEnter) {
            onPopoverMouseEnter()
        }
    }, [onPopoverMouseEnter, setShowUnlockPopover])

    const handlePopoverMouseLeave = useCallback(() => {
        setShowUnlockPopover(false)
        if (onPopoverMouseLeave) {
            onPopoverMouseLeave()
        }
    }, [onPopoverMouseLeave, setShowUnlockPopover])

    const handleUnlockClick = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            setShowUnlockPopover(false)
            setShowUnlockSweetAlert(true)
        },
        [setShowUnlockSweetAlert, setShowUnlockPopover]
    )

    const handleUnlockCancel = useCallback(() => {
        setShowUnlockSweetAlert(false)
    }, [setShowUnlockSweetAlert])

    const handleUnlockValid = useCallback(() => {
        setShowUnlockSweetAlert(false)
        dispatch(cartLockerProcessAction(cart['@id'], false, true, reloadCarts))
    }, [setShowUnlockSweetAlert, dispatch, cart, reloadCarts])

    const handleSweetAlertLockFailClick = useCallback(() => {
        dispatch(cartLockerResetAction(cart['@id']))
        setShowUnlockSweetAlertError(false)
        setShowUnlockSweetAlertSuccess(false)
        if (onFail) {
            onFail(cart)
        }
    }, [setShowUnlockSweetAlertError, setShowUnlockSweetAlertSuccess, dispatch, cart, onFail])

    const handleSweetAlertLockSuccessClick = useCallback(() => {
        dispatch(cartLockerResetAction(cart['@id']))
        setShowUnlockSweetAlertError(false)
        setShowUnlockSweetAlertSuccess(false)
        if (onDone) {
            onDone(cart)
        }
    }, [setShowUnlockSweetAlertError, setShowUnlockSweetAlertSuccess, dispatch, cart, onDone])

    const cartLockerErrors = useMemo(() => {
        return typeof lockersErrors[cart['@id']] !== 'undefined' ? lockersErrors[cart['@id']] : []
    }, [cart, lockersErrors])

    useEffect(() => {
        setShowUnlockPopover(showPopover)
    }, [showPopover, setShowUnlockPopover])

    useEffect(() => {
        if (!lockersSuccess || (cart['@id'] && !lockersSuccess[cart['@id']])) {
            setShowUnlockSweetAlertSuccess(false)
            return
        }
        setShowUnlockSweetAlertSuccess(lockersSuccess[cart['@id']] || false)
    }, [setShowUnlockSweetAlertSuccess, lockersSuccess, cart])

    useEffect(() => {
        if (!lockersErrors || (cart['@id'] && !lockersErrors[cart['@id']])) {
            setShowUnlockSweetAlertError(false)
            return
        }
        setShowUnlockSweetAlertError(typeof lockersErrors[cart['@id']] !== 'undefined' || false)
    }, [setShowUnlockSweetAlertError, lockersErrors, cart])

    return (
        <>
            <Portal>
                <SweetAlert
                    showCancel
                    warning={true}
                    cancelBtnText={formatMessage({ id: 'default.cancel' })}
                    confirmBtnText={formatMessage({ id: 'default.continue' })}
                    show={showUnlockSweetAlert}
                    title={formatMessage({ id: `default.warning` })}
                    onCancel={handleUnlockCancel}
                    onConfirm={handleUnlockValid}
                >
                    <FormattedMessage id={'cart.cart_unlock_message'} />
                </SweetAlert>
                <SweetAlert
                    success
                    confirmBtnText={formatMessage({ id: 'default.ok' })}
                    show={showUnlockSweetAlertSuccess}
                    title={formatMessage({ id: `cart.lock` })}
                    onConfirm={handleSweetAlertLockSuccessClick}
                >
                    <FormattedMessage id={'cart.unlocked_success_message'} />
                </SweetAlert>
                <SweetAlert
                    error
                    dependencies={[cartLockerErrors]}
                    confirmBtnText={formatMessage({ id: 'default.ok' })}
                    show={showUnlockSweetAlertError}
                    title={formatMessage({ id: `cart.lock` })}
                    onConfirm={handleSweetAlertLockFailClick}
                >
                    {cartLockerErrors.map((cartLockerError, idx) => (
                        <p key={idx} className="mb-0">
                            {cartLockerError.message}
                        </p>
                    ))}
                </SweetAlert>
            </Portal>
            <Overlay show={showUnlockPopover} target={cartLightRef}>
                {(props) => (
                    <Popover
                        className="cart-light-unlock-popover"
                        id={`cart-light-unlock-${cart['@id']}`}
                        {...props}
                        onMouseEnter={handlePopoverMouseEnter}
                        onMouseLeave={handlePopoverMouseLeave}
                    >
                        <Popover.Title>
                            <FormattedMessage id="cart.cart_locked" />
                        </Popover.Title>
                        <Popover.Content>
                            <FormattedMessage id="cart.cart_unlock_explain" />
                            <Button variant="primary" onClick={handleUnlockClick} className="btn-unlock">
                                <FontAwesomeIcon icon={['fal', 'lock']} className={'app-icon locked-icon'} />
                                <FormattedMessage id="cart.unlock_cart" />
                            </Button>
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
            <span
                ref={cartLightRef}
                onClick={handleUnlockClick}
                onMouseEnter={handleLockerMouseEnter}
                className={classNames('locked-icon-container', className)}
            >
                <FontAwesomeIcon icon={['fal', 'lock']} className={'app-icon locked-icon'} />
                {showLabel && (
                    <>
                        {' '}
                        <FormattedMessage id="cart.unlock_cart" />
                    </>
                )}
            </span>
        </>
    )
}

CartLocker.defaultProps = {} as Partial<IProps>

export default CartLocker
