/**
 *
 * Badge
 *
 */
import classNames, { Value as ClassValue } from 'classnames'
import React, { memo, ReactChild } from 'react'
import { FormattedMessage } from 'react-intl'
import FlatIcon from '../Icon/FlatIcon'
import { OverlayTrigger, Popover } from 'react-bootstrap'

interface IProps {
    expanded?: boolean
    className?: ClassValue
    uniqId?: string
}

const uniqIdGenerator = require('uniqid')
function Badge({ expanded, className, uniqId }: IProps): JSX.Element {
    const popoverId = uniqId ? uniqId : uniqIdGenerator()
    const PopoverGeneralPrice: ReactChild = (
        <Popover id={`popover-general-price-${popoverId}`}>
            <Popover.Content>
                <FormattedMessage id={'product.in_listing'} />
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger
            placement={'auto'}
            trigger={['hover', 'focus']}
            show={typeof expanded === 'boolean' && expanded ? false : undefined}
            overlay={PopoverGeneralPrice}
        >
            <span className={classNames('general-price', { expanded }, className)}>
                <FlatIcon icon="available-box" />
                {expanded && <FormattedMessage id="product.in_listing" />}
            </span>
        </OverlayTrigger>
    )
}

Badge.defaultProps = {
    general: false,
} as Partial<IProps>

export default memo(Badge)
