/*
 *
 * Maintenance
 *
 */

import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MenuList, { ISlideMenuProps } from '../../components/SlideMenu/SlideMenu'
import { IApplicationRootState } from '../../store'
import { ISlideMenuItemCollection } from '../../store/classification/types'
import { makeSelectSideMenuActiveId, makeSelectSideMenuItems } from '../../store/sidemenu/selectors'

type IProps = Omit<ISlideMenuProps, 'items' | 'level'>

const stateSelector = createStructuredSelector<any, any>({
    items: makeSelectSideMenuItems(),
    activeId: makeSelectSideMenuActiveId(),
})

function MobileMenu(props: IProps): JSX.Element {
    const { items, activeId } = useSelector<
        IApplicationRootState,
        { items: ISlideMenuItemCollection; activeId?: string }
    >(stateSelector)
    return <MenuList {...props} items={items} navigateTo={activeId} />
}

MobileMenu.defaultProps = {} as Partial<IProps>

export default memo(MobileMenu)
