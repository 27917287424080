/**
 *
 * CreateOrderErrors
 *
 */
import React, { memo } from 'react'
import { Alert } from 'react-bootstrap'
import { ICartCollection } from '../../services/api/service/carts/types'
import { IFamilyTreeCollection } from '../../services/api/service/classification/types'
import { IAppErrorTypes, IHttpError } from '../../store/app/types'
import { ICreateOrderErrorsType } from '../../store/carts/types'
import { findShippingLocationBy } from '../../store/classification/utils'
import { useIntl } from 'react-intl'

interface IProps {
    tree: IFamilyTreeCollection
    carts: ICartCollection
    errors: ICreateOrderErrorsType
}

function CreateOrderErrors({ tree, carts, errors }: IProps): JSX.Element {
    const { formatMessage } = useIntl()

    if (Object.keys(errors).length === 0) {
        return <></>
    }

    const keys = Object.keys(errors)
    const values = Object.values(errors)

    return (
        <>
            {keys.map((key, index) => {
                const error: IAppErrorTypes = values[index]
                const cart = carts.find((cart) => cart['@id'] === key)
                const shippingLocation = findShippingLocationBy(tree, '@id', cart!.shipping_location)

                let errorMessage = error?.message || formatMessage({ id: 'checkout.save_to_order.error' })
                if (error?.isHttpError) {
                    const violations = (error as IHttpError)?.violations
                    if (violations && violations['cart']) {
                        errorMessage = violations['cart'].join('<br />')
                    }
                }

                return (
                    <Alert key={`cart_global_error_${cart!['@id']}`} variant={'danger'}>
                        <h3>{shippingLocation?.full_name}</h3>
                        <p className={'mb-0'}>{errorMessage}</p>
                    </Alert>
                )
            })}
        </>
    )
}

CreateOrderErrors.defaultProps = {} as Partial<IProps>

export default memo(CreateOrderErrors)
