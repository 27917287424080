import React, { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../routes'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    className?: string
}

function Favorite(): JSX.Element {
    const { locale } = useIntl()
    const favoriteUrl = generatePath(getPath('favorite', locale), { lang: locale })

    return (
        <NavLink to={favoriteUrl} className="nav-item favorite">
            <FlatIcon icon={'heart-o-2'} />
            <span className={'lbl'}>
                <FormattedMessage id="default.favorite" />
            </span>
        </NavLink>
    )
}

Favorite.defaultProps = {} as Partial<IProps>

export default memo(Favorite)
