/*
 *
 * Products actions
 *
 */

import { action } from 'typesafe-actions'

import {
    IProductFavoritePayload,
    IProductsCrudSelectionPayload,
    IProductSearchProcessPayload,
    IProductsListProcessPayload,
    IProductsListSuccessPayload,
    IProductsPersistSettingsPayload,
    IProductBulkFavoriteProcessPayload,
    IProductsSelectionApplyActionFailurePayload,
    IProductsSelectionApplyActionProcessPayload,
    IProductsSelectionApplyActionSuccessPayload,
    IProductsSelectionSetActionPayload,
    IProductBulkFavoriteSuccessPayload,
    IProductBulkFavoriteFailurePayload,
    IProductGridSuccessPayload,
    IProductGridFailurePayload,
    IProductSidebarChangeModePayload,
} from './types'

import ActionTypes from './constants'
import {
    IApiProductGridApiCollection,
    IApiProductListPagination,
    IApiProductListResponseDataTypes,
    IProductListParameters,
    IProductListPersistParameters,
    IProductsListGridDefinition,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../../services/api/service/products/types'
import { IAppErrorTypes } from '../app/types'
import { Undefinable } from 'tsdef'
import { $PropertyType } from 'utility-types'
import { ProductListSidebarMode } from './utils'

// GLOBAL
export const productsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

export const productsListFavoriteAction = (productId: string, favorite: boolean) =>
    action<ActionTypes.LIST_FAVORITE_PRODUCT_ACTION, IProductFavoritePayload>(
        ActionTypes.LIST_FAVORITE_PRODUCT_ACTION,
        { productId, favorite }
    )

export const productsListProcessAction = (mode: ProductsListMode, params: IProductListParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IProductsListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        mode,
        params,
    })

export const productsListRefreshAction = () => action<ActionTypes.LIST_REFRESH_ACTION>(ActionTypes.LIST_REFRESH_ACTION)

export const productsListSuccessAction = (
    response: IApiProductListResponseDataTypes,
    params: IProductListParameters,
    showPriceFilters?: boolean
) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IProductsListSuccessPayload>(ActionTypes.LIST_SUCCESS_ACTION, {
        response,
        params,
        showPriceFilters,
    })
export const productsListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const productsListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)

export const productsSearchProcessAction = (params: IProductListParameters) => {
    return action<ActionTypes.SEARCH_PROCESS_ACTION, IProductSearchProcessPayload>(ActionTypes.SEARCH_PROCESS_ACTION, {
        params,
    })
}
export const productsSearchSuccessAction = (response: IApiProductListPagination) =>
    action<ActionTypes.SEARCH_SUCCESS_ACTION, IApiProductListPagination>(ActionTypes.SEARCH_SUCCESS_ACTION, response)
export const productsSearchFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.SEARCH_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.SEARCH_FAILURE_ACTION, { error })
export const productsSearchResetAction = () => action<ActionTypes.SEARCH_RESET_ACTION>(ActionTypes.SEARCH_RESET_ACTION)

export const productsPersistParamsAction = (params: IProductListPersistParameters, merge = false) =>
    action<ActionTypes.PERSIST_PARAMS_ACTION, IProductsPersistSettingsPayload>(ActionTypes.PERSIST_PARAMS_ACTION, {
        params,
        merge,
    })

export const productsPersistedParamsAction = () =>
    action<ActionTypes.PERSISTED_PARAMS_ACTION>(ActionTypes.PERSISTED_PARAMS_ACTION)

export const productsSelectionAddAction = (productId: string) =>
    action<ActionTypes.LIST_SELECTION_ADD_ACTION, IProductsCrudSelectionPayload>(
        ActionTypes.LIST_SELECTION_ADD_ACTION,
        {
            productId,
        }
    )
export const productsSelectionAddBulkAction = (productIds: Array<string>) =>
    action<ActionTypes.LIST_SELECTION_ADD_BULK_ACTION, { productIds: Array<string> }>(
        ActionTypes.LIST_SELECTION_ADD_BULK_ACTION,
        {
            productIds,
        }
    )
export const productsSelectionRemoveBulkAction = (productIds: Array<string>) =>
    action<ActionTypes.LIST_SELECTION_REMOVE_BULK_ACTION, { productIds: Array<string> }>(
        ActionTypes.LIST_SELECTION_REMOVE_BULK_ACTION,
        {
            productIds,
        }
    )
export const productsSelectionRemoveAction = (productId: string) =>
    action<ActionTypes.LIST_SELECTION_REMOVE_ACTION, IProductsCrudSelectionPayload>(
        ActionTypes.LIST_SELECTION_REMOVE_ACTION,
        {
            productId,
        }
    )
export const productsSelectionSetAction = (code: string) =>
    action<ActionTypes.LIST_SELECTION_SET_ACTION, IProductsSelectionSetActionPayload>(
        ActionTypes.LIST_SELECTION_SET_ACTION,
        {
            action: code,
        }
    )
export const productsSelectionApplyActionProcess = (params?: any) =>
    action<ActionTypes.LIST_SELECTION_APPLY_ACTION_PROCESS, IProductsSelectionApplyActionProcessPayload>(
        ActionTypes.LIST_SELECTION_APPLY_ACTION_PROCESS,
        {
            ...params,
        }
    )

export const productsSelectionApplyActionSuccess = (message?: Undefinable<string>, extra?: any) =>
    action<ActionTypes.LIST_SELECTION_APPLY_ACTION_SUCCESS, IProductsSelectionApplyActionSuccessPayload>(
        ActionTypes.LIST_SELECTION_APPLY_ACTION_SUCCESS,
        {
            message,
            extra,
        }
    )

export const productsSelectionApplyActionFailure = (error: IAppErrorTypes, extra?: any) =>
    action<ActionTypes.LIST_SELECTION_APPLY_ACTION_FAILURE, IProductsSelectionApplyActionFailurePayload>(
        ActionTypes.LIST_SELECTION_APPLY_ACTION_FAILURE,
        { error, extra }
    )

export const productsSelectionResetProcessAction = () =>
    action<ActionTypes.LIST_SELECTION_RESET_PROCESS_ACTION>(ActionTypes.LIST_SELECTION_RESET_PROCESS_ACTION)

export const productsSelectionResetAction = () =>
    action<ActionTypes.LIST_SELECTION_RESET_ACTION>(ActionTypes.LIST_SELECTION_RESET_ACTION)

export const productsBulkRemoveFavoriteProcessAction = (productIds: Array<string>) =>
    action<ActionTypes.BULK_REMOVE_FAVORITE_PROCESS_ACTION, IProductBulkFavoriteProcessPayload>(
        ActionTypes.BULK_REMOVE_FAVORITE_PROCESS_ACTION,
        {
            productIds,
        }
    )

export const productsBulkRemoveFavoriteSuccessAction = (productIds: Array<string>) =>
    action<ActionTypes.BULK_REMOVE_FAVORITE_SUCCESS_ACTION, IProductBulkFavoriteSuccessPayload>(
        ActionTypes.BULK_REMOVE_FAVORITE_SUCCESS_ACTION,
        {
            productIds,
        }
    )

export const productsBulkRemoveFavoriteFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.BULK_REMOVE_FAVORITE_FAILURE_ACTION, IProductBulkFavoriteFailurePayload>(
        ActionTypes.BULK_REMOVE_FAVORITE_FAILURE_ACTION,
        {
            error,
        }
    )

export const productsBulkRemoveFavoriteResetAction = () =>
    action<ActionTypes.BULK_REMOVE_FAVORITE_RESET_ACTION>(ActionTypes.BULK_REMOVE_FAVORITE_RESET_ACTION)

export const productsBulkAddFavoriteProcessAction = (productIds: Array<string>) =>
    action<ActionTypes.BULK_ADD_FAVORITE_PROCESS_ACTION, IProductBulkFavoriteProcessPayload>(
        ActionTypes.BULK_ADD_FAVORITE_PROCESS_ACTION,
        {
            productIds,
        }
    )

export const productsBulkAddFavoriteSuccessAction = (productIds: Array<string>) =>
    action<ActionTypes.BULK_ADD_FAVORITE_SUCCESS_ACTION, IProductBulkFavoriteSuccessPayload>(
        ActionTypes.BULK_ADD_FAVORITE_SUCCESS_ACTION,
        {
            productIds,
        }
    )

export const productsBulkAddFavoriteFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.BULK_ADD_FAVORITE_FAILURE_ACTION, IProductBulkFavoriteFailurePayload>(
        ActionTypes.BULK_ADD_FAVORITE_FAILURE_ACTION,
        {
            error,
        }
    )

export const productsBulkAddFavoriteResetAction = () =>
    action<ActionTypes.BULK_ADD_FAVORITE_RESET_ACTION>(ActionTypes.BULK_ADD_FAVORITE_RESET_ACTION)

export const productListGridsProcessAction = () =>
    action<ActionTypes.LIST_PRODUCT_GRID_PROCESS_ACTION>(ActionTypes.LIST_PRODUCT_GRID_PROCESS_ACTION)

export const productListGridsSuccessAction = (response: $PropertyType<IApiProductGridApiCollection, 'hydra:member'>) =>
    action<ActionTypes.LIST_PRODUCT_GRID_SUCCESS_ACTION, IProductGridSuccessPayload>(
        ActionTypes.LIST_PRODUCT_GRID_SUCCESS_ACTION,
        {
            response,
        }
    )

export const productListGridFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_PRODUCT_GRID_FAILURE_ACTION, IProductGridFailurePayload>(
        ActionTypes.LIST_PRODUCT_GRID_FAILURE_ACTION,
        {
            error,
        }
    )

export const productListGridStoreDefinitionAction = (definition?: IProductsListGridDefinition) =>
    action<ActionTypes.LIST_PRODUCT_GRID_SELECT_DEFINITION_ACTION, { definition?: IProductsListGridDefinition }>(
        ActionTypes.LIST_PRODUCT_GRID_SELECT_DEFINITION_ACTION,
        { definition }
    )

export const productListGridResetDefinitionAction = () =>
    action<ActionTypes.LIST_PRODUCT_GRID_RESET_DEFINITION_ACTION>(ActionTypes.LIST_PRODUCT_GRID_RESET_DEFINITION_ACTION)

export const productListSidebarChangeMode = (
    displayMode: ProductsListDisplayMode,
    sidebarMode: ProductListSidebarMode,
    persist = false
) =>
    action<ActionTypes.LIST_SIDEBAR_CHANGE_MODE, IProductSidebarChangeModePayload>(
        ActionTypes.LIST_SIDEBAR_CHANGE_MODE,
        { displayMode, sidebarMode, persist }
    )
