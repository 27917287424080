import React, { memo, MouseEvent, useCallback, useMemo, useRef } from 'react'
import { IOrderList } from '../../../services/api/service/orders/types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { generatePath, useHistory } from 'react-router-dom'
import { getPath } from '../../../routes'
import { Nullable } from 'tsdef'
import isUndefined from 'lodash/isUndefined'
import { OrderDetailAction } from './type'
import isNull from 'lodash/isNull'

type Props<T extends IOrderList = IOrderList> = OrderDetailAction & {
    tooltip?: string | null
    routeName?: string
    order: T
    onClick?: (e: MouseEvent<HTMLButtonElement>, order: T) => void
}

const Export = ({
    order,
    onClick,
    icon,
    label,
    size,
    block,
    tooltip,
    variant = 'link',
    routeName = 'customerOrder',
}: Props): JSX.Element => {
    const { locale, formatMessage } = useIntl()
    const targetBtn = useRef<HTMLButtonElement>()
    const history = useHistory()

    const icn: Nullable<IconProp> = useMemo(() => {
        if (isUndefined(icon)) {
            return ['fal', 'eye']
        }
        return icon
    }, [icon])

    const lbl = useMemo(() => {
        if (isUndefined(label)) {
            return formatMessage({ id: `${order.type}.detail.view` })
        }
        return label
    }, [label, formatMessage])

    const tooltipMessage = useMemo(() => {
        if (isUndefined(tooltip)) {
            return formatMessage({ id: `${order.type}.detail.view` })
        }
        return tooltip
    }, [tooltip, formatMessage])

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (onClick) {
                onClick(e, order)
            }

            const orderDetailUrl = generatePath(getPath(routeName, locale), {
                lang: locale,
                orderId: order.id,
            })
            history.push(orderDetailUrl)
        },
        [onClick, order, history, locale, routeName]
    )

    return (
        <>
            <OverlayTrigger
                show={isNull(tooltipMessage) ? false : undefined}
                overlay={<Tooltip id={`tooltip-${order.id}-view`}>{tooltipMessage}</Tooltip>}
            >
                <Button ref={targetBtn} variant={variant} onClick={handleClick} size={size} block={block}>
                    {icn && <FontAwesomeIcon icon={icn} />}
                    {lbl && <span className="lbl">{lbl}</span>}
                </Button>
            </OverlayTrigger>
        </>
    )
}

export default memo(Export)
