/**
 *
 * StoreQuantity
 *
 */
import React, { memo, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ICustomer } from '../../services/api/service/customers/types'
import { IProductCatalog } from '../../services/api/service/products/types'
import ResumeCard from '../Customer/ResumeCard'
import QuantitySelector from '../QuantitySelector/QuantitySelector'

interface IProps {
    store: ICustomer
    product: IProductCatalog
    quantity: number
    packing: number
    saving: boolean
    showPacking: boolean
    outOfStock: boolean
    outOfStockLabel: string
    onQuantityChange?: (storeId: string, quantity: number, oldQuantity: number) => void
}

function StoreQuantity({
    store,
    quantity,
    packing,
    saving,
    showPacking,
    onQuantityChange,
    outOfStock,
    outOfStockLabel,
}: IProps): JSX.Element {
    const handleOnQuantityChange = useCallback(
        (quantity: number, oldQuantity: number) => {
            if (onQuantityChange) {
                onQuantityChange(store.id, quantity, oldQuantity)
            }
        },
        [store, onQuantityChange]
    )

    return (
        <div className={'store-quantity'}>
            <Row>
                <Col xs={24} md={12}>
                    <ResumeCard customer={store} />
                </Col>
                <Col xs={24} md={'auto'}>
                    <QuantitySelector
                        onQuantityChange={handleOnQuantityChange}
                        packing={packing}
                        currentValue={quantity}
                        saving={saving}
                        showPacking={showPacking}
                        outOfStock={outOfStock}
                        outOfStockLabel={outOfStockLabel}
                    />
                </Col>
            </Row>
        </div>
    )
}

StoreQuantity.defaultProps = {
    saving: false,
    showPacking: true,
    outOfStock: false,
} as Partial<IProps>

export default memo(StoreQuantity)
