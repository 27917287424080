/*
 *
 * Pagination
 *
 */

import paginator from 'paginator'
import Qs from 'qs'
import React, { memo, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Component } from '../../../components/Pagination/index'
import Config from '../../../config'
import { ProductListQueryName } from '../../../services/api/service/products/types'
import rtrim from '../../../utils/rtrim'

interface IProps {
    loading: boolean
    count?: number
}

const pageRangeDisplayed = Config.PRODUCT_LIST.PAGE_RANGE_DISPLAYED
function Pagination({ loading, count }: IProps): JSX.Element {
    const history = useHistory()
    const { search, pathname } = useLocation()

    const itemsPerPage = useMemo(() => {
        const params = new URLSearchParams(search)
        const param = params.get(ProductListQueryName.ItemsPerPage)
        const itemsPerPage = param !== null && param.length > 0 ? parseInt(param) : Config.PRODUCT_LIST.ITEMS_PER_PAGE
        return isNaN(itemsPerPage) ? Config.PRODUCT_LIST.ITEMS_PER_PAGE : itemsPerPage
    }, [search])

    const currentPage = useMemo(() => {
        const params = new URLSearchParams(search)
        const param = params.get(ProductListQueryName.Page)
        const page = param !== null && param.length > 0 ? parseInt(param) : 1
        return isNaN(page) ? 1 : page
    }, [search])

    const multipage = useMemo(() => {
        if (typeof count === 'undefined') {
            return undefined
        }
        return new paginator(itemsPerPage, pageRangeDisplayed).build(count, currentPage)
    }, [count, itemsPerPage, currentPage])

    const handlePageChange = useCallback(
        (selectedPage: number) => {
            const parsed = Qs.parse(search.substring(1))
            parsed.page = String(selectedPage)
            const stringified = Qs.stringify(parsed)
            const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
            const baseUrl = rtrim(pathname, '/')
            history.push(`${baseUrl}${qstring}`)
        },
        [history, search, pathname]
    )

    const show = useMemo(() => {
        return typeof count === 'number' && count > 0 && typeof multipage !== 'undefined'
    }, [count, multipage])

    return (
        <Component
            show={show}
            disabled={loading}
            currentPage={multipage?.current_page}
            totalPages={multipage?.total_pages}
            firstPage={multipage?.first_page}
            lastPage={multipage?.last_page}
            hasNextPage={multipage?.has_next_page}
            hasPreviousPage={multipage?.has_previous_page}
            previousPage={multipage?.previous_page}
            nextPage={multipage?.next_page}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageClick={handlePageChange}
        />
    )
}

Pagination.defaultProps = {} as Partial<IProps>

export default memo(Pagination)
