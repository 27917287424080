import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiOrderDocumentResponse,
    IApiOrderListResponse,
    IApiOrderResponse,
    IApiOrdersCreateResponse,
    IOrderDocumentType,
    IOrderListParameters,
    IOrdersService,
} from './types'

const create = (instance: AxiosInstance): IOrdersService => {
    /*****************************
     * METHODS
     *****************************/
    const create = (
        cartId: string,
        comment?: string,
        requestedDeliveryDate?: string,
        validateOnlyMinimumAmountOrders?: boolean,
        paymentMode?: string
    ): Promise<IApiOrdersCreateResponse> => {
        return instance.post('/orders', {
            cart: cartId,
            comment,
            requestedDeliveryDate,
            validateOnlyMinimumAmountOrders,
            paymentMode,
        })
    }

    const list = (params: IOrderListParameters, cancelToken?: CancelToken): Promise<IApiOrderListResponse> => {
        return instance.get('/orders', { params: params, cancelToken })
    }

    const get = (orderId: string, cancelToken?: CancelToken): Promise<IApiOrderResponse> => {
        return instance.get(`/orders/${orderId}`, { cancelToken })
    }

    const document = (
        orderId: string,
        documentType: IOrderDocumentType,
        cancelToken?: CancelToken
    ): Promise<IApiOrderDocumentResponse> => {
        let path = 'pdf'
        if (documentType === IOrderDocumentType.DeliveryNoteExcel) {
            path = 'excel/delivery-note'
        } else if (documentType === IOrderDocumentType.PurchaseOrderExcel) {
            path = 'excel/purchase-order'
        } else if (documentType === IOrderDocumentType.DeliveryRemaindersExcel) {
            path = 'excel/remainders'
        }
        return instance.get(`/orders/${orderId}/${path}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        create,
        list,
        get,
        document,
    }
}

export default {
    create,
}
