/*
 *
 * Sidebar
 *
 */

import classNames from 'classnames'
import React from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import FlatIcon from '../../../components/Icon/FlatIcon'
import { ICartCollection } from '../../../services/api/service/carts/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectMainCartsList } from '../../../store/carts/selectors'
import List from './List'
import { ISidebarProps } from './type'
import { makeSelectOrdersMode } from '../../../store/orders/selectors'
import { IOrderMode } from '../../../services/api/service/orders/types'
import { makeSelectCustomer } from '../../../store/customers/selectors'
import CartModeSwitcher from '../../CartModeSwitcher/CartModeSwitcher'

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    orderMode: makeSelectOrdersMode(),
    customer: makeSelectCustomer(),
})

function Sidebar({ onCartTitleClick, activeCartId }: ISidebarProps): JSX.Element {
    const { carts, orderMode } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            orderMode: IOrderMode
        }
    >(stateSelector)

    return (
        <Card className={classNames('cart-card cart-resume')}>
            <Card.Header>
                <Card.Title className={'cart-resume-header'}>
                    <FlatIcon icon={'list'} />
                    <FormattedMessage id={`cart.resume_${orderMode}`} values={{ total: carts.length }} />
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <CartModeSwitcher />
                <List onCartTitleClick={onCartTitleClick} activeCartId={activeCartId} />
            </Card.Body>
        </Card>
    )
}

Sidebar.defaultProps = {} as Partial<ISidebarProps>

export default Sidebar
