/**
 *
 * Product Card
 *
 */
import React, { useCallback } from 'react'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { ProductClickCallback } from '../../../types/productCallback'
import LightList from '../List/LightList'

interface IProps {
    product: IProductCatalog
    className?: string
    showPrices?: boolean
    onProductClick?: ProductClickCallback
}

function CarouselItem({ product, className, onProductClick, showPrices = true }: IProps): JSX.Element {
    const handleProductClick = useCallback(() => {
        if (onProductClick) {
            onProductClick(product)
        }
    }, [onProductClick, product])

    return (
        <LightList
            product={product}
            onProductClick={handleProductClick}
            className={className}
            showPrices={showPrices}
        />
    )
}

CarouselItem.defaultProps = {} as Partial<IProps>

export default CarouselItem
