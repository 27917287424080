/**
 * Initialisation de la "library" FontAwesome permettant d'exploiter les icones sous forme de composants React
 * dans toute l'application
 * @see https://github.com/FortAwesome/react-fontawesome
 * @see https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 *
 * ce fichier utils est à importer dans la phase d'initialisation de l'application
 * puis appeller la factory createFontAwesomeLibrary
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal, faLock as falLock, faUser as falUser } from '@fortawesome/pro-light-svg-icons'

export function createFontAwesomeLibrary(): void {
    library.add(fas, fad, fal, falLock, falUser)
}
