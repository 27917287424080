/*
 *
 * Orders reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'
import { IOrderMode } from '../../services/api/service/orders/types'

export const initialListState = {
    fetching: false,
}

export const initialDocumentState = {}
export const initialCustomerState = {}

export const initialState: ContainerState = {
    list: initialListState,
    customer: initialCustomerState,
    last: initialListState,
    document: initialDocumentState,
    mode: IOrderMode.Order,
}

function ordersReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return { ...initialState }
        case ActionTypes.RESET_COLLECTION_ACTION:
            return {
                ...initialState,
                mode: state.mode,
            }
        case ActionTypes.LIST_SUCCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        case ActionTypes.LAST_SUCCESS_ACTION:
            return {
                ...state,
                last: {
                    ...state.last,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        case ActionTypes.LIST_RESET_ACTION: {
            return {
                ...state,
                list: {
                    ...initialListState,
                },
            }
        }
        case ActionTypes.LAST_RESET_ACTION: {
            return {
                ...state,
                last: {
                    ...initialListState,
                },
            }
        }
        case ActionTypes.DOCUMENT_PROCESS_ACTION: {
            const { orderId, documentType } = action.payload
            return {
                ...state,
                document: {
                    ...state.document,
                    [orderId]: {
                        ...(state.document[orderId] || {}),
                        [documentType]: {
                            fetching: true,
                        },
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_FAILURE_ACTION: {
            const { orderId, documentType } = action.payload

            return {
                ...state,
                document: {
                    ...state.document,
                    [orderId]: {
                        ...state.document[orderId],
                        [documentType]: {
                            tms: Date.now(),
                            fetching: false,
                            error: action.payload.error,
                            link: undefined,
                        },
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_RESET_SINGLE_ACTION: {
            const { orderId, documentType } = action.payload

            return {
                ...state,
                document: {
                    ...state.document,
                    [orderId]: {
                        ...state.document[orderId],
                        [documentType]: {
                            fetching: false,
                        },
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_RESET_ACTION: {
            return {
                ...state,
                document: {
                    ...initialDocumentState,
                },
            }
        }
        case ActionTypes.DOCUMENT_SUCCESS_ACTION: {
            const { orderId, documentType } = action.payload

            return {
                ...state,
                document: {
                    ...state.document,
                    [orderId]: {
                        ...state.document[orderId],
                        [documentType]: {
                            ...state.document[orderId][documentType],
                            tms: Date.now(),
                            fetching: false,
                            link: action.payload.response.data,
                        },
                    },
                },
            }
        }
        case ActionTypes.SWITCH_ORDER_MODE_ACTION: {
            return {
                ...state,
                mode: action.payload.mode,
            }
        }
        case ActionTypes.CUSTOMER_PROCESS_ACTION: {
            const { customer: identifier } = action.payload

            return {
                ...state,
                customer: {
                    ...state.customer,
                    [identifier]: {
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.CUSTOMER_SUCCESS_ACTION: {
            const { customer } = action.payload

            return {
                ...state,
                customer: {
                    ...state.customer,
                    [customer.id]: {
                        fetching: false,
                        customer,
                    },
                },
            }
        }
        case ActionTypes.CUSTOMER_FAILURE_ACTION: {
            const { customer, error } = action.payload

            return {
                ...state,
                customer: {
                    ...state.customer,
                    [customer]: {
                        fetching: false,
                        error,
                    },
                },
            }
        }
        default:
            return state
    }
}

export default ordersReducer
