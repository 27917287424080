import { select, takeLatest, put, getContext, call, delay } from 'redux-saga/effects'
import ProductActionTypes from '../product/constants'
import CustomersActionTypes from './constants'
import { IProductDetail } from '../../services/api/service/products/types'
import { makeSelectProductDetail } from '../product/selectors'
import {
    customerGetSuccessAction,
    customerModifyLoginFailureAction,
    customerModifyLoginSuccessAction,
    customerResetCurrent,
    customerSalesmenFailureAction,
    customerSalesmenSuccessAction,
    customersResetAction,
    customersResetAllAction,
    customerViewedProductsFailureAction,
    customerViewedProductsInvalidateAction,
    customerViewedProductsSuccessAction,
} from './actions'
import { IApiClient } from '../../services/api/types'
import { formatAppError } from '../app/saga'
import { ICustomerLogoutCurrentAction, ICustomerModifyLoginProcessAction, ICustomerSetCurrentAction } from './types'
import Config from '../../config'
import * as Sentry from '@sentry/react'
import { IApiCustomerGetResponse } from '../../services/api/service/customers/types'
import localforage from 'localforage'
import { reloadingAction } from '../app/actions'
import { splashScreenShowAction } from '../splashScreen/actions'
import { makeSelectAuthMe } from '../auth/selectors'
import { isSalesmanResource } from '../salesmens/utils'
import { IApiCustomerSalesmenResponse } from '../../services/api/service/salesman/types'
import { push } from 'connected-react-router'

export function* processRetrieveCustomer(customerId: string) {
    const api: IApiClient = yield getContext('api')

    const response: IApiCustomerGetResponse = yield call({ context: api.customers, fn: 'get' }, customerId)
    const customer = response.data

    yield put(customerGetSuccessAction(customer))

    return customer
}

function* processResetCurrentCustomer(action: ICustomerLogoutCurrentAction) {
    const { redirectUrl } = action.payload
    const storage: typeof localforage = yield getContext('storage')
    const me = yield select(makeSelectAuthMe())
    const api: IApiClient = yield getContext('api')

    // redirect
    if (redirectUrl) {
        yield put(push(redirectUrl))
    }

    // remove ID
    yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.CUSTOMER_ID_STORAGE_NAME)
    // on set le customer id
    yield call({ context: api, fn: 'setCustomerId' }, undefined)

    // reset customer
    yield put(customerResetCurrent())
    yield put(customersResetAllAction())

    // redirection si salesman
    if (!isSalesmanResource(me)) {
        return
    }
}

function* processGetCurrentCustomer(action: ICustomerSetCurrentAction) {
    const { customerId, reloadApp, redirectUrl } = action.payload
    const me = yield select(makeSelectAuthMe())
    const api: IApiClient = yield getContext('api')
    const storage: typeof localforage = yield getContext('storage')

    if (!isSalesmanResource(me)) {
        return
    }

    if (reloadApp) {
        yield put(splashScreenShowAction(true))
        yield delay(400) // ca va tellement vite que l'interface "rame". ON patiente un tout petit peu
    }

    // reset customer
    yield put(customersResetAction())

    if (!customerId) {
        yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.CUSTOMER_ID_STORAGE_NAME)
        return
    }

    try {
        // stockage
        yield call({ context: storage, fn: 'setItem' }, Config.AUTH.CUSTOMER_ID_STORAGE_NAME, customerId)

        // on set le customer id
        yield call({ context: api, fn: 'setCustomerId' }, customerId)

        // récupération des informations du client
        if (!reloadApp) {
            yield call(processRetrieveCustomer, customerId)
        } else {
            yield put(reloadingAction(undefined, redirectUrl))
        }
    } catch (e) {
        // on remet à zéro la sélection du client !
        yield put(customerResetCurrent())

        // stockage erreur !
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }
}

function* processInvalidateViewedProducts() {
    const productDetail: IProductDetail = yield select(makeSelectProductDetail())
    if (!productDetail) {
        return
    }
    yield put(customerViewedProductsInvalidateAction(productDetail['@id']))
}

function* processViewedProductsRequest() {
    const api: IApiClient = yield getContext('api')
    try {
        // récupération des produits vus
        const response = yield call({ context: api.products, fn: 'viewedProducts' })
        yield put(customerViewedProductsSuccessAction(response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'default.error')
        yield put(customerViewedProductsFailureAction(error))
    }
}

function* processModifyLoginRequest(action: ICustomerModifyLoginProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const params = action.payload
        yield call({ context: api.customers, fn: 'changePassword' }, params)
        yield put(customerModifyLoginSuccessAction())
    } catch (e) {
        yield put(customerModifyLoginFailureAction(e))
    }
}

export function* processCustomerSalesmenRequest() {
    const api: IApiClient = yield getContext('api')

    try {
        const response: IApiCustomerSalesmenResponse | undefined = yield call({
            context: api.salesman,
            fn: 'getCustomerSalesmen',
        })
        if (response) {
            yield put(customerSalesmenSuccessAction(response.data['hydra:member']))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(customerSalesmenFailureAction(error))
    }
}

export default [
    takeLatest(ProductActionTypes.LOADED_ACTION, processInvalidateViewedProducts),
    takeLatest(CustomersActionTypes.PROCESS_VIEWED_PRODUCTS_ACTION, processViewedProductsRequest),
    takeLatest(CustomersActionTypes.PROCESS_MODIFY_LOGIN_ACTION, processModifyLoginRequest),
    takeLatest(CustomersActionTypes.SET_CURRENT_CUSTOMER_ACTION, processGetCurrentCustomer),
    takeLatest(CustomersActionTypes.LOGOUT_CURRENT_CUSTOMER_ACTION, processResetCurrentCustomer),
    takeLatest(CustomersActionTypes.SALESMEN_PROCESS_ACTION, processResetCurrentCustomer),
]
