const breakpoints = {
    screen: 'screen',
    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px)',
    small: '(max-width: 1023px)',
    desktop: '(min-width: 1024px)',
    large: '(min-width: 1200px)',
    extraLarge: '(min-width: 1560px)',
    lg: '(min-width: 992px)', // cohérence breakpoint bootstrap
}

export default breakpoints
