import classNames from 'classnames'
import Qs from 'qs'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useHistory, useLocation } from 'react-router-dom'
import { IProductListFilterOption } from '../../../../../services/api/service/products/types'
import { IProductListUserFilterValue } from '../../../../../store/products/types'
import rtrim from '../../../../../utils/rtrim'
import Heading from '../../Partial/Heading'
import { IFactoryProps } from '../../type'
import Entry from './Entry'

function TypeOrder({ filter, value, onToggleOpen, filterPortalId }: IFactoryProps): JSX.Element {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [wantedValues, setWantedValues] = useState<IProductListUserFilterValue>(undefined)
    const history = useHistory()
    const location = useLocation()
    // const { queries } = useProductListParsedQuery()

    const isEmpty = useMemo(() => {
        return typeof wantedValues === 'undefined'
    }, [wantedValues])

    const options = useMemo(() => {
        if (!filter.translatable) {
            return filter.options
        }
        let values = [...filter.options]
        values = values.map((value) => {
            return {
                ...value,
                label: formatMessage({ id: `product_filter.${filter.code}.options.${value.id}` }),
            }
        })
        return values
    }, [filter.code, filter.options, filter.translatable, formatMessage])

    const headingSubTitle = useMemo(() => {
        if (!options || !value) {
            return undefined
        }
        const k = Object.keys(value)[0]
        const v = Object.values(value)[0]
        const identifier = `${k}_${v}`
        const labels = options.filter((option) => option.id === identifier).map((option) => option.label)
        return labels.join(', ')
    }, [options, value])

    const handleHeadingClick = useCallback(() => {
        setOpen((state) => !state)
    }, [setOpen, filter])

    const handleCancelClick = useCallback(() => {
        setOpen(false)
        if (!value || Object.keys(value).length === 0) {
            setWantedValues(undefined)
        } else {
            const k = Object.keys(value)[0]
            const v = Object.values(value)[0]
            setWantedValues([`${k}_${v}`])
        }
    }, [setOpen, setWantedValues, value])

    const handleOnChange = useCallback(
        (option: IProductListFilterOption, selected: boolean) => {
            let qstring = ''
            const parsed = Qs.parse(location.search.substring(1))
            if (!selected) {
                setWantedValues(undefined)
                if (filter.autoValidate) {
                    delete parsed[filter.code]
                    // delete parsed[ProductListQueryName.Page]
                    const stringified = Qs.stringify(parsed)
                    qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
                }
            } else {
                setWantedValues([option.id])
                if (filter.autoValidate) {
                    const [field, position] = String(option.id).split('_')
                    parsed[filter.code] = { [field]: position }
                    // delete parsed[ProductListQueryName.Page]
                    const stringified = Qs.stringify(parsed)
                    qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
                }
            }

            setOpen(false)
            if (filter.autoValidate) {
                const baseUrl = rtrim(location.pathname, '/')
                const finalUrl = `${baseUrl}${qstring}`
                if (finalUrl !== `${location.pathname}${location.search}`) {
                    history.push(`${baseUrl}${qstring}`)
                }
            }
        },
        [filter, wantedValues, setWantedValues, setOpen, history, location]
    )

    const handleOutsideClick = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const Content = open ? (
        <div className={'content'}>
            <div className={'values'}>
                {options.map((option) => (
                    <Entry
                        code={filter.code}
                        key={option.id}
                        option={option}
                        selected={
                            typeof wantedValues === 'object' &&
                            Object.values(wantedValues).indexOf(String(option.id)) > -1
                        }
                        multiple={filter.multiple}
                        onChange={handleOnChange}
                    />
                ))}
            </div>
        </div>
    ) : (
        <></>
    )

    const Component =
        filterPortalId && document.getElementById(filterPortalId) && open
            ? ReactDOM.createPortal(
                  <div
                      className={classNames(
                          'product-filter',
                          `product-filter-${filter.code}`,
                          `product-filter-type-${filter.mode}`,
                          { open, empty: isEmpty }
                      )}
                  >
                      <Heading filter={filter} subTitle={headingSubTitle} onClick={handleCancelClick} />
                      {Content}
                  </div>,
                  document.getElementById(filterPortalId!)!
              )
            : Content

    useEffect(() => {
        if (!value || Object.keys(value).length === 0) {
            setWantedValues(undefined)
        } else {
            const k = Object.keys(value)[0]
            const v = Object.values(value)[0]
            setWantedValues([`${k}_${v}`])
        }
    }, [value, setWantedValues])

    useEffect(() => {
        if (onToggleOpen) {
            onToggleOpen(filter, open)
        }
    }, [filter, open, onToggleOpen])

    return (
        <div
            className={classNames(
                'product-filter',
                `product-filter-${filter.code}`,
                `product-filter-type-${filter.mode}`,
                { open, empty: isEmpty }
            )}
        >
            {!filterPortalId && (
                <OutsideClickHandler onOutsideClick={handleOutsideClick} disabled={!open}>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={undefined}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </OutsideClickHandler>
            )}
            {filterPortalId && (
                <>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={undefined}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </>
            )}
        </div>
    )
}

export default memo(TypeOrder)
