import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the search state domain
 */

const selectSearchDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.search || initialState
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Search
 */

const makeSelectSearch = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate
    })

const makeSelectSearchSuggestionFetching = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.fetching
    })

const makeSelectSearchSuggestionParams = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.params
    })

const makeSelectSearchSuggestionError = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.error
    })

const makeSelectSearchSuggestionFamilies = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.families
    })

const makeSelectSearchSuggestionProducts = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.products
    })

const makeSelectSearchSuggestionCancelToken = () =>
    createSelector(selectSearchDomain, (substate) => {
        return substate.cancelToken
    })

export default makeSelectSearch
export {
    makeSelectSearchSuggestionFetching,
    makeSelectSearchSuggestionParams,
    makeSelectSearchSuggestionError,
    makeSelectSearchSuggestionFamilies,
    makeSelectSearchSuggestionProducts,
    makeSelectSearchSuggestionCancelToken,
}
