/*
 *
 * Invoices reducer
 *
 */

import ActionTypes from './constants'
import { ContainerState, ContainerActions } from './types'

export const initialListState = {
    fetching: false,
}

export const initialDocumentState = {}

export const initialState: ContainerState = {
    list: initialListState,
    document: initialDocumentState,
}

function invoicesReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LIST_SUCCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        case ActionTypes.DOCUMENT_PROCESS_ACTION: {
            return {
                ...state,
                document: {
                    ...state.document,
                    [action.payload.invoiceId]: {
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_FAILURE_ACTION: {
            return {
                ...state,
                document: {
                    ...state.document,
                    [action.payload.invoiceId]: {
                        ...state.document[action.payload.invoiceId],
                        tms: Date.now(),
                        fetching: false,
                        error: action.payload.error,
                        link: undefined,
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_RESET_ACTION: {
            return {
                ...state,
                document: initialDocumentState,
            }
        }
        case ActionTypes.DOCUMENT_RESET_SINGLE_ACTION: {
            return {
                ...state,
                document: {
                    ...state.document,
                    [action.payload.invoiceId]: {
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.DOCUMENT_SUCCESS_ACTION: {
            return {
                ...state,
                document: {
                    ...state.document,
                    [action.payload.invoiceId]: {
                        ...state.document[action.payload.invoiceId],
                        tms: Date.now(),
                        fetching: false,
                        link: action.payload.response.data,
                    },
                },
            }
        }
        default:
            return state
    }
}

export default invoicesReducer
