import React, { useEffect, useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { generatePath, useLocation } from 'react-router-dom'
import Breadcrumb from '../containers/Breadcrumb/Breadcrumb'
import Carts from '../containers/Carts/Carts'
import { getPath } from '../routes'
import { cartsBannerHideAction } from '../store/carts/actions'

export default function CartsPage(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(cartsBannerHideAction())
    })

    let defaultActiveKey: string | undefined = undefined
    if (location && location.hash && location.hash.length > 0) {
        const idx = parseInt(location.hash.substring(1))
        if (!isNaN(idx)) {
            defaultActiveKey = String(idx)
        }
    }

    const items = useMemo(() => {
        return [
            {
                label: 'page.cart',
                href: generatePath(getPath('cart', locale), { lang: locale }),
            },
        ]
    }, [locale])

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.cart.title' })}</title>
                <meta name="description" content={formatMessage({ id: 'seo.cart.description' })} />
            </Helmet>
            <Breadcrumb items={items} />
            <Container className={'container-boxed'} fluid>
                <Carts defaultActiveKey={defaultActiveKey} />
            </Container>
        </>
    )
}
