import { AxiosInstance } from 'axios'
import { IApiCountryListResponse, ILocalizationService } from './types'

const create = (instance: AxiosInstance): ILocalizationService => {
    /*****************************
     * METHODS
     *****************************/
    const getCountries = (): Promise<IApiCountryListResponse> => {
        return instance.get('/countries')
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        getCountries,
    }
}

export default {
    create,
}
