/*
 *
 * Maintenance
 *
 */

import React, { memo } from 'react'

interface IProps {}

function Maintenance(): JSX.Element {
    return <div></div>
}

Maintenance.defaultProps = {} as Partial<IProps>

export default memo(Maintenance)
