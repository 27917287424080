/*
 *
 * Invoices constants
 *
 */

enum ActionTypes {
    LIST_PROCESS_ACTION = 'app/Invoices/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/Invoices/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/Invoices/List/REQUEST_FAILURE',
    DOCUMENT_PROCESS_ACTION = 'app/Invoices/Pdf/REQUEST_PROCESS',
    DOCUMENT_SUCCESS_ACTION = 'app/Invoices/Pdf/REQUEST_SUCCESS',
    DOCUMENT_FAILURE_ACTION = 'app/Invoices/Pdf/REQUEST_FAILURE',
    DOCUMENT_RESET_ACTION = 'app/Invoices/Pdf/RESET',
    DOCUMENT_RESET_SINGLE_ACTION = 'app/Invoices/Pdf/RESET_SINGLE',
    RESET_ACTION = 'app/Invoices/RESET',
}

export default ActionTypes
