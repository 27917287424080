/*
 *
 * Products constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Products/RESET',
    PERSIST_PARAMS_ACTION = 'app/Products/Params/PERSIST',
    PERSISTED_PARAMS_ACTION = 'app/Products/Params/PERSISTED',
    LIST_REFRESH_ACTION = 'app/Products/List/REFRESH',
    LIST_PROCESS_ACTION = 'app/Products/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/Products/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/Products/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/Products/List/RESET',
    // LIST_CHANGE_DISPLAY_MODE = 'app/Products/List/DisplayMode/CHANGE',
    SEARCH_PROCESS_ACTION = 'app/Products/Search/REQUEST_PROCESS',
    SEARCH_SUCCESS_ACTION = 'app/Products/Search/REQUEST_SUCCESS',
    SEARCH_FAILURE_ACTION = 'app/Products/Search/REQUEST_FAILURE',
    SEARCH_RESET_ACTION = 'app/Products/Search/RESET',
    LIST_FAVORITE_PRODUCT_ACTION = 'app/Products/List/PRODUCT_FAVORITE',

    LIST_SELECTION_SET_ACTION = 'app/Products/List/Selection/SET_ACTION',
    LIST_SELECTION_APPLY_ACTION_PROCESS = 'app/Products/List/Selection/APPLY_ACTION/PROCESS',
    LIST_SELECTION_APPLY_ACTION_SUCCESS = 'app/Products/List/Selection/APPLY_ACTION/SUCCESS',
    LIST_SELECTION_APPLY_ACTION_FAILURE = 'app/Products/List/Selection/APPLY_ACTION/FAILURE',
    LIST_SELECTION_ADD_ACTION = 'app/Products/List/Selection/ADD',
    LIST_SELECTION_ADD_BULK_ACTION = 'app/Products/List/Selection/ADD/BULK',
    LIST_SELECTION_REMOVE_ACTION = 'app/Products/List/Selection/REMOVE',
    LIST_SELECTION_REMOVE_BULK_ACTION = 'app/Products/List/Selection/REMOVE/BULK',
    LIST_SELECTION_RESET_ACTION = 'app/Products/List/Selection/RESET',
    LIST_SELECTION_RESET_PROCESS_ACTION = 'app/Products/List/Selection/RESET_PROCESS',

    BULK_REMOVE_FAVORITE_PROCESS_ACTION = 'app/Products/FAVORITE/BULK_REMOVE/PROCESS',
    BULK_REMOVE_FAVORITE_SUCCESS_ACTION = 'app/Products/FAVORITE/BULK_REMOVE/SUCCESS',
    BULK_REMOVE_FAVORITE_FAILURE_ACTION = 'app/Products/FAVORITE/BULK_REMOVE/FAILURE',
    BULK_REMOVE_FAVORITE_RESET_ACTION = 'app/Products/FAVORITE/BULK_REMOVE/RESET',

    BULK_ADD_FAVORITE_PROCESS_ACTION = 'app/Products/FAVORITE/BULK_ADD/PROCESS',
    BULK_ADD_FAVORITE_SUCCESS_ACTION = 'app/Products/FAVORITE/BULK_ADD/SUCCESS',
    BULK_ADD_FAVORITE_FAILURE_ACTION = 'app/Products/FAVORITE/BULK_ADD/FAILURE',
    BULK_ADD_FAVORITE_RESET_ACTION = 'app/Products/FAVORITE/BULK_ADD/RESET',

    LIST_PRODUCT_GRID_PROCESS_ACTION = 'app/Products/List/Grids/PROCESS',
    LIST_PRODUCT_GRID_SUCCESS_ACTION = 'app/Products/List/Grids/SUCCESS',
    LIST_PRODUCT_GRID_FAILURE_ACTION = 'app/Products/List/Grids/FAILURE',
    LIST_PRODUCT_GRID_SELECT_DEFINITION_ACTION = 'app/Products/List/Grids/SELECT_DEFINITION',
    LIST_PRODUCT_GRID_RESET_DEFINITION_ACTION = 'app/Products/List/Grids/RESET_DEFINITION',

    LIST_SIDEBAR_CHANGE_MODE = 'app/Products/List/Sidebar/CHANGE_MODE',
}

export default ActionTypes
