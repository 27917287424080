import React from 'react'
import { useParams } from 'react-router-dom'
import OrderDetail from '../containers/OrderDetail/OrderDetail'
import { IOrderType } from '../services/api/service/orders/types'

type Props = { type: IOrderType }
export default function SalesmanOrderPage({ type }: Props): JSX.Element {
    const { orderId } = useParams<{ orderId: string }>()

    return (
        <>
            <OrderDetail orderId={orderId} orderType={type} />
        </>
    )
}
