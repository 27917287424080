/**
 *
 * CopyrightImage
 *
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

function CopyrightImage(): JSX.Element {
    return (
        <div className={'copyright'}>
            <p>
                <FormattedMessage id={'default.copyright_image'} />
                <br />
                <FormattedMessage id={'default.copyright'} values={{ year: new Date().getFullYear() }} />
            </p>
        </div>
    )
}

export default CopyrightImage
