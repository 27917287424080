/**
 *
 * AppError
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { FlashMessage } from 'redux-flash-messages/lib'
import Gears from '../Gears/Gears'

interface IProps {
    messages: Array<FlashMessage>
}

function AppError({ messages }: IProps): JSX.Element {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(true)
    const isFatalError = Object.values(messages).reduce(
        (sum, next) => sum || next.type.toString().toLowerCase() === 'apocalypse',
        false
    )

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.fatal.title' })}</title>
            </Helmet>
            <div className={classNames('app-error', 'error-section', { loading })}>
                <div className={'app-error-head'}>
                    <h2>
                        <FontAwesomeIcon icon={'exclamation-triangle'} className={'app-icon'} />
                        <FormattedMessage id={'default.error_occurred'} />
                    </h2>
                    {messages.map((message) => (
                        <p
                            className={classNames('mb-0', `app-error-message-${message.type.toString().toLowerCase()}`)}
                            key={message.id}
                        >
                            {message.text}
                        </p>
                    ))}
                    {isFatalError ? (
                        <Button variant={'primary'} onClick={() => window.location.reload()}>
                            <FormattedMessage id={'default.go_back'} />
                        </Button>
                    ) : (
                        <Button variant={'primary'} onClick={() => history.goBack()}>
                            <FormattedMessage id={'default.go_back'} />
                        </Button>
                    )}
                </div>
                <Gears />
            </div>
        </>
    )
}

AppError.defaultProps = {
    messages: [],
} as Partial<IProps>

export default memo(AppError)
