import { IPaginationQueryParameters, PaginationQueryName } from '../types/pagination'
import Qs from 'qs'
import Config from '../config'

export function extractPaginationQueryParameters(): IPaginationQueryParameters {
    const parsed = Qs.parse(window.location.search.substring(1))

    const search =
        typeof parsed[PaginationQueryName.Search] === 'string'
            ? (parsed[PaginationQueryName.Search] as string)
            : undefined

    const itemsPerPage =
        typeof parsed[PaginationQueryName.ItemsPerPage] === 'string'
            ? parseInt(parsed[PaginationQueryName.ItemsPerPage] as string)
            : Config.LIST.ITEMS_PER_PAGE

    const page =
        typeof parsed[PaginationQueryName.Page] === 'string' ? parseInt(parsed[PaginationQueryName.Page] as string) : 1

    return {
        page,
        itemsPerPage,
        search,
    }
}
