import { IApiResource, IBasePaginationParameters, ICollectionApiResponse, IPaginationApiResponse } from '../../types'
import {
    ClassificationType,
    IBaseClassificationFamily,
    IDepartment,
    IFamily,
    IShippingLocation,
    ISubFamily,
} from '../classification/types'
import { $PropertyType } from 'utility-types'
import { AxiosResponse } from 'axios'
import { IProductList } from '../products/types'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

/*******************************
 * STATISTICS
 * ****************************/
// ATTENTION: si un filtre est ajouté il faut également l'ajouter dans le fichier :
// containers/MemberStatistics/Partial/TopProducts/Partial/Table
export type IMeStatisticsParameters = IBasePaginationParameters & {
    readonly from: string
    readonly to: string
    readonly all?: 1 | 0
    readonly departments?: Array<$PropertyType<IDepartment, '@id'>>
    readonly families?: Array<$PropertyType<IFamily, '@id'>>
    readonly sub_families?: Array<$PropertyType<ISubFamily, '@id'>>
}

export interface IMeYearlyStatEntry extends IApiResource {
    readonly year: number
    readonly total_amount: number
}
export type IMeYearlyStatEntries = Array<IMeYearlyStatEntry>
export type IApiMeYearlyStatsResponse = AxiosResponse<ICollectionApiResponse<IMeYearlyStatEntries>>

export interface IMeTopSalesEntry extends IApiResource {
    readonly month: string
    readonly previous_period_month: string
    readonly previous_period_total: number
    readonly total: number
}
export type IMemberTopSalesEntries = Array<IMeTopSalesEntry>

export type IMeStatisticsTopSalesParameters = Partial<IMeStatisticsParameters>
export type IApiMeTopSalesResponse = AxiosResponse<ICollectionApiResponse<IMemberTopSalesEntries>>

export type IMeStatisticsTopProductsParameters = Partial<Omit<IMeStatisticsParameters, 'order'>> & {
    readonly order?: string
    readonly order_direction?: string
}

export type IApiMeTopProductsEntry = Pick<
    IProductList,
    | '@id'
    | '@type'
    | 'id'
    | 'ean13'
    | 'packing'
    | 'unit_of_sale'
    | 'reference'
    | 'name'
    | 'shipping_location'
    | 'main_pictures'
> & {
    readonly unit_of_sale: string
    readonly ordered_quantity: number
    readonly ordered_amount: number
    readonly rank: number
}

export type IStatisticsDepartment = Omit<IDepartment, 'slug' | 'url' | 'parent_ids'>
export type IStatisticsShippingLocationTreeItemType = IStatisticsDepartment | IStatisticsShippingLocation
export type IStatisticsShippingLocation = Omit<IShippingLocation, 'image' | 'best_sellers' | 'tree'> & {
    tree?: Array<IStatisticsShippingLocationTreeItemType>
}

export type IStatisticsFamilyTreeCollection = Array<IStatisticsShippingLocation>
export type IStatisticsClassificationTypes = IStatisticsShippingLocation | IStatisticsDepartment | IFamily | ISubFamily

export function isStatisticsClassificationFamily(obj: any): obj is IBaseClassificationFamily {
    return isObject(obj) && isString(obj['@type']) && obj['@type'] === ClassificationType.Family
}

export function isStatisticsShippingLocation(obj: any): obj is IStatisticsShippingLocation {
    return isObject(obj) && isString(obj['@type']) && obj['@type'] === ClassificationType.ShippingLocation
}

export type IApiMeTopProductsEntries = Array<IApiMeTopProductsEntry>

export type IApiMeTopProductsPagination = IPaginationApiResponse<IApiMeTopProductsEntries>
export type IApiMeTopProductsResponse = AxiosResponse<IApiMeTopProductsPagination>

export type IMeClassificationParameters = Omit<IMeStatisticsParameters, 'classification'>
export type IMeClassificationResponse = ICollectionApiResponse<IStatisticsFamilyTreeCollection>
export type IApiMeClassificationResponse = AxiosResponse<IMeClassificationResponse>

export interface IStatisticsService {
    familiesTree(params: IMeClassificationParameters): Promise<IApiMeClassificationResponse>
    topSales(params: IMeStatisticsTopSalesParameters): Promise<IApiMeTopSalesResponse>
    topProducts(params: IMeStatisticsTopProductsParameters): Promise<IApiMeTopProductsResponse>
    yearlyStats(): Promise<IApiMeYearlyStatsResponse>
}
