import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import CustomerImport from '../containers/CustomerImport/CustomerImport'

export default function CustomerImportPage(): JSX.Element {
    const { formatMessage } = useIntl()
    const { id } = useParams<{ id: string }>()
    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.import.title' })}</title>
            </Helmet>
            <CustomerImport itemId={id} />
        </>
    )
}
