import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the cms state domain
 */

const selectStatisticsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.statistics || initialState
}

const makeSelectStatisticsClassificationFamilyTree = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.classification?.tree
    })

const makeSelectStatisticsClassificationFamilyTreeFetching = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.classification?.fetching || false
    })

const makeSelectStatisticsClassificationFamilyTreeFilters = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.classification?.filters
    })

const makeSelectStatisticsClassificationFamilyTreeError = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.classification?.error
    })

const makeSelectStatisticsTopSalesData = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_sales?.data
    })

const makeSelectStatisticsTopSalesFetching = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_sales?.fetching
    })

const makeSelectStatisticsTopSalesError = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_sales?.error
    })

const makeSelectStatisticsTopProductsFetching = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_products?.fetching
    })

const makeSelectStatisticsTopProductsError = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_products?.error
    })

const makeSelectStatisticsTopProductsItems = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_products?.items
    })

const makeSelectStatisticsTopProductsTotalItems = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.top_products?.totalItems
    })

const makeSelectYearlyStatisticsData = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.yearly_stats?.data
    })

const makeSelectYearlyStatisticsFetching = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.yearly_stats?.fetching
    })

const makeSelectYearlyStatisticsError = () =>
    createSelector(selectStatisticsDomain, (substate) => {
        return substate.yearly_stats?.error
    })

export default selectStatisticsDomain
export {
    makeSelectStatisticsClassificationFamilyTreeFetching,
    makeSelectStatisticsClassificationFamilyTree,
    makeSelectStatisticsClassificationFamilyTreeFilters,
    makeSelectStatisticsClassificationFamilyTreeError,
    makeSelectStatisticsTopSalesData,
    makeSelectStatisticsTopSalesFetching,
    makeSelectStatisticsTopSalesError,
    makeSelectStatisticsTopProductsFetching,
    makeSelectStatisticsTopProductsError,
    makeSelectStatisticsTopProductsItems,
    makeSelectStatisticsTopProductsTotalItems,
    makeSelectYearlyStatisticsData,
    makeSelectYearlyStatisticsError,
    makeSelectYearlyStatisticsFetching,
}
