/*
 *
 * ViewedProducts
 *
 */

import React, { useCallback } from 'react'
import { InView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { ScrollTo } from 'scroll-to-position'
import Carousel from '../../components/Product/Carousel/Carousel'
import { routes } from '../../routes'
import { IProductList } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { customerViewedProductsProcessAction } from '../../store/customers/actions'
import {
    makeSelectCustomerViewedProductsInvalidated,
    makeSelectCustomerViewedProductsItems,
    makeSelectCustomerViewedProductsItemsFetching,
} from '../../store/customers/selectors'
import { IBreakpointMap } from '../../types/common'
import { generateProductUrl } from '../../utils/productHelper'

interface IProps {}

const THRESHOLD = [0.5]
const stateSelector = createStructuredSelector<any, any>({
    items: makeSelectCustomerViewedProductsItems(),
    fetching: makeSelectCustomerViewedProductsItemsFetching(),
    invalidated: makeSelectCustomerViewedProductsInvalidated(),
})

function ViewedProducts(): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const location = useLocation()
    let productId: string | undefined = undefined
    const matchPath2 = matchPath<{ productId?: string }>(location.pathname, {
        path: routes.product.match,
    })
    if (matchPath2 && matchPath2.isExact && matchPath2.params.productId) {
        productId = matchPath2.params.productId
    } else {
        productId = undefined
    }

    const breakpoints: IBreakpointMap = {
        // when window width is >= 480px
        720: {
            slidesPerView: 3,
            spaceBetween: 15,
        },
        // when window width is >= 640px
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        // when window width is >= 640px
        1400: {
            slidesPerView: 5,
        },
    }
    const { invalidated, fetching, items } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            invalidated: boolean
            items: Array<IProductList>
        }
    >(stateSelector)
    const handleOnChange = useCallback(
        (inView: boolean) => {
            if (inView && invalidated && !fetching) {
                dispatch(customerViewedProductsProcessAction())
            }
        },
        [invalidated, fetching, dispatch]
    )

    const handleProductClick = useCallback(
        (product: IProductList) => {
            const finalUrl = generateProductUrl(product.id, locale)
            if (!productId) {
                history.push(finalUrl, { background: location })
            } else {
                history.replace(finalUrl)
                ScrollTo(document.body, {
                    duration: [200, 1500],
                })
            }
        },
        [history, location, productId, locale]
    )

    return (
        <InView onChange={handleOnChange} threshold={THRESHOLD} triggerOnce>
            {({ ref }) => (
                <div ref={ref}>
                    <Carousel
                        breakpoints={breakpoints}
                        autoplay={false}
                        products={items}
                        title={formatMessage({ id: 'products.last_products_viewed' })}
                        onProductClick={handleProductClick}
                    />
                </div>
            )}
        </InView>
    )
}

ViewedProducts.defaultProps = {} as Partial<IProps>

export default ViewedProducts
