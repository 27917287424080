import { IAppError, IAppErrorCollection, IAppErrorTypes } from './types'

export default class AppError extends Error implements IAppError {
    public status: number
    public errors: IAppErrorCollection | undefined
    public previous: IAppErrorTypes | undefined
    isAppError = true

    constructor(m: string, status: number, errors?: IAppErrorCollection, previous?: IAppErrorTypes) {
        super(m)
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AppError.prototype)
        this.name = 'AppError'
        this.message = m
        // TODO : voir comment "merger" les stack
        // FINIR LA CLASSE ET L'UTILISER PARTOUT DANS LES STORES
        this.stack = new Error().stack
        this.status = status
        this.errors = errors
        this.previous = previous

        return this
    }
}
