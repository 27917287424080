import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import {
    cartsCartsState,
    cartsCleanState,
    cartsItemsState,
    cartsVisibilityState,
    cartsInitialBulkState,
    initialState,
    cartsDuplicateState,
    cartsLockedState,
    cartsLockState,
} from './reducer'
import { findCartBy, findCartItemByProductId } from './utils'
import { ICart, ICartCollection, ICartMode, ICartValidationMode } from '../../services/api/service/carts/types'

/**
 * Direct selector to the carts state domain
 */

const selectCartsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts || initialState
}
const selectCartListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.list || []
}
const selectCartLightDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.light || {}
}
const selectMultiStoreCartDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.multi_store.store_cart_list || {}
}

const selectSalesmanCartListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.salesman || {}
}

const selectCartMainDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.main || []
}

const selectCartItemDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.items || cartsItemsState
}

const selectCartLockedDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.locked || cartsLockedState
}

const selectCartLockDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.lock || cartsLockState
}

const selectCartItemStoreQuantitiesDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.items.store_quantities || {}
}

const selectCartItemFetchingDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.carts.items.fetching || {}
}

const selectCartsVisibilityDomain = (state: IApplicationRootState) => {
    return state.carts.visibility || cartsVisibilityState
}
const selectCartOrdersDomain = (state: IApplicationRootState) => {
    return state.carts.orders || cartsCartsState
}
const selectCartDuplicateDomain = (state: IApplicationRootState) => {
    return state.carts.duplicate || cartsDuplicateState
}
const selectCartsCleanDomain = (state: IApplicationRootState) => {
    return state.carts.clean || cartsCleanState
}
const selectCartsBulkDomain = (state: IApplicationRootState) => {
    return state.carts.bulk || cartsInitialBulkState
}
const selectCartsSettingsDomain = (state: IApplicationRootState) => {
    return state.carts.settings || undefined
}

/**
 * Other specific selectors
 */

const makeSelectCartItemByProductId = () =>
    createSelector(
        [selectCartsDomain, (_: any, cartItemId: string) => cartItemId, (_: any, _2: any, main?: boolean) => main],
        (state, cartItemId, main) => {
            if (!state.list && !cartItemId) {
                return undefined
            }
            // récupération des paniers elligibles
            const carts = state.list.filter(
                (item: ICart) =>
                    (main && state.main.indexOf(item['@id']) > -1) || (!main && state.main.indexOf(item['@id']) === -1)
            )
            return findCartItemByProductId(carts, cartItemId)
        }
    )

/**
 * Default selector used by Carts
 */

const makeSelectQuantityByProductId = () =>
    createSelector(
        [
            selectCartItemDomain,
            (_: any, productId: string | null) => productId,
            (_: any, _2: any, main?: boolean) => main,
        ],
        (items, productId, main) => {
            if (!items || !items.main || !items.sub || !productId) {
                return undefined
            }
            const quantities = main ? items.main.quantities : items.sub.quantities
            for (const k in quantities) {
                if (typeof quantities[k][productId] === 'number') {
                    return quantities[k][productId]
                }
            }
            return 0
        }
    )

const makeSelectErrorsByProductId = () =>
    createSelector(
        [
            selectCartItemDomain,
            (_: any, productId: string | null | undefined) => productId,
            (_: any, _2: any, main?: boolean) => main,
        ],
        (items, productId, main) => {
            if (!items || !items.main || !items.sub || !productId) {
                return undefined
            }
            const errors = main ? items.main.errors : items.sub.errors
            if (errors[productId]) {
                return errors[productId]
            }
            return []
        }
    )

const makeSelectLockByShippingLocationId = () =>
    createSelector(
        [selectCartLockedDomain, (_: any, shippingLocationId: string | null | undefined) => shippingLocationId],
        (locks, shippingLocationId) => {
            if (
                !locks ||
                !locks.shipping_location ||
                !shippingLocationId ||
                typeof locks.shipping_location[shippingLocationId] === 'undefined'
            ) {
                return false
            }
            return locks.shipping_location[shippingLocationId]
        }
    )

const makeSelectLockedCartIds = () =>
    createSelector([selectCartLockedDomain], (locks) => {
        if (!locks || !locks.cart) {
            return []
        }

        const ids: Array<string> = []
        for (const cartId in locks.cart) {
            if (locks.cart[cartId]) {
                ids.push(cartId)
            }
        }
        return ids
    })

const makeSelectCartsLockerFetching = () =>
    createSelector(selectCartLockDomain, (substate) => {
        return substate.fetching
    })

const makeSelectCartsLockerSuccess = () =>
    createSelector(selectCartLockDomain, (substate) => {
        return substate.success
    })

const makeSelectCartsLockerErrors = () =>
    createSelector(selectCartLockDomain, (substate) => {
        return substate.errors
    })

const makeSelectCanIncrementQuantityByProductId = () =>
    createSelector(
        [
            selectCartItemDomain,
            (_: any, productId: string | null) => productId,
            (_: any, _2: any, main?: boolean) => main,
        ],
        (items, productId, main) => {
            if (!items || !items.main || !items.sub || !productId) {
                return true
            }

            const quantities = main ? items.main.canIncrementQuantity : items.sub.canIncrementQuantity
            for (const k in quantities) {
                if (typeof quantities[k][productId] === 'boolean') {
                    return quantities[k][productId]
                }
            }
            return true
        }
    )

const makeSelectCartsCreateOrdersFetching = () =>
    createSelector(selectCartOrdersDomain, (substate) => {
        return substate.create.fetching
    })

const makeSelectCartsCreateOrdersData = () =>
    createSelector(selectCartOrdersDomain, (substate) => {
        return substate.create.data
    })

const makeSelectCartsCreateOrdersErrors = () =>
    createSelector(selectCartOrdersDomain, (substate) => {
        return substate.create.errors
    })

const makeSelectCartsCreateOrdersItems = () =>
    createSelector(selectCartOrdersDomain, (substate) => {
        return substate.create.items
    })

const makeSelectCartsReOrderFetching = () =>
    createSelector([selectCartOrdersDomain, (_: any, orderId: string) => orderId], (substate, orderId) => {
        return substate.reorder.fetching[orderId] || false
    })

const makeSelectCartsReOrderData = () =>
    createSelector([selectCartOrdersDomain, (_: any, orderId: string) => orderId], (substate, orderId) => {
        return substate.reorder.data[orderId] || undefined
    })

const makeSelectCartsReOrderErrors = () =>
    createSelector([selectCartOrdersDomain, (_: any, orderId: string) => orderId], (substate, orderId) => {
        return substate.reorder.errors[orderId] || undefined
    })

const makeSelectCartsReOrderWarnings = () =>
    createSelector([selectCartOrdersDomain, (_: any, orderId: string) => orderId], (substate, orderId) => {
        return substate.reorder.warnings[orderId] || undefined
    })

const makeSelectCartsReOrderSuccess = () =>
    createSelector([selectCartOrdersDomain, (_: any, orderId: string) => orderId], (substate, orderId) => {
        return substate.reorder.success[orderId] || false
    })

const makeSelectCartsDuplicateFetching = () =>
    createSelector(selectCartDuplicateDomain, (substate) => {
        return substate.fetching
    })

const makeSelectCartsDuplicateData = () =>
    createSelector(selectCartDuplicateDomain, (substate) => {
        return substate.data
    })

const makeSelectCartsDuplicateErrors = () =>
    createSelector(selectCartDuplicateDomain, (substate) => {
        return substate.errors
    })

const makeSelectCartsDuplicateWarnings = () =>
    createSelector(selectCartDuplicateDomain, (substate) => {
        return substate.warnings
    })

const makeSelectCartsDuplicateSuccess = () =>
    createSelector(selectCartDuplicateDomain, (substate) => {
        return substate.success
    })

const makeSelectFetching = () =>
    createSelector([selectCartItemFetchingDomain], (fetching) => {
        return fetching
    })

const makeSelectFetchingByProductId = () =>
    createSelector(
        [selectCartItemFetchingDomain, (_: any, productId: string | null) => productId],
        (fetching, productId) => {
            if (!fetching || !productId) {
                return null
            }
            return fetching[productId] || false
        }
    )

const makeSelectCarts = () =>
    createSelector(selectCartsDomain, (substate) => {
        return substate
    })

const makeSelectCartByShippingLocationId = () =>
    createSelector(
        [
            selectCartListDomain,
            selectCartMainDomain,
            (_: any, shippingLocationId: string | null) => shippingLocationId,
            (_2: any, _3: any, main?: boolean) => main,
        ],
        (list, mainIds, shippingLocationId, main) => {
            if (!list || !shippingLocationId || !mainIds) {
                return null
            }
            // récupération des paniers elligbles
            const carts: ICartCollection = []
            for (const idx in list) {
                const cart: ICart = list[idx]
                if ((main && mainIds.indexOf(cart['@id']) > -1) || (!main && mainIds.indexOf(cart['@id']) === -1)) {
                    carts.push(cart)
                }
            }
            return findCartBy(carts, 'shipping_location', shippingLocationId)
        }
    )

const makeSelectCartById = () =>
    createSelector([selectCartsDomain, (_: any, cartId: string | null | undefined) => cartId], (state, cartId) => {
        if (!state.list || !cartId) {
            return null
        }
        return findCartBy(state.list, '@id', cartId)
    })

const makeSelectCartsList = () =>
    createSelector(selectCartListDomain, (substate) => {
        return substate
    })

const makeSelectMainCartsList = () =>
    createSelector([selectCartMainDomain, selectCartListDomain], (main, list) => {
        const carts: ICartCollection = []
        if (!list || list.length === 0 || !main || main.length === 0) {
            return carts
        }
        for (const idx in list) {
            const cart: ICart = list[idx]
            if (main.indexOf(cart['@id']) > -1) {
                carts.push(cart)
            }
        }
        return carts
    })

const makeSelectChildCartsList = () =>
    createSelector([selectCartMainDomain, selectCartListDomain], (main, list) => {
        const carts: ICartCollection = []
        if (!list || list.length === 0 || !main || main.length === 0) {
            return carts
        }
        for (const idx in list) {
            const cart: ICart = list[idx]
            if (main.indexOf(cart['@id']) === -1) {
                carts.push(cart)
            }
        }
        return carts
    })

const makeSelectMainCartsListIsEmpty = () =>
    createSelector([selectCartMainDomain, selectCartListDomain], (main, list) => {
        if (!list || list.length === 0 || !main || main.length === 0) {
            return true
        }
        const carts: ICartCollection = []
        for (const idx in list) {
            const cart: ICart = list[idx]
            if (main.indexOf(cart['@id']) > -1) {
                carts.push(cart)
            }
        }
        return carts.reduce(
            (sum: boolean, next: ICart) =>
                sum &&
                typeof next === 'object' &&
                typeof next.items !== 'undefined' &&
                Object.values(next.items).length === 0,
            true
        )
    })

const makeSelectMainCartQuantities = () =>
    createSelector(selectCartItemDomain, (substate) => {
        return substate.main.quantities
    })

// eslint-disable-next-line unused-imports/no-unused-vars,no-unused-vars,@typescript-eslint/no-unused-vars
const makeSelectMainCartErrors = () =>
    createSelector(selectCartItemDomain, (substate) => {
        return substate.main.errors
    })

const makeSelectSubCartQuantities = () =>
    createSelector(selectCartItemDomain, (substate) => {
        return substate.sub.quantities
    })

const makeSelectCartFetching = () =>
    createSelector(selectCartItemFetchingDomain, (substate) => {
        return substate
    })

const makeSelectBannerVisibility = () =>
    createSelector(selectCartsVisibilityDomain, (substate) => {
        return substate.banner
    })

const makeSelectCartItemStoreQuantitiesDomain = () =>
    createSelector(selectCartItemStoreQuantitiesDomain, (substate) => {
        return substate
    })

const makeSelectCartsCleanFetching = () =>
    createSelector([selectCartsCleanDomain], (substate) => {
        return substate.fetching
    })

const makeSelectCartCleanFetching = () =>
    createSelector([selectCartsCleanDomain, (_: any, cartId: string) => cartId], (state, cartId) => {
        return state.fetching[cartId] || false
    })

const makeSelectCartLightDomain = () =>
    createSelector(selectCartLightDomain, (substate) => {
        return substate
    })

const makeSelectMultiStoreCartDomain = () =>
    createSelector(selectMultiStoreCartDomain, (substate) => {
        return substate
    })

const makeSelectCartLightByParentId = () =>
    createSelector([selectCartsCleanDomain, (_: any, cartId: string) => cartId], (state, cartId) => {
        return state[cartId] || { fetching: false }
    })

const makeSelectCartBulkCartQuantityFetching = () =>
    createSelector(selectCartsBulkDomain, (substate) => {
        return substate.fetching
    })

const makeSelectCartBulkCartQuantityError = () =>
    createSelector(selectCartsBulkDomain, (substate) => {
        return substate.error
    })

// const makeSelectCartBulkCartQuantityItems = () =>
//     createSelector(selectCartsBulkDomain, (substate) => {
//         return substate.items
//     })

const makeSelectCartBulkCartQuantitySuccess = () =>
    createSelector(selectCartsBulkDomain, (substate) => {
        return substate.success
    })

const makeSelectCartBulkCartQuantityUnavailableStockProducts = () =>
    createSelector(selectCartsBulkDomain, (substate) => {
        return substate.unavailable_stock_products || undefined
    })

const makeSelectCartsPersistSettings = () =>
    createSelector(selectCartsSettingsDomain, (substate) => {
        return substate
    })

const makeSelectCartMode = () =>
    createSelector(selectCartsSettingsDomain, (substate) => {
        return substate?.cart_mode || ICartMode.Default
    })
const makeSelectCartValidationMode = () =>
    createSelector(selectCartsSettingsDomain, (substate) => {
        return substate?.cart_validation_mode || ICartValidationMode.Default
    })

const makeSelectMultiStoreCartsListFetching = () =>
    createSelector(selectMultiStoreCartDomain, (substate) => {
        return substate.fetching
    })

const makeSelectMultiStoreCartsListError = () =>
    createSelector(selectMultiStoreCartDomain, (substate) => {
        return substate.error
    })

const makeSelectMultiStoreCartsListItems = () =>
    createSelector(selectMultiStoreCartDomain, (substate) => {
        return substate.items
    })

const makeSelectSalesmanCartListFetching = () =>
    createSelector(selectSalesmanCartListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectSalesmanCartListError = () =>
    createSelector(selectSalesmanCartListDomain, (substate) => {
        return substate.error
    })

const makeSelectSalesmanCartListItems = () =>
    createSelector(selectSalesmanCartListDomain, (substate) => {
        return substate.items
    })

const makeSelectOrdersListTotalItems = () =>
    createSelector(selectSalesmanCartListDomain, (substate) => {
        return substate.totalItems
    })

export default makeSelectCarts
export {
    selectCartsDomain,
    makeSelectCartItemByProductId,
    makeSelectCartsList,
    makeSelectMainCartsList,
    makeSelectQuantityByProductId,
    makeSelectCartByShippingLocationId,
    makeSelectCartById,
    makeSelectFetchingByProductId,
    makeSelectBannerVisibility,
    makeSelectMainCartQuantities,
    makeSelectCartFetching,
    makeSelectMainCartsListIsEmpty,
    makeSelectCartsCreateOrdersFetching,
    makeSelectCartsCreateOrdersData,
    makeSelectCartsCreateOrdersErrors,
    makeSelectCartsCreateOrdersItems,
    makeSelectCartsReOrderFetching,
    makeSelectCartsReOrderData,
    makeSelectCartsReOrderErrors,
    makeSelectCartsReOrderWarnings,
    makeSelectCartsReOrderSuccess,
    makeSelectFetching,
    makeSelectCartItemStoreQuantitiesDomain,
    makeSelectCartsCleanFetching,
    makeSelectCartCleanFetching,
    makeSelectCartLightDomain,
    makeSelectChildCartsList,
    makeSelectSubCartQuantities,
    makeSelectCartBulkCartQuantityFetching,
    makeSelectCartBulkCartQuantityError,
    // makeSelectCartBulkCartQuantityItems,
    makeSelectCartBulkCartQuantitySuccess,
    makeSelectErrorsByProductId,
    makeSelectCanIncrementQuantityByProductId,
    makeSelectCartLightByParentId,
    makeSelectCartsDuplicateData,
    makeSelectCartsDuplicateFetching,
    makeSelectCartsDuplicateErrors,
    makeSelectCartsDuplicateSuccess,
    makeSelectCartsDuplicateWarnings,
    makeSelectCartBulkCartQuantityUnavailableStockProducts,
    makeSelectCartsPersistSettings,
    makeSelectCartMode,
    makeSelectCartValidationMode,
    makeSelectLockByShippingLocationId,
    makeSelectLockedCartIds,
    makeSelectCartsLockerFetching,
    makeSelectCartsLockerErrors,
    makeSelectCartsLockerSuccess,
    makeSelectMultiStoreCartsListItems,
    makeSelectMultiStoreCartsListError,
    makeSelectMultiStoreCartsListFetching,
    makeSelectSalesmanCartListFetching,
    makeSelectSalesmanCartListError,
    makeSelectSalesmanCartListItems,
    makeSelectOrdersListTotalItems,
}
