import { IPickerAction } from '../../../../types/productPicker'
import PickerActionFavorites from '../../../../containers/Products/Picker/Type/PickerActionFavorites'

export const managerFavoriteAction: IPickerAction = {
    icon: 'heart',
    code: 'favorite',
    label: 'product_picker.organize_favorites',
    options: {
        min: 1,
    },
    components: {
        action: PickerActionFavorites,
    },
}

export const manageFavoriteDepartmentAction: IPickerAction = {
    icon: 'tree-structure',
    code: 'favorite_department',
    label: 'product_picker.organize_favorite_departments',
    options: {},
}

export default managerFavoriteAction
