/**
 *
 * ProductCarouselTabbed
 *
 */
import React, { memo, MouseEvent, useCallback, useState } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { ICmsWidgetInteractiveSlide } from '../../../../services/api/service/cms/types'
import { IComponentLazyProps } from '../../../../types/common'
import { InteractiveSlideClickCallback } from '../../../../types/interactiveSlider'
import Tag, { TagProps } from './Tag'
import { IProductList } from '../../../../services/api/service/products/types'
import { FormattedMessage } from 'react-intl'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import InteractiveSlideCtx, { InteractiveSlideIndex } from './Context'

type Props = IComponentLazyProps & {
    slide: ICmsWidgetInteractiveSlide
    onClick?: InteractiveSlideClickCallback
} & Pick<
        TagProps,
        | 'onProductFavorite'
        | 'quantitySelectorMultiple'
        | 'showProductSalesmanInfo'
        | 'showPrices'
        | 'showGeneralPriceBadge'
        | 'bypassCartLocker'
    >

const slidePlaceholder = (
    <div className="my-awesome-placeholder">
        <RectShape color={'gray'} className={'show-loading-animation'} style={{ width: 1200, height: 441 }} />
    </div>
)

function Slide({ ready, slide, onClick, ...rest }: Props): JSX.Element {
    const [activePopoverIndex, setActivePopoverIndex] = useState<InteractiveSlideIndex>(undefined)

    const handleSlideClick = useCallback(
        (evt: MouseEvent) => {
            if (slide.tags.length) {
                return
            }

            evt.preventDefault()

            if (onClick) {
                onClick(slide)
            }
        },
        [slide, onClick]
    )

    const handleBtnClick = useCallback(
        (evt: MouseEvent) => {
            evt.preventDefault()

            if (onClick) {
                onClick(slide)
            }
        },
        [slide, onClick]
    )

    const handleOnProductClick = useCallback(
        (product: IProductList) => {
            if (onClick) {
                onClick(slide, product)
            }
        },
        [slide, onClick]
    )

    return (
        <div className={'app-slider-slide interactive-slide'} onClick={handleSlideClick}>
            <InteractiveSlideCtx.Provider value={{ activePopoverIndex, setActivePopoverIndex }}>
                <ReactPlaceholder ready={ready} customPlaceholder={slidePlaceholder}>
                    <div className="interactive-slide-content">
                        <img src={slide.image.default} alt={slide.title} className={'swiper-lazy'} />
                        {slide.tags.map((tag, index) => (
                            <Tag
                                index={index}
                                key={`${tag.position_x}_${tag.position_y}`}
                                tag={tag}
                                excludeBadges={undefined}
                                onProductClick={handleOnProductClick}
                                {...rest}
                            />
                        ))}
                    </div>
                    <div className="interactive-slide-footer">
                        <span
                            onClick={handleBtnClick}
                            className="btn btn-primary d-block"
                            style={{
                                backgroundColor: slide.banner_background_color
                                    ? slide.banner_background_color
                                    : undefined,
                                color: slide.banner_text_color ? slide.banner_text_color : undefined,
                            }}
                        >
                            <FormattedMessage id="default.see_collection" />
                            <FlatIcon icon={'chevron-right'} />
                        </span>
                    </div>
                </ReactPlaceholder>
            </InteractiveSlideCtx.Provider>
        </div>
    )
}

Slide.defaultProps = {
    ready: false,
} as Partial<Props>

export default memo(Slide)
