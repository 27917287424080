/**
 *
 * MainMenu
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../../routes'
import { ISalesmanMenuItem, ISalesmanMenuItemClickCallback, ISalesmanMenuItemType } from '../../../types/salesman'
import FlatIcon from '../../Icon/FlatIcon'
import getSalesmanMenu from '../../../utils/salesman'
import { IMe } from '../../../services/api/service/me/types'

interface IMenuItemProps {
    item: ISalesmanMenuItem
    onItemClick: ISalesmanMenuItemClickCallback
}

function MenuItem({ item, onItemClick }: IMenuItemProps): JSX.Element {
    const { locale } = useIntl()
    let itemUrl: string | undefined = undefined
    if (item.route) {
        itemUrl = generatePath(getPath(item.route, locale), { lang: locale })
    }

    const handleOnClick = (e: MouseEvent) => {
        onItemClick(e, item, itemUrl)
    }

    const Content = (
        <>
            <span className={'menu-item-icon'}>
                <FlatIcon icon={item.icon} className={'main-icon'} />
                {item.sub_icon && <FlatIcon icon={item.sub_icon!} className={'sub-icon'} />}
            </span>
            <span className={'menu-item-title'}>
                <FormattedMessage id={item.label} />
            </span>
        </>
    )

    return (
        <div className={classNames('menu-item', `menu-item-${item.type}`)} onClick={handleOnClick}>
            {itemUrl && (
                <NavLink to={itemUrl} className={'menu-item-nav'} exact={item.exact}>
                    {Content}
                </NavLink>
            )}
            {!item.route && <div className={'menu-item-nav no-link'}>{Content}</div>}
        </div>
    )
}

interface IProps {
    className?: string
    onItemClick: ISalesmanMenuItemClickCallback
    exclude?: Array<ISalesmanMenuItemType>
    only?: Array<ISalesmanMenuItemType>
    me: IMe
}

function Menu({ className, onItemClick, exclude, only, me }: IProps): JSX.Element {
    let itms: Array<ISalesmanMenuItem> = useMemo(() => {
        return [...getSalesmanMenu(me)]
    }, [me])
    if (only && only.length > 0) {
        itms = itms.filter((itm) => only.indexOf(itm.type) > -1)
    }
    if (exclude && exclude.length > 0) {
        itms = itms.filter((itm) => exclude.indexOf(itm.type) === -1)
    }
    return (
        <div className={classNames('customer-menu', className)}>
            {itms.map((value) => (
                <MenuItem item={value} key={value.type} onItemClick={onItemClick} />
            ))}
        </div>
    )
}

Menu.defaultProps = {
    exclude: [],
    only: [],
} as Partial<IProps>

export default memo(Menu)
