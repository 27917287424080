import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import PictureExports from '../containers/CustomerPictureExports/PictureExports'

export default function CustomerPictureExportsPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.picture_export' })}</title>
            </Helmet>
            <PictureExports />
        </>
    )
}
