/**
 *
 * OrderState
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { OrderStates } from '../../services/api/service/orders/types'

interface IProps {
    state: OrderStates
}

function OrderState({ state }: IProps): JSX.Element {
    return (
        <span className={'order-state'}>
            <FormattedMessage id={`orders.state.${state.toString()}`} />
        </span>
    )
}

OrderState.defaultProps = {} as Partial<IProps>

export default memo(OrderState)
