/*
 *
 * Orders constants
 *
 */

enum ActionTypes {
    PERSIST_PARAMS_ACTION = 'app/Orders/Params/PERSIST',
    PERSISTED_PARAMS_ACTION = 'app/Orders/Params/PERSISTED',
    SWITCH_ORDER_MODE_ACTION = 'app/Orders/Mode/SWITCH',
    LIST_PROCESS_ACTION = 'app/Orders/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/Orders/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/Orders/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/Orders/List/RESET',
    LAST_PROCESS_ACTION = 'app/Orders/Last/REQUEST_PROCESS',
    LAST_SUCCESS_ACTION = 'app/Orders/Last/REQUEST_SUCCESS',
    LAST_FAILURE_ACTION = 'app/Orders/Last/REQUEST_FAILURE',
    LAST_RESET_ACTION = 'app/Orders/Last/RESET',
    DOCUMENT_PROCESS_ACTION = 'app/Orders/Document/REQUEST_PROCESS',
    DOCUMENT_SUCCESS_ACTION = 'app/Orders/Document/REQUEST_SUCCESS',
    DOCUMENT_FAILURE_ACTION = 'app/Orders/Document/REQUEST_FAILURE',
    DOCUMENT_RESET_SINGLE_ACTION = 'app/Orders/Document/REST_SINGLE',
    CUSTOMER_PROCESS_ACTION = 'app/Orders/Customer/REQUEST_PROCESS',
    CUSTOMER_SUCCESS_ACTION = 'app/Orders/Customer/REQUEST_SUCCESS',
    CUSTOMER_FAILURE_ACTION = 'app/Orders/Customer/REQUEST_FAILURE',
    DOCUMENT_RESET_ACTION = 'app/Orders/Document/RESET',
    RESET_COLLECTION_ACTION = 'app/Orders/RESET_COLLECTION',
    RESET_ACTION = 'app/Orders/RESET',
}

export default ActionTypes
