/*
 *
 * Order constants
 *
 */

enum ActionTypes {
    DETAIL_PROCESS_ACTION = 'app/Order/Detail/REQUEST_PROCESS',
    DETAIL_SUCCESS_ACTION = 'app/Order/Detail/REQUEST_SUCCESS',
    DETAIL_FAILURE_ACTION = 'app/Order/Detail/REQUEST_FAILURE',
    DOCUMENT_RESET_ACTION = 'app/Order/Pdf/RESET',
    RESET_ACTION = 'app/Order/RESET',
}

export default ActionTypes
