import { call, getContext, put, takeLatest, takeEvery } from 'redux-saga/effects'
import AuthActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { ICmsBlockProcessAction, ICmsPageProcessAction } from './types'
import { cmsBlockFailureAction, cmsBlockSuccessAction, cmsPageFailureAction, cmsPageSuccessAction } from './actions'
import { formatAppError } from '../app/saga'
import {
    CmsBlockIdentifierType,
    CmsPageIdentifierType,
    IApiCmsBlockResponse,
    IApiCmsPageResponse,
} from '../../services/api/service/cms/types'

function* processCmsPageRequest(action: ICmsPageProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { identifier, identifierType } = action.payload

    try {
        let response: IApiCmsPageResponse | undefined = undefined
        if (identifierType === CmsPageIdentifierType.Code) {
            response = yield call({ context: api.cms, fn: 'getPageByCode' }, identifier)
        } else if (identifierType === CmsPageIdentifierType.Slug) {
            response = yield call({ context: api.cms, fn: 'getPageBySlug' }, identifier)
        } else if (identifierType === CmsPageIdentifierType.Identifier) {
            response = yield call({ context: api.cms, fn: 'getPreviewPage' }, identifier)
        }
        if (response) {
            yield put(cmsPageSuccessAction(identifier, identifierType, response))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(cmsPageFailureAction(identifier, identifierType, error))
    }
}

function* processCmsBlockRequest(action: ICmsBlockProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { identifier, identifierType } = action.payload

    try {
        let response: IApiCmsBlockResponse | undefined = undefined
        if (identifierType === CmsBlockIdentifierType.Code) {
            response = yield call({ context: api.cms, fn: 'getBlockByCode' }, identifier)
        }
        if (response) {
            yield put(cmsBlockSuccessAction(identifier, identifierType, response))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'default.error')
        yield put(cmsBlockFailureAction(identifier, identifierType, error))
    }
}

export default [
    takeLatest(AuthActionTypes.PAGE_PROCESS_ACTION, processCmsPageRequest),
    takeEvery(AuthActionTypes.BLOCK_PROCESS_ACTION, processCmsBlockRequest),
]
