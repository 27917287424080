/*
 *
 * Products
 *
 */

import React, { memo, useMemo } from 'react'
import { Table } from 'react-bootstrap'
import { TextBlock } from 'react-placeholder/lib/placeholders'

interface IProps {
    itemsPerPage?: number
    columns?: number
}

function Placeholder({ itemsPerPage = 24, columns = 5 }: IProps): JSX.Element {
    const cols: Array<JSX.Element> = useMemo(() => {
        const comps: Array<JSX.Element> = []
        for (let i = 1; i <= columns; i++) {
            comps.push(
                <td key={`placeholder_row_${i}`} className={'cell number'}>
                    <TextBlock rows={1} color={'gray'} className={'order-head-placeholder'} />
                </td>
            )
        }
        return comps
    }, [columns])

    const components: Array<JSX.Element> = useMemo(() => {
        const comps: Array<JSX.Element> = []
        for (let i = 1; i <= itemsPerPage; i++) {
            comps.push(<tr key={`placeholder_row_${i}`}>{cols}</tr>)
        }

        return comps
    }, [cols, itemsPerPage])

    return (
        <Table className={'placeholder'} striped hover responsive>
            <thead className={'throw head'}>
                <tr>{cols}</tr>
            </thead>
            <tbody>{components}</tbody>
        </Table>
    )
}

export default memo(Placeholder)
