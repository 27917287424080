/*
 *
 * Auth actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import { IAuthHostTokenParameters, IAuthParameters } from '../../services/api/service/authenticate/types'
import { IAppErrorTypes } from '../app/types'
import {
    IAuthProcess2FaPayload,
    IAuthProcessPayload,
    IAuthWait2FaPayload,
    IAuthLoggedPayload,
    IAuthLogoutPayload,
} from './types'
import { IMe } from '../../services/api/service/me/types'

export const authResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const authLoggedAction = (me: IMe, redirectUrl?: string, origin?: string) =>
    action<ActionTypes.LOGGED_ACTION, IAuthLoggedPayload>(ActionTypes.LOGGED_ACTION, {
        me,
        redirectUrl,
        originUrl: origin,
    })
export const authLogoutAction = (auto: boolean, reason?: IAppErrorTypes, origin?: string) =>
    action<ActionTypes.LOGOUT_ACTION, IAuthLogoutPayload>(ActionTypes.LOGOUT_ACTION, {
        auto,
        reason,
        origin,
    })
export const authProcessAction = (data: IAuthParameters & IAuthHostTokenParameters) =>
    action<ActionTypes.PROCESS_ACTION, IAuthProcessPayload>(ActionTypes.PROCESS_ACTION, data)
export const authFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.FAILURE_ACTION, { error })
export const authWait2FaAction = (token: string, mobilePhone: string | null) =>
    action<ActionTypes.WAIT2FA_ACTION, IAuthWait2FaPayload>(ActionTypes.WAIT2FA_ACTION, {
        token,
        mobilePhone,
    })
export const authProcess2FaAction = (code: string) =>
    action<ActionTypes.PROCESS_2FA_ACTION, IAuthProcess2FaPayload>(ActionTypes.PROCESS_2FA_ACTION, { code })
export const authFailure2FaAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAILURE_2FA_ACTION, { error: IAppErrorTypes }>(ActionTypes.FAILURE_2FA_ACTION, { error })
