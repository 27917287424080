import { MouseEvent } from 'react'

export enum ISalesmanMenuLocation {
    Area = 'Area',
    Dropdown = 'Dropdown',
}

export enum ISalesmanMenuItemType {
    Home = 'Home',
    Dashboard = 'Dashboard',
    Orders = 'Orders',
    Assets = 'Assets',
    Carts = 'carts',
    Invoices = 'invoices',
    Customers = 'customers',
    Prospects = 'prospects',
    Logout = 'logout',
    // Modify_Login = 'modify_login',
}

export interface ISalesmanMenuItem {
    readonly type: ISalesmanMenuItemType
    readonly label: string
    readonly route?: string
    readonly icon: string
    readonly sub_icon?: string
    readonly exact: boolean
    readonly position: number
}

export type ISalesmanMenuItemTypeCollection = Array<ISalesmanMenuItem>
export type ISalesmanMenuItemClickCallback = (e: MouseEvent, item: ISalesmanMenuItem, url?: string) => void
export type ISalesmanMenu = Array<ISalesmanMenuItem>
