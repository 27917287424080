/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { $enum } from 'ts-enum-util'
import { ICustomer } from '../../../../services/api/service/customers/types'
import { IProductList } from '../../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../../store'
import { makeSelectCustomer } from '../../../../store/customers/selectors'
import { getCustomerBadgesUrl, productHasDiscount } from '../../../../store/products/utils'
import {
    BadgeModeType,
    extractBadgeUrl,
    ProductBadgeMode,
    ProductBadgeType,
    ShowBadgeType,
} from '../../../../types/productBadge'
import defaultImages from './Images'

export type IBadgeListProps = {
    show: ShowBadgeType
    mode: BadgeModeType
    exclude?: Array<ProductBadgeType>
}

type IProps = IBadgeListProps & {
    product: IProductList
    className?: string
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
})

function List({ show, mode, exclude, product, className }: IProps): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const { customer } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
        }
    >(stateSelector)

    const hasDiscount = useMemo(() => {
        return productHasDiscount(product)
    }, [product])

    const only: Array<ProductBadgeType> | undefined = useMemo(() => {
        let values: Array<ProductBadgeType> = []

        if (typeof show === 'object') {
            values = Object.values(show)
        } else if (typeof show === 'string') {
            values = [show]
        } else if (typeof show === 'boolean' && !show) {
            values = []
        } else {
            values = $enum(ProductBadgeType).getValues()
        }

        if (typeof exclude === 'object') {
            values.filter((ol) => exclude.indexOf(ol) === -1)
        }

        // suppression remise si pas de remise
        const discountIdx = values.indexOf(ProductBadgeType.Discount)
        if (!hasDiscount && discountIdx > -1) {
            values.splice(discountIdx, 1)
        }

        // suppression prix en baisse si pas disponible
        const decreasingPriceIdx = values.indexOf(ProductBadgeType.DecreasingPrice)
        if (!product.frontend_attributes?.decreasing_price && decreasingPriceIdx > -1) {
            values.splice(decreasingPriceIdx, 1)
        }

        // suppression nouveauté si pas disponible
        const newIdx = values.indexOf(ProductBadgeType.New)
        if (!product.frontend_attributes?.new && newIdx > -1) {
            values.splice(newIdx, 1)
        }

        // demande client le 21/07/2022 : si new pas de badge best b2c
        const bestIdx = values.indexOf(ProductBadgeType.BestB2c)
        if (product.best_seller && product.frontend_attributes?.new && bestIdx > -1) {
            values.splice(bestIdx, 1)
        }

        // demande du client le 04/10/2022 : si on a une remise de disponible, on masque le best et le new
        if (hasDiscount) {
            const idx1 = values.indexOf(ProductBadgeType.BestB2c)
            if (idx1 > -1) {
                values.splice(idx1, 1)
            }
            const idx2 = values.indexOf(ProductBadgeType.New)
            if (idx2 > -1) {
                values.splice(idx2, 1)
            }
            const idx3 = values.indexOf(ProductBadgeType.DecreasingPrice)
            if (idx3 > -1) {
                values.splice(idx3, 1)
            }
        }

        return values
    }, [
        show,
        exclude,
        hasDiscount,
        product.frontend_attributes?.decreasing_price,
        product.frontend_attributes?.new,
        product.best_seller,
    ])

    const productBadgeImages = useMemo(() => {
        // images custom du client
        const customerImages = getCustomerBadgesUrl(customer)

        if (!customerImages || Object.values(customerImages).length === 0) {
            return defaultImages
        }

        const images = { ...defaultImages }
        $enum(ProductBadgeType)
            .getValues()
            .forEach((value) => {
                if (customerImages[value]) {
                    images[value] = customerImages[value]
                }
            })
        return images
    }, [customer])

    const discountBadgeInfo = useMemo(() => {
        if (!product.discount) {
            return undefined
        }

        let discountBadgeTitle: string | undefined = formatMessage({ id: 'price.rounded_percentage' })
        let discountBadgeUrl: string | undefined = extractBadgeUrl(
            productBadgeImages[ProductBadgeType.Discount],
            locale
        )

        if (!product.discount_percent) {
            return {
                url: discountBadgeUrl,
                title: undefined,
            }
        }

        if (product.discount_percent >= 5 && product.discount_percent <= 9) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_5`], locale)
        } else if (product.discount_percent <= 14) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_10`], locale)
        } else if (product.discount_percent <= 19) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_15`], locale)
        } else if (product.discount_percent <= 24) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_20`], locale)
        } else if (product.discount_percent <= 29) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_25`], locale)
        } else if (product.discount_percent <= 34) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_30`], locale)
        } else if (product.discount_percent <= 39) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_35`], locale)
        } else if (product.discount_percent <= 44) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_40`], locale)
        } else if (product.discount_percent <= 49) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_45`], locale)
        } else if (product.discount_percent <= 54) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_50`], locale)
        } else if (product.discount_percent <= 59) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_55`], locale)
        } else if (product.discount_percent <= 64) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_60`], locale)
        } else if (product.discount_percent <= 69) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_65`], locale)
        } else if (product.discount_percent <= 74) {
            discountBadgeUrl = extractBadgeUrl(productBadgeImages[`${ProductBadgeType.Discount}_70`], locale)
        } else {
            discountBadgeTitle = undefined
        }

        return {
            url: discountBadgeUrl,
            title: discountBadgeTitle,
        }
    }, [product.discount, product.discount_percent, formatMessage, productBadgeImages, locale])

    let Badges = <></>

    if (mode === ProductBadgeMode.Image) {
        Badges = (
            <span className={'product-badge-list'}>
                {product.frontend_attributes?.new && only && only.indexOf(ProductBadgeType.New) > -1 && (
                    <span className={classNames('badge', 'product-badge', 'product-badge-image', 'product-badge-new')}>
                        <img
                            src={extractBadgeUrl(productBadgeImages[ProductBadgeType.New], locale)}
                            alt={formatMessage({ id: 'products.badge.new' })}
                        />
                    </span>
                )}
                {product.frontend_attributes?.decreasing_price &&
                    only &&
                    only.indexOf(ProductBadgeType.DecreasingPrice) > -1 && (
                        <span
                            className={classNames(
                                'badge',
                                'product-badge',
                                'product-badge-image',
                                'product-badge-decreasing-price'
                            )}
                        >
                            <img
                                src={extractBadgeUrl(productBadgeImages[ProductBadgeType.DecreasingPrice], locale)}
                                alt={formatMessage({ id: 'products.badge.decreasing_price' })}
                            />
                        </span>
                    )}
                {product.best_seller && only && only.indexOf(ProductBadgeType.BestB2c) > -1 && (
                    <span
                        className={classNames(
                            'badge',
                            'product-badge',
                            'product-badge-image',
                            'product-badge-best-seller'
                        )}
                    >
                        <img
                            src={extractBadgeUrl(productBadgeImages[ProductBadgeType.BestB2c], locale)}
                            alt={formatMessage({ id: 'products.badge.new' })}
                        />
                    </span>
                )}
                {hasDiscount && only && only.indexOf(ProductBadgeType.Discount) > -1 && (
                    <span
                        title={discountBadgeInfo?.title}
                        className={classNames(
                            'badge',
                            'product-badge',
                            'product-badge-image',
                            'product-badge-discount'
                        )}
                    >
                        <img src={discountBadgeInfo?.url} alt={formatMessage({ id: 'page.discounts' })} />
                    </span>
                )}
                {product.buying_group_product && only && only.indexOf(ProductBadgeType.MyListing) > -1 && (
                    <span
                        className={classNames(
                            'badge',
                            'product-badge',
                            'product-badge-image',
                            'product-badge-my-listing'
                        )}
                    >
                        <img
                            src={extractBadgeUrl(productBadgeImages[ProductBadgeType.MyListing], locale)}
                            alt={formatMessage({ id: 'products.badge.my_listing' })}
                        />
                    </span>
                )}
            </span>
        )
    } else {
        Badges = (
            <span className={'product-badge-list'}>
                {product.frontend_attributes?.new && only && only.indexOf(ProductBadgeType.New) > -1 && (
                    <span className={classNames('badge', 'product-badge', 'product-badge-new')}>
                        <FormattedMessage id={'products.badge.new'} />
                    </span>
                )}
                {product.best_seller && only && only.indexOf(ProductBadgeType.BestB2c) > -1 && (
                    <span className={classNames('badge', 'product-badge', 'product-badge-best-seller')}>
                        <FormattedMessage id={'products.badge.best_seller'} />
                    </span>
                )}
                {product.buying_group_product && only && only.indexOf(ProductBadgeType.MyListing) > -1 && (
                    <span className={classNames('badge', 'product-badge', 'product-badge-my-listing')}>
                        <FormattedMessage id={'products.badge.my_listing'} />
                    </span>
                )}
            </span>
        )
    }

    return <span className={classNames('product-badge-list', className)}>{Badges}</span>
}

List.defaultProps = {
    show: true,
    mode: 'image',
} as Partial<IProps>

const areEqual = (prevProps: IProps, nextProps: IProps): boolean => {
    return (
        prevProps.product['@id'] === nextProps.product['@id'] &&
        prevProps.product.already_ordered === nextProps.product.already_ordered
    )
}

export default memo(List, areEqual)
