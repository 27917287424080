/*
 *
 * ExportProducts reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, IExportsListState } from './types'

export const initialListState: IExportsListState = {
    fetching: false,
    params: {
        page: 1,
    },
}

export const exportsState = {
    create: {},
}

export const processState = {}

export const initialState: ContainerState = {
    list: initialListState,
    exports: exportsState,
    process: processState,
}

function exportProductsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.LIST_RESET_ACTION:
            return {
                ...state,
                list: {
                    ...initialListState,
                },
            }
        case ActionTypes.CHECK_STATE_RESET_ACTION:
            return {
                ...state,
                process: {
                    ...processState,
                },
            }
        case ActionTypes.CREATE_RESET_ACTION:
            return {
                ...state,
                process: {
                    ...processState,
                },
                exports: {
                    ...exportsState,
                },
            }
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LIST_PROCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: undefined,
                    params: {
                        ...state.list.params,
                        ...action.payload,
                    },
                },
            }
        case ActionTypes.LIST_SUCCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        case ActionTypes.LIST_FAILURE_ACTION:
            return {
                ...state,
            }
        case ActionTypes.CREATE_PROCESS_ACTION: {
            let createType = state.exports.create[action.payload.resourceType.toString()] || {}
            createType = {
                ...createType,
                [action.payload.resourceId]: {
                    fetching: true,
                    data: action.payload,
                },
            }

            return {
                ...state,
                exports: {
                    ...state.exports,
                    create: {
                        ...state.exports.create,
                        [action.payload.resourceType]: {
                            ...createType,
                        },
                    },
                },
            }
        }
        case ActionTypes.CREATE_SUCCESS_ACTION: {
            let createType = state.exports.create[action.payload.resourceType.toString()] || {}
            createType = {
                ...createType,
                [action.payload.resourceId]: {
                    fetching: false,
                    itemId: action.payload.item['@id'],
                },
            }

            return {
                ...state,
                exports: {
                    ...state.exports,
                    create: {
                        ...state.exports.create,
                        [action.payload.resourceType]: {
                            ...createType,
                        },
                    },
                },
                process: {
                    ...state.process,
                    [action.payload.item['@id']]: {
                        fetching: false,
                        item: action.payload.item,
                    },
                },
            }
        }
        case ActionTypes.CREATE_FAILURE_ACTION: {
            let createType = state.exports.create[action.payload.resourceType.toString()] || {}
            createType = {
                ...createType,
                [action.payload.resourceId]: {
                    fetching: false,
                    error: action.payload.error,
                },
            }

            return {
                ...state,
                exports: {
                    ...state.exports,
                    create: {
                        ...state.exports.create,
                        [action.payload.resourceType]: {
                            ...createType,
                        },
                    },
                },
            }
        }
        case ActionTypes.CREATE_RESET_SINGLE_ACTION: {
            let createType = state.exports.create[action.payload.resourceType.toString()] || {}
            createType = {
                ...createType,
                [action.payload.resourceId]: {
                    fetching: false,
                },
            }

            return {
                ...state,
                exports: {
                    ...state.exports,
                    create: {
                        ...state.exports.create,
                        [action.payload.resourceType]: {
                            ...createType,
                        },
                    },
                },
                process: {
                    ...state.process,
                    [action.payload.resourceId]: {
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.CHECK_STATE_PROCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    [action.payload.item['@id']]: {
                        fetching: true,
                        item: {
                            ...(state.process[action.payload.item['@id']] || {}),
                            ...action.payload.item,
                        },
                    },
                },
            }
        }
        case ActionTypes.CHECK_STATE_SUCCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    [action.payload['@id']]: {
                        fetching: false,
                        item: {
                            ...(state.process[action.payload['@id']] || {}),
                            ...action.payload,
                        },
                    },
                },
            }
        }
        default:
            return state
    }
}

export default exportProductsReducer
