import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialModifyLoginState, initialProductsState, initialState } from './reducer'
import { ICustomer } from '../../services/api/service/customers/types'

/**
 * Direct selector to the customers state domain
 */

const selectCustomersDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.customers || initialState
}

const selectCustomersProductsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.customers.products || initialProductsState
}

const selectCustomersModifyLoginDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.customers.modifyLogin || initialModifyLoginState
}

const selectCustomerSalesmenDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.customers.customerSalesmen || initialState.customerSalesmen
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Customers
 */

const makeSelectCustomers = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate
    })

const makeSelectCustomer = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.customer ? substate.customer || undefined : undefined
    })

const makeSelectCustomerStoreId = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.storeId
    })

const makeSelectCustomerStore = () =>
    createSelector(selectCustomersDomain, (substate) => {
        const storeId = substate.storeId
        const customer: ICustomer | undefined = substate.customer ? substate.customer || undefined : undefined
        let store: ICustomer | undefined = undefined
        if (!customer || !storeId || storeId.length === 0) {
            return store
        }
        const idx = customer.stores!.findIndex((stre) => stre.id === storeId)
        if (idx > -1) {
            store = customer.stores![idx]
        }
        return store
    })

const makeSelectCustomerViewedProductsItems = () =>
    createSelector(selectCustomersProductsDomain, (substate) => {
        return substate.list.items || undefined
    })

const makeSelectCustomerViewedProductsItemsFetching = () =>
    createSelector(selectCustomersProductsDomain, (substate) => {
        return substate.fetching || false
    })

const makeSelectCustomerViewedProductsInvalidated = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.products ? substate.products.invalidated || false : false
    })

const makeSelectCustomerModifyLoginError = () =>
    createSelector(selectCustomersModifyLoginDomain, (substate) => {
        return substate.error
    })
const makeSelectCustomerModifyLoginFetching = () =>
    createSelector(selectCustomersModifyLoginDomain, (substate) => substate.fetching)

const makeSelectCustomerModifyLoginSuccess = () =>
    createSelector(selectCustomersModifyLoginDomain, (substate) => substate.done)

const makeSelectCustomerSalesmenItems = () =>
    createSelector(selectCustomerSalesmenDomain, (substate) => {
        return substate.items
    })

export default makeSelectCustomers
export {
    selectCustomersDomain,
    makeSelectCustomer,
    makeSelectCustomerViewedProductsItems,
    makeSelectCustomerViewedProductsInvalidated,
    makeSelectCustomerViewedProductsItemsFetching,
    makeSelectCustomerStoreId,
    makeSelectCustomerStore,
    makeSelectCustomerModifyLoginError,
    makeSelectCustomerModifyLoginFetching,
    makeSelectCustomerModifyLoginSuccess,
    makeSelectCustomerSalesmenItems,
}
