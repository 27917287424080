/*
 *
 * ExportProducts reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, IShopImportListState, IShopImportListSubState } from './types'

export const initialListState: IShopImportListState = {}
export const initialListSubState: IShopImportListSubState = {
    fetching: false,
    params: {
        page: 1,
    },
}

export const createsState = {}
export const processState = {}
export const matrixUrls = {}
export const detailState = {
    fetching: false,
    detail: null,
}

export const initialState: ContainerState = {
    list: initialListState,
    create: createsState,
    process: processState,
    detail: detailState,
    matrixUrls: matrixUrls,
}

function exportProductsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.DETAIL_RESET_ACTION:
            return {
                ...state,
                detail: detailState,
            }
        case ActionTypes.LIST_RESET_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.shopImportListType]: {
                        ...initialListSubState,
                    },
                },
            }
        case ActionTypes.CHECK_STATE_RESET_ACTION: {
            const val = {
                ...state,
                process: {
                    ...state.process,
                },
            }

            delete val.process[action.payload.itemType]
            return val
        }
        case ActionTypes.CREATE_RESET_ACTION: {
            const val = {
                ...state,
                create: {
                    ...state.create,
                },
            }
            delete val.create[action.payload.shopImportType]
            return val
        }
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LIST_PROCESS_ACTION: {
            const val = {
                ...state,
                list: {
                    ...state.list,
                },
            }

            if (!val.list[action.payload.shopImportListType]) {
                val.list[action.payload.shopImportListType] = {
                    ...initialListSubState,
                }
            }

            val.list[action.payload.shopImportListType] = {
                ...val.list[action.payload.shopImportListType],
                error: undefined,
                params: {
                    ...action.payload.parameters,
                },
            }
            return val
        }
        case ActionTypes.LIST_SUCCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.shopImportListType]: {
                        ...state.list[action.payload.shopImportListType],
                        items: action.payload.response['hydra:member'],
                        totalItems: action.payload.response['hydra:totalItems'],
                        nextPageUrl: action.payload.response['hydra:view']['hydra:next'],
                        prevPageUrl: action.payload.response['hydra:view']['hydra:prev'],
                        firstPageUrl: action.payload.response['hydra:view']['hydra:first'],
                        lastPageUrl: action.payload.response['hydra:view']['hydra:last'],
                    },
                },
            }
        case ActionTypes.LIST_FAILURE_ACTION:
            // TODO
            return {
                ...state,
            }
        case ActionTypes.CREATE_PROCESS_ACTION: {
            return {
                ...state,
                create: {
                    ...state.create,
                    [action.payload.shopImportType]: {
                        ...(state.create[action.payload.shopImportType] || {}),
                        data: action.payload.data,
                        storeId: action.payload.storeId,
                        progress: 0.0,
                        error: undefined,
                        fetching: true,
                    },
                },
            }
        }
        case ActionTypes.CREATE_PROCESS_PROGRESS_ACTION: {
            return {
                ...state,
                create: {
                    ...state.create,
                    [action.payload.shopImportType]: {
                        ...(state.create[action.payload.shopImportType] || {}),
                        progress: action.payload.progress,
                    },
                },
            }
        }
        case ActionTypes.CREATE_SUCCESS_ACTION: {
            return {
                ...state,
                create: {
                    ...state.create,
                    [action.payload.shopImportType]: {
                        ...(state.create[action.payload.shopImportType] || {}),
                        progress: 100.0,
                        fetching: false,
                        error: undefined,
                        itemId: action.payload.item['@id'],
                    },
                },
                process: {
                    ...state.process,
                    [action.payload.item['@id']]: {
                        fetching: false,
                        item: action.payload.item,
                    },
                },
            }
        }
        case ActionTypes.CREATE_FAILURE_ACTION: {
            return {
                ...state,
                create: {
                    ...state.create,
                    [action.payload.shopImportType]: {
                        ...(state.create[action.payload.shopImportType] || {}),
                        error: action.payload.error,
                        fetching: false,
                    },
                },
            }
        }
        case ActionTypes.CHECK_STATE_FAILURE_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    [action.payload.itemId]: {
                        ...state.process[action.payload.itemId],
                        fetching: false,
                        error: action.payload.error,
                    },
                },
            }
        }
        case ActionTypes.CHECK_STATE_PROCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    [action.payload.item['@id']]: {
                        fetching: true,
                        item: {
                            ...(state.process[action.payload.item['@id']] || {}),
                            ...action.payload.item,
                        },
                    },
                },
            }
        }
        case ActionTypes.CHECK_STATE_SUCCESS_ACTION: {
            return {
                ...state,
                process: {
                    ...state.process,
                    [action.payload['@id']]: {
                        fetching: false,
                        item: {
                            ...(state.process[action.payload['@id']] || {}),
                            ...action.payload,
                        },
                    },
                },
            }
        }
        case ActionTypes.DETAIL_PROCESS_ACTION: {
            return {
                ...state,
                detail: {
                    ...detailState,
                    fetching: true,
                },
            }
        }
        case ActionTypes.DETAIL_FAILURE_ACTION: {
            return {
                ...state,
                detail: {
                    ...detailState,
                    fetching: false,
                    error: action.payload.error,
                },
            }
        }
        case ActionTypes.DETAIL_SUCCESS_ACTION: {
            return {
                ...state,
                detail: {
                    ...detailState,
                    fetching: false,
                    detail: action.payload.item,
                },
            }
        }
        case ActionTypes.STORE_MATRIX_URLS_ACTION:
            return {
                ...state,
                matrixUrls: action.payload.urls,
            }
        default:
            return state
    }
}

export default exportProductsReducer
