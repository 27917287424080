import { IMemberStatisticsFilters } from '../../../../store/statistics/types'
import { IMeStatisticsParameters } from '../../../../services/api/service/stats/types'
import isUndefined from 'lodash/isUndefined'

export function convertStatisticFiltersToApiParams(
    filters?: IMemberStatisticsFilters
): Partial<IMeStatisticsParameters> {
    if (!filters) {
        return {}
    }

    const params: IMeStatisticsParameters = {
        from: `${filters.from.getFullYear()}-${('0' + (filters.from.getMonth() + 1)).slice(-2)}`,
        to: `${filters.to.getFullYear()}-${('0' + (filters.to.getMonth() + 1)).slice(-2)}`,
        departments: filters.classification.departments,
        families: filters.classification.families,
        sub_families: filters.classification.subFamilies,
    }

    if (!isUndefined(filters.all_customers)) {
        params['all_customers'] = filters.all_customers ? 1 : 0
    }

    if (!isUndefined(filters.all_activity)) {
        params['all_activity'] = filters.all_activity ? 1 : 0
    }

    return params
}
