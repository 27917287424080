/**
 *
 * Product Card
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames, { Value as ClassValue } from 'classnames'

interface IProps {
    className?: ClassValue
    reference: string
    showLabel: boolean
    labelAbbr: boolean
}

function Reference({ reference, showLabel, labelAbbr, className }: IProps): JSX.Element {
    return (
        <div className={classNames('product-field', 'product-field-reference', { 'with-label': showLabel }, className)}>
            {showLabel && (
                <span className={'lbl'}>
                    <FormattedMessage id={labelAbbr ? 'product.reference_abbr' : 'product.reference'} />
                </span>
            )}
            <span className={classNames('value', 'product-reference')}>{reference}</span>
        </div>
    )
}

Reference.defaultProps = {
    showLabel: false,
    labelAbbr: false,
} as Partial<IProps>

export default memo(Reference)
