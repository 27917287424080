import { AxiosInstance } from 'axios'
import {
    IApiProspectGetResponse,
    IApiProspectListCollection,
    IApiProspectListParameters,
    IApiProspectPriceListResponse,
    IProspectsService,
    IApiProspectListResponse,
    IApiProspectEditableFields,
    IApiProspectEditResponse,
} from './types'

const create = (instance: AxiosInstance): IProspectsService => {
    /*****************************
     * METHODS
     *****************************/
    const get = (prospectId: string): Promise<IApiProspectGetResponse> => {
        const paths = prospectId.split('/')
        const identifier = paths[paths.length - 1]
        return instance.get(`/prospects/${identifier}`)
    }

    const priceList = (): Promise<IApiProspectPriceListResponse> => {
        return instance.get(`/prospect-prices`)
    }

    const list = <T = IApiProspectListCollection>(
        params: IApiProspectListParameters,
        cancelToken
    ): Promise<IApiProspectListResponse<T>> => {
        return instance.get('/prospects', { params, cancelToken })
    }

    const create = (params: Partial<IApiProspectEditableFields>): Promise<IApiProspectEditResponse> => {
        return instance.post('/prospects', { ...params })
    }

    const edit = (
        params: Partial<IApiProspectEditableFields>,
        identifier: string
    ): Promise<IApiProspectEditResponse> => {
        const paths = identifier.split('/')
        const prospectId = paths[paths.length - 1]
        return instance.put(`/prospects/${prospectId}`, { ...params })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        get,
        list,
        priceList,
        create,
        edit,
    }
}

export default {
    create,
}
