import React, { ButtonHTMLAttributes, forwardRef, memo } from 'react'
import {
    Button as BootstrapButton,
    ButtonProps as BootstrapButtonProps,
    Spinner,
    SpinnerProps as BaseSpinnerProps,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

/**
 * Props du Button
 * - les props d'un bouton HTML (pour récupérer le onClick)
 * - les props d'un bouton bootstrap (pour récupérer le style du bouton bootstrap)
 * - custom (pour récupérer le libellé du message)
 */

type LoadingProps = BaseSpinnerProps & {}

export type ButtonProps = ButtonHTMLAttributes<HTMLElement> &
    BootstrapButtonProps & {
        loading?: boolean
        loader?: LoadingProps
    }

const Button = forwardRef<any, ButtonProps>(
    (
        {
            loading = false,
            loader = {
                animation: 'grow',
                size: 'sm',
                role: 'loading',
            },
            variant = 'dark',
            href,
            ...props
        },
        ref
    ) => {
        if (href) {
            props = {
                ...props,
                as: NavLink,
                // @ts-ignore
                to: href || '#',
            }
        }
        return (
            <BootstrapButton variant={variant} {...props} ref={ref} disabled={props.disabled || loading}>
                {loading && (
                    <Spinner
                        as="span"
                        animation={loader.animation}
                        size={loader.size}
                        variant={loader.variant}
                        role={loader.role}
                        aria-hidden="true"
                    />
                )}
                {props.children}
            </BootstrapButton>
        )
    }
)

export default memo(Button)
