/**
 *
 * Sidebar
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface IProps {
    show: boolean
    disabled: boolean
    start: number
    end: number
    total: number
    labelId: string
}

function Counter({ start, end, total, show, disabled, labelId }: IProps): JSX.Element {
    return (
        <div className={classNames('product-list-counter', { 'd-none': !show, disabled })}>
            {!disabled && (
                <FormattedMessage
                    id={labelId}
                    values={{
                        start: start + 1,
                        end: end + 1,
                        total: total,
                    }}
                />
            )}
            {disabled && (
                <>
                    <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} />{' '}
                    <FormattedMessage id={'default.loading'} />
                </>
            )}
        </div>
    )
}

Counter.defaultProps = {
    show: false,
} as Partial<IProps>

export default memo(Counter)
