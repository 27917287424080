/*
 *
 * Cms actions
 *
 */

import { action } from 'typesafe-actions'

import { AxiosError } from 'axios'

import ActionTypes from './constants'
import {
    CmsBlockIdentifierType,
    CmsPageIdentifierType,
    IApiCmsBlockResponse,
    IApiCmsPageResponse,
} from '../../services/api/service/cms/types'
import {
    ICmsBlockFailurePayload,
    ICmsBlockLoadPayload,
    ICmsBlockResetPayload,
    ICmsBlockSuccessPayload,
    ICmsPageFailurePayload,
    ICmsPageLoadPayload,
    ICmsPageResetPayload,
    ICmsPageSuccessPayload,
} from './types'

export const cmsBlockProcessAction = (identifier: string, identifierType: CmsBlockIdentifierType) =>
    action<ActionTypes.BLOCK_PROCESS_ACTION, ICmsBlockLoadPayload>(ActionTypes.BLOCK_PROCESS_ACTION, {
        identifier,
        identifierType,
    })

export const cmsBlockSuccessAction = (
    identifier: string,
    identifierType: CmsBlockIdentifierType,
    response: IApiCmsBlockResponse
) =>
    action<ActionTypes.BLOCK_SUCCESS_ACTION, ICmsBlockSuccessPayload>(ActionTypes.BLOCK_SUCCESS_ACTION, {
        identifier,
        identifierType,
        response,
    })

export const cmsBlockFailureAction = (identifier: string, identifierType: CmsBlockIdentifierType, error: AxiosError) =>
    action<ActionTypes.BLOCK_FAILURE_ACTION, ICmsBlockFailurePayload>(ActionTypes.BLOCK_FAILURE_ACTION, {
        error,
        identifierType,
        identifier,
    })
export const cmsBlockResetAction = (identifier: string, identifierType: CmsBlockIdentifierType) =>
    action<ActionTypes.BLOCK_RESET_ACTION, ICmsBlockResetPayload>(ActionTypes.BLOCK_RESET_ACTION, {
        identifier,
        identifierType,
    })

export const cmsPageProcessAction = (identifier: string, identifierType: CmsPageIdentifierType) =>
    action<ActionTypes.PAGE_PROCESS_ACTION, ICmsPageLoadPayload>(ActionTypes.PAGE_PROCESS_ACTION, {
        identifier,
        identifierType,
    })

export const cmsPageSuccessAction = (
    identifier: string,
    identifierType: CmsPageIdentifierType,
    response: IApiCmsPageResponse
) =>
    action<ActionTypes.PAGE_SUCCESS_ACTION, ICmsPageSuccessPayload>(ActionTypes.PAGE_SUCCESS_ACTION, {
        identifier,
        identifierType,
        response,
    })

export const cmsPageFailureAction = (identifier: string, identifierType: CmsPageIdentifierType, error: AxiosError) =>
    action<ActionTypes.PAGE_FAILURE_ACTION, ICmsPageFailurePayload>(ActionTypes.PAGE_FAILURE_ACTION, {
        error,
        identifierType,
        identifier,
    })
export const cmsPageResetAction = (identifier: string, identifierType: CmsPageIdentifierType) =>
    action<ActionTypes.PAGE_RESET_ACTION, ICmsPageResetPayload>(ActionTypes.PAGE_RESET_ACTION, {
        identifier,
        identifierType,
    })

export const cmsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
