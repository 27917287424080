/**
 *
 * Product Prices
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import FlatIcon from '../../Icon/FlatIcon'
interface IProps {
    shippingLocationId: string
}

function Location({ shippingLocationId }: IProps): JSX.Element {
    return (
        <div className={classNames('product-location')}>
            <FlatIcon icon={'delivery-truck-1'} />
            <span className={'value'}>{shippingLocationId}</span>
        </div>
    )
}

Location.defaultProps = {} as Partial<IProps>

export default memo(Location)
