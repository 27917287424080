import React, { MouseEvent, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../store'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import classNames from 'classnames'
import { Card } from 'react-bootstrap'
import { default as SalesmanMenu } from '../components/Salesman/Menu/List'
import { ISalesmanMenuItem, ISalesmanMenuItemType } from '../types/salesman'
import { authLogoutAction } from '../store/auth/actions'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

function SalesmanHomePage(): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    const handleOnItemClick = useCallback(
        (e: MouseEvent, item: ISalesmanMenuItem) => {
            if (item.type === ISalesmanMenuItemType.Logout) {
                e.preventDefault()
                e.stopPropagation()
                dispatch(authLogoutAction(false))
            }
        },
        [dispatch]
    )

    useEffect(() => {
        document.documentElement.classList.add('no-sidebar')
        return () => {
            document.documentElement.classList.remove('no-sidebar')
        }
    }, [])

    if (!isSalesmanResource(me)) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'salesman.home' })}</title>
            </Helmet>
            <div className={'salesman-home-page'} id={'salesman-home-page'}>
                <div className="member-dashboard">
                    <Card className={classNames('member-card')}>
                        <Card.Header>
                            <h3 className={'hv'}>
                                <FormattedMessage id="salesman.home" />
                            </h3>
                        </Card.Header>

                        <Card.Body>
                            <SalesmanMenu
                                onItemClick={handleOnItemClick}
                                className={'member-area-menu'}
                                exclude={[ISalesmanMenuItemType.Home]}
                                me={me}
                            />
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default SalesmanHomePage
