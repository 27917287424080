/*
 *
 * SideMenu constants
 *
 */

enum ActionTypes {
    OPEN_ACTION = 'app/SideMenu/OPEN_ACTION',
    CLOSE_ACTION = 'app/SideMenu/CLOSE_ACTION',
    TOGGLE_ACTION = 'app/SideMenu/TOGGLE_ACTION',
    STORE_ACTIVE_ID_ACTION = 'app/SideMenu/STORE_ACTIVE_ID',
    STORE_ITEMS_ACTION = 'app/SideMenu/STORE_ITEMS',
    RESET_ACTION = 'app/SideMenu/RESET',
}

export default ActionTypes
