/**
 *
 * InvoiceType
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { InvoiceDetailType } from '../../services/api/service/invoices/types'

interface IProps {
    type: InvoiceDetailType
}

function InvoiceType({ type }: IProps): JSX.Element {
    return (
        <span className={'invoice-type'}>
            <FormattedMessage id={`invoices.type.${type.toString()}`} />
        </span>
    )
}

InvoiceType.defaultProps = {} as Partial<IProps>

export default memo(InvoiceType)
