import classNames from 'classnames'
import React, { memo, useEffect } from 'react'
import { Alert, Card } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IMeYearlyStatEntries } from '../../../../services/api/service/stats/types'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'
import {
    statisticsYearlyStatsProcessAction,
    statisticsYearlyStatsResetAction,
} from '../../../../store/statistics/actions'
import {
    makeSelectYearlyStatisticsData,
    makeSelectYearlyStatisticsError,
    makeSelectYearlyStatisticsFetching,
} from '../../../../store/statistics/selectors'
import { MemberStatisticsContext } from '../../../../store/statistics/types'
import CONFIG from '../../../../config'

type IProps = {
    context?: MemberStatisticsContext
}

const stateSelector = createStructuredSelector<
    IApplicationRootState,
    {
        fetching: boolean
        entries?: IMeYearlyStatEntries
        error?: IAppErrorTypes
    }
>({
    entries: makeSelectYearlyStatisticsData(),
    fetching: makeSelectYearlyStatisticsFetching(),
    error: makeSelectYearlyStatisticsError(),
})

function Statistics({ context = MemberStatisticsContext.Customer }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatNumber, formatMessage } = useIntl()
    const { entries, error } = useSelector(stateSelector)

    useEffect(() => {
        dispatch(statisticsYearlyStatsProcessAction())
        return () => {
            dispatch(statisticsYearlyStatsResetAction())
        }
    }, [dispatch])

    return (
        <Card
            className={classNames(
                'member-card',
                'statistics-card',
                'statistics-data-card',
                'statistics-yearly-stats-card',
                `statistics-context-${context}`
            )}
        >
            <Card.Header>
                <h3 className={'hv'}>
                    <FormattedMessage id="default.my_turnover" />
                </h3>
            </Card.Header>
            <Card.Body>
                {error && <Alert variant="danger">{error?.message}</Alert>}
                {entries && (
                    <div className="list">
                        {entries.map((entry) => (
                            <div key={entry.year} className="card-stat">
                                <div className="card-body d-flex flex-column">
                                    <h6 className="title">{entry.year}</h6>
                                    <p className="value">
                                        {formatNumber(entry.total_amount, {
                                            style: 'currency',
                                            currency: CONFIG.I18N.DEFAULT_CURRENCY.CURRENCY,
                                            currencySign: 'standard',
                                            currencyDisplay: 'symbol',
                                            minimumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MINIMUM_FRACTION_DIGITS,
                                            maximumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MAXIMUM_FRACTION_DIGITS,
                                        }).replaceAll(' ', '   ')}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Card.Body>
        </Card>
    )
}

export default memo(Statistics)
