import React from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import LogoutButton from '../components/Auth/LogoutButton'
import Header from '../containers/Header/Header'

function LogoutPage(): JSX.Element {
    return (
        <>
            <Header />
            <Container>
                <Alert variant="info">
                    <Row>
                        <Col>
                            <p className="text-center">
                                <p>Logout</p>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <LogoutButton
                                variant="danger"
                                onClick={() => {
                                    console.log('LOGOUT')
                                }}
                            />
                        </Col>
                    </Row>
                </Alert>
            </Container>
        </>
    )
}

export default LogoutPage
