import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import PaymentModesActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { formatAppError } from '../app/saga'
import { paymentModesListFailureAction, paymentModesListSuccessAction } from './actions'
import { IApiPaymentModeListResponse } from '../../services/api/service/payment-modes/types'

function* processPaymentModesRequest() {
    const api: IApiClient = yield getContext('api')

    try {
        const response: IApiPaymentModeListResponse = yield call({ context: api.paymentModes, fn: 'list' }, {})
        yield put(paymentModesListSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'default.unknow_list_error')
        yield put(paymentModesListFailureAction(err))
    }
}

export default [takeLatest(PaymentModesActionTypes.LIST_PROCESS_ACTION, processPaymentModesRequest)]
