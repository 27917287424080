import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CartBanner, { IProps } from '../../components/CartBanner/CartBanner'
import { IShippingLocation } from '../../services/api/service/classification/types'
import { IApplicationRootState } from '../../store'
import { makeSelectShippingLocationById } from '../../store/classification/selectors'

type IItemProps = Omit<IProps, 'shippingLocation'>
function Item(props: IItemProps): JSX.Element {
    const selectShippingLocationWithId = useMemo(makeSelectShippingLocationById, [])
    const shippingLocation: IShippingLocation | null = useSelector<IApplicationRootState, IShippingLocation | null>(
        (state) => selectShippingLocationWithId(state, props.cart.shipping_location)
    )
    return <CartBanner {...props} shippingLocation={shippingLocation} />
}

Item.defaultProps = {} as Partial<IProps>

export default memo(Item)
