/*
 *
 * Products constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Statistics/RESET',
    TOP_SALES_PROCESS_ACTION = 'app/Statistics/TopSales/REQUEST_PROCESS',
    TOP_SALES_SUCCESS_ACTION = 'app/Statistics/TopSales/REQUEST_SUCCESS',
    TOP_SALES_FAILURE_ACTION = 'app/Statistics/TopSales/REQUEST_FAILURE',
    TOP_SALES_RESET_ACTION = 'app/Statistics/TopSales/RESET',
    TOP_PRODUCTS_PROCESS_ACTION = 'app/Statistics/TopProducts/REQUEST_PROCESS',
    TOP_PRODUCTS_SUCCESS_ACTION = 'app/Statistics/TopProducts/REQUEST_SUCCESS',
    TOP_PRODUCTS_FAILURE_ACTION = 'app/Statistics/TopProducts/REQUEST_FAILURE',
    TOP_PRODUCTS_RESET_ACTION = 'app/Statistics/TopProducts/RESET',
    CLASSIFICATION_RESET_ACTION = 'app/Statistics/Classification/RESET',
    CLASSIFICATION_PROCESS_ACTION = 'app/Statistics/Classification/REQUEST_PROCESS',
    CLASSIFICATION_SUCCESS_ACTION = 'app/Statistics/Classification/REQUEST_SUCCESS',
    CLASSIFICATION_FAILURE_ACTION = 'app/Statistics/Classification/REQUEST_FAILURE',
    YEARLY_STATS_PROCESS_ACTION = 'app/Statistics/YearlyStats/REQUEST_PROCESS',
    YEARLY_STATS_SUCCESS_ACTION = 'app/Statistics/YearlyStats/REQUEST_SUCCESS',
    YEARLY_STATS_FAILURE_ACTION = 'app/Statistics/YearlyStats/REQUEST_FAILURE',
    YEARLY_STATS_RESET_ACTION = 'app/Statistics/YearlyStats/RESET',
}

export default ActionTypes
