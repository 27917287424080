import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { default as Component } from '../containers/CmsPage/CmsPage'
import NotFound from '../containers/NotFound/NotFound'
import { routes } from '../routes'
import { CmsPageIdentifierType } from '../services/api/service/cms/types'

export default function CmsPagePreview(): JSX.Element {
    const location = useLocation()
    const matchPath1 = matchPath<{ lang?: string; cmsPageId?: string }>(location.pathname, {
        path: routes.cmsPagePreview.match,
        exact: true,
    })
    const cmsPageId = matchPath1?.params.cmsPageId
    return (
        <>
            {!cmsPageId && <NotFound />}
            {cmsPageId && (
                <Component identifier={cmsPageId} identifierType={CmsPageIdentifierType.Identifier} preview />
            )}
        </>
    )
}
