import { call, getContext, put, takeLatest } from 'redux-saga/effects'
// import AuthActionTypes from '../auth/constants'
import ConfigActionTypes from './constants'
import CustomerActionTypes from '../customers/constants'
import localforage from 'localforage'
import Config from '../../config'
import * as Sentry from '@sentry/react'
import { ISalesmanConfigSetCartAlertDisplayAction } from './types'
import { salesmanConfigSetCartAlertDisplay } from './actions'

function* processStoreSalesmanCartAlertDisplayed(action: ISalesmanConfigSetCartAlertDisplayAction) {
    try {
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'setItem' }, Config.SALESMAN.CART_ALERT_STORAGE_KEY, action.payload)
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }
}

function* processResetSalesmanConfiguration() {
    try {
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'removeItem' }, Config.SALESMAN.CART_ALERT_STORAGE_KEY)
        yield put(salesmanConfigSetCartAlertDisplay(false))
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }
}

export default [
    // takeLatest(AuthActionTypes.LOGOUT_ACTION, processResetSalesmanConfiguration),
    // takeLatest(CustomerActionTypes.LOGOUT_CURRENT_CUSTOMER_ACTION, processResetSalesmanConfiguration),
    takeLatest(CustomerActionTypes.RESET_ALL_ACTION, processResetSalesmanConfiguration),
    takeLatest(ConfigActionTypes.SET_SALESMAN_CART_ALERT_DISPLAY_ACTION, processStoreSalesmanCartAlertDisplayed),
]
