/*
 *
 * CartListEntry
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EntryModalType, IEntryProps } from './type'
import classNames from 'classnames'
import React, { MouseEvent, useCallback, useMemo, useState, forwardRef } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { default as ButtonApp } from '../../../components/Buttons/Button'
import { default as CartHeader } from '../../../components/Carts/Header'
import { IApplicationRootState } from '../../../store'
import { cleanCartsProcessAction } from '../../../store/carts/actions'
import { makeSelectCartCleanFetching } from '../../../store/carts/selectors'
import { cartHasErrors, isCartDeletable, isCartDuplicable, isCartExportable } from '../../../store/carts/utils'
import { findShippingLocationBy } from '../../../store/classification/utils'
import ExportModal from '../../Export/ExportModal'
import Cart from '../Cart'
import DuplicateModal from '../DuplicateModal'

const Entry = forwardRef<HTMLElement, IEntryProps>(
    (
        { cart, cartMode, orderMode, tree, customer, currentStore, number, onAccordionItemClick, onCartBackClick },
        ref
    ) => {
        const { formatMessage } = useIntl()
        const dispatch = useDispatch()

        const [showCartActionModal, setShowCartActionModal] = useState<EntryModalType | undefined>(undefined)
        const hasErrors = useMemo(() => {
            return cartHasErrors(cart)
        }, [cart])

        const shippingLocation = useMemo(() => {
            const res = findShippingLocationBy(tree, '@id', cart.shipping_location)
            return res ? res : undefined
        }, [cart, tree])

        const handleAccordionToggleClick = useCallback(() => {
            if (onAccordionItemClick) {
                onAccordionItemClick(cart, number, cart['@id'])
            }
        }, [onAccordionItemClick, cart, number])

        const handleExportClick = useCallback(
            (e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                setShowCartActionModal(EntryModalType.Export)
            },
            [setShowCartActionModal]
        )

        const handleCartCleanClick = useCallback(
            (e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                setShowCartActionModal(EntryModalType.Clean)
            },
            [setShowCartActionModal]
        )

        const handleExportModalExit = useCallback(() => {
            setShowCartActionModal(undefined)
        }, [setShowCartActionModal])

        const handleOnConfirmClean = useCallback(() => {
            dispatch(cleanCartsProcessAction([cart['@id']]))
            setShowCartActionModal(undefined)
        }, [cart, dispatch, setShowCartActionModal])

        const handleOnCancelClean = useCallback(() => {
            setShowCartActionModal(undefined)
        }, [setShowCartActionModal])

        const handleDuplicateClick = useCallback(
            (e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                setShowCartActionModal(EntryModalType.Duplicate)
            },
            [setShowCartActionModal]
        )

        const handleDuplicateModalExit = useCallback(() => {
            setShowCartActionModal(undefined)
        }, [setShowCartActionModal])

        const handleDuplicateModalProcessDone = useCallback(() => {
            setShowCartActionModal(undefined)
        }, [setShowCartActionModal])

        const selectCartCleanFetchingById = useMemo(makeSelectCartCleanFetching, [])
        const isCartCleanFetching: boolean = useSelector<IApplicationRootState, boolean>((state) =>
            selectCartCleanFetchingById(state, cart['@id'])
        )
        const isDeletable = useMemo(() => {
            return isCartDeletable(cart, customer, cartMode, false)
        }, [customer, cart, cartMode])
        const isCartCleanDisabled = useMemo(() => {
            return !isCartDeletable(cart, customer, cartMode, true) || isCartCleanFetching
        }, [customer, cart, cartMode, isCartCleanFetching])
        const isExportable = useMemo(() => {
            return isCartExportable(cart, customer, false)
        }, [customer, cart])
        const isCartExportDisabled = useMemo(() => {
            return !isCartExportable(cart, customer, true) || isCartCleanFetching
        }, [customer, cart, isCartCleanFetching])
        const isDuplicable = useMemo(() => {
            return isCartDuplicable(cart, customer, cartMode, false)
        }, [customer, cart, cartMode])
        const isCartDuplicateDisabled = useMemo(() => {
            return !isCartDuplicable(cart, customer, cartMode, true) || isCartCleanFetching
        }, [customer, cart, isCartCleanFetching, cartMode])

        return (
            <Card
                // @ts-ignore
                ref={ref}
                key={`card_${cart['@id']}`}
                id={`card_${cart['@id']}`}
                className={classNames('cart-card', { 'has-errors': hasErrors })}
            >
                <Card.Header onClick={handleAccordionToggleClick}>
                    <CartHeader
                        shippingLocation={shippingLocation}
                        number={number}
                        hasErrors={hasErrors}
                        orderMode={orderMode}
                    />
                    <div className={'cart-header-actions'}>
                        {isDuplicable && (
                            <ButtonApp
                                variant={'link'}
                                className={'btn-export'}
                                disabled={isCartDuplicateDisabled}
                                onClick={handleDuplicateClick}
                                title={formatMessage({ id: 'default.duplicate' })}
                            >
                                <FontAwesomeIcon icon={['fad', 'copy']} className={'app-icon'} />
                                <span className={'lbl'}>
                                    <FormattedMessage id={'default.duplicate'} />
                                </span>
                            </ButtonApp>
                        )}
                        {isExportable && (
                            <ButtonApp
                                variant={'link'}
                                className={'btn-export'}
                                disabled={isCartExportDisabled}
                                onClick={handleExportClick}
                                title={formatMessage({ id: 'default.export' })}
                            >
                                <FontAwesomeIcon icon={['fad', 'file-export']} className={'app-icon'} />
                                <span className={'lbl'}>
                                    <FormattedMessage id={'default.export'} />
                                </span>
                            </ButtonApp>
                        )}
                        {isDeletable && (
                            <ButtonApp
                                variant={'link'}
                                className={'btn-clean'}
                                loading={isCartCleanFetching}
                                disabled={isCartCleanDisabled}
                                onClick={handleCartCleanClick}
                                title={formatMessage({ id: 'carts.reset' })}
                            >
                                <FontAwesomeIcon icon={'trash'} className={'app-icon'} />
                                <span className={'lbl'}>
                                    <FormattedMessage id={'carts.reset'} />
                                </span>
                            </ButtonApp>
                        )}
                    </div>
                </Card.Header>
                <Accordion.Collapse eventKey={cart['@id']}>
                    <Card.Body>
                        <Cart
                            main={true}
                            index={number}
                            cartId={cart['@id']}
                            minimumAmountMode={customer.minimum_amount_mode}
                            onCartBackClick={onCartBackClick}
                        />
                    </Card.Body>
                </Accordion.Collapse>
                {isExportable && (
                    <ExportModal
                        resource={cart}
                        show={showCartActionModal === EntryModalType.Export}
                        onExportModalExit={handleExportModalExit}
                    />
                )}
                {isDuplicable && (
                    <DuplicateModal
                        cartId={cart['@id']}
                        show={showCartActionModal === EntryModalType.Duplicate}
                        customer={customer}
                        currentStore={currentStore}
                        shippingLocation={shippingLocation}
                        onDuplicateModalExit={handleDuplicateModalExit}
                        onDuplicateProcessDone={handleDuplicateModalProcessDone}
                    />
                )}
                <SweetAlert
                    warning
                    allowEscape
                    showConfirm
                    showCancel
                    cancelBtnText={formatMessage({
                        id: 'default.no',
                    })}
                    confirmBtnText={formatMessage({
                        id: 'default.yes',
                    })}
                    title={formatMessage({
                        id: 'carts.reset',
                    })}
                    show={showCartActionModal === EntryModalType.Clean}
                    onConfirm={handleOnConfirmClean}
                    onCancel={handleOnCancelClean}
                >
                    <FormattedMessage id={'carts.reset_confirm'} />
                </SweetAlert>
            </Card>
        )
    }
)

Entry.defaultProps = {
    number: 1,
} as Partial<IEntryProps>

export default Entry
