import { BadgeUrlCollection, ProductBadgeType } from '../../../../types/productBadge'
import ImageBadgeBest from '../../../../assets/img/picto_best.png'
import ImageBadgeNew from '../../../../assets/img/picto_new.png'
import ImageBadgeListing from '../../../../assets/img/picto_my_listing.png'
import ImageBadgeDiscount from '../../../../assets/img/picto_promo.png'
import ImageBadgeDecreasingPrice from '../../../../assets/img/picto_decreasing_price.png'

import ImageBadgeDiscount5 from '../../../../assets/img/promo/picto_promo_5.png'
import ImageBadgeDiscount10 from '../../../../assets/img/promo/picto_promo_10.png'
import ImageBadgeDiscount15 from '../../../../assets/img/promo/picto_promo_15.png'
import ImageBadgeDiscount20 from '../../../../assets/img/promo/picto_promo_20.png'
import ImageBadgeDiscount25 from '../../../../assets/img/promo/picto_promo_25.png'
import ImageBadgeDiscount30 from '../../../../assets/img/promo/picto_promo_30.png'
import ImageBadgeDiscount35 from '../../../../assets/img/promo/picto_promo_35.png'
import ImageBadgeDiscount40 from '../../../../assets/img/promo/picto_promo_40.png'
import ImageBadgeDiscount45 from '../../../../assets/img/promo/picto_promo_45.png'
import ImageBadgeDiscount50 from '../../../../assets/img/promo/picto_promo_50.png'
import ImageBadgeDiscount55 from '../../../../assets/img/promo/picto_promo_55.png'
import ImageBadgeDiscount60 from '../../../../assets/img/promo/picto_promo_60.png'
import ImageBadgeDiscount65 from '../../../../assets/img/promo/picto_promo_65.png'
import ImageBadgeDiscount70 from '../../../../assets/img/promo/picto_promo_70.png'

const images: BadgeUrlCollection = {
    [ProductBadgeType.BestB2c]: ImageBadgeBest,
    [ProductBadgeType.New]: ImageBadgeNew,
    [ProductBadgeType.MyListing]: ImageBadgeListing,
    [ProductBadgeType.Discount]: ImageBadgeDiscount,
    [ProductBadgeType.DecreasingPrice]: ImageBadgeDecreasingPrice,
    [`${ProductBadgeType.Discount}_5`]: ImageBadgeDiscount5,
    [`${ProductBadgeType.Discount}_10`]: ImageBadgeDiscount10,
    [`${ProductBadgeType.Discount}_15`]: ImageBadgeDiscount15,
    [`${ProductBadgeType.Discount}_20`]: ImageBadgeDiscount20,
    [`${ProductBadgeType.Discount}_25`]: ImageBadgeDiscount25,
    [`${ProductBadgeType.Discount}_30`]: ImageBadgeDiscount30,
    [`${ProductBadgeType.Discount}_35`]: ImageBadgeDiscount35,
    [`${ProductBadgeType.Discount}_40`]: ImageBadgeDiscount40,
    [`${ProductBadgeType.Discount}_45`]: ImageBadgeDiscount45,
    [`${ProductBadgeType.Discount}_50`]: ImageBadgeDiscount50,
    [`${ProductBadgeType.Discount}_55`]: ImageBadgeDiscount55,
    [`${ProductBadgeType.Discount}_60`]: ImageBadgeDiscount60,
    [`${ProductBadgeType.Discount}_65`]: ImageBadgeDiscount65,
    [`${ProductBadgeType.Discount}_70`]: ImageBadgeDiscount70,
}

export default images
