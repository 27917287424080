/**
 *
 * Range
 *
 */
import classNames from 'classnames'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import CONFIG from '../../config'

interface IProps {
    className?: string
    price: number | null | undefined
    currency?: string
    minimumFractionDigits?: number
    maximumFractionDigits?: number
}

function Price({ price, className, currency, minimumFractionDigits, maximumFractionDigits }: IProps): JSX.Element {
    return (
        <span className={classNames('price', className)}>
            {typeof price === 'number' && (
                <FormattedNumber
                    value={price}
                    style={'currency'}
                    currency={currency}
                    currencySign={'standard'}
                    currencyDisplay={'symbol'}
                    minimumFractionDigits={minimumFractionDigits}
                    maximumFractionDigits={maximumFractionDigits}
                />
            )}
            {typeof price !== 'number' && '-'}
        </span>
    )
}

Price.defaultProps = {
    currency: CONFIG.I18N.DEFAULT_CURRENCY.CURRENCY,
    minimumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MAXIMUM_FRACTION_DIGITS,
} as Partial<IProps>

export default Price
