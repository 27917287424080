import { IPickerAction } from '../../../../types/productPicker'
import PickerActionPicture from '../../../../containers/Products/Picker/Type/PickerActionPicture'

const pickerAction: IPickerAction = {
    icon: 'download',
    code: 'download_product_picture',
    label: 'product_picker.download_picture',
    options: {
        min: 1,
        max: 50,
    },
    components: {
        action: PickerActionPicture,
    },
}

export default pickerAction
