// FAVORITES
import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import {
    IStockAlertBulkFailurePayload,
    IStockAlertBulkPayload,
    IStockAlertBulkSuccessPayload,
    IStockAlertFailurePayload,
    IStockAlertPayload,
    IStockAlertSuccessPayload,
    StockAlertOrigin,
} from './types'
import { IAppErrorTypes } from '../app/types'

export const stockAlertProcessAction = (productId: string, subscribe: boolean, origin?: StockAlertOrigin) =>
    action<ActionTypes.STOCK_ALERT_PROCESS_ACTION, IStockAlertPayload>(ActionTypes.STOCK_ALERT_PROCESS_ACTION, {
        productId,
        origin,
        subscribe,
    })

export const stockAlertSuccessAction = (productId: string, subscribe: boolean, origin?: StockAlertOrigin) =>
    action<ActionTypes.STOCK_ALERT_SUCCESS_ACTION, IStockAlertSuccessPayload>(ActionTypes.STOCK_ALERT_SUCCESS_ACTION, {
        productId,
        origin,
        subscribe,
    })

export const stockAlertFailureAction = (
    error: IAppErrorTypes,
    productId: string,
    subscribe: boolean,
    origin?: StockAlertOrigin,
    subscriptionEnabled?: boolean
) =>
    action<ActionTypes.STOCK_ALERT_FAILURE_ACTION, IStockAlertFailurePayload>(ActionTypes.STOCK_ALERT_FAILURE_ACTION, {
        error,
        productId,
        origin,
        subscribe,
        subscriptionEnabled,
    })

export const stockAlertBulkProcessAction = (productIds: Array<string>, subscribe: boolean, origin?: StockAlertOrigin) =>
    action<ActionTypes.STOCK_ALERT_BULK_PROCESS_ACTION, IStockAlertBulkPayload>(
        ActionTypes.STOCK_ALERT_BULK_PROCESS_ACTION,
        {
            productIds,
            origin,
            subscribe,
        }
    )

export const stockAlertBulkSuccessAction = (productIds: Array<string>, subscribe: boolean, origin?: StockAlertOrigin) =>
    action<ActionTypes.STOCK_ALERT_BULK_SUCCESS_ACTION, IStockAlertBulkSuccessPayload>(
        ActionTypes.STOCK_ALERT_BULK_SUCCESS_ACTION,
        {
            productIds,
            origin,
            subscribe,
        }
    )

export const stockAlertBulkFailureAction = (
    error: IAppErrorTypes,
    productIds: Array<string>,
    subscribe: boolean,
    origin?: StockAlertOrigin
) =>
    action<ActionTypes.STOCK_ALERT_BULK_FAILURE_ACTION, IStockAlertBulkFailurePayload>(
        ActionTypes.STOCK_ALERT_BULK_FAILURE_ACTION,
        {
            error,
            productIds,
            origin,
            subscribe,
        }
    )
