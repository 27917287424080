import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerCarts from '../containers/CustomerCarts/Loadable'

export default function CustomerCartsPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.carts' })}</title>
            </Helmet>
            <div className={'customer-carts'} id={'customer-carts'}>
                <CustomerCarts />
            </div>
        </>
    )
}
