import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, RouteComponentProps } from 'react-router-dom'
import Config from '../config'
import LoginWindow from '../containers/LoginWindow/LoginWindow'
import { getPath } from '../routes'
import { isInternetExplorer } from '../utils/browser'

const locales: Array<string> = Config.I18N.LANGUAGES

function LoginPage(props: RouteComponentProps): JSX.Element {
    const { locale, formatMessage } = useIntl()

    return (
        <div className={classNames('login-window-wrapper', { 'is-ie': isInternetExplorer() })}>
            <Helmet>
                <title>{formatMessage({ id: 'seo.login.title' })}</title>
                <meta name="description" content={formatMessage({ id: 'seo.login.description' })} />
                <link rel="canonical" href={generatePath(getPath('login', locale), { lang: locale })} />
                {locales
                    .filter((lcl) => {
                        return lcl !== locale
                    })
                    .map((lcl) => {
                        return (
                            <link
                                rel="alternate"
                                href={generatePath(getPath('login', lcl), {
                                    lang: lcl,
                                })}
                                hrefLang={lcl}
                                key={lcl}
                            />
                        )
                    })}
                <body className={'login-page'} />
            </Helmet>
            <LoginWindow {...props} />
        </div>
    )
}

export default LoginPage
