import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { createSelector } from 'reselect'

const selectPaymentModesDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.paymentModes || initialState
}

const makeSelectPaymentModeListFetching = () =>
    createSelector(selectPaymentModesDomain, (substate) => {
        return substate.list.fetching
    })

const makeSelectPaymentModeListError = () =>
    createSelector(selectPaymentModesDomain, (substate) => {
        return substate.list.error
    })

const makeSelectPaymentModes = () =>
    createSelector(selectPaymentModesDomain, (substate) => {
        return substate.list.items
    })

export { makeSelectPaymentModeListFetching, makeSelectPaymentModeListError, makeSelectPaymentModes }
