/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IAttributeDefinitionCollection } from '../../../services/api/service/classification/types'
import { IProductAttributeCollection } from '../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectClassificationAttributeDefinition } from '../../../store/classification/selectors'
import { formatAttributeValue } from '../../../utils/productHelper'

interface IProps {
    allowEmptyValue: boolean
    attributes: IProductAttributeCollection
    attributekey: keyof IProductAttributeCollection
    className?: string
    parentAs: React.ElementType
    childAs: React.ElementType
}

const stateSelector = createStructuredSelector<any, any>({
    definition: makeSelectClassificationAttributeDefinition(),
})

function Attribute({ allowEmptyValue, attributes, attributekey, className, parentAs, childAs }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const { definition } = useSelector<IApplicationRootState, { definition: IAttributeDefinitionCollection }>(
        stateSelector
    )
    if ((typeof attributes[attributekey] === 'undefined' || attributes[attributekey] === null) && !allowEmptyValue) {
        return <></>
    }

    if (!definition) {
        return <></>
    }

    const reduced = definition.filter((s) => s.code === attributekey)
    const def = reduced ? reduced[0] : undefined
    if (!def) {
        return <></>
    }

    const formattedValue = formatAttributeValue(attributekey, attributes[attributekey], attributes, formatMessage)

    if (
        !allowEmptyValue &&
        (typeof formattedValue === 'undefined' ||
            formattedValue === null ||
            (typeof formattedValue === 'string' && formattedValue.length === 0))
    ) {
        return <></>
    }

    return React.createElement(
        parentAs || 'tr',
        {
            className: classNames('product-attribute', 'with-label', className),
        },
        [
            React.createElement(
                childAs || 'td',
                {
                    className: 'lbl',
                    key: `attribute_label_${attributekey}`,
                },
                def.name
            ),
            React.createElement(
                childAs || 'td',
                {
                    className: 'value',
                    key: `attribute_value_${attributekey}`,
                },
                formattedValue || '-'
            ),
        ]
    )
}

Attribute.defaultProps = {
    allowEmptyValue: false,
    parentAs: 'tr',
    childAs: 'td',
} as Partial<IProps>

export default memo(Attribute)
