import classNames from 'classnames'
import isNull from 'lodash/isNull'
// @ts-ignore
import moment from 'moment/min/moment-with-locales.min'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Undefinable } from 'tsdef'
import { $PropertyType, Writable } from 'utility-types'
import { IStatisticsFamilyTreeCollection } from '../../../../services/api/service/stats/types'
import { IApplicationRootState } from '../../../../store'
import { statisticsCustomerClassificationProcessAction } from '../../../../store/statistics/actions'
import {
    makeSelectStatisticsClassificationFamilyTreeFilters,
    makeSelectStatisticsClassificationFamilyTree,
    makeSelectStatisticsClassificationFamilyTreeFetching,
} from '../../../../store/statistics/selectors'
import {
    IMemberStatisticsClassificationFilters,
    IMemberStatisticsFilters,
    MemberStatisticsContext,
} from '../../../../store/statistics/types'
import { IMe } from '../../../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../../../store/auth/selectors'
import { canViewAllCustomers, isSalesmanResource } from '../../../../store/salesmens/utils'
import { useLocation } from 'react-router-dom'
import Qs from 'qs'
import { newDecoder } from '../../../../utils/qs'
import get from 'lodash/get'
import isString from 'lodash/isString'
import DateRange from './Partial/DateRange'
import FamilyTreeChoice from './Partial/FamilyTreeChoice'
import { objectEquals } from 'object-equals'
import { ISalesmanStatsMode } from '../../../../services/api/service/salesman/types'

const stateSelector = createStructuredSelector<
    IApplicationRootState,
    {
        treeFilters?: IMemberStatisticsClassificationFilters
        treeFetching: boolean
        tree: Undefinable<IStatisticsFamilyTreeCollection>
        me?: IMe
    }
>({
    tree: makeSelectStatisticsClassificationFamilyTree(),
    treeFilters: makeSelectStatisticsClassificationFamilyTreeFilters(),
    treeFetching: makeSelectStatisticsClassificationFamilyTreeFetching(),
    me: makeSelectAuthMe(),
})

type PortfolioCheckProps = {
    checked?: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const PortfolioCheck = ({ onChange, checked = true }: PortfolioCheckProps) => {
    const { formatMessage } = useIntl()

    return (
        <Form.Check
            type={'switch'}
            id={'salesman-portfolio-only'}
            label={formatMessage({ id: 'salesman.my_portfolio_only' })}
            checked={checked}
            onChange={onChange}
            value={1}
        />
    )
}

const MyActivityOnlyCheck = ({ onChange, checked = true }: PortfolioCheckProps) => {
    const { formatMessage } = useIntl()

    return (
        <Form.Check
            type={'switch'}
            id={'salesman-my-activity-only'}
            label={formatMessage({ id: 'salesman.my_activity_only' })}
            checked={checked}
            onChange={onChange}
            value={1}
        />
    )
}

type IProps = {
    onChange: (filters?: IMemberStatisticsFilters) => void
    maxDuration?: number
    context?: MemberStatisticsContext
}

function StatisticsFilters({
    onChange,
    maxDuration = 12,
    context = MemberStatisticsContext.Customer,
}: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { search } = useLocation()
    const { tree, me, treeFetching, treeFilters } = useSelector(stateSelector)
    const salesmanCanViewAllCustomers = useMemo(() => {
        return canViewAllCustomers(me) && context === MemberStatisticsContext.Salesman
    }, [me, context])
    const myActivityOnlyFilterAllowed = useMemo(() => {
        return (
            isSalesmanResource(me) &&
            me.stats_mode === ISalesmanStatsMode.Both &&
            (context === MemberStatisticsContext.Customer ||
                (context === MemberStatisticsContext.Salesman && !salesmanCanViewAllCustomers))
        )
    }, [context, me, salesmanCanViewAllCustomers])

    const queries = useMemo(() => {
        // @ts-ignore
        return Qs.parse(search.substring(1), {
            ignoreQueryPrefix: true,
            arrayFormat: 'bracket',
            decoder: newDecoder(),
        })
    }, [search])

    const initialStartDate = useMemo(() => {
        const queryDate = get(queries, 'criteria.filters.from')
        if (isString(queryDate) && queryDate.length) {
            return moment(queryDate, 'YYYY-MM').toDate()
        }

        return moment()
            .subtract(maxDuration - 1, 'months')
            .startOf('month')
            .toDate()
    }, [maxDuration, queries])

    const initialEndDate = useMemo(() => {
        const queryDate = get(queries, 'criteria.filters.to')
        if (isString(queryDate) && queryDate.length) {
            return moment(queryDate, 'YYYY-MM').toDate()
        }

        return moment().endOf('month').toDate()
    }, [queries])

    const sectionTitle = useMemo(() => {
        if (isSalesmanResource(me)) {
            return formatMessage({ id: 'default.my_sales_statistics' })
        }
        return formatMessage({ id: 'default.my_buying_statistics' })
    }, [me, formatMessage])

    const [filters, setFilters] = useState<Writable<IMemberStatisticsFilters>>(() => {
        const values: Writable<IMemberStatisticsFilters> = {
            classification: {
                departments: get(queries, 'criteria.filters.departments', []) as Array<string>,
                families: get(queries, 'criteria.filters.families', []) as Array<string>,
                subFamilies: get(queries, 'criteria.filters.sub_families', []) as Array<string>,
            },
            from: initialStartDate,
            to: initialEndDate,
        }

        if (salesmanCanViewAllCustomers) {
            values['all_customers'] = get(queries, 'criteria.filters.all_customers', 0) as 1 | 0
        }

        if (myActivityOnlyFilterAllowed) {
            values['all_activity'] = get(queries, 'criteria.filters.all_activity', 0) as 1 | 0
        }

        return values
    })

    const refreshClassification = useCallback(
        (classificationFilters: IMemberStatisticsFilters) => {
            const fltrs = { ...classificationFilters }
            // @ts-ignore
            delete fltrs['classification']

            if (objectEquals(fltrs, treeFilters)) {
                return
            }

            dispatch(statisticsCustomerClassificationProcessAction(fltrs))
        },
        [dispatch, treeFilters]
    )

    const handlePortfolioChange = useCallback((e) => {
        setFilters((currentFilters) => {
            const fltrs = { ...currentFilters }

            if (!e.target.checked) {
                fltrs['all_customers'] = 1
            } else {
                fltrs['all_customers'] = 0
            }

            return fltrs
        })
    }, [])

    const handleMyActivityChange = useCallback((e) => {
        setFilters((currentFilters) => {
            const fltrs = { ...currentFilters }

            if (!e.target.checked) {
                fltrs['all_activity'] = 1
            } else {
                fltrs['all_activity'] = 0
            }

            return fltrs
        })
    }, [])

    const handleFamilyTreeChange = useCallback(
        (classification: $PropertyType<IMemberStatisticsFilters, 'classification'>) => {
            setFilters((state) => {
                return {
                    ...state,
                    classification: {
                        ...state.classification,
                        ...classification,
                    },
                }
            })
        },
        [setFilters]
    )

    const handlePeriodChange = useCallback((startDate, endDate) => {
        if (isNull(startDate) || isNull(endDate)) {
            setFilters((currentFilters) => {
                const fltrs = { ...currentFilters }
                // @ts-ignore
                delete fltrs['from']
                // @ts-ignore
                delete fltrs['to']

                return fltrs
            })
            return
        }
        setFilters((currentFilters) => {
            return {
                ...currentFilters,
                classification: {
                    departments: [],
                    families: [],
                    subFamilies: [],
                },
                from: startDate,
                to: endDate,
            }
        })
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(filters)
        }
    }, [filters, onChange])

    useEffect(() => {
        refreshClassification(filters)
    }, [filters, refreshClassification])

    return (
        <Card
            className={classNames(
                'member-card',
                'statistics-card',
                'statistics-filters',
                `statistics-context-${context}`
            )}
        >
            <Card.Header>
                <h3 className={'hv'}>{sectionTitle}</h3>
            </Card.Header>
            <Card.Body>
                <Form>
                    {salesmanCanViewAllCustomers && (
                        <div className="form-group">
                            <PortfolioCheck onChange={handlePortfolioChange} checked={filters?.all_customers === 0} />
                        </div>
                    )}
                    {myActivityOnlyFilterAllowed && (
                        <div className="form-group">
                            <MyActivityOnlyCheck
                                onChange={handleMyActivityChange}
                                checked={filters?.all_activity === 0}
                            />
                        </div>
                    )}
                    <div className="form-inline">
                        <DateRange
                            onPeriodChange={handlePeriodChange}
                            valueFrom={filters?.from}
                            valueTo={filters?.to}
                            maxDuration={maxDuration}
                        />
                        <FamilyTreeChoice
                            familyTree={tree}
                            onChange={handleFamilyTreeChange}
                            disabled={treeFetching}
                            value={filters?.classification}
                        />
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default StatisticsFilters
