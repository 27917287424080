/*
 *
 * PaymentModes constants
 *
 */

enum ActionTypes {
    LIST_PROCESS_ACTION = 'app/PaymentModes/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/PaymentModes/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/PaymentModes/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/PaymentModes/List/RESET',
    RESET_ACTION = 'app/PaymentModes/RESET',
}

export default ActionTypes
