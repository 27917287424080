import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CustomerDashboard from '../containers/CustomerDashboard/Loadable'
import { ICustomer } from '../services/api/service/customers/types'
import { IApplicationRootState } from '../store'
import { makeSelectCustomer, makeSelectCustomerStore } from '../store/customers/selectors'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
})

function CustomerDashboardPage(): JSX.Element {
    // const { formatMessage } = useIntl()
    const { formatMessage } = useIntl()
    const { customer } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
            store?: ICustomer
        }
    >(stateSelector)

    if (!customer) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.dashboard' })}</title>
            </Helmet>
            <div className={'customer-dashboard-page'} id={'customer-dashboard-page'}>
                <CustomerDashboard />
            </div>
        </>
    )
}

export default CustomerDashboardPage
