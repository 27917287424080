import { IPickerAction } from '../../../../types/productPicker'
import PickerActionArrivals from '../../../../containers/Products/Picker/Type/PickerActionArrivals'
import ActionTypes from '../../../../store/stockAlert/constants'

const pickerAction: IPickerAction = {
    icon: 'trolley',
    code: 'remove_arrival',
    label: 'product_picker.remove_bulk_arrival',
    options: {
        min: 1,
    },
    redux: {
        type: ActionTypes.STOCK_ALERT_BULK_PROCESS_ACTION,
        paramKeys: ['productIds', 'subscribe', 'origin'],
    },
    components: {
        action: PickerActionArrivals,
    },
}

export default pickerAction
