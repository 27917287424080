/**
 *
 * Cart
 *
 */
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Col, Form, Row, Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CartItem from '../../components/Carts/CartItem'
import CartMinimumAmountAlert from '../../components/Carts/CartMinimumAmountAlert'
import { ICartProps } from '../../components/Carts/type'
import Price from '../../components/Price/Price'
import { Packing } from '../../components/Product/Partial'
import { ICart, ICartItem, ICartItemCollection, ICartMode } from '../../services/api/service/carts/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import { IStoreQuantityProduct } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { addToCartProcessAction, removeToCartProcessAction } from '../../store/carts/actions'
import {
    makeSelectCartById,
    makeSelectCartFetching,
    makeSelectCartMode,
    makeSelectErrorsByProductId,
} from '../../store/carts/selectors'
import { isQuantitySelectorMultipleEnabled } from '../../store/carts/utils'
import { makeSelectCustomer } from '../../store/customers/selectors'
import {
    customerShowFilters,
    customerShowPrices,
    findCustomerStoreBy,
    isShowProductCustomerInfo,
} from '../../store/customers/utils'
import { CartBackClickCallback } from '../../types/cartCallback'
import { CollectionMap } from '../../types/common'
import CreateOrder from '../CreateOrder/CreateOrder'
import StoreQuantity from '../StoreQuantity/StoreQuantity'

import { stockAlertProcessAction } from '../../store/stockAlert/actions'
import { StrictCartMode } from '../../store/carts/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import FuzzySearch from 'fuzzy-search'
import flow from 'lodash/flow'
import ContainerVolumes from '../../components/ContainerVolume/ContainerVolumes'
import isNumber from 'lodash/isNumber'
import { useHistory, useLocation } from 'react-router-dom'
import { generateProductUrl } from '../../utils/productHelper'
import EcoTax from '../../components/Product/Partial/EcoTax'

interface IProps extends ICartProps {
    main: boolean
    showSaveToOrderButton?: boolean
    showContainerVolumes?: boolean
    cartParentId?: string
    storeId?: string
    minInputLength?: number
    throttle?: number
    onCartBackClick?: CartBackClickCallback
    minimumAmountMode: ICustomerMinimumAmountMode
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    fetchingItems: makeSelectCartFetching(),
    cartMode: makeSelectCartMode(),
})

function Cart({
    main,
    index,
    cartId,
    cartParentId,
    storeId,
    onCartBackClick,
    showSaveToOrderButton,
    showContainerVolumes,
    throttle,
    minInputLength,
}: IProps): JSX.Element {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const { formatMessage, locale } = useIntl()
    const [keywords, setKeywords] = useState<string>('')
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)
    const { customer, fetchingItems, cartMode, me } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            fetchingItems: CollectionMap<boolean>
            cartMode: StrictCartMode
            me: IMe
        }
    >(stateSelector)

    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    const showPacking = useMemo(() => {
        return customer?.order_by_unit === false
    }, [customer])

    const showFilters = useMemo(() => {
        return customerShowFilters(customer, me)
    }, [customer, me])

    // récupération des paniers
    const selectCartById = useMemo(makeSelectCartById, [])
    const cart: ICart = useSelector<IApplicationRootState, ICart | null>((state) =>
        selectCartById(state, cartId)
    ) as ICart
    const cartParent: ICart | null = useSelector<IApplicationRootState, ICart | null>((state) =>
        selectCartById(state, cartParentId)
    )
    const cartQuantityItems = useSelector<IApplicationRootState, CollectionMap<number>>((state) => {
        const quantityItems = main ? state.carts.items.main.quantities : state.carts.items.sub.quantities
        if (!quantityItems) {
            return {}
        }
        if (quantityItems[cartId]) {
            return quantityItems[cartId]
        }
        return {}
    })

    // récupération du nombre total d'éléments (sans filtre)
    const realTotalItems = useMemo(() => {
        return cart.items ? Object.values(cart.items).length : 0
    }, [cart])

    // définition du state pour le filtre
    const [filteredCartItems, setFilteredCartItems] = useState<ICartItemCollection>(cart?.items || {})

    // définition du fuzzy search
    const fuzzySearch = useMemo(() => {
        const items = cart && cart.items ? Object.values(cart.items) : []
        return new FuzzySearch(items, ['product.name', 'product.reference', 'product.ean13'], {
            caseSensitive: false,
        })
    }, [cart])

    useEffect(() => {
        if (keywords.length === 0) {
            setFilteredCartItems(cart.items || {})
            return
        }

        // filters !
        const results = fuzzySearch.search(keywords)
        // si on a rien, on n'affiche rien
        if (results.length === 0) {
            setFilteredCartItems({})
            return
        }
        // sinon on extrait les ids et on les retrouves dans le panier
        const itemsIds = results.map((result) => result['@id'])
        setFilteredCartItems(
            flow([
                Object.entries,
                (arr) => arr.filter(([, value]) => itemsIds.indexOf(value['@id']) > -1),
                Object.fromEntries,
            ])(cart.items)
        )
    }, [cart, keywords, fuzzySearch, setFilteredCartItems])

    // écouteur !
    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.currentTarget.value

            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }

            const timeoutId = setTimeout(() => {
                if (inputValue.length < (minInputLength || 0)) {
                    // reset
                    setKeywords('')
                    return
                }

                setKeywords(inputValue)
            }, throttle)
            setChangeTimeoutId(timeoutId)
        },
        [changeTimeoutId, setChangeTimeoutId, throttle, minInputLength, setKeywords]
    )

    // edition multiple
    const store = useMemo(() => {
        return storeId ? findCustomerStoreBy(customer, 'id', storeId) : undefined
    }, [storeId, customer])

    const quantityMultiple = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])
    const [editingQuantityMultipleItem, setEditingQuantityMultipleItem] = useState<IStoreQuantityProduct | undefined>(
        undefined
    )

    // on affiche ou pas le customer info
    const showProductCustomerInfo: boolean = useMemo(() => {
        return !isSalesmanResource(me) && isShowProductCustomerInfo(customer)
    }, [me, customer])

    const handleOnItemQuantityChange = useCallback(
        (cartItem: ICartItem, quantity: number, quantityPrev: number) => {
            const origin = cartParentId ? 'sub_cart' : 'cart'
            if (quantity > 0) {
                dispatch(addToCartProcessAction(cartItem.product.id, quantity, quantityPrev, origin, storeId, cartId))
            } else {
                dispatch(
                    removeToCartProcessAction(cartItem.product.id, quantity, quantityPrev, origin, storeId, cartId)
                )
            }
        },
        [dispatch, cartId, cartParentId, storeId]
    )

    const handleItemImageClick = useCallback(
        (cartItem: ICartItem) => {
            const finalUrl = generateProductUrl(cartItem.product.id, locale)
            history.replace(finalUrl, { background: location })
        },
        [history, locale, location]
    )

    const handleAlertClick = useCallback(() => {
        if (onCartBackClick) {
            onCartBackClick(cart)
        }
    }, [onCartBackClick, cart])

    const handleOnItemQuantityMultipleAsked = useCallback(
        (cartItem: ICartItem) => {
            const p: IStoreQuantityProduct = {
                ...cartItem.product,
                shipping_location: cart.shipping_location,
                price: cartItem.unit_price === null ? undefined : cartItem.unit_price,
            }
            setEditingQuantityMultipleItem(p)
        },
        [cart]
    )

    const handleRestockAlertSubscriptionChange = useCallback(
        (cartItem: ICartItem, subscribe: boolean) => {
            dispatch(stockAlertProcessAction(cartItem.product.id, subscribe))
        },
        [dispatch]
    )

    const handleQuantityMultipleStoreModalExit = useCallback(() => {
        setEditingQuantityMultipleItem(undefined)
    }, [setEditingQuantityMultipleItem])

    const selectItemErrorsWithId = useMemo(makeSelectErrorsByProductId, [])
    const editingQuantityMultipleItemErrors: Array<string> | undefined = useSelector<
        IApplicationRootState,
        Array<string> | undefined
    >((state) => selectItemErrorsWithId(state, editingQuantityMultipleItem?.id, true))

    if (realTotalItems === 0) {
        return (
            <div className={'single-cart'}>
                <Alert variant={'warning'}>
                    <FormattedMessage id={'carts.empty'} values={{ total: 1 }} />
                </Alert>
            </div>
        )
    }

    return (
        <div
            data-id={cart['@id']}
            className={classNames('single-cart', { 'main-cart': main }, { 'sub-cart': !main })}
            id={`single-cart-${main ? 'main' : 'sub'}-${index}`}
        >
            {cart.locked && customer['@id'] === cart.customer && (
                <Alert variant="warning" className="alert-cart-locked">
                    <FontAwesomeIcon icon={['fal', 'lock']} className={'app-icon locked-icon'} />
                    <span>
                        <FormattedMessage id="cart.your_cart_locked_explain" />
                    </span>
                </Alert>
            )}
            {showContainerVolumes && isNumber(cart.total_volume) && (
                <ContainerVolumes
                    uniqId={`${cart['@id']}_top`}
                    totalVolume={cart.total_volume}
                    sm={12}
                    lg={8}
                    className="top"
                />
            )}
            <div className={'cart-table single-cart-table'}>
                <div className={'throw head'}>
                    <div className={'cell product'}>
                        <FormattedMessage
                            id={'products.plural'}
                            values={{ total: Object.keys(filteredCartItems).length }}
                        />
                    </div>
                    {!main && (
                        <div className={'cell quantity store-quantity'}>
                            <FormattedMessage
                                id={'cart.store_quantity'}
                                values={{ total: Object.keys(filteredCartItems).length }}
                            />
                        </div>
                    )}
                    <div className={'cell quantity item-quantity'}>
                        <FormattedMessage
                            id={main ? 'cart.quantity' : 'cart.total_quantity'}
                            values={{ total: Object.keys(filteredCartItems).length }}
                        />
                    </div>
                    {showPacking && (
                        <div className={'cell packing'}>
                            <FormattedMessage id={'cart.packing'} />
                        </div>
                    )}
                    {showPrices && (
                        <>
                            <div className={'cell price unit-price'}>
                                <FormattedMessage id={'cart.price_excluding_taxes'} />
                            </div>
                            <div className={'cell price total-price'}>
                                <FormattedMessage id={'cart.total_price_excluding_taxes'} />
                            </div>
                        </>
                    )}
                </div>
                {showFilters && (
                    <div className={'throw head filters'}>
                        <div className={'cell product'}>
                            <Form.Control
                                type="text"
                                placeholder={formatMessage({ id: 'cart.filter_list_placeholder' })}
                                onChange={handleOnChange}
                            />
                        </div>
                        {!main && <div className={'cell quantity store-quantity'} />}
                        <div className={'cell quantity item-quantity'} />
                        <div className={'cell packing'} />
                        {showPrices && (
                            <>
                                <div className={'cell price unit-price'} />
                                <div className={'cell price total-price'} />
                            </>
                        )}
                    </div>
                )}
                {realTotalItems > 0 && Object.keys(filteredCartItems).length === 0 && (
                    <Alert variant={'warning'} className="inner-alert">
                        <FormattedMessage id={'default.no_searched_results'} />
                    </Alert>
                )}
                {Object.keys(filteredCartItems).map((productId: string) => {
                    const cartItem = filteredCartItems[productId]
                    const cartItemParent =
                        cartParent && cartParent.items && cartParent.items[productId]
                            ? cartParent.items[productId]
                            : undefined
                    return (
                        <CartItem
                            key={cartItem['@id']}
                            className={'throw'}
                            classNameSection={'cell'}
                            item={cartItem}
                            itemParent={cartItemParent}
                            isMainCart={main}
                            showPrices={showPrices}
                            quantitySaving={fetchingItems[productId] || false}
                            quantityMultiple={quantityMultiple}
                            quantityValue={cartQuantityItems[productId] || 0}
                            quantityLocked={cart.locked && cart.customer === customer['@id']}
                            onQuantityChange={handleOnItemQuantityChange}
                            onImageClick={handleItemImageClick}
                            onQuantityMultipleAsked={handleOnItemQuantityMultipleAsked}
                            onRestockAlertSubscriptionChange={handleRestockAlertSubscriptionChange}
                            showProductCustomerInfo={showProductCustomerInfo}
                        />
                    )
                })}
                <div className={'cart-totals'}>
                    <Table size="sm">
                        <tbody>
                            <tr className={'cart-totals-quantity store-quantity'}>
                                <th>
                                    <FormattedMessage id={'cart.total'} />
                                </th>
                                <td>{cart.total_quantity}</td>
                            </tr>
                            {showPacking && (
                                <tr className={'cart-totals-packing item-quantity'}>
                                    <th>
                                        <FormattedMessage id={'product.packing'} />
                                    </th>
                                    <td>
                                        <Packing packing={cart.package_count} showLabel={false} showIcon />
                                    </td>
                                </tr>
                            )}
                            {showPrices && (
                                <tr className={'cart-totals-price'}>
                                    <th>
                                        <FormattedMessage id={'cart.total_price_excluding_taxes'} />
                                    </th>
                                    <td>
                                        <Price price={cart.total} />
                                        <EcoTax
                                            ecoTax={cart.total_eco_tax || 0}
                                            as="div"
                                            className="total-eco-tax"
                                            labelMessageKey="price.eco_tax_abbr"
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                <div className={'throw footer'}>
                    <div className={'cell product'}>
                        <FormattedMessage id={'cart.total'} />
                    </div>
                    <div className={'cell quantity'}>{cart.total_quantity}</div>
                    {!main && <div className={'cell quantity'}>{cartParent?.total_quantity}</div>}
                    <div className={'cell packing'}>
                        <Packing packing={cart.package_count} showLabel={false} showIcon />
                    </div>
                    {showPrices && (
                        <>
                            <div className={'cell price unit-price'} />
                            <div className={'cell price total-price'}>
                                <Price price={cart.total} />
                                <EcoTax
                                    ecoTax={cart.total_eco_tax || 0}
                                    as="div"
                                    className="total-eco-tax"
                                    labelMessageKey="price.eco_tax_abbr"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showContainerVolumes && isNumber(cart.total_volume) && (
                <ContainerVolumes
                    uniqId={`${cart['@id']}_bottom`}
                    totalVolume={cart.total_volume}
                    sm={12}
                    lg={8}
                    className="bottom"
                />
            )}
            <div className={'single-cart-footer'}>
                <Row noGutters>
                    <Col
                        xs={24}
                        lg={showSaveToOrderButton ? 16 : 24}
                        xl={showSaveToOrderButton ? 18 : 24}
                        className={'col-alert'}
                    >
                        <CartMinimumAmountAlert
                            cart={cart}
                            cartMode={store ? ICartMode.Default : cartMode}
                            customer={customer}
                            store={store}
                            minimumAmountMode={customer.minimum_amount_mode}
                            abbreviate={false}
                            onClick={handleAlertClick}
                        />
                    </Col>
                    {showSaveToOrderButton && (
                        <Col xs={24} lg={showSaveToOrderButton ? 8 : 24} xl={showSaveToOrderButton ? 6 : 24}>
                            <CreateOrder
                                mainCart={false}
                                customer={customer}
                                minimumAmountMode={customer.minimum_amount_mode}
                                cartId={cart['@id']}
                            />
                        </Col>
                    )}
                </Row>
            </div>
            {editingQuantityMultipleItem && (
                <StoreQuantity
                    product={editingQuantityMultipleItem}
                    onStoreQuantityExit={handleQuantityMultipleStoreModalExit}
                    errors={editingQuantityMultipleItemErrors}
                />
            )}
        </div>
    )
}

Cart.defaultProps = {
    index: 0,
    quantityMultiple: false,
    showProductCustomerInfo: true,
    showSaveToOrderButton: false,
    main: true,
    throttle: 150,
    minInputLength: 3,
} as Partial<IProps>

export default Cart
