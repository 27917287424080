/**
 *
 * Product Card
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames, { Value as ClassValue } from 'classnames'

interface IProps {
    className?: ClassValue
    ean13: string
    showLabel: boolean
}

function Ean({ ean13, showLabel, className }: IProps): JSX.Element {
    return (
        <div className={classNames(className, 'product-field', 'product-field-ean', { 'with-label': showLabel })}>
            {showLabel && (
                <span className={'lbl'}>
                    <FormattedMessage id={'product.ean'} />
                </span>
            )}
            <span className={classNames('value', 'product-ean')}>{ean13}</span>
        </div>
    )
}

Ean.defaultProps = {
    labelled: false,
} as Partial<IProps>

export default memo(Ean)
