/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, ReactNode } from 'react'
import { LazyImage } from 'react-lazy-images'
import { IBaseProduct } from '../../../services/api/service/products/types'
import { OptionalExceptFor } from '../../../types/common'
import { ProductImageClickCallback } from '../../../types/productCallback'
export type MainPictureProps = {
    product: OptionalExceptFor<IBaseProduct, 'name' | '@id' | 'id'>
    className?: string
    loadEagerly: boolean
    children?: ReactNode
    onImageClick?: ProductImageClickCallback
}

function CardImage({ product, className, loadEagerly, ...props }: MainPictureProps): JSX.Element {
    const handleOnClickImage = (event: MouseEvent) => {
        if (!(event.currentTarget instanceof HTMLImageElement)) {
            return
        }

        if (props.onImageClick) {
            props.onImageClick(product, parseInt(event.currentTarget.dataset.index!))
        }
    }

    return (
        <div className={classNames('product-image', 'product-image-list', className)}>
            <div className={'product-image-container'}>
                <div className={'product-image-inner'}>
                    {(!product.main_pictures || product.main_pictures.length === 0) && product.main_picture && (
                        <LazyImage
                            loadEagerly={loadEagerly}
                            debounceDurationMs={200}
                            src={product.main_picture?.list}
                            alt={product.name}
                            placeholder={({ imageProps, ref }) => (
                                <img
                                    {...imageProps}
                                    className={'placeholder'}
                                    ref={ref}
                                    alt={product.name}
                                    src={product.main_picture?.placeholder}
                                    onClick={handleOnClickImage}
                                />
                            )}
                            actual={({ imageProps }) => (
                                <>
                                    <img
                                        {...imageProps}
                                        alt={product.name}
                                        className={'picture'}
                                        onClick={handleOnClickImage}
                                    />
                                </>
                            )}
                        />
                    )}
                    {product.main_pictures && product.main_pictures.length > 0 && (
                        <LazyImage
                            loadEagerly={loadEagerly}
                            debounceDurationMs={200}
                            src={product.main_pictures[0].list}
                            alt={product.name}
                            placeholder={({ imageProps, ref }) => (
                                <img
                                    {...imageProps}
                                    className={'placeholder'}
                                    ref={ref}
                                    alt={product.name}
                                    src={product.main_pictures![0].placeholder}
                                    data-index={0}
                                    onClick={handleOnClickImage}
                                />
                            )}
                            actual={({ imageProps }) => (
                                <>
                                    <img
                                        {...imageProps}
                                        alt={product.name}
                                        className={'picture'}
                                        data-index={0}
                                        onClick={handleOnClickImage}
                                    />
                                </>
                            )}
                        />
                    )}
                </div>
                {props.children}
            </div>
        </div>
    )
}

CardImage.defaultProps = {
    loadEagerly: false,
} as Partial<MainPictureProps>

const areEqual = (prevProps: MainPictureProps, nextProps: MainPictureProps) => {
    return (
        prevProps.product['@id'] === nextProps.product['@id'] &&
        prevProps.onImageClick === nextProps.onImageClick &&
        prevProps.children === nextProps.children
    )
}

export default memo(CardImage, areEqual)
