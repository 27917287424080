import { Switch, withRouter } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cartsBannerHideAction } from '../../store/carts/actions'
import { getRouteMatch } from '../utils'
import LoggedInRoute from '../LoggedInRoute'
import SalesmanDashboardPage from '../../pages/SalesmanDashboardPage'
import Layout from '../../pages/Layout/Layout'
import SalesmanLayout from '../../pages/Layout/SalesmanLayout'
import SalesmanCustomersPage from '../../pages/SalesmanCustomersPage'
import SalesmanProspectsPage from '../../pages/SalesmanProspectsPage'
import SalesmanOrdersPage from '../../pages/SalesmanOrdersPage'
import { IOrderType } from '../../services/api/service/orders/types'
import SalesmanOrderPage from '../../pages/SalesmanOrderPage'
import SalesmanHomePage from '../../pages/SalesmanHomePage'
import SalesmanCartsPage from '../../pages/SalesmanCartsPage'
import SalesmanInvoicesPage from '../../pages/SalesmanInvoicesPage'

function SalesmanArea(): JSX.Element {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cartsBannerHideAction())
    })

    return (
        <Layout>
            <SalesmanLayout>
                <Switch>
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanArea')}
                        key={'salesmanArea'}
                        component={SalesmanHomePage}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanDashboard')}
                        key={'salesmanDashboard'}
                        component={SalesmanDashboardPage}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanProspects')}
                        key={'salesmanProspects'}
                        component={SalesmanProspectsPage}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanCustomers')}
                        key={'salesmanCustomers'}
                        component={SalesmanCustomersPage}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanOrders')}
                        key={'salesmanOrders'}
                        component={SalesmanOrdersPage}
                        componentProps={{ type: IOrderType.Order }}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanAssets')}
                        key={'salesmanAssets'}
                        component={SalesmanOrdersPage}
                        componentProps={{ type: IOrderType.Asset }}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanOrder')}
                        key={'salesmanOrder'}
                        component={SalesmanOrderPage}
                        componentProps={{ type: IOrderType.Order }}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanAsset')}
                        key={'salesmanAsset'}
                        component={SalesmanOrderPage}
                        componentProps={{ type: IOrderType.Asset }}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanInvoices')}
                        key={'salesmanInvoices'}
                        component={SalesmanInvoicesPage}
                    />
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('salesmanCarts')}
                        key={'salesmanCarts'}
                        component={SalesmanCartsPage}
                    />
                </Switch>
            </SalesmanLayout>
        </Layout>
    )
}

export default withRouter(SalesmanArea)
