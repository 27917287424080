import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { default as Component } from '../containers/CmsPage/CmsPage'
import NotFound from '../containers/NotFound/NotFound'
import { routes } from '../routes'
import { CmsPageIdentifierType } from '../services/api/service/cms/types'

export default function CmsPage(): JSX.Element {
    const location = useLocation()
    const matchPath1 = matchPath<{ lang?: string; slug?: string }>(location.pathname, {
        path: routes.cmsPage.match,
        exact: true,
    })
    const slug = matchPath1?.params.slug

    return (
        <>
            {!slug && <NotFound />}
            {slug && <Component identifier={`/${slug}`} identifierType={CmsPageIdentifierType.Slug} />}
        </>
    )
}
