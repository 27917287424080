import React, { memo, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { IOrderList } from '../../../services/api/service/orders/types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExportModal from '../../Export/ExportModal'
import { OrderDetailAction } from './type'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import isUndefined from 'lodash/isUndefined'
import { Nullable } from 'tsdef'
import isNull from 'lodash/isNull'

type Props<T extends IOrderList = IOrderList> = OrderDetailAction & {
    tooltip?: string | null
    order: T
    onClick?: (e: MouseEvent<HTMLButtonElement>, order: T) => void
}

const Export = ({ order, onClick, icon, label, size, block, tooltip, variant = 'link' }: Props): JSX.Element => {
    const targetBtn = useRef<HTMLButtonElement>()
    const [showModal, setShowModal] = useState(false)
    const { formatMessage } = useIntl()

    const lbl = useMemo(() => {
        if (isUndefined(label)) {
            return formatMessage({ id: `order.detail.excel` })
        }
        return label
    }, [label, formatMessage])

    const icn: Nullable<IconProp> = useMemo(() => {
        if (isUndefined(icon)) {
            return ['fal', 'file-excel']
        }
        return icon
    }, [icon])

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (onClick) {
                onClick(e, order)
            }

            setShowModal(true)
        },
        [onClick, order, setShowModal]
    )

    const handleModalExit = useCallback(() => {
        setShowModal(false)
    }, [setShowModal])

    const tooltipMessage = useMemo(() => {
        if (isUndefined(tooltip)) {
            return formatMessage({ id: 'order.detail.excel' })
        }
        return tooltip
    }, [tooltip, formatMessage])

    return (
        <>
            <OverlayTrigger
                show={isNull(tooltipMessage) ? false : undefined}
                overlay={<Tooltip id={`tooltip-${order.id}-excel`}>{tooltipMessage}</Tooltip>}
            >
                <Button ref={targetBtn} variant={variant} onClick={handleClick} size={size} block={block}>
                    {icn && <FontAwesomeIcon icon={icn} />}
                    {lbl && <span className="lbl">{lbl}</span>}
                </Button>
            </OverlayTrigger>
            {showModal && <ExportModal resource={order} onExportModalExit={handleModalExit} />}
        </>
    )
}

export default memo(Export)
