/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { IMessageCollection } from '../../i18n'
import { makeSelectLocale } from '../../store/intl/selectors'

export interface IProps {
    messages: IMessageCollection
    children?: React.ReactNode
}

const stateSelector = createSelector(makeSelectLocale(), (locale) => ({
    locale,
}))

const defaultRichTextElements = {
    strong: (chunks: Array<string>) => <strong>{chunks}</strong>,
    i: (chunks: Array<string>) => <i>{chunks}</i>,
    u: (chunks: Array<string>) => <u>{chunks}</u>,
    linebreak: <br />,
}

export default function LanguageProvider(props: IProps): JSX.Element {
    const { locale } = useSelector(stateSelector)

    return (
        <IntlProvider
            locale={locale}
            key={locale}
            messages={props.messages[locale]}
            // @ts-ignore
            defaultRichTextElements={defaultRichTextElements}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    )
}
