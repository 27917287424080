/**
 *
 * SplashScreen
 *
 */
import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import Loader from '../Loader/AppLoader'

interface IProps {
    show: boolean
    animated: boolean
}

const MOUNT_NODE = document.body as HTMLElement

function SplashScreen({ show, animated }: IProps): JSX.Element {
    return ReactDOM.createPortal(
        <>
            <Loader animated={animated} show={show} />
        </>,
        MOUNT_NODE
    )
}

SplashScreen.defaultProps = {
    show: true,
    animated: true,
} as Partial<IProps>

export default memo(SplashScreen)
