import { AxiosInstance } from 'axios'
import {
    IApiOrderAnomalyResponse,
    IApiOrderAnomalyTempUploadResponse,
    IOrderAnomalyParams,
    IOrderAnomalyService,
} from './types'

const create = (instance: AxiosInstance): IOrderAnomalyService => {
    /*****************************
     * METHODS
     *****************************/
    const uploads = (
        formData: FormData,
        onUploadProgress?: (progressEvent: any) => void,
        headers?: any
    ): Promise<IApiOrderAnomalyTempUploadResponse> => {
        return instance.post('/uploads', formData, { onUploadProgress, headers })
    }

    const create = (params: IOrderAnomalyParams): Promise<IApiOrderAnomalyResponse> => {
        return instance.post('/order-anomalies', { ...params })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        uploads,
        create,
    }
}

export default {
    create,
}
