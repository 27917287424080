/*
 *
 * SalesmanCustomers
 *
 */

import React, { useCallback, useMemo } from 'react'
import { ISalesmanCustomerList } from '../../services/api/service/customers/types'
import Table from '../../components/Table/Table'
import { Column, Filters, SortingRule, TableState } from 'react-table'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../components/Buttons/Button'
import FlatIcon from '../../components/Icon/FlatIcon'
import { useDispatch, useSelector } from 'react-redux'
import { customerSetCurrent } from '../../store/customers/actions'
import { Card, Form } from 'react-bootstrap'
import classNames from 'classnames'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectCustomersError,
    makeSelectCustomersFetching,
    makeSelectCustomersItems,
    makeSelectCustomersTableState,
    makeSelectCustomersTotalItems,
} from '../../store/salesmens/selectors'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { $enum } from 'ts-enum-util'
import { IOrderMode } from '../../services/api/service/orders/types'
import { ordersSwitchOrderModeAction } from '../../store/orders/actions'
import { salesmenCustomersProcessAction } from '../../store/salesmens/actions'
import { ICustomerProspectTableState } from '../../store/salesmens/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { canViewAllCustomers, isSalesmanResource } from '../../store/salesmens/utils'
import BooleanFilter from '../CustomerCarts/Partial/Filter/BooleanFilter'
import { generatePath, Link } from 'react-router-dom'
import { getPath } from '../../routes'
import Qs from 'qs'

type SalesmanCustomersItemType = ISalesmanCustomerList

const stateSelector = createStructuredSelector<any, any>({
    items: makeSelectCustomersItems(),
    totalItems: makeSelectCustomersTotalItems(),
    fetching: makeSelectCustomersFetching(),
    error: makeSelectCustomersError(),
    tableState: makeSelectCustomersTableState(),
    me: makeSelectAuthMe(),
})

function SalesmanCustomers(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()

    const { items, totalItems, fetching, error, tableState, me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            fetching: boolean
            error?: IAppErrorTypes
            totalItems: number
            items: Array<ISalesmanCustomerList>
            tableState?: ICustomerProspectTableState
        }
    >(stateSelector)

    const handleCustomerSelect = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const customerId = e.currentTarget.dataset.id
            const orderMode = e.currentTarget.dataset.orderMode

            if (orderMode && $enum(IOrderMode).isValue(orderMode)) {
                dispatch(ordersSwitchOrderModeAction(orderMode))
            }

            if (customerId) {
                dispatch(customerSetCurrent(customerId, true))
            }
        },
        [dispatch]
    )

    const handleFetchData = useCallback(
        (
            page: number,
            pageSize: number,
            filters: Filters<SalesmanCustomersItemType>,
            sortBy: Array<SortingRule<SalesmanCustomersItemType>>
        ) => {
            const filteredFilters = [...filters].filter((filter) => {
                if (filter.id !== 'all') {
                    return true
                }
                return canViewAllCustomers(me)
            })
            dispatch(salesmenCustomersProcessAction(page, pageSize, filteredFilters, sortBy))
        },
        [dispatch, me]
    )

    const CustomerSalesmenCell = useCallback((props) => {
        const customer = props.data[props.cell.row.id]

        if (!customer.salesmen || customer.salesmen.length === 0) {
            return <span className={'customer-salesmen'}>-</span>
        }

        return (
            <span className={'customer-salesmen'}>
                {customer.salesmen.map((salesman) => (
                    <span className={'customer-salesmen-item'} key={salesman.id}>
                        {salesman.name}
                    </span>
                ))}
            </span>
        )
    }, [])

    const CustomerAddressCell = useCallback((props) => {
        const customer = props.data[props.cell.row.id]

        if (!customer.address1 && !customer.postcode && !customer.city && !customer.country_name) {
            return <span className={'customer-address'}>-</span>
        }

        return (
            <span className={'customer-address'}>
                {customer.address1 && <span className={'address-1'}>{customer.address1}</span>}
                {customer.address2 && <span className={'address-2'}>{customer.address2}</span>}
            </span>
        )
    }, [])

    const CustomerCountryCell = useCallback((props) => {
        const customer = props.data[props.cell.row.id]

        if (!customer.country_name) {
            return <span className={'customer-address'}>-</span>
        }

        return (
            <span className={'customer-address'}>
                <span className={'city-country'}>
                    <span className={'country-name'}>{customer.country_name}</span>
                </span>
            </span>
        )
    }, [])

    const CustomerPaymentInLate = useCallback((props) => {
        const customer = props.data[props.cell.row.id]

        if (!customer.has_late_payment) {
            return <span>-</span>
        }

        return (
            <span className="yes">
                <FormattedMessage id="default.yes" />
            </span>
        )
    }, [])

    const customerCartList = useMemo(() => {
        return generatePath(getPath('salesmanCarts', locale), {
            lang: locale,
        })
    }, [locale])

    const CustomerInProgressCart = useCallback(
        (props) => {
            const customer = props.data[props.cell.row.id]

            if (!customer.has_cart_in_progress) {
                return <span>-</span>
            }

            const stringified = Qs.stringify({
                criteria: {
                    filters: [
                        {
                            id: 'customer.login',
                            value: customer.store_code,
                        },
                    ],
                },
            })

            return (
                <Link to={`${customerCartList}?${stringified}`}>
                    <span className="yes">
                        <FormattedMessage id="default.yes" />
                    </span>
                </Link>
            )
        },
        [customerCartList]
    )

    const CustomerActions = useCallback(
        ({ value }) => (
            <>
                {$enum(IOrderMode).map((orderMode, orderModeKey) => {
                    return (
                        <Button
                            block
                            key={orderModeKey}
                            variant={orderMode === IOrderMode.Order ? 'primary' : 'secondary'}
                            data-id={value}
                            data-order-mode={orderMode}
                            onClick={handleCustomerSelect}
                            disabled={fetching}
                            size="sm"
                        >
                            <FlatIcon icon={orderMode === IOrderMode.Order ? 'shopping-cart' : 'budget'} />{' '}
                            <FormattedMessage id={`salesman.customers.action_order_${orderMode}`} />
                        </Button>
                    )
                })}
            </>
        ),
        [handleCustomerSelect, fetching]
    )

    const initialState: Partial<TableState<SalesmanCustomersItemType>> = useMemo(() => {
        const hiddenColumns: string[] = []

        if (!isSalesmanResource(me) || !me.is_manager || !tableState) {
            hiddenColumns.push('salesmen')
        } else {
            let allActive = false
            tableState.filters.forEach((filter) => {
                if (filter.id === 'all' && filter.value) {
                    allActive = true
                }
            })

            if (!allActive) {
                hiddenColumns.push('salesmen')
            }
        }

        if (tableState) {
            return { tableState, ...{ hiddenColumns } }
        }
        return { pageIndex: 0, pageSize: 40, filters: [{ id: 'all', value: 0 }], hiddenColumns }
    }, [me, tableState])

    const columns: Array<Column<SalesmanCustomersItemType>> = useMemo(() => {
        return [
            {
                Header: 'all',
                defaultCanFilter: canViewAllCustomers(me),
                filterOnly: true,
                Filter: ({ column: { filterValue, setFilter, id } }) => {
                    return (
                        <Form.Check
                            type={'switch'}
                            id={id}
                            checked={filterValue !== 1}
                            label={formatMessage({ id: 'salesman.my_portfolio_only' })}
                            onChange={() => {
                                setFilter(!filterValue ? 1 : 0)
                            }}
                            value={0}
                        />
                    )
                },
            },
            {
                Header: formatMessage({ id: 'default.client_code' }),
                accessor: 'store_code',
                className: 'store-code',
                defaultCanSort: true,
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.name' }),
                accessor: 'business_name',
                className: 'business-name',
                defaultCanSort: true,
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.salesmen' }),
                accessor: 'salesmen',
                Cell: CustomerSalesmenCell,
                className: 'salesmen',
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.address' }),
                accessor: 'address1',
                Cell: CustomerAddressCell,
                className: 'address',
            },
            {
                Header: formatMessage({ id: 'default.postcode' }),
                className: 'postcode',
                accessor: 'postcode',
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.city' }),
                accessor: 'city',
                className: 'city',
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.country' }),
                Cell: CustomerCountryCell,
                accessor: 'country_name',
                className: 'country',
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'payment.in_late' }),
                Cell: CustomerPaymentInLate,
                Filter: BooleanFilter,
                accessor: 'has_late_payment',
                className: 'late_payment',
                defaultCanFilter: true,
            },
            {
                Header: formatMessage({ id: 'default.in_progress_cart' }),
                Cell: CustomerInProgressCart,
                Filter: BooleanFilter,
                accessor: 'has_cart_in_progress',
                defaultCanFilter: true,
            },
            {
                id: 'select',
                accessor: 'id',
                Cell: CustomerActions,
                className: 'action-type',
            },
        ]
    }, [
        me,
        formatMessage,
        CustomerAddressCell,
        CustomerCountryCell,
        CustomerPaymentInLate,
        CustomerActions,
        CustomerSalesmenCell,
    ])

    return (
        <Card className={classNames('member-card')}>
            <Card.Header>
                <h3 className={'hv'}>
                    <FormattedMessage id={'salesman.customers'} />
                </h3>
            </Card.Header>
            <Card.Body>
                <Table<SalesmanCustomersItemType>
                    totalItems={totalItems}
                    columns={columns}
                    data={items || []}
                    initialState={initialState}
                    fetchData={handleFetchData}
                    fetching={fetching}
                    fetchError={error}
                />
            </Card.Body>
        </Card>
    )
}

export default SalesmanCustomers
