/**
 *
 * QuantitySelector
 *
 */
import classNames from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    expanded?: boolean
    favorite?: boolean
    onFavoriteChange?: (favorite: boolean) => void
}

function Favorite({ favorite, expanded, onFavoriteChange }: IProps): JSX.Element {
    const [isFavorite, setIsFavorite] = useState<boolean>(favorite || false)
    const handleOnClick = () => {
        if (onFavoriteChange) {
            onFavoriteChange(!isFavorite)
        }
        setIsFavorite(!isFavorite)
    }

    useEffect(() => {
        setIsFavorite(favorite || false)
    }, [favorite])

    const iconId = isFavorite ? 'heart' : 'heart-o-2'
    const formattedMessageId = isFavorite ? 'favorite.remove' : 'favorite.add'

    return (
        <span className={classNames('favorite', { checked: isFavorite })} onClick={handleOnClick}>
            {expanded && <FormattedMessage id={formattedMessageId} />}
            <FlatIcon icon={iconId} />
        </span>
    )
}

Favorite.defaultProps = {
    favorite: false,
    expanded: false,
} as Partial<IProps>

export default memo(Favorite)
