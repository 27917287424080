/*
 *
 * CustomerImport
 *
 */

import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Badge, Card, Col, Row, Table } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Button from '../../components/Buttons/Button'
import ResumeCard from '../../components/Customer/ResumeCard'
import FlatIcon from '../../components/Icon/FlatIcon'
import ImportState from '../../components/Import/ImportState'
import ImportType from '../../components/Import/ImportType'
import { CustomerLayoutRowTopId } from '../../pages/Layout/CustomerLayout'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { IApiShopImportDetail, IApiShopImportStateList } from '../../services/api/service/imports/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { canSwitchAccount } from '../../store/carts/utils'
import { customerSwitchStore } from '../../store/customers/actions'
import { makeSelectCustomer, makeSelectCustomerStoreId } from '../../store/customers/selectors'
import { findCustomerStoreBy } from '../../store/customers/utils'
import { shopImportDetailProcessAction, shopImportDetailResetAction } from '../../store/imports/actions'
import {
    makeSelectShopImportDetail,
    makeSelectShopImportDetailError,
    makeSelectShopImportDetailFetching,
} from '../../store/imports/selectors'
import forEach from 'lodash/forEach'
import { stockAlertProcessAction } from '../../store/stockAlert/actions'
import isBoolean from 'lodash/isBoolean'

interface IProps {
    itemId: string
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    currentStoreId: makeSelectCustomerStoreId(),
    detailFetching: makeSelectShopImportDetailFetching(),
    detailError: makeSelectShopImportDetailError(),
    itemDetail: makeSelectShopImportDetail(),
})

function CustomerImport({ itemId }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const history = useHistory()
    const { formatDate } = useIntl()
    const [productStockAlerts, setProductStockAlerts] = useState({})
    const { itemDetail, detailError, detailFetching, customer, currentStoreId } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
            currentStoreId?: string
            itemDetail: IApiShopImportDetail
            detailError?: IAppErrorTypes | undefined
            detailFetching: boolean
        }
    >(stateSelector)

    // dans le cas du multi magasin
    const storeImport = useMemo(() => {
        if (
            !itemDetail ||
            !customer ||
            customer.account_type !== ICustomerType.MultiStore ||
            !itemDetail.customer ||
            !customer.stores ||
            customer.stores.length === 0
        ) {
            return undefined
        }

        // récupération du store
        return findCustomerStoreBy(customer, '@id', itemDetail.customer)
    }, [customer, itemDetail])

    useEffect(() => {
        if ((!detailFetching && !detailError && !itemDetail) || (itemDetail && itemId !== itemDetail['@id'])) {
            dispatch(shopImportDetailResetAction())
            dispatch(shopImportDetailProcessAction(itemId))
        }
    }, [detailFetching, detailError, itemId, itemDetail, dispatch])

    const Component = document.getElementById(CustomerLayoutRowTopId) ? (
        ReactDOM.createPortal(
            <>
                <Col xs={24} md={'auto'}>
                    <Button variant={'link'} onClick={() => history.goBack()} className={'btn-back'}>
                        <FlatIcon icon={'chevron-left'} />
                        <FormattedMessage id={'default.back_to_list'} />
                    </Button>
                </Col>
            </>,
            document.getElementById(CustomerLayoutRowTopId)!
        )
    ) : (
        <></>
    )

    const hasStockErrors = useMemo(() => {
        if (!itemDetail?.errors || !itemDetail?.errors.length) {
            return false
        }

        for (const err of itemDetail.errors) {
            if (err.product?.can_add_stock_alert) {
                return true
            }
        }

        return false
    }, [itemDetail?.errors])

    const isGlobalErrors = useMemo(() => {
        if (!itemDetail || !itemDetail.errors || itemDetail.errors.length === 0) {
            return false
        }
        return Object.values(itemDetail.errors).reduce((sum, next) => {
            return sum || next.line === null
        }, false)
    }, [itemDetail])

    const isReady = useMemo(() => {
        if (!itemDetail) {
            return false
        }
        return itemDetail.state === IApiShopImportStateList.Completed
    }, [itemDetail])

    const totalLines = useMemo(() => {
        if (!itemDetail) {
            return 0
        }
        return (itemDetail.error_lines_count || 0) + (itemDetail.success_lines_count || 0)
    }, [itemDetail])

    const isAllowToSwitchAccount = useMemo(() => {
        if (!canSwitchAccount(customer)) {
            return false
        }

        if (storeImport && storeImport.id !== currentStoreId) {
            return true
        }

        return false
    }, [customer, storeImport, currentStoreId])

    const handleStockAlertClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const identifier = e.currentTarget.dataset.id
            if (!identifier || !isBoolean(productStockAlerts[identifier])) {
                return
            }

            const subscribed = productStockAlerts[identifier]
            dispatch(stockAlertProcessAction(identifier, !subscribed))
            setProductStockAlerts((ste) => ({
                ...ste,
                [identifier]: !subscribed,
            }))
        },
        [dispatch, setProductStockAlerts, productStockAlerts]
    )

    const handleStoreChangeClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const storeId = e.currentTarget.dataset.id
            dispatch(customerSwitchStore(storeId, true, true))
        },
        [dispatch]
    )

    useEffect(() => {
        if (!itemDetail?.errors || !itemDetail?.errors.length) {
            setProductStockAlerts({})
            return
        }

        const stockAlerts = {}
        forEach(itemDetail.errors, (error) => {
            if (error.product?.can_add_stock_alert) {
                stockAlerts[error.product.id] = error.product.has_stock_alert
            }
        })
        setProductStockAlerts(stockAlerts)
    }, [itemDetail?.errors, setProductStockAlerts])

    return (
        <>
            <Helmet>
                <body className="customer-import-page" />
            </Helmet>
            {Component}
            {itemDetail && (
                <div className={'customer-import-detail-section'}>
                    <Row>
                        <Col xs={24}>
                            <Card className={classNames('member-card', 'resume')}>
                                <Card.Header>
                                    <h3 className={'hv'}>
                                        <FormattedMessage
                                            id={'imports.detail.title'}
                                            values={{ date: formatDate(itemDetail.created_at) }}
                                        />
                                    </h3>
                                </Card.Header>
                                <Card.Body>
                                    {isGlobalErrors && (
                                        <Alert variant={'danger'}>
                                            <Alert.Heading>
                                                <FormattedMessage id={'default.error'} />
                                            </Alert.Heading>
                                            {itemDetail.errors.map((error, idx) => (
                                                <p key={idx}>{error.message}</p>
                                            ))}
                                        </Alert>
                                    )}
                                    <Table striped hover responsive>
                                        <tbody>
                                            {storeImport && (
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id={'default.store'} />
                                                    </th>
                                                    <td>
                                                        <div className="customer-store">
                                                            <ResumeCard customer={storeImport} principal={false} />
                                                            {isAllowToSwitchAccount && (
                                                                <div className="switch-store-action">
                                                                    <Button
                                                                        size="sm"
                                                                        variant="primary"
                                                                        data-id={storeImport.id}
                                                                        onClick={handleStoreChangeClick}
                                                                    >
                                                                        <FormattedMessage id="default.place_order" />
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.import_date'} />
                                                </th>
                                                <td>
                                                    <FormattedDate value={itemDetail.created_at} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.type'} />
                                                </th>
                                                <td>
                                                    <ImportType type={itemDetail['@type']} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.state'} />
                                                </th>
                                                <td>
                                                    <ImportState state={itemDetail.state} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.parsed_lines_count'} />
                                                </th>
                                                <td>
                                                    {!isReady && '-'}
                                                    {isReady && `${totalLines}`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.error_lines_count'} />
                                                </th>
                                                <td>
                                                    {!isReady && '-'}
                                                    {isReady && `${itemDetail.error_lines_count}`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id={'imports.success_lines_count'} />
                                                </th>
                                                <td>
                                                    {!isReady && '-'}
                                                    {isReady && `${itemDetail.success_lines_count}`}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {!isGlobalErrors && itemDetail.errors && itemDetail.errors.length > 0 && (
                        <Row>
                            <Col xs={24}>
                                <Card className={classNames('member-card', 'shop-import-errors')}>
                                    <Card.Header>
                                        <h3 className={'hv'}>
                                            <FormattedMessage
                                                id={'imports.errors'}
                                                values={{ date: formatDate(itemDetail.created_at) }}
                                            />
                                        </h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id={'imports.line_number'} />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id={'product.reference'} />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id={'imports.error.type.label'} />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id={'imports.message'} />
                                                    </th>
                                                    {hasStockErrors && (
                                                        <th>
                                                            <FormattedMessage id={'restock_alert.title'} />
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {itemDetail.errors.map((error, index) => (
                                                    <tr
                                                        key={index}
                                                        className={classNames({
                                                            danger: !error.warning,
                                                            warning: error.warning,
                                                        })}
                                                    >
                                                        <td>{error.line}</td>
                                                        <td>{error.product_reference || '-'}</td>
                                                        <td>
                                                            {error.warning && (
                                                                <Badge variant={'warning'}>
                                                                    <FlatIcon icon={'warning'} />
                                                                    <span className={'value'}>
                                                                        <FormattedMessage
                                                                            id={'imports.error.type.warning'}
                                                                        />
                                                                    </span>
                                                                </Badge>
                                                            )}
                                                            {!error.warning && (
                                                                <Badge variant={'danger'}>
                                                                    <FlatIcon icon={'danger'} />
                                                                    <span className={'value'}>
                                                                        <FormattedMessage
                                                                            id={'imports.error.type.error'}
                                                                        />
                                                                    </span>
                                                                </Badge>
                                                            )}
                                                        </td>
                                                        <td>{error.message}</td>
                                                        {hasStockErrors && (
                                                            <td>
                                                                {error.product?.can_add_stock_alert && (
                                                                    <div className="restock-alert">
                                                                        <Button
                                                                            variant={
                                                                                productStockAlerts[error.product.id]
                                                                                    ? 'outline-success'
                                                                                    : 'outline-primary'
                                                                            }
                                                                            data-id={error.product.id}
                                                                            onClick={handleStockAlertClick}
                                                                        >
                                                                            <FlatIcon icon="bell" />
                                                                            <span className="lbl">
                                                                                <FormattedMessage
                                                                                    id={
                                                                                        productStockAlerts[
                                                                                            error.product.id
                                                                                        ]
                                                                                            ? 'restock_alert.active'
                                                                                            : 'restock_alert.receive'
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </>
    )
}

CustomerImport.defaultProps = {} as Partial<IProps>

export default memo(CustomerImport)
