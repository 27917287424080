import React, { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import NotFoundAlert, { NotFoundReasonModes } from '../components/Products/Content/NotFoundAlert'
import FamilyTree from '../components/Products/Sidebar/Type/FamilyTree'
import Breadcrumb from '../containers/Breadcrumb/Breadcrumb'
import CmsBlock from '../containers/CmsBlock/CmsBlock'
import { default as ProductsPlaceholder } from '../containers/Products/Placeholder'
import { getPath } from '../routes'
import { formatDisplayableFamilyTree } from '../store/classification/utils'
import { ICategory, IFamilyTreeCollection } from '../services/api/service/classification/types'
import { CmsBlockCodeList } from '../services/api/service/cms/types'
import { ProductsListMode } from '../services/api/service/products/types'
import { IApplicationRootState } from '../store'
import { IAppErrorTypes } from '../store/app/types'
import { cartsBannerShowAction } from '../store/carts/actions'
import { classificationCategoryProcessAction, classificationCategoryResetAction } from '../store/classification/actions'
import {
    makeSelectClassificationCategory,
    makeSelectClassificationCategoryError,
    makeSelectClassificationFamilyTreeDefault,
} from '../store/classification/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import { makeSelectCustomer } from '../store/customers/selectors'
import ProductsPage from './ProductsPage'

const stateSelector = createStructuredSelector<any, any>({
    category: makeSelectClassificationCategory(),
    categoryError: makeSelectClassificationCategoryError(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    customer: makeSelectCustomer(),
})

function ProductsCategoryPage(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const { categorySlug } = useParams<{
        categorySlug: string
    }>()
    const { category, familyTree, categoryError, customer } = useSelector<
        IApplicationRootState,
        {
            categoryError: IAppErrorTypes
            category: ICategory
            familyTree: IFamilyTreeCollection
            customer?: ICustomer
        }
    >(stateSelector)

    const basePath: string | undefined = useMemo(() => {
        return generatePath(getPath('family', locale), { lang: locale })
    }, [locale])

    useEffect(() => {
        dispatch(cartsBannerShowAction())
    })

    useEffect(() => {
        if (categorySlug) {
            dispatch(classificationCategoryProcessAction(categorySlug))
        } else {
            dispatch(classificationCategoryResetAction())
        }
    }, [categorySlug, dispatch])

    let seoPageTitle = formatMessage({ id: 'seo.products.title' })
    if (category) {
        seoPageTitle = category.label
    }

    const items = useMemo(() => {
        if (!category) {
            return []
        }
        return [
            {
                label: category.label,
            },
        ]
    }, [category])

    const handleGoBack = useCallback(() => {
        const productsUrl = generatePath(getPath('family', locale), { lang: locale })
        history.push(productsUrl)
    }, [history, locale])

    const currentFamilyTreeDisplayable = useMemo(() => {
        return formatDisplayableFamilyTree(familyTree || [], customer?.has_virtual_cart)
    }, [familyTree, customer])

    if (category && currentFamilyTreeDisplayable) {
        return <ProductsPage category={category} mode={ProductsListMode.Category} />
    }

    return (
        <>
            <Helmet>
                <title>{seoPageTitle}</title>
                <meta
                    name="description"
                    content={formatMessage({ id: 'seo.products.description' }, { name: seoPageTitle })}
                />
            </Helmet>
            <div className={'products-page'} id={'products-page'}>
                {category && <Breadcrumb items={items} />}
                <Container fluid>
                    <Row noGutters className={'products-page-content'}>
                        <Col className={'col-sidebar'}>
                            <div className={'product-list-sidebar'}>
                                {currentFamilyTreeDisplayable && (
                                    <FamilyTree basePath={basePath} tree={currentFamilyTreeDisplayable} />
                                )}
                                <CmsBlock identifier={CmsBlockCodeList.SidebarHighlight} lazy />
                            </div>
                        </Col>
                        <Col className={'col-product-list'}>
                            <ReactPlaceholder
                                ready={typeof categoryError !== 'undefined' || typeof categorySlug === 'undefined'}
                                customPlaceholder={<ProductsPlaceholder itemsPerPage={48} />}
                            >
                                <>
                                    {(!categorySlug || typeof categoryError !== 'undefined') && (
                                        <NotFoundAlert
                                            mode={ProductsListMode.Category}
                                            reason={NotFoundReasonModes.UnknownCategory}
                                            onClick={handleGoBack}
                                        />
                                    )}
                                </>
                            </ReactPlaceholder>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ProductsCategoryPage
