/**
 *
 * Product Card
 *
 */
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { default as ProductList, IProps } from '../../../components/Product/List/List'
import { IProductList } from '../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../store'
import { addToCartProcessAction, removeToCartProcessAction } from '../../../store/carts/actions'
import { stockAlertProcessAction } from '../../../store/stockAlert/actions'
import {
    makeSelectCanIncrementQuantityByProductId,
    makeSelectCartByShippingLocationId,
    makeSelectErrorsByProductId,
    makeSelectFetchingByProductId,
    makeSelectLockByShippingLocationId,
    makeSelectQuantityByProductId,
} from '../../../store/carts/selectors'
import StoreQuantity from '../../StoreQuantity/StoreQuantity'
import { ICart } from '../../../services/api/service/carts/types'
import { Nullable } from 'tsdef'

type Props = IProps & {
    bypassCartLocker?: boolean
}

function Item({ bypassCartLocker = false, ...rest }: Props): JSX.Element {
    const dispatch = useDispatch()
    const [showStoreQuantity, setShowStoreQuantity] = useState<boolean>(false)
    const selectQuantityWithId = useMemo(makeSelectQuantityByProductId, [])
    const quantity: number = useSelector<IApplicationRootState, number>((state) =>
        selectQuantityWithId(state, rest.product.id, true)
    )
    const selectFetchingWithId = useMemo(makeSelectFetchingByProductId, [])
    const fetching: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectFetchingWithId(state, rest.product.id, true)
    )
    const selectCanIncrementQuantityWithId = useMemo(makeSelectCanIncrementQuantityByProductId, [])
    const canIncrementQuantity: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectCanIncrementQuantityWithId(state, rest.product.id, true)
    )
    const selectItemErrorsWithId = useMemo(makeSelectErrorsByProductId, [])
    const itemErrors: Array<string> | undefined = useSelector<IApplicationRootState, Array<string> | undefined>(
        (state) => selectItemErrorsWithId(state, rest.product.id, true)
    )
    const selectItemLockedWithShippingLocationId = useMemo(makeSelectLockByShippingLocationId, [])
    const cartIsLocked: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectItemLockedWithShippingLocationId(state, rest.product.shipping_location)
    )
    const selectCartWithShippingLocationId = useMemo(makeSelectCartByShippingLocationId, [])
    const cart: Nullable<ICart> = useSelector<IApplicationRootState, Nullable<ICart>>((state) =>
        selectCartWithShippingLocationId(state, rest.product.shipping_location, true)
    )

    const handleProductQuantityMultipleAsked = useCallback(() => {
        setShowStoreQuantity(true)
    }, [setShowStoreQuantity])

    const handleProductQuantityExit = useCallback(() => {
        setShowStoreQuantity(false)
    }, [setShowStoreQuantity])

    const handleOnProductQuantityChange = useCallback(
        (product: IProductList, quantity: number, quantityPrev: number) => {
            if (quantity > 0) {
                dispatch(
                    addToCartProcessAction(product.id, quantity, quantityPrev, undefined, undefined, cart?.['@id'])
                )
            } else {
                dispatch(
                    removeToCartProcessAction(product.id, quantity, quantityPrev, undefined, undefined, cart?.['@id'])
                )
            }
        },
        [dispatch, cart]
    )

    const handleRestockAlertSubscriptionChange = useCallback(
        (product: IProductList, subscribed: boolean) => {
            dispatch(stockAlertProcessAction(product.id, subscribed))
        },
        [dispatch]
    )

    return (
        <>
            <ProductList
                {...rest}
                restockAlertOutOfStockButtonOnly
                onProductQuantityChange={handleOnProductQuantityChange}
                quantitySelectorCurrentValue={quantity}
                quantitySelectorSaving={fetching}
                quantitySelectorErrors={itemErrors}
                quantityCanIncrement={canIncrementQuantity}
                quantityCartLocked={cartIsLocked && !bypassCartLocker}
                onProductQuantityMultipleAsked={handleProductQuantityMultipleAsked}
                onProductQuantityRestockAlertSubscriptionChange={handleRestockAlertSubscriptionChange}
            />
            {showStoreQuantity && (
                <StoreQuantity
                    product={rest.product}
                    errors={itemErrors}
                    onStoreQuantityExit={handleProductQuantityExit}
                />
            )}
        </>
    )
}

Item.defaultProps = {} as Partial<IProps>

export default Item
