import { put, select, takeEvery } from 'redux-saga/effects'
import makeSelectSideMenuOpen, { makeSelectSideMenuItems } from './selectors'
import { sideMenuCloseAction, sideMenuStoreActiveIdAction } from './actions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { ISlideMenuItemCollection } from '../classification/types'
import { findSlideMenuItemBy } from '../classification/utils'
import { removeProductPathToPathName } from '../products/utils'
import ActionTypes from './constants'
import ApplicationHelper from '../../utils/applicationHelper'

export function* processMobileMenuClose() {
    const isOpen: boolean = yield select(makeSelectSideMenuOpen())
    if (isOpen) {
        yield put(sideMenuCloseAction())
    }
}

export function* processMobileMenuFindLevel() {
    // on regarde si on peux définir le level
    if (ApplicationHelper.isMobileResolution()) {
        const menu: ISlideMenuItemCollection = yield select(makeSelectSideMenuItems())
        const url = removeProductPathToPathName(window.location.pathname)
        let menuItem = findSlideMenuItemBy(menu, 'url', url)
        if (menuItem?.children) {
            menuItem = menuItem?.children[0]
        }
        if (!menuItem) {
            menuItem = menu ? menu[0] : null
        }
        yield put(sideMenuStoreActiveIdAction(menuItem ? menuItem.id : undefined))
    }
}

export default [
    takeEvery(LOCATION_CHANGE, processMobileMenuClose),
    takeEvery(LOCATION_CHANGE, processMobileMenuFindLevel),
    takeEvery(ActionTypes.STORE_ITEMS_ACTION, processMobileMenuFindLevel),
]
