import { AxiosError } from 'axios'
import { IApiErrorDescription, IAppErrorErrorsDescription } from './types'
import { IApiErrorViolation } from '../../services/api/types'
import HttpStatusCode from './codes'
import { IAppErrorTypes } from '../app/types'

export function formatErrorDescription(err: IAppErrorTypes, publicError: string, debug: boolean): IApiErrorDescription {
    if (typeof err === 'string' && (err as string).indexOf('Request failed with status code 401') > -1) {
        // formattage des erreurs
        return {
            status: 401,
            statusText: 'Invalid token refresh',
            error: debug ? (err as string) : publicError,
        }
    }

    if (!err.isAxiosError) {
        return {
            status: 0,
            statusText: 'Internal App Error',
            error: debug ? err.message : publicError,
        }
    }

    const err2 = err as AxiosError
    const status = !err2.response ? parseInt(err2.code!) : err2.response?.status
    const statusText = !err2.response ? err2.message : err2.response?.statusText
    const headers = err2.response?.headers

    let violationError = false
    let realError = err2.message
    if (err2.response) {
        realError =
            err2.response.statusText && err2.response.statusText.length > 0 ? err2.response.statusText : err2.message
        if (err2.response.data) {
            // @ts-ignore
            if (err2.response.data.violations) {
                const realErrors: Array<string> = []
                // @ts-ignore
                for (const idx in err2.response.data.violations) {
                    // @ts-ignore
                    const violation: IApiErrorViolation = err2.response.data.violations[idx]
                    if (violation.propertyPath.length === 0) {
                        realErrors.push(violation.message)
                    }
                }
                violationError = true
                realError = realErrors.join(', ')
                // @ts-ignore
            } else if (err2.response.data['hydra:description']) {
                // @ts-ignore
                realError = `${err2.response?.data['hydra:title']} : ${err2.response?.data['hydra:description']}`
                // @ts-ignore
            } else if (err2.response.data.message) {
                // @ts-ignore
                realError = err2.response.data.message
            }
        }
    }

    let error = realError
    if (!debug || (status === HttpStatusCode.BAD_REQUEST && (!violationError || realError.length === 0))) {
        error = publicError
    }

    let errors: IAppErrorErrorsDescription | undefined = undefined
    const data = err2.response?.data
    // @ts-ignore
    if (data && data.violations && Array.isArray(data.violations) && data.violations.length > 0) {
        errors = {}
        // @ts-ignore
        for (const idx in data.violations) {
            // @ts-ignore
            const violation: IApiErrorViolation = data.violations[idx]
            errors[violation.propertyPath] = [
                ...(errors[violation.propertyPath] ? errors[violation.propertyPath] : []),
                violation.message,
            ]
        }
    }

    // formattage des erreurs
    return {
        status,
        statusText,
        headers,
        error,
        realError,
        errors,
    }
}
