/**
 *
 * ReOrder
 *
 */
import classNames, { Value as ClassValue } from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Modal, Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { ICustomer } from '../../../../../services/api/service/customers/types'
import { DropdownItemChoiceTypes, ICartReOrderProps, IReOrderCartMode } from '../../../../../types/cart'
import Button from '../../../../Buttons/Button'
import ResumeCard from '../../../../Customer/ResumeCard'
import { IManageCartParameters, IReOrderParameters } from '../../../../../services/api/service/carts/types'
import { $enum } from 'ts-enum-util'
import { IOrderMode } from '../../../../../services/api/service/orders/types'

type IProps<T extends IManageCartParameters = IReOrderParameters> = Omit<
    ICartReOrderProps<T>,
    'done' | 'error' | 'order' | 'shippingLocation'
> & {
    className?: ClassValue
    show: boolean
    excludedCustomerIds?: Array<string>
    baseI18nKey?: string
}

function MultiStoreModal<T extends IManageCartParameters = IReOrderParameters>({
    className,
    customer,
    fetching,
    onReOrderSubmit,
    onReOrderReset,
    show = false,
    excludedCustomerIds,
    baseI18nKey,
    useCartType,
}: IProps<T>): JSX.Element {
    const { formatMessage } = useIntl()
    const [cartMode, setCartMode] = useState<IReOrderCartMode | undefined>(undefined)
    const [cartType, setCartType] = useState<IOrderMode | undefined>(undefined)
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)

    const i18nKey = useMemo(() => {
        return baseI18nKey ? baseI18nKey : 'order.detail.re_order'
    }, [baseI18nKey])

    const handleModalExited = () => {
        setShowSubmitButton(false)
        // RESET FORM
        setCartMode(undefined)
        if (onReOrderReset) {
            onReOrderReset()
        }
    }

    const stores: Array<ICustomer> = useMemo(() => {
        const stores = customer.stores || []
        if (typeof excludedCustomerIds === 'undefined') {
            return stores
        }
        return stores.filter((store) => excludedCustomerIds.indexOf(store['@id']) === -1)
    }, [customer, excludedCustomerIds])
    const initialSelectedStoreIds = useMemo(() => {
        const ids = stores.map((store) => store['@id'])
        if (typeof excludedCustomerIds === 'undefined' || excludedCustomerIds!.indexOf(customer['@id']) === -1) {
            ids.push(customer['@id'])
        }
        return ids
    }, [stores, excludedCustomerIds, customer])
    const [selectedStoreIds, setSelectedStoreIds] = useState<Array<string> | undefined>(initialSelectedStoreIds)

    const choices: DropdownItemChoiceTypes = useMemo(() => {
        const values: DropdownItemChoiceTypes = []

        if (!useCartType) {
            $enum(IReOrderCartMode).forEach((cartMode) => {
                values.push({
                    cartMode,
                    value: cartMode,
                    label: formatMessage({
                        id: `${i18nKey}.cart_mode.multi_store.erase`,
                    }),
                })
            })
            return values
        }

        $enum(IReOrderCartMode).forEach((cartMode) => {
            $enum(IOrderMode).forEach((cartType) => {
                values.push({
                    value: `${cartMode}_${cartType}`,
                    cartMode,
                    cartType,
                    label: formatMessage(
                        {
                            id: `${i18nKey}.cart_mode.cart_type.multi_store.erase`,
                        },
                        {
                            cart_type: formatMessage({ id: `order_mode.${cartType}` }),
                        }
                    ),
                })
            })
        })

        return values
    }, [useCartType, formatMessage, i18nKey])

    const handleOnSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()
            setShowSubmitButton(false)
            setCartMode(undefined)
            onReOrderSubmit({
                customers: selectedStoreIds,
                cart_type: cartType,
                erase_cart: cartMode === IReOrderCartMode.Erase,
            })
        },
        [selectedStoreIds, cartMode, onReOrderSubmit, cartType]
    )

    useEffect(() => {
        setShowSubmitButton(
            typeof cartMode !== 'undefined' && typeof selectedStoreIds !== 'undefined' && selectedStoreIds!.length > 0
        )
    }, [cartMode, selectedStoreIds])

    const handleCartModeChange = (event: any) => {
        const value = event.currentTarget.value
        const item = choices.find((item) => item.value === value)

        if (!item) {
            return
        }

        setCartMode(item.cartMode)
        setCartType(item.cartType)
    }

    const handleChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const checked = event.currentTarget.checked
            const value = event.currentTarget.value
            if (checked) {
                setSelectedStoreIds((state) => {
                    const arr = state ? state : []
                    arr.push(value)
                    return arr
                })
            } else {
                setSelectedStoreIds((state) => {
                    if (!state) {
                        return undefined
                    }
                    const newArr = state.filter((ste) => ste !== value)
                    return newArr.length > 0 ? newArr : undefined
                })
            }
        },
        [setSelectedStoreIds]
    )

    return (
        <Modal
            className={classNames('re-order-action-modal re-order-action-modal-multi-store', className)}
            show={show}
            onHide={handleModalExited}
            keyboard={false}
            backdrop="static"
        >
            <Form className={classNames('re-order-info')} onSubmit={handleOnSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id={`${i18nKey}.title`} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="re_order.cart_mode">
                        <Form.Label>
                            <FormattedMessage id={`${i18nKey}.cart_mode.label`} />
                        </Form.Label>
                        <Form.Control as="select" onChange={handleCartModeChange} defaultValue={cartMode}>
                            <option>{formatMessage({ id: `${i18nKey}.cart_mode.placeholder` })}</option>
                            {choices.map((choice) => (
                                <option key={choice.value} value={choice.value}>
                                    {choice.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="re_order.store_choice">
                        <Form.Label>
                            <FormattedMessage id={`${i18nKey}.principal_or_store_choice.label`} />
                        </Form.Label>
                        <Table>
                            <tbody>
                                {stores.map((store) => (
                                    <tr key={store['@id']}>
                                        <td>
                                            <label
                                                htmlFor={`multistore_modal_switch_${store['@id']}`}
                                                className={'multistore-lbl'}
                                            >
                                                <ResumeCard customer={store} as={'span'} addressInline={true} />
                                            </label>
                                        </td>
                                        <td>
                                            <Form.Check
                                                type={'switch'}
                                                id={`multistore_modal_switch_${store['@id']}`}
                                                defaultChecked={
                                                    typeof selectedStoreIds !== 'undefined' &&
                                                    selectedStoreIds.indexOf(store['@id']) > -1
                                                }
                                                label={''}
                                                onChange={handleChange}
                                                value={store['@id']}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Form.Group>
                </Modal.Body>
                {showSubmitButton && (
                    <Modal.Footer className={'text-right'}>
                        <Button
                            loading={fetching}
                            type={'submit'}
                            variant={'primary'}
                            disabled={fetching}
                            className={'btn-process'}
                        >
                            <FormattedMessage id={`${i18nKey}.submit`} />
                        </Button>
                    </Modal.Footer>
                )}
            </Form>
        </Modal>
    )
}

export default MultiStoreModal
