import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { CmsBlockIdentifierType, CmsPageIdentifierType } from '../../services/api/service/cms/types'
import { ICmsBlockState, ICmsPageState } from './types'

/**
 * Direct selector to the cms state domain
 */

const selectCmsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.cms || initialState
}

const selectCmsPagesDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.cms.pages || initialState.pages
}

const selectCmsBlocksDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.cms.blocks || initialState.blocks
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Cms
 */

const makeSelectCms = () =>
    createSelector(selectCmsDomain, (substate) => {
        return substate
    })

const makeSelectCmsPages = () =>
    createSelector(selectCmsPagesDomain, (substate) => {
        return substate
    })

const makeSelectCmsPage = () =>
    createSelector(
        [
            makeSelectCmsPages(),
            (_: any, cmsIdentifier: string) => cmsIdentifier,
            (_: any, _2: string, cmsIdentifierType: CmsPageIdentifierType) => cmsIdentifierType,
        ],
        (substate, cmsIdentifier, cmsIdentifierType) => {
            const idx = substate.findIndex(
                (obj: ICmsPageState) =>
                    obj.identifier?.type === cmsIdentifierType && obj.identifier?.value === cmsIdentifier
            )
            if (idx === -1) {
                return undefined
            }
            return substate[idx]
        }
    )

const makeSelectCmsBlocks = () =>
    createSelector(selectCmsBlocksDomain, (substate) => {
        return substate
    })

const makeSelectCmsBlock = () =>
    createSelector(
        [
            makeSelectCmsBlocks(),
            (_: any, cmsIdentifier: string) => cmsIdentifier,
            (_: any, _2: string, cmsIdentifierType: CmsBlockIdentifierType) => cmsIdentifierType,
        ],
        (substate, cmsIdentifier, cmsIdentifierType) => {
            const idx = substate.findIndex(
                (obj: ICmsBlockState) =>
                    obj.identifier?.type === cmsIdentifierType && obj.identifier?.value === cmsIdentifier
            )
            if (idx === -1) {
                return undefined
            }
            return substate[idx]
        }
    )

export default makeSelectCms
export { selectCmsDomain, makeSelectCmsBlocks, makeSelectCmsPages, makeSelectCmsPage, makeSelectCmsBlock }
