import FontFaceObserver from 'fontfaceobserver'

export default function createFontObserver(fonts: Array<string>) {
    const observers: Array<Promise<void>> = []

    fonts.forEach((fontFamily: string) => {
        const observer = new FontFaceObserver(fontFamily)
        observers.push(observer.load())
    })

    Promise.all(observers)
        .then(function () {
            document.documentElement.classList.add('fonts-loaded')
        })
        .catch(function (err) {
            console.warn('Some critical font are not available:', err)
        })
}
