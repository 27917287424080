/*
 *
 * Exports constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/Exports/RESET',
    LIST_PROCESS_ACTION = 'app/Exports/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/Exports/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/Exports/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/Exports/List/RESET',
    CREATE_PROCESS_ACTION = 'app/Exports/Create/REQUEST_PROCESS',
    CREATE_SUCCESS_ACTION = 'app/Exports/Create/REQUEST_SUCCESS',
    CREATE_FAILURE_ACTION = 'app/Exports/Create/REQUEST_FAILURE',
    CHECK_STATE_PROCESS_ACTION = 'app/Exports/CheckState/REQUEST_PROCESS',
    CHECK_STATE_SUCCESS_ACTION = 'app/Exports/CheckState/REQUEST_SUCCESS',
    CHECK_STATE_FAILURE_ACTION = 'app/Exports/CheckState/REQUEST_FAILURE',
    CREATE_RESET_ACTION = 'app/Exports/Create/RESET',
    CREATE_RESET_SINGLE_ACTION = 'app/Exports/Create/RESET_SINGLE',
    CHECK_STATE_RESET_ACTION = 'app/Exports/Create/RESET',
}

export default ActionTypes
