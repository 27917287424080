/**
 *
 * Product Carousel
 *
 */
import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { Container } from 'react-bootstrap'

interface IProps {
    title?: string | null
    subTitle?: string | null
    beforeContainer?: ReactNode
    children: ReactNode
    footer?: ReactNode
    className?: string
    contained: boolean
}

function Wrapper({ title, subTitle, beforeContainer, children, footer, className, contained }: IProps): JSX.Element {
    let Heading = <></>
    if (title) {
        Heading = (
            <Container>
                <div className={'heading carousel-app-heading'}>
                    <p className={'h1 hv'}>{title}</p>
                    {subTitle && <p className={'h6'}>{subTitle}</p>}
                </div>
            </Container>
        )
    }

    let Footer = <></>
    if (footer) {
        Footer = (
            <Container>
                <div className={'carousel-app-footer'}>{footer}</div>
            </Container>
        )
    }

    return (
        <div className={classNames('carousel-app', 'carousel-app-full-width', className)}>
            {Heading}
            <div className={'carousel-app-content'}>
                {beforeContainer}
                <Container className={'carousel-app-container'} fluid={!contained}>
                    {children}
                </Container>
            </div>
            {Footer}
        </div>
    )
}

Wrapper.defaultProps = {
    params: {},
    showNavigation: true,
    contained: true,
} as Partial<IProps>

export default memo(Wrapper)
