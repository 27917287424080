/*
 *
 * App reducer
 *
 */

import ActionTypes from './constants'
import { ContainerState, ContainerActions } from './types'

export const initialState: ContainerState = {
    bootstrap: false,
    fatal: false,
    ready: false,
    reload: false,
}

function appReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.APP_BOOTSTRAP_ACTION:
            return { bootstrap: true, reload: true, fatal: false, ready: false }
        case ActionTypes.APP_RELOADING_ACTION:
            return { ...state, reload: true, fatal: false, error: undefined }
        case ActionTypes.APP_RELOADED_ACTION:
            return { ...state, reload: false, fatal: false }
        case ActionTypes.APP_RELOAD_FAIL_ACTION:
            return { ...state, reload: false, ready: false, bootstrap: false, fatal: true, error: action.payload.error }
        case ActionTypes.APP_ERROR_ACTION:
            return { ...state, error: action.payload.error }
        case ActionTypes.APP_ERROR_CLEAR_ACTION:
            return { ...state, fatal: false, error: undefined }
        case ActionTypes.APP_READY_ACTION:
            return { bootstrap: false, ready: true, fatal: false, reload: false }
        default:
            return state
    }
}

export default appReducer
