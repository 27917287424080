/*
 *
 * Carts actions
 *
 */

import { action } from 'typesafe-actions'

import ActionTypes from './constants'
import {
    IApiCartLightListResponse,
    IApiMultiStoreCartCollection,
    IApiSalesmanCartListPagination,
    ICart,
    ICartCollection,
    ICartDuplicateParameters,
    ICartItem,
    ICartItemCollection,
    IMultiStoreCartsParameters,
    IReOrderParameters,
    ISalesmanCartListParameters,
} from '../../services/api/service/carts/types'
import {
    BulkCartQuantityAction,
    BulkCartQuantityMode,
    IAddToCartProcessPayload,
    IAddToCartSuccessPayload,
    ICartBulkQuantityFailurePayload,
    ICartBulkQuantityProcessPayload,
    ICartBulkQuantitySuccessPayload,
    ICartDuplicateProcessPayload,
    ICartItemErrorsType,
    ICartItemRefreshErrorsPayload,
    ICartItemStoreQuantity,
    ICartLoadFailurePayload,
    ICartLoadProcessPayload,
    ICartLoadSuccessPayload,
    ICartLockerFailurePayload,
    ICartLockerProcessPayload,
    ICartLockerResetPayload,
    ICartLockerSuccessPayload,
    ICartMultiStoreCartsFailurePayload,
    ICartMultiStoreCartsProcessPayload,
    ICartMultiStoreCartsSuccessPayload,
    ICartRefreshPayload,
    ICartReOrderFailurePayload,
    ICartReOrderProcessPayload,
    ICartReOrderResetPayload,
    ICartReOrderSuccessPayload,
    ICartRevertQuantityPayload,
    ICartSalesmanCartListProcessPayload,
    ICartSaveToOrderFailurePayload,
    ICartSaveToOrderProcessPayload,
    ICartSaveToOrdersFailurePayload,
    ICartSaveToOrdersProcessPayload,
    ICartSaveToOrderSuccessPayload,
    ICartsBaseCleanPayload,
    ICartsCleanSuccessPayload,
    ICartsLockerFailurePayload,
    ICartsLockerProcessPayload,
    ICartsLockerResetPayload,
    ICartsLockerSuccessPayload,
    ICartsPersistParameters,
    ICartsPersistSettingsPayload,
    ICartsRefreshPayload,
    ICartsSwitchCartMode,
    ICartStoreCartLightFailurePayload,
    ICartStoreCartLightProcessPayload,
    ICartStoreCartLightResetPayload,
    ICartStoreCartLightSuccessPayload,
    ICartStoreQuantityFailurePayload,
    ICartStoreQuantityProcessPayload,
    ICartStoreQuantitySuccessPayload,
    ICreateOrderDataCollectionType,
    ICreateOrderDataType,
    IRemoveToCartProcessPayload,
    IRemoveToCartSuccessPayload,
    RevertQuantityErrorsType,
    RevertQuantityErrorType,
    StrictCartMode,
} from './types'
import { IOrdersCreateCollection } from '../../services/api/service/orders/types'
import { CollectionMap } from '../../types/common'
import { IAppErrorTypes } from '../app/types'
import { ProductStockAlertCollection } from '../../services/api/service/products/types'

export const cartsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

export const cartRevertQuantityAction = (
    productId: string,
    quantity: number,
    origin?: string,
    storeId?: string,
    cartId?: string,
    error?: RevertQuantityErrorType,
    errors?: RevertQuantityErrorsType
) =>
    action<ActionTypes.REVERT_QUANTITY_ACTION, ICartRevertQuantityPayload>(ActionTypes.REVERT_QUANTITY_ACTION, {
        productId,
        quantity,
        error,
        errors,
        origin,
        storeId,
        cartId,
    })

export const addToCartProcessAction = (
    productId: string,
    quantity: number,
    quantityPrev: number,
    origin?: string,
    storeId?: string,
    cartId?: string
) =>
    action<ActionTypes.ADD_TO_CART_PROCESS_ACTION, IAddToCartProcessPayload>(ActionTypes.ADD_TO_CART_PROCESS_ACTION, {
        productId,
        quantity,
        quantityPrev,
        origin,
        storeId,
        cartId,
    })

export const addToCartSuccessAction = (cartItem: ICartItem, origin?: string, storeId?: string, cartId?: string) =>
    action<ActionTypes.ADD_TO_CART_SUCCESS_ACTION, IAddToCartSuccessPayload>(ActionTypes.ADD_TO_CART_SUCCESS_ACTION, {
        cartItem,
        origin,
        storeId,
        cartId,
    })

export const removeToCartProcessAction = (
    productId: string,
    quantity: number,
    quantityPrev: number,
    origin?: string,
    storeId?: string,
    cartId?: string
) =>
    action<ActionTypes.REMOVE_TO_CART_PROCESS_ACTION, IRemoveToCartProcessPayload>(
        ActionTypes.REMOVE_TO_CART_PROCESS_ACTION,
        {
            productId,
            quantity,
            quantityPrev,
            origin,
            storeId,
            cartId,
        }
    )

export const removeToCartSuccessAction = (
    productId: string,
    origin?: string,
    storeId?: string,
    cartId?: string,
    cartItem?: ICartItem
) =>
    action<ActionTypes.REMOVE_TO_CART_SUCCESS_ACTION, IRemoveToCartSuccessPayload>(
        ActionTypes.REMOVE_TO_CART_SUCCESS_ACTION,
        {
            productId,
            origin,
            storeId,
            cartId,
            cartItem,
        }
    )

export const cartsRefreshAction = (carts: ICartCollection, main?: boolean) =>
    action<ActionTypes.REFRESH_CARTS_ACTION, ICartsRefreshPayload>(ActionTypes.REFRESH_CARTS_ACTION, { carts, main })

export const cartRefreshAction = (cart: ICart, main?: boolean) =>
    action<ActionTypes.REFRESH_CART_ACTION, ICartRefreshPayload>(ActionTypes.REFRESH_CART_ACTION, { cart, main })

export const cartsBannerShowAction = () => action<ActionTypes.SHOW_BANNER_ACTION>(ActionTypes.SHOW_BANNER_ACTION)
export const cartsBannerHideAction = () => action<ActionTypes.HIDE_BANNER_ACTION>(ActionTypes.HIDE_BANNER_ACTION)
export const cartsBannerToggleAction = () => action<ActionTypes.TOGGLE_BANNER_ACTION>(ActionTypes.TOGGLE_BANNER_ACTION)

export const cartSaveToOrderProcessAction = (
    cartId: string,
    data: ICreateOrderDataType,
    processApiCall: boolean,
    redirectAfterSuccess?: boolean
) =>
    action<ActionTypes.SAVE_TO_ORDER_PROCESS_ACTION, ICartSaveToOrderProcessPayload>(
        ActionTypes.SAVE_TO_ORDER_PROCESS_ACTION,
        {
            cartId,
            data,
            processApiCall,
            redirectAfterSuccess,
        }
    )

export const cartSaveToOrderSuccessAction = (
    cartId: string,
    orders: IOrdersCreateCollection,
    redirectAfterSuccess?: boolean
) =>
    action<ActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION, ICartSaveToOrderSuccessPayload>(
        ActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION,
        {
            orders,
            cartId,
            redirectAfterSuccess,
        }
    )

export const cartSaveToOrderFailureAction = (cartId: string, error: IAppErrorTypes) =>
    action<ActionTypes.SAVE_TO_ORDER_FAILURE_ACTION, ICartSaveToOrderFailurePayload>(
        ActionTypes.SAVE_TO_ORDER_FAILURE_ACTION,
        {
            cartId,
            error,
        }
    )

export const cartReOrderProcessAction = (orderId: string, data: IReOrderParameters, customerId?: string) =>
    action<ActionTypes.REORDER_PROCESS_ACTION, ICartReOrderProcessPayload>(ActionTypes.REORDER_PROCESS_ACTION, {
        resourceId: orderId,
        data,
        customerId,
    })

export const cartReOrderResetAction = (orderId: string) =>
    action<ActionTypes.REORDER_RESET_ACTION, ICartReOrderResetPayload>(ActionTypes.REORDER_RESET_ACTION, {
        resourceId: orderId,
    })

export const cartReOrderSuccessAction = (orderId: string, carts: ICartCollection, warnings?: Array<string>) =>
    action<ActionTypes.REORDER_SUCCESS_ACTION, ICartReOrderSuccessPayload>(ActionTypes.REORDER_SUCCESS_ACTION, {
        resourceId: orderId,
        carts,
        warnings,
    })

export const cartReOrderFailureAction = (orderId: string, error: IAppErrorTypes) =>
    action<ActionTypes.REORDER_FAILURE_ACTION, ICartReOrderFailurePayload>(ActionTypes.REORDER_FAILURE_ACTION, {
        resourceId: orderId,
        error,
    })

export const cartDuplicateProcessAction = (cartId: string, data: ICartDuplicateParameters) =>
    action<ActionTypes.DUPLICATE_PROCESS_ACTION, ICartDuplicateProcessPayload>(ActionTypes.DUPLICATE_PROCESS_ACTION, {
        resourceId: cartId,
        data,
    })

export const cartDuplicateResetAction = (cartId: string) =>
    action<ActionTypes.DUPLICATE_RESET_ACTION, ICartReOrderResetPayload>(ActionTypes.DUPLICATE_RESET_ACTION, {
        resourceId: cartId,
    })

export const cartDuplicateSuccessAction = (cartId: string, carts: ICartCollection, warnings?: Array<string>) =>
    action<ActionTypes.DUPLICATE_SUCCESS_ACTION, ICartReOrderSuccessPayload>(ActionTypes.DUPLICATE_SUCCESS_ACTION, {
        resourceId: cartId,
        carts,
        warnings,
    })

export const cartDuplicateFailureAction = (cartId: string, error: IAppErrorTypes) =>
    action<ActionTypes.DUPLICATE_FAILURE_ACTION, ICartReOrderFailurePayload>(ActionTypes.DUPLICATE_FAILURE_ACTION, {
        resourceId: cartId,
        error,
    })

export const cartSaveToOrdersProcessAction = (cartIds: Array<string>, data: ICreateOrderDataCollectionType) =>
    action<ActionTypes.SAVE_TO_ORDERS_PROCESS_ACTION, ICartSaveToOrdersProcessPayload>(
        ActionTypes.SAVE_TO_ORDERS_PROCESS_ACTION,
        {
            cartIds,
            data,
        }
    )

export const cartSaveToOrdersSuccessAction = () =>
    action<ActionTypes.SAVE_TO_ORDERS_SUCCESS_ACTION>(ActionTypes.SAVE_TO_ORDERS_SUCCESS_ACTION)

export const cartSaveToOrdersFailureAction = (cartIds: Array<string>, errors: CollectionMap<IAppErrorTypes>) =>
    action<ActionTypes.SAVE_TO_ORDERS_FAILURE_ACTION, ICartSaveToOrdersFailurePayload>(
        ActionTypes.SAVE_TO_ORDERS_FAILURE_ACTION,
        {
            cartIds,
            errors,
        }
    )

export const cartRefreshCartItemsErrors = (cartId: string, errors: ICartItemErrorsType) =>
    action<ActionTypes.REFRESH_CART_ITEMS_ERRORS_ACTION, ICartItemRefreshErrorsPayload>(
        ActionTypes.REFRESH_CART_ITEMS_ERRORS_ACTION,
        {
            cartId,
            errors,
        }
    )

export const cartSaveToOrdersResetAction = () =>
    action<ActionTypes.RESET_ORDERS_ACTION>(ActionTypes.RESET_ORDERS_ACTION)

export const cartStoreQuantityProcessAction = (productId: string) =>
    action<ActionTypes.STORE_QUANTITY_PROCESS_ACTION, ICartStoreQuantityProcessPayload>(
        ActionTypes.STORE_QUANTITY_PROCESS_ACTION,
        { productId }
    )

export const cartStoreQuantitySuccessAction = (productId: string, storeQuantity: ICartItemStoreQuantity) =>
    action<ActionTypes.STORE_QUANTITY_SUCCESS_ACTION, ICartStoreQuantitySuccessPayload>(
        ActionTypes.STORE_QUANTITY_SUCCESS_ACTION,
        { productId, storeQuantity }
    )

export const cartStoreQuantityFailureAction = (productId: string, error: IAppErrorTypes) =>
    action<ActionTypes.STORE_QUANTITY_FAILURE_ACTION, ICartStoreQuantityFailurePayload>(
        ActionTypes.STORE_QUANTITY_FAILURE_ACTION,
        { productId, error }
    )

export const cleanCartsProcessAction = (cartIds: Array<string>) =>
    action<ActionTypes.CLEAN_CART_PROCESS_ACTION, ICartsBaseCleanPayload>(ActionTypes.CLEAN_CART_PROCESS_ACTION, {
        cartIds,
    })

export const cleanCartsSuccessAction = (carts: ICartCollection) =>
    action<ActionTypes.CLEAN_CART_SUCCESS_ACTION, ICartsCleanSuccessPayload>(ActionTypes.CLEAN_CART_SUCCESS_ACTION, {
        carts,
    })

export const cartCartLightsProcessAction = (cartIdParent: string) =>
    action<ActionTypes.CART_LIGHT_PROCESS_ACTION, ICartStoreCartLightProcessPayload>(
        ActionTypes.CART_LIGHT_PROCESS_ACTION,
        { cartIdParent }
    )

export const cartCartLightsResetAction = (cartIdParent?: string) =>
    action<ActionTypes.CART_LIGHT_RESET_ACTION, ICartStoreCartLightResetPayload>(ActionTypes.CART_LIGHT_RESET_ACTION, {
        cartIdParent,
    })

export const cartCartLightsReloadAction = () =>
    action<ActionTypes.CART_LIGHT_RELOAD_ACTION>(ActionTypes.CART_LIGHT_RELOAD_ACTION)

export const cartCartLightsSuccessAction = (cartIdParent: string, response: IApiCartLightListResponse) =>
    action<ActionTypes.CART_LIGHT_SUCCESS_ACTION, ICartStoreCartLightSuccessPayload>(
        ActionTypes.CART_LIGHT_SUCCESS_ACTION,
        { cartIdParent, response }
    )

export const cartCartLightFailureAction = (cartIdParent: string, error: IAppErrorTypes) =>
    action<ActionTypes.CART_LIGHT_FAILURE_ACTION, ICartStoreCartLightFailurePayload>(
        ActionTypes.CART_LIGHT_FAILURE_ACTION,
        { cartIdParent, error }
    )

export const cartLoadCartProcessAction = (cartId: string, main?: boolean) =>
    action<ActionTypes.LOAD_CART_PROCESS_ACTION, ICartLoadProcessPayload>(ActionTypes.LOAD_CART_PROCESS_ACTION, {
        cartId,
        main,
    })

export const cartLoadCartSuccessAction = (cartId: string, response: ICart, main?: boolean) =>
    action<ActionTypes.LOAD_CART_SUCCESS_ACTION, ICartLoadSuccessPayload>(ActionTypes.LOAD_CART_SUCCESS_ACTION, {
        cartId,
        main,
        response,
    })

export const cartLoadCartFailureAction = (cartId: string, error: IAppErrorTypes) =>
    action<ActionTypes.LOAD_CART_FAILURE_ACTION, ICartLoadFailurePayload>(ActionTypes.LOAD_CART_FAILURE_ACTION, {
        cartId,
        error,
    })

export const cartBulkCartQuantityProcessAction = (
    bulkMode: BulkCartQuantityMode,
    bulkAction: BulkCartQuantityAction,
    quantity = 1
) =>
    action<ActionTypes.BULK_ADD_TO_CART_PROCESS_ACTION, ICartBulkQuantityProcessPayload>(
        ActionTypes.BULK_ADD_TO_CART_PROCESS_ACTION,
        {
            quantity: bulkAction === BulkCartQuantityAction.Remove && quantity > 0 ? quantity * -1 : quantity,
            mode: bulkMode,
            action: bulkAction,
        }
    )

export const cartBulkCartQuantitySuccessAction = (
    items: ICartItemCollection,
    unavailableStockProducts: ProductStockAlertCollection
) =>
    action<ActionTypes.BULK_ADD_TO_CART_SUCCESS_ACTION, ICartBulkQuantitySuccessPayload>(
        ActionTypes.BULK_ADD_TO_CART_SUCCESS_ACTION,
        {
            items,
            unavailableStockProducts,
        }
    )

export const cartBulkCartQuantityFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.BULK_ADD_TO_CART_FAILURE_ACTION, ICartBulkQuantityFailurePayload>(
        ActionTypes.BULK_ADD_TO_CART_FAILURE_ACTION,
        { error }
    )

export const cartBulkCartQuantityResetAction = () =>
    action<ActionTypes.BULK_ADD_TO_CART_RESET_ACTION>(ActionTypes.BULK_ADD_TO_CART_RESET_ACTION)

export const cartFetchCartsAction = () => action<ActionTypes.FETCH_CARTS_ACTION>(ActionTypes.FETCH_CARTS_ACTION)

export const cartsPersistParamsAction = (params: Partial<ICartsPersistParameters>, merge = false) =>
    action<ActionTypes.PERSIST_PARAMS_ACTION, ICartsPersistSettingsPayload>(ActionTypes.PERSIST_PARAMS_ACTION, {
        params,
        merge,
    })

export const cartsPersistedParamsAction = () =>
    action<ActionTypes.PERSISTED_PARAMS_ACTION>(ActionTypes.PERSISTED_PARAMS_ACTION)

export const cartLockerProcessAction = (cartId: string, lock: boolean, processApiCall: boolean, reloadCarts = true) =>
    action<ActionTypes.LOCKER_PROCESS_ACTION, ICartLockerProcessPayload>(ActionTypes.LOCKER_PROCESS_ACTION, {
        cartId,
        lock,
        processApiCall,
        reloadCarts,
    })

export const cartLockerSuccessAction = (cart: ICart, lock: boolean) =>
    action<ActionTypes.LOCKER_SUCCESS_ACTION, ICartLockerSuccessPayload>(ActionTypes.LOCKER_SUCCESS_ACTION, {
        cart,
        lock,
    })

export const cartLockerFailureAction = (cartId: string, error: IAppErrorTypes, lock: boolean) =>
    action<ActionTypes.LOCKER_FAILURE_ACTION, ICartLockerFailurePayload>(ActionTypes.LOCKER_FAILURE_ACTION, {
        cartId,
        error,
        lock,
    })

export const cartsLockerProcessAction = (cartIds: Array<string>, lock: boolean) =>
    action<ActionTypes.LOCKERS_PROCESS_ACTION, ICartsLockerProcessPayload>(ActionTypes.LOCKERS_PROCESS_ACTION, {
        cartIds,
        lock,
    })

export const cartsLockerSuccessAction = (cartIds: Array<string>, lock: boolean, errors?: Array<IAppErrorTypes>) =>
    action<ActionTypes.LOCKERS_SUCCESS_ACTION, ICartsLockerSuccessPayload>(ActionTypes.LOCKERS_SUCCESS_ACTION, {
        cartIds,
        lock,
        errors,
    })

export const cartsLockerFailureAction = (cartIds: Array<string>, errors: Array<IAppErrorTypes>, lock: boolean) =>
    action<ActionTypes.LOCKERS_FAILURE_ACTION, ICartsLockerFailurePayload>(ActionTypes.LOCKERS_FAILURE_ACTION, {
        cartIds,
        errors,
        lock,
    })

export const cartsLockerResetAction = (cartIds?: Array<string>) =>
    action<ActionTypes.LOCKERS_RESET_ACTION, ICartsLockerResetPayload>(ActionTypes.LOCKERS_RESET_ACTION, {
        cartIds,
    })

export const cartLockerResetAction = (cartId?: string) =>
    action<ActionTypes.LOCKER_RESET_ACTION, ICartLockerResetPayload>(ActionTypes.LOCKER_RESET_ACTION, {
        cartId,
    })

export const cartsSwitchCartMode = (cartMode: StrictCartMode) =>
    action<ActionTypes.SWITCH_CART_MODE, ICartsSwitchCartMode>(ActionTypes.SWITCH_CART_MODE, {
        cartMode,
    })

export const cartMultiStoreCartsProcessAction = (params: IMultiStoreCartsParameters) =>
    action<ActionTypes.CART_MULTI_STORE_PROCESS_ACTION, ICartMultiStoreCartsProcessPayload>(
        ActionTypes.CART_MULTI_STORE_PROCESS_ACTION,
        { params }
    )

export const cartMultiStoreCartsResetAction = () =>
    action<ActionTypes.CART_MULTI_STORE_RESET_ACTION>(ActionTypes.CART_MULTI_STORE_RESET_ACTION)

export const cartMultiStoreCartsSuccessAction = (data: IApiMultiStoreCartCollection) =>
    action<ActionTypes.CART_MULTI_STORE_SUCCESS_ACTION, ICartMultiStoreCartsSuccessPayload>(
        ActionTypes.CART_MULTI_STORE_SUCCESS_ACTION,
        { data }
    )

export const cartMultiStoreCartsFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.CART_MULTI_STORE_FAILURE_ACTION, ICartMultiStoreCartsFailurePayload>(
        ActionTypes.CART_MULTI_STORE_FAILURE_ACTION,
        { error }
    )

export const salesmanCartListProcessAction = (params: ISalesmanCartListParameters) =>
    action<ActionTypes.CART_SALESMAN_LIST_PROCESS_ACTION, ICartSalesmanCartListProcessPayload>(
        ActionTypes.CART_SALESMAN_LIST_PROCESS_ACTION,
        {
            params,
        }
    )
export const salesmanCartListSuccessAction = (response: IApiSalesmanCartListPagination) =>
    action<ActionTypes.CART_SALESMAN_LIST_SUCCESS_ACTION, IApiSalesmanCartListPagination>(
        ActionTypes.CART_SALESMAN_LIST_SUCCESS_ACTION,
        response
    )
export const salesmanCartListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.CART_SALESMAN_LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.CART_SALESMAN_LIST_FAILURE_ACTION,
        { error }
    )
export const salesmanCartListResetAction = () =>
    action<ActionTypes.CART_SALESMAN_LIST_RESET_ACTION>(ActionTypes.CART_SALESMAN_LIST_RESET_ACTION)
