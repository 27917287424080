import React, { memo, MouseEvent, useCallback, useMemo, useRef } from 'react'
import { IOrderDocumentLink } from '../../../services/api/service/orders/types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'

import { IApplicationRootState } from '../../../store'
import { IAppErrorTypes } from '../../../store/app/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import RemotePdf from '../../../components/RemotePdf/RemotePdf'
import isUndefined from 'lodash/isUndefined'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Nullable } from 'tsdef'
import isNull from 'lodash/isNull'
import { invoicesDocumentProcessAction, invoicesDocumentResetSingleAction } from '../../../store/invoices/actions'
import {
    makeSelectInvoicesDocumentErrorWithId,
    makeSelectInvoicesDocumentFetchingWithId,
    makeSelectInvoicesDocumentLinkWithId,
} from '../../../store/invoices/selectors'
import { IInvoiceDocumentLink } from '../../../services/api/service/invoices/types'
import { OrderDetailAction } from '../../OrderDetail/Action/type'

type Props = OrderDetailAction & {
    tooltip?: string | null
    invoiceId: string
    invoiceNumber: string
    onClick?: (e: MouseEvent<HTMLButtonElement>, invoiceId: string) => void
}

const Document = ({
    invoiceId,
    invoiceNumber,
    onClick,
    icon,
    label,
    size,
    block,
    tooltip,
    variant = 'link',
}: Props): JSX.Element => {
    const dispatch = useDispatch()
    const targetBtn = useRef<HTMLButtonElement>()
    const { formatMessage } = useIntl()

    const selectFetching = useMemo(makeSelectInvoicesDocumentFetchingWithId, [])
    const selectLink = useMemo(makeSelectInvoicesDocumentLinkWithId, [])
    const selectError = useMemo(makeSelectInvoicesDocumentErrorWithId, [])
    const fetching: boolean = useSelector<IApplicationRootState, boolean>((state) => selectFetching(state, invoiceId))
    const link: IOrderDocumentLink | undefined = useSelector<IApplicationRootState, IInvoiceDocumentLink | undefined>(
        (state) => selectLink(state, invoiceId)
    )
    const error: IAppErrorTypes | undefined = useSelector<IApplicationRootState, IAppErrorTypes | undefined>((state) =>
        selectError(state, invoiceId)
    )

    const lbl = useMemo(() => {
        if (isUndefined(label)) {
            return formatMessage({ id: `invoice.detail.pdf` })
        }
        return label
    }, [label, formatMessage])

    const icn: Nullable<IconProp> = useMemo(() => {
        if (isUndefined(icon)) {
            return ['fal', `file-pdf`]
        }
        return icon
    }, [icon])

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (onClick) {
                onClick(e, invoiceId)
            }

            dispatch(invoicesDocumentProcessAction(invoiceId))
        },
        [dispatch, onClick, invoiceId]
    )

    const handleConfirmError = useCallback(() => {
        dispatch(invoicesDocumentResetSingleAction(invoiceId))
    }, [dispatch, invoiceId])

    const handleRemotePdfClose = useCallback(() => {
        dispatch(invoicesDocumentResetSingleAction(invoiceId))
    }, [dispatch, invoiceId])

    const tooltipMessage = useMemo(() => {
        if (isUndefined(tooltip)) {
            return formatMessage({ id: `invoice.detail.pdf` })
        }
        return tooltip
    }, [tooltip, formatMessage])

    return (
        <>
            <OverlayTrigger
                show={isNull(tooltipMessage) ? false : undefined}
                overlay={<Tooltip id={`tooltip-${invoiceId}`}>{tooltipMessage}</Tooltip>}
            >
                <Button
                    ref={targetBtn}
                    variant={variant}
                    onClick={handleClick}
                    disabled={fetching}
                    size={size}
                    block={block}
                >
                    {icn && <FontAwesomeIcon icon={icn} />}
                    {lbl && <span className="lbl">{lbl}</span>}
                </Button>
            </OverlayTrigger>
            <SweetAlert
                error
                title={formatMessage({
                    id: 'default.error',
                })}
                show={typeof error !== 'undefined'}
                onConfirm={handleConfirmError}
            >
                {error?.message}
            </SweetAlert>
            {link && <RemotePdf title={invoiceNumber} pdf={link} onExit={handleRemotePdfClose} />}
        </>
    )
}

export default memo(Document)
