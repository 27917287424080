const isExternalRegexClosure = (function () {
    const domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i
    return function (url: string) {
        function domain(url: string) {
            const res = domainRe.exec(url)
            return res ? res[1] : undefined
        }
        const location = window.location.href
        return domain(url) !== null && domain(url) !== undefined && domain(location) !== domain(url)
    }
})()

export default isExternalRegexClosure
