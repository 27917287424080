import ApplicationHelper from '../utils/applicationHelper'
import { ProductListOrderType } from '../services/api/service/products/types'
import { ApiListOrderDirection } from '../services/api/types'

export default {
    // Add common config values here
    APP_NAME: 'U10.com',
    ADVICE_TEL: '+33971455854',
    FONTS: ['Lato', 'Caveat'],
    APP_ROOT_HTML_ID: 'u10-app',
    SALESMAN: {
        CART_ALERT_STORAGE_KEY: 'salesman_cart_alert',
    },
    AUTH: {
        CUSTOMER_ID_STORAGE_NAME: 'customer_id',
        STORE_ID_STORAGE_NAME: 'customer_store_id',
    },
    SIDE_MENU: {
        PAGE_WRAP_ID: 'page-wrap',
        OUTER_CONTAINER_ID: 'u10-app',
        WIDTH: 280,
    },
    I18N: {
        DEFAULT_LOCALE: 'fr',
        FALLBACK_LOCALE: 'en',
        LANGUAGES: ['fr', 'en', 'pt', 'it', 'de', 'es'],
        FLAGS: { fr: 'fr', en: 'gb', pt: 'pt', it: 'it', de: 'de', es: 'es' },
        DEFAULT_CURRENCY: {
            CURRENCY: 'EUR',
            MINIMUM_FRACTION_DIGITS: 2,
            MAXIMUM_FRACTION_DIGITS: 2,
        },
    },
    LOG_ROCKET: {
        ACTIVE:
            process.env.REACT_APP_LOG_ROCKET_ID &&
            typeof process.env.REACT_APP_LOG_ROCKET_ID === 'string' &&
            process.env.REACT_APP_LOG_ROCKET_ID.length > 0,
        IDENTIFIER: process.env.REACT_APP_LOG_ROCKET_ID,
    },
    HOTJAR: {
        ACTIVE:
            process.env.REACT_APP_HOTJAR_ID &&
            typeof process.env.REACT_APP_HOTJAR_ID === 'string' &&
            process.env.REACT_APP_HOTJAR_ID.length > 0 &&
            process.env.REACT_APP_HOTJAR_ID &&
            typeof process.env.REACT_APP_HOTJAR_VERSION === 'string' &&
            process.env.REACT_APP_HOTJAR_VERSION.length > 0,
        IDENTIFIER: process.env.REACT_APP_HOTJAR_ID,
        VERSION: process.env.REACT_APP_HOTJAR_VERSION,
    },
    SENTRY: {
        ACTIVE:
            process.env.REACT_APP_SENTRY_DSN &&
            typeof process.env.REACT_APP_SENTRY_DSN === 'string' &&
            process.env.REACT_APP_SENTRY_DSN.length > 0,
        IDENTIFIER: process.env.REACT_APP_SENTRY_DSN,
    },
    DEBUG: {
        ACTIVE: ApplicationHelper.stringToBool(process.env.REACT_APP_DEBUG_MODE),
        WHY_DID_YOU_RENDER: false,
        BYPASS_ORDER_AVAILABLE: false,
    },
    API: {
        URL: process.env.REACT_APP_API_URL,
        PING_URL: `${process.env.REACT_APP_API_URL}/ping`,
        TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
    },
    STORAGE: {
        VERSION: process.env.REACT_APP_STORAGE_VERSION,
        NAME: process.env.REACT_APP_STORAGE_NAME + '_' + process.env.NODE_ENV,
    },
    LIST: {
        ITEMS_PER_PAGE: 24,
        PAGE_RANGE_DISPLAYED: 5,
    },
    ORDER_LAST: {
        ITEMS_PER_PAGE: 5,
        PAGE_RANGE_DISPLAYED: 5,
    },
    ORDER_LIST: {
        ITEMS_PER_PAGE: 30,
        PAGE_RANGE_DISPLAYED: 5,
        ORDER_ANOMALY: true,
    },
    PICTURE_EXPORT: {
        ITEMS_PER_PAGE: 24,
        PAGE_RANGE_DISPLAYED: 5,
        ENABLED: true,
    },
    STATISTICS_TOP_PRODUCTS: {
        ITEMS_PER_PAGE: 5,
        PAGE_RANGE_DISPLAYED: 5,
    },
    INVOICE_LIST: {
        ITEMS_PER_PAGE: 24,
        PAGE_RANGE_DISPLAYED: 5,
    },
    CART: {
        SETTINGS_STORAGE_NAME: 'customer_cart_settings',
        DISPLAY_REQUESTED_DELIVERY_DATE: true,
    },
    ORDER: {
        SETTINGS_STORAGE_NAME: 'customer_order_settings',
    },
    PRODUCT_LIST: {
        ITEMS_PER_PAGE: 48,
        PAGE_RANGE_DISPLAYED: 5,
        SORT: { type: ProductListOrderType.Position, direction: ApiListOrderDirection.Asc },
        SETTINGS_STORAGE_NAME: 'customer_product_list_settings',
        SEARCH_BY_IMAGE: ApplicationHelper.stringToBool(process.env.REACT_APP_USE_GOOGLE_VISION),
        SEARCH_BY_BARCODE: true,
    },
    PRODUCT_DETAIL: {
        CROSS_SELL: {
            ITEMS_PER_PAGE: 12,
        },
    },
    UI: {
        BREAKPOINTS: {
            SM: 540,
            MD: 720,
            LG: 960,
            XL: 1320,
            XXL: 1400,
        },
    },
    GOOGLE: {
        ANALYTICS: {
            ACTIVE:
                process.env.REACT_APP_ANALYTICS_ID &&
                typeof process.env.REACT_APP_ANALYTICS_ID === 'string' &&
                process.env.REACT_APP_ANALYTICS_ID.length > 0,
            IDENTIFIER: process.env.REACT_APP_ANALYTICS_ID,
        },
    },
}
