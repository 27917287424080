/*
 *
 * Search reducer
 *
 */

import ActionTypes from './constants'
import { ContainerState, ContainerActions } from './types'

export const initialState: ContainerState = {
    fetching: false,
    params: {
        page: 1,
    },
    products: [],
    families: [],
}

function searchReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return {
                ...initialState,
                cancelToken: state.cancelToken,
            }
        case ActionTypes.STORE_CANCEL_TOKEN:
            return {
                ...state,
                cancelToken: action.payload.token,
            }
        case ActionTypes.STORE_SEARCH:
            return {
                ...state,
                params: {
                    ...state.params,
                    search: action.payload.search,
                },
            }
        case ActionTypes.PROCESS_ACTION:
            return {
                ...state,
                params: action.payload.params,
                tms: undefined,
                fetching: true,
                error: undefined,
                products: [],
                families: [],
            }
        case ActionTypes.FAILURE_ACTION:
            return {
                ...state,
                tms: Date.now(),
                fetching: false,
                error: action.payload.error,
                cancelToken: undefined,
            }
        case ActionTypes.SUCCESS_ACTION:
            return {
                ...state,
                ...action.payload,
                fetching: false,
                cancelToken: undefined,
            }
        default:
            return state
    }
}

export default searchReducer
