import { IApiErrorDescription, IAppErrorErrorsInnerDescription } from '../store/http/types'

export function violationStandardToString(error: IApiErrorDescription): string | null | undefined {
    let message = error.error
    if (error.errors) {
        let all: Array<string> = []
        const violations: Array<IAppErrorErrorsInnerDescription> = Object.values(error.errors)
        for (const idx in violations) {
            all = [...all, ...violations[idx]]
        }
        message = all.join('\n')
    }
    return message
}
