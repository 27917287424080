import React from 'react'
import { createStructuredSelector } from 'reselect'
import { makeSelectAuthLoggedIn, makeSelectAuthMe } from '../store/auth/selectors'
import { makeSelectCustomer, makeSelectCustomerStore } from '../store/customers/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../store'
import { IMe } from '../services/api/service/me/types'
import { ICustomer } from '../services/api/service/customers/types'
import { generatePath, Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { orderAvailableVoter } from '../routes/voters'
import { getPath } from '../routes'

const stateSelector = createStructuredSelector<any, any>({
    loggedIn: makeSelectAuthLoggedIn(),
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
})

function LoggedPage(): JSX.Element {
    const { locale } = useIntl()
    // récupération des informations de la personne connectée
    const { loggedIn, customer, me, store } = useSelector<
        IApplicationRootState,
        {
            loggedIn: boolean
            me?: IMe
            customer?: ICustomer
            store?: ICustomer
        }
    >(stateSelector)

    if (!loggedIn || (!customer && !me)) {
        return <></>
    }

    // redirect vers la home ou son espace client !
    if (customer) {
        if (!orderAvailableVoter(customer, store)) {
            return <Redirect to={generatePath(getPath('customerArea', locale), { lang: locale })} />
        }
        return <Redirect to={`/${locale}`} />
    }

    return <Redirect to={generatePath(getPath('salesmanArea', locale), { lang: locale })} />
}

export default LoggedPage
