/*
 *
 * Carts constants
 *
 */

enum ActionTypes {
    FETCH_CARTS_ACTION = 'app/Carts/List/FETCH',
    REFRESH_CARTS_ACTION = 'app/Carts/List/REFRESH',
    REFRESH_CART_ACTION = 'app/Carts/Single/REFRESH',
    REFRESH_CART_ITEMS_ERRORS_ACTION = 'app/Carts/Items/Errors/REFRESH',
    ADD_TO_CART_PROCESS_ACTION = 'app/Carts/Items/Add/PROCESS',
    ADD_TO_CART_SUCCESS_ACTION = 'app/Carts/Items/Add/SUCCESS',
    CLEAN_CART_PROCESS_ACTION = 'app/Carts/Clean/PROCESS',
    CLEAN_CART_SUCCESS_ACTION = 'app/Carts/Clean/SUCCESS',
    REVERT_QUANTITY_ACTION = 'app/Carts/Items/Quantity/REVERT',
    REMOVE_TO_CART_PROCESS_ACTION = 'app/Carts/Items/Remove/PROCESS',
    REMOVE_TO_CART_SUCCESS_ACTION = 'app/Carts/Items/Remove/SUCCESS',
    RESET_ACTION = 'app/Carts/RESET',
    RESET_ORDERS_ACTION = 'app/Carts/List/SaveToOrder/RESET',
    SAVE_TO_ORDERS_PROCESS_ACTION = 'app/Carts/List/SaveToOrder/PROCESS',
    SAVE_TO_ORDERS_SUCCESS_ACTION = 'app/Carts/List/SaveToOrder/SUCCESS',
    SAVE_TO_ORDERS_FAILURE_ACTION = 'app/Carts/List/SaveToOrder/FAILURE',
    SAVE_TO_ORDER_PROCESS_ACTION = 'app/Carts/Single/SaveToOrder/PROCESS',
    SAVE_TO_ORDER_SUCCESS_ACTION = 'app/Carts/Single/SaveToOrder/SUCCESS',
    SAVE_TO_ORDER_FAILURE_ACTION = 'app/Carts/Single/SaveToOrder/FAILURE',
    LOCKERS_PROCESS_ACTION = 'app/Carts/List/Locker/PROCESS',
    LOCKERS_SUCCESS_ACTION = 'app/Carts/List/Locker/SUCCESS',
    LOCKERS_FAILURE_ACTION = 'app/Carts/List/Locker/FAILURE',
    LOCKERS_RESET_ACTION = 'app/Carts/List/Locker/RESET',
    LOCKER_PROCESS_ACTION = 'app/Carts/Single/Locker/PROCESS',
    LOCKER_SUCCESS_ACTION = 'app/Carts/Single/Locker/SUCCESS',
    LOCKER_FAILURE_ACTION = 'app/Carts/Single/Locker/FAILURE',
    LOCKER_RESET_ACTION = 'app/Carts/Single/Locker/RESET',
    REORDER_PROCESS_ACTION = 'app/Carts/Single/ReOrder/PROCESS',
    REORDER_SUCCESS_ACTION = 'app/Carts/Single/ReOrder/SUCCESS',
    REORDER_FAILURE_ACTION = 'app/Carts/Single/ReOrder/FAILURE',
    REORDER_RESET_ACTION = 'app/Carts/Single/ReOrder/RESET',
    DUPLICATE_PROCESS_ACTION = 'app/Carts/Single/Duplicate/PROCESS',
    DUPLICATE_SUCCESS_ACTION = 'app/Carts/Single/Duplicate/SUCCESS',
    DUPLICATE_FAILURE_ACTION = 'app/Carts/Single/Duplicate/FAILURE',
    DUPLICATE_RESET_ACTION = 'app/Carts/Single/Duplicate/RESET',
    SHOW_BANNER_ACTION = 'app/Carts/Banner/SHOW_ACTION',
    HIDE_BANNER_ACTION = 'app/Carts/Banner/HIDE_ACTION',
    TOGGLE_BANNER_ACTION = 'app/Carts/Banner/TOGGLE_ACTION',
    STORE_QUANTITY_PROCESS_ACTION = 'app/Carts/StoreQuantity/REQUEST_PROCESS',
    STORE_QUANTITY_SUCCESS_ACTION = 'app/Carts/StoreQuantity/REQUEST_SUCCESS',
    STORE_QUANTITY_FAILURE_ACTION = 'app/Carts/StoreQuantity/REQUEST_FAILURE',
    CART_LIGHT_PROCESS_ACTION = 'app/Carts/Sub/Light/REQUEST_PROCESS',
    CART_LIGHT_SUCCESS_ACTION = 'app/Carts/Sub/Light/REQUEST_SUCCESS',
    CART_LIGHT_FAILURE_ACTION = 'app/Carts/Sub/Light/REQUEST_FAILURE',
    CART_LIGHT_RESET_ACTION = 'app/Carts/Sub/Light/RESET',
    CART_LIGHT_RELOAD_ACTION = 'app/Carts/Sub/Light/RELOAD',
    LOAD_CART_PROCESS_ACTION = 'app/Carts/Single/Load/REQUEST_PROCESS',
    LOAD_CART_SUCCESS_ACTION = 'app/Carts/Single/Load/REQUEST_SUCCESS',
    LOAD_CART_FAILURE_ACTION = 'app/Carts/Single/Load/REQUEST_FAILURE',
    BULK_ADD_TO_CART_RESET_ACTION = 'app/Carts/Items/Bulk/Add/RESET',
    BULK_ADD_TO_CART_PROCESS_ACTION = 'app/Carts/Items/Bulk/Add/PROCESS',
    BULK_ADD_TO_CART_SUCCESS_ACTION = 'app/Carts/Items/Bulk/Add/SUCCESS',
    BULK_ADD_TO_CART_FAILURE_ACTION = 'app/Carts/Items/Bulk/Add/FAILURE',
    PERSIST_PARAMS_ACTION = 'app/Carts/Params/PERSIST',
    PERSISTED_PARAMS_ACTION = 'app/Carts/Params/PERSISTED',
    SWITCH_CART_MODE = 'app/Carts/Mode/SWITCH',

    CART_MULTI_STORE_PROCESS_ACTION = 'app/Carts/MultiStore/REQUEST_PROCESS',
    CART_MULTI_STORE_SUCCESS_ACTION = 'app/Carts/MultiStore/REQUEST_SUCCESS',
    CART_MULTI_STORE_FAILURE_ACTION = 'app/Carts/MultiStore/REQUEST_FAILURE',
    CART_MULTI_STORE_RESET_ACTION = 'app/Carts/MultiStore/RESET',

    CART_SALESMAN_LIST_PROCESS_ACTION = 'app/Carts/Salesman/List/REQUEST_PROCESS',
    CART_SALESMAN_LIST_SUCCESS_ACTION = 'app/Carts/Salesman/List/REQUEST_SUCCESS',
    CART_SALESMAN_LIST_FAILURE_ACTION = 'app/Carts/Salesman/List/REQUEST_FAILURE',
    CART_SALESMAN_LIST_RESET_ACTION = 'app/Carts/Salesman/List/RESET',
}

export default ActionTypes
