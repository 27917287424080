/**
 *
 * Range
 *
 */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@grubersjoe/slide-menu/dist/slide-menu.ie'
import classNames from 'classnames'
import { objectEquals } from 'object-equals'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { ISlideMenuItem, ISlideMenuItemCollection } from '../../store/classification/types'
import FlatIcon from '../Icon/FlatIcon'
import { Badge } from 'react-bootstrap'

export interface ISlideMenuProps {
    identifier: string
    className?: string
    items: ISlideMenuItemCollection
    position: 'left' | 'right'
    showBackLink?: boolean
    isOpen: boolean
    navigateTo?: string
    onStateChange?: (open: boolean) => void
}

type IMenuItemProps = {
    item: ISlideMenuItem
    bypassChildren?: boolean
    className?: string
    label?: string
    faIcon?: IconProp
    icon?: string
}

declare let SlideMenu: any // Magic

function MenuItem({ item, className, bypassChildren, label, icon, faIcon }: IMenuItemProps): JSX.Element {
    const { formatMessage } = useIntl()
    const itemBadge = useMemo(() => {
        return item.badge || null
    }, [item])

    const Component = (
        <>
            {!icon && !faIcon && item.icon && <FlatIcon icon={item.icon} className={'icon'} />}
            {icon && <FlatIcon icon={icon} className={'icon'} />}
            {faIcon && <FontAwesomeIcon icon={faIcon} className={'icon'} />}
            <span className={'lbl'}>{label || item.label}</span>
            {itemBadge && (
                <Badge pill variant="primary" className="ml-2">
                    {itemBadge}
                </Badge>
            )}
        </>
    )

    return (
        <li
            className={classNames(
                'menu-item',
                `menu-item-${item.type || 'default'}`,
                { 'has-children': !bypassChildren && item.children && item.children.length > 0 },
                { 'with-badge': typeof itemBadge === 'string' },
                item.className,
                className
            )}
        >
            {item.url && (typeof item.external === 'undefined' || !item.external) && (
                <NavLink to={item.url} className={'nav-link'} id={`menu-link-${item.id}`}>
                    {Component}
                </NavLink>
            )}
            {item.url && typeof item.external === 'boolean' && item.external && (
                <a href={item.url} className={'nav-link'} id={`menu-link-${item.id}`}>
                    {Component}
                </a>
            )}
            {!item.url && (
                <span className={'nav-link'} id={`menu-link-${item.id}`}>
                    {Component}
                </span>
            )}
            {!bypassChildren && item.children && (
                <ul className={'slide-menu-item-child'}>
                    {item.children?.map((child) => (
                        <MenuItemEntry key={`slide_menu_item_${child.label}_${child.url}`} item={child} />
                    ))}
                    {item.show && (
                        <MenuItemEntry
                            key={`slide_menu_item_${item.label}_${item.url}_all`}
                            item={item}
                            className={'show-all'}
                            bypassChildren={true}
                            faIcon={['fad', 'eye']}
                            label={label || formatMessage({ id: 'default.view_all' })}
                        />
                    )}
                </ul>
            )}
        </li>
    )
}

const MenuItemEntry = memo(MenuItem)

function MenuList({
    isOpen,
    navigateTo,
    identifier,
    className,
    items,
    showBackLink,
    position,
    onStateChange,
}: ISlideMenuProps): JSX.Element {
    const [instance, setInstance] = useState(undefined)
    const [currentItems, setCurrentItems] = useState<ISlideMenuItemCollection | undefined>(undefined)
    const [currentNavigateTo, setCurrentNavigateTo] = useState<string | undefined>(undefined)
    const navRef = useRef<HTMLElement | null>(null)

    useEffect(() => {
        if (navRef.current) {
            if (!objectEquals(items, currentItems)) {
                if (instance) {
                    // @ts-ignore
                    instance!.destroy()
                    setInstance(undefined)
                    return
                }
                if (items.length > 0) {
                    // eslint-disable-next-line no-undef
                    const menu = new SlideMenu(navRef.current, {
                        showBackLink: showBackLink || true,
                        position: position || 'left',
                    })
                    setInstance(menu)
                    setCurrentItems(items)
                }
            }
        }
    }, [items, instance, currentItems, setInstance, setCurrentItems, position, showBackLink])

    useEffect(() => {
        if (navRef.current) {
            navRef.current.addEventListener('sm.open-after', () => {
                if (onStateChange) {
                    onStateChange(true)
                }
            })
            navRef.current.addEventListener('sm.close-after', () => {
                if (onStateChange) {
                    onStateChange(false)
                }
            })
        }
    }, [navRef, onStateChange])

    useEffect(() => {
        if (instance) {
            if (isOpen) {
                // @ts-ignore
                instance.open()
            } else {
                // @ts-ignore
                instance.close()
            }
        }
    }, [isOpen, instance])

    useEffect(() => {
        if (navigateTo !== currentNavigateTo) {
            if (instance) {
                if (navigateTo) {
                    // @ts-ignore
                    instance.navigateTo(`#menu-link-${navigateTo}`)
                }
                setCurrentNavigateTo(navigateTo)
            }
        }
    }, [navigateTo, instance, currentNavigateTo, setCurrentNavigateTo])

    const handleOverlayClick = useCallback(() => {
        if (onStateChange) {
            onStateChange(false)
        }
    }, [onStateChange])
    return (
        <>
            <div className={classNames('slide-menu-overlay', { 'd-none': !isOpen })} onClick={handleOverlayClick} />
            <nav className={classNames('slide-menu', className)} id={identifier} ref={navRef}>
                <ul className="list-unstyled">
                    {items.map((item) => (
                        <MenuItemEntry key={`slide_menu_item_${item.label}_${item.url}`} item={item} />
                    ))}
                </ul>
            </nav>
        </>
    )
}

MenuList.defaultProps = {
    position: 'left',
    showBackLink: true,
    open: true,
} as Partial<ISlideMenuProps>

export default MenuList
