/**
 *
 * AppLoader
 *
 */
import classNames from 'classnames'
import React, { memo, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'
interface IProps {
    animated: boolean
    show: boolean
}

function PageInnerPlaceholder(): JSX.Element {
    return (
        <div className={'loader-page-inner-content'}>
            <Row>
                <Col xs={24} md={8}>
                    <div className={classNames('loader-page-image')}>
                        <div className={'loader-page-image-container'}>
                            <div className={'loader-page-image-inner'}>
                                <RectShape />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <TextBlock rows={1} color={'gray'} className={'loader-page-inner-title'} />
                    <TextBlock rows={12} color={'gray'} className={'loader-page-inner-text'} />
                </Col>
            </Row>
        </div>
    )
}

function PageInnerLoader({ animated, show }: IProps): JSX.Element {
    useEffect(() => {
        if (show) {
            document.documentElement.classList.add('page-inner-loading')
        } else {
            document.documentElement.classList.remove('page-inner-loading')
        }

        return () => {
            document.documentElement.classList.remove('page-inner-loading')
        }
    }, [show])

    return (
        <div
            className={classNames('loader-page-inner', {
                'animate__animated animate__fadeIn': animated && show,
                'd-none': !animated && !show,
                'animate__animated animate__fadeOut': animated && !show,
            })}
        >
            <ReactPlaceholder ready={false} customPlaceholder={<PageInnerPlaceholder />}>
                <FormattedMessage id={'default.loading'} />
            </ReactPlaceholder>
        </div>
    )
}

PageInnerLoader.defaultProps = {
    animated: true,
    show: true,
} as Partial<IProps>
export default memo(PageInnerLoader)
