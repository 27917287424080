/**
 *
 * CmsWidgetFactory
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import FlatIcon from '../Icon/FlatIcon'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'

interface IProps {
    customer: ICustomer
    storeId?: string
    me?: IMe
}

function Banner({ customer, storeId, me }: IProps): JSX.Element {
    const isMultiStoreCustomer =
        customer.account_type === ICustomerType.MultiStore && customer.stores && customer.stores.length > 0
    let selectedStore: ICustomer | undefined = undefined
    if (isMultiStoreCustomer && storeId) {
        const idx = customer.stores!.findIndex((store) => store.id === storeId)
        if (idx > -1) {
            selectedStore = customer.stores![idx]
        }
    }
    return (
        <div className={classNames('account', 'account-banner')}>
            <span className={'customer-avatar'}>
                <FlatIcon icon={'user'} />
            </span>
            {!isSalesmanResource(me) && (
                <>
                    <span className={'customer-business-name'}>
                        <FormattedMessage id={'customer.hello'}>
                            {(txt) => (
                                <span className={'value'}>
                                    {txt}{' '}
                                    <span className={'business-name'}>
                                        {selectedStore ? selectedStore.business_name : customer.business_name}
                                    </span>
                                </span>
                            )}
                        </FormattedMessage>
                    </span>
                    <span className={'separator'}>|</span>
                </>
            )}
            <span className={'customer-code'}>
                <FormattedMessage
                    id={'customer.code'}
                    values={{ code: selectedStore ? selectedStore.code : customer.code }}
                />
            </span>
            <span className={'separator'}>|</span>
            <span className={'customer-address-code'}>
                <FormattedMessage
                    id={'customer.address_code'}
                    values={{ code: selectedStore ? selectedStore.address_code : customer.address_code }}
                />
            </span>
        </div>
    )
}

Banner.defaultProps = {} as Partial<IProps>

export default memo(Banner)
