import { AxiosInstance } from 'axios'
import {
    IApiMeClassificationResponse,
    IApiMeTopProductsResponse,
    IApiMeTopSalesResponse,
    IMeClassificationParameters,
    IMeStatisticsTopProductsParameters,
    IMeStatisticsTopSalesParameters,
    IStatisticsService,
    IApiMeYearlyStatsResponse,
} from './types'

const create = (instance: AxiosInstance): IStatisticsService => {
    /*****************************
     * METHODS
     *****************************/
    const familiesTree = (params: IMeClassificationParameters): Promise<IApiMeClassificationResponse> => {
        return instance.get('/stats/departments', { params: params })
    }
    const topSales = (params: IMeStatisticsTopSalesParameters): Promise<IApiMeTopSalesResponse> => {
        return instance.get('/stats/sales', { params: params })
    }

    const topProducts = (params: IMeStatisticsTopProductsParameters): Promise<IApiMeTopProductsResponse> => {
        return instance.get('/stats/top-products', { params: params })
    }

    const yearlyStats = (): Promise<IApiMeYearlyStatsResponse> => {
        return instance.get('/stats/yearly')
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        familiesTree,
        topSales,
        topProducts,
        yearlyStats,
    }
}

export default {
    create,
}
