/**
 *
 * MegaItem
 *
 */
import React, { memo } from 'react'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    type: string
}

function ItemIcon({ type }: IProps): JSX.Element {
    let iconName: string | null = null
    if (type === 'location') {
        iconName = 'warehouse'
        // iconName = 'delivery-truck'
    } else if (type === 'department') {
        iconName = 'arrow-right'
    }

    return <>{iconName && <FlatIcon icon={iconName} />}</>
}

ItemIcon.defaultProps = {} as Partial<IProps>

const areEqual = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.type === nextProps.type
}

export default memo(ItemIcon, areEqual)
