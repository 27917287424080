/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IProductDetail } from '../../../services/api/service/products/types'
import {
    ProductQuantityMultipleAskedCallback,
    ProductRestockSubscriptionAlertChangeCallback,
} from '../../../types/productCallback'
import QuantitySelector, {
    QuantityAlertDisplayMode,
    QuantitySelectorCallback,
} from '../../QuantitySelector/QuantitySelector'

interface IProps {
    product: IProductDetail
    onQuantityChange?: QuantitySelectorCallback
    onProductQuantityMultipleAsked?: ProductQuantityMultipleAskedCallback
    onProductQuantityRestockAlertChange?: ProductRestockSubscriptionAlertChangeCallback
    quantitySelectorLocked?: boolean
    quantitySelectorDefaultValue?: number
    quantitySelectorMultiple?: boolean
    quantitySelectorSaving?: boolean
    quantityCanIncrement: boolean
    quantityOutOfStock: boolean
    quantityOutOfStockLabel: string
    disabled?: boolean
    showTotalPrice?: boolean
    showPrices?: boolean
    errors?: Array<string>
}

function ProductQuantitySection({
    product,
    onQuantityChange,
    onProductQuantityMultipleAsked,
    quantitySelectorMultiple,
    quantitySelectorDefaultValue,
    quantitySelectorSaving,
    quantityCanIncrement,
    disabled,
    onProductQuantityRestockAlertChange,
    errors,
    quantitySelectorLocked,
    showTotalPrice = true,
    showPrices = true,
}: IProps): JSX.Element {
    const handleOnQuantityAlertSubscriptionChange = useCallback(
        (active: boolean) => {
            if (onProductQuantityRestockAlertChange) {
                onProductQuantityRestockAlertChange(product, active)
            }
        },
        [product, onProductQuantityRestockAlertChange]
    )

    const handleOnQuantitySelectorClick = useCallback(
        (enabled: boolean, saving: boolean, multiple: boolean) => {
            if (multiple && quantitySelectorMultiple) {
                if (onProductQuantityMultipleAsked) {
                    onProductQuantityMultipleAsked(
                        product,
                        quantitySelectorDefaultValue || 0,
                        quantitySelectorDefaultValue || 0
                    )
                }
            }
        },
        [quantitySelectorMultiple, onProductQuantityMultipleAsked, product, quantitySelectorDefaultValue]
    )
    return (
        <div className={classNames('product-quantity-section')}>
            <Row className={'product-quantity-section'}>
                <Col className={'product-quantity-section-col-quantity'}>
                    <QuantitySelector
                        showPacking
                        showTotalPrice={showTotalPrice}
                        onQuantityChange={onQuantityChange}
                        onSelectorClick={handleOnQuantitySelectorClick}
                        packing={product.packing}
                        currentValue={quantitySelectorDefaultValue}
                        saving={quantitySelectorSaving}
                        multiple={quantitySelectorMultiple}
                        multipleButtonLabel={'product.update_quantity'}
                        arrivalStock={product.arrival_stock}
                        remainingStock={product.stock}
                        canIncrement={quantityCanIncrement}
                        outOfStock={product.out_of_stock}
                        outOfStockLabel={product.coming_soon ? 'default.coming_soon' : 'product.out_of_stock'}
                        unitPrice={product.price}
                        disabled={disabled}
                        quantityAlertDisplayMode={QuantityAlertDisplayMode.Insert}
                        restockAlertSubscribed={product.has_stock_alert}
                        restockAlertEnabled={product.can_add_stock_alert}
                        onRestockAlertSubscriptionChange={handleOnQuantityAlertSubscriptionChange}
                        locked={quantitySelectorLocked}
                        uniqId={product.id}
                        errors={errors}
                        showPrices={showPrices}
                    />
                </Col>
            </Row>
        </div>
    )
}

ProductQuantitySection.defaultProps = {} as Partial<IProps>

export default memo(ProductQuantitySection)
