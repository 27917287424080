/**
 *
 * Item
 *
 */
import classNames from 'classnames'
import React, { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { ICmsWidgetHighlightCollectionItem } from '../../../../../services/api/service/cms/types'
import { IProductList } from '../../../../../services/api/service/products/types'
import { CollectionClickCallback } from '../../../../../types/collection'
import { default as ProductCard } from '../../../../Product/Card/Card'

interface IProps {
    collection: ICmsWidgetHighlightCollectionItem
    onCollectionClick?: CollectionClickCallback
    prefixTitle?: boolean
    showPrices?: boolean
}

function CarouselItem({ collection, onCollectionClick, prefixTitle = true, showPrices = true }: IProps): JSX.Element {
    const productsCount = collection.count
    const itemsCount = collection.products.length

    const handleCollectionClick = (event: MouseEvent) => {
        event.preventDefault()
        if (onCollectionClick) {
            onCollectionClick(collection)
        }
    }

    const handleProductClick = (product: IProductList, index: number) => {
        if (index === itemsCount - 1) {
            if (onCollectionClick) {
                onCollectionClick(collection)
            }
            return
        }

        if (onCollectionClick) {
            onCollectionClick(collection, product, index)
        }
    }

    const handleSeeDetailClick = () => {
        if (onCollectionClick) {
            onCollectionClick(collection)
        }
    }

    const Overlay = (
        <div className={classNames('product-content', 'product-overlay')} onClick={handleSeeDetailClick}>
            <div className={'product-overlay-inner'}>
                <button className={'btn btn-primary show-detail'}>
                    <FormattedMessage id="cms.collection.see_all" values={{ total: productsCount }} />
                </button>
            </div>
        </div>
    )

    return (
        <div className={'carousel-app-item'}>
            <div className={'carousel-item-content'}>
                <div className={'collection-picture'}>
                    <div className="embed-responsive">
                        {collection.image && (
                            <img
                                className={'embed-responsive-item'}
                                src={collection.image.default}
                                alt={collection.label}
                                onClick={handleCollectionClick}
                            />
                        )}
                    </div>
                </div>
                <div className={'collection-product-list'}>
                    <div className="embed-responsive embed-responsive-1by1">
                        <div className="embed-responsive-item">
                            {collection.products.map((product, index) => (
                                <ProductCard
                                    showPrices={showPrices}
                                    loadEagerly={true}
                                    key={`collection_item_${product['@id']}`}
                                    product={product}
                                    onImageClick={() => handleProductClick(product, index)}
                                    overlay={index === itemsCount - 1 ? Overlay : null}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {collection.url && (
                <p className={'collection-name'} onClick={handleCollectionClick}>
                    {prefixTitle && <FormattedMessage id={'default.collection'} />}
                    {prefixTitle && ' '}
                    {prefixTitle && <strong className={'value'}>{collection.label}</strong>}
                    {!prefixTitle && <span className={'value'}>{collection.label}</span>}
                </p>
            )}
        </div>
    )
}

export default CarouselItem
