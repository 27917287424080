import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Overlay, Popover, ProgressBar } from 'react-bootstrap'

type IProps = {
    uniqId: string
    volume: number
    steps?: Record<number, string>
    max?: number
}

function ContainerVolume({
    uniqId,
    volume,
    steps = { 28: "20'", 56: "40'", 65: '40HQ' },
    max = 65.0,
}: IProps): JSX.Element {
    const arrowRef = useRef() as React.MutableRefObject<HTMLElement>
    const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const [showTooltip, setShowTooltip] = useState(false)
    const [arrowWidth, setArrowWidth] = useState<number>(0)

    // console.log(volume, volume / max, volumes)
    // on regarde le nombre de conteneur à créer
    const pos = useMemo(() => {
        const maxVolume = Math.min(volume, max)
        return (maxVolume * 100) / max
    }, [volume, max])

    const roundedVolume = useMemo(() => {
        return Math.round((volume + Number.EPSILON) * 100) / 100
    }, [volume])

    const handleHelpMouseEnter = useCallback(() => {
        setShowTooltip(true)
    }, [setShowTooltip])

    const handleHelpMouseLeave = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    useEffect(() => {
        if (arrowRef.current) {
            setArrowWidth(arrowRef.current?.getBoundingClientRect().width)
        }
    }, [arrowRef.current, setArrowWidth])

    const values = useMemo(() => {
        return Object.keys(steps).map((step) => parseFloat(step))
    }, [steps])

    const labels = useMemo(() => {
        return Object.values(steps)
    }, [steps])

    return (
        <div className={classNames('container-volume')}>
            <Overlay placement={'top'} target={containerRef!.current} show={showTooltip}>
                <Popover id={`popover-volume-resume-${uniqId}`}>
                    <Popover.Content>
                        <span>
                            {roundedVolume} m<sup>3</sup>
                        </span>
                    </Popover.Content>
                </Popover>
            </Overlay>
            <div
                ref={containerRef}
                className="container-volume-inner"
                onMouseEnter={handleHelpMouseEnter}
                onMouseLeave={handleHelpMouseLeave}
            >
                <span ref={arrowRef} className="arrow" style={{ left: `calc(${pos}% - ${arrowWidth / 2}px)` }} />

                {labels.map((label, index) => {
                    const step = values[index]
                    const min = values[index - 1] || 0
                    const now = volume >= step ? step : volume
                    const volumeWidth = `${((step - min) * 100) / max}%`
                    const percentage = Math.round((((now - min) / (step - min)) * 100 * 1000) / 1000)
                    return (
                        <div
                            className={classNames('volume', {
                                empty: percentage === 0,
                                'step-1': percentage > 0 && percentage <= 50,
                                'step-2': percentage > 50 && percentage <= 80,
                                'step-3': percentage > 81,
                            })}
                            key={index}
                            style={{ width: volumeWidth }}
                        >
                            <ProgressBar min={min} max={step} now={now} variant="success" />
                            <span className="info">
                                <span className="lbl">{label}</span>
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default memo(ContainerVolume)
