/*
 *
 * Assistance
 *
 */

import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Modal, ModalProps } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { $PropertyType } from 'utility-types'
import { makeSelectLoginHelpMessage, makeSelectOpeningHours, makeSelectPhoneNumber } from '../../store/config/selectors'
import { makeSelectCustomerSalesmenItems } from '../../store/customers/selectors'
import CustomerSalesmenCard from '../CustomerSalesmen/CustomerSalesmenCard'

interface IProps {
    show: boolean
    onHide: () => void
    onExit: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    phoneNumber: makeSelectPhoneNumber(),
    openingHours: makeSelectOpeningHours(),
    loginHelpMessage: makeSelectLoginHelpMessage(),
    salesmen: makeSelectCustomerSalesmenItems(),
})

function AssistanceModal({ show, onHide, onExit }: IProps): JSX.Element {
    const { loginHelpMessage, salesmen } = useSelector(stateSelector)
    const [helpShown, setHelpShown] = useState<boolean>(false)
    const [modalSize, setModalSize] = useState<$PropertyType<ModalProps, 'size'>>('lg')

    useEffect(() => {
        setHelpShown(show)
    }, [show])

    useEffect(() => {
        if (!salesmen) {
            setModalSize('lg')
            return
        }
        setModalSize('xl')
    }, [salesmen, setModalSize])

    const handleHelpOnHide = () => {
        setHelpShown(false)
        onHide()
    }

    const handleHelpOnExited = () => {
        setHelpShown(false)
        onExit()
    }

    return (
        <Modal
            className={classNames('advice-modal', 'assistance-modal')}
            show={helpShown}
            onExited={handleHelpOnExited}
            onHide={handleHelpOnHide}
            size={modalSize}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={'default.need_help'} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loginHelpMessage && <div className={'login-help-message'}>{ReactHtmlParser(loginHelpMessage)}</div>}
                <CustomerSalesmenCard />
            </Modal.Body>
        </Modal>
    )
}

AssistanceModal.defaultProps = {
    show: false,
} as Partial<IProps>

export default AssistanceModal
