/*
 *
 * TopBar
 *
 */

import React, { useCallback, useRef, useState } from 'react'
import { Col, Container, Row, Form, Tooltip, Overlay } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import ResumeCard from '../../components/Customer/ResumeCard'
import FlatIcon from '../../components/Icon/FlatIcon'
import { ICustomer } from '../../services/api/service/customers/types'
import { IMe } from '../../services/api/service/me/types'
import Button from '../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IOrderMode } from '../../services/api/service/orders/types'
import { $enum } from 'ts-enum-util'
import classNames from 'classnames'

interface IProps {
    orderMode: IOrderMode
    customer?: ICustomer
    store?: ICustomer
    me?: IMe
    onLogout?: () => void
    onOrderModeChange?: (orderMode: IOrderMode) => void
    onSalesmanArea?: () => void
}

function SalesmanTopBar({ customer, onLogout, onOrderModeChange, orderMode }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const inLateSectionRef = useRef() as React.MutableRefObject<HTMLElement>
    const [showInLateTooltip, setShowInLateTooltip] = useState(false)

    const handleChange = useCallback(
        (e) => {
            if (onOrderModeChange) {
                if ($enum(IOrderMode).isValue(e.target.value)) {
                    onOrderModeChange(e.target.value)
                }
            }
        },
        [onOrderModeChange]
    )

    return (
        <section className={classNames('member-top-bar', 'salesman-top-bar', `order-mode-${orderMode}`)}>
            <Container>
                <Row className="align-items-center justify-content-end">
                    <Col md="auto">
                        <Button variant="link" onClick={onLogout} className="btn-switch-account">
                            <FontAwesomeIcon icon={['fad', 'random']} className={'app-icon'} />
                            <span className="lbl">
                                <FormattedMessage id={'default.switch_account'} />
                            </span>
                        </Button>
                    </Col>
                    <Col md="auto">
                        <div className="account-resume">
                            <label className="form-label">
                                <FlatIcon icon="shop" />
                                {customer && customer.has_late_payment && (
                                    <>
                                        <Overlay
                                            target={inLateSectionRef.current}
                                            show={showInLateTooltip}
                                            placement="right"
                                        >
                                            {(props) => (
                                                <Tooltip id={`tooltip-salesman-top-bar-in-late`} {...props}>
                                                    <FormattedMessage id="payment.in_late" />
                                                </Tooltip>
                                            )}
                                        </Overlay>
                                        <span
                                            onMouseEnter={() => setShowInLateTooltip(true)}
                                            onMouseLeave={() => setShowInLateTooltip(false)}
                                            onFocus={() => setShowInLateTooltip(true)}
                                            onBlur={() => setShowInLateTooltip(false)}
                                            className="d-inline-flex"
                                            ref={inLateSectionRef}
                                        >
                                            <FlatIcon icon={'warning'} className={'warning-icon text-danger'} />
                                        </span>
                                    </>
                                )}
                                <span>
                                    {customer && customer.prospect && <FormattedMessage id={`default.prospect`} />}
                                </span>
                            </label>
                            {customer && (
                                <ResumeCard
                                    hideAddressLines
                                    customer={customer}
                                    addressInline
                                    className={'selected-card'}
                                />
                            )}
                            <div className="order-mode">
                                <Form.Group>
                                    <Form.Control size="sm" as="select" value={orderMode} onChange={handleChange}>
                                        {$enum(IOrderMode).map((mode) => (
                                            <option key={mode} value={mode}>
                                                {formatMessage({ id: `order_mode.mode_${mode}` })}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SalesmanTopBar
