/**
 *
 * ProductCarouselTabbed
 *
 */
import React, { memo, useCallback, useContext, useMemo, useRef } from 'react'
import { ICmsWidgetInteractiveSlideTag } from '../../../../services/api/service/cms/types'
import { Image, OverlayTrigger, Popover } from 'react-bootstrap'
import { default as ProductItemList } from '../../../Products/Partial/Item'
import { IProps as ProductItemListProps, ProductListMode } from '../../../../components/Product/List/List'
import { useMediaQueries } from '@react-hook/media-query'
import breakpoints from '../../../../utils/breakpoints'
import { IProductList } from '../../../../services/api/service/products/types'
import { useSwiper } from 'swiper/react'
import Config from '../../../../config'
import SVGPlusRounded from '../../../../assets/svg/plus-rounded.svg'
import SVGPlusRoundedPrimary from '../../../../assets/svg/plus-rounded-primary.svg'
import InteractiveSlideCtx from './Context'

export type TagProps = Omit<
    ProductItemListProps,
    'className' | 'product' | 'picked' | 'productCustomerInfoParts' | 'mode'
> & {
    index: number
    tag: ICmsWidgetInteractiveSlideTag
    bypassCartLocker?: boolean
}

function Tag({ tag, index, onProductClick, ...rest }: TagProps): JSX.Element {
    const swiper = useSwiper()
    const { activePopoverIndex, setActivePopoverIndex } = useContext(InteractiveSlideCtx)

    const ref = useRef() as React.MutableRefObject<HTMLSpanElement>
    const { matches } = useMediaQueries(breakpoints)
    const style = useMemo(() => {
        return { top: `${tag.position_y}%`, left: `${tag.position_x}%` }
    }, [tag])

    const handleOnMouseEnter = useCallback(() => {
        if (matches.mobile) {
            return
        }

        setActivePopoverIndex(index)
        swiper.autoplay.stop()
    }, [matches.mobile, setActivePopoverIndex, index, swiper.autoplay])

    const handleClick = useCallback(() => {
        if (activePopoverIndex !== index) {
            swiper.autoplay.stop()
            setActivePopoverIndex(index)
        } else {
            swiper.autoplay.start()
            setActivePopoverIndex(undefined)
        }
    }, [activePopoverIndex, index, swiper.autoplay, setActivePopoverIndex])

    const handleOnMouseLeave = useCallback(() => {
        if (matches.mobile) {
            return
        }
        setActivePopoverIndex(undefined)
        swiper.autoplay.start()
    }, [matches.mobile, setActivePopoverIndex, swiper.autoplay])

    const handleProductClick = useCallback(
        (product: IProductList) => {
            setActivePopoverIndex(undefined)
            ref.current.dispatchEvent(
                new MouseEvent('mouseleave', {
                    bubbles: true,
                    cancelable: true,
                    clientX: 0,
                    clientY: 0,
                })
            )
            if (onProductClick) {
                onProductClick(product)
            }
        },
        [onProductClick, setActivePopoverIndex]
    )

    const placement = useMemo(() => (matches.mobile ? 'bottom' : 'auto'), [matches])

    const popover = (
        <Popover
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            className="interactive-slide-tag-popover"
            id={`interactive_slide_product_popover_${tag.position_x}_${tag.position_y}`}
        >
            <Popover.Content>
                <ProductItemList
                    className={'products-list-item interactive-slide-product'}
                    mode={ProductListMode.Expanded}
                    product={tag.product}
                    onProductClick={handleProductClick}
                    showNotOrderableAlert
                    {...rest}
                />
            </Popover.Content>
        </Popover>
    )

    const popperConfig = useMemo(
        () => ({
            modifiers: [
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [-2, 2],
                    },
                },
            ],
        }),
        []
    )

    return (
        <div className={'interactive-slide-tag'} style={style}>
            <OverlayTrigger
                show={activePopoverIndex === index}
                placement={placement}
                overlay={popover}
                popperConfig={popperConfig}
            >
                <span
                    ref={ref}
                    className="btn-tag"
                    onClick={handleClick}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                >
                    <span className="app-icon">
                        <Image src={SVGPlusRounded} alt={Config.APP_NAME} />
                        <Image src={SVGPlusRoundedPrimary} alt={Config.APP_NAME} className={'hover'} />
                    </span>
                </span>
            </OverlayTrigger>
        </div>
    )
}

export default memo(Tag)
