import { ICustomer } from '../services/api/service/customers/types'
import { MouseEvent } from 'react'

export enum ICustomerMenuLocation {
    Area = 'Area',
    Dropdown = 'Dropdown',
}

export enum ICustomerMenuItemType {
    Dashboard = 'Dashboard',
    Orders = 'orders',
    Carts = 'carts',
    Assets = 'assets',
    Invoices = 'invoices',
    Imports = 'imports',
    Exports = 'exports',
    Favorites = 'favorites',
    Assistance = 'assistance',
    FavoriteDepartment = 'favorite_department',
    Salesmen = 'salesmen',
    Logout = 'logout',
    Modify_Login = 'modify_login',
    Stores = 'stores',
    PictureExport = 'picture_export',
}

export interface ICustomerMenuItem {
    readonly type: ICustomerMenuItemType
    readonly label: string
    readonly route?: string
    readonly icon: string
    readonly sub_icon?: string
    readonly position: number
}

export type ICustomerMenuItemTypeCollection = Array<ICustomerMenuItemType>
export type ICustomerMenuItemClickCallback = (e: MouseEvent, item: ICustomerMenuItem, url?: string) => void
export type ICustomerMenu = Array<ICustomerMenuItem>
export type CustomerStoreCallback = (customer: ICustomer, store?: ICustomer, storeId?: string) => void
