/**
 *
 * Picker Button
 *
 */
import React, { memo, useCallback, useMemo } from 'react'
import { IPickerAction, IPickerActionCollection } from '../../../types/productPicker'
import { Dropdown } from 'react-bootstrap'
import PickerButton from './PickerButton'
import Button from '../../Buttons/Button'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

type IProps = {
    action?: string
    actions: IPickerActionCollection
    loading?: boolean
    disabled?: boolean
    onChange?: (action: IPickerAction) => void
    onCancel?: () => void
    showCancel?: boolean
}

function PickerActions({
    actions,
    action,
    loading,
    disabled,
    onChange,
    onCancel,
    showCancel = true,
}: IProps): JSX.Element {
    const multiple = useMemo(() => {
        return Object.values(actions).length > 1
    }, [actions])

    const firstAction = useMemo(() => {
        const values = Object.values(actions)
        return values.length > 0 ? values[0] : undefined
    }, [actions])

    const isEmpty = useMemo(() => {
        return Object.values(actions).length === 0
    }, [actions])

    const handleSelect = useCallback(
        (code: string | null) => {
            if (!code) {
                return
            }

            if (onChange) {
                onChange(actions[code])
            }
        },
        [actions, onChange]
    )

    if (loading || disabled || isEmpty || (!multiple && typeof firstAction === 'undefined')) {
        return <></>
    }

    return (
        <div className={classNames('product-picker-action', { loading: loading, disabled })}>
            <div className="product-picker-action-content">
                {action && showCancel && (
                    <Button className="btn-cancel" size="sm" variant="link" onClick={onCancel}>
                        <FormattedMessage id="default.cancel" />
                    </Button>
                )}
                {multiple ? (
                    <Dropdown>
                        <Dropdown.Toggle
                            size="sm"
                            variant="outline-secondary"
                            id="product-picker-dropdown"
                            block={true}
                            disabled={disabled || loading}
                        >
                            <FormattedMessage id="product_picker.group_actions" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu onSelect={handleSelect}>
                            {Object.keys(actions).map((code) => {
                                if (actions[code].components?.button) {
                                    return React.createElement(actions[code].components!.button!, {
                                        as: Dropdown.Item,
                                        key: code,
                                        action: actions[code],
                                        onSelect: handleSelect,
                                        loading,
                                        disabled,
                                    })
                                }
                                return (
                                    <PickerButton
                                        key={code}
                                        action={actions[code]}
                                        loading={loading}
                                        disabled={disabled}
                                        onSelect={handleSelect}
                                    />
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <>
                        {firstAction?.components?.button ? (
                            React.createElement(firstAction?.components.button, {
                                key: firstAction.code,
                                action: firstAction,
                                onSelect: handleSelect,
                                single: true,
                                loading,
                                disabled,
                            })
                        ) : (
                            <PickerButton
                                single
                                key={firstAction!.code}
                                onSelect={handleSelect}
                                action={firstAction!}
                                as={Button}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default memo(PickerActions)
