import React, { memo, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { IPickerActionComponentProps } from '../../../types/productPicker'
import { Col, Container, FormCheck, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PickerCounter from './PickerCounter'
import Button from '../../Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createStructuredSelector } from 'reselect'
import {
    // makeSelectProductsListSelectionError,
    // makeSelectProductsListSelectionProcessed,
    makeSelectProductsListSelectionProcessing,
    // makeSelectProductsListSelectionSuccess,
} from '../../../store/products/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../store'
// import { IAppErrorTypes } from '../../../store/app/types'

type IProps = IPickerActionComponentProps & {
    onCancel: () => void
    onSelectAllChange: (checked: boolean, indeterminate: boolean) => void
}

const stateSelector = createStructuredSelector<any, any>({
    processing: makeSelectProductsListSelectionProcessing(),
    // processed: makeSelectProductsListSelectionProcessed(),
    // error: makeSelectProductsListSelectionError(),
    // successMessage: makeSelectProductsListSelectionSuccess(),
})

function PickerBanner({ onCancel, onSelectAllChange, ...rest }: IProps): JSX.Element {
    const { action, selectedPageItemCount = 0, pageItemCount = 0 } = rest

    const { selectAll = true, showCounter = true, showCancel = true, max } = action.options
    const bannerRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const pageItemCnt = Math.min(max || 9999, pageItemCount)
    const allChecked = selectedPageItemCount === pageItemCnt
    const allIndeterminate = selectedPageItemCount > 0 && selectedPageItemCount !== pageItemCnt

    const { processing } = useSelector<
        IApplicationRootState,
        {
            processing: boolean
            // processed: boolean
            // error?: IAppErrorTypes
            // successMessage?: string
        }
    >(stateSelector)

    const handleInputChange = useCallback(() => {
        onSelectAllChange(!allChecked, allIndeterminate)
    }, [onSelectAllChange, allChecked, allIndeterminate])

    useEffect(() => {
        if (!inputRef.current) {
            return
        }

        inputRef.current.checked = allChecked
        inputRef.current.indeterminate = allIndeterminate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef.current, allIndeterminate, allChecked])

    return (
        <div
            ref={bannerRef}
            className={classNames('product-picker-banner', {
                'with-counter': showCounter,
                'with-pick-all': selectAll,
            })}
        >
            <Container fluid>
                <Row>
                    <Col className="spacer" />
                    <Col>
                        <Row className="content">
                            <Col lg="auto" className="start">
                                {selectAll && (
                                    <div className="pick-all">
                                        <FormCheck custom type={'checkbox'} id={`picker-banner-select-all`}>
                                            <FormCheck.Input
                                                type="checkbox"
                                                disabled={processing}
                                                onChange={handleInputChange}
                                                value={1}
                                                checked={allChecked}
                                                ref={inputRef}
                                            />
                                            <FormCheck.Label htmlFor={`picker-banner-select-all`}>
                                                {!allChecked ? (
                                                    <FormattedMessage id="default.select_all" />
                                                ) : (
                                                    <FormattedMessage id="default.unselect_all" />
                                                )}
                                            </FormCheck.Label>
                                        </FormCheck>
                                    </div>
                                )}
                                {showCounter && (
                                    <label htmlFor={`picker-banner-select-all`} className="counter-label">
                                        {action.components?.counter ? (
                                            React.createElement(action.components.counter, rest)
                                        ) : (
                                            <PickerCounter {...rest} />
                                        )}
                                    </label>
                                )}
                            </Col>
                            <Col className="end">
                                <div className="buttons">
                                    <span className="lbl">
                                        <FormattedMessage id="product_piker.for_selection" /> :
                                    </span>
                                    <div className="actions">
                                        {showCancel && (
                                            <Button variant="secondary" onClick={onCancel} disabled={processing}>
                                                <FontAwesomeIcon className="app-icon" icon={['fal', 'undo']} />
                                                <FormattedMessage id="default.cancel" />
                                            </Button>
                                        )}
                                        {action.components?.action &&
                                            React.createElement(action.components.action, rest)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default memo(PickerBanner)
