/**
 *
 * Pagination
 *
 */
import classNames from 'classnames'
import React, { ChangeEvent, memo, MouseEvent } from 'react'
import { Pagination as MultiPage, Form } from 'react-bootstrap'
import { PaginationCallback } from '../../types/pagination'
import FlatIcon from '../Icon/FlatIcon'
import { FormattedMessage } from 'react-intl'

interface IProps {
    disabled: boolean
    show: boolean
    showSelector?: boolean
    pageRangeDisplayed: number
    currentPage: number
    totalPages: number
    firstPage: number
    lastPage: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    previousPage: number
    nextPage: number
    // ui
    className?: string
    hideNavigation: boolean
    hideFirstLast: boolean
    onPageClick: PaginationCallback
}

function Pagination({
    currentPage,
    firstPage,
    lastPage,
    hasNextPage,
    hasPreviousPage,
    className,
    hideNavigation,
    hideFirstLast,
    previousPage,
    nextPage,
    totalPages,
    onPageClick,
    disabled,
    show,
    showSelector = true,
}: IProps): JSX.Element {
    const pages: Array<JSX.Element> = []
    const options: Array<JSX.Element> = []
    const handlePageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const wantedPage = parseInt(event.currentTarget.value)
        if (onPageClick && wantedPage) {
            onPageClick(wantedPage)
        }
    }

    const handleClick = (event: MouseEvent) => {
        const page =
            // @ts-ignore
            event.currentTarget.dataset && event.currentTarget.dataset.page
                ? // @ts-ignore
                  parseInt(event.currentTarget.dataset.page)
                : undefined
        if (onPageClick && page) {
            onPageClick(page)
        }
    }

    for (let i = firstPage; i <= lastPage; i++) {
        pages.push(
            <MultiPage.Item key={i} active={i === currentPage} onClick={handleClick} disabled={disabled} data-page={i}>
                {i}
            </MultiPage.Item>
        )
    }

    for (let i = 1; i <= totalPages; i++) {
        options.push(
            <option key={i} value={i}>
                {i}
            </option>
        )
    }

    return (
        <div className={classNames('pagination-section', className, { 'd-block': show }, { 'd-none': !show })}>
            <MultiPage>
                {!hideFirstLast && (
                    <MultiPage.First
                        className={'goto-first-item'}
                        data-page={1}
                        active={currentPage === 1}
                        disabled={currentPage === 1 || disabled}
                        onClick={handleClick}
                    />
                )}
                {!hideNavigation && (
                    <MultiPage.Prev
                        data-page={previousPage}
                        disabled={!hasPreviousPage || disabled}
                        onClick={handleClick}
                        className={'goto-prev-item'}
                    >
                        <FlatIcon icon={'arrow-left'} />
                    </MultiPage.Prev>
                )}
                {pages}
                {lastPage !== totalPages && <MultiPage.Item disabled>...</MultiPage.Item>}
                {!hideNavigation && (
                    <MultiPage.Next
                        data-page={nextPage}
                        disabled={!hasNextPage || disabled}
                        onClick={handleClick}
                        className={'goto-next-item'}
                    >
                        <FlatIcon icon={'arrow-right'} />
                    </MultiPage.Next>
                )}
                {!hideFirstLast && (
                    <MultiPage.Last
                        className={'goto-last-item'}
                        data-page={totalPages}
                        active={currentPage === totalPages}
                        disabled={currentPage === totalPages || disabled}
                        onClick={handleClick}
                    />
                )}
            </MultiPage>
            {showSelector && totalPages > 1 && (
                <div className="page-select">
                    <div className="form-inline">
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="pagination.go_to_page" />
                            </Form.Label>
                            <Form.Control
                                tabIndex={-1}
                                value={currentPage}
                                onChange={handlePageChange}
                                disabled={disabled}
                                as="select"
                                size="sm"
                                custom
                            >
                                {options}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
            )}
        </div>
    )
}

Pagination.defaultProps = {
    hideNavigation: false,
    hideFirstLast: true,
    disabled: true,
    show: false,
} as Partial<IProps>

export default memo(Pagination)
