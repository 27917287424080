import React, { memo, useCallback } from 'react'
import Component from '../../components/CartModeSwitcher/CartModeSwitcher'
import { createStructuredSelector } from 'reselect'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import { StrictCartMode } from '../../store/carts/types'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { Undefinable } from 'tsdef'
import { ICustomer } from '../../services/api/service/customers/types'
import { canSwitchCartMode } from '../../store/carts/utils'
import { cartsSwitchCartMode } from '../../store/carts/actions'

const stateSelector = createStructuredSelector<any, any>({
    cartMode: makeSelectCartMode(),
    customer: makeSelectCustomer(),
})

const CartModeSwitcher = (): JSX.Element => {
    const dispatch = useDispatch()
    const { cartMode, customer } = useSelector<
        IApplicationRootState,
        {
            cartMode: StrictCartMode
            customer: Undefinable<ICustomer>
        }
    >(stateSelector)

    const handleChange = useCallback(
        (mode: StrictCartMode) => {
            dispatch(cartsSwitchCartMode(mode))
        },
        [dispatch]
    )

    if (!customer || !canSwitchCartMode(customer)) {
        return <></>
    }

    return <Component mode={cartMode} onChange={handleChange} />
}

export default memo(CartModeSwitcher)
