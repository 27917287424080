import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { IAuthTokens, clearAuthTokens, setAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor } from './jwt'
import { IAuthResponse, IAuthParameters, IAuthenticateService, IAuth2FaParameters } from './types'

const authResponseToAuthTokens = (res: IAuthResponse): IAuthTokens => ({
    accessToken: res.token!,
    refreshToken: res.refresh_token!,
})

const registerJwt = (instance: AxiosInstance, refreshTokenUrl: string): AxiosInstance => {
    const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<string> => {
        const response = await axios.post(refreshTokenUrl, { refresh_token: refreshToken })
        return response.data?.token
    }

    applyAuthTokenInterceptor(instance, { requestRefresh })
    return instance
}

const create = (instance: AxiosInstance, refreshTokenUrl: string): IAuthenticateService => {
    // on forge l'url de login
    const loginPath = '/login'
    const check2FaPath = `${loginPath}/check-2fa`

    /*****************************
     * Refresh token logic
     *****************************/
    instance = registerJwt(instance, refreshTokenUrl)

    /*****************************
     * METHODS
     *****************************/
    const login = (parameters: IAuthParameters): Promise<AxiosResponse<IAuthResponse>> => {
        // on n'utilise pas l'instance principale qui est reliée à la logique du jwt
        // cette url est anonyme d'un point de vue firewall
        // si on doit repasser par l'instance, attention à interceptor dans configStore !
        return axios
            .post(loginPath, {
                ...parameters,
            })
            .then((response) => {
                // save tokens to storage if require_2fa_authentication is false
                if (!response.data.require_2fa_authentication) {
                    setAuthTokens(authResponseToAuthTokens(response.data))
                }

                return response
            })
    }

    const check2Fa = (parameters: IAuth2FaParameters, token: string): Promise<AxiosResponse<IAuthResponse>> => {
        // on n'utilise pas l'instance principale qui est reliée à la logique du jwt
        // cette url est anonyme d'un point de vue firewall
        // si on doit repasser par l'instance, attention à interceptor dans configStore !
        return axios
            .post(check2FaPath, parameters, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                // save tokens to storage
                setAuthTokens(authResponseToAuthTokens(response.data))
                return response
            })
    }

    const logout = (): void => {
        clearAuthTokens()
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        login,
        logout,
        check2Fa,
    }
}

export default {
    create,
}
