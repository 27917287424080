/**
 *
 * MegaItem
 *
 */
import classNames from 'classnames'
import chunk from 'lodash/chunk'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ClassificationType, IDepartment, IShippingLocation } from '../../../services/api/service/classification/types'
import { LinkItemCallbacks } from '../type'
import LinkItem from './LinkItem'

type IProps = LinkItemCallbacks & {
    item: IShippingLocation
    basePath?: string
    departmentsOnly: boolean
    selectedDepartmentSetter: (department?: IDepartment) => void
}

function ShippingLocationItem({
    item,
    basePath,
    departmentsOnly,
    selectedDepartmentSetter,
    onMouseEnter,
    onClick,
    onMouseLeave,
}: IProps): JSX.Element {
    const [active, setActive] = useState<boolean>(false)
    const [entered, setEntered] = useState<boolean>(false)
    const location = useLocation()
    useEffect(() => {
        setActive(location.pathname.indexOf(item.url) > -1)
    }, [location.pathname, item, setActive])

    const handleMouseClick = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            const department = item as IDepartment
            selectedDepartmentSetter(department)
            if (onClick) {
                onClick(e, item, itemType)
            }
        },
        [selectedDepartmentSetter, onClick]
    )

    const handleMouseEnter = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(true)
            const department = item as IDepartment
            selectedDepartmentSetter(department)
            if (onMouseEnter) {
                onMouseEnter(e, item, itemType)
            }
        },
        [selectedDepartmentSetter, onMouseEnter]
    )

    const handleMouseLeave = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            selectedDepartmentSetter(undefined)
            if (onMouseLeave) {
                onMouseLeave(e, item, itemType)
            }
        },
        [selectedDepartmentSetter, onMouseLeave]
    )

    const isShippingLocationChildren =
        item.tree.filter((subItem) => subItem['@type'] === ClassificationType.ShippingLocation).length > 0

    // on eclate les enfants
    const subShippingLocations: Array<IShippingLocation> = item.tree.filter(
        (subItem): subItem is IShippingLocation => subItem['@type'] === ClassificationType.ShippingLocation
    )
    const subFamilies: Array<IDepartment> = item.tree.filter(
        (subItem): subItem is IDepartment => subItem['@type'] === ClassificationType.Family
    )
    const formattedSubFamilies = chunk(subFamilies, 4)

    return (
        <div
            className={classNames('section-item', `section-item-shipping-location`, {
                'has-children': item.tree.length > 0,
                'has-uniq-child': item.tree.length === 1,
                'has-multiple-child': item.tree.length > 1,
                active: active,
                entered: entered,
            })}
        >
            <div className={'section-item-heading'}>
                <LinkItem
                    basePath={basePath}
                    item={item}
                    itemType={'location'}
                    className={'section-nav-item'}
                    onClick={handleMouseClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </div>
            <div
                className={classNames('section-item-content', {
                    'section-item-shipping-location': isShippingLocationChildren,
                })}
            >
                {subShippingLocations &&
                    subShippingLocations.map((subItem) => (
                        <ShippingLocationItem
                            basePath={basePath}
                            key={subItem['@id']}
                            item={subItem as IShippingLocation}
                            departmentsOnly={departmentsOnly}
                            selectedDepartmentSetter={selectedDepartmentSetter}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleMouseClick}
                        />
                    ))}
                {formattedSubFamilies &&
                    formattedSubFamilies.map((subItems, index) => {
                        const SubSubItems = subItems.map((subItem) => (
                            <LinkItem
                                basePath={basePath}
                                item={subItem}
                                subItems={subItem.sub_families}
                                showSubItems={!departmentsOnly}
                                key={subItem['@id']}
                                onClick={handleMouseClick}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                        ))
                        return (
                            <div key={`sp_sub_group_${item['@id']}_${index}`} className={'sub-families-group'}>
                                {SubSubItems}
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

ShippingLocationItem.defaultProps = {
    departmentsOnly: true,
    basePath: '',
    selectedDepartmentSetter: () => {},
} as Partial<IProps>

export default memo(ShippingLocationItem)
