import {
    IMemberClassificationProcessAction,
    IMemberTopProductsProcessAction,
    IMemberTopSalesProcessAction,
} from './types'
import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import ActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    IApiMeClassificationResponse,
    IApiMeTopProductsResponse,
    IApiMeTopSalesResponse,
    IApiMeYearlyStatsResponse,
} from '../../services/api/service/stats/types'
import {
    statisticsCustomerClassificationFailureAction,
    statisticsCustomerClassificationSuccessAction,
    statisticsCustomerTopProductsFailureAction,
    statisticsCustomerTopProductsSuccessAction,
    statisticsCustomerTopSalesFailureAction,
    statisticsCustomerTopSalesSuccessAction,
    statisticsYearlyStatsFailureAction,
    statisticsYearlyStatsSuccessAction,
} from './actions'
import { formatAppError } from '../app/saga'
import { Undefinable } from 'tsdef'

function* processTopProductsRequest(action: IMemberTopProductsProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { params } = action.payload

    try {
        const response: Undefinable<IApiMeTopProductsResponse> = yield call(
            { context: api.statistics, fn: 'topProducts' },
            params
        )
        if (response) {
            yield put(statisticsCustomerTopProductsSuccessAction(response.data))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(statisticsCustomerTopProductsFailureAction(error))
    }
}

function* processTopSalesRequest(action: IMemberTopSalesProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { params } = action.payload

    try {
        const response: Undefinable<IApiMeTopSalesResponse> = yield call(
            { context: api.statistics, fn: 'topSales' },
            params
        )
        if (response) {
            yield put(statisticsCustomerTopSalesSuccessAction(response.data['hydra:member']))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(statisticsCustomerTopSalesFailureAction(error))
    }
}

function* processYearlyStatsRequest() {
    const api: IApiClient = yield getContext('api')

    try {
        const response: Undefinable<IApiMeYearlyStatsResponse> = yield call({
            context: api.statistics,
            fn: 'yearlyStats',
        })
        if (response) {
            yield put(statisticsYearlyStatsSuccessAction(response.data['hydra:member']))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(statisticsYearlyStatsFailureAction(error))
    }
}

function* processCustomerClassificationRequest(action: IMemberClassificationProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { filters } = action.payload

    try {
        const params = {
            from: `${filters.from.getFullYear()}-${('0' + (filters.from.getMonth() + 1)).slice(-2)}`,
            to: `${filters.to.getFullYear()}-${('0' + (filters.to.getMonth() + 1)).slice(-2)}`,
            all_customers: filters.all_customers || 0,
            all_activity: filters.all_activity || 0,
        }

        const response: Undefinable<IApiMeClassificationResponse> = yield call(
            { context: api.statistics, fn: 'familiesTree' },
            params
        )
        if (response) {
            yield put(statisticsCustomerClassificationSuccessAction(response.data['hydra:member']))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'cms.page.error')
        yield put(statisticsCustomerClassificationFailureAction(error))
    }
}

export default [
    takeLatest(ActionTypes.CLASSIFICATION_PROCESS_ACTION, processCustomerClassificationRequest),
    takeLatest(ActionTypes.TOP_SALES_PROCESS_ACTION, processTopSalesRequest),
    takeLatest(ActionTypes.TOP_PRODUCTS_PROCESS_ACTION, processTopProductsRequest),
    takeLatest(ActionTypes.YEARLY_STATS_PROCESS_ACTION, processYearlyStatsRequest),
]
