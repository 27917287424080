import { AxiosResponse, CancelToken } from 'axios'
import { IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'

export type IPictureExportParams = {
    products: Array<string>
}

export enum PictureExportState {
    Wait = 'wait',
    Processing = 'processing',
    Ready = 'ready',
    Expired = 'expired',
    Error = 'error',
}

export type IPictureExport = IApiResource & {
    state: PictureExportState
    state_label: string
    url: string | null
    processed_at: string | null
    products_count: number
    created_at: string
}

export type IApiPictureExportListCollection = Array<IPictureExport>
export type IApiPictureExportListPagination = IPaginationApiResponse<IApiPictureExportListCollection>
export type IApiPictureExportListResponse = AxiosResponse<IApiPictureExportListPagination>
export type IApiPictureExportListParameters = IBasePaginationParameters
export type IApiPictureExportResponse = AxiosResponse<IPictureExport>

export interface IPictureExportService {
    list(params: IApiPictureExportListParameters, cancelToken?: CancelToken): Promise<IApiPictureExportListResponse>
    create(params: IPictureExportParams): Promise<IApiPictureExportResponse>
    get(identifier: string): Promise<IApiPictureExportResponse>
}
