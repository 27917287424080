/*
 *
 * Breadcrumb
 *
 */

import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Breadcrumb as Root, BreadcrumbItemProps, Container } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../routes/utils'

export type BreadcrumbItemObject = Pick<BreadcrumbItemProps, 'active' | 'href'> & { label: string; localize?: boolean }

interface IProps {
    items?: Array<BreadcrumbItemObject>
}

function Breadcrumb({ items }: IProps): JSX.Element {
    const { locale } = useIntl()
    const homepageUrl = useMemo(() => {
        return generatePath(getPath('home', locale), { lang: locale })
    }, [locale])
    let cnt = 1
    if (typeof items === 'object') {
        cnt += Object.values(items).length
    }

    let home: BreadcrumbItemObject = {
        label: 'page.home',
    }

    if (cnt > 1) {
        home = {
            ...home,
            href: homepageUrl,
        }
    } else {
        home = {
            ...home,
            active: true,
        }
    }
    const all = [home, ...(items ? items : [])]

    return (
        <div className={'breadcrumb-section'}>
            <Container>
                <Root>
                    {all.map((itm, index) => {
                        const isActive = index === cnt - 1
                        return (
                            <li
                                key={itm.label}
                                className={classNames('breadcrumb-item', {
                                    active: isActive,
                                })}
                            >
                                {/*<NavLink to={itm.href!}>*/}
                                {/*    {itm.localize !== false && <FormattedMessage id={itm.label} />}*/}
                                {/*    {itm.localize === false && itm.label}*/}
                                {/*</NavLink>*/}
                                {!isActive && (
                                    <NavLink to={itm.href!}>
                                        {itm.localize !== false && <FormattedMessage id={itm.label} />}
                                        {itm.localize === false && itm.label}
                                    </NavLink>
                                )}
                                {isActive && (
                                    <span>
                                        {itm.localize !== false && <FormattedMessage id={itm.label} />}
                                        {itm.localize === false && itm.label}
                                    </span>
                                )}
                            </li>
                        )
                    })}
                </Root>
            </Container>
        </div>
    )
}

Breadcrumb.defaultProps = {} as Partial<IProps>

export default Breadcrumb
