// LIBRARIES
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { ScrollTo } from 'scroll-to-position'
import Favorite from '../../components/Favorite/Favorite'
import ProductImages from '../../components/Product/Detail/ProductImages'
import ProductQuantitySection from '../../components/Product/Detail/ProductQuantitySection'
import { AlreadyOrdered, CustomerInfo, Packing } from '../../components/Product/Partial'
import Attributes from '../../components/Product/Partial/Attributes'
import AttributeSelector from '../../components/Product/Partial/AttributeSelector'
import Brand from '../../components/Product/Partial/Brand'
import Description from '../../components/Product/Partial/Description'
import Ean from '../../components/Product/Partial/Ean'
import Heading from '../../components/Product/Partial/Heading'
import LogisticInformation from '../../components/Product/Partial/LogisticInformation'
import { default as ProductPrice } from '../../components/Product/Partial/Price'
import Reference from '../../components/Product/Partial/Reference'
import { getPath } from '../../routes'
import { ICart, ICartItem, ICartMode } from '../../services/api/service/carts/types'
import { IShippingLocation } from '../../services/api/service/classification/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { ShopImportType } from '../../services/api/service/imports/types'
import {
    IProductDetail,
    IProductGroupAttribute,
    IProductGroupAttributeCollection,
    IProductGroupAttributeValueIdentifiers,
} from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { addToCartProcessAction, removeToCartProcessAction } from '../../store/carts/actions'
import {
    makeSelectCartByShippingLocationId,
    makeSelectCartItemByProductId,
    makeSelectCartMode,
    makeSelectFetchingByProductId,
    makeSelectLockByShippingLocationId,
    makeSelectQuantityByProductId,
} from '../../store/carts/selectors'
import { isOrderAvailable } from '../../store/carts/utils'
import { makeSelectShippingLocationById } from '../../store/classification/selectors'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import {
    productAddToFavoriteAction,
    productDetailProcessAction,
    productDetailResetAction,
    productRemoveToFavoriteAction,
} from '../../store/product/actions'
import {
    makeSelectProductDetail,
    makeSelectProductDetailError,
    makeSelectProductDetailFetching,
    makeSelectProductGroupLinkedProductsAttributes,
} from '../../store/product/selectors'
import { findProductIdsFromProductGroupAttributes } from '../../store/products/utils'
import { CollectionMap } from '../../types/common'
import { ProductAttributeSelectorChangeCallback } from '../../types/productCallback'
import { generateProductUrl } from '../../utils/productHelper'
import CrossSelling from '../CrossSelling/CrossSelling'
import ProductCollection from '../ProductCollection/ProductCollection'
import ShippingLocation from '../ShippingLocation/ShippingLocation'
import StoreQuantity from '../StoreQuantity/StoreQuantity'
import { default as ProductPlaceholder } from './Placeholder'
import { stockAlertProcessAction } from '../../store/stockAlert/actions'
import { StrictCartMode } from '../../store/carts/types'
import { canUseFavorites, customerShowPrices, isShowProductCustomerInfo } from '../../store/customers/utils'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import SalesmanInfo from '../../components/Product/Partial/SalesmanInfo'
import { canExportPictures } from '../../store/pictureExport/utils'
import Button from '../../components/Buttons/Button'
import PictureExportModal from '../PictureExport/Loadable'
import { CustomerInfoPart } from '../../components/Product/Partial/CustomerInfo'
import GeneralPriceBadge from '../../components/GeneralPrice/Badge'
import { Nullable } from 'tsdef'
import EcoTax from '../../components/Product/Partial/EcoTax'

interface IProps {
    className?: string
    productId: string
}

export const PRODUCT_HEADER_ID = 'product-detail-header'
export const PRODUCT_CONTENT_ID = 'product-detail-content'
export const PRODUCT_FOOTER_ID = 'product-detail-footer'

const stateSelector = createStructuredSelector<any, any>({
    detailFetching: makeSelectProductDetailFetching(),
    detailError: makeSelectProductDetailError(),
    productDetail: makeSelectProductDetail(),
    groupAttributes: makeSelectProductGroupLinkedProductsAttributes(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
    cartMode: makeSelectCartMode(),
})

function Product({ className, productId }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const { formatMessage, locale } = useIntl()
    const { groupAttributes, productDetail, detailError, customer, store, cartMode, me } = useSelector<
        IApplicationRootState,
        {
            groupAttributes: IProductGroupAttributeCollection
            productDetail?: IProductDetail
            detailError?: IAppErrorTypes | undefined
            detailFetching: boolean
            customer: ICustomer
            store?: ICustomer
            cartMode: StrictCartMode
            me: IMe
        }
    >(stateSelector)
    const [initialized, setInitialized] = useState<boolean>(false)
    const [downloadPictures, setDownloadPictures] = useState<boolean>(false)
    const [showMultipleQtyModal, setShowMultipleQtyModal] = useState<boolean>(false)
    const isQtySelectorMultiple = useMemo(() => cartMode === ICartMode.Grouped, [cartMode])
    const [hasProductAttributes, setHasProductAttributes] = useState<boolean>(false)
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useState<IProductGroupAttributeValueIdentifiers>({})
    const selectShippingLocationWithId = useMemo(makeSelectShippingLocationById, [])
    const shippingLocationIdentifier = useMemo(() => {
        return productDetail ? productDetail.shipping_location : null
    }, [productDetail])
    const shippingLocation: IShippingLocation | null = useSelector<IApplicationRootState, IShippingLocation | null>(
        (state) => selectShippingLocationWithId(state, shippingLocationIdentifier)
    )
    const selectSelectCartItemByProductId = useMemo(makeSelectCartItemByProductId, [])
    const cartItem: ICartItem | undefined = useSelector<IApplicationRootState, ICartItem | undefined>((state) =>
        selectSelectCartItemByProductId(state, productId, true)
    )

    const selectQuantityWithId = useMemo(makeSelectQuantityByProductId, [])
    const productQuantity: number = useSelector<IApplicationRootState, number>((state) =>
        selectQuantityWithId(state, productId, true)
    )
    const selectLockedWithShippingLocationId = useMemo(makeSelectLockByShippingLocationId, [])
    const isCartLocked: boolean = useSelector<IApplicationRootState, boolean>((state) => {
        if (!shippingLocationIdentifier) {
            return true
        }
        return selectLockedWithShippingLocationId(state, shippingLocationIdentifier)
    })

    const selectCartWithShippingLocationId = useMemo(makeSelectCartByShippingLocationId, [])
    const cart: Nullable<ICart> = useSelector<IApplicationRootState, Nullable<ICart>>((state) => {
        if (!shippingLocationIdentifier) {
            return null
        }
        return selectCartWithShippingLocationId(state, shippingLocationIdentifier, true)
    })

    const selectFetchingWithId = useMemo(makeSelectFetchingByProductId, [])
    const productQuantityFetching: boolean = useSelector<IApplicationRootState, boolean>((state) =>
        selectFetchingWithId(state, productId)
    )

    const groupAttributeKeys: Array<string> = useMemo(() => {
        return groupAttributes ? groupAttributes.map((groupAttribute) => groupAttribute.attribute) : []
    }, [groupAttributes])

    const favoritesAllowed = useMemo(() => {
        return canUseFavorites(customer, me, store, cartMode)
    }, [customer, store, me, cartMode])

    const meCanExportPicture = useMemo(() => {
        return canExportPictures(me)
    }, [me])

    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    useEffect(() => {
        if ((productDetail || detailError) && !initialized) {
            setInitialized(true)
        }
    }, [productDetail, detailError, initialized, setInitialized])

    useEffect(() => {
        // chargement du produit si necessaire
        dispatch(productDetailProcessAction(productId))
    }, [productId, dispatch])

    useEffect(() => {
        if (productDetail && groupAttributes) {
            const groupIds: CollectionMap<number> = {}
            for (const groupAttributeIndex in groupAttributes) {
                const groupAttribute: IProductGroupAttribute = groupAttributes[groupAttributeIndex]
                for (const groupAttributeValueIndex in groupAttribute.values) {
                    const groupAttributeValue = groupAttribute.values[groupAttributeValueIndex]
                    if (groupAttributeValue.products.indexOf(productDetail.id) > -1) {
                        groupIds[groupAttribute.attribute] = groupAttributeValue.id
                    }
                }
            }
            setSelectedProductGroupIds(groupIds)
        }
        if (productDetail) {
            if (
                (productDetail.custom_attributes && productDetail.custom_attributes.length > 0) ||
                (productDetail.frontend_attributes && Object.values(productDetail.frontend_attributes).length > 0)
            ) {
                setHasProductAttributes(true)
            }
        }
    }, [groupAttributes, productDetail, setSelectedProductGroupIds])

    const handleAttributeSelectorChange: ProductAttributeSelectorChangeCallback = useCallback(
        (attribute, value) => {
            const selectedAttributes = selectedProductGroupIds
                ? { ...selectedProductGroupIds, [attribute]: value.id }
                : { [attribute]: value.id }
            setSelectedProductGroupIds(selectedAttributes)
            // on forge la bonne url
            let finalUrl: string | undefined = undefined
            const selectedProductIds = findProductIdsFromProductGroupAttributes(
                groupAttributes,
                selectedAttributes,
                value
            )

            if (selectedProductIds) {
                finalUrl = generateProductUrl(selectedProductIds[0], locale)
            }

            if (finalUrl) {
                // @ts-ignore
                if (location.state && location.state.background) {
                    // @ts-ignore
                    history.push(finalUrl, { background: location.state.background })
                } else {
                    history.push(finalUrl)
                }
            }
        },
        [setSelectedProductGroupIds, selectedProductGroupIds, groupAttributes, history, locale, location.state]
    )

    const handleOnFavoriteChange = useCallback(
        (favorite: boolean) => {
            if (favorite) {
                dispatch(productAddToFavoriteAction(productDetail!.id))
            } else {
                dispatch(productRemoveToFavoriteAction(productDetail!.id))
            }
        },
        [productDetail, dispatch]
    )

    const handleQuantityMultipleStoreModalExit = useCallback(() => {
        setShowMultipleQtyModal(false)
    }, [setShowMultipleQtyModal])
    const handleOnProductMultipleQuantityAsked = useCallback(() => {
        setShowMultipleQtyModal(true)
    }, [setShowMultipleQtyModal])
    const handleOnRestockAlertChange = useCallback(
        (product, enabled) => {
            dispatch(stockAlertProcessAction(product.id, enabled))
        },
        [dispatch]
    )

    const handleCrossSellProductClick = useCallback(() => {
        const productAttributeElement = document.getElementById(PRODUCT_HEADER_ID)
        if (productAttributeElement) {
            let opts: any = {
                duration: [200, 1500],
            }
            if (document.body.classList.contains('modal-open')) {
                const container = document.querySelector('.modal')
                opts = {
                    ...opts,
                    scrollContainer: container,
                }
            }
            ScrollTo(productAttributeElement, opts)
        }
    }, [])

    const handleOnQuantityChange = useCallback(
        (quantity: number, oldQuantity: number) => {
            if (!shippingLocationIdentifier) {
                return
            }
            if (quantity > 0) {
                dispatch(addToCartProcessAction(productId, quantity, oldQuantity, undefined, undefined, cart?.['@id']))
            } else {
                dispatch(
                    removeToCartProcessAction(productId, quantity, oldQuantity, undefined, undefined, cart?.['@id'])
                )
            }
        },
        [productId, shippingLocationIdentifier, cart, dispatch]
    )

    const handleDownloadPictures = useCallback(() => {
        setDownloadPictures(true)
    }, [setDownloadPictures])

    const handleDownloadPictureModalHide = useCallback(() => {
        setDownloadPictures(false)
    }, [setDownloadPictures])

    const handleCustomerStockClick = useCallback(() => {
        const importUrl = generatePath(getPath('customerImports', locale), {
            lang: locale,
            type: ShopImportType.CustomerStock,
        })
        history.push(importUrl)
    }, [history, locale])

    // on affiche ou pas certaines parties
    const customerInfoParts: Array<CustomerInfoPart> | undefined = useMemo(() => {
        if (!isShowProductCustomerInfo(customer) || !productDetail) {
            return undefined
        }

        const parts: Array<CustomerInfoPart> = []
        if (!isSalesmanResource(me)) {
            parts.push(CustomerInfoPart.Stock)
            parts.push(CustomerInfoPart.Arrival)
        }

        if (productDetail.orders_in_progress.length > 0) {
            parts.push(CustomerInfoPart.InProgressOrders)
        } else {
            parts.push(CustomerInfoPart.LastOrder)
        }

        return parts
    }, [me, customer, productDetail])

    const handleCustomerInProgressOrderClick = useCallback(
        (e, orderId) => {
            if (!orderId) {
                return
            }
            const orderDetailUrl = generatePath(getPath('customerOrder', locale), { lang: locale, orderId: orderId })
            history.push(orderDetailUrl)
        },
        [history, locale]
    )

    // on affiche ou pas le customer stock
    const showProductSalesmanInfo: boolean = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    // incrémentation activée ou non
    const quantityCanIncrement: boolean = useMemo(() => {
        return cartItem && typeof cartItem.can_increment_quantity === 'boolean' ? cartItem.can_increment_quantity : true
    }, [cartItem])

    useEffect(() => {
        return () => {
            dispatch(productDetailResetAction())
        }
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>{productDetail?.name || formatMessage({ id: 'seo.product.title' })}</title>
                <meta
                    name="description"
                    content={formatMessage({ id: 'seo.products.description' }, { name: productDetail?.name })}
                />
            </Helmet>
            {detailError && (
                <Alert variant="danger" className="mb-0">
                    <Alert.Heading>
                        <FormattedMessage id="default.error_occurred" />
                    </Alert.Heading>
                    <p>{detailError.message}</p>
                </Alert>
            )}
            {!detailError && (
                <ReactPlaceholder ready={initialized} customPlaceholder={<ProductPlaceholder />}>
                    <div className={classNames('product', 'product-detail', className)} id={PRODUCT_HEADER_ID}>
                        <div className={'product-detail-content'} id={PRODUCT_CONTENT_ID}>
                            <Row>
                                <Col xs={24} md={12}>
                                    {productDetail && <ProductImages product={productDetail} showBadges />}
                                    {meCanExportPicture && (
                                        <Button variant="primary" onClick={handleDownloadPictures}>
                                            <FormattedMessage id="product_picker.download_picture" />
                                        </Button>
                                    )}
                                </Col>
                                <Col xs={24} md={12}>
                                    {favoritesAllowed && (
                                        <div className={'product-detail-inner-section'}>
                                            <Favorite
                                                expanded={true}
                                                favorite={productDetail?.favorite || false}
                                                onFavoriteChange={handleOnFavoriteChange}
                                            />
                                        </div>
                                    )}
                                    {productDetail?.frontend_attributes && productDetail?.frontend_attributes.brand && (
                                        <div className={'product-detail-inner-section'}>
                                            <Brand brand={productDetail.frontend_attributes.brand} />
                                        </div>
                                    )}
                                    <div className={'product-detail-inner-section'}>
                                        {productDetail && (
                                            <Heading
                                                product={productDetail}
                                                productNameAs={'h1'}
                                                productNameClassName={'h5'}
                                            />
                                        )}
                                        {productDetail?.already_ordered && (
                                            <AlreadyOrdered
                                                productId={productDetail['@id']}
                                                showLabel
                                                popover={false}
                                                date={productDetail?.last_order_date}
                                            />
                                        )}
                                    </div>
                                    <div className={'product-detail-inner-section'}>
                                        {productDetail && (
                                            <div className={'product-detail-base-info'}>
                                                <Reference reference={productDetail.reference} showLabel />
                                                <Ean ean13={productDetail.ean13} showLabel />
                                                <ShippingLocation
                                                    shippingLocationId={productDetail.shipping_location}
                                                    showLabel
                                                    showIcon={false}
                                                    labelId={'product.logistic_location'}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={'product-detail-inner-section'}>
                                        {showPrices ? (
                                            <ProductPrice
                                                price={productDetail?.price || undefined}
                                                strikePrice={productDetail?.strike_price || undefined}
                                                retailPrice={productDetail?.retail_price || undefined}
                                                unitOfSale={productDetail?.unit_of_sale}
                                            >
                                                {(productDetail?.eco_tax || 0) > 0 && (
                                                    <EcoTax ecoTax={productDetail!.eco_tax!} as={'span'} />
                                                )}
                                                <Packing
                                                    unitOfSale={productDetail?.unit_of_sale}
                                                    packing={productDetail?.packing || 0}
                                                    as={'span'}
                                                    abbreviateLabels={false}
                                                />
                                            </ProductPrice>
                                        ) : (
                                            <div className="product-price-list">
                                                <Packing
                                                    unitOfSale={productDetail?.unit_of_sale}
                                                    packing={productDetail?.packing || 0}
                                                    as={'span'}
                                                    abbreviateLabels={false}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {groupAttributeKeys && groupAttributeKeys.length > 0 && (
                                        <div className={'product-detail-inner-section product-attribute-selectors'}>
                                            {groupAttributeKeys.map((groupAttributeKey: string) => {
                                                return (
                                                    <AttributeSelector
                                                        key={`product_group_attribute_${groupAttributeKey}`}
                                                        attributes={groupAttributes}
                                                        attributeKey={groupAttributeKey}
                                                        selectedIds={selectedProductGroupIds}
                                                        onChange={handleAttributeSelectorChange}
                                                    />
                                                )
                                            })}
                                        </div>
                                    )}
                                    <div className={'product-detail-inner-section'}>
                                        {productDetail?.orderable &&
                                            customer &&
                                            isOrderAvailable(customer, store, cartMode) && (
                                                <>
                                                    {!productDetail?.general_price && customer.general_price && (
                                                        <Alert className="general-price-alert" variant={'default'}>
                                                            <GeneralPriceBadge expanded />
                                                        </Alert>
                                                    )}
                                                    <ProductQuantitySection
                                                        product={productDetail}
                                                        quantitySelectorLocked={isCartLocked}
                                                        quantitySelectorDefaultValue={productQuantity}
                                                        quantitySelectorSaving={productQuantityFetching}
                                                        quantitySelectorMultiple={isQtySelectorMultiple}
                                                        quantityCanIncrement={quantityCanIncrement}
                                                        onQuantityChange={handleOnQuantityChange}
                                                        onProductQuantityMultipleAsked={
                                                            handleOnProductMultipleQuantityAsked
                                                        }
                                                        onProductQuantityRestockAlertChange={handleOnRestockAlertChange}
                                                        errors={cartItem?.errors}
                                                        showTotalPrice={showPrices}
                                                        showPrices={showPrices}
                                                    />
                                                    {showProductSalesmanInfo && (
                                                        <SalesmanInfo
                                                            tooltip={false}
                                                            arrivalStock={productDetail.arrival_stock}
                                                            arrivalStockDate={productDetail.arrival_date}
                                                            stock={productDetail.stock}
                                                            currentValue={productQuantity}
                                                        />
                                                    )}
                                                    {customerInfoParts && (
                                                        <CustomerInfo
                                                            tooltip={false}
                                                            parts={customerInfoParts}
                                                            uniqId={productDetail.id}
                                                            arrivalStockDate={productDetail.arrival_date}
                                                            customerStock={productDetail.customer_stock}
                                                            customerStockDate={productDetail.customer_stock_date}
                                                            onCustomerStockClick={handleCustomerStockClick}
                                                            onCustomerInProgressOrderClick={
                                                                handleCustomerInProgressOrderClick
                                                            }
                                                            customerLastOrderQuantity={
                                                                productDetail.last_order_quantity
                                                            }
                                                            customerLastOrderDate={productDetail.last_order_date}
                                                            customerLastOrderState={productDetail.last_order_state}
                                                            ordersInProgress={productDetail.orders_in_progress}
                                                            orderInProgressQuantity={
                                                                productDetail.order_in_progress_quantity
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        {!productDetail?.orderable && (
                                            <Alert variant={'info'}>
                                                <FormattedMessage id={'product.not_available_in_catalog'} />
                                            </Alert>
                                        )}
                                    </div>
                                    {productDetail?.description && (
                                        <div className={'product-detail-inner-section'}>
                                            <Description value={productDetail.description} />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                {hasProductAttributes && productDetail && (
                                    <Col xs={24} sm={24} md={12}>
                                        <Attributes
                                            unitOfSale={productDetail.unit_of_sale}
                                            attributes={productDetail.frontend_attributes}
                                            customAttributes={productDetail.custom_attributes}
                                        />
                                    </Col>
                                )}
                                <Col xs={24} sm={24} md={12}>
                                    <LogisticInformation
                                        shippingLocation={shippingLocation}
                                        unitOfSale={productDetail?.unit_of_sale}
                                        packing={productDetail?.packing || 0}
                                        boxWeight={
                                            productDetail?.frontend_attributes
                                                ? productDetail.frontend_attributes.box_weight || undefined
                                                : undefined
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={'product-detail-footer'} id={PRODUCT_FOOTER_ID}>
                            <div className={'product-detail-inner-section'}>
                                {productDetail?.frontend_attributes && (
                                    <Row className={'row-collection'}>
                                        <Col>
                                            <ProductCollection
                                                ids={{
                                                    collectionId: productDetail.frontend_attributes.collection?.id
                                                        ? String(productDetail.frontend_attributes.collection?.id)
                                                        : undefined,
                                                    themeId: productDetail.frontend_attributes.theme?.id
                                                        ? String(productDetail.frontend_attributes.theme?.id)
                                                        : undefined,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {productDetail && (
                                    <Row className={'row-cross-selling'}>
                                        <Col>
                                            <CrossSelling
                                                productId={productId}
                                                onProductClick={handleCrossSellProductClick}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </div>
                    </div>
                </ReactPlaceholder>
            )}
            {showMultipleQtyModal && productDetail && (
                <StoreQuantity
                    product={productDetail}
                    onStoreQuantityExit={handleQuantityMultipleStoreModalExit}
                    errors={cartItem?.errors}
                />
            )}
            {productDetail && (
                <PictureExportModal
                    show={downloadPictures}
                    identifiers={[productDetail['@id']]}
                    onHide={handleDownloadPictureModalHide}
                />
            )}
        </>
    )
}

Product.defaultProps = {
    modal: false,
} as Partial<IProps>

export default Product
