/*
 *
 * SideMenu actions
 *
 */

import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import { ISlideMenuItemCollection } from '../classification/types'

export const sideMenuOpenAction = (activeId?: string) =>
    action<ActionTypes.OPEN_ACTION, { activeId?: string }>(ActionTypes.OPEN_ACTION, { activeId })
export const sideMenuCloseAction = () => action<ActionTypes.CLOSE_ACTION>(ActionTypes.CLOSE_ACTION)
export const sideMenuToggleAction = (activeId?: string) =>
    action<ActionTypes.TOGGLE_ACTION, { activeId?: string }>(ActionTypes.TOGGLE_ACTION, { activeId })

export const sideMenuStoreItemsAction = (items: ISlideMenuItemCollection) =>
    action<ActionTypes.STORE_ITEMS_ACTION, { items: ISlideMenuItemCollection }>(ActionTypes.STORE_ITEMS_ACTION, {
        items,
    })

export const sideMenuStoreActiveIdAction = (activeId?: string) =>
    action<ActionTypes.STORE_ACTIVE_ID_ACTION, { activeId?: string }>(ActionTypes.STORE_ACTIVE_ID_ACTION, { activeId })

export const sideMenuResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
