/**
 *
 * Mobile
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Button from '../../../components/Buttons/Button'
import FlatIcon from '../../../components/Icon/FlatIcon'
import {
    IProductListFilter,
    IProductListFilterCollection,
    IProductListParameters,
} from '../../../services/api/service/products/types'
import { IApplicationRootState } from '../../../store'
import {
    makeSelectProductsListFetching,
    makeSelectProductsListFilters,
    makeSelectProductsListParams,
    makeSelectProductsListTotalItems,
} from '../../../store/products/selectors'
import { customerFilterHasSelectedValues } from '../../../store/products/utils'
import { default as FilterList } from './List'

const stateSelector = createStructuredSelector<any, any>({
    loading: makeSelectProductsListFetching(),
    count: makeSelectProductsListTotalItems(),
    params: makeSelectProductsListParams(),
    filters: makeSelectProductsListFilters(),
})

export const PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID = 'mobile-filter-content-page'

function Mobile(): JSX.Element {
    const history = useHistory()
    const { pathname } = useLocation()
    const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false)
    const [showMobileDetailPage, setShowMobileDetailPage] = useState<boolean>(false)
    const [currentPathname, setCurrentPathname] = useState<string>(pathname)

    const { loading, count, params, filters } = useSelector<
        IApplicationRootState,
        {
            loading: boolean
            params: IProductListParameters
            filters?: IProductListFilterCollection
            count: number | undefined
        }
    >(stateSelector)

    const hasSelectedValues = useMemo(() => {
        return customerFilterHasSelectedValues(filters, params)
    }, [filters, params])

    const handleMobileFilterClick = useCallback(() => {
        setShowMobileFilters(true)
    }, [setShowMobileFilters])

    const handleClose = useCallback(() => {
        setShowMobileFilters(false)
    }, [setShowMobileFilters])

    const handleReset = useCallback(() => {
        setShowMobileFilters(false)
        history.push(pathname)
    }, [setShowMobileFilters, pathname, history])

    const handleFilterToggleOpen = useCallback(
        (filter: IProductListFilter, open: boolean) => {
            setShowMobileDetailPage(open)
        },
        [setShowMobileDetailPage]
    )

    useEffect(() => {
        if (pathname !== currentPathname) {
            setShowMobileDetailPage(false)
            setShowMobileFilters(false)
            setCurrentPathname(pathname)
        }
    }, [pathname, currentPathname, setCurrentPathname, setShowMobileFilters, setShowMobileDetailPage])

    useEffect(() => {
        if (showMobileFilters) {
            document.documentElement.classList.add('mobile-filter-opened')
        } else {
            document.documentElement.classList.remove('mobile-filter-opened')
        }

        return () => {
            document.documentElement.classList.remove('mobile-filter-opened')
        }
    }, [showMobileFilters])

    return (
        <div className={classNames('mobile-filter-list', { 'is-empty': !hasSelectedValues })}>
            <Button
                type={'button'}
                variant={'light'}
                className={'btn-filter'}
                onClick={handleMobileFilterClick}
                loading={loading}
                disabled={typeof count === 'undefined'}
            >
                {!loading && <FontAwesomeIcon icon={'list'} className={'app-icon'} />}
                <FormattedMessage id={'products.filter'} />
            </Button>
            <div className={classNames('mobile-filter-page', { show: showMobileFilters })}>
                <div className={'root-page'}>
                    <div className={'header'}>
                        <Button onClick={handleClose} type={'button'} variant={'link'}>
                            <FlatIcon icon={'chevron-left'} />
                            <FormattedMessage id={'products.filter'} />
                        </Button>
                        <Button onClick={handleReset} type={'button'} variant={'link'} disabled={!hasSelectedValues}>
                            <FormattedMessage id={'products.remove_filters'} />
                        </Button>
                    </div>
                    <div className={'content'}>
                        <FilterList
                            filterPortalId={PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID}
                            onFilterToggleOpen={handleFilterToggleOpen}
                        />
                    </div>
                    <div className={'footer'}>
                        <Button block loading={loading} disabled={loading} onClick={handleClose}>
                            <FormattedMessage id={'product.view_catalog_count'} values={{ itemCount: count }} />
                        </Button>
                    </div>
                </div>
                <div className={classNames('detail-page', { show: showMobileDetailPage })}>
                    <div
                        className={classNames('product-filter-list', 'mobile', 'content')}
                        id={PRODUCT_LIST_MOBILE_FILTER_CONTENT_ID}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(Mobile)
