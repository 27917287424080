import { Nullable } from 'tsdef'
import { useIntl } from 'react-intl'
import React, { useCallback, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import FlatIcon from '../../../../../components/Icon/FlatIcon'
// @ts-ignore
import moment from 'moment/min/moment-with-locales.min'

type DateRangeProps = {
    maxDuration: number
    valueFrom: Date
    valueTo: Date
    onPeriodChange?: (startAt: Nullable<Date>, endAt: Nullable<Date>) => void
}

const DateRange = ({ maxDuration, onPeriodChange, valueFrom, valueTo }: DateRangeProps) => {
    const { locale, formatMessage } = useIntl()
    const [startDate, setStartDate] = useState<Nullable<Date>>(valueFrom)
    const [endDate, setEndDate] = useState<Nullable<Date>>(valueTo)

    const handleStartDateChange = useCallback(
        (date) => {
            if (date === null || date instanceof Date) {
                setStartDate(date)
                if (onPeriodChange) {
                    onPeriodChange(date, endDate)
                }
            }
        },
        [setStartDate, endDate, onPeriodChange]
    )

    const handleEndDateChange = useCallback(
        (date) => {
            if (date === null || date instanceof Date) {
                setEndDate(date)
                if (onPeriodChange) {
                    onPeriodChange(startDate, date)
                }
            }
        },
        [setEndDate, startDate, onPeriodChange]
    )

    return (
        <div className="form-group">
            <Form.Label htmlFor="customer-stats-from">
                {formatMessage({ id: 'default.period' })} {formatMessage({ id: 'default.from' }).toLowerCase()}
            </Form.Label>
            <InputGroup>
                <DatePicker
                    locale={locale}
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className={'form-control'}
                    dateFormat={'MM/yyyy'}
                    data-start={true}
                    id={'customer-stats-from'}
                    showMonthYearPicker
                />
                <InputGroup.Append>
                    <InputGroup.Text>
                        <FlatIcon icon="calendar-o" />
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            <Form.Label htmlFor="customer-stats-to">{formatMessage({ id: 'default.to' }).toLowerCase()}</Form.Label>
            <InputGroup>
                <DatePicker
                    locale={locale}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={moment(startDate).add(maxDuration, 'months').startOf('month').toDate()}
                    className={'form-control'}
                    dateFormat={'MM/yyyy'}
                    data-end={true}
                    id={'customer-stats-to'}
                    showMonthYearPicker
                />
                <InputGroup.Append>
                    <InputGroup.Text>
                        <FlatIcon icon="calendar-o" />
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </div>
    )
}

export default DateRange
