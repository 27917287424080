/*
 *
 * SplashScreen constants
 *
 */

enum ActionTypes {
    SHOW_ACTION = 'app/SplashScreen/SHOW',
    HIDE_ACTION = 'app/SplashScreen/HIDE',
}

export default ActionTypes
