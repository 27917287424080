/*
 *
 * ShopImports constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/ShopImports/RESET',
    LIST_PROCESS_ACTION = 'app/ShopImports/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/ShopImports/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/ShopImports/List/REQUEST_FAILURE',
    DETAIL_PROCESS_ACTION = 'app/ShopImports/Detail/REQUEST_PROCESS',
    DETAIL_SUCCESS_ACTION = 'app/ShopImports/Detail/REQUEST_SUCCESS',
    DETAIL_FAILURE_ACTION = 'app/ShopImports/Detail/REQUEST_FAILURE',
    DETAIL_RESET_ACTION = 'app/ShopImports/Detail/RESET',
    LIST_RESET_ACTION = 'app/ShopImports/List/RESET',
    CREATE_PROCESS_ACTION = 'app/ShopImports/Create/REQUEST_PROCESS',
    CREATE_PROCESS_PROGRESS_ACTION = 'app/ShopImports/Create/Progress/REQUEST_PROCESS',
    CREATE_SUCCESS_ACTION = 'app/ShopImports/Create/REQUEST_SUCCESS',
    CREATE_FAILURE_ACTION = 'app/ShopImports/Create/REQUEST_FAILURE',
    CHECK_STATE_PROCESS_ACTION = 'app/ShopImports/CheckState/REQUEST_PROCESS',
    CHECK_STATE_SUCCESS_ACTION = 'app/ShopImports/CheckState/REQUEST_SUCCESS',
    CHECK_STATE_FAILURE_ACTION = 'app/ShopImports/CheckState/REQUEST_FAILURE',
    CREATE_RESET_ACTION = 'app/ShopImports/Create/RESET',
    CHECK_STATE_RESET_ACTION = 'app/ShopImports/CheckState/RESET',
    FORMAT_MATRIX_URLS_ACTION = 'app/ShopImports/MatrixUrls/FORMAT',
    STORE_MATRIX_URLS_ACTION = 'app/ShopImports/MatrixUrls/STORE',
}

export default ActionTypes
