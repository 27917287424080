/**
 *
 * Product Carousel
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, ReactNode, useCallback, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
// import Swiper core and required components
import SwiperCore, { A11y, Controller, Navigation, Pagination, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import FlatIcon from '../Icon/FlatIcon'

// install Swiper components
SwiperCore.use([Controller, Navigation, Pagination, A11y])

type IProps = SwiperOptions & {
    navigationDefault: boolean
    children: ReactNode
}

function Carousel({ navigationDefault, children, ...options }: IProps): JSX.Element {
    const swiperRef = useRef<SwiperClass | null>(null)
    const [isBeginning, setIsBeginning] = useState<boolean>(true)
    const [isEnd, setIsEnd] = useState<boolean>(true)
    const [navigationHide, setNavigationHide] = useState<boolean>(true)

    const handleSlideChange = useCallback(() => {
        if (swiperRef.current) {
            setIsBeginning(swiperRef.current.isBeginning)
            setIsEnd(swiperRef.current.isEnd)
        }
    }, [swiperRef])

    const handlePrevClick = useCallback(
        (evt: MouseEvent) => {
            evt.preventDefault()
            if (swiperRef.current) {
                swiperRef.current.slidePrev()
            }
        },
        [swiperRef]
    )

    const handleNextClick = useCallback(
        (evt: MouseEvent) => {
            evt.preventDefault()
            if (swiperRef.current) {
                swiperRef.current.slideNext()
            }
        },
        [swiperRef]
    )
    return (
        <div className={'carousel-app-component'}>
            {!navigationDefault && (
                <Container className={classNames('carousel-app-navigation', { 'd-none': navigationHide })}>
                    <button
                        className={classNames('swiper-button-prev', { disabled: isBeginning })}
                        onClick={handlePrevClick}
                    >
                        <FlatIcon icon={'arrow-left'} />
                    </button>
                    <button className={classNames('swiper-button-next', { disabled: isEnd })} onClick={handleNextClick}>
                        <FlatIcon icon={'arrow-right'} />
                    </button>
                </Container>
            )}
            <Swiper
                {...options}
                onSwiper={(instance) => {
                    swiperRef.current = instance
                    setIsBeginning(instance.isBeginning)
                    setIsEnd(instance.isEnd)
                    setNavigationHide(instance.isBeginning && instance.isEnd)
                }}
                onSlideChange={handleSlideChange}
            >
                {React.Children.map(children, (child, idx) => (
                    <SwiperSlide key={`carousel_swiper_slide_${idx}`}>{child}</SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

Carousel.defaultProps = {
    navigationDefault: false,
} as Partial<IProps>

export default memo(Carousel)
