import classNames, { Value as ClassValue } from 'classnames'
import React, { memo, ReactNode, useCallback, useMemo } from 'react'
import Button from '../../Buttons/Button'
import { ProductListSidebarMode } from '../../../store/products/utils'
import FlatIcon from '../../Icon/FlatIcon'

export type Props = {
    as: React.ElementType
    mode?: ProductListSidebarMode
    className?: ClassValue
    children?: ReactNode | JSX.Element | React.FC
    onToggleSidebarVisibility?: () => void
}

const Sidebar: React.FC<Props> = function ({
    as,
    className,
    children,
    onToggleSidebarVisibility,
    mode = ProductListSidebarMode.Expanded,
}) {
    const handleClick = useCallback(() => {
        if (onToggleSidebarVisibility) {
            onToggleSidebarVisibility()
        }
    }, [onToggleSidebarVisibility])

    const iconName = useMemo(() => {
        if (mode === ProductListSidebarMode.Expanded) {
            return 'panel-close'
        }

        return 'panel-open'
    }, [mode])

    return React.createElement(
        as || 'div',
        {
            className: classNames('sidebar', className, `sidebar-mode-${mode}`),
        },
        <>
            <Button className="btn-toggle-collapse" onClick={handleClick} variant="link">
                <FlatIcon icon={iconName} />
            </Button>
            <div className="product-list-sidebar">{children}</div>
        </>
    )
}

export default memo(Sidebar)
