import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../store'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import { IOrderType } from '../services/api/service/orders/types'
import SalesmanOrders from '../containers/SalesmanOrders/SalesmanOrders'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

type Props = { type: IOrderType }
function SalesmanDashboardPage({ type }: Props): JSX.Element {
    const { formatMessage } = useIntl()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    const title = useMemo(() => {
        return formatMessage({ id: type === IOrderType.Asset ? 'order_mode.assets' : 'order_mode.orders' })
    }, [formatMessage, type])

    if (!isSalesmanResource(me)) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={'salesman-orders-page'} id={'salesman-orders-page'}>
                <SalesmanOrders type={type} />
            </div>
        </>
    )
}

export default SalesmanDashboardPage
