/*
 *
 * CrossSelling
 *
 */

import { objectEquals } from 'object-equals'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Carousel from '../../components/CmsWidget/Type/Collection/Carousel/Carousel'
import { ICmsWidgetHighlightCollection } from '../../services/api/service/cms/types'
import { IProductCollection } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { productCollectionProcessAction } from '../../store/product/actions'
import {
    makeFormatProductCollection,
    makeSelectProductCollection,
    makeSelectProductCollectionFetching,
    makeSelectProductCollectionIdentifiers,
} from '../../store/product/selectors'
import { IProductCollectionIdentifiers } from '../../store/product/types'
import { ICmsWidgetLink } from '../../types/widget'

interface IProps {
    ids: IProductCollectionIdentifiers
    showPrices: boolean
}

const THRESHOLD = [0.5]
const stateSelector = createStructuredSelector<any, any>({
    item: makeSelectProductCollection(),
    widget: makeFormatProductCollection(),
    fetching: makeSelectProductCollectionFetching(),
    identifiers: makeSelectProductCollectionIdentifiers(),
})

function ProductCollection({ ids, showPrices = true }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const [hasIds, setHasIds] = useState<boolean>(false)
    const { fetching, identifiers, item, widget } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            identifiers: IProductCollectionIdentifiers
            item: IProductCollection | undefined
            widget: ICmsWidgetHighlightCollection | undefined
        }
    >(stateSelector)
    const handleOnChange = useCallback(
        (inView: boolean) => {
            if (inView && !fetching && hasIds) {
                dispatch(productCollectionProcessAction(ids))
            }
        },
        [hasIds, ids, fetching, dispatch]
    )

    useEffect(() => {
        const vals = Object.values(ids).filter((id) => typeof id === 'string' && id.length > 0)
        setHasIds(vals.length > 0)
    }, [ids, setHasIds])

    useEffect(() => {
        if (hasIds && !item && !objectEquals(identifiers, ids)) {
            dispatch(productCollectionProcessAction(ids))
        }
    }, [hasIds, ids, identifiers, fetching, item, dispatch])

    const handleCmsWidgetLinkClick = (link: ICmsWidgetLink) => {
        try {
            if (!link.url) {
                return
            }
            const parser = document.createElement('a')
            parser.href = link.url!
            const redirectUrl = parser.pathname + parser.search
            history.push(redirectUrl)
        } catch (e) {
            console.error(e)
        }
    }

    let WidgetComponent = <></>

    if (widget) {
        const wdg: ICmsWidgetHighlightCollection = {
            ...widget,
            configuration: {
                ...widget.configuration,
                title: formatMessage({ id: 'products.collection.total_look' }),
                subtitle: formatMessage({ id: 'products.collection.up_selling' }),
            },
        }
        WidgetComponent = (
            <Carousel widget={wdg} showPrices={showPrices} onLinkClick={handleCmsWidgetLinkClick} prefixTitle />
        )
    }

    return (
        <InView onChange={handleOnChange} threshold={THRESHOLD} triggerOnce>
            {({ ref }) => (
                <div ref={ref} id={'product-collection-list'}>
                    {WidgetComponent}
                </div>
            )}
        </InView>
    )
}

ProductCollection.defaultProps = {} as Partial<IProps>

export default memo(ProductCollection)
