/*
 *
 * CustomerSalesmen
 *
 */

import classNames, { Value as ClassValue } from 'classnames'
import React, { useMemo } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { $PropertyType, NonUndefined } from 'utility-types'
import { IFamilyTreeCollection } from '../../../services/api/service/classification/types'
import { ICustomer } from '../../../services/api/service/customers/types'
import {
    ICustomerSalesman,
    ICustomerSalesmenCollection,
    ISalesman,
    ISalesmenCollection,
} from '../../../services/api/service/salesman/types'
import ResumeCard from '../../Customer/ResumeCard'
import FlatIcon from '../../Icon/FlatIcon'
import List from '../../Salesman/List/List'
import { forEach, has } from 'lodash'

const DEFAULT_SHIPPING_LOCATION = 'default'

export interface IProps {
    className?: ClassValue
    salesmen: ICustomerSalesmenCollection
    tree: IFamilyTreeCollection
    customer: ICustomer
    store?: ICustomer
}

type SalesmanItemKey = NonUndefined<NonNullable<$PropertyType<ISalesman, 'shipping_location'>>> | 'default'
type SalesmanItem = {
    shippingLocation?: $PropertyType<ICustomerSalesman, 'shipping_location'>
    entries: ISalesmenCollection
}

function CustomerSalesmenCard({ className, salesmen, store }: IProps): JSX.Element {
    const formatted = useMemo(() => {
        const items: Record<SalesmanItemKey, SalesmanItem> = {}

        if (!salesmen) {
            return []
        }

        // 1 - extraction de tous les identifiants des commerciaux
        const identifiers = salesmen.map((itm) => itm.salesman['@id'])

        // c'est le même pour tous les emplacements logistique ?
        const same = identifiers.every((val, i, arr) => val === arr[0])
        if (same) {
            return [{ entries: [salesmen[0].salesman] }]
        }

        // 2 - identifiants & commerciaux par shipping location
        const ids: Record<SalesmanItemKey, Array<$PropertyType<$PropertyType<SalesmanItem, 'entries'>[0], '@id'>>> = {}
        forEach(salesmen, (itm) => {
            const shippingLocationId = itm.shipping_location?.['@id'] || DEFAULT_SHIPPING_LOCATION
            if (!has(ids, shippingLocationId)) {
                ids[shippingLocationId] = []
            }
            if (!has(items, shippingLocationId)) {
                items[shippingLocationId] = { shippingLocation: itm.shipping_location, entries: [] }
            }

            // on s'assure qu'on n'a qu'un seul commercial
            if (ids[shippingLocationId]?.indexOf(itm.salesman['@id']) === -1) {
                ids[shippingLocationId].push(itm.salesman['@id'])
                items[shippingLocationId].entries.push(itm.salesman)
            }
        })

        // on retourne les infos
        return Object.values(items)
    }, [salesmen])

    return (
        <Card className={classNames('member-card', 'customer-salesmen', className)}>
            <Card.Header>
                <h3 className={'hv'}>
                    <FormattedMessage id={'customer.salesmen'} />
                </h3>
                {store && (
                    <div className={'store-info'}>
                        <FlatIcon icon="shopping-store" />
                        <ResumeCard customer={store} addressInline hideAddressLines />
                    </div>
                )}
            </Card.Header>
            <Card.Body>
                <Row>
                    {formatted.map((item, index) => {
                        return (
                            <Col xs={24} lg={12} xl={true} key={`salesman_shipping_location_${index}`}>
                                <div className={'shipping-location-salesmen'}>
                                    <div className={'heading'}>
                                        <p className="title">
                                            <FlatIcon icon="delivery-truck-2" />
                                            <span className="lbl">
                                                {item.shippingLocation && item.shippingLocation.full_name}
                                                {!item.shippingLocation && (
                                                    <FormattedMessage id="default.sales_contact" />
                                                )}
                                            </span>
                                        </p>
                                    </div>

                                    <div className="list">
                                        {item.entries.map((entry) => (
                                            <List
                                                salesman={entry}
                                                key={entry['@id']}
                                                shippingLocation={item.shippingLocation}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CustomerSalesmenCard
