import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import LocalizationActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { formatAppError } from '../app/saga'
import { IApiCountryListResponse } from '../../services/api/service/localization/types'
import { localizationCountryListFailureAction, localizationCountryListSuccessAction } from './actions'

function* processCountriesRequest() {
    const api: IApiClient = yield getContext('api')

    try {
        const response: IApiCountryListResponse = yield call({ context: api.localization, fn: 'getCountries' })
        yield put(localizationCountryListSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'default.unknow_list_error')
        yield put(localizationCountryListFailureAction(err))
    }
}

export default [takeLatest(LocalizationActionTypes.COUNTRY_LIST_PROCESS_ACTION, processCountriesRequest)]
