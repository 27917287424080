/**
 *
 * Item
 *
 */
import React, { MouseEvent, useCallback } from 'react'
import { ICMsWidgetInspirationItem } from '../../../../services/api/service/cms/types'
import { InspirationItemClickCallback } from '../../../../types/inspiration'

interface IProps {
    item: ICMsWidgetInspirationItem
    onItemClick?: InspirationItemClickCallback
}

function CarouselItem({ item, onItemClick }: IProps): JSX.Element {
    const handleOnClick = useCallback(
        (evt: MouseEvent) => {
            evt.preventDefault()
            if (onItemClick) {
                onItemClick(item)
            }
        },
        [item, onItemClick]
    )

    return (
        <div className={'carousel-app-item'}>
            <div className={'carousel-app-item-inner'}>
                <button onClick={handleOnClick}>
                    <img className={'swiper-lazy'} src={item.image.default} alt={item.label} />
                    <span className={'btn btn-dark animate__animated'}>{item.label}</span>
                </button>
            </div>
        </div>
    )
}

CarouselItem.defaultProps = {} as Partial<IProps>

export default CarouselItem
