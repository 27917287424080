import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerStores from '../containers/CustomerStores/Loadable'

export default function CustomerStoresPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.stores' })}</title>
            </Helmet>
            <CustomerStores />
        </>
    )
}
