import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../store'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import SalesmanInvoices from '../containers/SalesmanInvoices/SalesmanInvoices'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

type Props = {}
function SalesmanInvoicesPage(_: Props): JSX.Element {
    const { formatMessage } = useIntl()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    const title = useMemo(() => {
        return formatMessage({ id: 'salesman_customer.invoices' })
    }, [formatMessage])

    if (!isSalesmanResource(me)) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={'salesman-invoices-page'} id={'salesman-invoices-page'}>
                <SalesmanInvoices />
            </div>
        </>
    )
}

export default SalesmanInvoicesPage
