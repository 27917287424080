import { call, put, select, takeLatest, getContext, cancelled } from 'redux-saga/effects'
import AuthActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { orderDetailFailureAction, orderDetailSuccessAction } from './actions'

import { makeSelectOrderDetailId } from './selectors'
import { IApiOrderResponse } from '../../services/api/service/orders/types'
import { formatAppError } from '../app/saga'
// TOKEN
import axios from 'axios'
import { makeSelectOrderCustomerWithId } from '../orders/selectors'
import { processOrderCustomerRequest } from '../orders/saga'
import OrderActionTypes from '../orders/constants'
const CancelToken = axios.CancelToken

function* processOrderRequest() {
    const source = CancelToken.source()
    const api: IApiClient = yield getContext('api')
    const orderId: string = yield select(makeSelectOrderDetailId())

    try {
        const response: IApiOrderResponse = yield call({ context: api.orders, fn: 'get' }, orderId, source.token)

        // récupération du client si besoin
        const orderCustomer = yield select(makeSelectOrderCustomerWithId(), response.data.customer.id)

        if (!orderCustomer) {
            yield call(processOrderCustomerRequest, {
                type: OrderActionTypes.CUSTOMER_PROCESS_ACTION,
                payload: {
                    customer: response.data.customer.id,
                },
            })
        }

        // stockage
        yield put(orderDetailSuccessAction(response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'order.detail.error')
        yield put(orderDetailFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [takeLatest(AuthActionTypes.DETAIL_PROCESS_ACTION, processOrderRequest)]
