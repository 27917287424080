import { useSelector } from 'react-redux'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../../store'
import { ICustomer } from '../../services/api/service/customers/types'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
})

export const useCustomer = () => {
    const { customer } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
        }
    >(stateSelector)

    return customer
}
