/*
 *
 * SplashScreen reducer
 *
 */

import ActionTypes from './constants'
import { ContainerState, ContainerActions } from './types'

export const initialState: ContainerState = {
    show: true,
    animated: false,
}

function splashScreenReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.SHOW_ACTION:
            return { ...state, show: true }
        case ActionTypes.HIDE_ACTION:
            return { ...state, show: false }
        default:
            return state
    }
}

export default splashScreenReducer
