/*
 *
 * TopBar
 *
 */

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Advice from '../../components/Header/Advice'
import Schedule from '../../components/Header/Schedule'
import { LangItemPartType } from '../../components/LangItem/types'
import { makeSelectOpeningHours } from '../../store/config/selectors'
import LangSwitcher from '../LanguageProvider/LangSwitcher'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IApplicationRootState } from '../../store'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'

const stateSelector = createStructuredSelector<any, any>({
    openingHours: makeSelectOpeningHours(),
    me: makeSelectAuthMe(),
})

const itemParts = [LangItemPartType.Flag, LangItemPartType.Native]
const selectedItemParts = [LangItemPartType.Flag]

function TopBar(): JSX.Element {
    const { openingHours, me } = useSelector<
        IApplicationRootState,
        {
            openingHours?: string
            me: IMe
        }
    >(stateSelector)

    return (
        <section className={'top-bar'}>
            <Container>
                <Row className={'justify-content-between'}>
                    <Col>
                        {!isSalesmanResource(me) && (
                            <div className={'schedule-advice'}>
                                <Schedule text={openingHours} />
                                {openingHours && <span className={'separator'} />}
                                <Advice />
                            </div>
                        )}
                    </Col>
                    <Col xs={'auto'}>
                        <LangSwitcher itemParts={itemParts} selectedItemParts={selectedItemParts} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TopBar
