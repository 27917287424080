import {
    isStatisticsClassificationFamily,
    isStatisticsShippingLocation,
    IStatisticsClassificationTypes,
    IStatisticsFamilyTreeCollection,
} from '../../../../../services/api/service/stats/types'
import { Form, InputGroup } from 'react-bootstrap'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import DropdownTreeSelect, { TextProps, TreeData, TreeNode, TreeNodeProps } from 'react-dropdown-tree-select'
import { Undefinable } from 'tsdef'
import forEach from 'lodash/forEach'
import isUndefined from 'lodash/isUndefined'
import { IMemberStatisticsFilters } from '../../../../../store/statistics/types'
import { FamilyType, ParentFamilyType } from '../../../../../services/api/service/classification/types'
import { $PropertyType } from 'utility-types'
import classNames from 'classnames'

type FamilyTreeChoiceProps = {
    familyTree?: IStatisticsFamilyTreeCollection
    disabled?: boolean
    onChange: (classification: $PropertyType<IMemberStatisticsFilters, 'classification'>) => void
    value?: $PropertyType<IMemberStatisticsFilters, 'classification'>
}

type FlattenCollection = Record<string, Array<string>>

function appendItemToCollection(item: IStatisticsClassificationTypes, collection: FlattenCollection) {
    if (isUndefined(collection[item['@type']])) {
        collection[item['@type']] = []
    }
    collection[item['@type']].push(item['@id'])

    if (isStatisticsShippingLocation(item)) {
        forEach(item.tree, (item) => {
            appendItemToCollection(item, collection)
        })
    } else {
        forEach(item.sub_families, (item) => {
            appendItemToCollection(item, collection)
        })
    }

    return collection
}

function getItemFamilyType(
    item: IStatisticsClassificationTypes,
    parent?: IStatisticsClassificationTypes,
    parentNode?: TreeNode
): FamilyType | ParentFamilyType.ShippingLocation {
    if (isStatisticsShippingLocation(item)) {
        return ParentFamilyType.ShippingLocation
    }

    if (isStatisticsShippingLocation(parent)) {
        return FamilyType.Department
    } else if (isStatisticsClassificationFamily(parent) && parentNode?.type !== FamilyType.Department) {
        return FamilyType.SubFamily
    }

    return FamilyType.Family
}

function isItemChecked(
    item: IStatisticsClassificationTypes,
    itemType: ReturnType<typeof getItemFamilyType>,
    classification?: $PropertyType<IMemberStatisticsFilters, 'classification'>
): boolean {
    if (!classification) {
        return false
    }

    if (itemType === FamilyType.Department) {
        if (!classification.departments) {
            return false
        }
        return classification.departments.indexOf(item['@id']) > -1
    } else if (itemType === FamilyType.Family) {
        if (!classification.families) {
            return false
        }
        return classification.families.indexOf(item['@id']) > -1
    } else if (itemType === FamilyType.SubFamily) {
        if (!classification.subFamilies) {
            return false
        }
        return classification.subFamilies.indexOf(item['@id']) > -1
    }

    return false
}

function convertItemToTreeNode(
    item: IStatisticsClassificationTypes,
    classification?: $PropertyType<IMemberStatisticsFilters, 'classification'>,
    parent?: IStatisticsClassificationTypes,
    parentNode?: TreeNode
): TreeNode {
    const itemType = getItemFamilyType(item, parent, parentNode)
    const itemChecked = isItemChecked(item, itemType, classification)
    const node: TreeNode = {
        checked: itemChecked,
        type: itemType,
        value: item['@id'],
        label: item.label,
        children: [],
        parent: parent?.['@id'],
        expanded: itemType === ParentFamilyType.ShippingLocation,
    }

    if (isStatisticsShippingLocation(item)) {
        forEach(item.tree, (sub) => {
            node.children.push(convertItemToTreeNode(sub, classification, item, node))
        })
    } else {
        forEach(item.sub_families, (sub) => {
            node.children.push(convertItemToTreeNode(sub, classification, item, node))
        })
    }

    node.className = classNames(`classification-item-${itemType}`, {
        'has-subs': node.children.length > 0,
        'has-parent': !isUndefined(parent),
    })

    return node
}

function convertFamilyTreeToTreeData(
    shippingLocations: Undefinable<IStatisticsFamilyTreeCollection>,
    classification?: $PropertyType<IMemberStatisticsFilters, 'classification'>
): TreeData {
    if (!shippingLocations) {
        return []
    }

    const data: Array<TreeNodeProps> = []
    forEach(shippingLocations, (shippingLocation) => {
        data.push(convertItemToTreeNode(shippingLocation, classification))
    })

    return data
}

const FamilyTreeChoice = ({ familyTree, onChange, disabled = false, value: classification }: FamilyTreeChoiceProps) => {
    const { formatMessage } = useIntl()
    const [initialized, setInitialized] = useState(false)
    const [data, setData] = useState<TreeData>([])

    const texts: TextProps = useMemo(() => {
        return {
            placeholder: formatMessage({ id: 'default.all' }),
            noMatches: formatMessage({ id: 'default.no_searched_results' }),
            labelRemove: formatMessage({ id: 'default.delete' }),
        }
    }, [formatMessage])

    const handleChange = useCallback(
        (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
            const classification: $PropertyType<IMemberStatisticsFilters, 'classification'> = {
                departments: [],
                families: [],
                subFamilies: [],
            }

            forEach(selectedNodes, (selectedNode) => {
                if (selectedNode.type === FamilyType.Family) {
                    classification.families?.push(selectedNode.value)
                } else if (selectedNode.type === FamilyType.SubFamily) {
                    classification.subFamilies?.push(selectedNode.value)
                } else if (selectedNode.type === FamilyType.Department) {
                    classification.departments?.push(selectedNode.value)
                }
            })

            onChange(classification)
        },
        [onChange]
    )

    useEffect(() => {
        if (!familyTree || disabled) {
            console.log({ familyTree, disabled })
            setData([])
            setInitialized(false)
            return
        }
        setData(convertFamilyTreeToTreeData(familyTree, initialized ? classification : undefined))
        setInitialized(true)
    }, [familyTree, classification, setInitialized, initialized, setData, disabled])

    return (
        <div className="customer-stats-family-tree-field">
            <Form.Group>
                <Form.Label htmlFor="customer-stats-family-tree">
                    <FormattedMessage id="default.filter" />
                </Form.Label>
                <InputGroup className="input-group-search">
                    <DropdownTreeSelect
                        className={'bs-tree-select'}
                        showPartiallySelected
                        keepTreeOnSearch
                        keepOpenOnSelect
                        mode="multiSelect"
                        keepChildrenOnSearch={false}
                        disabled={disabled}
                        data={data}
                        onChange={handleChange}
                        texts={texts}
                    />
                </InputGroup>
            </Form.Group>
        </div>
    )
}

export default FamilyTreeChoice
