/*
 *
 * Auth constants
 *
 */

enum ActionTypes {
    WAIT2FA_ACTION = 'app/Auth/2Fa/WAIT',
    PROCESS_2FA_ACTION = 'app/Auth/2Fa/REQUEST_PROCESS',
    FAILURE_2FA_ACTION = 'app/Auth/2Fa/REQUEST_FAILURE',
    PROCESS_ACTION = 'app/Auth/REQUEST_PROCESS',
    FAILURE_ACTION = 'app/Auth/REQUEST_FAILURE',
    LOGOUT_ACTION = 'app/Auth/LOGOUT',
    LOGGED_ACTION = 'app/Auth/LOGGED',
    RESET_ACTION = 'app/Auth/RESET',
}

export default ActionTypes
