import { blobToBase64String, imgSrcToBlob } from 'blob-util'
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js'
import isString from 'lodash/isString'
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox'
import { FormattedMessage, useIntl } from 'react-intl'
import { $PropertyType } from 'utility-types'
import VCard from 'vcard-creator'
import { ICustomerSalesman, ISalesman } from '../../../services/api/service/salesman/types'
import FlatIcon from '../../Icon/FlatIcon'
import SalesmanPlaceholder from '../../../assets/img/salesman-placeholder.png'

export interface IProps {
    salesman: ISalesman
    shippingLocation?: $PropertyType<ICustomerSalesman, 'shipping_location'>
}

function List({ salesman, shippingLocation }: IProps): JSX.Element {
    const { locale } = useIntl()
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
    const [phoneNumberParsed, setPhoneNumberParsed] = useState<PhoneNumber>()
    const imgRef = React.createRef<HTMLImageElement>()
    const phoneNumber = useMemo(() => {
        if (!salesman.telephone) {
            return undefined
        }
        return salesman.telephone
    }, [salesman])

    useEffect(() => {
        if (!phoneNumber) {
            setPhoneNumberParsed(undefined)
            return
        }
        try {
            setPhoneNumberParsed(parsePhoneNumber(phoneNumber))
            return
        } catch (e) {
            console.error(e)
        }
        setPhoneNumberParsed(undefined)
    }, [phoneNumber, locale, setPhoneNumberParsed])

    const phoneNumberHuman = useMemo(() => {
        if (!phoneNumberParsed) {
            return undefined
        }
        return phoneNumberParsed.formatInternational()
    }, [phoneNumberParsed])

    const fullName = useMemo(() => {
        return salesman.name
        // if (!salesman.gender) {
        // }
        // return `${salesman.gender} ${salesman.name}`
    }, [salesman])

    // const letters = useMemo(() => {
    //     // eslint-disable-next-line no-useless-escape
    //     return initials(salesman.name.replace(/[\[\]\(\)']+/g, ''), 3)
    // }, [salesman])

    const handleOnZoomClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            setLightboxOpen(true)
        },
        [setLightboxOpen]
    )

    const handleVCardClick = useCallback(async () => {
        const vCard = new VCard()
        // vCard.addName(salesman.name, undefined, undefined, salesman.gender)
        vCard.addName(salesman.name)

        if (salesman.email) {
            vCard.addEmail(salesman.email)
        }
        if (shippingLocation) {
            vCard.addCompany(shippingLocation.full_name)
        } else {
            vCard.addCompany('U10')
        }

        // ajout logo
        // try {
        //     const logoUrl = `${window.location.origin}${images.logoImg}`
        //     const blob = await imgSrcToBlob(logoUrl, 'image/png', 'anonymous')
        //     const base = await blobToBase64String(blob)
        //     vCard.addLogo(base, 'PNG')
        // } catch (e) {
        //     console.error(e)
        // }

        if (phoneNumberParsed) {
            // @ts-ignore
            vCard.addPhoneNumber(phoneNumberParsed.formatInternational(), 'PREF;WORK')
        }

        if (imgRef.current) {
            try {
                const blob = await imgSrcToBlob(imgRef.current.src, 'image/jpeg', 'anonymous')
                const base = await blobToBase64String(blob)
                vCard.addPhoto(base, 'JPEG')
            } catch (e) {
                console.error(e)
            }
        }

        try {
            const link = document.createElement('a')
            link.download = `${fullName}.vcf`
            const blob = new Blob([vCard.toString()], { type: 'text/vcard' })
            link.href = window.URL.createObjectURL(blob)
            link.click()
        } catch (e) {
            console.error(e)
        }
    }, [salesman.name, salesman.email, shippingLocation, phoneNumberParsed, imgRef, fullName])

    const caption = useMemo(() => {
        return [fullName, salesman.email, phoneNumberHuman].filter((s) => isString(s) && s.length > 0).join(' - ')
    }, [fullName, salesman, phoneNumberHuman])

    return (
        <div className="salesman">
            <Row>
                <Col className="avatar" xs="auto">
                    <span className={'preview'} onClick={handleOnZoomClick}>
                        {salesman.image ? (
                            <img src={salesman.image} ref={imgRef} alt={fullName} className="picture" />
                        ) : (
                            <img src={SalesmanPlaceholder} ref={imgRef} alt={fullName} className="picture" />
                        )}
                    </span>
                </Col>
                <Col className="details">
                    <p className="full-name">{fullName}</p>
                    {phoneNumber && (
                        <p className="phone">
                            <a href={`tel:${phoneNumber}`}>
                                <FlatIcon icon="phone" />
                                <span className="lbl">{phoneNumberHuman}</span>
                            </a>
                        </p>
                    )}
                    {salesman.email && (
                        <p className="email">
                            <a href={`mailto:${salesman.email}`}>
                                <FlatIcon icon="closed-envelope" />
                                <span className="lbl">{salesman.email}</span>
                            </a>
                        </p>
                    )}
                    <p className="download">
                        <button className={'btn btn-link'} onClick={handleVCardClick}>
                            <FlatIcon icon="id-card" />
                            <span className="lbl">
                                <FormattedMessage id="default.download_vcard" />
                            </span>
                        </button>
                    </p>
                </Col>
            </Row>
            {lightboxOpen && (
                <Lightbox
                    imageCaption={caption}
                    mainSrc={salesman.image || SalesmanPlaceholder}
                    mainSrcThumbnail={salesman.image || SalesmanPlaceholder}
                    onCloseRequest={() => setLightboxOpen(false)}
                />
            )}
        </div>
    )
}

export default memo(List)
