import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the orders state domain
 */

const selectOrdersDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders || initialState
}

const selectOrdersCustomerDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders.customer || initialState.customer
}

const selectOrdersListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders.list || initialState.list
}

const selectOrdersLastDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders.last || initialState.last
}

const selectOrdersDocumentDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders.document || initialState.document
}

const selectOrdersMode = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.orders.mode || initialState.mode
}

const makeSelectOrdersListFetching = () =>
    createSelector(selectOrdersListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectOrdersListError = () =>
    createSelector(selectOrdersListDomain, (substate) => {
        return substate.error
    })

const makeSelectOrdersListItems = () =>
    createSelector(selectOrdersListDomain, (substate) => {
        return substate.items
    })

const makeSelectOrdersListTotalItems = () =>
    createSelector(selectOrdersListDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectOrdersLastFetching = () =>
    createSelector(selectOrdersLastDomain, (substate) => {
        return substate.fetching
    })

const makeSelectOrdersLastError = () =>
    createSelector(selectOrdersLastDomain, (substate) => {
        return substate.error
    })

const makeSelectOrdersLastItems = () =>
    createSelector(selectOrdersLastDomain, (substate) => {
        return substate.items
    })

const makeSelectOrdersLastTotalItems = () =>
    createSelector(selectOrdersLastDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectOrdersDocument = () =>
    createSelector(selectOrdersDocumentDomain, (substate) => {
        return substate
    })

const makeSelectOrdersMode = () =>
    createSelector(selectOrdersMode, (orderMode) => {
        return orderMode
    })

const makeSelectOrdersDocumentFetching = () =>
    createSelector(
        [
            makeSelectOrdersDocument(),
            (_: any, orderId: string, __: any) => orderId,
            (_: any, __: string, documentType: any) => documentType,
        ],
        (subState, orderId, documentType) => {
            if (!subState[orderId] || !subState[orderId][documentType]) {
                return false
            }
            return subState[orderId][documentType].fetching
        }
    )

const makeSelectOrdersDocumentLink = () =>
    createSelector(
        [
            makeSelectOrdersDocument(),
            (_: any, orderId: string, __: any) => orderId,
            (_: any, __: string, documentType: any) => documentType,
        ],
        (subState, orderId, documentType) => {
            if (!subState[orderId] || !subState[orderId][documentType]) {
                return undefined
            }
            return subState[orderId][documentType].link
        }
    )
const makeSelectOrdersDocumentError = () =>
    createSelector(
        [
            makeSelectOrdersDocument(),
            (_: any, orderId: string, __: any) => orderId,
            (_: any, __: string, documentType: any) => documentType,
        ],
        (subState, orderId, documentType) => {
            if (!subState[orderId] || !subState[orderId][documentType]) {
                return undefined
            }
            return subState[orderId][documentType].error
        }
    )

const makeSelectOrders = () =>
    createSelector(selectOrdersDomain, (substate) => {
        return substate
    })

const makeSelectOrderCustomerWithId = () =>
    createSelector([selectOrdersCustomerDomain, (_: any, customerId: string) => customerId], (subState, customerId) => {
        return subState[customerId] ? subState[customerId].customer : undefined
    })

export default makeSelectOrders
export {
    selectOrdersDomain,
    makeSelectOrdersLastFetching,
    makeSelectOrdersListError,
    makeSelectOrdersListItems,
    makeSelectOrdersListTotalItems,
    makeSelectOrdersListFetching,
    makeSelectOrdersLastError,
    makeSelectOrdersLastItems,
    makeSelectOrdersLastTotalItems,
    makeSelectOrdersDocument,
    makeSelectOrdersMode,
    makeSelectOrdersDocumentLink,
    makeSelectOrdersDocumentError,
    makeSelectOrdersDocumentFetching,
    makeSelectOrderCustomerWithId,
}
