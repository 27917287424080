/**
 *
 * InvoiceState
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { InvoiceState as InvoiceStates } from '../../services/api/service/invoices/types'
import classNames from 'classnames'

interface IProps {
    state: InvoiceStates
}

function InvoiceState({ state }: IProps): JSX.Element {
    return (
        <span className={classNames('invoice-state', state)}>
            <FormattedMessage id={`invoice.state.${state.toString()}`} />
        </span>
    )
}

InvoiceState.defaultProps = {} as Partial<IProps>

export default memo(InvoiceState)
