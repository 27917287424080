import React, { useEffect, useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { default as Component } from '../../components/CmsBlock/CmsBlock'
import { CmsBlockIdentifierType } from '../../services/api/service/cms/types'
import { IApplicationRootState } from '../../store'
import { cmsBlockProcessAction } from '../../store/cms/actions'
import { makeSelectCmsBlock } from '../../store/cms/selectors'
import { ICmsBlockState } from '../../store/cms/types'
import { CmsWidgetLinkClickCallback } from '../../types/widget'

interface IProps {
    children?(...nodes: React.ReactNodeArray): React.ReactNode
    identifierType?: CmsBlockIdentifierType
    identifier: string
    lazy?: boolean
    threshold?: number
    onCmsWidgetLinkClick?: CmsWidgetLinkClickCallback
}

function CmsBlock({
    identifier,
    identifierType,
    lazy,
    threshold,
    children,
    onCmsWidgetLinkClick,
}: IProps): JSX.Element {
    const dispatch = useDispatch()
    const [cmsLoading, setCmsLoading] = useState<boolean>(!lazy)
    const selectCmsBlockWithIdentifier = useMemo(makeSelectCmsBlock, [])
    const cmsBlock: ICmsBlockState = useSelector<IApplicationRootState, ICmsBlockState>((state) =>
        selectCmsBlockWithIdentifier(state, identifier, identifierType)
    )

    useEffect(() => {
        if (!lazy || (lazy && cmsLoading)) {
            if (!cmsBlock) {
                dispatch(cmsBlockProcessAction(identifier, identifierType!))
            }
        }
    }, [cmsBlock, identifier, identifierType, cmsLoading, lazy, dispatch])

    const handleVisibilityChangeCallback = (inView: boolean) => {
        if (inView) {
            setCmsLoading(true)
        }
    }

    let WidgetComponent = <></>

    if (!lazy && !cmsBlock) {
        return WidgetComponent
    }

    if (cmsBlock) {
        WidgetComponent = (
            <Component
                fetching={cmsBlock.fetching}
                item={cmsBlock.block}
                error={cmsBlock.error}
                onCmsWidgetLinkClick={onCmsWidgetLinkClick}
            />
        )
    }

    if (!lazy) {
        return (
            <>
                {children && children(WidgetComponent, cmsBlock?.block)}
                {!children && WidgetComponent}
            </>
        )
    }
    return (
        <InView onChange={handleVisibilityChangeCallback} threshold={threshold} triggerOnce>
            {children && children(WidgetComponent, cmsBlock?.block)}
            {!children && WidgetComponent}
        </InView>
    )
}

CmsBlock.defaultProps = {
    identifierType: CmsBlockIdentifierType.Code,
    lazy: true,
    threshold: 0.1,
} as Partial<IProps>

export default CmsBlock
