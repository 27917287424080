/**
 *
 * CustomerStock
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface IProps {
    labelId?: string
    customerStock: number | null
    customerStockDate: string | null
    onClick?: (e: MouseEvent) => void
}

function CustomerStock({ customerStock, customerStockDate, onClick, labelId }: IProps): JSX.Element {
    const { formatDate } = useIntl()

    const customerStockValue = useMemo(() => {
        if (customerStock === null) {
            return '-'
        }
        return customerStock
    }, [customerStock])
    return (
        <div
            className={classNames('product-member-info', 'product-customer-stock', 'product-field', {
                'has-callback': typeof onClick !== 'undefined',
                'has-date': typeof customerStockDate === 'string',
            })}
            onClick={onClick}
        >
            <span className={'lbl'}>
                <FormattedMessage id={labelId} />
            </span>
            <span className={'value'}>{customerStockValue}</span>
            {customerStockDate && <span className={'extra'}>({formatDate(customerStockDate)})</span>}
        </div>
    )
}

CustomerStock.defaultProps = {
    customerStock: null,
    labelId: 'customer.your_stock',
} as Partial<IProps>

export default memo(CustomerStock)
