/**
 *
 * Picker Action Picture
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import isNumber from 'lodash/isNumber'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'
import {
    productsListRefreshAction,
    productsSelectionApplyActionProcess,
    productsSelectionResetAction,
    productsSelectionResetProcessAction,
} from '../../../../store/products/actions'
import Button from '../../../../components/Buttons/Button'
import classNames from 'classnames'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import { Overlay, Popover } from 'react-bootstrap'
import { IPickerActionComponentProps } from '../../../../types/productPicker'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductsListSelectionError,
    makeSelectProductsListSelectionProcessed,
    makeSelectProductsListSelectionProcessing,
    makeSelectProductsListSelectionSuccess,
} from '../../../../store/products/selectors'
import PictureExportModal from '../../../PictureExport/Loadable'
import { IPictureExport } from '../../../../services/api/service/pictureExport/types'

const stateSelector = createStructuredSelector<any, any>({
    processing: makeSelectProductsListSelectionProcessing(),
    processed: makeSelectProductsListSelectionProcessed(),
    error: makeSelectProductsListSelectionError(),
    successMessage: makeSelectProductsListSelectionSuccess(),
})

function PickerActionPicture({ action, items }: IPickerActionComponentProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { min = 1, max } = action.options
    const target = useRef() as React.MutableRefObject<HTMLDivElement>
    const [warnings, setWarnings] = useState<Array<string>>([])
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const disabled = useMemo(() => {
        return isNumber(min) && min > items.length
    }, [min, items])

    const { processed, processing, error, successMessage } = useSelector<
        IApplicationRootState,
        {
            processing: boolean
            processed: boolean
            error?: IAppErrorTypes
            successMessage?: string
        }
    >(stateSelector)

    useEffect(() => {
        const warns: Array<string> = []
        if (isNumber(min) && items.length < min) {
            warns.push(formatMessage({ id: 'product_picker.min_warning' }, { min: min }))
        } else if (isNumber(max) && items.length > max) {
            warns.push(formatMessage({ id: 'product_picker.max_warning' }, { max: max }))
        }
        setWarnings(warns)
    }, [min, max, items, setWarnings, formatMessage])

    const handleMouseEnter = useCallback((): void => {
        setShowTooltip(warnings.length > 0)
    }, [setShowTooltip, warnings])
    const handleMouseLeave = useCallback((): void => {
        setShowTooltip(false)
    }, [setShowTooltip])

    const onDownloadClick = useCallback(() => {
        if (disabled) {
            return
        }
        // dispatch pour dire qu'on est en processing
        dispatch(productsSelectionApplyActionProcess())
        setShowModal(true)
    }, [disabled, dispatch, setShowModal])

    const handleExportDone = useCallback(
        (pictureExport: IPictureExport) => {
            dispatch(productsListRefreshAction())
            dispatch(productsSelectionResetAction())
            setShowModal(false)
        },
        [dispatch, setShowModal]
    )

    const handleExportError = useCallback(() => {
        dispatch(productsSelectionResetProcessAction())
    }, [dispatch])

    const handleExportHide = useCallback(() => {
        setShowModal(false)
    }, [setShowModal])

    return (
        <>
            <div className="sub-actions" ref={target} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Button
                    variant="primary"
                    onClick={onDownloadClick}
                    className={classNames({ inactive: disabled })}
                    disabled={disabled || processing || processed || typeof error !== 'undefined'}
                >
                    <FlatIcon icon={'download'} />
                    <FormattedMessage id="product_picker.download_picture" />
                </Button>
            </div>
            <Overlay target={target.current} show={showTooltip}>
                {(props) => (
                    <Popover id="warnings-tooltip" {...props}>
                        <Popover.Content>
                            {warnings.map((warning) => (
                                <p className="invalid-feedback" key={warning}>
                                    {warning}
                                </p>
                            ))}
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
            <PictureExportModal
                show={showModal}
                identifiers={items}
                onDone={handleExportDone}
                onError={handleExportError}
                onHide={handleExportHide}
            />
        </>
    )
}

export default memo(PickerActionPicture)
