import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiExportProductListParameters,
    IApiExportProductListResponse,
    IApiExportProductResponse,
    IExportService,
} from './types'

const create = (instance: AxiosInstance): IExportService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (
        params: IApiExportProductListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiExportProductListResponse> => {
        return instance.get('/exports', { params: params, cancelToken })
    }

    const get = (productExportId: string, cancelToken?: CancelToken): Promise<IApiExportProductResponse> => {
        return instance.get(`${productExportId}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        get,
    }
}

export default {
    create,
}
