import classNames from 'classnames'
import remove from 'lodash/remove'
import Qs from 'qs'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap'
import ReactDOM from 'react-dom'
import useSearch from 'react-hook-search'
import { FormattedMessage, useIntl } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useHistory, useLocation } from 'react-router-dom'
import FlatIcon from '../../../../../components/Icon/FlatIcon'
import {
    IProductListFilterOption,
    IProductListFilterOptionCollection,
    ProductListQueryName,
} from '../../../../../services/api/service/products/types'
import { IProductListUserFilterValue } from '../../../../../store/products/types'
import rtrim from '../../../../../utils/rtrim'
import Heading from '../../Partial/Heading'
import { IFactoryProps } from '../../type'
import Entry from './Entry'

function TypeOptions({ filter, value, showPreview, swatch, onToggleOpen, filterPortalId }: IFactoryProps): JSX.Element {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [wantedValues, setWantedValues] = useState<IProductListUserFilterValue>(undefined)
    const history = useHistory()
    const location = useLocation()
    // const { queries } = useProductListParsedQuery()

    const isEmpty = useMemo(() => {
        return typeof wantedValues === 'undefined'
    }, [wantedValues])

    const valueCount = useMemo(() => {
        if (value) {
            return Object.values(value).length
        }
        return undefined
    }, [value])

    const options = useMemo(() => {
        let formatted: IProductListFilterOptionCollection = [...filter.options]
        if (filter.translatable) {
            formatted = formatted.map((value) => {
                return {
                    ...value,
                    label: formatMessage({ id: `product_filter.${filter.code}.options.${value.id}` }),
                }
            })
        }

        if (typeof value === 'undefined' || Object.keys(value).length === 0) {
            return formatted
        }

        const selected: IProductListFilterOptionCollection = []
        const notSelected: IProductListFilterOptionCollection = []
        formatted.forEach((single) => {
            if ((value as Array<number>).indexOf(single.id as number) === -1) {
                notSelected.push(single)
            } else {
                selected.push(single)
            }
        })

        return [...selected, ...notSelected]
    }, [filter.code, filter.options, filter.translatable, formatMessage, value])

    const headingSubTitle = useMemo(() => {
        if (!options || !value) {
            return undefined
        }
        const labels = options
            .filter((option) => Object.values(value).indexOf(option.id) > -1)
            .map((option) => option.label)
        return labels.join(', ')
    }, [options, value])
    // The search function will search through 'name' and 'address', but not 'id'
    const [filteredItems, search, setSearch] = useSearch<IProductListFilterOption>(options, (searchValue, item) =>
        item.label.toLowerCase().includes(searchValue.toLowerCase())
    )

    const handleHeadingClick = useCallback(() => {
        setOpen((state) => !state)
    }, [setOpen])

    const handleOnSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.currentTarget.value)
        },
        [setSearch]
    )

    const handleOnChange = useCallback(
        (option: IProductListFilterOption, selected: boolean) => {
            const wanted =
                typeof wantedValues === 'undefined' || !filter.multiple
                    ? []
                    : [...(wantedValues as Array<string | number>)]
            if (!selected) {
                remove(wanted, (val) => val === option.id)
            } else {
                wanted.push(option.id)
            }

            if (wanted.length === 0) {
                setWantedValues(undefined)
            } else {
                setWantedValues(wanted)
            }

            if (!filter.autoValidate) {
                return
            }

            const parsed = Qs.parse(location.search.substring(1))
            if (wanted.length === 0) {
                delete parsed[filter.code]
            } else {
                parsed[filter.code] = wanted as Array<string>
            }

            // reset pagination
            delete parsed[ProductListQueryName.Page]

            const stringified = Qs.stringify(parsed)
            const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
            const baseUrl = rtrim(location.pathname, '/')
            const finalUrl = `${baseUrl}${qstring}`
            if (finalUrl !== `${location.pathname}${location.search}`) {
                history.push(`${baseUrl}${qstring}`)
            }

            setOpen(false)
        },
        [filter, wantedValues, setWantedValues, setOpen, history, location]
    )

    const handleCancelClick = useCallback(() => {
        setOpen(false)
        setWantedValues(value)
        setSearch('')
    }, [setOpen, setWantedValues, setSearch, value])

    const handleResetClick = useCallback(() => {
        setOpen(false)
        setWantedValues(undefined)
        setSearch('')

        // reset query
        const parsed = Qs.parse(location.search.substring(1))
        delete parsed[filter.code]
        // reset pagination
        delete parsed[ProductListQueryName.Page]

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(location.pathname, '/')
        const finalUrl = `${baseUrl}${qstring}`
        if (finalUrl !== `${location.pathname}${location.search}`) {
            history.push(`${baseUrl}${qstring}`)
        }
    }, [setOpen, setSearch, setWantedValues, location, history, filter])

    const handleValidateClick = useCallback(() => {
        setOpen(false)
        setSearch('')

        // add to query
        const parsed = Qs.parse(location.search.substring(1))
        parsed[filter.code] = wantedValues as Array<string>
        // reset pagination
        delete parsed[ProductListQueryName.Page]

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(location.pathname, '/')
        const finalUrl = `${baseUrl}${qstring}`
        if (finalUrl !== `${location.pathname}${location.search}`) {
            history.push(`${baseUrl}${qstring}`)
        }
    }, [setSearch, location.search, location.pathname, filter.code, wantedValues, history])

    const handleOutsideClick = useCallback(() => {
        setOpen(false)
        setSearch('')

        const parsed = Qs.parse(location.search.substring(1))
        if (typeof wantedValues === 'undefined') {
            delete parsed[filter.code]
        } else {
            parsed[filter.code] = wantedValues as Array<string>
        }

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(location.pathname, '/')
        const finalUrl = `${baseUrl}${qstring}`
        if (finalUrl !== `${location.pathname}${location.search}`) {
            history.push(`${baseUrl}${qstring}`)
        }
    }, [setSearch, location.search, location.pathname, wantedValues, filter.code, history])

    const Content = open ? (
        <div className={'content'}>
            {filter.searchable && (
                <div className={'product-filter-search'}>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FlatIcon icon={'search'} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control
                                type={'text'}
                                value={search}
                                onChange={handleOnSearchChange}
                                placeholder={formatMessage({
                                    id: 'default.search',
                                })}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
            )}
            <div className={'values'}>
                {filteredItems.map((filteredItem) => (
                    <Entry
                        code={filter.code}
                        key={filteredItem.id}
                        swatch={swatch}
                        keyword={search}
                        showPreview={showPreview}
                        option={filteredItem}
                        selected={
                            typeof wantedValues === 'object' &&
                            Object.values(wantedValues).indexOf(filteredItem.id) > -1
                        }
                        multiple={filter.multiple}
                        onChange={handleOnChange}
                    />
                ))}
            </div>
            {!filter.autoValidate && (
                <div className={'action'}>
                    <Button
                        block
                        className={'btn-cancel'}
                        variant={'link'}
                        onClick={handleResetClick}
                        disabled={typeof wantedValues === 'undefined' && typeof value === 'undefined'}
                    >
                        <FormattedMessage id={'products.reset_filters'} />
                    </Button>
                    <Button
                        block
                        className={'btn-save'}
                        variant={'link'}
                        onClick={handleValidateClick}
                        disabled={typeof wantedValues === 'undefined' && typeof value === 'undefined'}
                    >
                        <FormattedMessage id={'default.save'} />
                    </Button>
                </div>
            )}
        </div>
    ) : (
        <></>
    )

    const Component =
        filterPortalId && document.getElementById(filterPortalId) && open
            ? ReactDOM.createPortal(
                  <div
                      className={classNames(
                          'product-filter',
                          `product-filter-${filter.code}`,
                          `product-filter-type-${filter.mode}`,
                          { open, empty: isEmpty }
                      )}
                  >
                      <Heading
                          filter={filter}
                          count={valueCount}
                          subTitle={headingSubTitle}
                          onClick={handleCancelClick}
                      />
                      {Content}
                  </div>,
                  document.getElementById(filterPortalId!)!
              )
            : Content

    useEffect(() => {
        if (!value) {
            setWantedValues(undefined)
        } else {
            setWantedValues(value)
        }
    }, [value, setWantedValues])

    useEffect(() => {
        if (onToggleOpen) {
            onToggleOpen(filter, open)
        }
    }, [filter, open, onToggleOpen])

    return (
        <div
            className={classNames(
                'product-filter',
                `product-filter-${filter.code}`,
                `product-filter-type-${filter.mode}`,
                { open, empty: isEmpty }
            )}
        >
            {!filterPortalId && (
                <OutsideClickHandler onOutsideClick={handleOutsideClick} disabled={!open}>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={valueCount}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </OutsideClickHandler>
            )}
            {filterPortalId && (
                <>
                    <Heading
                        filter={filter}
                        onClick={handleHeadingClick}
                        active={open}
                        count={valueCount}
                        subTitle={headingSubTitle}
                    />
                    {Component}
                </>
            )}
        </div>
    )
}

export default memo(TypeOptions)
