/**
 *
 * QuantitySelector
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useRef } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { ICartItem } from '../../services/api/service/carts/types'
import {
    CartItemImageClickCallback,
    CartItemQuantityChangeCallback,
    CartItemQuantityMultipleAskedCallback,
    CartItemRestockAlertSubscriptionChangeCallback,
} from '../../types/cartCallback'
import FlatIcon from '../Icon/FlatIcon'
import Price from '../Price/Price'
import {
    Heading as ProductHeading,
    MainPicture as ProductMainPicture,
    Packing as ProductPacking,
} from '../Product/Partial'
import CustomerStock from '../Product/Partial/CustomerInfo/CustomerStock'
import QuantitySelector, { QuantityAlertDisplayMode } from '../QuantitySelector/QuantitySelector'
import { useCustomer } from '../../utils/hook/useCustomer'
import EcoTax from '../Product/Partial/EcoTax'

type IProps = {
    item: ICartItem
    itemParent?: ICartItem
    isMainCart: boolean
    quantityValue: number
    quantitySaving: boolean
    quantityLocked: boolean
    quantityDisabled: boolean
    quantityMultiple: boolean
    showPrices: boolean
    showProductCustomerInfo: boolean
    className?: string
    classNameSection?: string
    onImageClick?: CartItemImageClickCallback
    onQuantityChange?: CartItemQuantityChangeCallback
    onQuantityMultipleAsked?: CartItemQuantityMultipleAskedCallback
    onRestockAlertSubscriptionChange?: CartItemRestockAlertSubscriptionChangeCallback
}

function CartItem({
    item,
    itemParent,
    isMainCart,
    quantityValue,
    quantitySaving,
    quantityDisabled,
    quantityMultiple,
    quantityLocked,
    className,
    classNameSection,
    onQuantityChange,
    onQuantityMultipleAsked,
    onImageClick,
    showPrices,
    showProductCustomerInfo,
    onRestockAlertSubscriptionChange,
}: IProps): JSX.Element {
    const { product, errors } = item
    const customer = useCustomer()
    const quantityContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const handleOnQuantityChange = (quantity: number, oldQuantity: number) => {
        if (onQuantityChange) {
            onQuantityChange(item, quantity, oldQuantity)
        }
    }

    const handleImageClick = useCallback(() => onImageClick?.(item), [onImageClick, item])

    const handleOnRestockAlertSubscriptionChange = useCallback(
        (enabled) => {
            if (onRestockAlertSubscriptionChange) {
                onRestockAlertSubscriptionChange(item, enabled)
            }
        },
        [onRestockAlertSubscriptionChange, item]
    )

    const handleOnQuantitySelectorClick = useCallback(
        (enabled: boolean, saving: boolean, multiple: boolean) => {
            if (multiple && quantityMultiple) {
                if (onQuantityMultipleAsked) {
                    onQuantityMultipleAsked(item, saving)
                }
            }
        },
        [item, onQuantityMultipleAsked, quantityMultiple]
    )

    const PopoverItemParentErrors = useMemo(() => {
        if (!itemParent || !itemParent.errors || itemParent.errors.length === 0) {
            return undefined
        }

        return (
            <Popover
                id={`popover-cart-item-parent-errors-${itemParent['@id']}`}
                className={'cart-item-parent-errors-popover'}
            >
                <Popover.Content>
                    {itemParent?.errors?.map((val) => (
                        <p className={'invalid-feedback'} key={val}>
                            {val}
                        </p>
                    ))}
                </Popover.Content>
            </Popover>
        )
    }, [itemParent])

    return (
        <div className={classNames('cart-item', { 'has-errors': errors && errors.length > 0 }, className)}>
            <div className={classNames(classNameSection, 'image')}>
                <div className={'product-card'}>
                    <ProductMainPicture product={product} onImageClick={handleImageClick} />
                </div>
            </div>
            <div className={classNames(classNameSection, 'description')}>
                <ProductHeading
                    product={product}
                    errors={errors || itemParent?.errors || undefined}
                    showEan
                    showReference
                    showReferenceLabel
                    showEanLabel
                    productNameAs={'p'}
                    showPacking
                    showPackingLabel
                />
            </div>
            <div className={classNames(classNameSection, 'quantity', 'store-quantity')} ref={quantityContainerRef}>
                <QuantitySelector
                    packing={product.packing}
                    currentValue={quantityValue}
                    saving={quantitySaving}
                    onQuantityChange={handleOnQuantityChange}
                    onSelectorClick={handleOnQuantitySelectorClick}
                    canIncrement={item.can_increment_quantity}
                    outOfStock={item.product.out_of_stock}
                    disabled={quantityDisabled}
                    multiple={quantityMultiple}
                    multipleButtonLabel={'default.edit'}
                    arrivalStock={item.product.arrival_stock}
                    remainingStock={item.product.stock}
                    restockAlertEnabled={item.product.can_add_stock_alert}
                    restockAlertSubscribed={item.product.has_stock_alert}
                    quantityAlertDisplayMode={QuantityAlertDisplayMode.Tooltip}
                    onRestockAlertSubscriptionChange={handleOnRestockAlertSubscriptionChange}
                    popoverContainerRef={quantityContainerRef}
                    errors={item.errors}
                    uniqId={product.id}
                    locked={quantityLocked}
                    showPrices={showPrices}
                />
                {showProductCustomerInfo && (
                    <CustomerStock
                        customerStock={item.product.customer_stock}
                        customerStockDate={item.product.customer_stock_date}
                        labelId={!isMainCart ? 'imports.imported_stock' : 'customer.your_stock'}
                    />
                )}
            </div>
            {!isMainCart && (
                <div
                    className={classNames(classNameSection, 'quantity', 'item-quantity', {
                        'has-errors': itemParent?.errors && itemParent?.errors.length > 0,
                    })}
                >
                    {PopoverItemParentErrors && (
                        <OverlayTrigger
                            placement={'auto'}
                            trigger={['hover', 'focus']}
                            overlay={PopoverItemParentErrors}
                        >
                            <span className={'item-quantity'}>
                                <FlatIcon icon={'warning'} className={'warning-icon'} />
                                {itemParent?.quantity}
                            </span>
                        </OverlayTrigger>
                    )}
                    {!PopoverItemParentErrors && <>{itemParent?.quantity}</>}
                </div>
            )}
            <div className={'cart-item-prices'}>
                <ProductPacking packing={item.package_count} showLabel={false} showIcon />
                <div className="cart-item-product-prices">
                    <div className="product-prices">
                        <Price price={item.unit_price} className={'unit-price'} />
                        {item.unit_strike_price && (
                            <Price price={item.unit_strike_price} className={'unit-strike-price'} />
                        )}
                    </div>
                </div>
                <Price price={item.total} className={'total-price'} />
            </div>
            {!customer?.order_by_unit && (
                <div className={classNames(classNameSection, 'packing')}>
                    <ProductPacking packing={item.package_count} showLabel={false} showIcon />
                </div>
            )}
            {showPrices && (
                <>
                    <div className={classNames(classNameSection, 'price', 'unit-price')}>
                        <div className="product-prices">
                            {item.unit_strike_price && (
                                <Price price={item.unit_strike_price} className={'unit-strike-price'} />
                            )}
                            <Price price={item.unit_price} className={'unit-price'} />
                        </div>
                        <EcoTax ecoTax={item.product.eco_tax || 0} labelMessageKey="price.eco_tax_abbr" as={'div'} />
                    </div>
                    <div className={classNames(classNameSection, 'price', 'total-price')}>
                        <Price price={item.total} />
                        <EcoTax
                            ecoTax={item.total_eco_tax || 0}
                            labelMessageKey="price.eco_tax_abbr"
                            as={'div'}
                            className="total-eco-tax"
                        />
                    </div>
                </>
            )}
        </div>
    )
}

CartItem.defaultProps = {
    quantityValue: 0,
    quantityLocked: false,
    quantitySaving: false,
    quantityDisabled: false,
    quantityMultiple: false,
    showProductCustomerInfo: true,
    showPrices: true,
    isMainCart: true,
} as Partial<IProps>

export default memo(CartItem)
