import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import Config from '../../config'
import { matchPath } from 'react-router-dom'
import { initialState } from '../classification/reducer'
import { IntlState } from 'react-intl-redux'

/**
 * Direct selector to the languageToggle state domain
 */
const selectLocale = (state: IApplicationRootState) => state.intl.locale || {}
const selectLocalizedMessages = (state: IApplicationRootState) => state.intl.messages || {}

/**
 * Select the language locale
 */

const selectIntlDomain = (state: IApplicationRootState): IntlState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.intl || initialState
}

const makeSelectLocale = () => createSelector(selectIntlDomain, (languageState) => languageState.locale)
const makeSelectMessages = () => createSelector(selectLocalizedMessages, (messages) => messages)

const selectAutoDetectLocale = () => {
    const locales = Config.I18N.LANGUAGES.join('|')
    const match = matchPath<{ lang?: string }>(window.location.pathname, {
        path: `/:lang(${locales})*`,
    })

    let locale: string | null = null
    // on check si on a une locale dans l'url, sinon, on récupère celle sauvegardée si dispo
    if (match && match.params.lang && Config.I18N.LANGUAGES.includes(match.params.lang)) {
        locale = match.params.lang
    }

    if (!locale) {
        const navigatorLocale: string | null = navigator ? navigator.language.split('-')[0] : null
        if (Config.I18N.LANGUAGES.includes(navigatorLocale)) {
            locale = navigatorLocale
        }
    }

    if (!locale) {
        locale = Config.I18N.FALLBACK_LOCALE
    }

    return locale
}

export {
    selectIntlDomain,
    selectLocale,
    makeSelectLocale,
    selectAutoDetectLocale,
    makeSelectMessages,
    selectLocalizedMessages,
}
