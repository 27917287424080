/**
 *
 * CmsPage
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import NotFound from '../../containers/NotFound/NotFound'
import { ICmsPage } from '../../services/api/service/cms/types'
import { IAppErrorTypes, IHttpError } from '../../store/app/types'
import HttpStatusCode from '../../store/http/codes'
import { CmsWidgetCallbackList, ICmsWidgetLink } from '../../types/widget'
import redirectCmsWidgetLink from '../../utils/widget'
import CmsWidgetList from '../CmsWidget/CmsWidgetList'
import PageLoader from '../Loader/PageLoader'

type IProps = CmsWidgetCallbackList & {
    item: ICmsPage
    fetching: boolean
    preview: boolean
    error?: IAppErrorTypes
    className?: string
}

function CmsPage({ item, fetching, error, className, preview, ...callbacks }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const history = useHistory()
    const location = useLocation()
    const isPageNotFound = error && error.isHttpError && (error as IHttpError).status === HttpStatusCode.NOT_FOUND
    const seoPageTitle = fetching
        ? formatMessage({ id: 'default.loading' })
        : error
        ? isPageNotFound
            ? formatMessage({ id: 'cms.page.error' })
            : formatMessage({ id: 'default.error' })
        : item
        ? item.meta_title
        : formatMessage({ id: 'default.loading' })

    const handleCmsWidgetLinkClick = useCallback(
        (link: ICmsWidgetLink) => {
            redirectCmsWidgetLink(link, history, location)
        },
        [history, location]
    )

    return (
        <>
            <Helmet>
                <title>{seoPageTitle}</title>
                <meta name="description" content={item ? item.meta_description || '' : ''} />
            </Helmet>
            <div
                className={classNames(
                    'cms-page',
                    { 'cms-page-loading': fetching },
                    { 'cms-page-loaded': fetching },
                    `cms-page-${item ? item.code : 'load'}`,
                    className
                )}
            >
                {item && preview && (
                    <Container fluid>
                        <Row>
                            <Col>
                                <Alert variant={'warning'}>
                                    <h3>
                                        <FormattedMessage id={'default.warning'} />
                                    </h3>
                                    <p>
                                        <strong>
                                            <FormattedMessage id={'cms.page.preview'} />
                                        </strong>
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                )}
                {fetching && <PageLoader />}
                {!isPageNotFound && error && <Alert variant={'danger'}>{error!.message}</Alert>}
                {isPageNotFound && <NotFound />}
                {item && item.head_title && <h1 className={'hv'}>{item.head_title}</h1>}
                {item && (
                    <div className={'cms-page-content'}>
                        <CmsWidgetList
                            widgets={item.parts}
                            onCmsWidgetLinkClick={handleCmsWidgetLinkClick}
                            {...callbacks}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

CmsPage.defaultProps = {
    fetching: true,
    preview: false,
} as Partial<IProps>

export default memo(CmsPage)
