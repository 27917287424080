/**
 *
 * OrderDetail
 *
 */
import React, { memo, MouseEvent, useCallback } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { ProductOrderInProgressCollection } from '../../../../services/api/service/orders/types'
import { Table } from 'react-bootstrap'

type IProps = {
    orders: ProductOrderInProgressCollection
    onClick?: (e: MouseEvent, orderId?: string) => void
}

function InProgressOrders({ orders, onClick }: IProps): JSX.Element {
    const handleClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            if (onClick) {
                onClick(e, e.currentTarget.dataset['id'])
            }
        },
        [onClick]
    )

    return (
        <div className="orders-in-progress">
            <h5 className="title">
                <FormattedMessage id="default.orders_in_progress" />
            </h5>
            <Table size="sm">
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="orders.number" />
                        </th>
                        <th>
                            <FormattedMessage id="cart.quantity" />
                        </th>
                        <th>
                            <FormattedMessage id="orders.date" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order.id} data-id={order.id} onClick={handleClick}>
                            <td>{order.number}</td>
                            <td>{order.quantity}</td>
                            <td>
                                <FormattedDate value={order.date} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default memo(InProgressOrders)
