import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import CustomerDashboardPage from '../../pages/CustomerDashboardPage'
import CustomerExportsPage from '../../pages/CustomerExportsPage'
import CustomerImportPage from '../../pages/CustomerImportPage'
import CustomerImportsPage from '../../pages/CustomerImportsPage'
import CustomerInvoicesPage from '../../pages/CustomerInvoicesPage'
import CustomerLoginPage from '../../pages/CustomerLoginPage'
import CustomerOrderPage from '../../pages/CustomerOrderPage'
import CustomerOrdersPage from '../../pages/CustomerOrdersPage'
import CustomerSalesmenPage from '../../pages/CustomerSalesmenPage'
import CustomerStoresPage from '../../pages/CustomerStoresPage'
import CustomerLayout from '../../pages/Layout/CustomerLayout'
import { cartsBannerHideAction } from '../../store/carts/actions'
import LoggedInRoute from '../LoggedInRoute'
import { getRouteMatch } from '../utils'
import {
    customerDashboardPageVoter,
    customerExportsPageVoter,
    customerImportsPageVoter,
    customerInvoicesPageVoter,
    customerCartsPageVoter,
    customerOrdersPageVoter,
    customerPictureExportPageVoter,
    customerSalesmenPageVoter,
    customerStoresPageVoter,
} from '../voters'
import CustomerPictureExportsPage from '../../pages/CustomerPictureExportsPage'
import { IApplicationRootState } from '../../store'
import { IMe } from '../../services/api/service/me/types'
import { createStructuredSelector } from 'reselect'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { IOrderType } from '../../services/api/service/orders/types'
import CustomerCartsPage from '../../pages/CustomerCartsPage'
import CustomerHomePage from '../../pages/CustomerHomePage'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

function CustomerArea(): JSX.Element {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cartsBannerHideAction())
    })

    // récupération des informations de la personne connectée
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    const dashboardUrl = useMemo(() => {}, [me])

    return (
        <Switch>
            <CustomerLayout>
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerOrders')}
                    key={'customerOrders'}
                    component={CustomerOrdersPage}
                    componentProps={{ type: IOrderType.Order }}
                    voter={customerOrdersPageVoter}
                />
                {isSalesman && (
                    <LoggedInRoute
                        exact
                        path={getRouteMatch('customerAssets')}
                        key={'customerAssets'}
                        component={CustomerOrdersPage}
                        componentProps={{ type: IOrderType.Asset }}
                        voter={customerOrdersPageVoter}
                    />
                )}
                <LoggedInRoute
                    path={getRouteMatch('customerCarts')}
                    key={'customerCarts'}
                    component={CustomerCartsPage}
                    voter={customerCartsPageVoter}
                />
                <LoggedInRoute
                    path={getRouteMatch('customerOrder')}
                    key={'customerOrder'}
                    component={CustomerOrderPage}
                    voter={customerOrdersPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerInvoices')}
                    key={'customerInvoices'}
                    component={CustomerInvoicesPage}
                    voter={customerInvoicesPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerModifyLogin')}
                    key={'customerModifyLogin'}
                    component={CustomerLoginPage}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerExports')}
                    key={'customerExports'}
                    component={CustomerExportsPage}
                    voter={customerExportsPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerImports')}
                    key={'customerImports'}
                    component={CustomerImportsPage}
                    voter={customerImportsPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerImport')}
                    key={'customerImport'}
                    component={CustomerImportPage}
                    voter={customerImportsPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerStores')}
                    key={'customerStores'}
                    component={CustomerStoresPage}
                    voter={customerStoresPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerSalesmen')}
                    key={'customerSalesmen'}
                    component={CustomerSalesmenPage}
                    voter={customerSalesmenPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerPictureExports')}
                    key={'customerPictureExports'}
                    component={CustomerPictureExportsPage}
                    voter={customerPictureExportPageVoter}
                />
                <LoggedInRoute
                    path={getRouteMatch('customerDashboard')}
                    key={'customerDashboard'}
                    component={CustomerDashboardPage}
                    voter={customerDashboardPageVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('customerArea')}
                    key={'customerArea'}
                    component={CustomerHomePage}
                />
            </CustomerLayout>
        </Switch>
    )
}

export default withRouter(CustomerArea)
