/**
 *
 * RemotePdf
 *
 */
import classNames from 'classnames'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import Iframe from 'react-iframe'
import { useIntl } from 'react-intl'
import { IApiFileLink } from '../../services/api/types'
import { IAppErrorTypes } from '../../store/app/types'
import useWindowSize from '../../utils/hook/useWindowSize'
import PageInnerLoader from '../Loader/PageLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
    fetching: boolean
    disabled: boolean
    error: IAppErrorTypes
    title?: string
    pdf?: IApiFileLink
    breakpoint: number
    onExit?: () => void
}

function RemotePdf({ fetching, error, title, pdf, breakpoint, onExit }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const size = useWindowSize()
    const isMobile = typeof size !== 'undefined' && typeof size.width !== 'undefined' && size.width < breakpoint
    const [pdfShow, setPdfShow] = useState<boolean>(!isMobile)
    const [pdfWillShow, setPdfWillShow] = useState<boolean>(true)
    const [pdfErrorShown, setPdfErrorShown] = useState<boolean>(false)
    const [iframeLoaded, setIFrameLoaded] = useState<boolean>(false)
    const pdfDownloadUrl = useMemo(() => {
        if (fetching || !pdf || !pdf.url) {
            return undefined
        }

        try {
            const pdfUrl = new URL(pdf.url)
            pdfUrl.searchParams.append('download', '1')
            return pdfUrl.toString()
        } catch (e) {
            console.error(e)
        }
        return pdf.url
    }, [pdf, fetching])

    const handleOnPdfModalExited = () => {
        setPdfShow(false)
        setTimeout(() => {
            if (onExit) {
                onExit()
            }
        }, 350)
    }

    useEffect(() => {
        if (fetching) {
            if (!isMobile) {
                setPdfShow(true)
            }
            setPdfWillShow(true)
            setIFrameLoaded(false)
        }
    }, [fetching, size, breakpoint])

    useEffect(() => {
        if (error) {
            setPdfShow(false)
            setPdfWillShow(false)
            setIFrameLoaded(true)
        }
    }, [error])

    useEffect(() => {
        if (pdfDownloadUrl && pdfWillShow) {
            if (isMobile) {
                window.location.href = pdfDownloadUrl
            }
            setPdfWillShow(false)
        }
    }, [pdfDownloadUrl, pdfWillShow, size, breakpoint, isMobile])

    return (
        <>
            <Modal
                className={'remote-pdf-modal'}
                show={pdfShow}
                size={'xl'}
                // onExited={handleOnPdfModalExited}
                onHide={handleOnPdfModalExited}
            >
                <Modal.Header closeButton className="align-items-center">
                    {title ? <Modal.Title>{title}</Modal.Title> : undefined}
                    {pdfDownloadUrl ? (
                        <>
                            <a
                                href={pdfDownloadUrl}
                                download
                                className={classNames('btn', 'btn-primary', 'btn-sm', 'ml-2')}
                            >
                                <FontAwesomeIcon icon="download" />{' '}
                                <span className="lbl">{formatMessage({ id: 'default.download' })}</span>
                            </a>
                        </>
                    ) : undefined}
                </Modal.Header>
                <Modal.Body>
                    {(fetching || !iframeLoaded) && <PageInnerLoader />}
                    {pdf && (
                        <div className={'embed-responsive embed-responsive-1by1'}>
                            <Iframe
                                url={pdf.url}
                                className={classNames('embed-responsive-item', { 'd-none': !iframeLoaded })}
                                onLoad={() => setIFrameLoaded(true)}
                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {error && (
                <SweetAlert
                    error
                    title={formatMessage({
                        id: 'default.error',
                    })}
                    show={error!.message !== null && !pdfErrorShown}
                    onConfirm={() => {
                        setPdfErrorShown(true)
                    }}
                >
                    {error.message}
                </SweetAlert>
            )}
        </>
    )
}

RemotePdf.defaultProps = {
    breakpoint: 1024,
} as Partial<IProps>

export default memo(RemotePdf)
