/*
 *
 * Products actions
 *
 */

import { action } from 'typesafe-actions'

import {
    IApiExportProductCheckStateFailurePayload,
    IApiExportProductCheckStateProcessPayload,
    IApiExportProductCheckStateSuccessPayload,
    IApiExportProductCreateFailurePayload,
    IApiExportProductCreateProcessPayload,
    IApiExportProductCreateResetProcessPayload,
    IApiExportProductCreateSuccessPayload,
    IApiExportProductListProcessPayload,
    IApiExportProductResourceTypes,
} from './types'

import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import {
    ExportProductResourceType,
    ExportProductType,
    IApiExportProductCreateParameters,
    IApiExportProductListPagination,
} from '../../services/api/service/exports/types'
import { IApiOrderExportCreateResponse } from '../../services/api/service/orderExports/types'
import { IApiCartExportCreateResponse } from '../../services/api/service/cartExports/types'

// GLOBAL
export const exportsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const exportProductsListProcessAction = (page: number, itemsPerPage?: number, search?: string) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IApiExportProductListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        page,
        itemsPerPage,
        search,
    })

export const exportProductsListSuccessAction = (response: IApiExportProductListPagination) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IApiExportProductListPagination>(ActionTypes.LIST_SUCCESS_ACTION, response)
export const exportProductsListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const exportProductsListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)

export const exportProductCheckStateProcessAction = (item: IApiExportProductResourceTypes) =>
    action<ActionTypes.CHECK_STATE_PROCESS_ACTION, IApiExportProductCheckStateProcessPayload>(
        ActionTypes.CHECK_STATE_PROCESS_ACTION,
        {
            item,
        }
    )
export const exportProductCheckStateSuccessAction = (response: IApiExportProductCheckStateSuccessPayload) =>
    action<ActionTypes.CHECK_STATE_SUCCESS_ACTION, IApiExportProductCheckStateSuccessPayload>(
        ActionTypes.CHECK_STATE_SUCCESS_ACTION,
        response
    )
export const exportProductCheckStateFailureAction = (id: string, type: ExportProductType, error: IAppErrorTypes) =>
    action<ActionTypes.CHECK_STATE_FAILURE_ACTION, IApiExportProductCheckStateFailurePayload>(
        ActionTypes.CHECK_STATE_FAILURE_ACTION,
        { id, type, error }
    )
export const exportProductCheckStateReset = () =>
    action<ActionTypes.CHECK_STATE_RESET_ACTION>(ActionTypes.CHECK_STATE_RESET_ACTION)

export const exportProductCreateProcessAction = (params: IApiExportProductCreateParameters) =>
    action<ActionTypes.CREATE_PROCESS_ACTION, IApiExportProductCreateProcessPayload>(
        ActionTypes.CREATE_PROCESS_ACTION,
        {
            ...params,
        }
    )

export const exportProductCreateSuccessAction = (
    resourceType: ExportProductResourceType,
    resourceId: string,
    response: IApiCartExportCreateResponse | IApiOrderExportCreateResponse
) =>
    action<ActionTypes.CREATE_SUCCESS_ACTION, IApiExportProductCreateSuccessPayload>(
        ActionTypes.CREATE_SUCCESS_ACTION,
        {
            resourceId,
            resourceType,
            item: response.data,
        }
    )
export const exportProductCreateFailureAction = (
    resourceType: ExportProductResourceType,
    resourceId: string,
    error: IAppErrorTypes
) =>
    action<ActionTypes.CREATE_FAILURE_ACTION, IApiExportProductCreateFailurePayload>(
        ActionTypes.CREATE_FAILURE_ACTION,
        { resourceId, resourceType, error }
    )

export const exportProductCreateResetAction = () =>
    action<ActionTypes.CREATE_RESET_ACTION>(ActionTypes.CREATE_RESET_ACTION)

export const exportProductCreateResetSingleAction = (resourceType: ExportProductResourceType, resourceId: string) =>
    action<ActionTypes.CREATE_RESET_SINGLE_ACTION, IApiExportProductCreateResetProcessPayload>(
        ActionTypes.CREATE_RESET_SINGLE_ACTION,
        {
            resourceType,
            resourceId,
        }
    )
