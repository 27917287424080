/**
 *
 * FamilyTreeItem
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { LazyImage } from 'react-lazy-images'
// eslint-disable-next-line no-unused-vars
import { useLocation } from 'react-router-dom'
import CmsBlock from '../../../containers/CmsBlock/CmsBlock'
import { IDepartment, IFamilyTreeCollection, IMenuItem } from '../../../services/api/service/classification/types'
import { CmsBlockCodeList } from '../../../services/api/service/cms/types'
import FamilyTreeContext from '../FamilyTreeContext'
import { LinkItemCallbacks } from '../type'
import LinkItem from './LinkItem'
import ShippingLocationItem from './ShippingLocationItem'

type IProps = LinkItemCallbacks & {
    item: IMenuItem
    familyTree: IFamilyTreeCollection
    departmentsOnly: boolean
    basePath?: string
}

function FamilyTreeItem({
    basePath,
    item,
    familyTree,
    departmentsOnly,
    onMouseEnter,
    onMouseLeave,
}: IProps): JSX.Element {
    const [active, setActive] = useState<boolean>(false)
    const [entered, setEntered] = useState<boolean>(false)
    const [department, setDepartment] = useState<IDepartment | undefined>(undefined)
    const location = useLocation()

    const handleMouseClick = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            if (onMouseLeave) {
                onMouseLeave(e, item, itemType)
            }
        },
        [setEntered, onMouseLeave]
    )

    const handleCmsWidgetClick = useCallback(() => {
        setEntered(false)
    }, [setEntered])

    const handleMouseEnter = useCallback(() => {
        setEntered(true)
    }, [setEntered])

    const handleMouseLeave = useCallback(() => {
        setEntered(false)
    }, [setEntered])

    useEffect(() => {
        setActive(location.pathname.indexOf(item.url) > -1)
    }, [location.pathname, item])

    return (
        <div
            className={classNames('megamenu', 'nav-item', `megamenu-${item.type}`, {
                'has-children': true,
                active,
                open: entered,
            })}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            <div className={'megamenu-heading'}>
                <LinkItem
                    item={item}
                    className={'megamenu-nav-item'}
                    onClick={handleMouseClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            </div>
            <div className={'megamenu-content'}>
                <div className={'megamenu-container'}>
                    <FamilyTreeContext.Provider value={{ department, setDepartment }}>
                        <Container>
                            <CmsBlock
                                identifier={CmsBlockCodeList.MenuHighlight}
                                onCmsWidgetLinkClick={handleCmsWidgetClick}
                                lazy
                            >
                                {(widgetComponent, widget) => {
                                    return (
                                        <Row>
                                            <Col xs={24} lg={widget ? 19 : 24}>
                                                <div className={'megamenu-content-list'}>
                                                    {familyTree?.map((familyTreeItem) => {
                                                        return (
                                                            <ShippingLocationItem
                                                                basePath={basePath}
                                                                onClick={handleMouseClick}
                                                                onMouseEnter={onMouseEnter}
                                                                onMouseLeave={onMouseLeave}
                                                                selectedDepartmentSetter={setDepartment}
                                                                departmentsOnly={departmentsOnly}
                                                                key={`family_tree_${item.type}_${
                                                                    item['@id']
                                                                }_shipping_location_${
                                                                    familyTreeItem['@id'] || familyTreeItem.id
                                                                }`}
                                                                item={familyTreeItem}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                            {widget && (
                                                <Col xs={24} lg={5} className={'col-megamenu-preview'}>
                                                    <FamilyTreeContext.Consumer>
                                                        {(value) => (
                                                            <div
                                                                className={classNames('megamenu-content-preview', {
                                                                    'has-department':
                                                                        value.department &&
                                                                        value.department.inspiration_image,
                                                                })}
                                                            >
                                                                {value.department &&
                                                                    value.department.inspiration_image && (
                                                                        <div className={'department-preview'}>
                                                                            <div className={'department-preview-inner'}>
                                                                                <LazyImage
                                                                                    debounceDurationMs={200}
                                                                                    src={
                                                                                        value.department
                                                                                            .inspiration_image!.normal
                                                                                    }
                                                                                    alt={value.department.label}
                                                                                    placeholder={({
                                                                                        imageProps,
                                                                                        ref,
                                                                                    }) => (
                                                                                        <img
                                                                                            {...imageProps}
                                                                                            className={'placeholder'}
                                                                                            ref={ref}
                                                                                            alt={
                                                                                                value.department!.label
                                                                                            }
                                                                                            src={
                                                                                                value.department!
                                                                                                    .inspiration_image!
                                                                                                    .placeholder
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    actual={({ imageProps }) => (
                                                                                        <img
                                                                                            {...imageProps}
                                                                                            alt={
                                                                                                value.department!.label
                                                                                            }
                                                                                            className={'picture'}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {widgetComponent}
                                                            </div>
                                                        )}
                                                    </FamilyTreeContext.Consumer>
                                                </Col>
                                            )}
                                        </Row>
                                    )
                                }}
                            </CmsBlock>
                        </Container>
                    </FamilyTreeContext.Provider>
                </div>
            </div>
        </div>
    )
}

FamilyTreeItem.defaultProps = {
    departmentsOnly: true,
    featuredDepartments: [],
    basePath: '',
} as Partial<IProps>

export default memo(FamilyTreeItem)
