/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { ProductBadgeType, ShowBadgeType } from '../../../types/productBadge'
import { BadgeList } from '../Partial'
import { default as ProductImage } from '../Partial/MainPicture'

interface IProps {
    product: IProductCatalog
    className?: string
    loadEagerly?: boolean
    showPrices?: boolean
    showBadges?: ShowBadgeType
    excludeBadges?: Array<ProductBadgeType>
    overlay?: ReactNode
    children?: ReactNode
    onImageClick?: (product: IProductCatalog) => void
}

// eslint-disable-next-line react/display-name
const Card = React.forwardRef<HTMLDivElement, IProps>(
    (
        {
            product,
            className,
            loadEagerly = false,
            overlay = false,
            children,
            showBadges = true,
            excludeBadges,
            onImageClick,
            showPrices = true,
        },
        forwardedRef
    ) => {
        return (
            <div
                ref={forwardedRef}
                className={classNames('product', 'product-card', { 'has-overlay': overlay }, className)}
            >
                <div className={'product-head'}>
                    <ProductImage product={product} loadEagerly={loadEagerly} onImageClick={onImageClick}>
                        {showBadges && <BadgeList product={product} exclude={excludeBadges} />}
                        {overlay}
                    </ProductImage>
                </div>
                {children}
            </div>
        )
    }
)

Card.defaultProps = {
    loadEagerly: false,
    overlay: false,
    showBadges: true,
    showPrices: true,
} as Partial<IProps>

export default Card
