import * as Sentry from '@sentry/browser'
import classNames from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import Gears from '../components/Gears/Gears'
import { IAppErrorTypes } from '../store/app/types'
import isString from 'lodash/isString'

interface IProps {
    error?: IAppErrorTypes
}

function ErrorPage({ error }: IProps): JSX.Element {
    const { formatMessage, messages } = useIntl()
    const [loading, setLoading] = useState<boolean>(true)
    const { errorCode } = useParams<{ errorCode: string }>()
    let code = typeof errorCode !== 'undefined' ? parseInt(errorCode) : -1

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    useEffect(() => {
        if (error) {
            Sentry.captureException(error)
        }
    }, [error])
    // récupération du bon message
    let message = formatMessage({ id: 'seo.fatal.unexpected' })

    if (error) {
        message = error.message
        code =
            'status' in error && error.status
                ? isString(error.status)
                    ? parseInt(error.status!)
                    : error.status
                : 'code' in error && error.code
                ? parseInt(error.code!)
                : code
    }

    if (code && !error && messages[`default.http.code.${code}`]) {
        message = formatMessage({ id: `default.http.code.${code}` })
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.fatal.title' })}</title>
            </Helmet>
            <div className={classNames('error-page', 'error-section', { loading })} id={'error-page-container'}>
                <Container>
                    <h1>{code}</h1>
                    <h2>
                        {message} <b>:(</b>
                    </h2>
                    <Gears />
                </Container>
            </div>
        </>
    )
}

ErrorPage.defaultProps = {} as Partial<IProps>

export default memo(ErrorPage)
