/*
 *
 * CartsBanner
 *
 */

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectBannerVisibility,
    makeSelectCartMode,
    makeSelectCartValidationMode,
    makeSelectMainCartsList,
} from '../../store/carts/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import { ICart, ICartCollection, ICartValidationMode } from '../../services/api/service/carts/types'
import { makeSelectClassificationFamilyTreeDefault } from '../../store/classification/selectors'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import { generatePath, useHistory } from 'react-router-dom'
import { FlashMessage } from 'redux-flash-messages/lib'

import { getPath } from '../../routes'

import { ICustomer } from '../../services/api/service/customers/types'

import { makeSelectApocalypseFlashMessages } from '../../store/app/selectors'
import { cartsBannerHideAction } from '../../store/carts/actions'

import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import Item from './Item'
import { StrictCartMode } from '../../store/carts/types'

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    cartMode: makeSelectCartMode(),
    cartValidationMode: makeSelectCartValidationMode(),
    tree: makeSelectClassificationFamilyTreeDefault(),
    show: makeSelectBannerVisibility(),
    flashMessages: makeSelectApocalypseFlashMessages(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
})

interface IProps {
    onHeightChange?: (height: number) => void
}

function CartsBanner({ onHeightChange }: IProps): JSX.Element {
    const { carts, show, flashMessages, customer, store, cartMode, cartValidationMode } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            show: boolean
            flashMessages: Array<FlashMessage>
            customer: ICustomer
            store?: ICustomer
            cartMode: StrictCartMode
            cartValidationMode: ICartValidationMode
        }
    >(stateSelector)
    const cartsBannerRef = useRef<HTMLDivElement | null>(null)
    const [hideOnScroll, setHideOnScroll] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const { locale } = useIntl()

    useEffect(() => {
        if (show && flashMessages.length > 0) {
            dispatch(cartsBannerHideAction())
        }
    }, [flashMessages, show, dispatch])

    useEffect(() => {
        if (show) {
            document.documentElement.classList.add('cart-list-banner-show')
        } else {
            document.documentElement.classList.remove('cart-list-banner-show')
        }

        return () => {
            document.documentElement.classList.remove('cart-list-banner-show')
        }
    }, [show])

    useEffect(() => {
        const isVisible = show && !hideOnScroll
        if (onHeightChange) {
            if (isVisible) {
                onHeightChange(cartsBannerRef?.current?.getBoundingClientRect().height || 0)
            } else {
                onHeightChange(0)
            }
        }
    }, [onHeightChange, cartsBannerRef, show, hideOnScroll])

    useScrollPosition(
        ({ currPos }) => {
            const isMobile = 'matchMedia' in window && window.matchMedia('(max-width:767px)').matches
            const value = isMobile && currPos.y > window.innerHeight / 2
            setHideOnScroll(value)
        },
        [setHideOnScroll],
        undefined,
        true,
        500
    )

    const cartUrl = generatePath(getPath('cart', locale), { lang: locale })
    const handleOnCartBannerClick = useCallback(
        (cart: ICart) => {
            const idx = carts.findIndex((obj) => obj['@id'] === cart['@id'])
            history.push(`${cartUrl}#${idx}`)
        },
        [carts, cartUrl, history]
    )

    if (!carts || !customer) {
        return <></>
    }

    return (
        <div
            ref={cartsBannerRef}
            className={classNames('cart-list-banner', {
                visible: show && !hideOnScroll,
                odd: carts.length % 2 === 0,
                even: carts.length % 2 !== 0,
            })}
        >
            <Row noGutters>
                {carts.map((cart: ICart) => {
                    return (
                        <Col key={`col_${cart['@id']}`}>
                            <Item
                                cart={cart}
                                cartMode={cartMode}
                                cartValidationMode={cartValidationMode}
                                customer={customer}
                                store={store}
                                minimumAmountMode={customer.minimum_amount_mode}
                                key={cart['@id']}
                                onCartBannerClick={handleOnCartBannerClick}
                            />
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

export default memo(CartsBanner)
