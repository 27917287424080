/*
 *
 * SalesmanCustomers
 *
 */

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
    IApiProspectPriceListCollection,
    ISalesmanProspect,
    ISalesmanProspectList,
} from '../../services/api/service/prospects/types'
import Table from '../../components/Table/Table'
import { Column, Filters, SortingRule, UseSortByColumnOptions } from 'react-table'
import client from '../../services/api/client'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '../../components/Buttons/Button'
import FlatIcon from '../../components/Icon/FlatIcon'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'
import EditFormModal from './Form/Loadable'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProspectsError,
    makeSelectProspectsFetching,
    makeSelectProspectsItems,
    makeSelectProspectsTableState,
    makeSelectProspectsTotalItems,
} from '../../store/salesmens/selectors'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { salesmenProspectsProcessAction, salesmenProspectsRefreshAction } from '../../store/salesmens/actions'
import { $enum } from 'ts-enum-util'
import { IOrderMode } from '../../services/api/service/orders/types'
import { ordersSwitchOrderModeAction } from '../../store/orders/actions'
import { customerSetCurrent } from '../../store/customers/actions'
import Portal from '../../components/Portal/Portal'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { canViewAllCustomers } from '../../store/salesmens/utils'
import BooleanFilter from '../CustomerCarts/Partial/Filter/BooleanFilter'
import { generatePath, Link } from 'react-router-dom'
import { getPath } from '../../routes'
import Qs from 'qs'

type SalesmanCustomersItemType = ISalesmanProspectList

const stateSelector = createStructuredSelector<any, any>({
    items: makeSelectProspectsItems(),
    totalItems: makeSelectProspectsTotalItems(),
    fetching: makeSelectProspectsFetching(),
    error: makeSelectProspectsError(),
    tableState: makeSelectProspectsTableState(),
    me: makeSelectAuthMe(),
})

function SalesmanProspects(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const [showFormModal, setShowFormModal] = useState<boolean>(false)
    const [showChoiceOrderModeModal, setShowChoiceOrderModeModal] = useState<boolean>(false)
    const [prices, setPrices] = useState<IApiProspectPriceListCollection | undefined>(undefined)
    const [fetchingPrices, setFetchingPrices] = useState(false)
    const [selectedProspect, setSelectedProspect] = useState<ISalesmanProspect | undefined>()
    const [prospectDetailFetching, setProspectDetailFetching] = useState<boolean>(false)

    const { items, totalItems, fetching, error, me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            fetching: boolean
            error?: IAppErrorTypes
            totalItems: number
            items: Array<ISalesmanProspectList>
        }
    >(stateSelector)

    const handleProspectEdit = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const customerId = e.currentTarget.dataset.id
            if (!customerId) {
                return
            }

            setProspectDetailFetching(true)
            client.prospects
                .get(customerId)
                .then((response) => {
                    setSelectedProspect(response.data)
                    setShowFormModal(true)
                })
                .finally(() => setProspectDetailFetching(false))
        },
        [setSelectedProspect, setShowFormModal, setProspectDetailFetching]
    )

    const handleCustomerSelect = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const customerId = e.currentTarget.dataset.id
            const orderMode = e.currentTarget.dataset.orderMode

            // reset
            setSelectedProspect(undefined)
            setShowChoiceOrderModeModal(false)

            if (orderMode && $enum(IOrderMode).isValue(orderMode)) {
                dispatch(ordersSwitchOrderModeAction(orderMode))
            }

            if (customerId) {
                dispatch(customerSetCurrent(customerId, true))
            }
        },
        [dispatch, setSelectedProspect, setShowChoiceOrderModeModal]
    )

    const handleChoiceOrderModeCancel = useCallback(() => {
        setSelectedProspect(undefined)
        setShowChoiceOrderModeModal(false)
    }, [setSelectedProspect, setShowChoiceOrderModeModal])

    const handleChoiceOrderModeModalConfirm = useCallback(() => {
        setSelectedProspect(undefined)
        setShowChoiceOrderModeModal(false)
    }, [setSelectedProspect, setShowChoiceOrderModeModal])

    useEffect(() => {
        if (typeof prices === 'undefined' && !fetchingPrices) {
            setFetchingPrices(true)
            client.prospects
                .priceList()
                .then((response) => setPrices(response.data['hydra:member']))
                .finally(() => setFetchingPrices(false))
        }
    }, [setPrices, setFetchingPrices, fetchingPrices, prices])

    const CustomerString = useCallback(({ value }) => {
        return value ? value : '-'
    }, [])

    const CustomerPriceType = useCallback(
        ({ value }) => {
            const price = prices?.find((curr) => curr['@id'] === value)
            return <span>{price?.label || '-'}</span>
        },
        [prices]
    )

    const customerCartList = useMemo(() => {
        return generatePath(getPath('salesmanCarts', locale), {
            lang: locale,
        })
    }, [locale])

    const CustomerInProgressCart = useCallback(
        (props) => {
            const customer = props.data[props.cell.row.id]

            if (!customer.has_cart_in_progress) {
                return <span>-</span>
            }

            const stringified = Qs.stringify({
                criteria: {
                    filters: {
                        'customer.login': customer.code,
                    },
                },
            })

            return (
                <Link to={`${customerCartList}?${stringified}`}>
                    <span className="yes">
                        <FormattedMessage id="default.yes" />
                    </span>
                </Link>
            )
        },
        [customerCartList]
    )

    const CustomerActions = useCallback(
        ({ value }) => (
            <>
                {$enum(IOrderMode).map((orderMode, orderModeKey) => {
                    return (
                        <Button
                            block
                            key={orderModeKey}
                            variant={orderMode === IOrderMode.Order ? 'primary' : 'secondary'}
                            data-id={value}
                            data-order-mode={orderMode}
                            onClick={handleCustomerSelect}
                            disabled={fetching || prospectDetailFetching}
                            size="sm"
                        >
                            <FlatIcon icon={orderMode === IOrderMode.Order ? 'shopping-cart' : 'budget'} />{' '}
                            <FormattedMessage id={`salesman.customers.action_order_${orderMode}`} />
                        </Button>
                    )
                })}
                <Button
                    size="sm"
                    block
                    variant="outline-secondary"
                    data-id={value}
                    onClick={handleProspectEdit}
                    disabled={fetching || prospectDetailFetching}
                >
                    <FlatIcon icon="pencil" /> <FormattedMessage id="default.edit" />
                </Button>
            </>
        ),
        [handleProspectEdit, fetching, prospectDetailFetching, handleCustomerSelect]
    )

    const handleAddProspect = useCallback(() => {
        setSelectedProspect(undefined)
        setShowFormModal(true)
    }, [setShowFormModal, setSelectedProspect])

    const columns: Array<Column<SalesmanCustomersItemType> & UseSortByColumnOptions<SalesmanCustomersItemType>> =
        useMemo(() => {
            return [
                {
                    Header: formatMessage({ id: 'default.company_name' }),
                    accessor: 'business_name',
                    Cell: CustomerString,
                    defaultCanSort: true,
                    defaultCanFilter: true,
                },
                {
                    Header: formatMessage({ id: 'default.email' }),
                    accessor: 'email',
                    Cell: CustomerString,
                },
                {
                    Header: formatMessage({ id: 'default.phone' }),
                    accessor: 'telephone',
                    Cell: CustomerString,
                },
                {
                    Header: formatMessage({ id: 'customer.customer_type' }),
                    accessor: 'prospect_price',
                    Cell: CustomerPriceType,
                },
                {
                    Header: formatMessage({ id: 'default.in_progress_cart' }),
                    Cell: CustomerInProgressCart,
                    Filter: BooleanFilter,
                    accessor: 'has_cart_in_progress',
                    defaultCanFilter: true,
                },
                {
                    id: 'customer_actions',
                    accessor: 'id',
                    Cell: CustomerActions,
                },
            ]
        }, [formatMessage, CustomerString, CustomerPriceType, CustomerActions, CustomerInProgressCart])

    const handleExitModal = useCallback(() => {
        setShowFormModal(false)
    }, [setShowFormModal])

    const handleDoneModal = useCallback(
        (prospect) => {
            setShowFormModal(false)
            setSelectedProspect(prospect)
            setShowChoiceOrderModeModal(true)
            dispatch(salesmenProspectsRefreshAction())
        },
        [setShowFormModal, setShowChoiceOrderModeModal, setSelectedProspect, dispatch]
    )

    const handleFetchData = useCallback(
        (
            page: number,
            pageSize: number,
            filters: Filters<SalesmanCustomersItemType>,
            sortBy: Array<SortingRule<SalesmanCustomersItemType>>
        ) => {
            const filteredFilters = [...filters].filter((filter) => {
                if (filter.id !== 'all') {
                    return true
                }
                return canViewAllCustomers(me)
            })
            dispatch(salesmenProspectsProcessAction(page, pageSize, filteredFilters, sortBy))
        },
        [dispatch, me]
    )

    return (
        <>
            <Card className={classNames('member-card')}>
                <Card.Header>
                    <h3 className={'hv'}>
                        <FormattedMessage id={'salesman.prospects'} />
                    </h3>
                    <div className="add-prospect">
                        <Button variant="primary" onClick={handleAddProspect} disabled={prospectDetailFetching}>
                            <FlatIcon icon={'plus'} /> <FormattedMessage id="default.create_prospect" />
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table<SalesmanCustomersItemType>
                        totalItems={totalItems}
                        columns={columns}
                        data={items || []}
                        initialState={{ pageIndex: 0, pageSize: 40 }}
                        fetchData={handleFetchData}
                        fetching={fetching}
                        fetchError={error}
                    />
                    <EditFormModal
                        onExit={handleExitModal}
                        onDone={handleDoneModal}
                        show={showFormModal}
                        prices={prices}
                        prospect={selectedProspect}
                    />
                </Card.Body>
            </Card>
            <Portal>
                <SweetAlert
                    success
                    customClass={'salesman-choice-order-mode-modal'}
                    title={formatMessage({ id: 'default.what_do_you_want' })}
                    onConfirm={handleChoiceOrderModeModalConfirm}
                    show={showChoiceOrderModeModal}
                    customButtons={
                        <div className="actions">
                            <Button variant="link" onClick={handleChoiceOrderModeCancel}>
                                <FormattedMessage id="default.back" />
                            </Button>
                            {$enum(IOrderMode).map((orderMode, orderModeKey) => {
                                return (
                                    <Button
                                        key={orderModeKey}
                                        variant={orderMode === IOrderMode.Order ? 'primary' : 'secondary'}
                                        data-id={selectedProspect?.id}
                                        data-order-mode={orderMode}
                                        onClick={handleCustomerSelect}
                                        disabled={fetching}
                                        size="sm"
                                    >
                                        <FlatIcon icon={orderMode === IOrderMode.Order ? 'shopping-cart' : 'budget'} />{' '}
                                        <FormattedMessage id={`salesman.customers.action_order_${orderMode}`} />
                                    </Button>
                                )
                            })}
                        </div>
                    }
                />
            </Portal>
        </>
    )
}

export default SalesmanProspects
