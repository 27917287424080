import { AxiosInstance } from 'axios'
import { IApiMeResponse, IMeService } from './types'

const create = (instance: AxiosInstance): IMeService => {
    /*****************************
     * METHODS
     *****************************/
    const me = (): Promise<IApiMeResponse> => {
        return instance.get('/me')
    }
    /*****************************
     * API RETURNS
     *****************************/
    return {
        me,
    }
}

export default {
    create,
}
