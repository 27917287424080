/*
 *
 * Products reducer
 *
 */

import ActionTypes from './constants'
import {
    ContainerActions,
    ContainerState,
    IProductGridsState,
    IProductsBulkFavoriteState,
    IProductSearchState,
    IProductsListSelectionState,
    IProductsListSidebarState,
    IProductsListState,
} from './types'
import {
    formatProductListFilters,
    isProductGridDataResponse,
    isProductListResponse,
    ProductListSidebarMode,
} from './utils'

import cloneDeep from 'lodash/cloneDeep'
import StockAlertActionTypes from '../stockAlert/constants'
import uniq from 'lodash/uniq'
import { ProductsListDisplayMode } from '../../services/api/service/products/types'
import isNumber from 'lodash/isNumber'

export const initialSelectionState: IProductsListSelectionState = {
    identifiers: [],
    process: {
        processed: false,
        processing: false,
    },
}

export const initialProductGridState: IProductGridsState = {
    fetching: false,
    items: [],
}

export const initialBulkFavoriteState: IProductsBulkFavoriteState = {
    add: {
        fetching: false,
        identifiers: [],
    },
    remove: {
        fetching: false,
        identifiers: [],
    },
}

export const initialSearchState: IProductSearchState = {
    fetching: false,
    params: {
        page: 1,
    },
}

export const initialSidebarState: IProductsListSidebarState = {
    mode: {
        [ProductsListDisplayMode.Default]: ProductListSidebarMode.Expanded,
        [ProductsListDisplayMode.GridData]: ProductListSidebarMode.Collapsed,
    },
}

export const initialListState: IProductsListState = {
    fetching: false,
    displayMode: ProductsListDisplayMode.Default,
    selection: initialSelectionState,
    bulkFavorites: initialBulkFavoriteState,
    grid: {
        rows: [],
        columns: [],
        errors: [],
        items: {},
    },
    params: {
        page: 1,
    },
}

export const initialState: ContainerState = {
    list: initialListState,
    search: initialSearchState,
    grid: initialProductGridState,
    sidebar: initialSidebarState,
}

function productsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.PERSIST_PARAMS_ACTION: {
            const { params } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    settings: params,
                },
            }
        }
        case ActionTypes.LIST_SIDEBAR_CHANGE_MODE: {
            const { sidebarMode, displayMode } = action.payload
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    mode: {
                        ...state.sidebar.mode,
                        [displayMode]: sidebarMode,
                    },
                },
            }
        }
        case ActionTypes.LIST_PROCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: undefined,
                    mode: action.payload.mode,
                    displayMode: action.payload.params.displayMode || ProductsListDisplayMode.Default,
                    grid: {
                        ...initialListState.grid,
                        identifier: action.payload.params.productGridId,
                    },
                    paramsPrev: state.list.params ? cloneDeep(state.list.params) : undefined,
                    params: cloneDeep(action.payload.params),
                },
            }
        case ActionTypes.LIST_SUCCESS_ACTION: {
            const { response, showPriceFilters } = action.payload

            const filters = response.filters
                ? formatProductListFilters(response.filters, showPriceFilters)
                : state.list.filters
            let listState = {
                ...state.list,
                filters,
                params: cloneDeep(action.payload.params),
            }

            // pagination
            if (isProductListResponse(response)) {
                const canAddBulk =
                    typeof response.can_add_bulk === 'boolean' ? response.can_add_bulk : state.list.canAddBulk
                const totalItems = isNumber(response['hydra:totalItems']) ? response['hydra:totalItems'] : undefined
                const nextPageUrl = response['hydra:view']['hydra:next'] || undefined
                const prevPageUrl = response['hydra:view']['hydra:prev'] || undefined
                const firstPageUrl = response['hydra:view']['hydra:first'] || undefined
                const lastPageUrl = response['hydra:view']['hydra:last'] || undefined
                const items = response['hydra:member'] || []

                listState = {
                    ...listState,
                    items,
                    totalItems,
                    nextPageUrl,
                    prevPageUrl,
                    firstPageUrl,
                    lastPageUrl,
                    canAddBulk,
                    grid: {
                        ...initialListState.grid,
                    },
                }
            }

            // grid ?
            if (isProductGridDataResponse(response)) {
                listState = {
                    ...listState,
                    totalItems: undefined,
                    nextPageUrl: undefined,
                    prevPageUrl: undefined,
                    firstPageUrl: undefined,
                    lastPageUrl: undefined,
                    items: undefined,
                    grid: {
                        ...listState.grid,
                        rows: response.rows,
                        columns: response.columns,
                        items: response.items,
                        errors: response.errors,
                    },
                }
            }

            return {
                ...state,
                list: {
                    ...listState,
                },
            }
        }
        case ActionTypes.SEARCH_RESET_ACTION:
            return {
                ...state,
                search: initialSearchState,
            }
        case ActionTypes.SEARCH_SUCCESS_ACTION: {
            return {
                ...state,
                search: {
                    ...state.search,
                    fetching: false,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        }
        case ActionTypes.SEARCH_FAILURE_ACTION:
            return {
                ...state,
            }
        case ActionTypes.LIST_FAILURE_ACTION:
            return {
                ...state,
            }
        case ActionTypes.LIST_FAVORITE_PRODUCT_ACTION: {
            if (!state.list.items) {
                return state
            }

            let newState = {
                ...state,
                list: {
                    ...state.list,
                },
            }

            // dans le cas où on est dans le mode standard
            const displayMode = state.list.displayMode
            if (displayMode === ProductsListDisplayMode.Default) {
                let idx = -1
                let items = [...state.list.items]
                if (action.payload.favorite) {
                    idx = items.findIndex((obj) => obj['@id'] === action.payload.productId)
                } else {
                    idx = items.findIndex((obj) => obj.id === action.payload.productId)
                }
                if (idx === -1) {
                    return state
                }

                const product = {
                    ...items[idx],
                    favorite: action.payload.favorite,
                }

                items = items
                    .slice(0, idx)
                    .concat([product])
                    .concat(items.slice(idx + 1))

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        items: items,
                    },
                }
                // dans le cas où on est dans le mode grille commercial
            } else if (displayMode === ProductsListDisplayMode.GridData) {
                const items = { ...state.list.grid.items }
                const keys = Object.keys(items)
                const subProducts = Object.values(items)

                for (const spi in subProducts) {
                    let idx = -1
                    let subItems = [...subProducts[spi]]
                    const currentKey = keys[spi]

                    if (action.payload.favorite) {
                        idx = subItems.findIndex((obj) => obj['@id'] === action.payload.productId)
                    } else {
                        idx = subItems.findIndex((obj) => obj.id === action.payload.productId)
                    }

                    const subProduct = {
                        ...subItems[idx],
                        favorite: action.payload.favorite,
                    }

                    subItems = subItems
                        .slice(0, idx)
                        .concat([subProduct])
                        .concat(subItems.slice(idx + 1))

                    // override
                    items[currentKey] = [...subItems]
                }

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        grid: {
                            ...newState.list.grid,
                            items,
                        },
                    },
                }
            }

            return newState
        }
        case ActionTypes.LIST_RESET_ACTION:
            return {
                ...state,
                list: {
                    ...initialListState,
                    settings: state.list.settings,
                },
            }
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.LIST_SELECTION_ADD_ACTION: {
            const identifiers = [...state.list.selection.identifiers]
            identifiers.push(action.payload.productId)

            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        identifiers: uniq(identifiers),
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_ADD_BULK_ACTION: {
            const identifiers = [...state.list.selection.identifiers, ...action.payload.productIds]
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        identifiers: uniq(identifiers),
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_REMOVE_ACTION: {
            let identifiers = [...state.list.selection.identifiers]
            identifiers = identifiers.filter((id) => id !== action.payload.productId)

            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        identifiers,
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_REMOVE_BULK_ACTION: {
            let identifiers = [...state.list.selection.identifiers]
            identifiers = identifiers.filter((id) => action.payload.productIds.indexOf(id) === -1)

            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        identifiers,
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_SET_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        // ON REPART A 0
                        ...initialSelectionState,
                        action: action.payload.action,
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_APPLY_ACTION_PROCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        process: {
                            processed: false,
                            processing: true,
                            extra: action.payload,
                        },
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_APPLY_ACTION_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        process: {
                            ...state.list.selection.process,
                            processed: true,
                            processing: false,
                            extra: action.payload.extra,
                            success: action.payload.message,
                        },
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_APPLY_ACTION_FAILURE: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        process: {
                            ...state.list.selection.process,
                            processed: false,
                            processing: false,
                            error: action.payload.error,
                        },
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_RESET_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...initialSelectionState,
                    },
                },
            }
        }
        case ActionTypes.LIST_SELECTION_RESET_PROCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    selection: {
                        ...state.list.selection,
                        process: {
                            ...state.list.selection.process,
                            processing: false,
                        },
                    },
                },
            }
        }
        case StockAlertActionTypes.STOCK_ALERT_BULK_SUCCESS_ACTION: {
            const { productIds, subscribe } = action.payload

            if (!state.list.items) {
                return state
            }

            let newState = {
                ...state,
                list: {
                    ...state.list,
                },
            }

            const displayMode = state.list.displayMode
            if (displayMode === ProductsListDisplayMode.Default) {
                for (const productId of productIds) {
                    let items = [...state.list.items]
                    const idx = items.findIndex((obj) => obj['@id'] === productId)
                    if (idx === -1) {
                        continue
                    }

                    const product = {
                        ...items[idx],
                        has_stock_alert: subscribe,
                    }

                    items = items
                        .slice(0, idx)
                        .concat([product])
                        .concat(items.slice(idx + 1))

                    newState = {
                        ...newState,
                        list: {
                            ...newState.list,
                            items,
                        },
                    }
                }
            } else if (displayMode === ProductsListDisplayMode.GridData) {
                const items = { ...state.list.grid.items }
                const keys = Object.keys(items)
                const subProducts = Object.values(items)

                for (const productId of productIds) {
                    for (const spi in subProducts) {
                        let subItems = [...subProducts[spi]]
                        const currentKey = keys[spi]
                        const sidx = subItems.findIndex((obj) => obj['@id'] === productId)
                        if (sidx === -1) {
                            continue
                        }

                        const subProduct = {
                            ...subItems[sidx],
                            has_stock_alert: subscribe,
                        }

                        subItems = subItems
                            .slice(0, sidx)
                            .concat([subProduct])
                            .concat(subItems.slice(sidx + 1))

                        // override
                        items[currentKey] = [...subItems]
                    }
                }

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        items: [],
                        grid: {
                            ...newState.list.grid,
                            items,
                        },
                    },
                }
            }

            return newState
        }
        case StockAlertActionTypes.STOCK_ALERT_SUCCESS_ACTION: {
            const { productId, subscribe } = action.payload

            if (!state.list.items) {
                return state
            }

            let newState = {
                ...state,
                list: {
                    ...state.list,
                },
            }

            // dans le cas où on est dans le mode standard
            const displayMode = state.list.displayMode

            if (displayMode === ProductsListDisplayMode.Default) {
                let items = [...state.list.items]
                const idx = items.findIndex((obj) => obj.id === productId)
                const product = {
                    ...items[idx],
                    has_stock_alert: subscribe,
                }

                items = items
                    .slice(0, idx)
                    .concat([product])
                    .concat(items.slice(idx + 1))

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        items: items,
                    },
                }
            } else if (displayMode === ProductsListDisplayMode.GridData) {
                const items = { ...state.list.grid.items }
                const keys = Object.keys(items)
                const subProducts = Object.values(items)

                for (const spi in subProducts) {
                    let subItems = [...subProducts[spi]]
                    const currentKey = keys[spi]
                    const sidx = subItems.findIndex((obj) => obj['@id'] === productId)
                    if (sidx === -1) {
                        continue
                    }

                    const subProduct = {
                        ...subItems[sidx],
                        has_stock_alert: subscribe,
                    }

                    subItems = subItems
                        .slice(0, sidx)
                        .concat([subProduct])
                        .concat(subItems.slice(sidx + 1))

                    // override
                    items[currentKey] = [...subItems]
                }

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        grid: {
                            ...newState.list.grid,
                            items,
                        },
                    },
                }
            }

            return newState
        }
        case StockAlertActionTypes.STOCK_ALERT_FAILURE_ACTION: {
            const { productId, subscribe, subscriptionEnabled } = action.payload

            if (!state.list.items) {
                return state
            }

            let newState = {
                ...state,
                list: {
                    ...state.list,
                },
            }

            // dans le cas où on est dans le mode standard
            const displayMode = state.list.displayMode
            if (displayMode === ProductsListDisplayMode.Default) {
                let items = [...state.list.items]
                const idx = items.findIndex((obj) => obj['@id'] === productId)
                const product = {
                    ...items[idx],
                    has_stock_alert: subscribe,
                    can_add_stock_alert: subscriptionEnabled,
                }

                items = items
                    .slice(0, idx)
                    .concat([product])
                    .concat(items.slice(idx + 1))

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        items,
                    },
                }
            } else if (displayMode === ProductsListDisplayMode.GridData) {
                const items = { ...state.list.grid.items }
                const keys = Object.keys(items)
                const subProducts = Object.values(items)

                for (const spi in subProducts) {
                    let subItems = [...subProducts[spi]]
                    const currentKey = keys[spi]
                    const sidx = subItems.findIndex((obj) => obj['@id'] === productId)
                    if (sidx === -1) {
                        continue
                    }

                    const subProduct = {
                        ...subItems[sidx],
                        has_stock_alert: subscribe,
                        can_add_stock_alert: subscriptionEnabled,
                    }

                    subItems = subItems
                        .slice(0, sidx)
                        .concat([subProduct])
                        .concat(subItems.slice(sidx + 1))

                    // override
                    items[currentKey] = [...subItems]
                }

                newState = {
                    ...newState,
                    list: {
                        ...newState.list,
                        grid: {
                            ...newState.list.grid,
                            items,
                        },
                    },
                }
            }

            return newState
        }
        case ActionTypes.BULK_REMOVE_FAVORITE_PROCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        remove: {
                            ...initialBulkFavoriteState.remove,
                            identifiers: action.payload.productIds,
                            fetching: true,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_ADD_FAVORITE_PROCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        add: {
                            ...initialBulkFavoriteState.add,
                            identifiers: action.payload.productIds,
                            fetching: true,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_REMOVE_FAVORITE_SUCCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        remove: {
                            ...state.list.bulkFavorites.remove,
                            success: true,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_ADD_FAVORITE_SUCCESS_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        add: {
                            ...state.list.bulkFavorites.add,
                            success: true,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_REMOVE_FAVORITE_FAILURE_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        remove: {
                            ...state.list.bulkFavorites.remove,
                            success: false,
                            error: action.payload.error,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_ADD_FAVORITE_FAILURE_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        add: {
                            ...state.list.bulkFavorites.add,
                            success: false,
                            error: action.payload.error,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_REMOVE_FAVORITE_RESET_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        remove: {
                            ...initialBulkFavoriteState.remove,
                        },
                    },
                },
            }
        }
        case ActionTypes.BULK_ADD_FAVORITE_RESET_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    bulkFavorites: {
                        ...state.list.bulkFavorites,
                        add: {
                            ...initialBulkFavoriteState.add,
                        },
                    },
                },
            }
        }
        case ActionTypes.LIST_PRODUCT_GRID_PROCESS_ACTION: {
            return {
                ...state,
                grid: {
                    ...initialProductGridState,
                    fetching: true,
                },
            }
        }
        case ActionTypes.LIST_PRODUCT_GRID_FAILURE_ACTION: {
            return {
                ...state,
                grid: {
                    ...initialProductGridState,
                    error: action.payload.error,
                    fetching: false,
                },
            }
        }
        case ActionTypes.LIST_PRODUCT_GRID_SUCCESS_ACTION: {
            return {
                ...state,
                grid: {
                    ...initialProductGridState,
                    items: action.payload.response,
                    fetching: false,
                },
            }
        }
        case ActionTypes.LIST_PRODUCT_GRID_SELECT_DEFINITION_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    grid: {
                        ...state.list.grid,
                        selectedDefinition: action.payload.definition,
                    },
                },
            }
        }
        case ActionTypes.LIST_PRODUCT_GRID_RESET_DEFINITION_ACTION: {
            return {
                ...state,
                list: {
                    ...state.list,
                    grid: {
                        ...state.list.grid,
                        selectedDefinition: undefined,
                    },
                },
            }
        }
        default:
            return state
    }
}

export default productsReducer
