import { AxiosInstance } from 'axios'
import {
    IApiAttributeDefinitionResponse,
    IApiClassificationBestSellerResponse,
    IApiCategoryResponse,
    IApiFamiliesTreeResponse,
    IApiMenuResponse,
    IClassificationParameters,
    IClassificationService,
} from './types'

const create = (instance: AxiosInstance): IClassificationService => {
    /*****************************
     * METHODS
     *****************************/
    const familiesTree = (params?: IClassificationParameters): Promise<IApiFamiliesTreeResponse> => {
        return instance.get('/families-tree', { params })
    }

    const menu = (params?: IClassificationParameters): Promise<IApiMenuResponse> => {
        return instance.get('/menu', { params })
    }

    const getCategoryBySlug = (slug: string): Promise<IApiCategoryResponse> => {
        return instance.get(`/categories/by-slug/${slug}`)
    }

    const attributes = (): Promise<IApiAttributeDefinitionResponse> => {
        return instance.get('/attributes')
    }

    const getBestSellers = (params?: IClassificationParameters): Promise<IApiClassificationBestSellerResponse> => {
        return instance.get('/shipping-locations/best-sellers', { params })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        familiesTree,
        menu,
        attributes,
        getCategoryBySlug,
        getBestSellers,
    }
}

export default {
    create,
}
