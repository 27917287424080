import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialListSubState, initialState } from './reducer'
import { ShopImportListType, ShopImportType } from '../../services/api/service/imports/types'

/**
 * Direct selector to the orders state domain
 */

const selectShopImportDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports || initialState
}

const selectShopImportListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports.list || initialState.list
}

const selectShopImportCreateDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports.create || initialState.create
}
const selectShopImportProcessDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports.process || initialState.process
}
const selectShopImportDetailDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports.detail || initialState.detail
}
const selectShopImportMatrixUrlsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.imports.matrixUrls || initialState.matrixUrls
}

const makeSelectTypedShopImportListFetching = () =>
    createSelector(selectShopImportListDomain, (state) => {
        return state && state[ShopImportListType.Global] ? state[ShopImportListType.Global].fetching : false
    })

const makeSelectTypedShopImportList = () =>
    createSelector(selectShopImportListDomain, (state) => {
        return state && state[ShopImportListType.Global]
            ? state[ShopImportListType.Global]
            : {
                  ...initialListSubState,
              }
    })

const makeSelectTypedShopImportListError = () =>
    createSelector(selectShopImportListDomain, (state) => {
        return state && state[ShopImportListType.Global] ? state[ShopImportListType.Global].error : undefined
    })

const makeSelectTypedShopImportListItems = () =>
    createSelector(selectShopImportListDomain, (state) => {
        return state && state[ShopImportListType.Global] ? state[ShopImportListType.Global].items : undefined
    })

const makeSelectTypedShopImportListTotalItems = () =>
    createSelector(selectShopImportListDomain, (state) => {
        return state && state[ShopImportListType.Global] ? state[ShopImportListType.Global].totalItems : undefined
    })

const makeSelectShopImportCreateState = () =>
    createSelector(
        [selectShopImportCreateDomain, (_: any, shopImportType: ShopImportType) => shopImportType],
        (state, shopImportType) => {
            return state && state[shopImportType] ? state[shopImportType] : undefined
        }
    )

const makeSelectShopImportMatrixUrl = () =>
    createSelector(
        [selectShopImportMatrixUrlsDomain, (_: any, shopImportType: ShopImportType) => shopImportType],
        (state, shopImportType) => {
            return state[shopImportType] || undefined
        }
    )

const makeSelectShopImportProcessState = () =>
    createSelector([selectShopImportProcessDomain, (_: any, itemId?: string) => itemId], (state, itemId) => {
        if (!itemId) {
            return undefined
        }
        return state[itemId]
    })

const makeSelectShopImportDetailFetching = () =>
    createSelector(selectShopImportDetailDomain, (substate) => {
        return substate.fetching
    })

const makeSelectShopImportDetailError = () =>
    createSelector(selectShopImportDetailDomain, (substate) => {
        return substate.error
    })

const makeSelectShopImportDetail = () =>
    createSelector(selectShopImportDetailDomain, (substate) => {
        return substate.detail
    })

/**
 * Other specific selectors
 */

/**
 * Default selector used by Orders
 */

const makeSelectImports = () =>
    createSelector(selectShopImportDomain, (substate) => {
        return substate
    })

export default makeSelectImports
export {
    selectShopImportDomain,
    makeSelectTypedShopImportList,
    makeSelectTypedShopImportListError,
    makeSelectTypedShopImportListItems,
    makeSelectTypedShopImportListTotalItems,
    makeSelectTypedShopImportListFetching,
    makeSelectShopImportCreateState,
    makeSelectShopImportProcessState,
    makeSelectShopImportDetailFetching,
    makeSelectShopImportDetail,
    makeSelectShopImportDetailError,
    makeSelectShopImportMatrixUrl,
}
