import React, { ChangeEvent, memo, useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { ICartMode } from '../../services/api/service/carts/types'
import { $enum } from 'ts-enum-util'
import { FormattedMessage, useIntl } from 'react-intl'
import { StrictCartMode } from '../../store/carts/types'

type Props = {
    mode: StrictCartMode
    onChange: (mode: StrictCartMode) => void
}

const CartModeSwitcher = ({ mode, onChange }: Props): JSX.Element => {
    const { formatMessage } = useIntl()
    const modes: Array<StrictCartMode> = useMemo(() => {
        return [ICartMode.Default, ICartMode.Grouped]
    }, [])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const value = e.currentTarget.value
            if (!$enum(ICartMode).isValue(value) || value === ICartMode.Both) {
                return
            }
            onChange(value)
        },
        [onChange]
    )

    return (
        <Form.Group controlId="cart.cart_mode.select">
            <Form.Label>
                <FormattedMessage id="cart.cart_mode" />
            </Form.Label>
            <Form.Control as="select" custom value={mode} onChange={handleChange}>
                {modes.map((cartMode) => (
                    <option key={cartMode} value={cartMode}>
                        {formatMessage({ id: `cart.cart_mode_${cartMode}` })}
                    </option>
                ))}
            </Form.Control>
        </Form.Group>
    )
}

export default memo(CartModeSwitcher)
