import { ICustomer, ICustomerType } from '../services/api/service/customers/types'
import { isOrderAvailable } from '../store/carts/utils'
import {
    canExport,
    canImport,
    canViewInvoices,
    canViewOrders,
    canViewStores,
    customerCanListCarts,
    customerShowPrices,
    isCustomerResource,
} from '../store/customers/utils'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import { canExportPictures } from '../store/pictureExport/utils'
import { StrictCartMode } from '../store/carts/types'
import { ICartMode } from '../services/api/service/carts/types'

export type VoterReturnType = boolean | { route: string; params?: any }
export type VoterFunc = (
    customer?: ICustomer,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
) => VoterReturnType

export function orderAvailableVoter(
    customer?: ICustomer,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
): VoterReturnType {
    if (typeof customer !== 'undefined' && isOrderAvailable(customer, store, cartMode)) {
        return true
    }

    if (
        isCustomerResource(me) &&
        me?.account_type === ICustomerType.MultiStore &&
        !store &&
        cartMode === ICartMode.Default
    ) {
        return { route: 'customerStores' }
    }

    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerCartsPageVoter(
    customer?: ICustomer,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
): VoterReturnType {
    if (customerCanListCarts(customer, me, store)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerOrdersPageVoter(
    customer?: ICustomer,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
): VoterReturnType {
    if (typeof customer !== 'undefined' && canViewOrders(customer, store, me, cartMode)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerInvoicesPageVoter(
    customer?: ICustomer,
    store?: ICustomer,
    me?: IMe,
    cartMode?: StrictCartMode
): VoterReturnType {
    if (typeof customer !== 'undefined' && canViewInvoices(customer, store, me, cartMode)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerImportsPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined' && canImport(customer, store)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerExportsPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined' && canExport(customer, store)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerStoresPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined' && canViewStores(customer, store)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerSalesmenPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined') {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerPictureExportPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined' && typeof me !== 'undefined' && canExportPictures(me)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}

export function customerDashboardPageVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined' && typeof me !== 'undefined' && customerShowPrices(customer, me)) {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerImports' }
}

export function customerAreaVoter(customer?: ICustomer, store?: ICustomer, me?: IMe): VoterReturnType {
    if (typeof customer !== 'undefined') {
        return true
    }
    return { route: isSalesmanResource(me) ? 'salesmanArea' : 'customerArea' }
}
