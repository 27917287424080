import { AxiosInstance } from 'axios'
import { IApiCustomerSalesmenResponse, ISalesmanService } from './types'

const create = (instance: AxiosInstance): ISalesmanService => {
    /*****************************
     * METHODS
     *****************************/
    const get = (): Promise<IApiCustomerSalesmenResponse> => {
        return instance.get('/customer-salesmans')
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        getCustomerSalesmen: get,
    }
}

export default {
    create,
}
