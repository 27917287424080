import axios, { AxiosInstance } from 'axios'
import { IApiConfigResponse, IConfigService } from './types'

const create = (_: AxiosInstance): IConfigService => {
    /*****************************
     * METHODS
     *****************************/
    const get = (): Promise<IApiConfigResponse> => {
        // on n'utilise pas l'instance principale qui est reliée à la logique du jwt
        // cette url est anonyme d'un point de vue firewall
        return axios.get('/config')
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        get,
    }
}

export default {
    create,
}
