import React, { memo, ReactChild } from 'react'
import { Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { ProductListStaticFilterCodes } from '../../services/api/service/products/types'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    prefixId: string
    listedOnly: boolean
    isSalesman?: boolean
    onChange: () => void
}

function ListedOnly({ prefixId, listedOnly, onChange, isSalesman = false }: IProps): JSX.Element {
    const identifier = `global-${prefixId}-${ProductListStaticFilterCodes.ListedOnly}-listed-only`
    const PopoverExplain: ReactChild = (
        <Popover id={`${identifier}-popover`}>
            <Popover.Content>
                <FormattedMessage
                    id={
                        isSalesman
                            ? 'products.filter.price.listed_only.salesman_label'
                            : 'products.filter.price.listed_only.label'
                    }
                />
            </Popover.Content>
        </Popover>
    )

    return (
        <div className={'nav-item listed-only'}>
            <OverlayTrigger placement={'auto'} trigger={['hover', 'focus']} overlay={PopoverExplain}>
                <div>
                    <div className={'listed-only-check'}>
                        <Form.Check
                            type={'switch'}
                            id={identifier}
                            checked={listedOnly}
                            label={''}
                            onChange={onChange}
                            value={1}
                        />
                    </div>
                    <span className={'lbl'}>
                        <label htmlFor={identifier}>
                            <FlatIcon icon="available-box" />{' '}
                            <FormattedMessage id={'products.filter.header.listed_only.abbr'} />
                        </label>
                    </span>
                </div>
            </OverlayTrigger>
        </div>
    )
}

ListedOnly.defaultProps = {} as Partial<IProps>

export default memo(ListedOnly)
