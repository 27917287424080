import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import SalesmanCarts from '../containers/SalesmanCarts/Loadable'

export default function SalesmanCartsPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.carts' })}</title>
            </Helmet>
            <div className={'salesman-carts-page'} id={'salesman-carts-page'}>
                <SalesmanCarts />
            </div>
        </>
    )
}
