import { call, cancelled, getContext, put, takeLatest } from 'redux-saga/effects'
import ActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    exportProductCheckStateFailureAction,
    exportProductCheckStateSuccessAction,
    exportProductCreateFailureAction,
    exportProductCreateSuccessAction,
    exportProductsListFailureAction,
    exportProductsListSuccessAction,
} from './actions'
import {
    IApiExportProductCheckStateProcessAction,
    IApiExportProductCreateProcessAction,
    IApiExportProductsListProcessAction,
} from './types'
import { formatAppError } from '../app/saga'

import { ExportProductType, IApiExportProductListResponse } from '../../services/api/service/exports/types'
import { RESOURCE_CART_TYPE } from '../../services/api/service/carts/types'
import { RESOURCE_ORDER_TYPE } from '../../services/api/service/orders/types'
import {
    IApiCartExportCreateResponse,
    IApiCartExportResponse,
    ICartExportCreateParameters,
} from '../../services/api/service/cartExports/types'
import {
    IApiOrderExportCreateResponse,
    IApiOrderExportResponse,
    IOrderExportCreateParameters,
} from '../../services/api/service/orderExports/types'

// TOKEN
import axios from 'axios'
const CancelToken = axios.CancelToken

function* processCreateExportProductRequest(action: IApiExportProductCreateProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { resourceType, resourceId, shippingLocationId, appendPicture, allStores } = action.payload

    try {
        let response: IApiCartExportCreateResponse | IApiOrderExportCreateResponse | undefined = undefined
        if (resourceType === RESOURCE_CART_TYPE) {
            const params: ICartExportCreateParameters = {
                shipping_location: shippingLocationId,
                append_picture: appendPicture,
                all_stores: allStores,
            }
            response = yield call({ context: api.cartExports, fn: 'create' }, params)
        } else if (resourceType === RESOURCE_ORDER_TYPE) {
            const params: IOrderExportCreateParameters = {
                order: resourceId,
                append_picture: appendPicture,
                all_stores: allStores,
            }
            response = yield call({ context: api.orderExports, fn: 'create' }, params)
        }
        if (response) {
            yield put(exportProductCreateSuccessAction(resourceType, resourceId, response))
        }
    } catch (e) {
        const err = yield call(formatAppError, e, 'export.unknow_error')
        yield put(exportProductCreateFailureAction(resourceType, resourceId, err))
    }
}

function* processCheckExportProductStateRequest(action: IApiExportProductCheckStateProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { item } = action.payload
    const id = item['@id']
    const type = item['@type']

    try {
        let response: IApiCartExportResponse | IApiOrderExportResponse | undefined = undefined
        if (type === ExportProductType.CartExport) {
            response = yield call({ context: api.cartExports, fn: 'get' }, id)
        } else if (type === ExportProductType.OrderExport) {
            response = yield call({ context: api.orderExports, fn: 'get' }, id)
        }
        if (response) {
            yield put(exportProductCheckStateSuccessAction(response.data))
        }
    } catch (e) {
        const err = yield call(formatAppError, e, 'export.unknow_error')
        yield put(exportProductCheckStateFailureAction(id, type, err))
    }
}

function* processExportProductsRequest(action: IApiExportProductsListProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const params = { ...action.payload }

    try {
        // on va chercher les produits
        const response: IApiExportProductListResponse = yield call(
            { context: api.exports, fn: 'list' },
            { ...params },
            source.token
        )

        yield put(exportProductsListSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'default.unknow_list_error')
        yield put(exportProductsListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(ActionTypes.CHECK_STATE_PROCESS_ACTION, processCheckExportProductStateRequest),
    takeLatest(ActionTypes.CREATE_PROCESS_ACTION, processCreateExportProductRequest),
    takeLatest(ActionTypes.LIST_PROCESS_ACTION, processExportProductsRequest),
]
