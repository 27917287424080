import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import {
    IApiPictureExportListParameters,
    IApiPictureExportListResponse,
    IPictureExport,
    IPictureExportParams,
} from '../../services/api/service/pictureExport/types'
import {
    IPictureExportCreateProcessPayload,
    IPictureExportItemFailurePayload,
    IPictureExportItemSuccessPayload,
    IPictureExportItemProcessPayload,
    IPictureExportListProcessPayload,
    IPictureExportListSuccessPayload,
} from './types'
import { IAppErrorTypes } from '../app/types'

export const pictureExportResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const pictureExportProcessResetAction = () =>
    action<ActionTypes.PROCESS_RESET_ACTION>(ActionTypes.PROCESS_RESET_ACTION)
export const pictureExportListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)
export const pictureExportDetailResetAction = () =>
    action<ActionTypes.DETAIL_RESET_ACTION>(ActionTypes.DETAIL_RESET_ACTION)

export const pictureExportProcessCreateProcessAction = (params: IPictureExportParams) =>
    action<ActionTypes.PROCESS_CREATE_PROCESS_ACTION, IPictureExportCreateProcessPayload>(
        ActionTypes.PROCESS_CREATE_PROCESS_ACTION,
        {
            params,
        }
    )
export const pictureExportProcessCreateSuccessAction = (item: IPictureExport) =>
    action<ActionTypes.PROCESS_CREATE_SUCCESS_ACTION, IPictureExportItemSuccessPayload>(
        ActionTypes.PROCESS_CREATE_SUCCESS_ACTION,
        {
            item,
        }
    )
export const pictureExportProcessCreateFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.PROCESS_CREATE_FAILURE_ACTION, IPictureExportItemFailurePayload>(
        ActionTypes.PROCESS_CREATE_FAILURE_ACTION,
        {
            error,
        }
    )

export const pictureExportProcessRefreshProcessAction = (identifier: string) =>
    action<ActionTypes.PROCESS_REFRESH_PROCESS_ACTION, IPictureExportItemProcessPayload>(
        ActionTypes.PROCESS_REFRESH_PROCESS_ACTION,
        {
            identifier,
        }
    )
export const pictureExportProcessRefreshSuccessAction = (item: IPictureExport) =>
    action<ActionTypes.PROCESS_REFRESH_SUCCESS_ACTION, IPictureExportItemSuccessPayload>(
        ActionTypes.PROCESS_REFRESH_SUCCESS_ACTION,
        {
            item,
        }
    )
export const pictureExportProcessRefreshFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.PROCESS_REFRESH_FAILURE_ACTION, IPictureExportItemFailurePayload>(
        ActionTypes.PROCESS_REFRESH_FAILURE_ACTION,
        {
            error,
        }
    )

export const pictureExportListProcessAction = (params: IApiPictureExportListParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IPictureExportListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })
export const pictureExportListSuccessAction = (response: IApiPictureExportListResponse) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IPictureExportListSuccessPayload>(ActionTypes.LIST_SUCCESS_ACTION, {
        response,
    })
export const pictureExportListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, IPictureExportItemFailurePayload>(ActionTypes.LIST_FAILURE_ACTION, {
        error,
    })
