import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ICustomer } from '../../../services/api/service/customers/types'
import Select, { FormatOptionLabelMeta } from 'react-select'
import ResumeCard from '../../../components/Customer/ResumeCard'
import { CustomerStoreCallback } from '../../../types/customer'
import { Col, Row } from 'react-bootstrap'
import FlatIcon from '../../../components/Icon/FlatIcon'
import { FormattedMessage } from 'react-intl'

export type StorePickerProps = {
    customer: ICustomer
    store?: ICustomer
    onStoreChange?: CustomerStoreCallback
}

function StorePicker({ customer, store, onStoreChange }: StorePickerProps): JSX.Element {
    const [value, setValue] = useState<ICustomer | undefined>(store || customer)

    const customers = useMemo(() => {
        return customer.stores!
    }, [customer])

    const handleChange = useCallback(
        (option) => {
            if (onStoreChange) {
                onStoreChange(
                    customer,
                    option.id !== customer.id ? option : undefined,
                    option.id !== customer.id ? option.id : undefined
                )
            }
            setValue(option)
        },
        [onStoreChange, customer]
    )

    const handleFormatOptionLabel = useCallback(
        (option: ICustomer, meta: FormatOptionLabelMeta<ICustomer, false>) => {
            const selected = meta.context === 'value'
            return (
                <ResumeCard
                    customer={option}
                    addressInline={!selected}
                    principal={customer.id === option.id}
                    className={selected ? 'selected-card' : 'menu-card'}
                />
            )
        },
        [customer]
    )

    useEffect(() => {
        setValue((ste) => {
            if (store) {
                if (ste?.id === store.id) {
                    return ste
                }
                return store
            }
            if (customer) {
                if (ste?.id === customer.id) {
                    return ste
                }
                return customer
            }
            return undefined
        })
    }, [customer, store, setValue])

    return (
        <div className="account-picker">
            <Row className="align-items-center">
                <Col md={'auto'}>
                    <label className="form-label">
                        <FlatIcon icon="shop" />
                        <span>
                            <FormattedMessage id="customer.your_store" />
                        </span>
                    </label>
                </Col>
                <Col>
                    <Select<ICustomer>
                        className="account-selector"
                        isClearable={false}
                        isSearchable={false}
                        options={customers}
                        classNamePrefix="ac"
                        formatOptionLabel={handleFormatOptionLabel}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.business_name}
                        onChange={handleChange}
                        value={value}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default StorePicker
