import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { IHeadingProps } from '../type'

function Heading({ filter, onClick, active, count, subTitle }: IHeadingProps): JSX.Element {
    const { formatMessage } = useIntl()
    const title = useMemo(() => {
        if (!filter.translatable) {
            return filter.label
        }
        if (!filter.label || filter.label.length === 0) {
            return filter.code
        }
        return formatMessage({ id: filter.label })
    }, [filter, formatMessage])
    return (
        <div className={classNames('heading', { active })} onClick={onClick}>
            <div className={'title'}>
                <span className={'lbl'}>{title}</span> {count && <span className={'badge'}>{count}</span>}
                {subTitle && <span className={'subtitle'}>{subTitle}</span>}
            </div>
        </div>
    )
}

Heading.defaultProps = {} as Partial<IHeadingProps>

export default memo(Heading)
