import { ContainerState, ContainerActions } from './types'
import ActionTypes from './constants'

export const initialListState = {
    fetching: false,
    items: [],
}

export const initialState: ContainerState = {
    countries: initialListState,
}

function localizationReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.COUNTRY_LIST_FAILURE_ACTION:
            return {
                ...state,
                countries: {
                    ...initialListState,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.COUNTRY_LIST_PROCESS_ACTION:
            return {
                ...state,
                countries: {
                    ...initialListState,
                    fetching: true,
                },
            }
        case ActionTypes.COUNTRY_LIST_SUCCESS_ACTION:
            return {
                ...state,
                countries: {
                    ...initialListState,
                    tms: Date.now(),
                    items: action.payload['hydra:member'],
                    fetching: false,
                },
            }
        case ActionTypes.COUNTRY_LIST_RESET_ACTION:
            return {
                ...state,
                countries: {
                    ...initialState.countries,
                },
            }
        case ActionTypes.RESET_ACTION:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default localizationReducer
