import IHttpAction from './actions'

export function createHttpReducer<S, T extends IHttpAction>(
    reducerPrefix: string,
    reducerFunction: (state: S, action: T) => S,
    reducerPropertyAccessor?: keyof S | null
): (state: S, action: T) => S {
    return (state: S, action: T) => {
        if (!state) {
            return reducerFunction(state, action)
        }

        // @ts-ignore
        let obj: S = reducerPropertyAccessor ? { ...state[reducerPropertyAccessor] } : { ...state }
        switch (action.type) {
            case `app/${reducerPrefix}/REQUEST_PROCESS`:
                obj = {
                    ...obj,
                    tms: undefined,
                    fetching: true,
                    error: undefined,
                }
                if (reducerPropertyAccessor) {
                    state = { ...state }
                    // @ts-ignore
                    state[reducerPropertyAccessor] = { ...obj }
                } else {
                    state = { ...state, ...obj }
                }
                break
            case `app/${reducerPrefix}/REQUEST_FAILURE`: {
                const err = action.payload?.error
                obj = {
                    ...obj,
                    tms: Date.now(),
                    error: err,
                    fetching: false,
                }
                if (reducerPropertyAccessor) {
                    state = { ...state }
                    // @ts-ignore
                    state[reducerPropertyAccessor] = { ...obj }
                } else {
                    state = { ...state, ...obj }
                }
                break
            }
            case `app/${reducerPrefix}/REQUEST_SUCCESS`:
                obj = {
                    ...obj,
                    tms: Date.now(),
                    fetching: false,
                }
                if (reducerPropertyAccessor) {
                    state = { ...state }
                    // @ts-ignore
                    state[reducerPropertyAccessor] = obj
                } else {
                    state = { ...state, ...obj }
                }
                break
            case `app/${reducerPrefix}/RESET`:
            default:
                break
        }
        return reducerFunction(state, action)
    }
}
