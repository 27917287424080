import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../routes'
import { ICartCollection } from '../../services/api/service/carts/types'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    carts: ICartCollection
}

function Cart({ carts }: IProps): JSX.Element {
    const [badgeValue, setBadgeValue] = useState<number>(0)
    const { locale } = useIntl()
    const cartUrl = generatePath(getPath('cart', locale), { lang: locale })
    useEffect(() => {
        let val = 0
        for (const idx in carts) {
            val += carts[idx].total_quantity
        }
        setBadgeValue(val)
    }, [carts])
    return (
        <NavLink to={cartUrl} className={'nav-item cart'}>
            <FlatIcon icon={'shopping-cart'} />
            {badgeValue > 0 && <span className={'badge badge-primary'}>{badgeValue}</span>}
            <span className={'lbl'}>
                <FormattedMessage id="default.cart" />
            </span>
        </NavLink>
    )
}

Cart.defaultProps = {} as Partial<IProps>

export default Cart
