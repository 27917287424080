import isObject from 'lodash/isObject'
import { has } from 'lodash'
import isUndefined from 'lodash/isUndefined'

export enum ProductBadgeType {
    BestB2c = 'best_b2c',
    New = 'new',
    MyListing = 'buying_group_product',
    Discount = 'discount',
    DecreasingPrice = 'decreasing_price',
}

export enum ProductBadgeMode {
    Image = 'image',
    Text = 'text',
}

export type BadgeLocalizedUrl = { fallback: string } & Record<string, string>
export type BadgeDefaultUrl = string
export type BadgeUrl = BadgeDefaultUrl | BadgeLocalizedUrl
export type BadgeUrlCollection = Partial<Record<ProductBadgeType, BadgeUrl>>
export type ShowBadgeType = ProductBadgeType | Array<ProductBadgeType> | boolean
export type BadgeModeType = ProductBadgeMode

export function isBadgeLocalizedUrl(obj: any): obj is BadgeLocalizedUrl {
    return isObject(obj) && has(obj, 'fallback')
}

export function extractBadgeUrl(badgeUrl?: BadgeUrl, locale?: string): string | undefined {
    if (isUndefined(badgeUrl)) {
        return undefined
    }

    if (isBadgeLocalizedUrl(badgeUrl)) {
        return badgeUrl[locale || 'fallback'] || badgeUrl.fallback
    }

    return badgeUrl
}
