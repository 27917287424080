import { AxiosInstance, CancelToken } from 'axios'
import { IApiPaymentModeListResponse, IPaymentModeListParameters, IPaymentModeService } from './types'

const create = (instance: AxiosInstance): IPaymentModeService => {
    const list = (
        params: IPaymentModeListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiPaymentModeListResponse> => {
        return instance.get('/payment-modes', { params: params, cancelToken })
    }

    return {
        list,
    }
}

export default {
    create,
}
