/*
 *
 * Search constants
 *
 */

enum ActionTypes {
    PROCESS_ACTION = 'app/Search/REQUEST_PROCESS',
    STORE_CANCEL_TOKEN = 'app/Search/STORE_CANCEL_TOKEN',
    STORE_SEARCH = 'app/Search/STORE_SEARCH',
    SUCCESS_ACTION = 'app/Search/REQUEST_SUCCESS',
    FAILURE_ACTION = 'app/Search/REQUEST_FAILURE',
    RESET_ACTION = 'app/Search/RESET',
}

export default ActionTypes
