import { ICartLight, ICartValidationMode } from '../../services/api/service/carts/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import {
    cartHasErrors,
    cartsAllEmpties,
    getMinimumOrderAmount,
    isMinimumAmountActive,
    isMinimumAmountReached,
} from '../../store/carts/utils'
import { useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import { Variant } from 'react-bootstrap/esm/types'
import { StrictCartMode } from '../../store/carts/types'

export type CartInfoOptions = {
    cart: ICartLight
    customer: ICustomer
    store?: ICustomer
    minimumAmountMode: ICustomerMinimumAmountMode
    cartMode: StrictCartMode
    cartValidationMode: ICartValidationMode
    abbreviateMessage: boolean
}

export function useCartInfo({
    cart,
    customer,
    store,
    minimumAmountMode,
    cartMode,
    abbreviateMessage = false,
}: CartInfoOptions) {
    const { formatNumber, formatMessage } = useIntl()

    const [value, setValue] = useState<string | undefined>(undefined)
    const [remainingValue, setRemainingValue] = useState<string | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [reached, setReached] = useState<boolean>(false)
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(false)
    const [level, setLevel] = useState<Variant>('success')
    const owner = useMemo(() => {
        if (store && cart.customer === store['@id']) {
            return store
        }
        return customer
    }, [customer, cart, store])

    useEffect(() => {
        setValue(
            formatNumber(getMinimumOrderAmount(cart, customer, cartMode, minimumAmountMode), {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                currencySign: 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'currency',
            })
        )
    }, [cart, cartMode, customer, formatNumber, setValue, minimumAmountMode])

    useEffect(() => {
        const isReached = isMinimumAmountReached(cart, owner, cartMode, minimumAmountMode)
        const isCartHasErrors = cartHasErrors(cart)
        const isCartEmpty = cartsAllEmpties([cart])
        const minimumActive = isMinimumAmountActive(cart, owner, cartMode, minimumAmountMode)
        let currLevel = 'success'
        let remainingAmount = formatNumber(0.0, {
            currency: 'EUR',
            currencyDisplay: 'symbol',
            currencySign: 'standard',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: 'currency',
        })

        if (!isReached) {
            if (minimumAmountMode === ICustomerMinimumAmountMode.Strict) {
                currLevel = 'danger'
            } else if (minimumAmountMode === ICustomerMinimumAmountMode.Permissive) {
                currLevel = 'warning'
            }
            const remainingAmountValue = getMinimumOrderAmount(cart, customer, cartMode, minimumAmountMode) - cart.total
            remainingAmount = formatNumber(remainingAmountValue, {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                currencySign: 'standard',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'currency',
            })
        } else if (isCartEmpty) {
            currLevel = 'danger'
        } else if (isCartHasErrors) {
            currLevel = 'warning'
        } else if (cart.locked) {
            currLevel = cart.customer === customer['@id'] ? 'warning' : 'success'
        }

        setIsEmpty(isCartEmpty)
        setHasErrors(isCartHasErrors)
        setReached(isReached)
        setLevel(currLevel)
        setActive(minimumActive)
        setRemainingValue(remainingAmount)
    }, [
        cart,
        customer,
        owner,
        store,
        setReached,
        setLevel,
        setActive,
        setHasErrors,
        setIsEmpty,
        minimumAmountMode,
        formatNumber,
        setRemainingValue,
        cartMode,
    ])

    useEffect(() => {
        const messages: Array<string> = []
        if (isEmpty) {
            messages.push(formatMessage({ id: 'carts.empty' }, { total: 1 }))
        } else if (hasErrors && !cart.locked) {
            messages.push(formatMessage({ id: 'cart.has_errors' }))
        }

        if (!isEmpty && !hasErrors && reached && (!cart.locked || (cart.locked && cart.customer !== customer['@id']))) {
            if (typeof cart.store_carts !== 'undefined') {
                messages.push(formatMessage({ id: 'cart.save_to_order_allowed' }))
            } else if (cart.minimum_order_amount > 0) {
                messages.push(
                    formatMessage(
                        { id: 'cart.total_minimum_amount_reached' },
                        {
                            minimum_amount: value,
                        }
                    )
                )
            }
        }

        if (!isEmpty && cart.locked) {
            if (cart.customer !== customer['@id']) {
                messages.push(formatMessage({ id: 'cart.cart_locked' }))
            } else {
                messages.push(formatMessage({ id: 'cart.your_cart_locked' }))
            }
        }

        if (!isEmpty && !reached) {
            if (typeof cart.store_carts === 'undefined') {
                const id = abbreviateMessage
                    ? 'cart.total_minimum_amount_not_reached'
                    : minimumAmountMode === ICustomerMinimumAmountMode.Permissive
                    ? 'cart.total_minimum_amount_warning'
                    : 'cart.total_minimum_amount_required'
                messages.push(formatMessage({ id }, { minimum_amount: value }))
            } else if (cart.store_carts!.length > 0) {
                let hasCartNotReachedMinimum = false
                for (const idx in cart.store_carts) {
                    const currentStoreCart = cart.store_carts[idx]
                    if (
                        isMinimumAmountActive(currentStoreCart, owner, cartMode, minimumAmountMode) &&
                        !isMinimumAmountReached(currentStoreCart, owner, cartMode, minimumAmountMode)
                    ) {
                        hasCartNotReachedMinimum = true
                        break
                    }
                }

                if (hasCartNotReachedMinimum) {
                    messages.push(formatMessage({ id: 'cart.total_minimum_amount_all_warning' }))
                }
            }
        }

        setMessage(messages.join(`\n`))
    }, [
        cart,
        customer,
        owner,
        formatMessage,
        reached,
        isEmpty,
        hasErrors,
        value,
        abbreviateMessage,
        minimumAmountMode,
        cartMode,
    ])

    return {
        active,
        reached,
        level,
        message,
        hasErrors,
        isEmpty,
        value,
        remainingValue,
    }
}
