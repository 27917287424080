/*
 *
 * CrossSelling
 *
 */

import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Carousel from '../../components/Product/Carousel/Carousel'
import { IProductList } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { productCrossSellingProcessAction } from '../../store/product/actions'
import {
    makeSelectProductCrossSellingFetching,
    makeSelectProductCrossSellingItems,
} from '../../store/product/selectors'
import { IBreakpointMap } from '../../types/common'
import { generateProductUrl } from '../../utils/productHelper'

interface IProps {
    productId: string
    onProductClick?: (product: IProductList) => void
}

const stateSelector = createStructuredSelector<any, any>({
    items: makeSelectProductCrossSellingItems(),
    fetching: makeSelectProductCrossSellingFetching(),
})

const breakpoints: IBreakpointMap = {
    // when window width is >= 480px
    720: {
        slidesPerView: 3,
        spaceBetween: 15,
    },
    // when window width is >= 640px
    960: {
        slidesPerView: 4,
        spaceBetween: 30,
    },
}

function CrossSelling({ productId, onProductClick }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const { fetching, items } = useSelector<
        IApplicationRootState,
        {
            fetching: boolean
            invalidated: boolean
            items: Array<IProductList>
        }
    >(stateSelector)

    useEffect(() => {
        if (!fetching) {
            dispatch(productCrossSellingProcessAction(productId))
        }
    }, [productId, fetching, dispatch])

    const handleProductClick = (product: IProductList) => {
        const finalUrl = generateProductUrl(product.id, locale)
        // @ts-ignore
        if (location.state && location.state.background) {
            // @ts-ignore
            history.replace(finalUrl, { background: location.state.background })
        } else {
            history.replace(finalUrl)
        }
        if (onProductClick) {
            onProductClick(product)
        }
    }

    return (
        <div id={'cross-selling-list'}>
            <Carousel
                breakpoints={breakpoints}
                products={items}
                title={formatMessage({ id: 'products.cross_selling' })}
                onProductClick={handleProductClick}
            />
        </div>
    )
}

CrossSelling.defaultProps = {} as Partial<IProps>

export default CrossSelling
