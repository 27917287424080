import { History } from 'history'
import { ApplicationInjectedStore, IApplicationRootState } from './index'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer from './reducers'
import client from '../services/api/client'
import localStorage from '../services/storage/create'
import { configureFlashMessages } from 'redux-flash-messages'
import Config from '../config'
import rootSaga from './sagas'
import LogRocket from 'logrocket'
import AuthActionTypes from './auth/constants'

import * as Sentry from '@sentry/react'
import routerHistoryMiddleware from './routes/middleware'
import { AxiosError } from 'axios'
import { authLogoutAction } from './auth/actions'

export default function configureStore(
    initialState: Partial<IApplicationRootState>,
    history: History
): ApplicationInjectedStore {
    // initialize API
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const storage = localStorage.create(Config.STORAGE.NAME, Config.STORAGE.VERSION)

    // inject API
    let reduxSagaMonitorOptions = {}

    // inject API in saga context
    reduxSagaMonitorOptions = {
        ...reduxSagaMonitorOptions,
        context: {
            api: client,
            storage,
        },
    }

    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

    const middlewares = [sagaMiddleware]
    if (history) {
        // @ts-ignore
        middlewares.push(routerHistoryMiddleware)
        // @ts-ignore
        middlewares.push(routerMiddleware(history))
    }

    if (Config.LOG_ROCKET.ACTIVE) {
        middlewares.push(LogRocket.reduxMiddleware())
    }

    const enhancers: Array<ReturnType<typeof applyMiddleware>> = []
    enhancers.push(applyMiddleware(...middlewares))

    if (Config.SENTRY.ACTIVE) {
        const sentryReduxEnhancer = Sentry.createReduxEnhancer({
            actionTransformer: (action) => {
                if (action.type === AuthActionTypes.PROCESS_ACTION) {
                    // Return null to not log the action to Sentry
                    return {
                        ...action,
                        payload: {
                            ...action.payload,
                            password: null,
                        },
                    }
                }
                if ([AuthActionTypes.LOGGED_ACTION].indexOf(action.type) > -1) {
                    // Return a transformed action to remove sensitive information
                    return {
                        ...action,
                        payload: {
                            ...action.payload,
                            me: null,
                        },
                    }
                }

                return action
            },
        })
        enhancers.push(sentryReduxEnhancer)
    }

    // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
    /* istanbul ignore next */
    let enhancer: StoreEnhancer<ApplicationInjectedStore>
    if (Config.DEBUG.ACTIVE && typeof window === 'object') {
        enhancer = composeWithDevTools(compose(...enhancers))
    } else {
        enhancer = compose(...enhancers)
    }

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state

    const store = createStore(createRootReducer(history), enhancer) as ApplicationInjectedStore

    // flash messages
    configureFlashMessages({
        dispatch: store.dispatch,
    })

    sagaMiddleware.run(rootSaga)

    // Extensions
    store.runSaga = sagaMiddleware.run
    store.injectedReducers = {} // Reducer registry
    store.injectedSagas = {} // Saga registry

    client.getInstance().interceptors.response.use(
        (response) => {
            return response
        },
        (error: AxiosError) => {
            if (!error.response && error.message.startsWith('Unable to refresh access token for request')) {
                store.dispatch(authLogoutAction(true, error, window.location.pathname))
                return Promise.reject(error)
            }
            if (error.response?.status === 401) {
                // if (error.response?.status === 401 && error.response?.config?.url === client.getRefreshTokenUrl()) {
                store.dispatch(authLogoutAction(true, error, window.location.pathname))
                return Promise.reject(error)
            }
            // Do something with response error
            return Promise.reject(error)
        }
    )

    return store
}
