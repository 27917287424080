import { IOrderDocumentType } from '../../../services/api/service/orders/types'
import { Nullable } from 'tsdef'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export function getDocumentIcon(type: IOrderDocumentType): Nullable<IconProp> {
    if (type === IOrderDocumentType.PurchaseOrderExcel) {
        return ['fal', `file-excel`]
    } else if (type === IOrderDocumentType.PurchaseOrderPdf) {
        return ['fal', `file-pdf`]
    } else if (type === IOrderDocumentType.DeliveryNoteExcel) {
        return ['fal', `file-spreadsheet`]
    } else if (type === IOrderDocumentType.DeliveryRemaindersExcel) {
        return ['fal', `file-spreadsheet`]
    }

    return null
}
