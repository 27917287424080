/**
 *
 * ReOrder
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { ICartReOrderProps } from '../../../../../types/cart'
import Button from '../../../../Buttons/Button'
import MultiStoreModal from './Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IReOrderData } from '../../../../../store/carts/types'

function MultiStore({
    customer,
    fetching,
    done,
    useCartType,
    onReOrderSubmit,
    onReOrderReset,
    block = true,
    size,
    icon,
    label,
}: ICartReOrderProps): JSX.Element {
    const { formatMessage } = useIntl()
    const [orderModalShown, setOrderModalShown] = useState<boolean>(false)
    const handleMultiStoreReOrderClick = useCallback(() => setOrderModalShown(true), [setOrderModalShown])

    const handleOnReset = useCallback(() => {
        // RESET FORM
        setOrderModalShown(false)
        if (onReOrderReset) {
            onReOrderReset()
        }
    }, [onReOrderReset, setOrderModalShown])

    const handleOnSubmit = useCallback(
        (data: IReOrderData) => {
            onReOrderSubmit(data)
        },
        [onReOrderSubmit]
    )

    const formattedLabel = useMemo(() => {
        return label || formatMessage({ id: 'order.detail.re_order' })
    }, [label, formatMessage])

    useEffect(() => {
        if (done) {
            setOrderModalShown(false)
        }
    }, [done, setOrderModalShown])
    return (
        <div className={'re-order-action multi-store'}>
            <Button
                block={block}
                size={size}
                loading={fetching}
                disabled={fetching}
                variant={'primary'}
                onClick={handleMultiStoreReOrderClick}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {formattedLabel && <span className="lbl">{formattedLabel}</span>}
            </Button>
            <MultiStoreModal
                customer={customer}
                excludedCustomerIds={customer.can_order_on_main_store ? undefined : [customer['@id']]}
                fetching={fetching}
                onReOrderSubmit={handleOnSubmit}
                onReOrderReset={handleOnReset}
                show={orderModalShown}
                useCartType={useCartType}
            />
        </div>
    )
}

MultiStore.defaultProps = {} as Partial<ICartReOrderProps>

export default memo(MultiStore)
