import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the cms state domain
 */

const selectSalesmanDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.salesman || initialState
}

const selectCustomersDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.salesman.customers || initialState.customers
}
const selectProspectListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.salesman.prospects.list || initialState.prospects.list
}

const selectEditProspectDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.salesman.prospects.edit || initialState.prospects.edit
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Cms
 */

const makeSelectSalesman = () =>
    createSelector(selectSalesmanDomain, (substate) => {
        return substate
    })
const makeSelectCustomersItems = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.items
    })

const makeSelectCustomersTableState = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.tableState
    })

const makeSelectCustomersTotalItems = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.totalItems || 0
    })

const makeSelectCustomersError = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.error
    })
const makeSelectCustomersFetching = () =>
    createSelector(selectCustomersDomain, (substate) => {
        return substate.fetching
    })

const makeSelectProspectsItems = () =>
    createSelector(selectProspectListDomain, (substate) => {
        return substate.items
    })

const makeSelectProspectsTotalItems = () =>
    createSelector(selectProspectListDomain, (substate) => {
        return substate.totalItems || 0
    })

const makeSelectProspectsError = () =>
    createSelector(selectProspectListDomain, (substate) => {
        return substate.error
    })
const makeSelectProspectsFetching = () =>
    createSelector(selectProspectListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectProspectsTableState = () =>
    createSelector(selectProspectListDomain, (substate) => {
        return substate.tableState
    })
const makeSelectEditProspectError = () =>
    createSelector(selectEditProspectDomain, (substate) => {
        return substate.error
    })
const makeSelectEditProspectFetching = () =>
    createSelector(selectEditProspectDomain, (substate) => {
        return substate.fetching
    })

const makeSelectEditProspectParams = () =>
    createSelector(selectEditProspectDomain, (substate) => {
        return substate.params
    })

const makeSelectEditProspectData = () =>
    createSelector(selectEditProspectDomain, (substate) => {
        return substate.data
    })

export default makeSelectSalesman
export {
    selectSalesmanDomain,
    makeSelectCustomersItems,
    makeSelectCustomersTotalItems,
    makeSelectCustomersError,
    makeSelectCustomersFetching,
    makeSelectCustomersTableState,
    makeSelectProspectsItems,
    makeSelectProspectsTotalItems,
    makeSelectProspectsError,
    makeSelectProspectsFetching,
    makeSelectProspectsTableState,
    makeSelectEditProspectFetching,
    makeSelectEditProspectParams,
    makeSelectEditProspectData,
    makeSelectEditProspectError,
}
