import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { IProductListQueryParameters, retrieveProductListQueryParams } from '../../store/products/utils'
import Config from '../../config'
import { ProductsListDisplayMode } from '../../services/api/service/products/types'

export function useProductListParsedQuery() {
    const { search } = useLocation()
    const [parsedQueries, setParsedQueries] = useState<IProductListQueryParameters | undefined>(undefined)

    useEffect(() => {
        const queries = retrieveProductListQueryParams(search)
        setParsedQueries({
            filters: queries.filters,
            page: queries.page || 1,
            itemsPerPage: queries.itemsPerPage || Config.PRODUCT_LIST.ITEMS_PER_PAGE,
            displayMode: queries.displayMode || ProductsListDisplayMode.Default,
            productGridId: queries.productGridId,
        })
    }, [search])

    return {
        queries: parsedQueries,
    }
}
