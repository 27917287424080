import classNames from 'classnames'
import React, { useState, forwardRef } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import Button from '../../../components/Buttons/Button'
import { isInternetExplorer } from '../../../utils/browser'
import { ILoginStepProps, ILoginUsernameStepFormData } from '../types'

type IProps = ILoginStepProps<ILoginUsernameStepFormData>

const LoginFormStep = forwardRef<HTMLInputElement, IProps>(({ step, onValidated, register, disabled = false }) => {
    const [currentValue, setCurrentValue] = useState<string>('')
    const [fieldError, setFieldError] = useState<string>('')
    const isFullFilled = currentValue.length > 0
    const isInvalid = fieldError.length > 0
    const isIe = isInternetExplorer()
    const { formatMessage } = useIntl()

    const constraints = yup.object().shape({
        login: yup.string().required(formatMessage({ id: 'login.login_required' })),
    })

    const handleOnFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value)
    }

    const validateField = (value: string) => {
        const val = {
            login: value,
        }
        constraints
            .validate(val)
            .catch((err) => {
                setFieldError(err.message)
            })
            .then((valid) => {
                if (valid) {
                    setFieldError('')
                    onValidated(valid as ILoginUsernameStepFormData, step)
                }
            })
    }

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            validateField(currentValue)
        }
    }

    const handleOnButtonClick = () => {
        validateField(currentValue)
    }

    return (
        <div
            className={classNames(
                'login-form-step',
                'login-form-step-1',
                { 'is-invalid': isInvalid },
                { 'is-full-filled': isFullFilled }
            )}
        >
            <Form.Group>
                <div className={classNames('form-group-inner', { 'is-invalid': isInvalid })}>
                    <Form.Control
                        autoFocus
                        size="lg"
                        type="text"
                        autoComplete="username"
                        spellCheck={false}
                        autoCapitalize={'none'}
                        disabled={disabled}
                        isInvalid={isInvalid}
                        onKeyPress={handleOnKeyPress}
                        className={classNames({ 'is-invalid': isInvalid }, { 'has-value': isFullFilled })}
                        placeholder={isIe ? formatMessage({ id: 'placeholder.login' }) : ''}
                        {...register('login', { required: true, onChange: handleOnFieldValueChange })}
                    />
                    {!isIe && (
                        <span className={'form-control-placeholder'}>
                            <FormattedMessage id={'placeholder.login'} />
                        </span>
                    )}
                </div>
                {isInvalid && <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>}
            </Form.Group>

            <p className={'login-window-private-mode'}>
                <FormattedMessage id={'login.private_mode_info'} />
            </p>
            <div className={'login-form-step-footer'}>
                <Button className={'btn-next'} variant="primary" disabled={disabled} onClick={handleOnButtonClick}>
                    <FormattedMessage id={'default.next'} />
                </Button>
            </div>
        </div>
    )
})

export default LoginFormStep
