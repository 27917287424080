import { Action } from 'redux'
import { IAppErrorTypes } from '../app/types'
import { ActionType } from 'typesafe-actions'
import * as actions from '../carts/actions'

export enum StockAlertOrigin {
    ProductList = 'list',
    Picker = 'picker',
    ProductDetail = 'product',
    CartItem = 'cartItem',
}

export interface IStockAlertPayload {
    productId: string
    subscribe: boolean
    origin?: StockAlertOrigin
}

export interface IStockAlertBulkPayload {
    productIds: Array<string>
    subscribe: boolean
    origin?: StockAlertOrigin
}

export type IStockAlertBulkSuccessPayload = IStockAlertBulkPayload
export type IStockAlertSuccessPayload = IStockAlertPayload

export interface IStockAlertBulkFailurePayload extends IStockAlertBulkPayload {
    error: IAppErrorTypes
}

export interface IStockAlertFailurePayload extends IStockAlertPayload {
    error: IAppErrorTypes
    subscriptionEnabled?: boolean
}

export interface IStockAlertProcessAction extends Action {
    payload: IStockAlertPayload
}

export interface IStockAlertSuccessAction extends Action {
    payload: IStockAlertSuccessPayload
}

export interface IStockAlertFailureAction extends Action {
    payload: IStockAlertFailurePayload
}

export interface IStockAlertBulkProcessAction extends Action {
    payload: IStockAlertBulkPayload
}

export interface IStockAlertBulkSuccessAction extends Action {
    payload: IStockAlertBulkSuccessPayload
}

export interface IStockAlertBulkFailureAction extends Action {
    payload: IStockAlertBulkFailurePayload
}

export type StockAlertActions = ActionType<typeof actions>
