import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the splashScreen state domain
 */

const selectSplashScreenDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.splashScreen || initialState
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by SplashScreen
 */

const makeSelectSplashScreen = () =>
    createSelector(selectSplashScreenDomain, (substate) => {
        return substate
    })

const makeSelectSplashScreenShow = () =>
    createSelector(selectSplashScreenDomain, (substate) => {
        return substate.show
    })

const makeSelectSplashScreenAnimated = () =>
    createSelector(selectSplashScreenDomain, (substate) => {
        return substate.animated
    })

export default makeSelectSplashScreen
export { makeSelectSplashScreenShow, makeSelectSplashScreenAnimated }
