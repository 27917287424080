import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import { ICountryListApiResponse } from '../../services/api/service/localization/types'

export const localizationCountryListProcessAction = () =>
    action<ActionTypes.COUNTRY_LIST_PROCESS_ACTION>(ActionTypes.COUNTRY_LIST_PROCESS_ACTION)

export const localizationCountryListSuccessAction = (response: ICountryListApiResponse) =>
    action<ActionTypes.COUNTRY_LIST_SUCCESS_ACTION, ICountryListApiResponse>(
        ActionTypes.COUNTRY_LIST_SUCCESS_ACTION,
        response
    )
export const localizationCountryListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.COUNTRY_LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.COUNTRY_LIST_FAILURE_ACTION,
        { error }
    )
export const localizationCountryListResetAction = () =>
    action<ActionTypes.COUNTRY_LIST_RESET_ACTION>(ActionTypes.COUNTRY_LIST_RESET_ACTION)

export const localizationResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
