import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import OrderActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import {
    ordersLastFailureAction,
    ordersLastSuccessAction,
    ordersListFailureAction,
    ordersListSuccessAction,
    ordersDocumentFailureAction,
    ordersDocumentSuccessAction,
    ordersPersistedParamsAction,
    ordersPersistParamsAction,
    ordersResetCollectionAction,
    ordersSwitchOrderModeAction,
    ordersCustomerSuccessAction,
    ordersCustomerFailureAction,
} from './actions'
import { IApiOrderListResponse, IApiOrderDocumentResponse, IOrderMode } from '../../services/api/service/orders/types'
import { formatAppError } from '../app/saga'
import CartActionTypes from '../carts/constants'

// TOKEN
import axios from 'axios'
import localforage from 'localforage'
import { IApiCustomerGetResponse, ICustomer } from '../../services/api/service/customers/types'
import { makeSelectCustomer } from '../customers/selectors'
import { ICartsPersistParameters } from '../carts/types'
import Config from '../../config'
import {
    IOrderLastProcessAction,
    IOrderListProcessAction,
    IOrdersCustomerProcessAction,
    IOrdersDocumentProcessAction,
    IOrdersPersistParameters,
    IOrdersPersistSettingsAction,
    IOrdersSwitchOrderModeAction,
} from './types'
import AuthActionTypes from '../auth/constants'
import CustomerActionTypes from '../customers/constants'
import { Undefinable } from 'tsdef'

const CancelToken = axios.CancelToken

export function* processInitializeOrderCustomers() {
    const customer: Undefinable<ICustomer> = yield select(makeSelectCustomer())
    if (!customer) {
        return
    }

    yield put(ordersCustomerSuccessAction(customer))
}

function* processOrdersRequest(action: IOrderListProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    try {
        const { params } = action.payload
        const response: IApiOrderListResponse = yield call({ context: api.orders, fn: 'list' }, params, source.token)
        yield put(ordersListSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(ordersListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processResetOrderCollection() {
    yield put(ordersResetCollectionAction())
}

export function* processOrderCustomerRequest(action: IOrdersCustomerProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { customer } = action.payload

    try {
        const response: IApiCustomerGetResponse = yield call({ context: api.customers, fn: 'get' }, customer)
        yield put(ordersCustomerSuccessAction(response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'order.document.error')
        yield put(ordersCustomerFailureAction(customer, error))
    }
}

function* processOrdersDocumentRequest(action: IOrdersDocumentProcessAction) {
    const source = CancelToken.source()
    const api: IApiClient = yield getContext('api')
    const { orderId, documentType } = action.payload

    try {
        const response: IApiOrderDocumentResponse = yield call(
            { context: api.orders, fn: 'document' },
            orderId,
            documentType,
            source.token
        )
        yield put(ordersDocumentSuccessAction(orderId, documentType, response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'order.document.error')
        yield put(ordersDocumentFailureAction(orderId, documentType, error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processLastOrdersRequest(action: IOrderLastProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    try {
        const { params } = action.payload

        const response: IApiOrderListResponse = yield call({ context: api.orders, fn: 'list' }, params, source.token)
        yield put(ordersLastSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(ordersLastFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processPersistedParams() {
    const api: IApiClient = yield getContext('api')
    const storage: typeof localforage = yield getContext('storage')

    try {
        const settings: IOrdersPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.ORDER.SETTINGS_STORAGE_NAME
        )
        yield call({ context: api, fn: 'setOrderMode' }, settings?.order_mode || undefined)
    } catch (e) {
        console.error(e)
    }
}

function* processPersistOrderMode(action: IOrdersSwitchOrderModeAction) {
    const { mode } = action.payload
    const api: IApiClient = yield getContext('api')

    yield call({ context: api, fn: 'setOrderMode' }, mode || undefined)
    yield put(ordersPersistParamsAction({ order_mode: mode }, true))
}

function* processPersistParams(action: IOrdersPersistSettingsAction) {
    const { params, merge } = action.payload

    try {
        const storage: typeof localforage = yield getContext('storage')
        if (!params) {
            yield call({ context: storage, fn: 'removeItem' }, Config.ORDER.SETTINGS_STORAGE_NAME)
            yield put(ordersPersistedParamsAction())
            return
        }
        if (!merge) {
            yield call({ context: storage, fn: 'setItem' }, Config.ORDER.SETTINGS_STORAGE_NAME, params)
            yield put(ordersPersistedParamsAction())
            return
        }

        // récupération item
        const settings: ICartsPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.ORDER.SETTINGS_STORAGE_NAME
        )
        const parameters = { ...settings, ...params }
        yield call({ context: storage, fn: 'setItem' }, Config.ORDER.SETTINGS_STORAGE_NAME, parameters)

        yield put(ordersPersistedParamsAction())
    } catch (e) {
        console.error(e)
    }
}

function* removeOrdersPersistParams() {
    try {
        const api: IApiClient = yield getContext('api')
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'removeItem' }, Config.ORDER.SETTINGS_STORAGE_NAME)
        yield call({ context: api, fn: 'setOrderMode' }, undefined)
    } catch (e) {}
}

export function* processInitializeOrderModeApi() {
    const api: IApiClient = yield getContext('api')
    const storage: typeof localforage = yield getContext('storage')
    const settings: IOrdersPersistParameters | undefined = yield call(
        { context: storage, fn: 'getItem' },
        Config.ORDER.SETTINGS_STORAGE_NAME
    )

    const mode = !settings ? IOrderMode.Order : settings.order_mode
    yield call({ context: api, fn: 'setOrderMode' }, mode || undefined)
}

export function* processSwitchOrderMode() {
    try {
        const api: IApiClient = yield getContext('api')
        const customer: ICustomer = yield select(makeSelectCustomer())
        const storage: typeof localforage = yield getContext('storage')
        const settings: IOrdersPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.ORDER.SETTINGS_STORAGE_NAME
        )

        const mode = !customer || !settings ? IOrderMode.Order : settings.order_mode
        yield call({ context: api, fn: 'setOrderMode' }, mode || undefined)

        if (!customer) {
            yield put(ordersSwitchOrderModeAction(IOrderMode.Order))
            return
        }

        if (!settings) {
            yield put(ordersSwitchOrderModeAction(IOrderMode.Order))
        } else {
            yield put(ordersSwitchOrderModeAction(settings.order_mode))
        }
    } catch (e) {}
}

function* removePersistParams() {
    try {
        const api: IApiClient = yield getContext('api')
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'removeItem' }, Config.ORDER.SETTINGS_STORAGE_NAME)
        yield call({ context: api, fn: 'setOrderMode' }, undefined)
    } catch (e) {}
}

export default [
    takeLatest(OrderActionTypes.LIST_PROCESS_ACTION, processOrdersRequest),
    takeLatest(OrderActionTypes.LAST_PROCESS_ACTION, processLastOrdersRequest),
    takeLatest(OrderActionTypes.DOCUMENT_PROCESS_ACTION, processOrdersDocumentRequest),
    takeLatest(OrderActionTypes.SWITCH_ORDER_MODE_ACTION, processPersistOrderMode),
    takeLatest(OrderActionTypes.PERSIST_PARAMS_ACTION, processPersistParams),
    takeLatest(OrderActionTypes.PERSISTED_PARAMS_ACTION, processPersistedParams),
    takeLatest(OrderActionTypes.CUSTOMER_PROCESS_ACTION, processOrderCustomerRequest),
    takeLatest(CustomerActionTypes.RESET_CURRENT_CUSTOMER_ACTION, removePersistParams),
    takeLatest(AuthActionTypes.LOGOUT_ACTION, removeOrdersPersistParams),
    takeLatest(CartActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION, processResetOrderCollection),
    takeLatest(CartActionTypes.SAVE_TO_ORDERS_SUCCESS_ACTION, processResetOrderCollection),
]
