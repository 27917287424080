/**
 *
 * CustomerLayout
 *
 */
import React, { MouseEvent, ReactNode, useMemo, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Banner from '../../components/Customer/Banner'
import { default as CustomerMenu } from '../../components/Customer/Menu'
import Config from '../../config'
import AssistanceModal from '../../containers/Assistance/AssistanceModal'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { authLogoutAction } from '../../store/auth/actions'
import { isMultiStoreHasStoreOrderAvailable, isOrderAvailable } from '../../store/carts/utils'
import { makeSelectCustomer, makeSelectCustomerStore, makeSelectCustomerStoreId } from '../../store/customers/selectors'
import { getExcludedCustomerMenuItems, getRestrictedCustomerMenuItems } from '../../store/customers/utils'
import { ICustomerMenuItem, ICustomerMenuItemType, ICustomerMenuLocation } from '../../types/customer'
import { IApplicationRootState } from '../../store'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { StrictCartMode } from '../../store/carts/types'

interface IProps {
    children?: ReactNode
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    store: makeSelectCustomerStore(),
    currentStoreId: makeSelectCustomerStoreId(),
    cartMode: makeSelectCartMode(),
})

// permet de faire du portal dans les pages enfants
export const CustomerLayoutRowTopId = 'member-area-row-top'

function CustomerLayout({ children }: IProps): JSX.Element {
    const { customer, currentStoreId, store, me, cartMode } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
            customer?: ICustomer
            store?: ICustomer
            currentStoreId?: string
            cartMode: StrictCartMode
        }
    >(stateSelector)
    const { locale, formatMessage } = useIntl()
    const dispatch = useDispatch()
    const [helpShown, setHelpShown] = useState<boolean>(false)
    const handleHelpHide = () => setHelpShown(false)
    const customerMenuOnly = customer
        ? getRestrictedCustomerMenuItems(customer, ICustomerMenuLocation.Area, store, me)
        : undefined
    const customerMenuExclude = customer
        ? getExcludedCustomerMenuItems(customer, ICustomerMenuLocation.Area, store, me)
        : undefined

    const handleOnItemClick = (e: MouseEvent, item: ICustomerMenuItem) => {
        if (item.type === ICustomerMenuItemType.Assistance) {
            setHelpShown(true)
        } else if (item.type === ICustomerMenuItemType.Logout) {
            e.preventDefault()
            e.stopPropagation()
            dispatch(authLogoutAction(false))
        }
    }

    const customerAreaLabel = formatMessage({ id: 'default.my_personal_place' })
    const orderNotAvailableMessageId = useMemo(() => {
        if (!customer || isOrderAvailable(customer, store, cartMode)) {
            return null
        }
        if (
            customer.account_type === ICustomerType.MultiStore &&
            !store &&
            isMultiStoreHasStoreOrderAvailable(customer)
        ) {
            return null
        }

        if (isSalesmanResource(me)) {
            return 'salesman.customer_order_not_available'
        }

        return 'customer.order_not_available'
    }, [customer, store, cartMode, me])
    return (
        <>
            <Helmet
                titleTemplate={`%s - ${customerAreaLabel} - ${Config.APP_NAME}`}
                defaultTitle={`${customerAreaLabel} - ${Config.APP_NAME}`}
            >
                <html lang={locale} />
            </Helmet>
            <Container className={'member-area'}>
                {orderNotAvailableMessageId && (
                    <Row>
                        <Col>
                            <Alert variant={'info'}>
                                <p className="mb-0">
                                    <FormattedMessage id={orderNotAvailableMessageId} />
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={'auto'} className={'member-area-sidebar'}>
                        <CustomerMenu
                            me={me}
                            customer={customer}
                            onItemClick={handleOnItemClick}
                            className={'member-area-menu'}
                            only={customerMenuOnly}
                            exclude={customerMenuExclude}
                        />
                    </Col>
                    <Col>
                        <Row id={CustomerLayoutRowTopId}>
                            <Col>{customer && <Banner customer={customer} storeId={currentStoreId} me={me} />}</Col>
                        </Row>
                        {children}
                    </Col>
                </Row>
                <AssistanceModal show={helpShown} onHide={handleHelpHide} onExit={handleHelpHide} />
            </Container>
        </>
    )
}

export default CustomerLayout
