/**
 *
 * Picker Action Picture
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IPickerActionComponentProps } from '../../../../types/productPicker'
import Button from '../../../../components/Buttons/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import isNumber from 'lodash/isNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Overlay, Popover } from 'react-bootstrap'
import classNames from 'classnames'
import {
    productsListRefreshAction,
    productsSelectionApplyActionProcess,
    productsSelectionResetAction,
} from '../../../../store/products/actions'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductsListSelectionError,
    makeSelectProductsListSelectionProcessed,
    makeSelectProductsListSelectionProcessing,
    makeSelectProductsListSelectionSuccess,
} from '../../../../store/products/selectors'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'
import { StockAlertOrigin } from '../../../../store/stockAlert/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import ReactDOM from 'react-dom'

const MOUNT_NODE = document.body as HTMLElement
const stateSelector = createStructuredSelector<any, any>({
    processing: makeSelectProductsListSelectionProcessing(),
    processed: makeSelectProductsListSelectionProcessed(),
    error: makeSelectProductsListSelectionError(),
    successMessage: makeSelectProductsListSelectionSuccess(),
})

function PickerActionRemoveArrival({ action, items }: IPickerActionComponentProps): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { min = 1, max } = action.options
    const target = useRef() as React.MutableRefObject<HTMLButtonElement>
    const [warnings, setWarnings] = useState<Array<string>>([])
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [showSwal, setShowSwal] = useState<boolean>(false)
    const disabled = useMemo(() => {
        return isNumber(min) && min > items.length
    }, [min, items])

    const { processed, processing, error, successMessage } = useSelector<
        IApplicationRootState,
        {
            processing: boolean
            processed: boolean
            error?: IAppErrorTypes
            successMessage?: string
        }
    >(stateSelector)

    useEffect(() => {
        const warns: Array<string> = []
        if (isNumber(min) && items.length < min) {
            warns.push(formatMessage({ id: 'product_picker.min_warning' }, { min: min }))
        } else if (isNumber(max) && items.length > max) {
            warns.push(formatMessage({ id: 'product_picker.max_warning' }, { max: max }))
        }
        setWarnings(warns)
    }, [min, max, items, setWarnings, formatMessage])

    useEffect(() => {
        setShowSwal(processed)
    }, [processed, successMessage, error, setShowSwal])

    const handleMouseEnter = useCallback((): void => {
        setShowTooltip(warnings.length > 0)
    }, [setShowTooltip, warnings])
    const handleMouseLeave = useCallback((): void => {
        setShowTooltip(false)
    }, [setShowTooltip])

    const onClick = useCallback(() => {
        if (disabled) {
            return
        }
        // dispatch pour dire qu'on est en processing
        dispatch(
            productsSelectionApplyActionProcess({
                productIds: items,
                subscribe: false,
                origin: StockAlertOrigin.Picker,
            })
        )
    }, [disabled, items, dispatch])

    const handleSwalConfirm = useCallback(() => {
        if (disabled) {
            return
        }
        if (processed) {
            dispatch(productsListRefreshAction())
            dispatch(productsSelectionResetAction())
        }
        setShowSwal(false)
    }, [disabled, processed, setShowSwal, dispatch])

    return (
        <>
            <Button
                ref={target}
                variant="primary"
                onClick={onClick}
                className={classNames({ inactive: disabled })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                disabled={processing || processed || typeof error !== 'undefined'}
            >
                <FontAwesomeIcon icon={'trash'} className={'app-icon'} />
                <FormattedMessage id="product_picker.remove_from_arrivals" />
            </Button>
            <Overlay target={target.current} show={showTooltip}>
                {(props) => (
                    <Popover id="warnings-tooltip" {...props}>
                        <Popover.Content>
                            {warnings.map((warning) => (
                                <p className="invalid-feedback" key={warning}>
                                    {warning}
                                </p>
                            ))}
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
            {ReactDOM.createPortal(
                <>
                    <SweetAlert
                        show={showSwal}
                        // error={typeof error !== 'undefined'}
                        // success={typeof error === 'undefined' && processed}
                        success
                        title={formatMessage({
                            id:
                                typeof error === 'undefined' && processed
                                    ? 'product_picker.your_selection'
                                    : 'default.error',
                        })}
                        onConfirm={handleSwalConfirm}
                    >
                        {/*{successMessage || error?.message}*/}
                        {successMessage}
                    </SweetAlert>
                </>,
                MOUNT_NODE
            )}
        </>
    )
}

export default memo(PickerActionRemoveArrival)
