/*
 *
 * Pagination
 *
 */

import paginator from 'paginator'
import React, { useMemo } from 'react'
import { default as Component } from '../../../components/Products/Content/Counter'
import Config from '../../../config'
import { useProductListParsedQuery } from '../useParsedQuery'

interface IProps {
    loading: boolean
    count?: number
}

const pageRangeDisplayed = Config.PRODUCT_LIST.PAGE_RANGE_DISPLAYED

function Counter({ loading, count }: IProps): JSX.Element {
    const { queries } = useProductListParsedQuery()
    const multipage = useMemo(() => {
        if (typeof count === 'undefined' || !queries) {
            return undefined
        }
        return new paginator(queries.itemsPerPage!, pageRangeDisplayed).build(count, queries.page!)
    }, [count, queries])

    const show = useMemo(() => {
        return loading || (typeof multipage !== 'undefined' && typeof count === 'number' && count > 0)
    }, [loading, multipage, count])

    const disabled = useMemo(() => {
        return loading && typeof multipage === 'undefined'
    }, [loading, multipage])

    return (
        <Component
            show={show}
            disabled={disabled}
            start={multipage?.first_result}
            end={multipage?.last_result}
            total={multipage?.total_results}
            labelId={'products.total_plural'}
        />
    )
}

Counter.defaultProps = {} as Partial<IProps>

export default Counter
