import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../store'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import SalesmanProspects from '../containers/SalesmanProspects/SalesmanProspects'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

function SalesmanProspectsPage(): JSX.Element {
    const { formatMessage } = useIntl()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    if (!isSalesmanResource(me)) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'salesman.prospects' })}</title>
            </Helmet>
            <div className={'salesman-customers-page'} id={'salesman-customers-page'}>
                <SalesmanProspects />
            </div>
        </>
    )
}

export default SalesmanProspectsPage
