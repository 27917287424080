/**
 *
 * TabbedCarousel
 *
 */
import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { Container, Nav, Tab } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { CmsWidgetTheme, CmsWidgetType, ICmsWidgetHighlightCategory } from '../../../../services/api/service/cms/types'
import { IProductList, ProductType } from '../../../../services/api/service/products/types'
import { ProductClickCallback } from '../../../../types/productCallback'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import { generateProductUrl } from '../../../../utils/productHelper'
import { default as ProductCarousel } from '../../../../components/Product/Carousel/Carousel'
import { createStructuredSelector } from 'reselect'

import { makeSelectCustomer, makeSelectCustomerStore } from '../../../../store/customers/selectors'
import { makeSelectAuthMe } from '../../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../../store'
import { ICustomer } from '../../../../services/api/service/customers/types'
import { IMe } from '../../../../services/api/service/me/types'
import { customerShowPrices } from '../../../../store/customers/utils'

interface IProps {
    widget: ICmsWidgetHighlightCategory
    onProductClick?: ProductClickCallback
    onLinkClick?: CmsWidgetLinkClickCallback
}

const breakpoints = {
    720: {
        slidesPerView: 3,
        spaceBetween: 15,
    },
    960: {
        slidesPerView: 3,
        spaceBetween: 30,
    },
    1200: {
        slidesPerView: 5,
    },
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
})

function TabbedCarousel({ widget, onProductClick, onLinkClick }: IProps): JSX.Element {
    const { locale, formatMessage } = useIntl()
    const { customer, me } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store?: ICustomer
            me: IMe
        }
    >(stateSelector)

    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    const handleProductClick = (product: ProductType<IProductList>) => {
        if (onProductClick) {
            onProductClick(product)
        }
        if (onLinkClick) {
            onLinkClick({
                widget: CmsWidgetType.HighlightCategory,
                url: generateProductUrl(product.id, locale, false),
                internal: true,
                blank: false,
                text: product.name,
                extra: product,
            })
        }
    }

    return (
        <div
            className={classNames(
                'cms-widget',
                'cms-widget-spacing',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                {
                    'multiple-category': widget.configuration.categories.length > 1,
                    'single-category': widget.configuration.categories.length === 1,
                },
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <Tab.Container defaultActiveKey={`highlight_category_0_tab_pane`}>
                    <Container>
                        {widget.configuration.title && (
                            <div className={'heading'}>
                                <p className={'h1 hv'}>{widget.configuration.title}</p>
                                {widget.configuration.subtitle && (
                                    <p className={'h6'}>{widget.configuration.subtitle}</p>
                                )}
                            </div>
                        )}
                        <Nav variant="pills">
                            {widget.configuration.categories.map((category, index) => (
                                <Nav.Item key={`highlight_category_${index}_nav_item`}>
                                    <Nav.Link eventKey={`highlight_category_${index}_tab_pane`}>
                                        {category.name}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Container>
                    <Tab.Content>
                        {widget.configuration.categories.map((category, index) => (
                            <Tab.Pane
                                eventKey={`highlight_category_${index}_tab_pane`}
                                key={`highlight_category_${index}_tab_pane`}
                                unmountOnExit
                            >
                                <ProductCarousel
                                    showPrices={showPrices}
                                    products={category.products}
                                    breakpoints={breakpoints}
                                    detail={{
                                        widget: CmsWidgetType.HighlightCategory,
                                        url: category.url,
                                        internal: true,
                                        blank: false,
                                        text:
                                            category.button_label ||
                                            formatMessage({ id: 'products.see_all_selection' }),
                                    }}
                                    onProductClick={handleProductClick}
                                    onDetailButtonClick={onLinkClick}
                                />
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default memo(TabbedCarousel)
