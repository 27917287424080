/**
 *
 * ScrollToTop
 *
 */
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import React, { memo, MutableRefObject, useCallback, useEffect, useState } from 'react'
import { Easing, ScrollTo } from 'scroll-to-position'
import useWindowSize from '../../utils/hook/useWindowSize'
import Button from '../Buttons/Button'
import FlatIcon from '../Icon/FlatIcon'

interface IProps {
    stickyRef: MutableRefObject<HTMLElement>
    threshold?: number
    offsetBottom?: number
    disabled?: boolean
    bottom: number | string
}

function ScrollToTop({ stickyRef, disabled, threshold, bottom, offsetBottom }: IProps): JSX.Element {
    const { height } = useWindowSize()
    const [show, setShow] = useState<boolean>(false)
    const [scrolling, setScrolling] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/ban-types
    const [style, setStyle] = useState<object>({ bottom: 0 })

    const handleClick = useCallback(() => {
        setScrolling(true)
        ScrollTo([0, 0], {
            easing: Easing.easeInSine,
            duration: [200, 1500],
        })
            .then(() => setScrolling(false))
            .catch(() => setScrolling(false))
        setShow(false)
    }, [setShow, setScrolling])

    useEffect(() => {
        let finalValue: number = typeof bottom === 'string' ? parseInt(bottom) : bottom
        const safeAreaBottom: number =
            parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) || 0

        if (finalValue === 0) {
            finalValue = safeAreaBottom + (safeAreaBottom > 0 ? 20 : offsetBottom!)
        } else {
            finalValue += offsetBottom!
            finalValue -= safeAreaBottom
            if (finalValue < safeAreaBottom) {
                finalValue = safeAreaBottom
            }
        }
        setStyle({ bottom: `${finalValue}px` })
    }, [bottom, offsetBottom, setStyle])

    useScrollPosition(
        ({ currPos }) => {
            if (typeof height === 'undefined' || scrolling) {
                return
            }
            const isShow = Math.abs(currPos.y) > height * threshold!
            setShow(isShow)
        },
        [height, threshold, setShow, scrolling, stickyRef],
        stickyRef,
        false,
        150
    )

    return (
        <Button
            variant={'primary'}
            className={classNames('btn-scroll-to-top', { 'd-none': disabled, hide: !show })}
            onClick={handleClick}
            style={style}
        >
            <FlatIcon icon={'chevron-up'} />
        </Button>
    )
}

ScrollToTop.defaultProps = {
    disabled: false,
    threshold: 0.3,
    bottom: '45px',
    offsetBottom: 10,
} as Partial<IProps>

export default memo(ScrollToTop)
