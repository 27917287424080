import {
    ClassificationType,
    FamilyType,
    IAttributeDefinitionCollection,
    IBaseClassification,
    IBaseClassificationFamily,
    ICategory,
    IClassificationTypes,
    IDepartment,
    IFamily,
    IFamilyChild,
    IFamilyTreeCollection,
    IFamilyTypes,
    IMenuItem,
    IMenuItemCollection,
    IShippingLocation,
    IShippingLocationCollection,
    IShippingLocationTree,
    MenuItemType,
} from '../../services/api/service/classification/types'
import prop from '../../utils/prop'
import {
    IProductListFilterTree,
    IProductListParameters,
    IProductListPersistParameters,
    ProductListStaticFilterCodes,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../../services/api/service/products/types'
import { IProductListQueryParameters } from '../products/utils'
import { ISlideMenuItem, ISlideMenuItemCollection } from './types'
import isExternalRegexClosure from '../../utils/location'
import { ICustomer } from '../../services/api/service/customers/types'
import { IProductListUserFilterCollection } from '../products/types'
import cloneDeep from 'lodash/cloneDeep'
import { uniqueId } from 'lodash'

type ShippingLocationKeys = keyof IShippingLocation
type DepartmentKeys = keyof IDepartment
type ClassificationKeys = keyof IBaseClassificationFamily
type BaseClassificationKeys = keyof IBaseClassification
type MenuItemKeys = keyof IMenuItem

function isShippingLocation(familyItem: IFamilyTypes): familyItem is IShippingLocation {
    return familyItem['@type'] === ClassificationType.ShippingLocation
}

function formatFamily(family: IFamilyChild, parent: IBaseClassificationFamily) {
    const parentIds = parent.parent_ids ? parent.parent_ids.slice() : []
    parentIds.push(parent['@id'])

    const fam = {
        ...family,
        url: `${parent.url}/${family.slug}`,
        sub_families: [],
        parent_ids: parentIds,
    } as IFamilyChild

    family.sub_families.forEach((sub) => {
        fam.sub_families.push(formatFamily(sub, fam))
    })
    return fam
}

function formatDepartments(departments: Array<IDepartment>, parent: IShippingLocation): Array<IDepartment> {
    const items: Array<IDepartment> = []
    departments.forEach((department) => {
        const itm = {
            ...department,
            type: FamilyType.Department, // override => modélisation de l'api fait qu'on est en famille sauf que nous on doit différencier tout ça
            url: `${parent.url}/${department.slug}`,
            parent_ids: [parent['@id']],
            sub_families: [],
        } as IDepartment
        department.sub_families?.forEach((fam) => {
            itm.sub_families.push(formatFamily(fam, itm))
        })
        items.push(itm)
    })
    return items
}

export function formatMenu(menu: IMenuItemCollection, locale: string): IMenuItemCollection {
    const formatMenuEntry = (item: IMenuItem, locale: string, parent?: IMenuItem): IMenuItem => {
        let parentIds: Array<string> = []
        if (parent && parent.parent_ids) {
            parentIds = parent.parent_ids.slice()
            parentIds.push(parent['@id'])
        }

        // parsage de l'url
        let parsedUrl: undefined | URL = undefined
        if (item.urls && item.urls[locale]) {
            parsedUrl = new URL(window.location.protocol + item.urls[locale])
        }

        const itm = {
            ...item,
            children: [],
            parent_ids: parentIds,
            url: parsedUrl ? parsedUrl.pathname : '',
        } as IMenuItem

        if (item.children) {
            item.children.forEach((sub) => {
                if (sub.urls[locale]) {
                    itm.children!.push(formatMenuEntry(sub, locale))
                }
            })
        }
        return itm
    }

    const items: IMenuItemCollection = []
    menu.forEach((item) => {
        if (item.urls && item.urls[locale]) {
            items.push(formatMenuEntry(item, locale))
        }
    })

    return items
}

export function formatShippingLocation(
    shippingLocation: IShippingLocation,
    parent?: IShippingLocation,
    basePath?: string,
    virtual?: boolean
): IShippingLocation {
    let parentIds: Array<string> = []
    if (parent) {
        parentIds = [...parent.parent_ids]
        parentIds.push(parent['@id'])
    }

    const slocation: IShippingLocation = {
        ...shippingLocation,
        url:
            typeof shippingLocation.url !== 'undefined' && typeof basePath === 'undefined'
                ? shippingLocation.url
                : `${basePath || ''}/${shippingLocation.slug}`,
        tree: [],
        parent_ids: parentIds,
    }

    if (shippingLocation.tree) {
        shippingLocation.tree.forEach((subItem) => {
            if (subItem['@type'] === ClassificationType.ShippingLocation) {
                const sub = formatShippingLocation(subItem as IShippingLocation, slocation, slocation.url, virtual)
                slocation.tree.push(sub)
            } else {
                const subs = formatDepartments([subItem as IDepartment], slocation)
                subs.forEach((department) => {
                    slocation.tree.push(department)
                })
            }
        })
    }

    return slocation
}

export function formatFamilyTree(familyTree: IFamilyTreeCollection, basePath?: string): IFamilyTreeCollection {
    const tree: IFamilyTreeCollection = []
    familyTree.forEach((item) => {
        // // push
        tree.push(formatShippingLocation(item, undefined, basePath))
    })
    return tree
}

export function formatDisplayableFamilyTree(
    familyTree: IFamilyTreeCollection,
    virtual?: boolean
): IFamilyTreeCollection {
    const tree: IFamilyTreeCollection = []
    const mergeTree = (a: Array<any>, ...b: Array<any>): IShippingLocationTree => {
        // @ts-ignore
        return b.length ? (a.length ? [a[0], ...mergeTree(...b, a.slice(1))] : mergeTree(...b)) : a
    }

    // dans le cas où on a rien, on retourne le family tree par défaut
    if (!virtual) {
        return familyTree
    }

    familyTree.forEach((sl) => {
        if (!sl.virtual) {
            tree.push(sl)
        } else {
            let subTree: IShippingLocationTree = []

            if (sl.tree) {
                const entries: Array<IShippingLocationTree> = []
                sl.tree.forEach((sl2) => {
                    if (isShippingLocation(sl2)) {
                        entries.push([...sl2.tree])
                    }
                })
                if (entries.length === 1) {
                    subTree = [...entries[0]]
                } else if (entries.length > 1) {
                    // @ts-ignore
                    subTree = mergeTree(...entries)
                }
            }

            // on doit merger les sous shipping location
            const slocation: IShippingLocation = {
                ...sl,
                tree: subTree,
            }

            tree.push(slocation)
        }
    })

    return tree
}

export function findShippingLocationBy(
    familyTree: IFamilyTreeCollection,
    propertyName: ShippingLocationKeys,
    value: any
): IShippingLocation | null {
    let item: IShippingLocation | null = null
    slocation_loop: for (let i = 0; i < familyTree.length; i++) {
        const fitem = familyTree[i]
        if (fitem['@type'] !== ClassificationType.ShippingLocation) {
            continue
        }
        const slocation = fitem as IShippingLocation
        if (prop(slocation, propertyName) === value) {
            item = slocation
            break slocation_loop
        }
        sub_location_loop: for (let j = 0; j < slocation.tree.length; j++) {
            const itm = slocation.tree[j]
            if (itm['@type'] === ClassificationType.ShippingLocation) {
                const sb = itm as IShippingLocation
                if (prop(sb, propertyName) === value) {
                    item = sb
                    break sub_location_loop
                }
            }
        }

        if (item) {
            break slocation_loop
        }
    }
    return item
}

export function findDepartmentBy(
    familyTree: IFamilyTreeCollection,
    propertyName: DepartmentKeys,
    value: any
): IDepartment | undefined {
    let item: IDepartment | undefined = undefined
    location_loop: for (let i = 0; i < familyTree.length; i++) {
        const slocation = familyTree[i]
        for (let j = 0; j < slocation.tree.length; j++) {
            const child = slocation.tree[j]
            if (child['@type'] === ClassificationType.ShippingLocation) {
                continue
            }
            const department: IDepartment = child as IDepartment
            if (prop(department, propertyName) === value) {
                item = department
                break location_loop
            }
        }
    }
    return item
}

export function findFamilyBy(
    familyTree: IFamilyTreeCollection,
    propertyName: ClassificationKeys,
    value: any
): IFamilyTypes | undefined {
    const findFamilyItem = (
        families: Array<IFamilyChild>,
        subPropertyName: ClassificationKeys,
        subValue: any
    ): IFamilyChild | undefined => {
        let familyFound: IFamilyChild | undefined = undefined
        for (let k = 0; k < families.length; k++) {
            const family = families[k]
            if (prop(family, subPropertyName) === subValue) {
                familyFound = family
                break
            }
            if (family.sub_families) {
                familyFound = findFamilyItem(family.sub_families, subPropertyName, subValue)
                if (familyFound) {
                    break
                }
            }
        }
        return familyFound
    }

    const parseShippingLocation = (child: IShippingLocation): IBaseClassificationFamily | undefined => {
        let item: IBaseClassificationFamily | undefined = undefined
        plocation_loop: for (let i = 0; i < child.tree.length; i++) {
            const itm = child.tree[i]
            if (itm['@type'] === ClassificationType.ShippingLocation) {
                const subItm = parseShippingLocation(itm as IShippingLocation)
                if (subItm) {
                    item = subItm
                    break plocation_loop
                }
            } else {
                const department = itm as IDepartment
                if (prop(department, propertyName) === value) {
                    item = department
                }
                if (!item) {
                    item = findFamilyItem(department.sub_families, propertyName, value)
                }
                if (item) {
                    break plocation_loop
                }
            }
        }

        return item
    }

    let item: IBaseClassificationFamily | undefined = undefined

    location_loop: for (let i = 0; i < familyTree.length; i++) {
        const subItem = parseShippingLocation(familyTree[i])
        if (subItem) {
            item = subItem
            break location_loop
        }
    }

    return item
}

export function formatSideMenuItems(
    familyTree: IFamilyTreeCollection,
    menu: IMenuItemCollection,
    baseFamilyPath?: string
): ISlideMenuItemCollection {
    const items: ISlideMenuItemCollection = []
    const level = 1
    const formatFamilyMenuItem = (item: IClassificationTypes, level: number): ISlideMenuItem => {
        let currentLevel = level
        let itm: ISlideMenuItem = {
            id: item.id,
            label: item.label,
            className: `family-tree-entry family-tree-entry-${item['@type']}`,
            url: item.url ? `${baseFamilyPath}${item.url}` : undefined,
            level: currentLevel,
        }

        if (item.url && isExternalRegexClosure(item.url)) {
            itm = {
                ...itm,
                external: true,
            }
        }

        if (item['@type'] === ClassificationType.ShippingLocation) {
            const sl = item as IShippingLocation
            if (sl.tree && sl.tree.length > 0) {
                itm = {
                    ...itm,
                    show: true,
                    children: [],
                }
                currentLevel += 1
                sl.tree.forEach((slSub) => {
                    itm.children?.push(formatFamilyMenuItem(slSub, currentLevel))
                })
            }
        } else if (item['@type'] === ClassificationType.Family) {
            const fl = item as IFamily
            if (fl.sub_families && fl.sub_families.length > 0) {
                itm = {
                    ...itm,
                    show: true,
                    children: [],
                }
                currentLevel += 1
                fl.sub_families.forEach((flSub) => {
                    itm.children?.push(formatFamilyMenuItem(flSub, currentLevel))
                })
            }
        }

        return itm
    }

    const formatSideMenuItem = (item: IMenuItem, level: number): ISlideMenuItem => {
        let currentLevel = level
        const uniqID = uniqueId('slide_menu_item')

        let itm: ISlideMenuItem = {
            // BAH OUAI
            id: item.parent_ids ? item.parent_ids.join('_') + '_' + uniqID : uniqID,
            label: item.label,
            url: item.url,
            level: level,
            badge: item.badge,
            type: item.type,
        }

        if (item.url && isExternalRegexClosure(item.url)) {
            itm = {
                ...itm,
                external: true,
            }
        }

        if (item.children && item.children.length > 0) {
            itm = {
                ...itm,
                show: item.type !== MenuItemType.Category,
                children: [],
            }

            currentLevel += 1
            item.children.forEach((sub) => {
                itm.children?.push(formatSideMenuItem(sub, currentLevel))
            })
        }

        if (item.type === MenuItemType.Tree) {
            itm = {
                ...itm,
                show: true,
                children: [...(itm.children || [])],
            }
            currentLevel += 1
            familyTree.forEach((item) => {
                itm.children?.push(formatFamilyMenuItem(item, currentLevel))
            })
        }

        return itm
    }

    menu.forEach((item) => {
        items.push(formatSideMenuItem(item, level))
    })

    return items
}

type SlideMenuItemKeys = keyof ISlideMenuItem
export function findSlideMenuItemBy(
    menu: ISlideMenuItemCollection,
    propertyName: SlideMenuItemKeys,
    value: any
): ISlideMenuItem | null {
    const findMenuItem = (
        subs: ISlideMenuItemCollection,
        subPropertyName: SlideMenuItemKeys,
        subValue: any
    ): ISlideMenuItem | null => {
        let itemFound: ISlideMenuItem | null = null
        for (let k = 0; k < subs.length; k++) {
            const subItem = subs[k]
            if (prop(subItem, subPropertyName) === subValue) {
                itemFound = subItem
                break
            }
            if (subItem.children) {
                itemFound = findMenuItem(subItem.children, subPropertyName, subValue)
                if (itemFound) {
                    break
                }
            }
        }
        return itemFound
    }

    return findMenuItem(menu, propertyName, value)
}

export function extractShippingLocations(familyTree: IFamilyTreeCollection): IShippingLocationCollection {
    // on formatte
    const locations: IShippingLocationCollection = []
    familyTree.forEach((item) => {
        if (isShippingLocation(item)) {
            let hasChildren = false
            item.tree.forEach((subItem) => {
                if (isShippingLocation(subItem)) {
                    hasChildren = true
                    // on supprime les enfants parents, etc ...
                    const subSp: IShippingLocation = {
                        ...subItem,
                        tree: [],
                    }
                    locations.push(subSp)
                }
            })
            if (!hasChildren) {
                const sp = {
                    ...item,
                    tree: [],
                }
                locations.push(sp)
            }
        }
    })
    return locations
}

export function findMenuItemBy(menu: IMenuItemCollection, propertyName: MenuItemKeys, value: any): IMenuItem | null {
    const findMenuItem = (subs: Array<IMenuItem>, subPropertyName: MenuItemKeys, subValue: any): IMenuItem | null => {
        let itemFound: IMenuItem | null = null
        for (let k = 0; k < subs.length; k++) {
            const subItem = subs[k]
            if (prop(subItem, subPropertyName) === subValue) {
                itemFound = subItem
                break
            }
            if (subItem.children) {
                itemFound = findMenuItem(subItem.children, subPropertyName, subValue)
                if (itemFound) {
                    break
                }
            }
        }
        return itemFound
    }

    return findMenuItem(menu, propertyName, value)
}

export function findFamilyTreeItemBy(
    familyTree: IFamilyTreeCollection,
    propertyName: BaseClassificationKeys,
    value: any
): IFamilyTypes | undefined {
    const shippingLocation = findShippingLocationBy(familyTree, propertyName, value)
    if (shippingLocation) {
        return shippingLocation
    }

    const department = findDepartmentBy(familyTree, propertyName, value)
    if (department) {
        return department
    }

    const family = findFamilyBy(familyTree, propertyName, value)
    if (family) {
        return family
    }

    return undefined
}

export type DepartmentChoiceOption = { value: string; label: string; entity: IDepartment }
export type DepartmentChoiceOptions = Array<DepartmentChoiceOption>
export type DepartmentChoiceGroup = { label: string; options: DepartmentChoiceOptions }
export type DepartmentChoices = Array<DepartmentChoiceGroup>

export function formatProductListFilterTree(
    item: IClassificationTypes | undefined,
    familyTree?: IFamilyTreeCollection | undefined,
    identifierKey: ProductListFilterTreeIdentifierKey = 'id'
): IProductListFilterTree | undefined {
    const tree: IProductListFilterTree = {}

    if (!item) {
        return undefined
    }

    if (item['@type'] === ClassificationType.Category) {
        tree.category = item[identifierKey]
        return tree
    } else if (item['@type'] === ClassificationType.ShippingLocation) {
        tree.shipping_location = item[identifierKey]
        return tree
    }

    const itm = item as IBaseClassificationFamily
    if (itm.type === FamilyType.Department) {
        tree.department = itm[identifierKey]
        return tree
    } else if (itm.type === FamilyType.Family) {
        if (!familyTree) {
            return undefined
        }
        tree.family = itm[identifierKey]

        let department: IDepartment | undefined
        item_loop: for (let i = 0; i < itm.parent_ids.length; i++) {
            const parentId = itm.parent_ids[i]
            const familyItem = findFamilyBy(familyTree!, '@id', parentId)
            if (familyItem && familyItem['@type'] === ClassificationType.Family) {
                if ((familyItem as IBaseClassificationFamily).type === FamilyType.Department) {
                    department = familyItem as IDepartment
                    break item_loop
                }
            }
        }

        if (department) {
            tree.department = department[identifierKey]
        }
    } else if (itm.type === FamilyType.SubFamily) {
        if (!familyTree) {
            return undefined
        }
        tree.sub_family = itm[identifierKey]

        let department: IDepartment | undefined
        let family: IFamily | undefined
        itm.parent_ids.forEach((parentId) => {
            const familyItem = findFamilyBy(familyTree!, '@id', parentId)
            if (familyItem) {
                if (familyItem['@type'] === ClassificationType.Family) {
                    if ((familyItem as IBaseClassificationFamily).type === FamilyType.Department) {
                        department = familyItem as IDepartment
                    } else if ((familyItem as IBaseClassificationFamily).type === FamilyType.Family) {
                        family = familyItem as IFamily
                    }
                }
            }
        })

        if (family) {
            tree.family = family[identifierKey]
        }
        if (department) {
            tree.department = department[identifierKey]
        }
    }

    return tree
}

type ProductListFilterTreeIdentifierKey = keyof Pick<IClassificationTypes, 'id' | '@id'>
export function formatProductListFilterTreeToBulkCartQuantityTree(
    tree?: IProductListFilterTree | undefined,
    familyTree?: IFamilyTreeCollection | undefined,
    category?: ICategory | undefined
): IProductListFilterTree | undefined {
    if (!tree || !familyTree) {
        return undefined
    }

    if (tree.category) {
        if (!category) {
            return undefined
        }
        return {
            category: category['@id'],
        }
    } else if (tree.sub_family || tree.department || tree.shipping_location) {
        const identifier = tree.sub_family || tree.department || tree.shipping_location
        const item = findFamilyTreeItemBy(familyTree, 'id', identifier)
        return formatProductListFilterTree(item, familyTree, '@id')
    }
}

export function productListAppendModeToFilters(
    filters: IProductListUserFilterCollection,
    mode: ProductsListMode
): IProductListUserFilterCollection {
    if (mode === ProductsListMode.BestSeller) {
        filters[ProductListStaticFilterCodes.BestSellers] = 1
    } else if (mode === ProductsListMode.Favorite) {
        filters[ProductListStaticFilterCodes.Favorite] = 1
    } else if (mode === ProductsListMode.MyListing) {
        filters[ProductListStaticFilterCodes.MyListing] = 1
    } else if (mode === ProductsListMode.New) {
        filters[ProductListStaticFilterCodes.New] = 1
    } else if (mode === ProductsListMode.ArrivalStocks) {
        filters[ProductListStaticFilterCodes.ArrivalStocks] = 1
    } else if (mode === ProductsListMode.Discount) {
        filters[ProductListStaticFilterCodes.Discount] = 1
    }

    return filters
}

export function productListAppendListedOnlyToFilters(
    filters: IProductListUserFilterCollection,
    settings: IProductListPersistParameters
): IProductListUserFilterCollection {
    if (settings && settings.listed_only) {
        filters[ProductListStaticFilterCodes.ListedOnly] = 1
    }

    return filters
}

export function productListFormatQueriesToParams(
    mode: ProductsListMode,
    queries?: IProductListQueryParameters,
    tree?: IProductListFilterTree,
    settings?: IProductListPersistParameters
): IProductListParameters | undefined {
    if (!queries) {
        return undefined
    }

    let params: IProductListParameters = {}
    let filters = queries && queries.filters ? cloneDeep(queries.filters) : {}

    // application du mode de liste
    filters = productListAppendModeToFilters(filters, mode)

    if (settings) {
        filters = productListAppendListedOnlyToFilters(filters, settings)
    }

    params = {
        ...params,
        page: queries.page,
        itemsPerPage: queries.itemsPerPage,
        displayMode: queries.displayMode || ProductsListDisplayMode.Default,
        productGridId: queries.productGridId,
        filters: filters,
        tree,
    }

    return params
}

export function productListMassAddToCartEligibleFilters(
    definition: IAttributeDefinitionCollection
): IAttributeDefinitionCollection {
    return definition.filter((def) => typeof def.allow_mass_add_to_cart === 'boolean' && def.allow_mass_add_to_cart)
}

export function productListIsMassAddToCartEligible(
    definition: IAttributeDefinitionCollection,
    customer: ICustomer,
    params?: IProductListParameters,
    category?: ICategory
): boolean {
    if (!params) {
        return false
    }

    if (
        category &&
        category.allow_mass_add_to_cart &&
        params.tree &&
        params.tree.category &&
        params.tree.category === category.id
    ) {
        return true
    }

    if (params && params.filters && params.filters[ProductListStaticFilterCodes.ArrivalStocks]) {
        return true
    }

    // // récupération de tous les codes
    const codes = definition
        .filter((def) => typeof def.allow_mass_add_to_cart === 'boolean' && def.allow_mass_add_to_cart)
        .map((def) => def.code)
    return codes.length > 0
}
