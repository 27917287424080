/**
 *
 * Product Card
 *
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import FlatIcon from '../../Icon/FlatIcon'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import { QuantityOutOfStockReason } from '../QuantitySelector'

export type RestockAlertSubscriptionChangeCallback = (subscribed: boolean) => void
export enum RestockAlertMode {
    Button = 'button',
    Insert = 'insert',
}

interface IProps {
    mode?: RestockAlertMode
    reason?: QuantityOutOfStockReason
    subscribed?: boolean
    readonly?: boolean
    onChange?: RestockAlertSubscriptionChangeCallback
    errors?: Array<string>
}

function ReStockAlert({
    mode = RestockAlertMode.Button,
    reason = QuantityOutOfStockReason.InsufficientStock,
    subscribed = false,
    readonly = false,
    errors = [],
    onChange,
}: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const buttonRef = useRef<HTMLButtonElement | null>(null)
    const [isSubscribed, setIsSubscribed] = useState(false)
    const variant = useMemo(() => {
        return isSubscribed ? 'outline-success' : 'outline-primary'
    }, [isSubscribed])

    const handleChange = useCallback(() => {
        if (onChange) {
            onChange(!isSubscribed)
        }
        setIsSubscribed((ste) => !ste)
        buttonRef.current?.blur()
    }, [isSubscribed, setIsSubscribed, onChange])

    const reasonMessage = useMemo(() => {
        if (reason === QuantityOutOfStockReason.OutOfStock) {
            return formatMessage({ id: 'product.out_of_stock' })
        } else if (reason === QuantityOutOfStockReason.InsufficientStock) {
            return formatMessage({ id: 'default.unavailable_wanted_stock' })
        } else if (reason === QuantityOutOfStockReason.MaxReached) {
            return formatMessage({ id: 'default.last_items_in_stock' })
        } else if (reason === QuantityOutOfStockReason.InvalidQuantity) {
            return formatMessage({ id: 'default.invalid_quantity_explain' })
        }

        return formatMessage({ id: 'default.victim_of_its_success' })
    }, [reason, formatMessage])

    const title = useMemo(() => {
        if (reason === QuantityOutOfStockReason.InsufficientStock) {
            return formatMessage({ id: 'restock_alert.insufficient_stock' })
        } else if (reason === QuantityOutOfStockReason.MaxReached) {
            return formatMessage({ id: 'default.last_items_in_stock' })
        } else if (reason === QuantityOutOfStockReason.InvalidQuantity) {
            return formatMessage({ id: 'default.invalid_quantity' })
        }

        return formatMessage({ id: 'default.victim_of_its_success' })
    }, [reason, formatMessage])

    const message = useMemo(() => {
        if (readonly) {
            return reasonMessage
        }
        return formatMessage({ id: 'restock_alert.scheduled_restock' })
    }, [reasonMessage, readonly, formatMessage])

    useEffect(() => {
        setIsSubscribed(subscribed)
    }, [setIsSubscribed, subscribed])

    return (
        <div className={classNames('quantity-selector-alert restock-alert', `mode-${mode}`)}>
            <span className="arrow" />
            <div className="body">
                {errors && errors.length > 0 && (
                    <div className="errors">
                        {errors.map((val) => (
                            <p className={'invalid-feedback'} key={val}>
                                {val}
                            </p>
                        ))}
                    </div>
                )}

                <h3 className="title">{title}</h3>
                <p>{message}</p>
            </div>
            {!readonly && (
                <div className="footer">
                    <Button ref={buttonRef} variant={variant} onClick={handleChange}>
                        <FlatIcon icon="bell" />
                        <span className="lbl">
                            <FormattedMessage id={isSubscribed ? 'restock_alert.active' : 'restock_alert.receive'} />
                        </span>
                    </Button>
                </div>
            )}
        </div>
    )
}

ReStockAlert.defaultProps = {} as Partial<IProps>

export default ReStockAlert
