import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { default as CmsPageComponent } from '../../components/CmsPage/CmsPage'
import { CmsPageIdentifierType } from '../../services/api/service/cms/types'
import { IApplicationRootState } from '../../store'
import { cmsPageProcessAction } from '../../store/cms/actions'
import { makeSelectCmsPage } from '../../store/cms/selectors'
import { ICmsPageState } from '../../store/cms/types'

interface IProps {
    children?(...nodes: React.ReactNodeArray): React.ReactNode
    identifierType?: CmsPageIdentifierType
    identifier: string
    preview?: boolean
}

function CmsPage({ identifierType, identifier, preview }: IProps): JSX.Element {
    const dispatch = useDispatch()

    const selectCmsPageWithIdentifier = useMemo(makeSelectCmsPage, [])
    const page: ICmsPageState = useSelector<IApplicationRootState, ICmsPageState>((state) =>
        selectCmsPageWithIdentifier(state, identifier, identifierType)
    )

    useEffect(() => {
        if (!page) {
            dispatch(cmsPageProcessAction(identifier, identifierType!))
        }
    }, [page, identifier, identifierType, dispatch])

    return (
        <>
            <CmsPageComponent item={page?.content} error={page?.error} fetching={page?.fetching} preview={preview} />
        </>
    )
}

CmsPage.defaultProps = {
    identifierType: CmsPageIdentifierType.Code,
    preview: false,
} as Partial<IProps>

export default CmsPage
