/**
 *
 * SplashScreen
 *
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectAutoDetectLocale } from '../../store/intl/selectors'

const stateSelector = createStructuredSelector<any, any>({
    locale: selectAutoDetectLocale,
})

function RedirectLanguage(): JSX.Element {
    const { locale } = useSelector(stateSelector)
    return <Redirect to={`/${locale}`} />
}

export default RedirectLanguage
