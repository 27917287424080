import React, { memo, useCallback, useMemo, useState } from 'react'
import { OrderDetailAction } from './type'
import { IOrderDetail } from '../../../services/api/service/orders/types'
import { useIntl } from 'react-intl'
import Button from '../../../components/Buttons/Button'
import { createStructuredSelector } from 'reselect'
import { makeSelectAuthMe } from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../store'
import { IMe } from '../../../services/api/service/me/types'
import { canDeclareAnomaly } from '../../../store/carts/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderAnomalyModal from '../../OrderAnomaly/Loadable'

type Props<T extends IOrderDetail = IOrderDetail> = OrderDetailAction & {
    order: T
    onHide?: (order: T) => void
    onDone?: (order: T) => void
}

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

const OrderAnomaly = ({
    order,
    label,
    size,
    icon,
    block = true,
    variant = 'secondary',
    onDone,
    onHide,
}: Props): JSX.Element => {
    const { formatMessage } = useIntl()
    const [showOrderAnomalyModal, setShowOrderAnomalyModal] = useState<boolean>(false)

    const { me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
        }
    >(stateSelector)

    const lbl = useMemo(() => {
        return label || formatMessage({ id: 'order_anomaly.missing_item_complaint' })
    }, [formatMessage, label])

    const allowedDeclareAnomaly = useMemo(() => {
        return order && canDeclareAnomaly(order, me)
    }, [me, order])

    const handleOrderReturnClick = useCallback(() => {
        setShowOrderAnomalyModal(true)
    }, [setShowOrderAnomalyModal])

    const handleModalHide = useCallback(() => {
        setShowOrderAnomalyModal(false)
        if (onHide) {
            onHide(order)
        }
    }, [setShowOrderAnomalyModal, onHide, order])

    const handleModalDone = useCallback(() => {
        setShowOrderAnomalyModal(false)
        if (onDone) {
            onDone(order)
        }
    }, [setShowOrderAnomalyModal, order, onDone])

    if (!allowedDeclareAnomaly) {
        return <></>
    }

    return (
        <>
            <Button block={block} variant={variant} onClick={handleOrderReturnClick} size={size}>
                {icon && <FontAwesomeIcon icon={icon} />}
                {lbl && <span className="lbl">{lbl}</span>}
            </Button>
            <OrderAnomalyModal
                show={showOrderAnomalyModal}
                resource={order}
                onHide={handleModalHide}
                onDone={handleModalDone}
            />
        </>
    )
}

export default memo(OrderAnomaly)
