import localforage from 'localforage'

const create = (name: string, version: number): typeof localforage => {
    return localforage.createInstance({
        name: name,
        version: version,
        description: 'u10 B2B',
    })
}

export default {
    create,
}
