import React, { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import AssistanceModal from '../../containers/Assistance/AssistanceModal'
import FlatIcon from '../Icon/FlatIcon'

function Advice(): JSX.Element {
    const [helpShown, setHelpShown] = useState<boolean>(false)
    const handleHelpHide = () => setHelpShown(false)
    const handleClick = () => setHelpShown(true)
    return (
        <div className="advice" onClick={handleClick}>
            <FlatIcon icon={'question'} />
            <FormattedMessage id={'default.need_help'} />
            <AssistanceModal show={helpShown} onHide={handleHelpHide} onExit={handleHelpHide} />
        </div>
    )
}

export default memo(Advice)
