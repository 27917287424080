import React, { memo } from 'react'
import Config from '../../config'
import useOnlineStatus from '../../utils/hook/useOnlineStatus'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

export type Props = {}

const Checker: React.FC<Props> = function () {
    const isOnline = useOnlineStatus(Config.API.PING_URL)

    return (
        <div className={classNames('top-network-reachability', { online: isOnline, offline: !isOnline })}>
            <FontAwesomeIcon icon={['fal', 'wifi-slash']} />
            <span className="lbl">
                <FormattedMessage id="network_reachability.offline" />
            </span>
        </div>
    )
}

export default memo(Checker)
