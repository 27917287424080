/**
 *
 * QuantitySelector
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { IShippingLocation } from '../../services/api/service/classification/types'
import ShippingLocation from '../Classification/ShippingLocation'
import FlatIcon from '../Icon/FlatIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IOrderMode } from '../../services/api/service/orders/types'

interface IProps {
    number?: number
    shippingLocation: IShippingLocation | undefined | null
    showShippingLocationLabel: boolean
    hasErrors: boolean
    orderMode: IOrderMode
    locked?: boolean
}

function Header({
    number,
    shippingLocation,
    showShippingLocationLabel,
    hasErrors,
    orderMode,
    locked = false,
}: IProps): JSX.Element {
    const hasNumber = typeof number !== 'undefined'

    return (
        <span className={'cart-header'}>
            {hasErrors && <FlatIcon icon={'warning'} className={'warning-icon'} />}
            {locked && <FontAwesomeIcon icon={['fal', 'lock']} className={'app-icon locked-icon'} />}
            {!hasErrors && !locked && <FlatIcon icon={'warehouse'} />}
            {hasNumber && (
                <>
                    <span className={'cart-number'}>
                        <FormattedMessage id={`cart.number_${orderMode}`} values={{ number }} />
                    </span>
                    <span className={'separator'}>|</span>
                </>
            )}
            {shippingLocation && (
                <ShippingLocation
                    shippingLocation={shippingLocation}
                    showLabel={showShippingLocationLabel}
                    labelId={'classification.transporter'}
                />
            )}
        </span>
    )
}

Header.defaultProps = {
    number: undefined,
    showShippingLocationLabel: true,
    hasErrors: false,
} as Partial<IProps>

export default memo(Header)
