/**
 *
 * AttributeSelector
 *
 */
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import {
    IProductGroupAttribute,
    IProductGroupAttributeCollection,
    IProductGroupAttributeValue,
    IProductGroupAttributeValueIdentifiers,
} from '../../../services/api/service/products/types'
import { findProductIdsFromProductGroupAttributes } from '../../../store/products/utils'
import { CollectionMap } from '../../../types/common'
import { ProductAttributeSelectorChangeCallback } from '../../../types/productCallback'
import ImagePlaceholder from '../../../assets/img/product-list-placeholder.png'

export enum AttributeSelectorMode {
    Radio = 'radio',
    List = 'list',
    Color = 'color',
}

interface IProps {
    disabled?: boolean
    attributeKey: string
    attributes: IProductGroupAttributeCollection
    selectedIds: IProductGroupAttributeValueIdentifiers
    onChange: ProductAttributeSelectorChangeCallback
}

function AttributeSelector({ attributeKey, attributes, selectedIds, disabled, onChange }: IProps): JSX.Element {
    let Selectors = <></>

    const attribute: IProductGroupAttribute = useMemo(() => {
        const values = attributes.filter((current) => current.attribute === attributeKey)
        return values[0]
    }, [attributeKey, attributes])

    const mode: AttributeSelectorMode = useMemo(() => {
        return attribute && attribute.attribute === 'color' ? AttributeSelectorMode.Color : AttributeSelectorMode.Radio
    }, [attribute])

    const handleOnInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!onChange) {
                return
            }
            const newIdentifier = parseInt(e.currentTarget.value)
            const selectedValues = attribute.values.filter(function (attrValue) {
                return attrValue.id === newIdentifier
            })
            if (selectedValues.length > 0) {
                onChange(attribute.attribute, selectedValues[0])
            }
        },
        [attribute, onChange]
    )

    // récupération de l'identifiant sélectionné
    const selectedId = useMemo(() => {
        return typeof selectedIds[attributeKey] !== 'undefined' ? selectedIds[attributeKey] : undefined
    }, [selectedIds, attributeKey])
    // récupération de l'objet
    // const selectedAttributeValue: IProductGroupAttributeValue | undefined = useMemo(() => {
    //     if (!attribute || typeof selectedId === 'undefined') {
    //         return undefined
    //     }
    //     // récupération du bon attribut
    //     const arr = attribute.values.filter(attributeValue => attributeValue.id === selectedId)
    //     return arr && arr.length > 0 ? arr[0] : undefined
    // }, [selectedId, attribute])

    // on extrait les clé des attributs sélectionnés
    const attributeValuesAvailability: CollectionMap<boolean> = useMemo(() => {
        let values: CollectionMap<boolean> = {}
        const hasOneAttributeOnly = attributes && attributes.length === 1
        Object.keys(selectedIds).forEach((selectedAttributeKey) => {
            if (hasOneAttributeOnly || selectedAttributeKey !== attribute.attribute) {
                attribute.values.forEach((attributeValue) => {
                    const customSelectedIds = { ...selectedIds, [attribute.attribute]: attributeValue.id }
                    const productIds = findProductIdsFromProductGroupAttributes(
                        attributes,
                        customSelectedIds,
                        undefined
                    )
                    // console.log(
                    //     attribute.attribute,
                    //     attributeValue.id,
                    //     attributeValue.label,
                    //     customSelectedIds,
                    //     productIds
                    // )
                    if (productIds && productIds.length > 0) {
                        values = {
                            ...values,
                            [attributeValue.id]: true,
                        }
                    }
                })
            }
        })
        return values
    }, [selectedIds, attributes, attribute])

    if ([AttributeSelectorMode.Radio, AttributeSelectorMode.Color].indexOf(mode) > -1) {
        Selectors = (
            <div key={`product-selector-inner_${mode}`} className={'product-selector-inner'}>
                {attribute.values.map((value: IProductGroupAttributeValue) => {
                    return (
                        <Form.Check
                            inline
                            key={`attr-selector-inline-${attribute.attribute}-${value.id}`}
                            type={'radio'}
                            id={`attr-selector-inline-${attribute.attribute}-${value.id}`}
                            className={classNames({
                                'product-selector-has-image': value.image,
                                'product-selector-has-color': value.color,
                                'is-selected': value.id === selectedId,
                                'is-available': attributeValuesAvailability[value.id] === true,
                                'not-available': attributeValuesAvailability[value.id] !== true,
                            })}
                        >
                            <Form.Check.Input
                                disabled={disabled}
                                name={`product_selector_${mode}_${attribute.attribute}`}
                                type={'radio'}
                                checked={value.id === selectedId}
                                onChange={handleOnInputChange}
                                value={value.id}
                            />
                            {mode === AttributeSelectorMode.Color && value.color && !value.image && (
                                <Form.Label>
                                    <span
                                        title={value.label}
                                        className={'product-selector-color'}
                                        style={{
                                            backgroundColor: value.color,
                                        }}
                                    />
                                </Form.Label>
                            )}
                            {mode === AttributeSelectorMode.Color && value.image && (
                                <Form.Label>
                                    <img
                                        className={'product-selector-image'}
                                        src={value.image}
                                        alt={value.label}
                                        title={value.label}
                                    />
                                </Form.Label>
                            )}
                            {mode === AttributeSelectorMode.Color && !value.image && !value.color && (
                                <Form.Label>
                                    <img
                                        className={'product-selector-image'}
                                        src={ImagePlaceholder}
                                        alt={value.label}
                                        title={value.label}
                                    />
                                </Form.Label>
                            )}
                            {mode === AttributeSelectorMode.Radio && <Form.Check.Label>{value.label}</Form.Check.Label>}
                        </Form.Check>
                    )
                })}
            </div>
        )
    } else {
        Selectors = (
            <Form.Group>
                <Form.Control
                    as="select"
                    id={`product-selector-${mode}-${attribute.attribute}`}
                    onChange={handleOnInputChange}
                    disabled={disabled}
                    defaultValue={selectedId}
                >
                    {attribute.values.map((value) => {
                        return (
                            <option
                                value={value.id}
                                key={`${attribute.attribute}_${value.id}`}
                                className={classNames({
                                    'is-selected': value.id === selectedId,
                                    'is-available': attributeValuesAvailability[value.id] === true,
                                    'not-available': attributeValuesAvailability[value.id] !== true,
                                })}
                            >
                                {value.label}
                            </option>
                        )
                    })}
                </Form.Control>
            </Form.Group>
        )
    }

    return (
        <div
            className={classNames(
                'product-attribute',
                'product-attribute-selector',
                `product-attribute-selector-mode-${mode}`,
                `product-attribute-selector-type-${attribute.attribute}`
            )}
        >
            <div className={'lbl'}>{attribute.name}</div>
            <div className={'selectors'}>{Selectors}</div>
        </div>
    )
}

AttributeSelector.defaultProps = {
    disabled: false,
} as Partial<IProps>

export default AttributeSelector
