/*
 *
 * Statistics reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'

export const initialState: ContainerState = {
    top_products: {
        fetching: false,
    },
    top_sales: {
        fetching: false,
    },
    yearly_stats: {
        fetching: false,
    },
    classification: {
        fetching: false,
    },
}

function statisticsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.CLASSIFICATION_RESET_ACTION:
            return {
                ...state,
                classification: {
                    fetching: false,
                },
            }
        case ActionTypes.CLASSIFICATION_PROCESS_ACTION:
            return {
                ...state,
                classification: {
                    fetching: true,
                    filters: {
                        ...action.payload.filters,
                    },
                },
            }
        case ActionTypes.CLASSIFICATION_SUCCESS_ACTION:
            return {
                ...state,
                classification: {
                    ...state.classification,
                    tms: Date.now(),
                    fetching: false,
                    tree: action.payload,
                },
            }

        case ActionTypes.CLASSIFICATION_FAILURE_ACTION:
            return {
                ...state,
                classification: {
                    ...state.classification,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.TOP_SALES_RESET_ACTION:
            return {
                ...state,
                top_sales: {
                    ...initialState.top_sales,
                },
            }
        case ActionTypes.TOP_SALES_PROCESS_ACTION:
            return {
                ...state,
                top_sales: {
                    fetching: true,
                    params: {
                        ...action.payload.params,
                    },
                },
            }
        case ActionTypes.TOP_SALES_SUCCESS_ACTION:
            return {
                ...state,
                top_sales: {
                    ...state.top_sales,
                    tms: Date.now(),
                    data: action.payload,
                    fetching: false,
                },
            }
        case ActionTypes.TOP_SALES_FAILURE_ACTION:
            return {
                ...state,
                top_sales: {
                    ...state.top_sales,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.TOP_PRODUCTS_PROCESS_ACTION:
            return {
                ...state,
                top_products: {
                    ...state.top_products,
                    tms: undefined,
                    fetching: true,
                    error: undefined,
                },
            }
        case ActionTypes.TOP_PRODUCTS_SUCCESS_ACTION:
            return {
                ...state,
                top_products: {
                    ...state.top_products,
                    tms: Date.now(),
                    fetching: false,
                    items: action.payload['hydra:member'],
                    totalItems: action.payload['hydra:totalItems'],
                    nextPageUrl: action.payload['hydra:view'] ? action.payload['hydra:view']['hydra:next'] : undefined,
                    prevPageUrl: action.payload['hydra:view'] ? action.payload['hydra:view']['hydra:prev'] : undefined,
                    firstPageUrl: action.payload['hydra:view']
                        ? action.payload['hydra:view']['hydra:first']
                        : undefined,
                    lastPageUrl: action.payload['hydra:view'] ? action.payload['hydra:view']['hydra:last'] : undefined,
                },
            }
        case ActionTypes.TOP_PRODUCTS_FAILURE_ACTION:
            return {
                ...state,
                top_products: {
                    ...state.top_products,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.YEARLY_STATS_PROCESS_ACTION:
            return {
                ...state,
                yearly_stats: {
                    ...state.yearly_stats,
                    fetching: true,
                },
            }
        case ActionTypes.YEARLY_STATS_SUCCESS_ACTION:
            return {
                ...state,
                yearly_stats: {
                    ...state.yearly_stats,
                    data: action.payload,
                },
            }
        case ActionTypes.YEARLY_STATS_FAILURE_ACTION:
            return {
                ...state,
                yearly_stats: {
                    ...state.yearly_stats,
                    tms: Date.now(),
                    error: action.payload.error,
                    fetching: false,
                },
            }
        case ActionTypes.YEARLY_STATS_RESET_ACTION:
            return {
                ...state,
                yearly_stats: {
                    ...initialState.yearly_stats,
                },
            }
        default:
            return state
    }
}

export default statisticsReducer
