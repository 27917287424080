import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { LOCATION_CHANGE, RouterAction } from 'connected-react-router'
import { IApplicationRootState } from '../index'

const routerHistoryMiddleware: Middleware<Dispatch> =
    ({ getState }: MiddlewareAPI) =>
    (next) =>
    (action: AnyAction) => {
        if (action.type === LOCATION_CHANGE) {
            const state: IApplicationRootState = getState()
            const routerAction: RouterAction = action as RouterAction
            // @ts-ignore
            if (routerAction.payload.location) {
                // @ts-ignore
                routerAction.payload.location.state = {
                    // @ts-ignore
                    ...(routerAction.payload.location.state ? routerAction.payload.location.state : {}),
                    // @ts-ignore
                    prev: {
                        pathname: state.router.location.pathname,
                        search: state.router.location.search,
                    },
                }
            }
        }

        return next(action)
    }

export default routerHistoryMiddleware
