/*
 *
 * Product constants
 *
 */

enum ActionTypes {
    STOCK_ALERT_PROCESS_ACTION = 'app/StockAlert/Single/REQUEST_PROCESS',
    STOCK_ALERT_SUCCESS_ACTION = 'app/StockAlert/Single/REQUEST_SUCCESS',
    STOCK_ALERT_FAILURE_ACTION = 'app/StockAlert/Single/REQUEST_FAILURE',
    STOCK_ALERT_BULK_PROCESS_ACTION = 'app/StockAlert/Bulk/REQUEST_PROCESS',
    STOCK_ALERT_BULK_SUCCESS_ACTION = 'app/StockAlert/Bulk/REQUEST_SUCCESS',
    STOCK_ALERT_BULK_FAILURE_ACTION = 'app/StockAlert/Bulk/REQUEST_FAILURE',
}

export default ActionTypes
