/*
 *
 * Product actions
 *
 */

import { action } from 'typesafe-actions'

import {
    IProductCollectionIdentifiers,
    IProductCollectionProcessPayload,
    IProductCrossSellingPayload,
    IProductFavoritePayload,
    IProductGroupProcessPayload,
    IProductProcessPayload,
} from './types'

import ActionTypes from './constants'
import {
    IApiProductCollectionResponse,
    IApiProductGroupResponse,
    IApiProductListPagination,
    IApiProductResponse,
} from '../../services/api/service/products/types'
import { IAppErrorTypes } from '../app/types'

// GLOBAL
export const productResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

// DETAIL
export const productDetailProcessAction = (productId: string, productGroupId?: string) =>
    action<ActionTypes.DETAIL_PROCESS_ACTION, IProductProcessPayload>(ActionTypes.DETAIL_PROCESS_ACTION, {
        productId,
        productGroupId,
    })
export const productDetailSuccessAction = (response: IApiProductResponse) =>
    action<ActionTypes.DETAIL_SUCCESS_ACTION, { response: IApiProductResponse }>(ActionTypes.DETAIL_SUCCESS_ACTION, {
        response,
    })
export const productDetailFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.DETAIL_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.DETAIL_FAILURE_ACTION, { error })
export const productDetailResetAction = () => action<ActionTypes.DETAIL_RESET_ACTION>(ActionTypes.DETAIL_RESET_ACTION)

// GROUP
export const productGroupAttributeProcessAction = (productGroupId?: string | null) =>
    action<ActionTypes.GROUP_ATTRIBUTE_PROCESS_ACTION, IProductGroupProcessPayload>(
        ActionTypes.GROUP_ATTRIBUTE_PROCESS_ACTION,
        { productGroupId }
    )
export const productGroupAttributeSuccessAction = (response: IApiProductGroupResponse | null) =>
    action<ActionTypes.GROUP_ATTRIBUTE_SUCCESS_ACTION, { response: IApiProductGroupResponse | null }>(
        ActionTypes.GROUP_ATTRIBUTE_SUCCESS_ACTION,
        { response }
    )
export const productGroupAttributeFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.GROUP_ATTRIBUTE_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.GROUP_ATTRIBUTE_FAILURE_ACTION,
        { error }
    )
export const productGroupAttributeResetAction = () =>
    action<ActionTypes.GROUP_ATTRIBUTE_RESET_ACTION>(ActionTypes.GROUP_ATTRIBUTE_RESET_ACTION)

// Collection
export const productCollectionProcessAction = (identifiers: IProductCollectionIdentifiers) =>
    action<ActionTypes.COLLECTION_PROCESS_ACTION, IProductCollectionProcessPayload>(
        ActionTypes.COLLECTION_PROCESS_ACTION,
        { identifiers }
    )

export const productCollectionSuccessAction = (response: IApiProductCollectionResponse | null) =>
    action<ActionTypes.COLLECTION_SUCCESS_ACTION, { response: IApiProductCollectionResponse | null }>(
        ActionTypes.COLLECTION_SUCCESS_ACTION,
        { response }
    )

export const productCollectionFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.COLLECTION_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.COLLECTION_FAILURE_ACTION, {
        error,
    })

export const productCollectionResetAction = () =>
    action<ActionTypes.COLLECTION_RESET_ACTION>(ActionTypes.COLLECTION_RESET_ACTION)

// FAVORITES
export const productAddToFavoriteAction = (productId: string) =>
    action<ActionTypes.ADD_TO_FAVORITE_ACTION, IProductFavoritePayload>(ActionTypes.ADD_TO_FAVORITE_ACTION, {
        productId,
    })
export const productRemoveToFavoriteAction = (productId: string) =>
    action<ActionTypes.REMOVE_TO_FAVORITE_ACTION, IProductFavoritePayload>(ActionTypes.REMOVE_TO_FAVORITE_ACTION, {
        productId,
    })

// CROSS SELLING
export const productCrossSellingProcessAction = (productId: string) =>
    action<ActionTypes.CROSS_SELLING_PROCESS_ACTION, IProductCrossSellingPayload>(
        ActionTypes.CROSS_SELLING_PROCESS_ACTION,
        {
            productId,
        }
    )
export const productCrossSellingSuccessAction = (response: IApiProductListPagination) =>
    action<ActionTypes.CROSS_SELLING_SUCCESS_ACTION, IApiProductListPagination>(
        ActionTypes.CROSS_SELLING_SUCCESS_ACTION,
        response
    )
export const productCrossSellingFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.CROSS_SELLING_FAILURE_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.CROSS_SELLING_FAILURE_ACTION,
        { error }
    )

export const productCrossSellingResetAction = () =>
    action<ActionTypes.CROSS_SELLING_RESET_ACTION>(ActionTypes.CROSS_SELLING_RESET_ACTION)
