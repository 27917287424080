/*
 *
 * Order actions
 *
 */

import { action } from 'typesafe-actions'

import { AxiosError } from 'axios'

import ActionTypes from './constants'
import { IOrderDetailProcessPayload } from './types'
import { IApiOrderResponse } from '../../services/api/service/orders/types'

export const orderDetailProcessAction = (orderId: string) =>
    action<ActionTypes.DETAIL_PROCESS_ACTION, IOrderDetailProcessPayload>(ActionTypes.DETAIL_PROCESS_ACTION, {
        orderId,
    })
export const orderDetailSuccessAction = (response: IApiOrderResponse) =>
    action<ActionTypes.DETAIL_SUCCESS_ACTION, { response: IApiOrderResponse }>(ActionTypes.DETAIL_SUCCESS_ACTION, {
        response,
    })
export const orderDetailFailureAction = (error: AxiosError) =>
    action<ActionTypes.DETAIL_FAILURE_ACTION, { error: AxiosError }>(ActionTypes.DETAIL_FAILURE_ACTION, { error })
export const orderResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
