/**
 *
 * Sidebar
 *
 */
import classNames from 'classnames'
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { IApiProductGridCollection, ProductsListDisplayMode } from '../../../services/api/service/products/types'

export type IProductsListDisplayModeChoiceProps = {
    show?: boolean
    disabled: boolean
    values: IApiProductGridCollection
    value?: IApiProductGridCollection[0]['@id']
    displayMode: ProductsListDisplayMode
    onChange: (mode: ProductsListDisplayMode, resourceId?: string) => void
}

function ProductsListDisplayModeChoice({
    displayMode = ProductsListDisplayMode.Default,
    disabled = false,
    show = true,
    values,
    onChange,
    value,
}: IProductsListDisplayModeChoiceProps): JSX.Element {
    const [selectedValue, setSelectedValue] = useState<string>('-1')
    const [, setSelectedDisplayMode] = useState<ProductsListDisplayMode>(displayMode)
    const { formatMessage } = useIntl()

    useEffect(() => {
        setSelectedValue(value || '-1')
    }, [setSelectedValue, value])

    useEffect(() => {
        setSelectedDisplayMode(displayMode)
    }, [setSelectedDisplayMode, displayMode])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const currentResourceId = e.currentTarget.value
            let grid: IApiProductGridCollection[0] | undefined = undefined
            if (currentResourceId.length > 0) {
                grid = values.find((value) => value['@id'] === currentResourceId)
            }

            if (!grid) {
                setSelectedDisplayMode(ProductsListDisplayMode.Default)
                setSelectedValue('')
                if (onChange) {
                    onChange(ProductsListDisplayMode.Default)
                }
                return
            }

            setSelectedDisplayMode(ProductsListDisplayMode.GridData)
            setSelectedValue(grid['@id'])
            if (onChange) {
                onChange(ProductsListDisplayMode.GridData, grid['@id'])
            }
        },
        [values, setSelectedValue, setSelectedDisplayMode, onChange]
    )

    return (
        <div className={classNames('product-list-display-mode', { 'd-none': !show })}>
            <Form.Group className={'product-list-action'}>
                <Form.Label>
                    <FormattedMessage id={'products.display_mode.label'} />
                </Form.Label>
                <Form.Control
                    as="select"
                    size="sm"
                    disabled={disabled}
                    className={'form-control'}
                    onChange={handleChange}
                    value={selectedValue}
                >
                    <option value={''}>{formatMessage({ id: 'products.display_mode.default' })}</option>
                    {values.map((value) => (
                        <option key={`display_mode_${value['@id']}`} value={value['@id']}>
                            {value.label}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        </div>
    )
}

export default memo(ProductsListDisplayModeChoice)
