import axios, { AxiosInstance } from 'axios'
import Qs from 'qs'
import { CART_MODE_HEADER_NAME, IApiClient, ORDER_MODE_HEADER_NAME, SELECTED_CUSTOMER_HEADER_NAME } from './types'
import authenticate from './service/authenticate/service'
import products from './service/products/service'
import config from './service/config/service'
import classification from './service/classification/service'
import customers from './service/customers/service'
import prospects from './service/prospects/service'
import carts from './service/carts/service'
import orders from './service/orders/service'
import favorite from './service/favorite/service'
import cms from './service/cms/service'
import invoices from './service/invoices/service'
import search from './service/search/service'
import cartExport from './service/cartExports/service'
import orderExport from './service/orderExports/service'
import exports from './service/exports/service'
import imports from './service/imports/service'
import cartImport from './service/cartImports/service'
import customerStock from './service/customerStocks/service'
import stockAlert from './service/stockAlert/service'
import salesman from './service/salesman/service'
import orderAnomaly from './service/orderAnomaly/service'
import statistics from './service/stats/service'
import paymentModes from './service/payment-modes/service'
import me from './service/me/service'
import pictureExport from './service/pictureExport/service'
import localization from './service/localization/service'

import Config from '../../config'
import { ICartMode } from './service/carts/types'
import { IOrderMode } from './service/orders/types'
// global config for auth
axios.defaults.baseURL = Config.API.URL
axios.defaults.timeout = Config.API.TIMEOUT || 3000
axios.defaults.paramsSerializer = (params) => {
    return Qs.stringify(params)
}

axios.defaults.responseType = 'json'
axios.defaults.headers.common = {
    Accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
    'X-Locale': Config.I18N.DEFAULT_LOCALE,
}

// const create = (baseUrl: string, timeout = 3000, locale: string): IApiClient => {
const instance: AxiosInstance = axios.create({
    baseURL: Config.API.URL || 'https://example.com',
})

const getInstance = () => {
    return instance
}

/*****************************
 * I18N
 *****************************/
const setLocale = (locale: string) => {
    instance.defaults.headers['X-Locale'] = locale
    axios.defaults.headers.common['X-Locale'] = locale
}
const setCustomerId = (customerId?: string) => {
    if (!customerId) {
        delete instance.defaults.headers[SELECTED_CUSTOMER_HEADER_NAME]
        delete axios.defaults.headers.common[SELECTED_CUSTOMER_HEADER_NAME]
        return
    }
    instance.defaults.headers[SELECTED_CUSTOMER_HEADER_NAME] = customerId
    axios.defaults.headers.common[SELECTED_CUSTOMER_HEADER_NAME] = customerId
}

const setCartMode = (cartMode?: ICartMode) => {
    if (!cartMode) {
        delete instance.defaults.headers[CART_MODE_HEADER_NAME]
        delete axios.defaults.headers.common[CART_MODE_HEADER_NAME]
        return
    }
    instance.defaults.headers[CART_MODE_HEADER_NAME] = cartMode
    axios.defaults.headers.common[CART_MODE_HEADER_NAME] = cartMode
}

const setOrderMode = (orderMode?: IOrderMode) => {
    if (!orderMode) {
        delete instance.defaults.headers[ORDER_MODE_HEADER_NAME]
        delete axios.defaults.headers.common[ORDER_MODE_HEADER_NAME]
        return
    }
    instance.defaults.headers[ORDER_MODE_HEADER_NAME] = orderMode
    axios.defaults.headers.common[ORDER_MODE_HEADER_NAME] = orderMode
}

/*****************************
 * API SERVICES
 *****************************/
const refreshTokenUrl = `${Config.API.URL}/token/refresh`
const getRefreshTokenUrl = () => {
    return refreshTokenUrl
}

const authenticateService = authenticate.create(instance, refreshTokenUrl)
const productsService = products.create(instance)
const configService = config.create(instance)
const classificationService = classification.create(instance)
const customersService = customers.create(instance)
const cartsService = carts.create(instance)
const ordersService = orders.create(instance)
const favoriteService = favorite.create(instance)
const cmsService = cms.create(instance)
const invoicesService = invoices.create(instance)
const searchService = search.create(instance)
const cartExportService = cartExport.create(instance)
const orderExportService = orderExport.create(instance)
const exportsService = exports.create(instance)
const importsService = imports.create(instance)
const cartImportService = cartImport.create(instance)
const customerStockService = customerStock.create(instance)
const salesmanService = salesman.create(instance)
const statisticsService = statistics.create(instance)
const paymentModeService = paymentModes.create(instance)
const stockAlertService = stockAlert.create(instance)
const meService = me.create(instance)
const orderAnomalyService = orderAnomaly.create(instance)
const pictureExportService = pictureExport.create(instance)
const localizationService = localization.create(instance)
const prospectsService = prospects.create(instance)

/*****************************
 * API RETURNS
 *****************************/
export default {
    authenticate: authenticateService,
    products: productsService,
    config: configService,
    classification: classificationService,
    customers: customersService,
    prospects: prospectsService,
    carts: cartsService,
    orders: ordersService,
    invoices: invoicesService,
    favorite: favoriteService,
    cms: cmsService,
    search: searchService,
    cartExports: cartExportService,
    orderExports: orderExportService,
    exports: exportsService,
    imports: importsService,
    cartImports: cartImportService,
    customerStocks: customerStockService,
    salesman: salesmanService,
    statistics: statisticsService,
    paymentModes: paymentModeService,
    stockAlert: stockAlertService,
    me: meService,
    orderAnomaly: orderAnomalyService,
    pictureExport: pictureExportService,
    localization: localizationService,
    setLocale,
    setCustomerId,
    setCartMode,
    setOrderMode,
    getInstance,
    getRefreshTokenUrl,
} as IApiClient
