import { CollectionMap } from '../types/common'
// @ts-ignore
import moment from 'moment/min/moment-with-locales.min'

export default class ApplicationHelper {
    static stringToBool(value: any): boolean {
        if (value === true || value === 'true' || value === 1 || value === '1' || value === 'on' || value === 'yes') {
            return true
        } else {
            return false
        }
    }
    static removeEmpty(obj: CollectionMap<any>): any {
        Object.keys(obj).forEach((key: string) => {
            if (typeof obj[key] === 'object') {
                ApplicationHelper.removeEmpty(obj[key])
            } else if (typeof obj[key] === undefined || (typeof obj[key] === 'string' && obj[key].length === 0))
                delete obj[key]
        })
        return obj
    }
    static isMobileResolution(): boolean {
        return window.matchMedia && window.matchMedia('(max-width:1023px)').matches
    }
    static isSmartphoneResolution(): boolean {
        return window.matchMedia && window.matchMedia('(max-width:767px)').matches
    }
    static isTouchScreen(): boolean {
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
            return true
        }
        return false
    }
    static convertDate(value?: Date | null, dateFormat = 'DD/MM/YYYY'): string {
        if (!value) {
            return ''
        }

        return moment(value).format(dateFormat)
    }
}
