import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

const selectInvoicesDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.invoices || initialState
}

const selectInvoicesListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.invoices.list || initialState.list
}

const selectInvoicesDocumentDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.invoices.document || initialState.document
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Invoices
 */

const makeSelectInvoicesListFetching = () =>
    createSelector(selectInvoicesListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectInvoicesListError = () =>
    createSelector(selectInvoicesListDomain, (substate) => {
        return substate.error
    })

const makeSelectInvoicesListItems = () =>
    createSelector(selectInvoicesListDomain, (substate) => {
        return substate.items
    })

const makeSelectInvoicesListTotalItems = () =>
    createSelector(selectInvoicesListDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectInvoicesDocumentFetchingWithId = () =>
    createSelector(
        [selectInvoicesDocumentDomain, (_: any, identifier: string) => identifier],
        (substate, identifier) => {
            return substate[identifier] ? substate[identifier].fetching : false
        }
    )

const makeSelectInvoicesDocumentErrorWithId = () =>
    createSelector(
        [selectInvoicesDocumentDomain, (_: any, identifier: string) => identifier],
        (substate, identifier) => {
            return substate[identifier] ? substate[identifier].error : undefined
        }
    )

const makeSelectInvoicesDocumentLinkWithId = () =>
    createSelector(
        [selectInvoicesDocumentDomain, (_: any, identifier: string) => identifier],
        (substate, identifier) => {
            return substate[identifier] ? substate[identifier].link : undefined
        }
    )

export {
    selectInvoicesDomain,
    makeSelectInvoicesListFetching,
    makeSelectInvoicesListError,
    makeSelectInvoicesListItems,
    makeSelectInvoicesListTotalItems,
    makeSelectInvoicesDocumentFetchingWithId,
    makeSelectInvoicesDocumentErrorWithId,
    makeSelectInvoicesDocumentLinkWithId,
}
