import React, { FunctionComponent, MouseEventHandler } from 'react'
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IProps extends BootstrapButtonProps {
    onClick: MouseEventHandler
}

const LogoutButton: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <BootstrapButton {...props}>
            <FormattedMessage id="default.logout" defaultMessage="Déconnexion" />
        </BootstrapButton>
    )
}

export default LogoutButton
