import { call, cancelled, getContext, put, takeLatest } from 'redux-saga/effects'
import PictureExportActionTypes from './constants'
import {
    IPictureExportListProcessAction,
    IPictureExportProcessCreateProcessAction,
    IPictureExportProcessRefreshProcessAction,
} from './types'
import { IApiClient } from '../../services/api/types'
import axios from 'axios'
import { formatAppError } from '../app/saga'
import {
    pictureExportListFailureAction,
    pictureExportListSuccessAction,
    pictureExportProcessCreateFailureAction,
    pictureExportProcessCreateSuccessAction,
    pictureExportProcessRefreshFailureAction,
    pictureExportProcessRefreshSuccessAction,
} from './actions'
import {
    IApiPictureExportListResponse,
    IApiPictureExportResponse,
} from '../../services/api/service/pictureExport/types'
import HttpStatusCode from '../http/codes'

// axios
const CancelToken = axios.CancelToken

function* processListRequest(action: IPictureExportListProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    try {
        const { params } = action.payload

        const response: IApiPictureExportListResponse = yield call(
            { context: api.pictureExport, fn: 'list' },
            params,
            source.token
        )
        yield put(pictureExportListSuccessAction(response))
    } catch (e) {
        // console.log(e)
        const err = yield call(formatAppError, e, 'picture_exports.unknow_error')
        yield put(pictureExportListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processCreateProcessRequest(action: IPictureExportProcessCreateProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const { params } = action.payload

        const response: IApiPictureExportResponse = yield call({ context: api.pictureExport, fn: 'create' }, params)
        yield put(pictureExportProcessCreateSuccessAction(response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'picture_export.unknow_error')
        if (error.isHttpError) {
            let violations: Array<string> = []
            if (String(error.status) === HttpStatusCode.BAD_REQUEST.toString() && error.violations) {
                for (const key in error.violations) {
                    const itemError = error.violations[key]
                    violations = [...violations, ...itemError]
                }
            }
            if (violations.length > 0) {
                error.message = violations.join(',')
            }
        }

        yield put(pictureExportProcessCreateFailureAction(error))
    }
}

function* processRefreshProcessRequest(action: IPictureExportProcessRefreshProcessAction) {
    const api: IApiClient = yield getContext('api')
    try {
        const { identifier } = action.payload

        const response: IApiPictureExportResponse = yield call({ context: api.pictureExport, fn: 'get' }, identifier)
        yield put(pictureExportProcessRefreshSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'picture_export.unknow_error')
        yield put(pictureExportProcessRefreshFailureAction(err))
    }
}

export default [
    takeLatest(PictureExportActionTypes.LIST_PROCESS_ACTION, processListRequest),
    takeLatest(PictureExportActionTypes.PROCESS_CREATE_PROCESS_ACTION, processCreateProcessRequest),
    takeLatest(PictureExportActionTypes.PROCESS_REFRESH_PROCESS_ACTION, processRefreshProcessRequest),
]
