/**
 *
 * SimpleHighlight
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { LazyImage } from 'react-lazy-images'
import { CmsWidgetType, ICmsWidgetSimpleHighlight } from '../../../../services/api/service/cms/types'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import isExternalRegexClosure from '../../../../utils/location'

interface IProps {
    widget: ICmsWidgetSimpleHighlight
    onLinkClick?: CmsWidgetLinkClickCallback
}

function SimpleHighlight({ widget, onLinkClick }: IProps): JSX.Element {
    const handleOnClick = () => {
        if (onLinkClick) {
            onLinkClick({
                widget: CmsWidgetType.SimpleHighlight,
                url: widget.configuration.link?.url || null,
                internal:
                    widget.configuration.link?.internal ||
                    (widget.configuration.link?.url ? isExternalRegexClosure(widget.configuration.link?.url) : false),
                blank: widget.configuration.link?.blank || false,
                text: widget.configuration.button_label,
                extra: {
                    widget,
                },
            })
        }
    }

    return (
        <div
            className={classNames('cms-widget', `cms-widget-${widget.widget}`, 'cms-widget-theme-default')}
            onClick={handleOnClick}
        >
            <div className={'cms-widget-inner'}>
                <div className={'heading'}>
                    <p className={'h1 hv'}>{widget.configuration.title}</p>
                    {widget.configuration.subtitle && <p className={'h6'}>{widget.configuration.subtitle}</p>}
                </div>
                <div className={'content'}>
                    {widget.configuration.image && (
                        <div className={'embed-responsive embed-responsive-1by1'}>
                            <div className={'embed-responsive-item'}>
                                <LazyImage
                                    debounceDurationMs={200}
                                    src={widget.configuration.image!.default}
                                    alt={widget.configuration.title!}
                                    placeholder={({ imageProps, ref }) => (
                                        <img
                                            {...imageProps}
                                            className={'placeholder'}
                                            ref={ref}
                                            alt={widget.configuration.title!}
                                            src={widget.configuration.image!.placeholder}
                                        />
                                    )}
                                    actual={({ imageProps }) => (
                                        <>
                                            <img
                                                {...imageProps}
                                                alt={widget.configuration.title!}
                                                className={'picture'}
                                                onClick={handleOnClick}
                                            />
                                        </>
                                    )}
                                />
                                {widget.configuration.link && (
                                    <button className={'btn btn-light animate__animated'}>
                                        <span>{widget.configuration.button_label}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

SimpleHighlight.defaultProps = {} as Partial<IProps>

export default memo(SimpleHighlight)
