/*
 *
 * SideMenu reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'

export const initialState: ContainerState = {
    open: false,
}

function sideMenuReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.OPEN_ACTION:
            return { ...state, open: true, activeId: action.payload.activeId }
        case ActionTypes.CLOSE_ACTION:
            return { ...state, open: false }
        case ActionTypes.TOGGLE_ACTION:
            return { ...state, open: !state.open, activeId: action.payload.activeId }
        case ActionTypes.STORE_ITEMS_ACTION:
            return { ...state, items: action.payload.items }
        case ActionTypes.STORE_ACTIVE_ID_ACTION:
            return { ...state, activeId: action.payload.activeId }
        default:
            return state
    }
}

export default sideMenuReducer
