import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the sideMenu state domain
 */

const selectSideMenuDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.sideMenu || initialState
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by SideMenu
 */
const makeSelectSideMenuOpen = () =>
    createSelector(selectSideMenuDomain, (substate) => {
        return substate.open
    })

const makeSelectSideMenuItems = () =>
    createSelector(selectSideMenuDomain, (substate) => {
        return substate.items || []
    })

const makeSelectSideMenuActiveId = () =>
    createSelector(selectSideMenuDomain, (substate) => {
        return substate.activeId || undefined
    })

export default makeSelectSideMenuOpen
export { selectSideMenuDomain, makeSelectSideMenuItems, makeSelectSideMenuActiveId }
