import React, { lazy, Suspense } from 'react'

interface IProps {
    fallback: React.ReactNode | null
}
const loadable = <T extends React.ComponentType<any>>(
    importFunc: () => Promise<{ default: T }>,
    { fallback = null }: IProps = { fallback: null }
) => {
    const LazyComponent = lazy(importFunc)

    // eslint-disable-next-line react/display-name
    return (props: React.ComponentProps<T>): JSX.Element => (
        <Suspense fallback={fallback}>
            <LazyComponent {...props} />
        </Suspense>
    )
}

export default loadable
