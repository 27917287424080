import React, { memo } from 'react'

interface IProps {
    text?: string
}

function Schedule({ text }: IProps): JSX.Element {
    return <div className="schedule">{text && <span>{text}</span>}</div>
}

Schedule.defaultProps = {
    mobile: false,
} as Partial<IProps>

const areEqual = (prevProps: IProps, nextProps: IProps) => {
    return prevProps.text === nextProps.text
}

export default memo(Schedule, areEqual)
