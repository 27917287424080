import { ISalesman, ISalesmanStatsMode } from '../../services/api/service/salesman/types'
import { IMe } from '../../services/api/service/me/types'

export function isSalesmanResource(obj: any): obj is ISalesman {
    return typeof obj === 'object' && typeof obj['@type'] === 'string' && obj['@type'] === 'Salesman'
}

export function canViewAllCustomers(me?: IMe): boolean {
    return isSalesmanResource(me) && me.allow_all_customers
}

export function canViewStats(me?: IMe): boolean {
    return isSalesmanResource(me) && me.stats_mode !== ISalesmanStatsMode.NoStats
}

export function isStatsMode(me: IMe | undefined, mode: ISalesmanStatsMode): boolean {
    return isSalesmanResource(me) && me.stats_mode === mode
}

export function canShowAllActivitySelector(me?: IMe): boolean {
    return !canViewAllCustomers(me) && isStatsMode(me, ISalesmanStatsMode.Both)
}
