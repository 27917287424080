/**
 *
 * RemotePdf
 *
 */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
    Ean,
    Heading as ProductHeading,
    MainPicture as ProductMainPicture,
    Packing as ProductPacking,
    Price as ProductPrice,
    Reference,
} from '../../components/Product/Partial'
import { default as StoreQuantityItem } from '../../components/StoreQuantity/StoreQuantity'
import { ICustomer } from '../../services/api/service/customers/types'
import { IStoreQuantityProduct } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import {
    addToCartProcessAction,
    cartCartLightsResetAction,
    cartFetchCartsAction,
    cartStoreQuantityProcessAction,
    removeToCartProcessAction,
} from '../../store/carts/actions'
import { makeSelectCartItemStoreQuantitiesDomain } from '../../store/carts/selectors'
import { ICartItemStoreQuantitiesState, ICartItemStoreQuantityState } from '../../store/carts/types'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { default as ItemsPlaceholder } from './Placeholder'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { customerShowPrices } from '../../store/customers/utils'

// TEST
interface IProps {
    product: IStoreQuantityProduct
    errors?: Array<string>
    onStoreQuantityExit: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
    storeQuantities: makeSelectCartItemStoreQuantitiesDomain(),
})

function StoreQuantity({ product, errors, onStoreQuantityExit }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()

    const { customer, storeQuantities, me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            customer: ICustomer
            storeQuantities: ICartItemStoreQuantitiesState
        }
    >(stateSelector)
    const storeQuantityState: ICartItemStoreQuantityState | undefined = storeQuantities[product.id]
    const { loading, error, quantities, updating } = storeQuantityState || {}

    const [modalShow, setModalShow] = useState<boolean>(true)
    const [errorShown, setErrorShown] = useState<boolean>(false)
    const hasErrors = useMemo(() => {
        return (errors && errors.length > 0) || product.out_of_stock
    }, [errors, product])
    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    useEffect(() => {
        setModalShow(true)
    }, [product, setModalShow])

    useEffect(() => {
        if (modalShow && !loading && !error && !quantities && product) {
            dispatch(cartStoreQuantityProcessAction(product.id))
        }
    }, [dispatch, modalShow, loading, quantities, error, product])

    const handleOnModalExited = useCallback(() => {
        setModalShow(false)

        // refresh des paniers (les items_count des stores quantity ne peuvent êtres calculé correctement sans avoir le détail de chaque sous panier)
        dispatch(cartFetchCartsAction())
        dispatch(cartCartLightsResetAction())

        setTimeout(() => onStoreQuantityExit(), 150)
    }, [dispatch, onStoreQuantityExit, setModalShow])

    const handleQuantityChange = useCallback(
        (storeId: string, quantity: number, quantityOld: number) => {
            if (quantity <= 0) {
                dispatch(removeToCartProcessAction(product.id, quantity, quantityOld, undefined, storeId))
            } else {
                dispatch(addToCartProcessAction(product.id, quantity, quantityOld, undefined, storeId))
            }
        },
        [product, dispatch]
    )

    return (
        <>
            <Modal size={'lg'} className={'store-quantity-modal'} show={modalShow} onHide={handleOnModalExited}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id={'product.update_quantities'} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'store-quantity-product'}>
                        {hasErrors && (
                            <Alert variant={'warning'}>
                                <h6>
                                    <FormattedMessage id={'default.warning'} />
                                </h6>
                                {errors?.map((error, index) => (
                                    <p key={index} className={'mb-0'}>
                                        {error}
                                    </p>
                                ))}
                                {product.out_of_stock && (
                                    <p className={'mb-0'}>
                                        <FormattedMessage id={'product.out_of_stock'} />
                                    </p>
                                )}
                            </Alert>
                        )}
                        <Row>
                            <Col md={'auto'}>
                                <div className={'product-image'}>
                                    <div className={'product-card'}>
                                        <ProductMainPicture product={product} />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className={'product-description'}>
                                    <ProductHeading product={product} productNameAs={'p'} />
                                    <Row>
                                        <Col xs={12} md={24}>
                                            <Reference reference={product.reference} showLabel />
                                            <Ean ean13={product.ean13} showLabel />
                                        </Col>
                                        <Col xs={12} md={24}>
                                            <div className={'product-prices-packing'}>
                                                {showPrices ? (
                                                    <ProductPrice
                                                        price={product.price}
                                                        strikePrice={product.strike_price}
                                                        retailPrice={product.retail_price}
                                                        abbreviateLabels
                                                    >
                                                        <ProductPacking
                                                            packing={product.packing}
                                                            abbreviateLabels
                                                            as={'span'}
                                                        />
                                                    </ProductPrice>
                                                ) : (
                                                    <ProductPacking
                                                        packing={product.packing}
                                                        abbreviateLabels
                                                        as={'span'}
                                                    />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <ReactPlaceholder
                        ready={typeof quantities !== 'undefined'}
                        customPlaceholder={<ItemsPlaceholder itemsPerPage={5} />}
                    >
                        <div className={'store-quantity-list'}>
                            {quantities &&
                                customer.stores?.map((store) => (
                                    <StoreQuantityItem
                                        store={store}
                                        quantity={quantities[store.id] || 0}
                                        saving={updating ? updating[store.id] || false : false}
                                        packing={product.packing}
                                        outOfStock={product.out_of_stock}
                                        outOfStockLabel={
                                            product.coming_soon ? 'default.coming_soon' : 'product.out_of_stock'
                                        }
                                        key={store['@id']}
                                        onQuantityChange={handleQuantityChange}
                                    />
                                ))}
                        </div>
                    </ReactPlaceholder>
                </Modal.Body>
            </Modal>
            {error && (
                <SweetAlert
                    error
                    title={formatMessage({
                        id: 'default.error',
                    })}
                    show={error!.message !== null && !errorShown}
                    onConfirm={() => {
                        setErrorShown(true)
                    }}
                >
                    {error}
                </SweetAlert>
            )}
        </>
    )
}

StoreQuantity.defaultProps = {} as Partial<IProps>

export default memo(StoreQuantity)
