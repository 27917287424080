/**
 *
 * OrderDetail
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import OrderState from '../../../Order/OrderState'
import { OrderStates } from '../../../../services/api/service/orders/types'
import { Overlay, Tooltip } from 'react-bootstrap'

type IProps = {
    labelId?: string
    uniqId?: string
    tooltip?: boolean
    lastOrderDate: string | null
    lastOrderQuantity: number | null
    lastOrderState: OrderStates | null
    onClick?: (e: MouseEvent) => void
}

function CustomerLastOrder({
    tooltip = true,
    labelId = 'default.last_order',
    lastOrderDate,
    lastOrderQuantity,
    lastOrderState,
    onClick,
    uniqId,
}: IProps): JSX.Element {
    const { formatDate, formatMessage } = useIntl()
    const target = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const customerQuantityValue = useMemo(() => {
        if (!lastOrderQuantity) {
            return '-'
        }
        // return formatMessage({ id: 'default.product_plural' }, { count: lastOrderQuantity })
        return String(lastOrderQuantity)
    }, [lastOrderQuantity])

    const customerDateLabel = useMemo(() => {
        if (!lastOrderDate) {
            return '-'
        }

        return formatMessage({ id: 'customer_order.from_date' }, { date: formatDate(lastOrderDate) })
    }, [lastOrderDate, formatMessage, formatDate])

    const Content = useMemo(() => {
        return (
            <>
                <span className={'value'}>{customerQuantityValue}</span>
                {(lastOrderDate || lastOrderState) && (
                    <span className="extra">
                        ({customerDateLabel && <span className={'date'}>{customerDateLabel}</span>}
                        {lastOrderState ? ' - ' : ''}
                        {lastOrderState && <OrderState state={lastOrderState} />})
                    </span>
                )}
            </>
        )
    }, [customerDateLabel, customerQuantityValue, lastOrderDate, lastOrderState])

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(tooltip)
    }, [setShowTooltip, tooltip])

    const handleMouseLeave = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    return (
        <>
            <Overlay show={showTooltip} target={target}>
                <Tooltip id={`tooltip-${uniqId || 'single'}-member-order`}>
                    <div className="tooltip-member-info-content">
                        <div
                            className={classNames(
                                'product-member-info',
                                'product-customer-order',
                                'product-field',
                                'has-subs',
                                {
                                    'has-date': typeof lastOrderDate === 'string',
                                }
                            )}
                        >
                            {Content}
                        </div>
                    </div>
                </Tooltip>
            </Overlay>
            <div
                // @ts-ignore
                ref={target}
                className={classNames('product-member-info', 'product-customer-order', 'product-field', {
                    'has-callback': typeof onClick !== 'undefined',
                    'has-date': typeof lastOrderDate === 'string',
                })}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <span className={'lbl'}>
                    <FormattedMessage id={labelId} />
                </span>
                {Content}
            </div>
        </>
    )
}

export default memo(CustomerLastOrder)
