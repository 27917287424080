/*
 *
 * Config reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState } from './types'

export const initialState: ContainerState = {}

function configReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION:
            return initialState
        case ActionTypes.STORE_REMOTE_ACTION:
            return {
                ...state,
                remote: {
                    ...action.payload,
                },
            }
        case ActionTypes.SET_SALESMAN_CART_ALERT_DISPLAY_ACTION:
            return {
                ...state,
                salesman: {
                    ...(state.salesman || {}),
                    cart_alert_displayed: action.payload,
                },
            }
        default:
            return state
    }
}

export default configReducer
