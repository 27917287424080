/**
 *
 * Sidebar
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation } from 'react-router-dom'
import {
    ClassificationType,
    FamilyType,
    IBaseClassificationFamily,
    IDepartment,
    IFamily,
    IFamilyTreeCollection,
    IFamilyTypes,
    IShippingLocation,
    ISubFamily,
    ParentFamilyType,
} from '../../../../services/api/service/classification/types'
import FlatIcon from '../../../Icon/FlatIcon'
import ItemIcon from '../../../MainMenu/ItemIcon'
import { useProductListParsedQuery } from '../../../../containers/Products/useParsedQuery'
import { ProductListQueryName, ProductsListDisplayMode } from '../../../../services/api/service/products/types'
import Qs from 'qs'
import Config from '../../../../config'

interface IProps {
    title?: string
    basePath?: string
    disabled: boolean
    useFullName?: boolean
    departmentOnly: boolean
    onTitleClick?: () => void
    onClick?: (e: MouseEvent, item: IFamilyTypes) => void
    tree: IFamilyTreeCollection
}

function ClassificationItem({
    useFullName,
    item,
    basePath,
    onClick,
    departmentOnly,
    queries,
}: {
    useFullName?: boolean
    basePath?: string
    item: IFamilyTypes
    departmentOnly?: boolean
    queries?: Record<string, any>
    onClick?: (e: MouseEvent, item: IFamilyTypes) => void
}): JSX.Element {
    const location = useLocation()
    const [sectionOpened, setSectionOpened] = useState<boolean>(true)
    const [currentSectionChevron, setCurrentSectionChevron] = useState<string>('chevron-up')
    let iconType: string | undefined = undefined
    let children: Array<IFamilyTypes> = []
    let entryType = 'unknow'
    if (item['@type'] === ClassificationType.ShippingLocation) {
        iconType = 'location'
        children = (item as IShippingLocation).tree
        entryType = ParentFamilyType.ShippingLocation
    } else if (item.type === FamilyType.Department) {
        children = (item as IDepartment).sub_families
        entryType = FamilyType.Department
    } else if (item.type === FamilyType.Family) {
        children = (item as IFamily).sub_families
        entryType = FamilyType.Family
    } else if (item.type === FamilyType.SubFamily) {
        children = (item as ISubFamily).sub_families
        entryType = FamilyType.SubFamily
    }

    const isShippingLocation = entryType === ParentFamilyType.ShippingLocation
    const handleOnClick = useCallback(
        (e: MouseEvent) => {
            if (onClick) {
                onClick(e, item)
            }
        },
        [item, onClick]
    )

    const handleSectionCloseClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            const newValue = !sectionOpened
            setSectionOpened(newValue)
            setCurrentSectionChevron(newValue ? 'chevron-up' : 'chevron-down')
        },
        [sectionOpened, setCurrentSectionChevron]
    )

    useEffect(() => {
        const isOpened = location.pathname.indexOf(item.url) > -1
        setSectionOpened(isOpened)
        setCurrentSectionChevron(isOpened ? 'chevron-up' : 'chevron-down')
    }, [location.pathname, item])

    const linkUrl = useMemo(() => {
        const itemUrl = `${basePath || ''}${item.url || ''}`
        if (!queries) {
            return itemUrl
        }
        const stringified = Qs.stringify(queries)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        return `${itemUrl}${qstring}`
    }, [item, basePath, queries])

    if (departmentOnly && FamilyType.Department !== entryType && ParentFamilyType.ShippingLocation !== entryType) {
        return <></>
    }

    return (
        <div
            className={classNames('family-tree-entry', `family-tree-entry-${entryType}`, {
                opened: isShippingLocation && sectionOpened,
                'has-children': children.length > 0,
            })}
        >
            <NavLink
                to={(location) => ({
                    ...location,
                    pathname: linkUrl.split('?')[0],
                    search: linkUrl.includes('?') ? `?${linkUrl.split('?')[1]}` : undefined,
                    state: { no_scroll: true },
                })}
                onClick={handleOnClick}
            >
                {iconType && <ItemIcon type={iconType} />}
                <span>{useFullName ? item.full_name || item.label : item.label}</span>
                {isShippingLocation && children.length > 0 && (
                    <span className={'btn-collapse'} onClick={handleSectionCloseClick}>
                        <FlatIcon icon={currentSectionChevron} />
                    </span>
                )}
            </NavLink>
            {children && children.length > 0 && (
                <div className={'family-tree-entry-children'}>
                    {children.map((child) => {
                        if (child['@type'] === ClassificationType.ShippingLocation) {
                            return (
                                <ClassificationItem
                                    key={`${child['@id']}`}
                                    basePath={basePath}
                                    queries={queries}
                                    item={child}
                                    departmentOnly={departmentOnly}
                                    onClick={onClick}
                                    useFullName={useFullName}
                                />
                            )
                        } else {
                            return (
                                <ClassificationItem
                                    departmentOnly={departmentOnly}
                                    key={`${(child as IBaseClassificationFamily).parent_ids.join('_')}_${child['@id']}`}
                                    basePath={basePath}
                                    queries={queries}
                                    item={child}
                                    onClick={onClick}
                                />
                            )
                        }
                    })}
                </div>
            )}
        </div>
    )
}

const ClassificationItemEntry = memo(ClassificationItem)

function FamilyTree({
    title,
    basePath,
    useFullName,
    tree,
    departmentOnly,
    onClick,
    onTitleClick,
}: IProps): JSX.Element {
    const { queries: listParsedQueries } = useProductListParsedQuery()
    const queries = useMemo(() => {
        if (!listParsedQueries) {
            return undefined
        }
        const qs = {}
        if (listParsedQueries.itemsPerPage && listParsedQueries.itemsPerPage !== Config.PRODUCT_LIST.ITEMS_PER_PAGE) {
            qs[ProductListQueryName.ItemsPerPage] = listParsedQueries.itemsPerPage
        }
        if (listParsedQueries.displayMode === ProductsListDisplayMode.GridData) {
            qs[ProductListQueryName.DisplayMode] = listParsedQueries.displayMode
        }
        if (listParsedQueries.productGridId) {
            qs[ProductListQueryName.ProductGrid] = listParsedQueries.productGridId
        }
        // extraction des paramètres
        return Object.keys(qs).length > 0 ? qs : undefined
    }, [listParsedQueries])

    const handleOnTitleClick = useCallback(() => {
        if (onTitleClick) {
            onTitleClick()
        }
    }, [onTitleClick])

    return (
        <div className={'product-list-family-tree'}>
            <div
                className={classNames('family-tree-entry', 'family-tree-entry-heading')}
                onDoubleClick={handleOnTitleClick}
            >
                <span className={'link'}>
                    {/*<FlatIcon icon={'list'} />*/}
                    <span>
                        {!title && <FormattedMessage id={'products.sidebar.title'} />}
                        {title}
                    </span>
                </span>
            </div>
            {tree.map((shippingLocation) => (
                <ClassificationItemEntry
                    basePath={basePath}
                    queries={queries}
                    onClick={onClick}
                    item={shippingLocation}
                    key={`shipping_location_${shippingLocation['@id']}`}
                    departmentOnly={departmentOnly}
                    useFullName={useFullName}
                />
            ))}
        </div>
    )
}

FamilyTree.defaultProps = {
    title: undefined,
    disabled: false,
    departmentOnly: false,
    useFullName: false,
    basePath: '',
} as Partial<IProps>

export default memo(FamilyTree)
