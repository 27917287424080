import React, { useCallback, useMemo, useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { getPublicOrderStates } from '../../../../utils/order'

const StateFilter = ({ column: { filterValue, setFilter, defaultCanFilter, canFilter } }): JSX.Element => {
    const { formatMessage } = useIntl()
    const [currentValue, setCurrentValue] = useState<string>(filterValue)
    const [changeTimeoutId, setChangeTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)

    const orderStates = useMemo(() => {
        return getPublicOrderStates()
    }, [])

    const handleChange = useCallback(
        (e) => {
            const inputValue = e.target.value
            if (changeTimeoutId) {
                clearTimeout(changeTimeoutId)
            }

            setCurrentValue(inputValue)
            const timeoutId = setTimeout(() => {
                setFilter(inputValue || undefined)
            }, 400)
            setChangeTimeoutId(timeoutId)
        },
        [changeTimeoutId, setChangeTimeoutId, setFilter]
    )

    if (!defaultCanFilter || !canFilter) {
        return <></>
    }

    return (
        <div className="filter">
            <div className="form-group">
                <FormControl as="select" value={currentValue} onChange={handleChange} custom>
                    <option key={'default'} value={''} />
                    {orderStates.map((value) => (
                        <option key={value} value={value}>
                            {formatMessage({ id: `orders.state.${value}` })}
                        </option>
                    ))}
                </FormControl>
                <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} className="app-icon" />
            </div>
        </div>
    )
}

export default StateFilter
