import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import { IPaymentModeListApiResponse, IPaymentModeListParameters } from '../../services/api/service/payment-modes/types'
import { IPaymentModeListProcessPayload } from './types'

export const paymentModesListProcessAction = (params: IPaymentModeListParameters = {}) =>
    // eslint-disable-next-line no-undef
    action<ActionTypes.LIST_PROCESS_ACTION, IPaymentModeListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })
export const paymentModesListSuccessAction = (response: IPaymentModeListApiResponse) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IPaymentModeListApiResponse>(ActionTypes.LIST_SUCCESS_ACTION, response)
export const paymentModesListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const ordersResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const paymentModesListResetAction = () => action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)

export const paymentModesResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
