import React, { ChangeEvent, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { useIntl } from 'react-intl'

const BooleanFilter = ({ column: { filterValue, setFilter, defaultCanFilter, canFilter } }): JSX.Element => {
    const { formatMessage } = useIntl()

    const handleChange = useCallback(
        (event: ChangeEvent) => {
            setFilter((event.currentTarget as HTMLInputElement).value)
        },
        [setFilter]
    )

    if (!defaultCanFilter || !canFilter) {
        return <></>
    }

    return (
        <Form.Control onChange={handleChange} as="select" custom defaultValue={filterValue}>
            <option value="" key="nope">
                {''}
            </option>
            <option value="1">{formatMessage({ id: 'default.yes' })}</option>
            <option value="0">{formatMessage({ id: 'default.no' })}</option>
        </Form.Control>
    )
}

export default BooleanFilter
