/**
 *
 * CmsWidgetFactory
 *
 */
import React, { memo } from 'react'
import { IBaseCmsWidget } from '../../services/api/service/cms/types'
import { CmsWidgetCallbackList } from '../../types/widget'
import CmsWidgetFactory from './CmsWidgetFactory'

type IProps = CmsWidgetCallbackList & {
    widgets: Array<IBaseCmsWidget>
}

function CmsWidgetList({ widgets, ...rest }: IProps): JSX.Element {
    return (
        <div className={'cms-widget-collection'}>
            {widgets.map((widget, index) => (
                <CmsWidgetFactory widget={widget} key={`cms_widget_${widget.widget}_${index}`} lazy={false} {...rest} />
            ))}
        </div>
    )
}

export default memo(CmsWidgetList)
