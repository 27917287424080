/**
 *
 * Video
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { CmsWidgetTheme, CmsWidgetType, ICmsWidgetVideo } from '../../../../services/api/service/cms/types'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import isExternalRegexClosure from '../../../../utils/location'
import isEmpty from 'lodash/isEmpty'

interface IProps {
    widget: ICmsWidgetVideo
    onLinkClick?: CmsWidgetLinkClickCallback
}

function Video({ widget, onLinkClick }: IProps): JSX.Element {
    const backgroundRef = useRef<HTMLDivElement | null>(null)

    const triggerClick = useCallback(() => {
        onLinkClick?.({
            widget: CmsWidgetType.Video,
            url: widget.configuration.link?.url || null,
            internal:
                widget.configuration.link?.internal ||
                (widget.configuration.link?.url ? isExternalRegexClosure(widget.configuration.link?.url) : false),
            blank: widget.configuration.link?.blank || false,
            text: widget.configuration.button_label,
            extra: {
                widget,
            },
        })
    }, [onLinkClick, widget])

    const handleOnWidgetClick = useCallback(() => {
        if (widget.configuration.interactive) {
            return
        }
        triggerClick()
    }, [triggerClick, widget])

    const handleOnButtonClick = useCallback(() => {
        if (!widget.configuration.interactive) {
            return
        }
        triggerClick()
    }, [triggerClick, widget])

    const Component = useMemo(
        () => (
            <iframe
                frameBorder={0}
                allowFullScreen
                allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                src={widget.configuration.embed_url}
                width={widget.configuration.width}
                height={widget.configuration.height}
            />
        ),
        [widget.configuration.embed_url, widget.configuration.width, widget.configuration.height]
    )

    useEffect(() => {
        if (!backgroundRef.current) {
            return
        }
        backgroundRef.current.style.setProperty(
            '--app-cms-widget-video-ratio',
            `${(widget.configuration.height / widget.configuration.width) * 100}%`
        )
    }, [backgroundRef, widget.configuration.width, widget.configuration.height])

    return (
        <div
            className={classNames(
                'cms-widget',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                `cms-widget-video-mode-${widget.configuration.interactive ? 'interactive' : 'background'}`,
                { 'with-link': !isEmpty(widget.configuration.link) },
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <div className="container" onClick={handleOnWidgetClick}>
                    <div className={'heading'}>
                        {widget.configuration.title && <p className={'h1 hv'}>{widget.configuration.title}</p>}
                        {widget.configuration.subtitle && <p className={'h6'}>{widget.configuration.subtitle}</p>}
                    </div>
                    <div className="cms-widget-video-element" ref={backgroundRef}>
                        <div className="cms-widget-video-element-container">{Component}</div>
                        <div className="cms-widget-video-element-overlay" />
                    </div>
                    {widget.configuration.link && widget.configuration.button_label && (
                        <button onClick={handleOnButtonClick} className={'btn btn-primary btn-lg btn-detail'}>
                            <span>{widget.configuration.button_label}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

Video.defaultProps = {} as Partial<IProps>

export default memo(Video)
