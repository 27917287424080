import { call, cancelled, getContext, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import AuthActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { ISearchSuggestionProcessAction } from './types'
import axios, { CancelTokenSource } from 'axios'
import { LOCATION_CHANGE } from 'connected-react-router'
import { IProductListPersistParameters } from '../../services/api/service/products/types'
import { makeSelectProductsListPersistSettings } from '../products/selectors'
import { formatAppError } from '../app/saga'
import {
    searchSuggestionFailureAction,
    searchSuggestionResetAction,
    searchSuggestionStoreToken,
    searchSuggestionSuccessAction,
} from './actions'
import {
    IApiSearchSuggestionResponse,
    ISearchSuggestionFamily,
    ISearchSuggestionFamilyCollection,
    ISearchSuggestionProduct,
    ISearchSuggestionProductCollection,
    SearchSuggestionType,
} from '../../services/api/service/search/types'
import { IApplicationRootState } from '../index'
import { matchPath } from 'react-router-dom'
import { routes } from '../../routes'
import { productsSearchResetAction } from '../products/actions'
import Qs from 'qs'
import { makeSelectSearchSuggestionCancelToken, makeSelectSearchSuggestionParams } from './selectors'

function* processSearchReset() {
    const source: CancelTokenSource = yield select(makeSelectSearchSuggestionCancelToken())
    if (source) {
        source.cancel('cancelled')
    }
}

function* processResetSearch() {
    const state: IApplicationRootState = yield select()
    const router = state.router
    const { location } = router
    const source: CancelTokenSource = yield select(makeSelectSearchSuggestionCancelToken())
    const qparams = yield select(makeSelectSearchSuggestionParams())

    if (source) {
        source.cancel('cancelled')
    }

    // @ts-ignore
    if (!location.state || !location.state.background) {
        // @ts-ignore
        if (location.state && location.state.prev) {
            // @ts-ignore
            const matchPath2 = matchPath<{ lang?: string }>(location.state.prev.pathname, {
                path: routes.product.match,
            })
            if (matchPath2?.isExact) {
                return
            }
        }
        // pour finir on check si on a des element
        const parsed = Qs.parse(window.location.search.substring(1))
        if (qparams && qparams.search && qparams.search.length > 0 && !parsed.search) {
            yield put(productsSearchResetAction())
            yield put(searchSuggestionResetAction())
        }
    }
}

// TOKEN
const CancelToken = axios.CancelToken
function* processSearchRequest(action: ISearchSuggestionProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const params = { ...action.payload.params }

    yield put(searchSuggestionStoreToken(source))
    // LISTED ONLY ?
    const settings: IProductListPersistParameters | undefined = yield select(makeSelectProductsListPersistSettings())
    if (settings && settings.listed_only) {
        params.listed_only = 1
    }

    try {
        const response: IApiSearchSuggestionResponse = yield call(
            { context: api.search, fn: 'search' },
            params,
            source.token
        )
        const data = response.data['hydra:member']
        const products: ISearchSuggestionProductCollection = []
        const families: ISearchSuggestionFamilyCollection = []
        data.forEach(function (value) {
            if (value.type === SearchSuggestionType.Family) {
                families.push(value.data as ISearchSuggestionFamily)
            } else {
                products.push(value.data as ISearchSuggestionProduct)
            }
        })
        yield put(searchSuggestionSuccessAction(families, products))
    } catch (e) {
        const error = yield call(formatAppError, e, 'products.unknow_error')
        yield put(searchSuggestionFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(AuthActionTypes.RESET_ACTION, processSearchReset),
    takeLatest(AuthActionTypes.PROCESS_ACTION, processSearchRequest),
    takeEvery(LOCATION_CHANGE, processResetSearch),
]
