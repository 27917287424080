/**
 *
 * Suggestion Card
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { Col, Row, Breadcrumb } from 'react-bootstrap'
import { ProductClassificationClickCallback, ProductClickCallback } from '../../../types/productCallback'
import {
    Heading as ProductHeading,
    MainPicture,
    Packing as ProductPacking,
    Price as ProductPrice,
    Ean as ProductEan,
    Reference as ProductReference,
} from '../Partial'
import { ISearchSuggestionProduct } from '../../../services/api/service/search/types'
import { FamilyType } from '../../../services/api/service/classification/types'
import { $enum } from 'ts-enum-util'

export interface IProps {
    product: ISearchSuggestionProduct
    className?: string
    onProductClick?: ProductClickCallback
    onClassificationItemClick?: ProductClassificationClickCallback
    showPacking?: boolean
    showPrices?: boolean
    column?: number
    noGutters?: boolean
}

function Suggestion({
    product,
    className,
    onProductClick,
    onClassificationItemClick,
    showPacking,
    showPrices,
    column,
    noGutters,
}: IProps): JSX.Element {
    const handleClassificationClick = useCallback(
        (evt) => {
            const classificationType = evt.currentTarget.dataset.type
            evt.preventDefault()
            evt.stopPropagation()

            if ($enum(FamilyType).isValue(classificationType) && onClassificationItemClick) {
                onClassificationItemClick(product, classificationType)
            }
        },
        [product, onClassificationItemClick]
    )

    const handleProductClick = useCallback(() => {
        if (onProductClick) {
            onProductClick(product)
        }
    }, [onProductClick])

    return (
        <div
            className={classNames('product', 'product-card', 'product-suggestion', className)}
            onClick={handleProductClick}
        >
            <Row noGutters={noGutters}>
                <Col xs={column}>
                    <MainPicture product={product} />
                </Col>
                <Col>
                    <ProductHeading product={product} showEan={false} showEanLabel={false} showReference={false} />
                    {(product.department || product.family || product.sub_family) && (
                        <Breadcrumb>
                            {product.department && (
                                <Breadcrumb.Item
                                    title={product.department.label}
                                    onClick={handleClassificationClick}
                                    data-type={FamilyType.Department}
                                >
                                    {product.department.label}
                                </Breadcrumb.Item>
                            )}
                            {product.family && (
                                <Breadcrumb.Item
                                    title={product.family.label}
                                    onClick={handleClassificationClick}
                                    data-type={FamilyType.Family}
                                >
                                    {product.family.label}
                                </Breadcrumb.Item>
                            )}
                            {product.sub_family && (
                                <Breadcrumb.Item
                                    title={product.sub_family.label}
                                    onClick={handleClassificationClick}
                                    data-type={FamilyType.SubFamily}
                                >
                                    {product.sub_family.label}
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    )}
                    <ProductReference reference={product.reference} showLabel />
                    <ProductEan ean13={product.ean13} showLabel />
                    {showPacking && <ProductPacking packing={product.packing} unitOfSale={product.unit_of_sale} />}
                    {showPrices && (
                        <ProductPrice
                            price={product.price}
                            strikePrice={product.strike_price}
                            retailPrice={product.retail_price}
                            unitOfSale={product.unit_of_sale}
                        />
                    )}
                </Col>
            </Row>
        </div>
    )
}

Suggestion.defaultProps = {
    showPacking: false,
    showPrices: false,
    column: 6,
    noGutters: true,
} as Partial<IProps>

export default memo(Suggestion)
