import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { IPictureExport, PictureExportState } from '../../services/api/service/pictureExport/types'
import { createStructuredSelector } from 'reselect'

import {
    makeSelectPictureExportListError,
    makeSelectPictureExportListFetching,
    makeSelectPictureExportListItems,
    makeSelectPictureExportListTotalItems,
} from '../../store/pictureExport/selectors'
import { useHistory } from 'react-router-dom'
import Config from '../../config'
import { extractPaginationQueryParameters } from '../../utils/pagination'
import { pictureExportListProcessAction } from '../../store/pictureExport/actions'
import Qs from 'qs'
import rtrim from '../../utils/rtrim'
import paginator, { PaginatorResults } from 'paginator'
import ReactPlaceholder from 'react-placeholder'
import { Component as Pagination, Placeholder as PaginationPlaceholder } from '../../components/Pagination'
import FlatIcon from '../../components/Icon/FlatIcon'
import Button from '../../components/Buttons/Button'
import moment from 'moment'
import TablePlaceholder from '../../components/Table/Placerholder'

const stateSelector = createStructuredSelector<any, any>({
    listFetching: makeSelectPictureExportListFetching(),
    listItems: makeSelectPictureExportListItems(),
    listTotalItems: makeSelectPictureExportListTotalItems(),
    listError: makeSelectPictureExportListError(),
})

function PictureExports(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const history = useHistory()
    const dispatch = useDispatch()

    const pageRangeDisplayed = Config.PICTURE_EXPORT.PAGE_RANGE_DISPLAYED
    const [listPagination, setListPagination] = useState<PaginatorResults | undefined>(undefined)
    const { page, itemsPerPage, search } = useMemo(extractPaginationQueryParameters, [window.location.search])
    const [selectedItem, setSelectedItem] = useState<IPictureExport>()

    const { listFetching, listItems, listError, listTotalItems } = useSelector<
        IApplicationRootState,
        {
            listFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IPictureExport>
            listError?: IAppErrorTypes | undefined
        }
    >(stateSelector)

    useEffect(() => {
        if (typeof listTotalItems === 'undefined') {
            setListPagination(undefined)
            return
        }
        const pagination = new paginator(itemsPerPage, pageRangeDisplayed).build(listTotalItems, page)
        setListPagination(pagination)
    }, [listTotalItems, itemsPerPage, page, pageRangeDisplayed])

    useEffect(() => {
        dispatch(pictureExportListProcessAction({ page, itemsPerPage, search }))
    }, [dispatch, page, itemsPerPage, search])

    useEffect(() => {
        if (!listFetching && !listError && typeof listTotalItems === 'undefined') {
            dispatch(pictureExportListProcessAction({ page, itemsPerPage, search }))
        }
    }, [dispatch, listFetching, listItems, listTotalItems, search, itemsPerPage, search, listError, page])

    useEffect(() => {
        if (page > 1 || (search && search.length > 0)) {
            dispatch(pictureExportListProcessAction({ page, itemsPerPage, search }))
        }
    }, [dispatch, page, itemsPerPage, search])

    const handlePageChange = (selectedPage: number): void => {
        const parsed = Qs.parse(window.location.search.substring(1))
        parsed.page = String(selectedPage)
        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(window.location.pathname, '/')
        history.push(`${baseUrl}${qstring}`)
    }

    const handleDownloadClick = useCallback(
        (item: IPictureExport) => {
            setSelectedItem(item)
        },
        [setSelectedItem]
    )

    return (
        <div className={'picture-export-section'}>
            <Row>
                <Col>
                    <Card className={classNames('member-card', 'customer-salesmen')}>
                        <Card.Header>
                            <h3 className={'hv'}>
                                <FormattedMessage id={'customer.picture_export'} />
                            </h3>
                        </Card.Header>
                        <Card.Body>
                            <div className={'product-export-list-section'} id={'product-export-list-section'}>
                                <div className={'product-export-list-content'}>
                                    <ReactPlaceholder
                                        ready={
                                            typeof listTotalItems !== 'undefined' || typeof listError !== 'undefined'
                                        }
                                        customPlaceholder={<TablePlaceholder itemsPerPage={5} columns={4} />}
                                    >
                                        {listError && <Alert variant={'danger'}>{listError!.message}</Alert>}
                                        {!listFetching && listPagination?.total_results === 0 && (
                                            <Alert variant={'danger'}>
                                                {formatMessage({ id: 'picture_exports.no_results' })}
                                            </Alert>
                                        )}

                                        {!listFetching && listPagination?.total_results === 0 && search && (
                                            <Alert variant={'danger'}>
                                                {formatMessage({ id: 'picture_exports.no_searched_results' })}
                                            </Alert>
                                        )}
                                        {listItems && listItems.length > 0 && (
                                            <Table className={'picture-export-table'} striped hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th className={'cell date'}>
                                                            <FormattedMessage id="default.created_at" />
                                                        </th>
                                                        <th className={'cell status'}>
                                                            <FormattedMessage id="default.state" />
                                                        </th>
                                                        <th className={'cell number'}>
                                                            <FormattedMessage id="default.products_count" />
                                                        </th>
                                                        <th className={'cell actions'}>
                                                            <FormattedMessage id={'default.actions'} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listItems.map((listItem) => {
                                                        return (
                                                            <tr key={listItem['@id']}>
                                                                <td className={'cell date'}>
                                                                    {moment(listItem.created_at, 'YYYY-MM-DD').format(
                                                                        'DD/MM/YYYY'
                                                                    )}
                                                                </td>
                                                                <td className={'cell status'}>
                                                                    {listItem.state_label}
                                                                </td>
                                                                <td className={'cell number'}>
                                                                    {listItem.products_count}
                                                                </td>
                                                                <td className={'cell actions'}>
                                                                    {listItem.state === PictureExportState.Ready && (
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip
                                                                                    id={`tooltip-${listItem['@id']}-export`}
                                                                                >
                                                                                    <FormattedMessage
                                                                                        id={
                                                                                            'product_export.download_help'
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button
                                                                                size="sm"
                                                                                variant="link"
                                                                                onClick={() =>
                                                                                    handleDownloadClick(listItem)
                                                                                }
                                                                            >
                                                                                <FlatIcon icon="download" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        )}
                                    </ReactPlaceholder>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer className={classNames({ 'd-none': listError })}>
                            <ReactPlaceholder
                                ready={typeof listPagination !== 'undefined'}
                                customPlaceholder={<PaginationPlaceholder />}
                            >
                                <Pagination
                                    disabled={listFetching}
                                    currentPage={listPagination?.current_page}
                                    totalPages={listPagination?.total_pages}
                                    firstPage={listPagination?.first_page}
                                    lastPage={listPagination?.last_page}
                                    hasNextPage={listPagination?.has_next_page}
                                    hasPreviousPage={listPagination?.has_previous_page}
                                    previousPage={listPagination?.previous_page}
                                    nextPage={listPagination?.next_page}
                                    pageRangeDisplayed={pageRangeDisplayed}
                                    onPageClick={handlePageChange}
                                />
                            </ReactPlaceholder>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            {selectedItem && selectedItem.state === PictureExportState.Ready && selectedItem.url && (
                <iframe src={selectedItem.url} width={0} height={0} style={{ border: '0 none' }} />
            )}
        </div>
    )
}

export default memo(PictureExports)
