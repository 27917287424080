import React, { memo, useCallback, useMemo } from 'react'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductGrids,
    makeSelectProductListGridId,
    makeSelectProductsListDisplayMode,
    makeSelectProductsListFetching,
    makeSelectProductsListSelectionAction,
} from '../../../store/products/selectors'
import { IApplicationRootState } from '../../../store'
import { IApiProductGridCollection, ProductsListDisplayMode } from '../../../services/api/service/products/types'
import { useSelector } from 'react-redux'
import { makeSelectCartBulkCartQuantityFetching } from '../../../store/carts/selectors'
import Component, {
    IProductsListDisplayModeChoiceProps,
} from '../../../components/Products/Content/ProductsListDisplayModeChoice'
import { Optional } from 'utility-types'
import useProductsListDisplayMode from '../../../utils/hook/useProductsListDisplayMode'

const stateSelector = createStructuredSelector<
    IApplicationRootState,
    {
        listFetching: boolean
        bulkFetching: boolean
        grids: IApiProductGridCollection
        productGridId?: string
        displayMode: ProductsListDisplayMode
        listPickerActionCode?: string
    }
>({
    listPickerActionCode: makeSelectProductsListSelectionAction(),
    listFetching: makeSelectProductsListFetching(),
    bulkFetching: makeSelectCartBulkCartQuantityFetching(),
    displayMode: makeSelectProductsListDisplayMode(),
    productGridId: makeSelectProductListGridId(),
    grids: makeSelectProductGrids(),
})

type IProps = Optional<
    Omit<IProductsListDisplayModeChoiceProps, 'disabled' | 'show' | 'defaultValue' | 'value' | 'displayMode' | 'values'>
>

const ProductsListDisplayModeChoice = ({ onChange }: IProps): JSX.Element => {
    const { switchDisplayMode } = useProductsListDisplayMode()
    const { listFetching, bulkFetching, grids, displayMode, productGridId, listPickerActionCode } =
        useSelector(stateSelector)
    const enabled = useMemo(() => {
        return !listFetching && !bulkFetching && typeof listPickerActionCode === 'undefined'
    }, [listFetching, bulkFetching, listPickerActionCode])

    const handleChange = useCallback(
        (displayMode: ProductsListDisplayMode, gridId?: string) => {
            // on switch
            switchDisplayMode(displayMode, gridId)

            // on indique le changement
            if (onChange) {
                onChange(displayMode, gridId)
            }
        },
        [switchDisplayMode, onChange]
    )

    return (
        <Component
            disabled={!enabled}
            values={grids}
            displayMode={displayMode}
            value={productGridId}
            onChange={handleChange}
        />
    )
}

export default memo(ProductsListDisplayModeChoice)
