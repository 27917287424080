/*
 *
 * SplashScreen actions
 *
 */

import { action } from 'typesafe-actions'
import { Action } from 'redux'

import ActionTypes from './constants'

export interface ISplashScreenParameter {
    animated: boolean
}

export interface ISplashScreenShowAction extends Action {
    show: boolean
    animated: boolean
}

export const splashScreenShowAction = (animated: boolean) =>
    action<ActionTypes.SHOW_ACTION, ISplashScreenParameter>(ActionTypes.SHOW_ACTION, { animated })
export const splashScreenHideAction = (animated: boolean) =>
    action<ActionTypes.HIDE_ACTION, ISplashScreenParameter>(ActionTypes.HIDE_ACTION, { animated })
