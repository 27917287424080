import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { FlashMessageState } from 'redux-flash-messages/lib/flash-message-reducer'
import { MessageTypes } from 'redux-flash-messages/lib'

/**
 * Direct selector to the app state domain
 */

const selectAppDomain = (state: IApplicationRootState) => {
    return state.app || initialState
}

const selectFlashMessagesDomain = (state: IApplicationRootState): FlashMessageState => {
    return state.flashMessage || { messages: [] }
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by App
 */

const makeSelectAppBootstrap = () =>
    createSelector(selectAppDomain, (substate) => {
        return substate.bootstrap
    })

const makeSelectAppFatal = () =>
    createSelector(selectAppDomain, (substate) => {
        return substate.fatal
    })

const makeSelectAppReady = () =>
    createSelector(selectAppDomain, (substate) => {
        return substate.ready
    })

const makeSelectAppReload = () =>
    createSelector(selectAppDomain, (substate) => {
        return substate.reload
    })

const makeSelectAppError = () =>
    createSelector(selectAppDomain, (substate) => {
        return substate.error
    })

const makeSelectFlashMessages = () =>
    createSelector(selectFlashMessagesDomain, (substate) => {
        return substate.messages
    })

const makeSelectApocalypseFlashMessages = () =>
    createSelector(selectFlashMessagesDomain, (substate) => {
        return substate.messages.filter((message) => message.type === MessageTypes.Apocalypse)
    })

export {
    selectAppDomain,
    makeSelectAppReload,
    makeSelectAppBootstrap,
    makeSelectAppReady,
    makeSelectAppFatal,
    makeSelectAppError,
    makeSelectFlashMessages,
    makeSelectApocalypseFlashMessages,
}
