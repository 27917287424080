/**
 *
 * Entry
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IEntryProps } from './type'
import { customerShowPrices, findCustomerStore } from '../../../store/customers/utils'
import { ICustomer } from '../../../services/api/service/customers/types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Buttons/Button'
import CartMinimumAmountAlert from '../../../components/Carts/CartMinimumAmountAlert'
import FlatIcon from '../../../components/Icon/FlatIcon'
import Price from '../../../components/Price/Price'
import { ICart, ICartMode } from '../../../services/api/service/carts/types'

import { IApplicationRootState } from '../../../store'
import { cartLoadCartProcessAction } from '../../../store/carts/actions'
import { canUnlockOrder, isMinimumAmountReached } from '../../../store/carts/utils'

import CreateOrder from '../../CreateOrder/CreateOrder'
import SubCartModal from '../SubCartModal'
import ContainerVolumes from '../../../components/ContainerVolume/ContainerVolumes'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectCartsLockerErrors,
    makeSelectCartsLockerFetching,
    makeSelectCartsLockerSuccess,
} from '../../../store/carts/selectors'
import { makeSelectAuthMe } from '../../../store/auth/selectors'
import { makeSelectCustomer } from '../../../store/customers/selectors'
import { IMe } from '../../../services/api/service/me/types'
import CartLocker from '../CartLocker'

const stateSelector = createStructuredSelector<any, any>({
    lockersFetching: makeSelectCartsLockerFetching(),
    lockersErrors: makeSelectCartsLockerErrors(),
    lockersSuccess: makeSelectCartsLockerSuccess(),
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
})

function Entry({ child, customer, cartParentId, cartMode, cartValidationMode }: IEntryProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const {
        // on récupère le client principal le client passé au composant pouvant être un store qui ne possède pas les bonnes propriétés
        // pour nos vérifications (ex: hide prices)
        customer: currentCustomer,
        me,
    } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            customer: ICustomer
        }
    >(stateSelector)
    const [showLockerPopover, setShowLockerPopover] = useState(false)
    const [editing, setEditing] = useState<boolean>(false)
    const loading = useSelector<IApplicationRootState, boolean>((state) => {
        return typeof state.carts.load[child['@id']] === 'undefined' ? false : state.carts.load[child['@id']].fetching
    })
    const cart = useSelector<IApplicationRootState, ICart | undefined>((state) => {
        return state.carts.list.find((cart: ICart) => cart['@id'] === child['@id'])
    })
    const cartParent = useSelector<IApplicationRootState, ICart | undefined>((state) => {
        return state.carts.list.find((cart: ICart) => cart['@id'] === cartParentId)
    })

    // est ce qu'on affiche les containers
    const showContainerVolumes = useMemo(() => {
        return customer.show_container_volume
    }, [customer])

    // récupération du magasin
    const store: ICustomer = useMemo(() => {
        return findCustomerStore(customer, child.customer!)
    }, [customer, child])

    const showPrices = useMemo(() => {
        // on récupère le client principal le client passé au composant pouvant être un store qui ne possède pas les bonnes propriétés
        return customerShowPrices(currentCustomer, me)
    }, [currentCustomer, me])

    const showPacking = useMemo(() => {
        // on récupère le client principal le client passé au composant pouvant être un store qui ne possède pas les bonnes propriétés
        return currentCustomer?.order_by_unit === false
    }, [currentCustomer])

    // on regarde si le minimum est atteint
    const minimumReached = useMemo(() => {
        return isMinimumAmountReached(child, store, ICartMode.Default, customer.minimum_amount_mode)
    }, [child, store, customer])

    // on forge l'adresse du magasin
    const storeAddress = useMemo(() => {
        let address = ''

        if (store.address1) {
            address += store.address1
        }
        if (store.address2) {
            address += ' ' + store.address2
        }

        if (store.postcode) {
            address += ' ' + store.postcode
        }
        if (store.city) {
            address += ' ' + store.postcode
        }

        if (store.country_name) {
            address += (address.length > 0 ? ', ' : '') + store.country_name
        }
        return address
    }, [store])

    const handleCartLightItemMouseLeave = useCallback(() => {
        setShowLockerPopover(false)
    }, [setShowLockerPopover])

    const handleLockerMouseEnter = useCallback(() => {
        setShowLockerPopover(true)
    }, [setShowLockerPopover])

    const handleLockerPopoverMouseEnter = useCallback(() => {
        setShowLockerPopover(true)
    }, [setShowLockerPopover])

    const handleLockerPopoverMouseLeave = useCallback(() => {
        setShowLockerPopover(false)
    }, [setShowLockerPopover])

    const handleEditCart = useCallback(() => {
        dispatch(cartLoadCartProcessAction(child['@id'], false))
        setEditing(true)
    }, [child, dispatch, setEditing])

    const handleSubCartModalExit = useCallback(() => {
        setEditing(false)
    }, [setEditing])

    const handleOnCartEditClick = useCallback(() => {
        setEditing(true)
    }, [setEditing])

    const currentCartId = useMemo(() => {
        return cart ? cart['@id'] : child['@id']
    }, [cart, child])

    const totalVolume = useMemo(() => {
        return cart ? cart.total_volume : child.total_volume
    }, [cart, child])

    const canUnlockOrders = useMemo(() => {
        return customer && canUnlockOrder(customer)
    }, [customer])

    const isLocked = useMemo(() => {
        return cart ? cart.locked : child.locked
    }, [cart, child])

    return (
        <>
            <div
                className={classNames('cart-light-item', { 'minimum-reached': minimumReached })}
                onMouseLeave={handleCartLightItemMouseLeave}
            >
                <div className={'cart-light-resume'}>
                    <div className={'account-card'}>
                        <p className={'label'}>
                            {canUnlockOrders && isLocked && (
                                <CartLocker
                                    cart={cart || child}
                                    customer={customer}
                                    showPopover={showLockerPopover}
                                    onMouseEnter={handleLockerMouseEnter}
                                    onPopoverMouseEnter={handleLockerPopoverMouseEnter}
                                    onPopoverMouseLeave={handleLockerPopoverMouseLeave}
                                />
                            )}
                            {store.business_name} - <i>{storeAddress}</i>
                        </p>
                    </div>
                    <div className={'resume-quantity'}>
                        <span className={'quantity'}>
                            <FormattedMessage id={'cart.quantity'} /> :{' '}
                            <span className={'value'}>{child.total_quantity}</span>
                        </span>{' '}
                        {showPacking && (
                            <>
                                -{' '}
                                <span className={'packing'}>
                                    <FormattedMessage id={'product.packing'} /> : {child.package_count}{' '}
                                    <FlatIcon icon={'box'} />
                                </span>{' '}
                            </>
                        )}
                        {showPrices && (
                            <>
                                {'- '}
                                <span className={'total-amount'}>
                                    <FormattedMessage id={'cart.total_price_excluding_taxes'} /> :{' '}
                                    <Price price={child.total} />
                                </span>
                            </>
                        )}
                    </div>
                    {showPrices && (
                        <CartMinimumAmountAlert
                            cart={cart || child}
                            cartMode={cartMode}
                            cartValidationMode={cartValidationMode}
                            customer={customer}
                            store={store}
                            minimumAmountMode={customer.minimum_amount_mode}
                            abbreviate={true}
                            reachedIcon={'check'}
                            unreachedIcon={'warning'}
                        />
                    )}
                </div>
                <div className={'cart-light-action'}>
                    {showContainerVolumes && (
                        <Row noGutters>
                            <Col xs={24}>
                                <ContainerVolumes uniqId={currentCartId} totalVolume={totalVolume} xs={24} lg={12} />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={24} xl={true}>
                            <Button
                                block
                                variant={'secondary'}
                                onClick={handleEditCart}
                                size={'sm'}
                                loading={editing && loading}
                                disabled={editing || loading}
                            >
                                <FontAwesomeIcon icon={'edit'} className={'app-icon'} />
                                <FormattedMessage id={'default.edit'} />
                            </Button>
                        </Col>
                        <Col xs={24} xl={true}>
                            <CreateOrder
                                cartId={child['@id']}
                                child={child}
                                store={store}
                                customer={customer}
                                minimumAmountMode={customer.minimum_amount_mode}
                                mainCart={false}
                                buttonSize={'sm'}
                                buttonLabel={formatMessage({ id: 'default.validate' })}
                                onCartEditClick={handleOnCartEditClick}
                            />
                        </Col>
                    </Row>
                </div>
                <SubCartModal
                    show={editing && typeof cart !== 'undefined'}
                    cartLight={child}
                    cartParent={cartParent}
                    onSubCartModalExit={handleSubCartModalExit}
                    store={store}
                    minimumAmountMode={customer.minimum_amount_mode}
                />
            </div>
        </>
    )
}

Entry.defaultProps = {} as Partial<IEntryProps>

export default memo(Entry)
