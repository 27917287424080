import { AxiosInstance, CancelToken } from 'axios'
import { IApiShopImportListParameters, IApiShopImportListResponse, IShopImportService, ShopImportType } from './types'
import { ICustomerType } from '../customers/types'

const create = (instance: AxiosInstance): IShopImportService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (
        params: IApiShopImportListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiShopImportListResponse> => {
        return instance.get('/imports', { params: params, cancelToken })
    }

    const getImportFileUrl = (locale: string, shopImportType: ShopImportType, customerType: ICustomerType): string => {
        return `${instance.defaults.baseURL}/xlsx/${locale}/imports/matrix/${shopImportType}/${customerType}`
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        getImportFileUrl,
    }
}

export default {
    create,
}
