import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerSalesmen from '../containers/CustomerSalesmen/Loadable'

export default function CustomerSalesmenPage(): JSX.Element {
    const { formatMessage } = useIntl()

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'customer.salesmen' })}</title>
            </Helmet>
            <CustomerSalesmen />
        </>
    )
}
