/*
 *
 * Customers actions
 *
 */

import { action } from 'typesafe-actions'
import { Action } from 'redux'

import { AxiosError } from 'axios'

import ActionTypes from './constants'
import { ICustomer, ICustomerModifyLoginParameters } from '../../services/api/service/customers/types'
import { IApiProductListPagination } from '../../services/api/service/products/types'
import {
    ICustomerGetSuccessPayload,
    ICustomerModifyLoginProcessPayload,
    ICustomerSetCurrentPayload,
    ICustomersViewedProductsInvalidatePayload,
    ICustomerSwitchStorePayload,
    ICustomerSalesmenFailurePayload,
    ICustomerSalesmenProcessPayload,
    ICustomerSalesmenSuccessPayload,
    ICustomerLogoutCurrentPayload,
} from './types'
import { IAppErrorTypes } from '../app/types'
import { ICustomerSalesmenCollection } from '../../services/api/service/salesman/types'

// export interface ICustomersDefaultParameters {}
export type ICustomersMeProcessAction = Action

export interface ICustomersMeSuccessAction extends Action {
    payload: ICustomerGetSuccessPayload
}

export const customerGetProcessAction = (customerId: string) =>
    action<ActionTypes.PROCESS_GET_ACTION, { customerId: string }>(ActionTypes.PROCESS_GET_ACTION, {
        customerId,
    })
export const customerGetSuccessAction = (customer: ICustomer, storeId?: string) =>
    action<ActionTypes.SUCCESS_GET_ACTION, ICustomerGetSuccessPayload>(ActionTypes.SUCCESS_GET_ACTION, {
        customer,
        storeId,
    })
export const customerGetFailureAction = (error: AxiosError) =>
    action<ActionTypes.FAILURE_GET_ACTION, { error: AxiosError }>(ActionTypes.FAILURE_GET_ACTION, { error })
export const customersResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
export const customersResetAllAction = () => action<ActionTypes.RESET_ALL_ACTION>(ActionTypes.RESET_ALL_ACTION)

export const customerViewedProductsProcessAction = () =>
    action<ActionTypes.PROCESS_VIEWED_PRODUCTS_ACTION>(ActionTypes.PROCESS_VIEWED_PRODUCTS_ACTION)

export const customerViewedProductsSuccessAction = (response: IApiProductListPagination) =>
    action<ActionTypes.SUCCESS_VIEWED_PRODUCTS_ACTION, IApiProductListPagination>(
        ActionTypes.SUCCESS_VIEWED_PRODUCTS_ACTION,
        response
    )
export const customerViewedProductsFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.FAILURE_VIEWED_PRODUCTS_ACTION, { error: IAppErrorTypes }>(
        ActionTypes.FAILURE_VIEWED_PRODUCTS_ACTION,
        { error }
    )

export const customerViewedProductsInvalidateAction = (productId: string) =>
    action<ActionTypes.VIEWED_PRODUCTS_INVALIDATE_ACTION, ICustomersViewedProductsInvalidatePayload>(
        ActionTypes.VIEWED_PRODUCTS_INVALIDATE_ACTION,
        { productId }
    )

export const customerSwitchStore = (storeId?: string, reloadApp = true, redirect = false) =>
    action<ActionTypes.SWITCH_STORE_ACTION, ICustomerSwitchStorePayload>(ActionTypes.SWITCH_STORE_ACTION, {
        storeId,
        reloadApp,
        redirect,
    })

export const customerSetCurrent = (customerId: string, reloadApp = false, redirectUrl?: string) =>
    action<ActionTypes.SET_CURRENT_CUSTOMER_ACTION, ICustomerSetCurrentPayload>(
        ActionTypes.SET_CURRENT_CUSTOMER_ACTION,
        {
            customerId,
            reloadApp,
            redirectUrl,
        }
    )

export const customerResetCurrent = () =>
    action<ActionTypes.RESET_CURRENT_CUSTOMER_ACTION>(ActionTypes.RESET_CURRENT_CUSTOMER_ACTION)

export const customerLogoutCurrent = (redirectUrl?: string) =>
    action<ActionTypes.LOGOUT_CURRENT_CUSTOMER_ACTION, ICustomerLogoutCurrentPayload>(
        ActionTypes.LOGOUT_CURRENT_CUSTOMER_ACTION,
        {
            redirectUrl,
        }
    )

export const customerModifyLoginProcessAction = (params: ICustomerModifyLoginParameters) =>
    action<ActionTypes.PROCESS_MODIFY_LOGIN_ACTION, ICustomerModifyLoginProcessPayload>(
        ActionTypes.PROCESS_MODIFY_LOGIN_ACTION,
        {
            ...params,
        }
    )
export const customerModifyLoginSuccessAction = () =>
    action<ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION>(ActionTypes.SUCCESS_MODIFY_LOGIN_ACTION)
export const customerModifyLoginFailureAction = (error: AxiosError) =>
    action<ActionTypes.FAILURE_MODIFY_LOGIN_ACTION, { error: AxiosError }>(ActionTypes.FAILURE_MODIFY_LOGIN_ACTION, {
        error,
    })
export const customerModifyLoginResetAction = () =>
    action<ActionTypes.RESET_MODIFY_LOGIN_ACTION>(ActionTypes.RESET_MODIFY_LOGIN_ACTION)

export const customerSalesmenProcessAction = () =>
    action<ActionTypes.SALESMEN_PROCESS_ACTION, ICustomerSalesmenProcessPayload>(
        ActionTypes.SALESMEN_PROCESS_ACTION,
        null
    )

export const customerSalesmenSuccessAction = (items: ICustomerSalesmenCollection) =>
    action<ActionTypes.SALESMEN_SUCCESS_ACTION, ICustomerSalesmenSuccessPayload>(ActionTypes.SALESMEN_SUCCESS_ACTION, {
        items,
    })

export const customerSalesmenFailureAction = (error: AxiosError) =>
    action<ActionTypes.SALESMEN_FAILURE_ACTION, ICustomerSalesmenFailurePayload>(ActionTypes.SALESMEN_FAILURE_ACTION, {
        error,
    })
