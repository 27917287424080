/*
 *
 * Config actions
 *
 */

import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import { IConfig } from '../../services/api/service/config/types'
import { SalesmanCartAlertDisplayPayload } from './types'

export const remoteConfigStoreAction = (data: IConfig) =>
    action<ActionTypes.STORE_REMOTE_ACTION, IConfig>(ActionTypes.STORE_REMOTE_ACTION, data)
export const salesmanConfigSetCartAlertDisplay = (displayed: boolean) =>
    action<ActionTypes.SET_SALESMAN_CART_ALERT_DISPLAY_ACTION, SalesmanCartAlertDisplayPayload>(
        ActionTypes.SET_SALESMAN_CART_ALERT_DISPLAY_ACTION,
        displayed
    )
export const configResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
