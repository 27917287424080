import React, { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { ICart, ICartLight } from '../../services/api/service/carts/types'
import { CmsPageIdentifierType } from '../../services/api/service/cms/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import Cart from './Cart'
import { Undefinable } from 'tsdef'

interface IProps {
    cartLight: Undefinable<ICartLight>
    store: Undefinable<ICustomer>
    minimumAmountMode: ICustomerMinimumAmountMode
    cartParent?: ICart
    onSubCartModalExit: () => void
    show: boolean
    showContainerVolumes?: boolean
}

function SubCartModal({
    minimumAmountMode,
    cartParent,
    cartLight,
    show,
    store,
    onSubCartModalExit,
    showContainerVolumes,
}: IProps): JSX.Element {
    const handleOnHide = () => {
        onSubCartModalExit()
    }

    const cartParentId = useMemo(() => {
        return cartParent ? cartParent['@id'] : undefined
    }, [cartParent])

    return (
        <Modal className={'sub-cart-modal'} show={show} size={'xl'} onHide={handleOnHide}>
            <Modal.Header closeButton>
                <Modal.Title>{store?.business_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {cartLight && (
                    <Cart
                        index={1}
                        main={false}
                        showSaveToOrderButton={true}
                        showContainerVolumes={showContainerVolumes}
                        cartId={cartLight['@id']}
                        cartParentId={cartParentId}
                        storeId={store?.id}
                        minimumAmountMode={minimumAmountMode}
                    />
                )}
            </Modal.Body>
        </Modal>
    )
}

SubCartModal.defaultProps = {
    identifierType: CmsPageIdentifierType.Code,
    preview: false,
    show: false,
} as Partial<IProps>

export default SubCartModal
