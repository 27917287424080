import { AxiosResponse, CancelToken } from 'axios'
import { IApiBooleanType, IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { IProductList } from '../products/types'
import { IDepartment, IFamily } from '../classification/types'

/*******************************
 * SEARCH
 * ****************************/
export enum SearchSuggestionType {
    Product = 'product',
    Family = 'family',
}

export type ISearchSuggestionParameters = IBasePaginationParameters & {
    listed_only?: IApiBooleanType
}

export type ISearchSuggestionProduct = IProductList & {
    family?: IFamily
    sub_family?: IFamily
    department?: IDepartment
}
export type ISearchSuggestionFamily = Pick<IFamily, 'id' | 'label'>
export interface ISearchSuggestion extends IApiResource {
    readonly type: SearchSuggestionType
    readonly data: ISearchSuggestionProduct | ISearchSuggestionFamily
}

export type ISearchSuggestionProductCollection = Array<ISearchSuggestionProduct>
export type ISearchSuggestionFamilyCollection = Array<ISearchSuggestionFamily>
export type ISearchSuggestionCollection = Array<ISearchSuggestion>

export type ISearchSuggestionPagination = IPaginationApiResponse<ISearchSuggestionCollection>
export type IApiSearchSuggestionResponse = AxiosResponse<ISearchSuggestionPagination>

export interface ISearchSuggestionService {
    search(parameters: ISearchSuggestionParameters, cancelToken?: CancelToken): Promise<IApiSearchSuggestionResponse>
}
