import { AxiosResponse, CancelToken } from 'axios'
import { IApiFileLink, IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { ICustomerIdentity } from '../customers/types'
/*******************************
 * Invoice
 * ****************************/

export enum InvoiceDetailType {
    Invoice = 'invoice',
    Asset = 'asset',
}

export enum InvoiceState {
    Wait = 'wait',
    Late = 'late',
    Paid = 'paid',
}

export interface IInvoiceDetail extends Omit<IApiResource, '@context'> {
    readonly id: string
    readonly date: string
    readonly number: string
    readonly amount: number
    readonly state: InvoiceState
    readonly state_label: string
    readonly amount_including_tax: number
    readonly type: InvoiceDetailType
    readonly customer: ICustomerIdentity
    readonly order?: {
        readonly id: string
        readonly number: string
    }
}

export type IInvoiceDocumentLink = IApiFileLink

export type IInvoiceListParameters = IBasePaginationParameters & {
    all_activity?: 1 | 0
}
export type IApiInvoiceListPagination = IPaginationApiResponse<Array<IInvoiceDetail>>
export type IApiInvoiceListResponse = AxiosResponse<IApiInvoiceListPagination>
export type IApiInvoiceResponse = AxiosResponse<IInvoiceDetail>
export type IApiInvoicePdfResponse = AxiosResponse<IInvoiceDocumentLink>

export interface IInvoicesService {
    list(parameters: IInvoiceListParameters): Promise<IApiInvoiceListResponse>
    get(orderId: string, cancelToken?: CancelToken): Promise<IApiInvoiceResponse>
    pdf(orderId: string, cancelToken?: CancelToken): Promise<IApiInvoicePdfResponse>
}
