import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ICustomer } from '../services/api/service/customers/types'
import { IApplicationRootState } from '../store'
import { makeSelectCustomer, makeSelectCustomerStore } from '../store/customers/selectors'
import { generatePath, Redirect } from 'react-router-dom'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import { customerShowPrices } from '../store/customers/utils'
import { isSalesmanResource } from '../store/salesmens/utils'
import { getPath } from '../routes'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
})

function CustomerHomePage(): JSX.Element {
    const { locale } = useIntl()

    const { customer, me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            customer?: ICustomer
            store?: ICustomer
        }
    >(stateSelector)

    if (typeof customer !== 'undefined' && typeof me !== 'undefined' && customerShowPrices(customer, me)) {
        return <Redirect to={generatePath(getPath('customerDashboard', locale), { lang: locale })} />
    } else if (isSalesmanResource(me)) {
        return <Redirect to={generatePath(getPath('salesmanArea', locale), { lang: locale })} />
    }

    return <Redirect to={generatePath(getPath('customerImports', locale), { lang: locale })} />
}

export default CustomerHomePage
