import { useMediaQueries } from '@react-hook/media-query'
import classNames from 'classnames'
import moment from 'moment'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Alert, Card } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CONFIG from '../../../../config'
import { IMemberTopSalesEntries } from '../../../../services/api/service/stats/types'
import { IApplicationRootState } from '../../../../store'
import { IAppErrorTypes } from '../../../../store/app/types'
import {
    statisticsCustomerTopSalesProcessAction,
    statisticsCustomerTopSalesResetAction,
} from '../../../../store/statistics/actions'
import {
    makeSelectStatisticsTopSalesData,
    makeSelectStatisticsTopSalesError,
    makeSelectStatisticsTopSalesFetching,
} from '../../../../store/statistics/selectors'
import { IMemberStatisticsFilters, MemberStatisticsContext } from '../../../../store/statistics/types'
import breakpoints from '../../../../utils/breakpoints'
import CustomerStatisticsFiltersResume from '../Filters/Partial/Resume'
import { convertStatisticFiltersToApiParams } from '../Filters/utils'

type IProps = {
    filters?: IMemberStatisticsFilters
    context?: MemberStatisticsContext
}

const stateSelector = createStructuredSelector<
    IApplicationRootState,
    {
        fetching: boolean
        entries?: IMemberTopSalesEntries
        error?: IAppErrorTypes
    }
>({
    entries: makeSelectStatisticsTopSalesData(),
    fetching: makeSelectStatisticsTopSalesFetching(),
    error: makeSelectStatisticsTopSalesError(),
})

function Statistics({ filters, context = MemberStatisticsContext.Customer }: IProps): JSX.Element {
    const { matches } = useMediaQueries(breakpoints)
    const dispatch = useDispatch()
    const { formatNumber, formatMessage } = useIntl()
    const { entries, error } = useSelector(stateSelector)
    const [data, setData] = useState({})

    const options = useMemo(() => {
        return {
            responsive: true,
            scales: {
                y: {
                    // display: false,
                    // stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value: string) {
                            return formatNumber(Number(value), {
                                style: 'currency',
                                currency: CONFIG.I18N.DEFAULT_CURRENCY.CURRENCY,
                                currencySign: 'standard',
                                currencyDisplay: 'symbol',
                                minimumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MINIMUM_FRACTION_DIGITS,
                                maximumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MAXIMUM_FRACTION_DIGITS,
                            })
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    // display: false,
                },
                title: {
                    display: false,
                    // text: 'Chart.js Bar Chart',
                },
                tooltip: {
                    padding: 10,
                    usePointStyle: true,
                    boxWidth: 6,
                    boxHeight: 6,
                    borderColor: '#e2e4e8',
                    borderWidth: 2,
                    footerMarginTop: 10,
                    callbacks: {
                        label: function (tooltipItem: any) {
                            const baseLbl = formatMessage({ id: 'orders_and_expenses.current' })
                            const formattedPrice = formatNumber(tooltipItem.raw, {
                                style: 'currency',
                                currency: CONFIG.I18N.DEFAULT_CURRENCY.CURRENCY,
                                currencySign: 'standard',
                                currencyDisplay: 'symbol',
                                minimumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MINIMUM_FRACTION_DIGITS,
                                maximumFractionDigits: CONFIG.I18N.DEFAULT_CURRENCY.MAXIMUM_FRACTION_DIGITS,
                            })
                            return `  ${baseLbl} ${
                                tooltipItem.dataset.labels[tooltipItem.dataIndex]
                            }: ${formattedPrice}`
                        },
                    },
                    backgroundColor: '#FFF',
                    bodyColor: '#3b3c41',
                    titleColor: '#3b3c41',
                    titleFont: {
                        size: 13,
                        weight: 'bold',
                    },
                    bodyFont: {
                        size: 13,
                    },
                },
            },
            type: 'bar',
            // @ts-ignore
            scaleLabel: function (label) {
                return ' $' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            },
        }
    }, [formatMessage, formatNumber])

    useEffect(() => {
        if (!filters) {
            dispatch(statisticsCustomerTopSalesResetAction())
            return
        }

        dispatch(statisticsCustomerTopSalesProcessAction(convertStatisticFiltersToApiParams(filters)))
    }, [dispatch, filters])

    useEffect(() => {
        if (!entries || entries.length === 0) {
            setData({})
            return
        }
        const labels: Array<string> = entries.map((entry) => moment(entry.month, 'YYYY-MM').format('MM/YYYY'))
        const previousLabels: Array<string> = entries.map((entry) =>
            moment(entry.previous_period_month, 'YYYY-MM').format('MM/YYYY')
        )
        const totals: Array<number> = entries.map((entry) => entry.total)
        const previousTotals: Array<number> = entries.map((entry) => entry.previous_period_total)

        const datasets: Array<any> = [
            {
                label: formatMessage({ id: 'orders_and_expenses.previous' }),
                backgroundColor: 'rgb(59, 60, 65)',
                borderColor: 'rgb(59, 60, 65)',
                borderWidth: 1,
                // stack: 1,
                hoverBackgroundColor: 'rgb(109, 110, 117)',
                hoverBorderColor: 'rgb(109, 110, 117)',
                data: previousTotals,
                labels: previousLabels,
            },
            {
                label: formatMessage({ id: 'orders_and_expenses.current' }),
                backgroundColor: '#f15d17',
                borderColor: 'rgb(241, 93, 23)',
                borderWidth: 1,
                // stack: 1,
                hoverBackgroundColor: 'rgb(247, 154, 111)',
                hoverBorderColor: 'rgb(247, 154, 111)',
                data: totals,
                labels,
            },
        ]

        setData({ labels, datasets })
    }, [setData, entries, formatMessage])

    return (
        <Card
            className={classNames(
                'member-card',
                'statistics-card',
                'statistics-data-card',
                'statistics-top-sales-card',
                `statistics-context-${context}`
            )}
        >
            <Card.Header>
                <h3 className={'h6'}>
                    <FormattedMessage id={'default.buying_prices'} />
                </h3>
                {filters && <CustomerStatisticsFiltersResume filters={filters} />}
            </Card.Header>
            <Card.Body>
                {error && <Alert variant="danger">{error?.message}</Alert>}
                {!error && <Bar data={data} options={options} height={matches.extraLarge ? 280 : undefined} />}
            </Card.Body>
        </Card>
    )
}

export default memo(Statistics)
