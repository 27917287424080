import { ICustomer } from '../services/api/service/customers/types'
import { IAppErrorTypes } from '../store/app/types'
import { IShippingLocation, IShippingLocationLight } from '../services/api/service/classification/types'
import { IOrderDetail, IOrderMode } from '../services/api/service/orders/types'
import { $PropertyType } from 'utility-types'
import { ButtonProps } from '../components/Buttons/Button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IManageCartParameters, IReOrderParameters } from '../services/api/service/carts/types'

export enum IReOrderCartMode {
    Erase = 'erase',
    Merge = 'merge',
}

export enum IReOrderMultiStoreMode {
    Principal = 'principal',
    Store = 'store',
}

export type ICartReOrderSubmitCallback<T extends IManageCartParameters = IReOrderParameters> = (
    data: Pick<T, 'erase_cart' | 'cart_type' | 'customers'>
) => void

export type DropdownItemChoiceTypes = Array<{
    value: string
    cartMode: IReOrderCartMode
    label: string
    cartType?: IOrderMode
}>

export interface ICartReOrderProps<T extends IManageCartParameters = IReOrderParameters> {
    customer: ICustomer
    useCartType?: boolean
    order?: IOrderDetail
    fetching?: boolean
    done?: boolean
    error?: IAppErrorTypes
    shippingLocation?: IShippingLocation | IShippingLocationLight | null
    onReOrderSubmit: ICartReOrderSubmitCallback<T>
    onReOrderReset?: () => void
    size?: $PropertyType<ButtonProps, 'size'>
    block?: $PropertyType<ButtonProps, 'block'>
    label?: string | null
    icon?: IconProp | null
}

export type ICartReOrderFactoryProps = ICartReOrderProps
