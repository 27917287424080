/*
 *
 * Classification constants
 *
 */

enum ActionTypes {
    STORE_ATTRIBUTE_ACTION = 'app/Classification/STORE_ATTRIBUTE',
    STORE_MENU_ACTION = 'app/Classification/STORE_MENU',
    RESET_CLASSIFICATION_ACTION = 'app/Classification/RESET',

    CATEGORY_PROCESS_ACTION = 'app/Classification/Category/REQUEST_PROCESS',
    CATEGORY_SUCCESS_ACTION = 'app/Classification/Category/REQUEST_SUCCESS',
    CATEGORY_FAILURE_ACTION = 'app/Classification/Category/REQUEST_FAILURE',
    CATEGORY_RESET_ACTION = 'app/Classification/Category/RESET',

    MENU_PROCESS_ACTION = 'app/Classification/Menu/REQUEST_PROCESS',
    MENU_SUCCESS_ACTION = 'app/Classification/Menu/SUCCESS_PROCESS',
    MENU_FAILURE_ACTION = 'app/Classification/Menu/FAILURE_PROCESS',

    FAMILY_TREE_PROCESS_ACTION = 'app/Classification/FamilyTree/REQUEST_PROCESS',
    FAMILY_TREE_SUCCESS_ACTION = 'app/Classification/FamilyTree/REQUEST_SUCCESS',
    FAMILY_TREE_FAILURE_ACTION = 'app/Classification/FamilyTree/REQUEST_FAILURE',
    FAMILY_TREE_RESET_ACTION = 'app/Classification/FamilyTree/RESET',
    FAMILY_TREE_REFRESH_ACTION = 'app/Classification/FamilyTree/REFRESH',

    FAVORITE_DEPARTMENT_LIST_PROCESS_ACTION = 'app/Classification/FavoriteDepartment/List/REQUEST_PROCESS',
    FAVORITE_DEPARTMENT_LIST_SUCCESS_ACTION = 'app/Classification/FavoriteDepartment/List/REQUEST_SUCCESS',
    FAVORITE_DEPARTMENT_LIST_FAILURE_ACTION = 'app/Classification/FavoriteDepartment/List/REQUEST_FAILURE',
    FAVORITE_DEPARTMENT_LIST_RESET_ACTION = 'app/Classification/FavoriteDepartment/List/RESET',

    FAVORITE_DEPARTMENT_EDIT_PROCESS_ACTION = 'app/Classification/FavoriteDepartment/Edit/REQUEST_PROCESS',
    FAVORITE_DEPARTMENT_EDIT_SUCCESS_ACTION = 'app/Classification/FavoriteDepartment/Edit/REQUEST_SUCCESS',
    FAVORITE_DEPARTMENT_EDIT_FAILURE_ACTION = 'app/Classification/FavoriteDepartment/Edit/REQUEST_FAILURE',
    FAVORITE_DEPARTMENT_EDIT_RESET_ACTION = 'app/Classification/FavoriteDepartment/Edit/RESET',

    FAVORITE_DEPARTMENT_DELETE_PROCESS_ACTION = 'app/Classification/FavoriteDepartment/Delete/REQUEST_PROCESS',
    FAVORITE_DEPARTMENT_DELETE_SUCCESS_ACTION = 'app/Classification/FavoriteDepartment/Delete/REQUEST_SUCCESS',
    FAVORITE_DEPARTMENT_DELETE_FAILURE_ACTION = 'app/Classification/FavoriteDepartment/Delete/REQUEST_FAILURE',
    FAVORITE_DEPARTMENT_DELETE_RESET_ACTION = 'app/Classification/FavoriteDepartment/Delete/RESET',

    FAVORITE_DEPARTMENT_ASSOCIATE_PROCESS_ACTION = 'app/Classification/FavoriteDepartment/Associate/REQUEST_PROCESS',
    FAVORITE_DEPARTMENT_ASSOCIATE_SUCCESS_ACTION = 'app/Classification/FavoriteDepartment/Associate/REQUEST_SUCCESS',
    FAVORITE_DEPARTMENT_ASSOCIATE_FAILURE_ACTION = 'app/Classification/FavoriteDepartment/Associate/REQUEST_FAILURE',
    FAVORITE_DEPARTMENT_ASSOCIATE_RESET_ACTION = 'app/Classification/FavoriteDepartment/Associate/RESET',
}

export default ActionTypes
