/**
 *
 * Product Eco tax
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { default as PriceComp } from '../../Price/Price'

interface IProps {
    ecoTax: number
    showLabel: boolean
    as: React.ElementType
    className?: string
    labelMessageKey: string
}

function EcoTax({ ecoTax, as, showLabel, className, labelMessageKey = 'price.eco_tax' }: IProps): JSX.Element | null {
    if (!ecoTax) {
        return null
    }

    const Child = (
        <>
            {showLabel && (
                <span className={'lbl'}>
                    <FormattedMessage id={labelMessageKey} />{' '}
                </span>
            )}
            <span className={'value'}>
                <PriceComp price={ecoTax} className={'eco-tax'} />
            </span>
        </>
    )

    return React.createElement(
        as || 'div',
        {
            className: classNames('product-eco-tax', className),
        },
        Child
    )
}

EcoTax.defaultProps = {
    showLabel: true,
    as: 'div',
} as Partial<IProps>

export default memo(EcoTax)
