/**
 * Asynchronously loads the component for HomePage
 */

import * as React from 'react'
import PageInnerLoader from '../../components/Loader/PageLoader'
import loadable from '../../utils/loadable'

export default loadable(() => import('./CustomerCarts'), {
    fallback: <PageInnerLoader />,
})
