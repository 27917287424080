/**
 *
 * TabbedCarousel
 *
 */
import classNames from 'classnames'
import React, { CSSProperties, memo } from 'react'
import { CmsWidgetDividerType, ICmsWidgetDivider } from '../../../../services/api/service/cms/types'

interface IProps {
    widget: ICmsWidgetDivider
}

function Divider({ widget }: IProps): JSX.Element {
    let style: CSSProperties = {}
    const styleInner: CSSProperties = {
        height: `${widget.configuration.height!}px`,
    }

    if (widget.configuration.type === CmsWidgetDividerType.Color) {
        style = {
            ...style,
            backgroundColor: widget.configuration.color!,
        }
    } else if (widget.configuration.type === CmsWidgetDividerType.Image) {
        style = {
            ...style,
            backgroundImage: `url('${widget.configuration.image!.default!}')`,
        }
    }

    return (
        <div
            style={style}
            className={classNames(
                'cms-widget',
                `cms-widget-${widget.widget}`,
                `cms-widget-${widget.widget}-${widget.configuration.type}`
            )}
        >
            <div className={'cms-widget-inner'} style={styleInner} />
        </div>
    )
}

Divider.defaultProps = {} as Partial<IProps>

export default memo(Divider)
