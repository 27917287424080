import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Undefinable } from 'tsdef'
import { IMemberStatisticsFilters, MemberStatisticsContext } from '../../store/statistics/types'
import StatisticsFilters from './Partial/Filters/StatisticsFilters'
import TopProducts from './Partial/TopProducts/Statistics'
import TopSales from './Partial/TopSales/Statistics'

type Props = {
    context?: MemberStatisticsContext
}

function Statistics({ context = MemberStatisticsContext.Customer }: Props): JSX.Element {
    const [filters, setFilters] = useState<Undefinable<IMemberStatisticsFilters>>(undefined)

    return (
        <div className="member-statistics-section">
            <StatisticsFilters onChange={setFilters} context={context} />
            <Row>
                <Col xs={24} className={'col-xxl-12'}>
                    <TopSales filters={filters} context={context} />
                </Col>
                <Col xs={24} className={'col-xxl'}>
                    <TopProducts filters={filters} context={context} />
                </Col>
            </Row>
        </div>
    )
}

export default Statistics
