import AppError from './error'
import { IAppErrorCollection, IAppErrorTypes, IAppErrorViolationCollection, IHttpError } from './types'

export default class HttpError extends AppError implements IHttpError {
    public violations: IAppErrorViolationCollection | undefined
    public isHttpError = true
    constructor(
        m: string,
        status: number,
        errors?: IAppErrorCollection,
        violations?: IAppErrorViolationCollection,
        previous?: IAppErrorTypes
    ) {
        super(m, status, errors, previous)
        this.violations = violations
    }
}
