/**
 *
 * Picker Button
 *
 */
import React, { forwardRef, memo, useCallback } from 'react'
import classNames from 'classnames'
import { IPickerActionButtonProps } from '../../../types/productPicker'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from 'react-bootstrap'
import FlatIcon from '../../Icon/FlatIcon'

const PickerButton = forwardRef<HTMLElement, IPickerActionButtonProps>(
    (
        { action, as, className, onSelect, loading = false, disabled = false, variant = 'secondary', size = 'sm' },
        ref
    ) => {
        const handleClick = useCallback(() => {
            if (onSelect) {
                onSelect(action.code)
            }
        }, [action, onSelect])

        return React.createElement(
            as || Dropdown.Item,
            {
                className: classNames('product-picker-action-button', className),
                onClick: handleClick,
                eventKey: action.code,
                disabled: disabled || loading,
                ref,
                // block,
                variant,
                size,
            },
            <>
                {action.icon && <FlatIcon icon={action.icon} />}
                {action.label && <FormattedMessage id={action.label} />}
            </>
        )
    }
)

export default memo(PickerButton)
