/* eslint react/jsx-key: 0 */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    MouseEvent,
    forwardRef,
    Ref,
    useImperativeHandle,
} from 'react'
import {
    TableOptions,
    useFilters,
    useSortBy,
    usePagination,
    useTable,
    Filters,
    SortingRule,
    useGlobalFilter,
    Row,
    Cell,
    RowPropGetter,
    UseFiltersInstanceProps,
    UsePaginationInstanceProps,
} from 'react-table'
import BTable from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames, { Value as ClassValue } from 'classnames'
import { Alert, Spinner, Pagination as MultiPage } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import FlatIcon from '../Icon/FlatIcon'
import { useHistory, useLocation } from 'react-router-dom'
import Qs from 'qs'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import { newDecoder } from '../../utils/qs'
import { $PropertyType } from 'utility-types'
import FilterDefault from './FilterDefault'
import { debounce, has } from 'lodash'
import rtrim from '../../utils/rtrim'
import objectEquals from 'check-object-equal'
import get from 'lodash/get'

export type TableHandleType<T extends object> = Pick<UseFiltersInstanceProps<T>, 'setFilter' | 'setAllFilters'> &
    Pick<UsePaginationInstanceProps<T>, 'gotoPage' | 'nextPage' | 'previousPage'>
export type TableHandleRef<T extends object> = Ref<TableHandleType<T>>

export interface ITableProps<T extends object = object, D = any[]> extends TableOptions<T> {
    className?: ClassValue
    pageRange?: number
    fetching?: boolean
    paginate?: boolean
    syncWithHistory?: boolean
    fetchError?: Error
    totalItems?: number
    deps?: D
    fetchData?: <D = any[]>(
        page: number,
        pageSize: number,
        filters: Filters<T>,
        sortBy: Array<SortingRule<T>>,
        deps?: D
    ) => void
    onRowClick?: (event: MouseEvent<HTMLElement>, item: T, row: Row<T>) => void
    onCellClick?: (event: MouseEvent<HTMLElement>, item: T, cell: Cell<T>) => void
    configureRowProps?: (row: Row<T>) => RowPropGetter<T>
}

export type TableHistoryCriteriaQuery = {
    filters?: Record<string, string | number | null | boolean | undefined>
    sortBy?: Record<string, string | number | null | boolean | undefined>
    page?: number
    itemsPerPage?: number
}

function isTableQueryState(object: any): boolean {
    return (
        isObject(object) &&
        // @ts-ignore
        (isObject(object.filters) ||
            // @ts-ignore
            isObject(object.sortBy) ||
            // @ts-ignore
            isNumber(object.page) ||
            // @ts-ignore
            isNumber(object.pageSize))
    )
}

function isDisplayableColumn(column: object): boolean {
    return (
        // @ts-ignore
        (isUndefined(column.filterOnly) || (isBoolean(column.filterOnly) && column.filterOnly === false)) &&
        // @ts-ignore
        (isUndefined(column.hidden) || (isBoolean(column.hidden) && column.hidden === false))
    )
}

function isHiddenColumn(column: object): boolean {
    return (
        // @ts-ignore
        (!isUndefined(column.filterOnly) && isBoolean(column.filterOnly) && column.filterOnly === true) ||
        // @ts-ignore
        (!isUndefined(column.hidden) && isBoolean(column.hidden) && column.hidden === true)
    )
}

function TableInner<T extends object = object>(
    {
        className,
        defaultColumn: wantedDefaultColumn,
        pageRange = 5,
        totalItems,
        fetching = false,
        fetchError,
        fetchData,
        defaultCanFilter = false,
        defaultCanSort = false,
        manualSortBy = true,
        manualFilters = true,
        manualPagination = true,
        manualGlobalFilter = true,
        onRowClick,
        onCellClick,
        configureRowProps,
        paginate = true,
        syncWithHistory = true,
        initialState: wantedState,
        deps,
        ...rest
    }: ITableProps<T>,
    ref: React.ForwardedRef<TableHandleType<T>>
): JSX.Element {
    const [pristine, setPristine] = useState(true)
    const [firstPage, setFirstPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const { pathname, search } = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (fetching) {
            setPristine(false)
        }
    }, [fetching])

    const defaultColumn = useMemo(() => {
        if (wantedDefaultColumn) {
            return wantedDefaultColumn
        }

        return {
            Filter: (props) => {
                return <FilterDefault {...props} />
            },
        }
    }, [wantedDefaultColumn])

    const initialState: $PropertyType<TableOptions<T>, 'initialState'> = useMemo(() => {
        const ste = { ...wantedState }
        if (!syncWithHistory) {
            return ste
        }
        // @ts-ignore
        const parsed = Qs.parse(search.substring(1), {
            ignoreQueryPrefix: true,
            arrayFormat: 'bracket',
            decoder: newDecoder(),
        })

        if (has(parsed, 'criteria') && isTableQueryState(parsed.criteria)) {
            const { criteria } = parsed
            // @ts-ignore
            return { ...ste, ...criteria }
        }

        return ste
    }, [wantedState, syncWithHistory, search])

    const initialPageCount = useMemo(() => {
        return Math.ceil(
            (totalItems || 0) / (initialState?.pageSize && initialState?.pageSize ? initialState?.pageSize : 1)
        )
    }, [initialState, totalItems])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { sortBy, filters, pageIndex, pageSize },
        setAllFilters,
        setFilter,
        setSortBy,
        // setPageSize,
    } = useTable<T>(
        {
            ...rest,
            initialState,
            defaultColumn,
            pageCount: initialPageCount,
            defaultCanFilter,
            defaultCanSort,
            manualSortBy,
            manualFilters,
            manualPagination,
            manualGlobalFilter,
            // autoResetPage: !syncWithHistory,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const hasFilters = useMemo(() => {
        let hasFilters = false
        loop1: for (const headerGroup of headerGroups) {
            for (const column of headerGroup.headers) {
                if (column.defaultCanFilter) {
                    hasFilters = true
                    break loop1
                }
            }
        }

        return hasFilters
    }, [headerGroups])

    const columnDisplayedLength = useMemo(() => {
        return rest.columns.filter(isDisplayableColumn).length
    }, [rest.columns])

    const currentPage = useMemo(() => {
        return pageIndex + 1
    }, [pageIndex])

    useEffect(() => {
        // This is the first page to be displayed as a numbered link.
        let firstPageCalc = Math.max(1, currentPage - Math.floor(pageRange / 2))

        // And here's the last page to be displayed specifically.
        let lastPageCalc = Math.min(pageCount, currentPage + Math.floor(pageRange / 2))

        // This is triggered if we're at or near one of the extremes; we won't have
        // enough page links. We need to adjust our bounds accordingly.
        if (lastPageCalc - firstPageCalc + 1 < pageRange) {
            if (currentPage < pageCount / 2) {
                lastPageCalc = Math.min(pageCount, lastPageCalc + (pageRange - (lastPageCalc - firstPageCalc)))
            } else {
                firstPageCalc = Math.max(1, firstPageCalc - (pageRange - (lastPageCalc - firstPageCalc)))
            }
        }

        // This can be triggered if the user wants an odd number of pages.
        if (lastPageCalc - firstPageCalc + 1 > pageRange) {
            // We want to move towards whatever extreme we're closest to at the time.
            if (currentPage > pageCount / 2) {
                firstPageCalc++
            } else {
                lastPageCalc--
            }
        }

        setFirstPage(firstPageCalc)
        setLastPage(lastPageCalc)
    }, [currentPage, pageCount, pageRange, setFirstPage, setLastPage])

    const MultiPages = useMemo(() => {
        const pages: Array<JSX.Element> = []

        for (let i = firstPage; i <= lastPage; i++) {
            pages.push(
                <MultiPage.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => {
                        gotoPage(i - 1)
                    }}
                    disabled={fetching}
                >
                    {i}
                </MultiPage.Item>
            )
        }

        return pages
    }, [gotoPage, fetching, firstPage, lastPage, currentPage])

    const handleRowClick = useCallback(
        (e: MouseEvent<HTMLElement>, row: Row<T>) => {
            if (onRowClick) {
                onRowClick(e, row.original, row)
            }
        },
        [onRowClick]
    )

    const handleCellClick = useCallback(
        (e: MouseEvent<HTMLElement>, cell: Cell<T>) => {
            if (onCellClick) {
                onCellClick(e, cell.row.original, cell)
            }
        },
        [onCellClick]
    )

    const TableAppLoader = useMemo(() => {
        return (
            <div className="table-app-loader">
                <Spinner
                    as="span"
                    animation={'grow'}
                    size={'sm'}
                    variant={'secondary'}
                    role={'loading'}
                    aria-hidden="true"
                />
            </div>
        )
    }, [])

    const debounceFetchData = useCallback(
        // @ts-ignore
        debounce<$PropertyType<ITableProps<T>, 'fetchData'>>((currentPage, pageSize, filters, sortBy, deps) => {
            if (!fetchData) {
                return () => {}
            }
            return fetchData(currentPage, pageSize, filters, sortBy, deps)
        }, 100),
        [fetchData]
    )

    useEffect(() => {
        if (!syncWithHistory) {
            return
        }

        // @ts-ignore
        const parsed = Qs.parse(search.substring(1), {
            ignoreQueryPrefix: true,
            arrayFormat: 'bracket',
            decoder: newDecoder(),
        })

        if (!parsed.criteria) {
            return
        }

        // @ts-ignore
        setSortBy(parsed.criteria.sortBy || [])
        // @ts-ignore
        setAllFilters(parsed.criteria.filters || [])
        // @ts-ignore
        // setPageSize(parsed.criteria.pageSize)
        // @ts-ignore
        // @ts-ignore
        gotoPage(parsed.criteria.pageIndex)
    }, [pathname, search, setSortBy, setAllFilters, gotoPage, syncWithHistory])

    useEffect(() => {
        if (!syncWithHistory) {
            return
        }

        // @ts-ignore
        const parsed = Qs.parse(window.location.search.substring(1), {
            ignoreQueryPrefix: true,
            arrayFormat: 'bracket',
            decoder: newDecoder(),
        })

        const currentCriteria = parsed.criteria
        const newCriteria = { sortBy, filters, pageSize, pageIndex }

        if (
            currentCriteria &&
            // @ts-ignore
            (!objectEquals(get(currentCriteria, 'sortBy', []), get(newCriteria, 'sortBy', [])) ||
                // @ts-ignore
                !objectEquals(get(currentCriteria, 'filters', []), get(newCriteria, 'filters', [])))
        ) {
            gotoPage(0)
            newCriteria.pageIndex = 0
        }

        // @ts-ignore
        parsed.criteria = newCriteria

        const stringified = Qs.stringify(parsed)
        const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
        const baseUrl = rtrim(window.location.pathname, '/')
        const relativeUrl = `${baseUrl}${qstring}`
        if (relativeUrl !== `${window.location.pathname}${window.location.search}`) {
            history.replace(relativeUrl)
        }
    }, [sortBy, filters, pageSize, pageIndex, history, gotoPage, syncWithHistory])

    useEffect(() => {
        debounceFetchData(currentPage, pageSize, filters, sortBy, deps)
    }, [debounceFetchData, sortBy, filters, pageSize, currentPage, deps])

    useImperativeHandle(ref, () => ({
        setFilter,
        setAllFilters,
        gotoPage,
        nextPage,
        previousPage,
    }))

    return (
        <div className="table-app-section">
            {hasFilters &&
                headerGroups.map((headerGroup) => (
                    <div
                        {...headerGroup.getHeaderGroupProps()}
                        className={classNames('table-app-filter-row', 'table-app-filter-row-top')}
                    >
                        {headerGroup.headers
                            .filter(isHiddenColumn)
                            .filter((column) => column.defaultCanFilter)
                            .map((column) => {
                                return (
                                    <div
                                        id={`top-filter-${column.id}`}
                                        {...column.getHeaderProps([{ className: classNames(column.className || '') }])}
                                        key={`top_filter_${column.id}`}
                                    >
                                        {column.render('Filter')}
                                    </div>
                                )
                            })}
                    </div>
                ))}
            <BTable
                hover
                size="sm"
                responsive
                {...getTableProps()}
                className={classNames('table-app', className, { fetching })}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.filter(isDisplayableColumn).map((column) => {
                                return (
                                    <th
                                        {...column.getHeaderProps([
                                            {
                                                className: classNames([
                                                    column.className || '',
                                                    column.defaultCanSort ? 'clickable' : '',
                                                ]),
                                            },
                                        ])}
                                    >
                                        <div
                                            className="property"
                                            {...(column.defaultCanSort
                                                ? {
                                                      onClick: () => {
                                                          column.toggleSortBy(!column.isSortedDesc)
                                                      },
                                                  }
                                                : {})}
                                        >
                                            {column.render('Header')}
                                            {column.defaultCanSort && (
                                                <span className="sort-by">
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'caret-up']}
                                                        className={classNames('app-icon', {
                                                            // @ts-ignore
                                                            active: column.isSorted && !column.isSortedDesc,
                                                        })}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'caret-down']}
                                                        className={classNames('app-icon', {
                                                            // @ts-ignore
                                                            active: column.isSorted && column.isSortedDesc,
                                                        })}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    {hasFilters &&
                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="table-app-filter-row">
                                {headerGroup.headers.filter(isDisplayableColumn).map((column) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps([
                                                { className: classNames(column.className || '') },
                                            ])}
                                        >
                                            <div>{column.render('Filter')}</div>
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {fetchError && (
                        <tr className="error-row">
                            <td colSpan={columnDisplayedLength}>
                                <Alert variant={'danger'}>{fetchError.message}</Alert>
                            </td>
                        </tr>
                    )}
                    {!pristine && rows.length === 0 && !fetching && !fetchError && (
                        <tr className="error-row">
                            <td colSpan={columnDisplayedLength}>
                                <Alert variant={'danger'}>
                                    <p className="mb-0">
                                        <FormattedMessage id="default.no_searched_results" />
                                    </p>
                                </Alert>
                            </td>
                        </tr>
                    )}
                    {rows.map((row) => {
                        prepareRow(row)
                        const rowProps = configureRowProps ? configureRowProps(row) : row.getRowProps()
                        return (
                            <tr {...rowProps} onClick={(e) => handleRowClick(e, row)}>
                                {row.cells
                                    .filter((cell) => isDisplayableColumn(cell.column))
                                    .map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={classNames(cell.column.className, `cell-${cell.column.id}`, {
                                                    clickable: cell.column.clickable,
                                                })}
                                                onClick={(e) => handleCellClick(e, cell)}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
                    {rows.length === 0 && fetching && (
                        <tr className="loader-row">
                            <td colSpan={columnDisplayedLength}>{TableAppLoader}</td>
                        </tr>
                    )}
                    {fetching && currentPage > 1 && (
                        <tr className="loader-row">
                            <td colSpan={columnDisplayedLength}>
                                <div
                                    className={classNames('table-app-overlay', {
                                        showing: fetching && currentPage > 1,
                                    })}
                                >
                                    {TableAppLoader}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
                {paginate && (
                    <tfoot>
                        <tr>
                            <td colSpan={columnDisplayedLength}>
                                <div
                                    className={classNames('pagination-section', className, {
                                        'd-none': isUndefined(totalItems) || totalItems <= pageSize,
                                    })}
                                >
                                    <MultiPage>
                                        <MultiPage.First
                                            className={'goto-first-item'}
                                            active={pageIndex === 0}
                                            disabled={pageIndex === 0 || fetching}
                                            onClick={() => gotoPage(0)}
                                        />
                                        <MultiPage.Prev
                                            disabled={!canPreviousPage || fetching}
                                            onClick={previousPage}
                                            className={'goto-prev-item'}
                                        >
                                            <FlatIcon icon={'arrow-left'} />
                                        </MultiPage.Prev>
                                        {MultiPages}
                                        {lastPage !== pageOptions.length && (
                                            <MultiPage.Item disabled>...</MultiPage.Item>
                                        )}
                                        <MultiPage.Next
                                            disabled={!canNextPage || fetching}
                                            onClick={nextPage}
                                            className={'goto-next-item'}
                                        >
                                            <FlatIcon icon={'arrow-right'} />
                                        </MultiPage.Next>
                                        <MultiPage.Last
                                            className={'goto-last-item'}
                                            active={pageIndex + 1 === pageCount}
                                            disabled={!canNextPage || fetching}
                                            onClick={() => gotoPage(pageCount - 1)}
                                        />
                                    </MultiPage>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </BTable>
        </div>
    )
}

const TableWithRef = forwardRef(TableInner)

type TableWithRefProps<T extends object = object> = ITableProps<T> & {
    mRef?: TableHandleRef<T>
}

export default function Table<T extends object = object>({ mRef, ...props }: TableWithRefProps<T>) {
    // @ts-ignore
    return <TableWithRef ref={mRef} {...props} />
}
