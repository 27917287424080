import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { ICart } from '../../services/api/service/carts/types'
import { IFamilyTreeCollection } from '../../services/api/service/classification/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { ICreateOrderDataChildType, ICreateOrderDataCollectionType } from '../../store/carts/types'
import { findShippingLocationBy } from '../../store/classification/utils'
import { CollectionMap } from '../../types/common'
import ApplicationHelper from '../../utils/applicationHelper'
import Button from '../Buttons/Button'
import FlatIcon from '../Icon/FlatIcon'
import { default as CartHeader } from './Header'
import { ICreateOrderFieldsProps } from './type'
import { IOrderMode } from '../../services/api/service/orders/types'
import Config from '../../config'
import { IPaymentModeList } from '../../services/api/service/payment-modes/types'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'
import { IHttpError } from '../../store/app/types'

type IProps = ICreateOrderFieldsProps & {
    submitButtonLabel: string
    fetching?: boolean
    allowGroupFields?: boolean
    showStrictMinimumAmountField?: boolean
    familyTree?: IFamilyTreeCollection
    paymentModes?: Array<IPaymentModeList>
    carts: Array<ICart>
    customer: ICustomer
    orderMode: IOrderMode
}

// TODO: paymentModes
function CreateOrderForm({
    submitButtonLabel,
    onFormSubmit,
    onFormReset,
    disabled,
    carts,
    familyTree,
    allowGroupFields,
    formValues,
    formErrors,
    customer,
    showStrictMinimumAmountField,
    orderMode,
    paymentModes,
}: IProps): JSX.Element {
    const { locale, formatMessage } = useIntl()
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<CollectionMap<Date | null>>({})
    const [paymentMode, setPaymentMode] = useState<CollectionMap<string | null>>({})
    const [cartOnlyReachedMinimumAmount, setCartOnlyReachedMinimumAmount] = useState<CollectionMap<boolean>>({})
    const [comment, setComment] = useState<CollectionMap<string | null>>({})
    const [groupFormFields, setGroupFormFields] = useState<boolean>(allowGroupFields || false)
    const [isShowDateInlinePicker, setIsShowDateInlinePicker] = useState<CollectionMap<boolean>>({})
    const isAllowGroupFields = allowGroupFields && carts.length > 1
    const showPaymentModes =
        customer.internal && !isUndefined(paymentModes) && paymentModes?.length > 0 && orderMode === IOrderMode.Order
    const isTouch = ApplicationHelper.isTouchScreen()

    const showRequestedDeliveryDateField = useMemo(() => {
        return orderMode === IOrderMode.Order && Config.CART.DISPLAY_REQUESTED_DELIVERY_DATE && !customer.internal
    }, [orderMode, customer])

    const violations = useMemo(() => {
        if (!isObject(formErrors)) {
            return {}
        }
        const values = {}
        Object.keys(formErrors).forEach((cartErrorId) => {
            const cartError = formErrors[cartErrorId]
            if (cartError?.isHttpError) {
                values[cartErrorId] = (cartError as IHttpError)?.violations
            }
        })
        return values
    }, [formErrors])

    const handleStrictMinimumAmountFieldsChange = (event: React.FormEvent<HTMLInputElement>) => {
        const cartId = event.currentTarget.dataset['id'] || 'all'
        const checked = event.currentTarget.checked
        setCartOnlyReachedMinimumAmount((state) => {
            return {
                ...state,
                [cartId]: checked,
            }
        })
    }

    const handleSplitFieldsChange = (event: React.FormEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked

        // reset
        let updatedCommentValues: CollectionMap<string | null> = {}
        let updatedPaymentModeValues: CollectionMap<string | null> = {}
        // ici on prend un raccourci. Si on voulait être préci on devrait tester le typage de date, etc ...
        let updatedDeliveryDates: CollectionMap<Date | null> = {}
        let updateCartOnlyReachedMinimumAmountValues: CollectionMap<boolean> = {}

        if (!checked) {
            carts.forEach((cart) => {
                const cartId = cart['@id']
                if (!updatedDeliveryDates[cartId]) {
                    updatedDeliveryDates[cartId] = requestedDeliveryDate['all'] || null
                }
                if (!updatedCommentValues[cartId]) {
                    updatedCommentValues[cartId] = comment['all'] || null
                }
                if (!updatedPaymentModeValues[cartId]) {
                    updatedPaymentModeValues[cartId] =
                        Object.values(paymentMode).length > 0 ? paymentMode['all'] || null : null
                }
                if (typeof updateCartOnlyReachedMinimumAmountValues[cartId] === 'undefined') {
                    updateCartOnlyReachedMinimumAmountValues[cartId] = cartOnlyReachedMinimumAmount['all'] || false
                }
            })
        } else {
            updatedCommentValues = {
                ...comment,
                all: Object.values(comment)[0],
            }
            updatedPaymentModeValues = {
                ...comment,
                all: Object.values(paymentMode).length > 0 ? Object.values(paymentMode)[0] || null : null,
            }

            updatedDeliveryDates = {
                ...requestedDeliveryDate,
                all: Object.values(requestedDeliveryDate)[0],
            }

            updateCartOnlyReachedMinimumAmountValues = {
                ...updateCartOnlyReachedMinimumAmountValues,
                all: Object.values(updateCartOnlyReachedMinimumAmountValues)[0],
            }
        }

        setComment(updatedCommentValues)
        setPaymentMode(updatedPaymentModeValues)
        setRequestedDeliveryDate(updatedDeliveryDates)
        setCartOnlyReachedMinimumAmount(updateCartOnlyReachedMinimumAmountValues)
        setGroupFormFields(checked)
    }

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (onFormSubmit) {
            const data: ICreateOrderDataCollectionType = {}
            carts.forEach((cart) => {
                const cartId = cart['@id']
                const accessorId = groupFormFields ? 'all' : cart['@id']
                data[cartId] = {
                    comment: comment[accessorId] || null,
                    date: requestedDeliveryDate[accessorId] || null,
                    validate_only_minimum_amount_orders: cartOnlyReachedMinimumAmount[accessorId] || false,
                }

                // if internal, payment mode !
                if (customer.internal) {
                    data[cartId].payment_mode = paymentMode[accessorId] || null
                }
            })
            onFormSubmit(data, carts)
        }
    }

    const handleOnFormReset = () => {
        setIsShowDateInlinePicker({})
        if (onFormReset) {
            onFormReset(carts)
        }
    }

    const handleOnRequestedDeliveryDateChange = (date: Date | null, cartId: string) => {
        setRequestedDeliveryDate({
            ...requestedDeliveryDate,
            [cartId]: date,
        })
        setIsShowDateInlinePicker({
            [cartId]: false,
        })
    }

    const handleOnRequestedDeliveryDateMobileShow = (cartId: string) => {
        setIsShowDateInlinePicker({
            [cartId]: typeof isShowDateInlinePicker[cartId] === 'boolean' ? !isShowDateInlinePicker[cartId] : true,
        })
    }

    // const handleOnRequestedDeliveryDateMobileChange = (value: string, uniqId: string) => {
    //     const date = value.length > 0 ? moment(value, 'YYYY-MM-DD').toDate() : null
    //     setRequestedDeliveryDate({
    //         ...requestedDeliveryDate,
    //         [uniqId]: date,
    //     })
    // }

    const handlePaymentModeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // récupération de l'index
        const cartId = e.currentTarget.dataset['id'] || 'all'
        setPaymentMode({
            ...comment,
            [cartId]: e.currentTarget.value,
        })
    }

    const handleOnInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // récupération de l'index
        const cartId = e.currentTarget.dataset['id'] || 'all'
        setComment({
            ...comment,
            [cartId]: e.currentTarget.value,
        })
    }

    useEffect(() => {
        const updatedCommentValues: ICreateOrderDataChildType = {}
        // ici on prend un raccourci. Si on voulait être préci on devrait tester le typage de date, etc ...
        const updatedDeliveryDates: ICreateOrderDataChildType = {}

        carts.forEach((cart) => {
            const cartId = cart['@id']
            if (!formValues || !formValues[cartId]) {
                updatedDeliveryDates[cartId] = null
                updatedCommentValues[cartId] = null
            } else {
                const form = formValues[cartId]
                if (form.date) {
                    updatedDeliveryDates[cartId] = form.date
                }
                if (form.comment) {
                    updatedCommentValues[cartId] = form.comment
                }
            }
        })

        setComment(updatedCommentValues)
        setRequestedDeliveryDate(updatedDeliveryDates)
    }, [formValues, carts, setComment, setRequestedDeliveryDate])

    const handleFilterDate = useCallback((date: Date | null) => {
        if (!date) {
            return true
        }
        const day = date.getDay()
        return day !== 0 && day !== 6
    }, [])

    const minGroupedDeliveryDate = useMemo(() => {
        let date: Date | null = null
        carts.forEach((cart) => {
            const curDate = cart.min_delivery_date ? new Date(cart.min_delivery_date) : null
            if (curDate && (date === null || date < curDate)) {
                date = curDate
            }
        })

        return date
    }, [carts])

    const maxGroupedDeliveryDate = useMemo(() => {
        let date: Date | null = null
        carts.forEach((cart) => {
            const curDate = cart.max_delivery_date ? new Date(cart.max_delivery_date) : null
            if (curDate && (date === null || date > curDate)) {
                date = curDate
            }
        })

        return date
    }, [carts])

    const excludeDates = useMemo(() => {
        let excludedDates: Array<Date> | undefined = undefined
        if (customer.holidays && customer.holidays.length > 0) {
            excludedDates = []
            customer.holidays.forEach((holidayDate) => excludedDates!.push(new Date(holidayDate)))
        }
        return excludedDates
    }, [customer])

    return (
        <div
            className={classNames('create-order-form', {
                collapsed: groupFormFields,
                multiple: carts && carts.length > 1,
            })}
        >
            <Form className={classNames('checkout-info')} onSubmit={handleOnSubmit} onReset={handleOnFormReset}>
                {isAllowGroupFields && (
                    <Form.Group className={'split-fields-form-group'}>
                        <Form.Check
                            type={'switch'}
                            id={'allow-split-fields'}
                            checked={groupFormFields}
                            disabled={disabled}
                            label={formatMessage({ id: 'cart.create_order_use_same_info' })}
                            onChange={handleSplitFieldsChange}
                            value={1}
                        />
                    </Form.Group>
                )}
                {!groupFormFields &&
                    carts.map((cart) => {
                        const shippingLocation = familyTree
                            ? findShippingLocationBy(familyTree, '@id', cart.shipping_location)
                            : undefined
                        const minDeliveryDate = cart.min_delivery_date ? new Date(cart.min_delivery_date) : null
                        const maxDeliveryDate = cart.max_delivery_date ? new Date(cart.max_delivery_date) : null
                        return (
                            <Card className={'single-cart-form card'} key={`form_cart_fields_${cart['@id']}`}>
                                <Card.Header>
                                    <CartHeader
                                        showShippingLocationLabel
                                        shippingLocation={shippingLocation}
                                        orderMode={orderMode}
                                    />
                                </Card.Header>
                                <Card.Body>
                                    <div className={'single-cart-form-fields'}>
                                        {showStrictMinimumAmountField && (
                                            <Form.Group className={'strict-minimum-amount-form-group'}>
                                                <Form.Check
                                                    type={'switch'}
                                                    id={'minimum-amount-reached-only'}
                                                    checked={cartOnlyReachedMinimumAmount[cart['@id']] || false}
                                                    disabled={disabled}
                                                    label={formatMessage({
                                                        id: 'cart.create_order_only_minimum_amount_reached',
                                                    })}
                                                    data-id={cart['@id']}
                                                    onChange={handleStrictMinimumAmountFieldsChange}
                                                    value={1}
                                                />
                                            </Form.Group>
                                        )}
                                        <Form.Group controlId={`checkout.comment.${cart['@id']}`}>
                                            <Form.Label>
                                                <FormattedMessage id={'checkout.comment.label'} />
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={comment ? comment[cart['@id']] || '' : ''}
                                                onChange={handleOnInputChange}
                                                disabled={disabled}
                                                data-id={cart['@id']}
                                            />
                                            <Form.Text className="text-muted">
                                                <FormattedMessage id={'checkout.comment.help'} />
                                            </Form.Text>
                                        </Form.Group>
                                        {showRequestedDeliveryDateField && (
                                            <Form.Group
                                                controlId={`checkout.requested_delivery_date.${cart['@id']}`}
                                                className={classNames('requested-delivery-date-form-group', {
                                                    'is-mobile-touch': isTouch,
                                                })}
                                            >
                                                <Form.Label>
                                                    <FormattedMessage id={'checkout.requested_delivery_date.label'} />
                                                </Form.Label>
                                                <br />
                                                {isTouch && (
                                                    <button
                                                        type={'button'}
                                                        className={'form-control text-left mb-2'}
                                                        onClick={() =>
                                                            handleOnRequestedDeliveryDateMobileShow(cart['@id'])
                                                        }
                                                    >
                                                        {requestedDeliveryDate
                                                            ? ApplicationHelper.convertDate(
                                                                  requestedDeliveryDate[cart['@id']]
                                                              ) || undefined
                                                            : undefined}
                                                    </button>
                                                )}
                                                <DatePicker
                                                    inline={isTouch}
                                                    locale={locale}
                                                    disabled={disabled}
                                                    selected={
                                                        requestedDeliveryDate
                                                            ? requestedDeliveryDate[cart['@id']] || undefined
                                                            : undefined
                                                    }
                                                    excludeDates={excludeDates}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    minDate={minDeliveryDate}
                                                    maxDate={maxDeliveryDate}
                                                    startDate={minDeliveryDate}
                                                    filterDate={handleFilterDate}
                                                    onChange={(date) => {
                                                        if (date === null || date instanceof Date) {
                                                            handleOnRequestedDeliveryDateChange(date, cart['@id'])
                                                        }
                                                    }}
                                                    calendarClassName={classNames({
                                                        'd-none': isTouch && !isShowDateInlinePicker[cart['@id']],
                                                    })}
                                                    className={'form-control'}
                                                    popperPlacement={'top'}
                                                    data-id={cart['@id']}
                                                    id={`checkout.requested_delivery_date.${cart['@id']}`}
                                                    showDisabledMonthNavigation={false}
                                                />
                                                <Form.Text className="text-muted">
                                                    <FormattedMessage id={'checkout.requested_delivery_date.help'} />
                                                </Form.Text>
                                            </Form.Group>
                                        )}
                                        {showPaymentModes && (
                                            <>
                                                <Form.Group controlId={`checkout.payment_mode.${cart['@id']}`}>
                                                    <Form.Label>
                                                        <FormattedMessage id={'checkout.payment_mode.label'} />
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        custom
                                                        onChange={handlePaymentModeChange}
                                                        data-id={cart['@id']}
                                                        isInvalid={
                                                            violations &&
                                                            violations[cart['@id']] &&
                                                            violations[cart['@id']].payment_mode
                                                        }
                                                    >
                                                        <option>
                                                            {formatMessage({ id: `checkout.payment_mode.placeholder` })}
                                                        </option>
                                                        {paymentModes!.map((paymentMode) => (
                                                            <option key={paymentMode['@id']} value={paymentMode['@id']}>
                                                                {paymentMode.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                    {violations &&
                                                        violations[cart['@id']] &&
                                                        violations[cart['@id']].payment_mode && (
                                                            <p className="invalid-feedback mb-0 d-block">
                                                                {violations[cart['@id']].payment_mode}
                                                            </p>
                                                        )}
                                                </Form.Group>
                                            </>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })}
                {groupFormFields && (
                    <Card className={'single-cart-form card'} key={`form_cart_fields_all`}>
                        <Card.Body>
                            <div className={'group-carts-form-fields'} key={`form_cart_fields_all`}>
                                {showStrictMinimumAmountField && (
                                    <Form.Group className={'strict-minimum-amount-form-group'}>
                                        <Form.Check
                                            type={'switch'}
                                            id={'minimum-amount-reached-only'}
                                            checked={cartOnlyReachedMinimumAmount['all'] || false}
                                            disabled={disabled}
                                            label={formatMessage({
                                                id: 'cart.create_order_only_minimum_amount_reached',
                                            })}
                                            onChange={handleStrictMinimumAmountFieldsChange}
                                            value={1}
                                        />
                                    </Form.Group>
                                )}
                                <Form.Group controlId="checkout.global.comment">
                                    <Form.Label>
                                        <FormattedMessage id={'checkout.comment.label'} />
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={comment ? comment['all'] || '' : ''}
                                        rows={3}
                                        onChange={handleOnInputChange}
                                        disabled={disabled}
                                    />
                                    <Form.Text className="text-muted">
                                        <FormattedMessage id={'checkout.comment.help'} />
                                    </Form.Text>
                                </Form.Group>
                                {showRequestedDeliveryDateField && (
                                    <Form.Group
                                        controlId="checkout.requested_delivery_date"
                                        className={classNames('requested-delivery-date-form-group', {
                                            'is-mobile-touch': isTouch,
                                        })}
                                    >
                                        <Form.Label>
                                            <FormattedMessage id={'checkout.requested_delivery_date.label'} />
                                        </Form.Label>
                                        <br />
                                        {isTouch && (
                                            <button
                                                type={'button'}
                                                className={'form-control text-left mb-2'}
                                                onClick={() => handleOnRequestedDeliveryDateMobileShow('all')}
                                            >
                                                {requestedDeliveryDate
                                                    ? ApplicationHelper.convertDate(requestedDeliveryDate['all']) ||
                                                      undefined
                                                    : undefined}
                                            </button>
                                        )}
                                        <DatePicker
                                            inline={isTouch}
                                            locale={locale}
                                            disabled={disabled}
                                            selected={
                                                requestedDeliveryDate
                                                    ? requestedDeliveryDate['all'] || undefined
                                                    : undefined
                                            }
                                            excludeDates={excludeDates}
                                            dateFormat={'dd/MM/yyyy'}
                                            minDate={minGroupedDeliveryDate}
                                            maxDate={maxGroupedDeliveryDate}
                                            startDate={minGroupedDeliveryDate}
                                            filterDate={handleFilterDate}
                                            onChange={(date) => {
                                                if (date === null || date instanceof Date) {
                                                    handleOnRequestedDeliveryDateChange(date, 'all')
                                                }
                                            }}
                                            className={'form-control'}
                                            calendarClassName={classNames({
                                                'd-none': isTouch && !isShowDateInlinePicker['all'],
                                            })}
                                            popperPlacement={'top'}
                                            id={'checkout.requested_delivery_date'}
                                            showDisabledMonthNavigation={false}
                                        />
                                        <Form.Text className="text-muted">
                                            <FormattedMessage id={'checkout.requested_delivery_date.help'} />
                                        </Form.Text>
                                    </Form.Group>
                                )}
                                {showPaymentModes && (
                                    <>
                                        <Form.Group controlId={`checkout.payment_mode`}>
                                            <Form.Label>
                                                <FormattedMessage id={'checkout.payment_mode.label'} />
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    violations && violations['all'] && violations['all'].payment_mode
                                                }
                                                as="select"
                                                custom
                                                onChange={handlePaymentModeChange}
                                            >
                                                <option>
                                                    {formatMessage({ id: `checkout.payment_mode.placeholder` })}
                                                </option>
                                                {paymentModes!.map((paymentMode) => (
                                                    <option key={paymentMode['@id']} value={paymentMode['@id']}>
                                                        {paymentMode.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text className="text-muted">
                                                <FormattedMessage id={'checkout.payment_mode.help'} />
                                            </Form.Text>
                                            {violations && violations['all'] && violations['all'].payment_mode && (
                                                <p className="invalid-feedback mb-0 d-block">
                                                    {violations['all'].payment_mode}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                )}
                <div className={'create-order-form-action'}>
                    <Button type={'reset'} variant={'secondary'} disabled={disabled}>
                        <FlatIcon icon={'arrow-left'} /> <FormattedMessage id={'default.cancel'} />
                    </Button>
                    <Button
                        loading={disabled}
                        type={'submit'}
                        variant={'success'}
                        disabled={disabled}
                        className={'btn-process'}
                    >
                        <FlatIcon icon={'check'} /> {submitButtonLabel}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

CreateOrderForm.defaultProps = {
    disabled: false,
    fetching: false,
    allowGroupFields: false,
    showStrictMinimumAmountField: false,
} as Partial<IProps>

export default CreateOrderForm
