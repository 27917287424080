/**
 * Fichiers de traductions
 * Mapping JSON et agrégat ici
 *
 * à utiliser simplement dans les modules nécessitant les fichiers de langues (LangSwitcher, Initialisation du State)
 * avec messages[lang]
 */
import { CollectionMap } from '../types/common'
import Config from '../config'

// moment
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/pt'
import 'moment/locale/es'

// flatpick
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import { German } from 'flatpickr/dist/l10n/de.js'

// messages intl
import messagesFR from './fr/index.json'
import messagesEN from './en/index.json'
import messagesDE from './de/index.json'
import messagesIT from './it/index.json'
import messagesPT from './pt/index.json'
import messagesES from './es/index.json'

const frTranslationMessages = {
    ...messagesFR,
}
const enTranslationMessages = {
    ...messagesEN,
}

const deTranslationMessages = {
    ...messagesDE,
}
const itTranslationMessages = {
    ...messagesIT,
}
const ptTranslationMessages = {
    ...messagesPT,
}
const esTranslationMessages = {
    ...messagesES,
}

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill')
    require('@formatjs/intl-pluralrules/locale-data/en')
    require('@formatjs/intl-pluralrules/locale-data/fr')
    require('@formatjs/intl-pluralrules/locale-data/es')
    require('@formatjs/intl-pluralrules/locale-data/pt')
    require('@formatjs/intl-pluralrules/locale-data/it')
    require('@formatjs/intl-pluralrules/locale-data/de')
}

// RelativeTimeFormat est en stage 4 et donc, n'est pas déclaré dans TypeScript
// Javascript allant très vite, on prend les devant pour que lorsque la release sortira, on sera prêt
// @ts-ignore
if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill')
    require('@formatjs/intl-relativetimeformat/locale-data/en')
    require('@formatjs/intl-relativetimeformat/locale-data/fr')
    require('@formatjs/intl-relativetimeformat/locale-data/es')
    require('@formatjs/intl-relativetimeformat/locale-data/pt')
    require('@formatjs/intl-relativetimeformat/locale-data/it')
    require('@formatjs/intl-relativetimeformat/locale-data/de')
}

export type LocaleMessageCollection = CollectionMap<string>
export interface IMessageCollection {
    [locale: string]: LocaleMessageCollection
}

export const formatTranslationMessages = (
    locale: string,
    messages: LocaleMessageCollection
): LocaleMessageCollection => {
    const defaultFormattedMessages: LocaleMessageCollection =
        locale !== Config.I18N.FALLBACK_LOCALE
            ? formatTranslationMessages(Config.I18N.FALLBACK_LOCALE, enTranslationMessages)
            : {}

    if (locale === Config.I18N.FALLBACK_LOCALE) {
        return messages
    }

    const flattenFormattedMessages = (
        formattedMessages: LocaleMessageCollection,
        key: string
    ): LocaleMessageCollection => {
        const formattedMessage =
            !messages[key] && locale !== Config.I18N.FALLBACK_LOCALE ? defaultFormattedMessages[key] : messages[key]
        return { ...formattedMessages, [key]: formattedMessage }
    }
    return Object.keys(defaultFormattedMessages).reduce(flattenFormattedMessages, {})
}

export const translationMessages: IMessageCollection = {
    fr: formatTranslationMessages('fr', frTranslationMessages),
    en: formatTranslationMessages('en', enTranslationMessages),
    es: formatTranslationMessages('es', esTranslationMessages),
    de: formatTranslationMessages('de', deTranslationMessages),
    pt: formatTranslationMessages('pt', ptTranslationMessages),
    it: formatTranslationMessages('it', itTranslationMessages),
}

export const translationFlatpickr = {
    fr: French,
    en: undefined,
    es: Spanish,
    de: German,
    pt: Portuguese,
    it: Italian,
}

export const dateFormatFlatpickr = {
    fr: 'd/m/Y',
}

export const getFlatpickrTranslation = (locale: string) => {
    if (translationFlatpickr[locale]) {
        return translationFlatpickr[locale]
    }
    return undefined
}

export const getFlatpickrDateFormat = (locale: string) => {
    if (dateFormatFlatpickr[locale]) {
        return dateFormatFlatpickr[locale]
    }
    return 'Y-m-d'
}
