import { createContext } from 'react'
import { Undefinable } from 'tsdef'

export type InteractiveSlideIndex = Undefinable<number>

export type InteractiveSliderContext = {
    activePopoverIndex: InteractiveSlideIndex
    setActivePopoverIndex: (activePopoverIdx: InteractiveSlideIndex) => void
}

const InteractiveSlideCtx = createContext<InteractiveSliderContext>({
    activePopoverIndex: undefined,
    setActivePopoverIndex: () => {},
})

export default InteractiveSlideCtx
