/**
 *
 * Divider
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { CmsWidgetTheme, CmsWidgetType, ICmsWidgetWysiwyg } from '../../../../services/api/service/cms/types'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import isExternalRegexClosure from '../../../../utils/location'

interface IProps {
    widget: ICmsWidgetWysiwyg
    onLinkClick?: CmsWidgetLinkClickCallback
}

function Wysiwyg({ widget, onLinkClick }: IProps): JSX.Element {
    const handleOnWysiwygClick = (e: MouseEvent) => {
        if (e.target instanceof Element && e.target.tagName.toLowerCase() === 'a') {
            e.preventDefault()
            e.stopPropagation()
            const target: HTMLLinkElement = e.target as HTMLLinkElement
            if (onLinkClick) {
                onLinkClick({
                    widget: CmsWidgetType.Wysiwyg,
                    url: target.href || null,
                    text: null,
                    internal: !isExternalRegexClosure(target.href),
                    blank: target.getAttribute('target') === '_blank',
                })
            }
        }
    }

    return (
        <div
            onClick={handleOnWysiwygClick}
            className={classNames(
                'cms-widget',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                {widget.configuration.content && (
                    <div className={'content'}>{ReactHtmlParser(widget.configuration.content)}</div>
                )}
            </div>
        </div>
    )
}

Wysiwyg.defaultProps = {} as Partial<IProps>

export default memo(Wysiwyg)
