/**
 *
 * ImportState
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { IApiShopImportStateList } from '../../services/api/service/imports/types'

interface IProps {
    state: IApiShopImportStateList
}

function ImportState({ state }: IProps): JSX.Element {
    return (
        <span className={'shop-import-state'}>
            <FormattedMessage id={`shop_import.state.${state.toString()}`} />
        </span>
    )
}

ImportState.defaultProps = {} as Partial<IProps>

export default memo(ImportState)
