/*
 *
 * Customers constants
 *
 */

enum ActionTypes {
    SET_CURRENT_CUSTOMER_ACTION = 'app/Customers/SET_CURRENT',
    LOGOUT_CURRENT_CUSTOMER_ACTION = 'app/Customers/LOGOUT_CURRENT',
    RESET_CURRENT_CUSTOMER_ACTION = 'app/Customers/RESET_CURRENT',
    PROCESS_GET_ACTION = 'app/Customers/Get/REQUEST_PROCESS',
    SUCCESS_GET_ACTION = 'app/Customers/Get/REQUEST_SUCCESS',
    FAILURE_GET_ACTION = 'app/Customers/Get/REQUEST_FAILURE',
    PROCESS_VIEWED_PRODUCTS_ACTION = 'app/Customers/Selected/ViewedProducts/REQUEST_PROCESS',
    SUCCESS_VIEWED_PRODUCTS_ACTION = 'app/Customers/Selected/ViewedProducts/REQUEST_SUCCESS',
    FAILURE_VIEWED_PRODUCTS_ACTION = 'app/Customers/Selected/ViewedProducts/REQUEST_FAILURE',
    VIEWED_PRODUCTS_INVALIDATE_ACTION = 'app/Customers/Selected/ViewedProducts/INVALIDATE',
    SWITCH_STORE_ACTION = 'app/Customers/Selected/SWITCH_STORE',
    PROCESS_MODIFY_LOGIN_ACTION = 'app/Customers/Selected/ModifyLogin/REQUEST_PROCESS',
    SUCCESS_MODIFY_LOGIN_ACTION = 'app/Customers/Selected/ModifyLogin/REQUEST_SUCCESS',
    FAILURE_MODIFY_LOGIN_ACTION = 'app/Customers/Selected/ModifyLogin/REQUEST_FAILURE',
    RESET_MODIFY_LOGIN_ACTION = 'app/Customers/Selected/ModifyLogin/RESET',
    RESET_ACTION = 'app/Customers/RESET',
    RESET_ALL_ACTION = 'app/Customers/RESET_ALL',

    SALESMEN_PROCESS_ACTION = 'app/Customers/CustomerSalesmen/REQUEST_PROCESS',
    SALESMEN_SUCCESS_ACTION = 'app/Customers/CustomerSalesmen/REQUEST_SUCCESS',
    SALESMEN_FAILURE_ACTION = 'app/Customers/CustomerSalesmen/REQUEST_FAILURE',
}

export default ActionTypes
