/**
 *
 * MainMenu
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, NavLink } from 'react-router-dom'
import { getPath } from '../../routes'
import {
    ICustomerMenu,
    ICustomerMenuItem,
    ICustomerMenuItemClickCallback,
    ICustomerMenuItemType,
} from '../../types/customer'
import customerMenuItems from '../../utils/customer'
import FlatIcon from '../Icon/FlatIcon'
import { IMe } from '../../services/api/service/me/types'
import { ICustomer } from '../../services/api/service/customers/types'

interface IMenuItemProps {
    item: ICustomerMenuItem
    onItemClick: ICustomerMenuItemClickCallback
}

function MenuItem({ item, onItemClick }: IMenuItemProps): JSX.Element {
    const { locale } = useIntl()
    let itemUrl: string | undefined = undefined
    if (item.route) {
        itemUrl = generatePath(getPath(item.route, locale), { lang: locale })
    }

    const handleOnClick = (e: MouseEvent) => {
        onItemClick(e, item, itemUrl)
    }

    const Content = (
        <>
            <span className={'menu-item-icon'}>
                <FlatIcon icon={item.icon} className={'main-icon'} />
                {item.sub_icon && <FlatIcon icon={item.sub_icon!} className={'sub-icon'} />}
            </span>
            <span className={'menu-item-title'}>
                <FormattedMessage id={item.label} />
            </span>
        </>
    )

    return (
        <div className={classNames('menu-item', `menu-item-${item.type}`)} onClick={handleOnClick}>
            {itemUrl && (
                <NavLink to={itemUrl} className={'menu-item-nav'} exact={item.type === ICustomerMenuItemType.Dashboard}>
                    {Content}
                </NavLink>
            )}
            {!item.route && <div className={'menu-item-nav no-link'}>{Content}</div>}
        </div>
    )
}

interface IProps {
    className?: string
    isSalesman?: boolean
    onItemClick: ICustomerMenuItemClickCallback
    me: IMe
    customer: ICustomer
    exclude?: Array<ICustomerMenuItemType>
    only?: Array<ICustomerMenuItemType>
}

function Menu({ className, onItemClick, exclude, only, me, customer }: IProps): JSX.Element {
    let itms: ICustomerMenu = useMemo(() => {
        return [...customerMenuItems(me, customer)]
    }, [me, customer])

    if (only && only.length > 0) {
        itms = itms.filter((itm) => only.indexOf(itm.type) > -1)
    }
    if (exclude && exclude.length > 0) {
        itms = itms.filter((itm) => exclude.indexOf(itm.type) === -1)
    }
    return (
        <div className={classNames('customer-menu', className)}>
            {itms.map((value) => (
                <MenuItem item={value} key={value.type} onItemClick={onItemClick} />
            ))}
        </div>
    )
}

Menu.defaultProps = {
    exclude: [],
    only: [],
    isSalesman: false,
} as Partial<IProps>

export default memo(Menu)
