import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IProductList } from '../../../../services/api/service/products/types'
import { IProps as ProductItemListProps } from '../../../../components/Product/List/List'
import { default as ProductItemList } from '../../Partial/Item'
import { Undefinable } from 'tsdef'
import { IProductListUserSelectionCollection } from '../../../../store/products/types'
import { IMe } from '../../../../services/api/service/me/types'
import { ICustomer, ICustomerType } from '../../../../services/api/service/customers/types'
import { isSalesmanResource } from '../../../../store/salesmens/utils'
import { isShowProductCustomerInfo } from '../../../../store/customers/utils'
import { CustomerInfoPart } from '../../../../components/Product/Partial/CustomerInfo'

type Props = Omit<ProductItemListProps, 'className' | 'product' | 'picked' | 'productCustomerInfoParts'> & {
    items: Undefinable<Array<IProductList>>
    pickerProductIds: IProductListUserSelectionCollection
    pickerPropertyPathId: string
    me: IMe
    customer: ICustomer
}

function List({ items, pickerProductIds, pickerPropertyPathId, me, customer, ...rest }: Props): JSX.Element {
    return (
        <Row className={'product-list'}>
            {items?.map((product: IProductList) => {
                let productCustomerInfoParts: Array<CustomerInfoPart> | undefined = undefined
                if (!isSalesmanResource(me) && isShowProductCustomerInfo(customer)) {
                    productCustomerInfoParts = []
                    productCustomerInfoParts.push(CustomerInfoPart.Stock)
                    productCustomerInfoParts.push(CustomerInfoPart.Arrival)
                    if (product.order_in_progress_quantity > 0) {
                        productCustomerInfoParts.push(CustomerInfoPart.InProgressOrderQuantity)
                    } else {
                        productCustomerInfoParts.push(CustomerInfoPart.LastOrder)
                    }
                }

                return (
                    <Col key={`product_item_${product['@id']}`}>
                        <ProductItemList
                            className={'products-list-item'}
                            product={product}
                            picked={pickerProductIds.indexOf(product[pickerPropertyPathId]) !== -1}
                            productCustomerInfoParts={productCustomerInfoParts}
                            bypassCartLocker={customer.account_type === ICustomerType.MultiStore}
                            {...rest}
                        />
                    </Col>
                )
            })}
        </Row>
    )
}

export default memo(List)
