import classNames from 'classnames'
import React, { useCallback } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Config from '../../config'
import { IInvoiceDetail, IInvoiceListParameters } from '../../services/api/service/invoices/types'
import { IBasePaginationParameters } from '../../services/api/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import { invoicesListProcessAction } from '../../store/invoices/actions'
import {
    makeSelectInvoicesListError,
    makeSelectInvoicesListFetching,
    makeSelectInvoicesListItems,
    makeSelectInvoicesListTotalItems,
} from '../../store/invoices/selectors'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import Table from './Partial/InvoiceList'
import { Filters, SortingRule } from 'react-table'
import { $PropertyType } from 'utility-types'

interface IProps {}

const stateSelector = createStructuredSelector<any, any>({
    listFetching: makeSelectInvoicesListFetching(),
    listItems: makeSelectInvoicesListItems(),
    listTotalItems: makeSelectInvoicesListTotalItems(),
    listError: makeSelectInvoicesListError(),
    me: makeSelectAuthMe(),
})

function SalesmanInvoices(): JSX.Element {
    const dispatch = useDispatch()
    const { listFetching, listItems, listError, listTotalItems, me } = useSelector<
        IApplicationRootState,
        {
            listFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IInvoiceDetail>
            listError?: IAppErrorTypes | undefined
            me?: IMe
        }
    >(stateSelector)

    const handleFetchData = useCallback(
        (
            page: number,
            pageSize: number,
            filters: Filters<IInvoiceDetail>,
            sortBy: Array<SortingRule<IInvoiceDetail>>
        ) => {
            const orderBy: $PropertyType<IBasePaginationParameters, 'order'> = {}
            sortBy.forEach((sb) => {
                orderBy[sb.id] = sb.desc ? 'desc' : 'asc'
            })

            const parameters: IInvoiceListParameters = {
                page,
                itemsPerPage: pageSize,
                order: orderBy,
            }

            filters.forEach((filter) => (parameters[filter.id] = filter.value))
            dispatch(invoicesListProcessAction(parameters))
        },
        [dispatch]
    )

    return (
        <Card className={classNames('member-card')}>
            <Card.Header>
                <h3 className={'hv'}>
                    <FormattedMessage
                        id={isSalesmanResource(me) ? 'salesman_customer.invoices' : 'customer.invoices'}
                    />
                </h3>
            </Card.Header>
            <Card.Body>
                <div className={'invoice-list-section'} id={'invoice-list-section'}>
                    <div className={'invoice-list-content'}>
                        <Table
                            totalItems={listTotalItems}
                            data={listItems || []}
                            fetchData={handleFetchData}
                            fetching={listFetching}
                            fetchError={listError}
                            pageSize={Config.INVOICE_LIST.ITEMS_PER_PAGE}
                            me={me}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

SalesmanInvoices.defaultProps = {} as Partial<IProps>

export default SalesmanInvoices
