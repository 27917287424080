/**
 *
 * AlertMessages
 *
 */
import React, { memo } from 'react'
import { Alert } from 'react-bootstrap'
import { ICartCollection, ICartLightCollection } from '../../services/api/service/carts/types'
import { IFamilyTreeCollection } from '../../services/api/service/classification/types'
import { findShippingLocationBy } from '../../store/classification/utils'
import { CollectionMap } from '../../types/common'

interface IProps {
    tree: IFamilyTreeCollection
    carts: ICartCollection | ICartLightCollection
    messages: CollectionMap<Array<string>>
}

function AlertMessages({ tree, carts, messages }: IProps): JSX.Element {
    if (Object.values(messages).length === 0) {
        return <></>
    }

    return (
        <>
            {Object.values(messages).map((cartAlertMessages, idx) => {
                const cartId = Object.keys(messages)[idx]
                const currentCart = carts!.find((validCart) => validCart['@id'] === cartId)
                const shippingLocation = findShippingLocationBy(tree, '@id', currentCart!.shipping_location)
                return (
                    <Alert key={`cart_global_warning_${idx}`} variant={'warning'}>
                        <h3>{shippingLocation?.full_name}</h3>
                        {cartAlertMessages.map((cartAlertMessage) => (
                            <p key={cartAlertMessage} className={'mb-0'}>
                                {cartAlertMessage}
                            </p>
                        ))}
                    </Alert>
                )
            })}
        </>
    )
}

AlertMessages.defaultProps = {} as Partial<IProps>

export default memo(AlertMessages)
