import {
    ICart,
    ICartCollection,
    ICartItem,
    ICartLight,
    ICartLightCollection,
    ICartMode,
} from '../../services/api/service/carts/types'
import prop from '../../utils/prop'
import { CollectionMap } from '../../types/common'
import { ICustomer, ICustomerMinimumAmountMode, ICustomerType } from '../../services/api/service/customers/types'
import { findCustomerStore, isCustomer } from '../customers/utils'
import isUndefined from 'lodash/isUndefined'
import { ICartLockedData, StrictCartMode } from './types'
import { IOrderDetail } from '../../services/api/service/orders/types'
import { IMe } from '../../services/api/service/me/types'
import CONFIG from '../../config'

export type CartKeys = keyof ICart
export type CartItemKeys = keyof ICartItem

export function findCartBy(carts: ICartCollection, propertyName: CartKeys, value: any): ICart | null {
    let item: ICart | null = null
    item_loop: for (let i = 0; i < carts.length; i++) {
        const cart = carts[i]
        if (prop(cart, propertyName) === value) {
            item = cart
            break item_loop
        }
    }

    return item
}

export function findCartItemByProductId(carts: ICartCollection, productId: string): ICartItem | undefined {
    for (const index in carts) {
        const currentCart = carts[index]
        if (currentCart.items && Object.keys(currentCart.items).indexOf(productId) > -1) {
            return currentCart.items[productId]
        }
    }
    return undefined
}

export function cartHasErrors(cart: ICart | ICartLight): boolean {
    // @ts-ignore
    // eslint-disable-next-line no-prototype-builtins
    if (cart.hasOwnProperty('items')) {
        // @ts-ignore
        for (const productId in cart.items) {
            // @ts-ignore
            // eslint-disable-next-line no-prototype-builtins
            if (cart.items.hasOwnProperty(productId)) {
                // @ts-ignore
                if (cart.items[productId].errors.length > 0) {
                    return true
                }
            }
        }
    }
    return false
}

export function cartsDefaultActiveKey(carts: ICartCollection, selectedCartId?: string): string {
    if (selectedCartId) {
        return selectedCartId
    }

    for (const idx in carts) {
        if (carts[idx].items_count > 0) {
            return carts[idx]['@id']
        }
    }

    return carts && carts.length > 0 ? carts[0]['@id'] : ''
}

export function cartHasReachedMinimumAmount(cart: ICart): boolean {
    return cart.minimum_order_amount - cart.total <= 0
}

export function cartIsEmpty(cart: ICartLight): boolean {
    if (cart.items_count > 0) {
        return false
    }
    return true
}
export function cartsAllEmpties(carts: ICartCollection | ICartLightCollection): boolean {
    let all = true
    for (const idx in carts) {
        if (carts[idx].items_count > 0) {
            all = false
            break
        }
    }
    return all
}

export function cartsHasErrors(carts: ICartCollection | ICartLightCollection): boolean {
    for (const idx in carts) {
        const cart = carts[idx]
        if (cartHasErrors(cart)) {
            return true
        }
    }
    return false
}

export function formatCartLockValues(carts: ICartCollection): ICartLockedData {
    const values: ICartLockedData = {}
    for (const idx in carts) {
        const cart = carts[idx]
        values[cart['@id']] = cart.locked
        if (cart.store_carts) {
            for (const idx2 in cart.store_carts) {
                const storeCart = cart.store_carts[idx2]
                values[storeCart['@id']] = storeCart.locked
            }
        }
    }

    return values
}
export function formatShippingLocationLockValues(carts: ICartCollection): ICartLockedData {
    const values: ICartLockedData = {}
    for (const idx in carts) {
        const cart = carts[idx]
        values[cart.shipping_location] = cart.locked
    }

    return values
}

export function formatErrorValues(
    carts: ICartCollection,
    identifiers?: Array<string>,
    excludeIdentifiers?: boolean
): CollectionMap<Array<string>> {
    const errors: CollectionMap<Array<string>> = {}
    for (const idx in carts) {
        const cart = carts[idx]
        if (
            typeof identifiers === 'undefined' ||
            (!excludeIdentifiers && identifiers.indexOf(cart['@id']) > -1) ||
            (excludeIdentifiers && identifiers.indexOf(cart['@id']) === -1)
        ) {
            if (cart.items) {
                for (const productId in cart.items) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (cart.items.hasOwnProperty(productId)) {
                        errors[productId] = cart.items[productId].errors
                    }
                }
            }
        }
    }
    return errors
}

export function formatCanIncrementQuantityValues(
    carts: ICartCollection,
    identifiers?: Array<string>,
    excludeIdentifiers?: boolean
): CollectionMap<CollectionMap<boolean>> {
    const collection: CollectionMap<CollectionMap<boolean>> = {}
    for (const idx in carts) {
        const cart = carts[idx]
        if (
            typeof identifiers === 'undefined' ||
            (!excludeIdentifiers && identifiers.indexOf(cart['@id']) > -1) ||
            (excludeIdentifiers && identifiers.indexOf(cart['@id']) === -1)
        ) {
            if (cart.items) {
                for (const productId in cart.items) {
                    if (!collection[cart['@id']]) {
                        collection[cart['@id']] = {}
                    }
                    // eslint-disable-next-line no-prototype-builtins
                    if (cart.items.hasOwnProperty(productId)) {
                        collection[cart['@id']][productId] = cart.items[productId].can_increment_quantity
                    }
                }
            }
        }
    }
    return collection
}

export function formatQuantityValues(
    carts: ICartCollection,
    identifiers?: Array<string>,
    excludeIdentifiers?: boolean
): CollectionMap<CollectionMap<number>> {
    const quantities: CollectionMap<CollectionMap<number>> = {}
    for (const idx in carts) {
        const cart = carts[idx]
        if (
            typeof identifiers === 'undefined' ||
            (!excludeIdentifiers && identifiers.indexOf(cart['@id']) > -1) ||
            (excludeIdentifiers && identifiers.indexOf(cart['@id']) === -1)
        ) {
            if (cart.items) {
                for (const productId in cart.items) {
                    if (!quantities[cart['@id']]) {
                        quantities[cart['@id']] = {}
                    }
                    // eslint-disable-next-line no-prototype-builtins
                    if (cart.items.hasOwnProperty(productId)) {
                        quantities[cart['@id']][productId] = cart.items[productId].quantity
                    }
                }
            }
        }
    }
    return quantities
}

// TODO: Remove shippingLocation by main
export function findCartItemBy(
    carts: ICartCollection,
    shippingLocationId: string,
    propertyName: CartItemKeys,
    value: any
): ICartItem | null {
    let item: ICartItem | null = null
    const cart = findCartBy(carts, 'shipping_location', shippingLocationId)
    if (!cart) {
        return item
    }

    const items = cart?.items
    item_loop: for (const key in items) {
        const citem = items[key]
        if (prop(citem, propertyName) === value) {
            item = citem
            break item_loop
        }
    }
    return item
}

export function getMinimumOrderAmount(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    minimumAmountMode?: ICustomerMinimumAmountMode
): number {
    if (!minimumAmountMode || minimumAmountMode === ICustomerMinimumAmountMode.None) {
        return 0.0
    }

    let minimumOrderAmount = cart.minimum_order_amount || 0
    if (
        cart.customer === customer['@id'] &&
        customer.account_type === ICustomerType.MultiStore &&
        cartMode === ICartMode.Grouped &&
        minimumAmountMode === ICustomerMinimumAmountMode.Strict
    ) {
        minimumOrderAmount = 0.0
        cart.store_carts?.forEach((storeCart) => {
            minimumOrderAmount += storeCart.minimum_order_amount
        })
    }
    return minimumOrderAmount
}

export function isMinimumAmountActive(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    minimumAmountMode?: ICustomerMinimumAmountMode
): boolean {
    if (minimumAmountMode === ICustomerMinimumAmountMode.None) {
        return false
    }

    if (cartMode === ICartMode.Grouped && cart.customer === customer['@id'] && cart.store_carts) {
        let minimumOrderAmount = 0.0
        cart.store_carts.forEach((storeCart) => {
            if (storeCart.minimum_order_amount && storeCart.minimum_order_amount > 0) {
                minimumOrderAmount += storeCart.minimum_order_amount
            }
        })
        return minimumOrderAmount > 0
    }

    return cart.minimum_order_amount > 0
}

export function isMinimumAmountReached(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    minimumAmountMode?: ICustomerMinimumAmountMode
): boolean {
    if (minimumAmountMode === ICustomerMinimumAmountMode.None) {
        return true
    } else if (minimumAmountMode === ICustomerMinimumAmountMode.Permissive) {
        return true
    }

    if (cartMode === ICartMode.Grouped && customer.account_type === ICustomerType.MultiStore && cart.store_carts) {
        let allStoresReached = true
        let hasLeastOneReached = false
        for (const idx in cart.store_carts) {
            const storeCart = cart.store_carts[idx]
            const customerStore = findCustomerStore(customer, storeCart.customer)
            // console.log(storeCart, customerStore)
            if (!isMinimumAmountReached(storeCart, customerStore, ICartMode.Default, minimumAmountMode)) {
                allStoresReached = false
            } else {
                hasLeastOneReached = true
            }
        }

        return minimumAmountMode === ICustomerMinimumAmountMode.Strict ? allStoresReached : hasLeastOneReached
    }

    const minimumOrderAmount = getMinimumOrderAmount(cart, customer, cartMode, minimumAmountMode)
    const remainingAmount = minimumOrderAmount - cart.total

    return remainingAmount <= 0
}

export function isMinimumAmountRequired(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    minimumAmountMode?: ICustomerMinimumAmountMode
): boolean {
    return (
        isMinimumAmountActive(cart, customer, cartMode, minimumAmountMode) &&
        minimumAmountMode === ICustomerMinimumAmountMode.Strict
    )
}

export function isQuantitySelectorMultipleEnabled(
    customer: ICustomer,
    store?: ICustomer,
    cartMode: StrictCartMode = ICartMode.Default
): boolean {
    return typeof store === 'undefined' && cartMode === ICartMode.Grouped
}

export function isCartDeletable(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    checkItems = false
): boolean {
    return customer && cartMode === ICartMode.Default && (!checkItems || cart.items_count > 0)
}

export function isCartExportable(cart: ICartLight, customer: ICustomer, checkItems = true): boolean {
    return typeof customer !== 'undefined' && (!checkItems || cart.items_count > 0)
}

export function isCartDuplicable(
    cart: ICartLight,
    customer: ICustomer,
    cartMode: StrictCartMode,
    checkItems = true
): boolean {
    return (
        customer &&
        customer.account_type === ICustomerType.MultiStore &&
        cartMode === ICartMode.Default &&
        (!checkItems || cart.items_count > 0)
    )
}

export function isOrderAvailable(customer: ICustomer, store?: ICustomer, cartMode?: ICartMode): boolean {
    if (!customer || (!customer.order_available && customer.account_type !== ICustomerType.MultiStore)) {
        return false
    }
    if (customer.order_available && customer.account_type !== ICustomerType.MultiStore) {
        return true
    }

    if (cartMode === ICartMode.Grouped) {
        return true
    }

    return customer.can_order_on_main_store === true || typeof store !== 'undefined'
}

export function canUnlockOrder(customer: ICustomer): boolean {
    return customer.account_type === ICustomerType.MultiStore
}

export function canReOrder(customer: ICustomer, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    if (customer.account_type !== ICustomerType.MultiStore) {
        return isOrderAvailable(customer, store, cartMode)
    }
    return true
}

export function canDeclareAnomaly(order: IOrderDetail, me: IMe): boolean {
    if (
        CONFIG.ORDER_LIST.ORDER_ANOMALY &&
        (!order.can_declare_anomaly || (isCustomer(me) && !me.allow_declare_anomaly))
    ) {
        return false
    }
    return true
}

export function canPlaceOrder(customer: ICustomer, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    return customer && isOrderAvailable(customer, store, cartMode) && customer.can_place_order
}

export function canSwitchCartMode(customer: ICustomer): boolean {
    // return customer && customer.order_available && customer.account_type !== ICustomerType.Store
    return customer && customer.cart_mode === ICartMode.Both
}

export function isMultiStoreHasStoreOrderAvailable(customer: ICustomer, cartMode?: StrictCartMode): boolean {
    if (!customer || customer.account_type !== ICustomerType.MultiStore) {
        return true
    }

    if (!customer.stores || customer.stores.length === 0 || cartMode === ICartMode.Grouped) {
        return false
    }

    return true
}

export function canSwitchAccount(customer?: ICustomer, cartMode?: StrictCartMode): boolean {
    return (
        !isUndefined(customer) &&
        customer.account_type === ICustomerType.MultiStore &&
        customer.order_available &&
        cartMode === ICartMode.Default
    )
}
