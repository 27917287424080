/**
 *
 * OrderDetail
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Overlay, Tooltip } from 'react-bootstrap'

type IProps = {
    labelId?: string
    stockLabelId?: string
    uniqId?: string
    arrivalDate: string | null
    arrivalStock: number | null
    stock: number | null
    currentValue?: number | null
    tooltip?: boolean
    onClick?: (e: MouseEvent) => void
}

function ArrivalInfo({
    arrivalDate,
    arrivalStock,
    stock,
    currentValue,
    onClick,
    uniqId,
    tooltip = true,
    labelId = 'classification.arrival',
    stockLabelId = 'cart.stock',
}: IProps): JSX.Element {
    const { formatDate, formatMessage } = useIntl()
    const target = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const arrivalQuantityValue = useMemo(() => {
        if (!arrivalStock) {
            return String(0)
        }
        // return formatMessage({ id: 'default.product_plural' }, { count: lastOrderQuantity })
        return String(arrivalStock)
    }, [arrivalStock])

    const stockValue = useMemo(() => {
        if (!stock) {
            return String(0)
        }
        return String(stock)
    }, [stock])

    const arrivalDateValue = useMemo(() => {
        if (!arrivalDate) {
            return null
        }

        return formatMessage({ id: 'customer_order.from_date' }, { date: formatDate(arrivalDate) })
    }, [arrivalDate, formatMessage, formatDate])

    const Content = useMemo(() => {
        return (
            <>
                {stockValue && (
                    <div className="sub-product-field remaining-stock">
                        <span className={'lbl'}>{formatMessage({ id: stockLabelId })}</span>
                        <span className={'value'}>{stockValue}</span>
                    </div>
                )}
                <div className="sub-product-field arrival-stock">
                    <span className={'lbl'}>{formatMessage({ id: labelId })}</span>
                    <span className={'value'}>{arrivalQuantityValue}</span>
                    {arrivalDateValue && (
                        <span className="extra">
                            ({arrivalDateValue && <span className={'date'}>{arrivalDateValue}</span>})
                        </span>
                    )}
                </div>
            </>
        )
    }, [arrivalDateValue, arrivalQuantityValue, labelId, stockValue, stockLabelId, formatMessage])

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(tooltip)
    }, [setShowTooltip, tooltip])

    const handleMouseLeave = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    return (
        <>
            <Overlay show={showTooltip} target={target}>
                <Tooltip id={`tooltip-${uniqId || 'single'}-member-order`}>
                    <div className="tooltip-member-info-content">
                        <div
                            className={classNames(
                                'product-member-info',
                                'product-customer-order',
                                'product-field',
                                'has-subs',
                                {
                                    'has-date': typeof arrivalDate === 'string',
                                }
                            )}
                        >
                            {Content}
                        </div>
                    </div>
                </Tooltip>
            </Overlay>
            <div
                // @ts-ignore
                ref={target}
                className={classNames('product-member-info', 'product-salesman-stock', 'has-subs', 'product-field', {
                    'has-callback': typeof onClick !== 'undefined',
                    'has-date': typeof arrivalDate === 'string',
                    'quantity-exceeded':
                        typeof stock === 'number' && typeof currentValue === 'number' && stock < currentValue,
                })}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {Content}
            </div>
        </>
    )
}

export default memo(ArrivalInfo)
