/**
 *
 * Entry
 *
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import Highlighter from 'react-highlight-words'
import FlatIcon from '../../../../../components/Icon/FlatIcon'
import { IOptionEntryProps } from '../../type'

function Entry({ code, keyword, selected, showPreview, option, swatch, onChange }: IOptionEntryProps): JSX.Element {
    const [showPopover, setShowPopover] = useState<boolean>(false)
    const target = useRef<HTMLDivElement>(null)
    const showPreviewActive = useMemo(() => {
        return showPreview && option.image
    }, [showPreview, option])

    const handleOnEnter = useCallback(() => {
        if (showPreviewActive) {
            setShowPopover(true)
        }
    }, [setShowPopover, showPreviewActive])

    const handleOnLeave = useCallback(() => {
        if (showPreviewActive) {
            setShowPopover(false)
        }
    }, [setShowPopover, showPreviewActive])

    const handleOnClick = useCallback(() => {
        setShowPopover(false)
        if (onChange) {
            onChange(option, !selected)
        }
    }, [onChange, option, setShowPopover, selected])

    const FormLabel = useMemo(() => {
        return (
            <>
                {swatch && !option.color && !option.image && (
                    <span className={'form-label-color'}>
                        <span className={'form-label-color-inner'}>
                            <span className={'product-list-filter-color no-color'}>
                                <FontAwesomeIcon icon={['fal', 'times']} className={'app-icon'} />
                            </span>
                        </span>
                    </span>
                )}
                {swatch && option.color && !option.image && (
                    <span className={'form-label-color'}>
                        <span className={'form-label-color-inner'}>
                            <span
                                className={'product-list-filter-color'}
                                style={{
                                    backgroundColor: option.color,
                                }}
                            />
                        </span>
                    </span>
                )}
                {swatch && option.image && (
                    <span className={'form-label-image'}>
                        <span className={'form-label-image-inner'}>
                            <img className={'product-list-filter-image'} src={option.image} alt={option.label} />
                        </span>
                    </span>
                )}
                <span className={'form-label-text'}>
                    <Highlighter
                        highlightClassName={'suggestion'}
                        searchWords={keyword.split(' ')}
                        autoEscape={true}
                        textToHighlight={option.label}
                    />
                </span>
            </>
        )
    }, [swatch, option, keyword])

    return (
        <div
            ref={target}
            onMouseEnter={handleOnEnter}
            onMouseLeave={handleOnLeave}
            onClick={handleOnClick}
            className={classNames('entry', {
                'filter-has-image': swatch && option.image,
                'filter-has-color': swatch && option.color && !option.image,
                'is-selected': selected,
            })}
        >
            {FormLabel}
            <FlatIcon icon={'check'} className={'app-icon-selected'} />

            {showPreviewActive && (
                <Overlay target={target.current} show={showPopover} placement={'right-end'}>
                    <Popover id={`${code}-${option.id}-popover`} className={'filter-option-popover'}>
                        <Popover.Content>
                            {option.image && <img className={'placeholder'} alt={option.label} src={option.image} />}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            )}
        </div>
    )
}

Entry.defaultProps = {} as Partial<IOptionEntryProps>

export default memo(Entry)
