import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CmsBlockCodeList } from '../../services/api/service/cms/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import { isOrderAvailable } from '../../store/carts/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import CmsBlock from '../CmsBlock/CmsBlock'
import ViewedProducts from '../ViewedProducts/ViewedProducts'
import { makeSelectCartMode } from '../../store/carts/selectors'
import { StrictCartMode } from '../../store/carts/types'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
})

function Footer(): JSX.Element {
    const { customer, store, cartMode } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store: ICustomer
            cartMode: StrictCartMode
        }
    >(stateSelector)
    return (
        <footer id={'footer'} className={'site-footer'}>
            <div id={'footer-inner'}>
                <div id={'footer-top'}>
                    <Container>
                        {customer && isOrderAvailable(customer, store, cartMode) && <ViewedProducts />}
                    </Container>
                </div>
                <div id={'footer-body'}>
                    <CmsBlock identifier={CmsBlockCodeList.Footer} lazy />
                </div>
                <div id={'footer-bottom'}></div>
            </div>
        </footer>
    )
}

export default Footer
