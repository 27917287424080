import { call, cancelled, fork, getContext, put, select, take, takeLatest } from 'redux-saga/effects'
import { END, eventChannel } from 'redux-saga'

import ActionTypes from './constants'
import { IApiClient, SELECTED_CUSTOMER_HEADER_NAME } from '../../services/api/types'
import {
    IApiShopImportCheckStateProcessAction,
    IApiShopImportCreateProcessAction,
    IApiShopImportCreateProcessPayload,
    IApiShopImportDetailProcessAction,
    IApiShopImportListProcessAction,
} from './types'
import { formatAppError } from '../app/saga'
import {
    IApiShopImportListResponse,
    IApiShopImportStateList,
    ShopImportEntityType,
    ShopImportType,
} from '../../services/api/service/imports/types'
import {
    shopImportCheckStateFailureAction,
    shopImportCheckStateSuccessAction,
    shopImportCreateFailureAction,
    shopImportCreateProcessProgressAction,
    shopImportCreateSuccessAction,
    shopImportDetailFailureAction,
    shopImportDetailSuccessAction,
    shopImportListFailureAction,
    shopImportListSuccessAction,
    shopImportStoreMatrixUrlsAction,
} from './actions'
import {
    IApiShopImportCartCreateResponse,
    IApiShopImportCartResponse,
} from '../../services/api/service/cartImports/types'

import { IApiShopImportCustomerStockResponse } from '../../services/api/service/customerStocks/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { $enum } from 'ts-enum-util'
import { CollectionMap } from '../../types/common'
import { selectLocale } from '../intl/selectors'
import { cartFetchCartsAction } from '../carts/actions'
import { makeSelectCustomer, makeSelectCustomerStore } from '../customers/selectors'
// TOKEN
import axios from 'axios'
import { canUseMatrix } from './utils'
import { makeSelectCartMode } from '../carts/selectors'
const CancelToken = axios.CancelToken

function* createUploader(payload: IApiShopImportCreateProcessPayload) {
    const api: IApiClient = yield getContext('api')
    const { shopImportType, storeId } = payload
    const source = CancelToken.source()

    let emit: Function
    const chan = eventChannel((emitter) => {
        emit = emitter
        return () => {}
    })
    const uploadProgressCb = (progressEvent: ProgressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        emit(percentage)
        if (percentage === 100) emit(END)
    }

    // ANY
    let headers: any = undefined
    if (storeId) {
        headers = {
            [SELECTED_CUSTOMER_HEADER_NAME]: storeId,
        }
    }

    const uploadPromise =
        shopImportType === ShopImportType.Cart
            ? api.cartImports.create(payload.data, uploadProgressCb, source.token, headers)
            : api.customerStocks.create(payload.data, uploadProgressCb, source.token, headers)
    return [uploadPromise, chan]
}

// @ts-ignore
function* uploadProgressWatcher(chan: any, shopImportType: ShopImportType) {
    while (true) {
        // eslint-disable-line no-constant-condition
        const progress = yield take(chan)
        yield put(shopImportCreateProcessProgressAction(shopImportType, progress))
    }
}

// @ts-ignore
function* processCreateShopImportRequest(action: IApiShopImportCreateProcessAction) {
    const { shopImportType } = action.payload

    try {
        const [uploadPromise, chan] = yield call(createUploader, action.payload)
        yield fork(uploadProgressWatcher, chan, shopImportType)
        const response: IApiShopImportCartCreateResponse = yield call(() => uploadPromise)
        yield put(shopImportCreateSuccessAction(shopImportType, response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'export.unknow_error')
        yield put(shopImportCreateFailureAction(action.payload.shopImportType, error))
    }
}

function* processCheckShopImportStateRequest(action: IApiShopImportCheckStateProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { item } = action.payload
    const id = item['@id']
    const type = item['@type']

    try {
        let response: IApiShopImportCartResponse | IApiShopImportCustomerStockResponse | undefined = undefined
        if (type === ShopImportEntityType.CartImport) {
            response = yield call({ context: api.cartImports, fn: 'get' }, id)
        } else if (type === ShopImportEntityType.CustomerStockImport) {
            response = yield call({ context: api.customerStocks, fn: 'get' }, id)
        }
        if (response) {
            // success
            yield put(shopImportCheckStateSuccessAction(response.data))
            if (response.data.state === IApiShopImportStateList.Completed) {
                yield put(cartFetchCartsAction())
            }
        }
    } catch (e) {
        const err = yield call(formatAppError, e, 'export.unknow_error')
        yield put(shopImportCheckStateFailureAction(id, err))
    }
}

function* processShopImportListRequest(action: IApiShopImportListProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const { shopImportListType, parameters: params } = action.payload

    try {
        // on va chercher les produits
        const response: IApiShopImportListResponse = yield call(
            { context: api.imports, fn: 'list' },
            { ...params },
            source.token
        )

        yield put(shopImportListSuccessAction(shopImportListType, response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'default.unknow_list_error')
        yield put(shopImportListFailureAction(shopImportListType, err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processShopImportDetailRequest(action: IApiShopImportDetailProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const { itemId } = action.payload

    try {
        const type = itemId.match('cart-imports')
            ? ShopImportEntityType.CartImport
            : ShopImportEntityType.CustomerStockImport
        let response: IApiShopImportCartResponse | IApiShopImportCustomerStockResponse | undefined = undefined
        if (type === ShopImportEntityType.CartImport) {
            response = yield call({ context: api.cartImports, fn: 'get' }, itemId)
        } else if (type === ShopImportEntityType.CustomerStockImport) {
            response = yield call({ context: api.customerStocks, fn: 'get' }, itemId)
        }
        if (response) {
            yield put(shopImportDetailSuccessAction(response.data))
        }
    } catch (e) {
        const err = yield call(formatAppError, e, 'default.unknow_error')
        yield put(shopImportDetailFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processStoreMatrixUrlsAction(customer: ICustomer, store?: ICustomer) {
    const api: IApiClient = yield getContext('api')
    const locale = yield select(selectLocale)
    const cartMode = yield select(makeSelectCartMode())
    const shopImportTypeValues = $enum(ShopImportType).getValues()
    const matrixUrls: CollectionMap<string> = {}
    if (!canUseMatrix(customer, store, cartMode)) {
        yield put(shopImportStoreMatrixUrlsAction(matrixUrls))
        return
    }
    shopImportTypeValues.forEach((shopImportTypeValue) => {
        matrixUrls[shopImportTypeValue] = api.imports.getImportFileUrl(
            locale,
            shopImportTypeValue,
            customer.account_type
        )
    })
    yield put(shopImportStoreMatrixUrlsAction(matrixUrls))
}

// function* processCustomerRetrievedAction(action: ICustomersMeSuccessAction) {
//     yield call(processStoreMatrixUrlsAction, action.payload.me)
// }

function* processCustomerRefreshAction() {
    const customer: ICustomer = yield select(makeSelectCustomer())
    const store: ICustomer | undefined = yield select(makeSelectCustomerStore())

    if (customer) {
        yield call(processStoreMatrixUrlsAction, customer, store)
    }
}

export default [
    takeLatest(ActionTypes.CHECK_STATE_PROCESS_ACTION, processCheckShopImportStateRequest),
    takeLatest(ActionTypes.CREATE_PROCESS_ACTION, processCreateShopImportRequest),
    takeLatest(ActionTypes.LIST_PROCESS_ACTION, processShopImportListRequest),
    takeLatest(ActionTypes.DETAIL_PROCESS_ACTION, processShopImportDetailRequest),
    takeLatest(ActionTypes.FORMAT_MATRIX_URLS_ACTION, processCustomerRefreshAction),
    // takeLatest(AuthActionTypes.SUCCESS_ME_ACTION, processCustomerRetrievedAction),
]
