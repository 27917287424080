import React, { useState } from 'react'
import { createPortal } from 'react-dom'

function createRootElement(id) {
    const rootElement = document.createElement('div')
    rootElement.id = id
    return rootElement
}

export default function Portal({ id = 'u10-app', children }: { id?: string; children: React.ReactNode }): JSX.Element {
    const [rootElement] = useState(() => document.getElementById(id))

    // useEffect(() => {
    //     const newRootElement = rootElement || createRootElement(id)
    //
    //     // If there is no existing DOM element, add a new one.
    //     if (!rootElement) {
    //         document.body.appendChild(newRootElement)
    //     }
    //
    //     setRootElement(newRootElement)
    //
    //     // Remove root element from DOM on component unmount
    //     return () => newRootElement.remove()
    // }, [id])

    if (!rootElement) {
        return <></>
    }

    return createPortal(children, rootElement)
}
