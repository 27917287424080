/*
 *
 * Cms constants
 *
 */

enum ActionTypes {
    PAGE_PROCESS_ACTION = 'app/Cms/Page/REQUEST_PROCESS',
    PAGE_SUCCESS_ACTION = 'app/Cms/Page/REQUEST_SUCCESS',
    PAGE_FAILURE_ACTION = 'app/Cms/Page/REQUEST_FAILURE',
    PAGE_RESET_ACTION = 'app/Cms/Page/RESET',
    BLOCK_PROCESS_ACTION = 'app/Cms/Block/REQUEST_PROCESS',
    BLOCK_SUCCESS_ACTION = 'app/Cms/Block/REQUEST_SUCCESS',
    BLOCK_FAILURE_ACTION = 'app/Cms/Block/REQUEST_FAILURE',
    BLOCK_RESET_ACTION = 'app/Cms/Block/RESET',
    RESET_ACTION = 'app/Cms/RESET',
}

export default ActionTypes
