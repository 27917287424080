/**
 *
 * Suggestion Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { ProductClickCallback } from '../../../types/productCallback'
import { Heading as ProductHeading, MainPicture, Packing as ProductPacking, Price as ProductPrice } from '../Partial'

export interface IProps {
    product: IProductCatalog
    className?: string
    onProductClick?: ProductClickCallback
    showPacking?: boolean
    showPrices?: boolean
    column?: number
    noGutters?: boolean
}

function Suggestion({
    product,
    className,
    onProductClick,
    showPacking,
    showPrices,
    column,
    noGutters,
}: IProps): JSX.Element {
    const handleProductClick = () => {
        if (onProductClick) {
            onProductClick(product)
        }
    }

    return (
        <div
            className={classNames('product', 'product-card', 'product-suggestion', className)}
            onClick={handleProductClick}
        >
            <Row noGutters={noGutters}>
                <Col xs={column}>
                    <MainPicture product={product} />
                </Col>
                <Col>
                    <ProductHeading product={product} showEan showEanLabel showReference showReferenceLabel />
                    {showPacking && <ProductPacking packing={product.packing} unitOfSale={product.unit_of_sale} />}
                    {showPrices && (
                        <ProductPrice
                            price={product.price}
                            strikePrice={product.strike_price}
                            retailPrice={product.retail_price}
                            unitOfSale={product.unit_of_sale}
                        />
                    )}
                </Col>
            </Row>
        </div>
    )
}

Suggestion.defaultProps = {
    showPacking: false,
    showPrices: false,
    column: 6,
    noGutters: true,
} as Partial<IProps>

export default memo(Suggestion)
