import { call, getContext, put, takeLatest } from 'redux-saga/effects'
import ActionTypes from './constants'
import { IStockAlertBulkProcessAction, IStockAlertProcessAction, StockAlertOrigin } from './types'
import { IApiClient } from '../../services/api/types'
import Config from '../../config'
import * as Sentry from '@sentry/react'
import { IApiStockAlertResponse } from '../../services/api/service/stockAlert/types'
import {
    stockAlertBulkFailureAction,
    stockAlertBulkSuccessAction,
    stockAlertFailureAction,
    stockAlertSuccessAction,
} from './actions'
import { IApiErrorDescription } from '../http/types'
import { formatApiError, formatAppError, formatIntlMessage } from '../app/saga'
import HttpStatusCode, { HttpAuthErrors } from '../http/codes'
import { authLogoutAction } from '../auth/actions'
import { toast } from 'react-toastify'
import { violationStandardToString } from '../../utils/api'
import { productsSelectionApplyActionFailure, productsSelectionApplyActionSuccess } from '../products/actions'
import { classificationMenuProcessAction } from '../classification/actions'

function* processStockAlertRequest(action: IStockAlertProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productId, origin, subscribe } = action.payload

    try {
        const response: IApiStockAlertResponse = yield call(
            { context: api.stockAlert, fn: subscribe ? 'addStockAlert' : 'removeStockAlert' },
            productId
        )
        yield put(stockAlertSuccessAction(productId, response.data.active, origin))
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }

        const error: IApiErrorDescription = yield call(
            formatApiError,
            e,
            subscribe ? `restock_alert.subscribe_error` : `restock_alert.unsubscribe_error`
        )
        if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
            if (error.status === HttpStatusCode.UNAUTHORIZED || error.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                const logoutErr = yield call(formatAppError, e, 'default.http.code.401')
                yield put(authLogoutAction(true, logoutErr, window.location.pathname))
                return
            }
        }

        const message = violationStandardToString(error)
        toast.error(message, {
            toastId: 'stock-alert-error',
        })
        const canAddStockAlert = false
        yield put(stockAlertFailureAction(e, productId, !subscribe, origin, canAddStockAlert))
    }
}

function* processStockAlertBulkRequest(action: IStockAlertBulkProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productIds, origin, subscribe } = action.payload

    try {
        yield call(
            { context: api.stockAlert, fn: subscribe ? 'addBulkStockAlert' : 'removeBulkStockAlert' },
            productIds
        )
        // badge le badge dans le menu !
        yield put(classificationMenuProcessAction())

        yield put(stockAlertBulkSuccessAction(productIds, subscribe, origin))

        // on dispatch dans le cas de la liste
        if (origin === StockAlertOrigin.Picker) {
            const mesg = yield call(formatIntlMessage, 'product_picker.arrivals_processed_message')
            yield put(productsSelectionApplyActionSuccess(mesg))
        }
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }

        const error: IApiErrorDescription = yield call(
            formatApiError,
            e,
            subscribe ? `restock_alert.subscribe_error` : `restock_alert.unsubscribe_error`
        )
        if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
            if (error.status === HttpStatusCode.UNAUTHORIZED || error.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                const logoutErr = yield call(formatAppError, e, 'default.http.code.401')
                yield put(authLogoutAction(true, logoutErr, window.location.pathname))
                return
            }
        }

        const message = violationStandardToString(error)
        toast.error(message, {
            toastId: 'stock-alert-error',
        })
        yield put(stockAlertBulkFailureAction(e, productIds, !subscribe, origin))
        if (origin === StockAlertOrigin.Picker) {
            yield put(productsSelectionApplyActionFailure(new Error(message || e.message)))
        }
    }
}

export default [
    takeLatest(ActionTypes.STOCK_ALERT_PROCESS_ACTION, processStockAlertRequest),
    takeLatest(ActionTypes.STOCK_ALERT_BULK_PROCESS_ACTION, processStockAlertBulkRequest),
]
