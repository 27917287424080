/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders'

interface IProps {
    className?: string
}

function Placeholder({ className }: IProps): JSX.Element {
    return (
        <div
            className={classNames(
                'product',
                'product-card',
                'product-card-placeholder',
                'product-list-item',
                className
            )}
        >
            <div className={'product-head'}>
                <div className={classNames('product-image', 'product-image-list')}>
                    <div className={'product-image-container'}>
                        <div className={'product-image-inner'}>
                            <RectShape />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'product-content'}>
                <TextBlock rows={1} color={'gray'} className={'product-name-placeholder'} />
                <TextBlock rows={1} color={'gray'} className={'product-reference-placeholder'} />
                <Row noGutters className={'align-items-top'} style={{ marginTop: '1rem' }}>
                    <Col sm={10}>
                        <TextBlock rows={1} color={'gray'} className={'product-price-placeholder'} />
                        <TextBlock rows={1} color={'gray'} className={'product-price-placeholder'} />
                    </Col>
                    <Col>
                        <RectShape color={'gray'} className={'product-quantity-placeholder'} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default memo(Placeholder)
