import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import classNames, { Value as ClassValue } from 'classnames'
import ContainerVolume from './ContainerVolume'
import { Col, Overlay, Popover, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import FlatIcon from '../Icon/FlatIcon'
import { ColProps } from 'react-bootstrap/Col'
import { $PropertyType } from 'utility-types'

type IProps = {
    className?: ClassValue
    uniqId: string
    totalVolume: number
    step?: number
    xs?: $PropertyType<ColProps, 'xs'>
    sm?: $PropertyType<ColProps, 'sm'>
    md?: $PropertyType<ColProps, 'md'>
    lg?: $PropertyType<ColProps, 'lg'>
    xl?: $PropertyType<ColProps, 'xl'>
}

function ContainerVolumes({
    uniqId,
    className,
    totalVolume = 0.0,
    step = 65.0,
    xs = 24,
    sm,
    md,
    lg,
    xl,
}: IProps): JSX.Element {
    const componentRef = useRef() as React.MutableRefObject<HTMLSpanElement>
    const [showTooltip, setShowTooltip] = useState(false)

    // on regarde le nombre de conteneur à créer
    const volumes = useMemo(() => {
        const values = new Array(Math.floor(totalVolume / step)).fill(step)
        const remaining = totalVolume % step
        if (remaining > 0) {
            values.push(remaining)
        }
        return values
    }, [totalVolume, step])

    const handleHelpMouseEnter = useCallback(() => {
        setShowTooltip(true)
    }, [setShowTooltip])

    const handleHelpMouseLeave = useCallback(() => {
        setShowTooltip(false)
    }, [setShowTooltip])

    const roundedTotal = useMemo(() => {
        return Math.round((totalVolume + Number.EPSILON) * 100) / 100
    }, [totalVolume])

    return (
        <div className={classNames('container-volumes', className)}>
            <Overlay placement={'top'} target={componentRef!.current} show={showTooltip}>
                <Popover id={`popover-volume-resume-${uniqId}`}>
                    <Popover.Content>
                        <FormattedMessage id="container_volume.explain" />
                    </Popover.Content>
                </Popover>
            </Overlay>
            <Row noGutters>
                <Col>
                    <div className="resume">
                        <span className="lbl">
                            <FormattedMessage id="default.volume" />
                        </span>
                        <span className="value">
                            {`${roundedTotal} m`}
                            <sup>3</sup>
                        </span>{' '}
                        <span
                            ref={componentRef}
                            className="icon"
                            onMouseEnter={handleHelpMouseEnter}
                            onMouseLeave={handleHelpMouseLeave}
                        >
                            <FlatIcon className="d-inline-block" icon={'info'} />
                        </span>
                    </div>
                </Col>
            </Row>
            <Row className="container-volume-list">
                {volumes.map((volume, index) => (
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={`volume_container_${index}_${volume}`}>
                        <ContainerVolume volume={volume} uniqId={uniqId} />
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default memo(ContainerVolumes)
