/*
 *
 * PictureExport constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/PictureExport/RESET',
    PROCESS_RESET_ACTION = 'app/PictureExport/Export/RESET',
    LIST_RESET_ACTION = 'app/PictureExport/List/RESET',
    DETAIL_RESET_ACTION = 'app/PictureExport/Detail/RESET',
    PROCESS_CREATE_PROCESS_ACTION = 'app/PictureExport/Process/Create/REQUEST_PROCESS',
    PROCESS_CREATE_SUCCESS_ACTION = 'app/PictureExport/Process/Create/REQUEST_SUCCESS',
    PROCESS_CREATE_FAILURE_ACTION = 'app/PictureExport/Process/Create/REQUEST_FAILURE',
    PROCESS_REFRESH_PROCESS_ACTION = 'app/PictureExport/Process/Refresh/REQUEST_PROCESS',
    PROCESS_REFRESH_SUCCESS_ACTION = 'app/PictureExport/Process/Refresh/REQUEST_SUCCESS',
    PROCESS_REFRESH_FAILURE_ACTION = 'app/PictureExport/Process/Refresh/REQUEST_FAILURE',
    LIST_PROCESS_ACTION = 'app/PictureExport/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/PictureExport/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/PictureExport/List/REQUEST_FAILURE',
}

export default ActionTypes
