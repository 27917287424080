/**
 *
 * Modal
 *
 */
import React, { useCallback, useMemo } from 'react'
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ScrollTo } from 'scroll-to-position'
import FlatIcon from '../../components/Icon/FlatIcon'
import { MainPicture } from '../../components/Product/Partial'
import { default as ProductSuggestion } from '../../components/Product/Navigation/Suggestion'
import { IProductList } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { makeSelectProductListNextItem, makeSelectProductListPrevItem } from '../../store/products/selectors'
import { generateProductUrl } from '../../utils/productHelper'
import { PRODUCT_DETAIL_MODAL_ID } from './Modal'

interface IProps {
    productId: string
}

function ProductDetailNavigation({ productId }: IProps): JSX.Element {
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const selectPreviousProductWithProductId = useMemo(makeSelectProductListPrevItem, [])
    const selectNextProductWithProductId = useMemo(makeSelectProductListNextItem, [])
    const previous: IProductList | null | undefined = useSelector<
        IApplicationRootState,
        IProductList | null | undefined
    >((state) => selectPreviousProductWithProductId(state, productId))
    const next: IProductList | null | undefined = useSelector<IApplicationRootState, IProductList | null | undefined>(
        (state) => selectNextProductWithProductId(state, productId)
    )

    const redirectProductUrl = useCallback(
        (product: IProductList) => {
            const finalUrl = generateProductUrl(product.id, locale)
            // @ts-ignore
            if (location.state && location.state.background) {
                // @ts-ignore
                history.push(finalUrl, { background: location.state.background })
            } else {
                history.push(finalUrl)
            }
            const productAttributeElement = document.getElementById(PRODUCT_DETAIL_MODAL_ID)
            if (productAttributeElement) {
                let opts = {}
                if (document.body.classList.contains('modal-open')) {
                    const container = document.querySelector('.modal')
                    opts = {
                        scrollContainer: container,
                    }
                }
                ScrollTo(productAttributeElement, opts)
            }
        },
        [history, location, locale]
    )

    const handleOnPreviousClick = () => {
        if (previous) {
            redirectProductUrl(previous)
        }
    }
    const handleOnNextClick = () => {
        if (next) {
            redirectProductUrl(next)
        }
    }

    if (!previous && !next) {
        return <></>
    }

    let PreviousComponent = <></>
    let NextComponent = <></>
    if (previous) {
        PreviousComponent = (
            <Popover id={`popover-button-cart-${previous['@id']}`} className={'product-detail-navigation-popover'}>
                <Popover.Content>
                    <ProductSuggestion product={previous} column={8} showPacking showPrices />
                </Popover.Content>
            </Popover>
        )
    }

    if (next) {
        NextComponent = (
            <Popover id={`popover-button-cart-${next['@id']}`} className={'product-detail-navigation-popover'}>
                <Popover.Content>
                    <ProductSuggestion product={next} column={8} showPacking showPrices noGutters={false} />
                </Popover.Content>
            </Popover>
        )
    }

    return (
        <div className={'product-detail-navigation'}>
            <Row noGutters>
                <Col>
                    {previous && (
                        <button
                            type={'button'}
                            className={'btn-product-detail-navigation btn-prev'}
                            onClick={handleOnPreviousClick}
                        >
                            <div className={'product-card'}>
                                {previous && (
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={['hover', 'focus', 'click']}
                                        overlay={PreviousComponent}
                                    >
                                        <span className={'d-block'}>
                                            <MainPicture product={previous} />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>

                            <FlatIcon icon={'arrow-left'} />
                            <span className={'lbl'}>
                                <FormattedMessage id={'product.navigation.previous'} />
                            </span>
                            <span className={'lbl abbr'}>
                                <FormattedMessage id={'default.previous'} />
                            </span>
                        </button>
                    )}
                </Col>
                <Col>
                    {next && (
                        <button
                            type={'button'}
                            className={'btn-product-detail-navigation btn-next'}
                            onClick={handleOnNextClick}
                        >
                            <span className={'lbl'}>
                                <FormattedMessage id={'product.navigation.next'} />
                            </span>
                            <span className={'lbl abbr'}>
                                <FormattedMessage id={'default.next'} />
                            </span>
                            <FlatIcon icon={'arrow-right'} />
                            <div className={'product-card'}>
                                {next && (
                                    <OverlayTrigger
                                        placement={'auto'}
                                        trigger={['hover', 'focus', 'click']}
                                        overlay={NextComponent}
                                    >
                                        <span className={'d-block'}>
                                            <MainPicture product={next} />
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </button>
                    )}
                </Col>
            </Row>
        </div>
    )
}

ProductDetailNavigation.defaultProps = {} as Partial<IProps>

export default ProductDetailNavigation
