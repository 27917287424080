import classNames from 'classnames'
import { getLangNameFromCode } from 'language-name-map'
import React, { useCallback } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { updateIntl } from 'react-intl-redux'
import { useDispatch } from 'react-redux'
import LangItem from '../../components/LangItem/LangItem'
import { LangItemParts } from '../../components/LangItem/types'
import Config from '../../config'
import { translationMessages } from '../../i18n'

interface IProps {
    identifier?: string
    className?: string
    selectedItemParts?: LangItemParts
    itemParts: LangItemParts
}

function LangSwitcher({ identifier, className, selectedItemParts, itemParts }: IProps): JSX.Element {
    const intl = useIntl()
    const dispatch = useDispatch()
    const languages = Config.I18N.LANGUAGES
    const onClickLanguage = useCallback(
        (locale: string | null) => {
            if (locale !== null && locale !== intl.locale) {
                dispatch(
                    updateIntl({
                        locale: locale,
                        messages: translationMessages[locale],
                    })
                )
            }
        },
        [dispatch, intl.locale]
    )

    const Component = (
        <LangItem locale={intl.locale} className={'current-locale'} parts={selectedItemParts || itemParts} />
    )

    return (
        <div className={classNames('language-switcher', className)}>
            <DropdownButton id={identifier!} title={Component} variant={'default'} onSelect={onClickLanguage}>
                {languages.map((locale: string) => {
                    const item = getLangNameFromCode(locale)
                    if (!item) {
                        return <></>
                    }
                    return (
                        <Dropdown.Item key={locale} eventKey={locale} active={locale === intl.locale}>
                            <LangItem locale={locale} parts={itemParts} />
                        </Dropdown.Item>
                    )
                })}
            </DropdownButton>
        </div>
    )
}

LangSwitcher.defaultProps = {
    identifier: 'language-switcher',
    expanded: false,
} as Partial<IProps>

export default LangSwitcher
