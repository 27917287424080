/*
 *
 * Products
 *
 */

import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { default as ProductListPlaceholder } from '../../components/Product/List/Placeholder'

interface IProps {
    itemsPerPage: number
}

function Placeholder({ itemsPerPage }: IProps): JSX.Element {
    const components: Array<JSX.Element> = []
    for (let i = 1; i <= itemsPerPage; i++) {
        components.push(
            <Col key={`placeholder_col_item_${i}`}>
                <ProductListPlaceholder key={`placeholder_item_${i}`} />
            </Col>
        )
    }
    return <Row className={'product-list'}>{components}</Row>
}

Placeholder.defaultProps = {} as Partial<IProps>

export default memo(Placeholder)
