import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerExports from '../containers/CustomerExports/Loadable'

export default function CustomerExportsPage(): JSX.Element {
    const { formatMessage } = useIntl()
    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.export.title' })}</title>
            </Helmet>
            <CustomerExports />
        </>
    )
}
