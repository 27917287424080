import React, { memo } from 'react'
import { IMemberStatisticsFilters } from '../../../../../store/statistics/types'
import ApplicationHelper from '../../../../../utils/applicationHelper'

type IProps = {
    filters?: IMemberStatisticsFilters
}

/*
{filters?.departments && filters?.departments.entities?.length && (
    <>
        <span className="separator"> | </span>
        <span className="departments">
            {filters?.departments.entities?.map((department) => department.label).join(', ')}
        </span>
    </>
)}
 */

function Resume({ filters }: IProps): JSX.Element {
    return (
        <div className={'statistics-filters-resume'}>
            {(filters?.from || filters?.to) && (
                <span className="period">
                    {filters?.from && (
                        <span className="period-from">{ApplicationHelper.convertDate(filters.from)}</span>
                    )}
                    <span className="separator"> - </span>
                    {filters?.to && <span className="period-to">{ApplicationHelper.convertDate(filters.to)}</span>}
                </span>
            )}
        </div>
    )
}

export default memo(Resume)
