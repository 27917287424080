/**
 *
 * ReOrder
 *
 */
import React, { memo, useCallback, useMemo } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { DropdownItemChoiceTypes, ICartReOrderProps, IReOrderCartMode } from '../../../../../types/cart'
import { $enum } from 'ts-enum-util'
import { IOrderMode } from '../../../../../services/api/service/orders/types'
import { Nullable } from 'tsdef'

function Simple({ fetching, shippingLocation, onReOrderSubmit, useCartType, label }: ICartReOrderProps): JSX.Element {
    const { formatMessage } = useIntl()

    const formattedLabel = useMemo(() => {
        return label || formatMessage({ id: 'order.detail.re_order' })
    }, [label, formatMessage])

    const choices: DropdownItemChoiceTypes = useMemo(() => {
        const values: DropdownItemChoiceTypes = []
        if (!useCartType) {
            $enum(IReOrderCartMode).forEach((cartMode) => {
                values.push({
                    cartMode,
                    value: cartMode,
                    label: formatMessage(
                        {
                            id: shippingLocation
                                ? `order.detail.re_order.${cartMode}_cart_for_shipping`
                                : `order.detail.re_order.${cartMode}_cart`,
                        },
                        {
                            shipping_location: shippingLocation?.label,
                        }
                    ),
                })
            })
            return values
        }

        $enum(IReOrderCartMode).forEach((cartMode) => {
            ;[IOrderMode.Order].forEach((cartType) => {
                values.push({
                    value: `${cartMode}_${cartType}`,
                    cartMode,
                    cartType,
                    label: formatMessage(
                        {
                            id: shippingLocation
                                ? `order.detail.re_order.cart_type.${cartMode}_cart_for_shipping`
                                : `order.detail.re_order.cart_type.${cartMode}_cart`,
                        },
                        {
                            shipping_location: shippingLocation?.label,
                            cart_type: formatMessage({ id: `order_mode.${cartType}` }),
                        }
                    ),
                })
            })
        })

        return values
    }, [useCartType, formatMessage, shippingLocation])

    const handleSelect = useCallback(
        (identifier: Nullable<string>) => {
            const item = choices.find((item) => item.value === identifier)
            if (item && onReOrderSubmit) {
                onReOrderSubmit({
                    erase_cart: item.cartMode === IReOrderCartMode.Erase,
                    cart_type: item.cartType,
                })
            }
        },
        [choices, onReOrderSubmit]
    )

    return (
        <div className={'re-order-action simple'}>
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="primary" id="dropdown-reorder" block disabled={fetching}>
                    {fetching && (
                        <Spinner
                            as="span"
                            animation={'grow'}
                            size={'sm'}
                            variant={'light'}
                            role={'loading'}
                            aria-hidden="true"
                        />
                    )}
                    {formattedLabel}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {choices.map((item) => {
                        return (
                            <Dropdown.Item key={`${item.value}_${item.cartType || '1'}`} eventKey={item.value}>
                                {item.label}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

Simple.defaultProps = {} as Partial<ICartReOrderProps>

export default memo(Simple)
