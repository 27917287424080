/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent, useCallback, useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox'
// import Swiper core and required components
import SwiperCore, { A11y, Controller, Lazy, Navigation, Pagination, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import { IProductDetail, IProductDetailImage } from '../../../services/api/service/products/types'
import { ProductImageClickCallback } from '../../../types/productCallback'
import CopyrightImage from '../../CopyrightImage/CopyrightImage'
import FlatIcon from '../../Icon/FlatIcon'
import { BadgeList } from '../Partial'

// install Swiper components
SwiperCore.use([Controller, Navigation, Pagination, A11y, Lazy, Thumbs])

interface IProps {
    product: IProductDetail
    className?: string
    onImageZoomClick?: ProductImageClickCallback
    allowZoom: boolean
    showBadges: boolean
}

function ProductImages({ product, className, allowZoom, onImageZoomClick, showBadges }: IProps): JSX.Element {
    const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined)
    const [swiperThumb, setSwiperThumb] = useState<SwiperClass | undefined>(undefined)
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
    const [zoomIndex, setZoomIndex] = useState<number>(0)

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(zoomIndex)
        }
    }, [swiper, swiperThumb, zoomIndex])

    useEffect(() => {
        swiper?.update()
        swiper?.slideTo(0, 0)
        swiper?.lazy.load()
        swiperThumb?.update()
        swiperThumb?.slideTo(0, 0)
        swiperThumb?.lazy.load()
    }, [product, swiper, swiperThumb])

    // const handleOnClickImage = (event: MouseEvent) => {
    //     event.preventDefault()
    // }

    const handleOnZoomClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            if (!swiper) {
                return
            }
            const pictureIndex = swiper.realIndex || 0
            setZoomIndex(pictureIndex)
            setLightboxOpen(true)
            if (onImageZoomClick) {
                onImageZoomClick(product, pictureIndex)
            }
        },
        [onImageZoomClick, product, setLightboxOpen, setZoomIndex, swiper]
    )

    const pictures: Array<IProductDetailImage> = product.pictures
    const zooms = pictures.map((value) => value.zoom)
    const placeholders = pictures.map((value) => value.placeholder)
    const hasPictures = product.pictures.length > 0

    return (
        <div
            className={classNames(
                'product-image-section',
                { 'product-image-section-multiple': pictures.length > 1 },
                className
            )}
        >
            <div
                className={classNames('product-image', 'product-image-list', 'product-image-detail', {
                    'product-image-multiple': pictures.length > 1,
                })}
            >
                {showBadges && <BadgeList product={product} />}
                <div className={'product-image-container'}>
                    <div className={'product-image-inner'}>
                        <Swiper
                            thumbs={{ swiper: swiperThumb }}
                            onSwiper={setSwiper}
                            lazy
                            preloadImages={false}
                            watchSlidesProgress
                            slidesPerView={1}
                            spaceBetween={0}
                            navigation={{}}
                        >
                            {pictures.map((picture, idx) => (
                                <SwiperSlide
                                    key={`product_${product.id}_image_${picture.large}_${idx}`}
                                    className={'product-image-slide'}
                                    zoom={false}
                                >
                                    <div className="swiper-zoom-container">
                                        <img
                                            data-src={picture.large}
                                            className={classNames('swiper-lazy', 'img-fluid')}
                                            alt={product.name}
                                            onClick={handleOnZoomClick}
                                        />
                                        <div
                                            className="swiper-zoom-target"
                                            style={{ backgroundImage: `url(${picture.zoom})` }}
                                        />
                                        <div
                                            className={classNames(
                                                'swiper-lazy-preloader',
                                                'swiper-lazy-preloader-black'
                                            )}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {allowZoom && hasPictures && (
                            <a href={'#zoom'} className={'btn-product-image-zoom'} onClick={handleOnZoomClick}>
                                <FlatIcon icon={'search'} />
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <Swiper
                navigation={{}}
                onSwiper={setSwiperThumb}
                spaceBetween={2}
                slidesPerView={3}
                slidesPerGroup={3}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    1300: {
                        slidesPerView: 6,
                        slidesPerGroup: 6,
                    },
                }}
            >
                {pictures.map((picture, idx) => (
                    <SwiperSlide
                        zoom={false}
                        className={classNames('product-image-slide', 'product-image-slide-thumb')}
                        key={`product_${product.id}_image_${picture.thumb}_${idx}`}
                    >
                        <img src={picture.thumb} className={classNames('img-fluid')} alt={product.name} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <CopyrightImage />

            {lightboxOpen && (
                <Lightbox
                    mainSrc={zooms[zoomIndex]}
                    mainSrcThumbnail={placeholders[zoomIndex]}
                    nextSrc={zooms[(zoomIndex + 1) % zooms.length]}
                    nextSrcThumbnail={placeholders[(zoomIndex + 1) % zooms.length]}
                    prevSrc={zooms[(zoomIndex + zooms.length - 1) % zooms.length]}
                    prevSrcThumbnail={placeholders[(zoomIndex + zooms.length - 1) % zooms.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() => setZoomIndex((zoomIndex + zooms.length - 1) % zooms.length)}
                    onMoveNextRequest={() => setZoomIndex((zoomIndex + 1) % zooms.length)}
                    wrapperClassName={'product-image-lightbox'}
                />
            )}
        </div>
    )
}

ProductImages.defaultProps = {
    allowZoom: true,
    showBadges: false,
} as Partial<IProps>

export default memo(ProductImages)
