import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route, Switch, withRouter } from 'react-router-dom'
import ProductModal from '../../containers/Product/Modal'
import CartsPage from '../../pages/CartsPage'
import CmsPage from '../../pages/CmsPage'
import CmsPagePreview from '../../pages/CmsPagePreview'
import ConfirmationPage from '../../pages/ConfirmationPage'
import HomePage from '../../pages/HomePage'
import ProductsCategoryPage from '../../pages/ProductsCategoryPage'
import ProductsPage from '../../pages/ProductsPage'
import { ProductsListMode } from '../../services/api/service/products/types'
import LoggedInRoute from '../LoggedInRoute'
import { getRouteMatch } from '../utils'
import { customerAreaVoter, orderAvailableVoter } from '../voters'
import CustomerArea from './CustomerArea'

import ProductsLoaderPage from '../../pages/ProductsLoaderPage'
import Layout from '../../pages/Layout/Layout'

function Restricted(props: RouteComponentProps): JSX.Element {
    const { location } = props
    // @ts-ignore
    const background = location.state && location.state.background ? location.state.background : undefined
    return (
        <Layout>
            <Switch location={background || location}>
                <LoggedInRoute
                    exact
                    path={getRouteMatch('home')}
                    key={'home'}
                    component={HomePage}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('category')}
                    key={'category'}
                    component={ProductsCategoryPage}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('family')}
                    key={'family'}
                    component={ProductsPage}
                    voter={orderAvailableVoter}
                    componentProps={{
                        mode: ProductsListMode.Default,
                    }}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('favorite')}
                    key={'favorite'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.Favorite,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('freshProducts')}
                    key={'freshProducts'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.New,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('bestSellers')}
                    key={'bestSellers'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.BestSeller,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('myListing')}
                    key={'myListing'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.MyListing,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('arrivalStocks')}
                    key={'arrivalStocks'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.ArrivalStocks,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('discounts')}
                    key={'discounts'}
                    component={ProductsLoaderPage}
                    componentProps={{
                        mode: ProductsListMode.Discount,
                    }}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    exact
                    path={getRouteMatch('confirmation')}
                    key={'confirmation'}
                    component={ConfirmationPage}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    path={getRouteMatch('cart')}
                    key={'cart'}
                    component={CartsPage}
                    voter={orderAvailableVoter}
                />
                <LoggedInRoute
                    path={getRouteMatch('customerArea')}
                    key={'customerArea'}
                    component={CustomerArea}
                    voter={customerAreaVoter}
                />
                <Route path={getRouteMatch('cmsPagePreview')} component={CmsPagePreview} key={'cms_page_preview'} />
                {!background && <Route component={CmsPage} key={'not_found'} />}
            </Switch>
            {background && (
                <Route path={getRouteMatch('product')}>
                    <ProductModal />
                </Route>
            )}
        </Layout>
    )
}

export default withRouter(Restricted)
