import { useEffect, useState } from 'react'
import { Nullable } from 'tsdef'

export const useChromeExtensionHostToken = () => {
    const [token, setToken] = useState<Nullable<string>>(null)

    useEffect(() => {
        if (typeof window.postMessage !== 'function') {
            return
        }

        window.postMessage(
            {
                action: 'U10_CHROME_EXTENSION_GET_TOKEN',
            },
            document.location.toString()
        )

        const onMessageReceived = (event) => {
            if (!event.isTrusted || typeof event.data !== 'object' || typeof event.data.action !== 'string') {
                return
            }

            if (
                event.data.action === 'U10_CHROME_EXTENSION_TOKEN_CHANGED' ||
                event.data.action === 'U10_CHROME_EXTENSION_GET_TOKEN_RESPONSE'
            ) {
                const postedToken = event.data.token || undefined

                setToken(typeof postedToken === 'string' && postedToken.length > 0 ? postedToken : null)
            }
        }
        window.addEventListener('message', onMessageReceived)

        return () => {
            window.removeEventListener('message', onMessageReceived)
        }
    }, [])

    return token
}
