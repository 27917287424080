let config = {}
if (process.env.NODE_ENV === 'development') {
    config = require('./environment/config.development.js').default
} else if (process.env.NODE_ENV === 'production') {
    config = require('./environment/config.production.js').default
} else if (process.env.NODE_ENV === 'test') {
    config = require('./environment/config.test.js').default
} else if (process.env.NODE_ENV === 'staging') {
    config = require('./environment/config.staging.js').default
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const merge = require('deepmerge')

export default merge(require('./global').default, config)
