import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiCartExportCreateResponse,
    IApiCartExportListResponse,
    IApiCartExportResponse,
    ICartExportCreateParameters,
    ICartExportListParameters,
    ICartExportService,
} from './types'

const create = (instance: AxiosInstance): ICartExportService => {
    /*****************************
     * METHODS
     *****************************/
    const create = (parameters: ICartExportCreateParameters): Promise<IApiCartExportCreateResponse> => {
        return instance.post('/cart-exports', parameters)
    }

    const list = (
        params: ICartExportListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiCartExportListResponse> => {
        return instance.get('/cart-exports', { params: params, cancelToken })
    }

    const get = (cartExportId: string, cancelToken?: CancelToken): Promise<IApiCartExportResponse> => {
        return instance.get(`${cartExportId}`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        create,
        list,
        get,
    }
}

export default {
    create,
}
