import { put } from 'redux-saga/effects'
import { updateIntl } from 'react-intl-redux'
import { selectAutoDetectLocale } from './selectors'
import { translationMessages } from '../../i18n'

export function* retrieveSavedLocale() {
    const locale = selectAutoDetectLocale()
    if (locale) {
        yield put(
            updateIntl({
                locale: locale,
                messages: translationMessages[locale],
            })
        )
    }
}
