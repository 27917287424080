import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { findNextProductItemBy, findPreviousProductItemBy } from './utils'
import { IProductList } from '../../services/api/service/products/types'

/**
 * Direct selector to the products state domain
 */

const selectProductsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products || initialState
}

const selectProductGridDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products.grid || initialState.grid
}

const selectProductsListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products.list || initialState.list
}

const selectProductsListSidebarDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products.sidebar || initialState.sidebar
}

const selectProductSearchDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products.search || initialState.search
}

const selectProductListGridDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.products.list.grid || initialState.list.grid
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Products
 */

const makeSelectProductSearchFetching = () =>
    createSelector(selectProductSearchDomain, (substate) => {
        return substate.fetching
    })

const makeSelectProductSearchParams = () =>
    createSelector(selectProductSearchDomain, (substate) => {
        return substate.params
    })

const makeSelectProductSearchError = () =>
    createSelector(selectProductSearchDomain, (substate) => {
        return substate.error
    })

const makeSelectProductSearchItems = () =>
    createSelector(selectProductSearchDomain, (substate) => {
        return substate.items
    })

const makeSelectProductsListFetching = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectProductsListParams = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.params
    })

const makeSelectProductsListParamsPrev = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.paramsPrev
    })

const makeSelectProductsListMode = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.mode
    })

const makeSelectProductsListDisplayMode = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.displayMode
    })

const makeSelectProductsListError = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.error
    })

const makeSelectProductsListItems = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.items
    })

const makeSelectProductsListFilters = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.filters
    })

const makeSelectProductsListCanAddBulk = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.canAddBulk
    })

const makeSelectProductsListTotalItems = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectProductsListOrderValues = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.order.values
    })

const makeSelectProductsListSelection = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.identifiers
    })

const makeSelectProductsListSelectionAction = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.action
    })

const makeSelectProductsListSelectionProcessing = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.process.processing
    })
const makeSelectProductsListSelectionProcessed = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.process.processed
    })
const makeSelectProductsListSelectionSuccess = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.process.success
    })
const makeSelectProductsListSelectionError = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.selection.process.error
    })

const makeSelectProductListNextItem = () =>
    createSelector(
        [makeSelectProductsListItems(), (_: any, currentProductId: string) => currentProductId],
        (items, currentProductId) => {
            if (!items || !currentProductId) {
                return null
            }
            return findNextProductItemBy<IProductList, 'id'>(items, 'id', currentProductId)
        }
    )

const makeSelectProductListPrevItem = () =>
    createSelector(
        [makeSelectProductsListItems(), (_: any, currentProductId: string) => currentProductId],
        (items, currentProductId) => {
            if (!items || !currentProductId) {
                return null
            }
            return findPreviousProductItemBy<IProductList, 'id'>(items, 'id', currentProductId)
        }
    )

const makeSelectProductsListPersistSettings = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.settings
    })

const makeSelectProductsBulkFavoriteRemoveSuccess = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.remove.success || false
    })
const makeSelectProductsBulkFavoriteRemoveError = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.remove.error || undefined
    })
const makeSelectProductsBulkFavoriteRemoveFetching = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.remove.fetching || false
    })
const makeSelectProductsBulkFavoriteRemoveIdentifiers = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.remove.identifiers || []
    })

const makeSelectProductsBulkFavoriteAddSuccess = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.add.success || false
    })
const makeSelectProductsBulkFavoriteAddError = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.add.error || undefined
    })
const makeSelectProductsBulkFavoriteAddFetching = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.add.fetching || false
    })
const makeSelectProductsBulkFavoriteAddIdentifiers = () =>
    createSelector(selectProductsListDomain, (substate) => {
        return substate.bulkFavorites.add.identifiers || []
    })

const makeSelectProductGrids = () =>
    createSelector(selectProductGridDomain, (substate) => {
        return substate.items || []
    })

const makeSelectProductListGridId = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.identifier
    })

const makeSelectProductsListGridRows = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.rows
    })

const makeSelectProductsListGridColumns = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.columns
    })

const makeSelectProductsListGridItems = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.items
    })

const makeSelectProductsListGridErrors = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.errors
    })

const makeSelectProductsListGridSelectedDefinition = () =>
    createSelector(selectProductListGridDomain, (substate) => {
        return substate.selectedDefinition
    })

const makeSelectProductsListSidebarMode = () =>
    createSelector(
        [selectProductsListSidebarDomain, (_: any, displayMode: string) => displayMode],
        (substate, displayMode) => {
            if (!substate || !displayMode || !substate.mode || !substate.mode[displayMode]) {
                return undefined
            }
            return substate.mode[displayMode]
        }
    )

export {
    selectProductsDomain,
    makeSelectProductsListParams,
    makeSelectProductsListParamsPrev,
    makeSelectProductsListItems,
    makeSelectProductsListFetching,
    makeSelectProductsListOrderValues,
    makeSelectProductsListError,
    makeSelectProductListNextItem,
    makeSelectProductListPrevItem,
    makeSelectProductsListTotalItems,
    makeSelectProductSearchFetching,
    makeSelectProductSearchParams,
    makeSelectProductSearchItems,
    makeSelectProductsListCanAddBulk,
    makeSelectProductSearchError,
    makeSelectProductsListPersistSettings,
    makeSelectProductsListMode,
    makeSelectProductsListDisplayMode,
    makeSelectProductsListFilters,
    makeSelectProductsListSelection,
    makeSelectProductsListSelectionAction,
    makeSelectProductsListSelectionProcessing,
    makeSelectProductsListSelectionProcessed,
    makeSelectProductsListSelectionSuccess,
    makeSelectProductsListSelectionError,
    makeSelectProductsBulkFavoriteRemoveFetching,
    makeSelectProductsBulkFavoriteRemoveSuccess,
    makeSelectProductsBulkFavoriteRemoveIdentifiers,
    makeSelectProductsBulkFavoriteRemoveError,
    makeSelectProductsBulkFavoriteAddFetching,
    makeSelectProductsBulkFavoriteAddSuccess,
    makeSelectProductsBulkFavoriteAddIdentifiers,
    makeSelectProductsBulkFavoriteAddError,
    makeSelectProductGrids,
    makeSelectProductListGridId,
    makeSelectProductsListGridRows,
    makeSelectProductsListGridColumns,
    makeSelectProductsListGridItems,
    makeSelectProductsListGridErrors,
    makeSelectProductsListGridSelectedDefinition,
    makeSelectProductsListSidebarMode,
}
