/**
 *
 * ImportType
 *
 */
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ShopImportEntityType } from '../../services/api/service/imports/types'

interface IProps {
    type: ShopImportEntityType
}

function ImportType({ type }: IProps): JSX.Element {
    return (
        <span className={'shop-import-type'}>
            {type === ShopImportEntityType.CartImport && <FormattedMessage id={`shop_import.typed.label.cart`} />}
            {type === ShopImportEntityType.CustomerStockImport && (
                <FormattedMessage id={`shop_import.typed.label.customer_stock`} />
            )}
        </span>
    )
}

ImportType.defaultProps = {} as Partial<IProps>

export default memo(ImportType)
