import React, { memo, useCallback, useMemo } from 'react'
import { createStructuredSelector } from 'reselect'
import { makeSelectCustomer } from '../../store/customers/selectors'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { makeSelectOrdersMode } from '../../store/orders/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import { IOrderMode } from '../../services/api/service/orders/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectSalesmanConfig } from '../../store/config/selectors'
import { ISalesmanConfig } from '../../store/config/types'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useIntl } from 'react-intl'
import { salesmanConfigSetCartAlertDisplay } from '../../store/config/actions'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { hasPendingCarts } from '../../store/customers/utils'
import isObject from 'lodash/isObject'

interface IProps {}

const stateSelector = createStructuredSelector<any, any>({
    config: makeSelectSalesmanConfig(),
    orderMode: makeSelectOrdersMode(),
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
})

function SalesmanCartAlert(_: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const { customer, me, orderMode, config } = useSelector<
        IApplicationRootState,
        {
            config?: ISalesmanConfig
            orderMode: IOrderMode
            customer?: ICustomer
            me?: IMe
        }
    >(stateSelector)

    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    const shippingLocationLabels = useMemo(() => {
        return customer?.alert_cart_in_progress || []
    }, [customer])

    const showAlert = useMemo(() => {
        return (
            isSalesmanResource(me) &&
            orderMode === IOrderMode.Order &&
            isObject(customer) &&
            hasPendingCarts(customer) &&
            !config?.cart_alert_displayed
        )
    }, [me, config, customer, orderMode])

    const handleConfirm = useCallback(() => {
        dispatch(salesmanConfigSetCartAlertDisplay(true))
    }, [dispatch])

    if (!isSalesman) {
        return <></>
    }

    return (
        <SweetAlert
            warning
            title={formatMessage({
                id: 'default.warning',
            })}
            show={showAlert}
            onConfirm={handleConfirm}
        >
            <p>
                {formatMessage({ id: 'default.customer_has_fulfill_carts' }, { count: shippingLocationLabels.length })}
            </p>
            <ul className="list-unstyled">
                {shippingLocationLabels.map((shippingLocationLabel) => (
                    <li key={shippingLocationLabel}>{shippingLocationLabel}</li>
                ))}
            </ul>
        </SweetAlert>
    )
}

SalesmanCartAlert.defaultProps = {} as Partial<IProps>

export default memo(SalesmanCartAlert)
