/**
 *
 * Logo
 *
 */
import React, { memo } from 'react'
import Config from '../../config'
import { Image } from 'react-bootstrap'
import logoWhite from '../../assets/svg/logo-u10-white.svg'
import SVGLogo from '../../assets/svg/logo-u10.svg'

interface IProps {
    white: boolean
}

function Logo({ white }: IProps): JSX.Element {
    return (
        <div className={'logo-container'}>
            <div className={'logo-inner'}>
                <Image src={white ? logoWhite : SVGLogo} alt={Config.APP_NAME} className={'logo'} />
            </div>
        </div>
    )
}

Logo.defaultProps = {
    white: false,
} as Partial<IProps>

export default memo(Logo)
