/**
 *
 * CustomerInfo
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent } from 'react'
import ArrivalInfo from './SalesmanInfo/ArrivalInfo'

interface IProps {
    uniqId?: string
    tooltip?: boolean
    stock: number | null
    currentValue?: number | null
    arrivalStock: number | null
    arrivalStockDate: string | null
    onArrivalStockClick?: (e: MouseEvent) => void
}

function SalesmanInfo({
    arrivalStock = null,
    arrivalStockDate = null,
    tooltip = true,
    stock,
    currentValue,
    onArrivalStockClick,
    uniqId,
}: IProps): JSX.Element {
    return (
        <div className={classNames('product-member-information')}>
            <div className={'content'}>
                <ArrivalInfo
                    uniqId={uniqId}
                    stock={stock}
                    currentValue={currentValue}
                    arrivalStock={arrivalStock}
                    arrivalDate={arrivalStockDate}
                    onClick={onArrivalStockClick}
                    tooltip={tooltip}
                />
            </div>
        </div>
    )
}

export default memo(SalesmanInfo)
