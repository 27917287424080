/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'

interface IProps {
    value?: string
}

function Description({ value }: IProps): JSX.Element {
    return (
        <div className={classNames('product-field-description')}>
            <span className={classNames('value', 'product-description')}>{value}</span>
        </div>
    )
}

Description.defaultProps = {} as Partial<IProps>

export default memo(Description)
