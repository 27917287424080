import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiInvoiceListResponse,
    IApiInvoicePdfResponse,
    IApiInvoiceResponse,
    IInvoiceListParameters,
    IInvoicesService,
} from './types'

const create = (instance: AxiosInstance): IInvoicesService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (params: IInvoiceListParameters): Promise<IApiInvoiceListResponse> => {
        return instance.get('/invoices', { params: params })
    }

    const get = (invoiceId: string, cancelToken?: CancelToken): Promise<IApiInvoiceResponse> => {
        return instance.get(`/invoices/${invoiceId}`, { cancelToken })
    }

    const pdf = (invoiceId: string, cancelToken?: CancelToken): Promise<IApiInvoicePdfResponse> => {
        return instance.get(`/invoices/${invoiceId}/pdf`, { cancelToken })
    }

    /*****************************
     * API RETURNS
     *****************************/
    return {
        list,
        get,
        pdf,
    }
}

export default {
    create,
}
