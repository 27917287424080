import classNames from 'classnames'
import React, { forwardRef, memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, NavLink, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Account from '../../components/Header/Account'
import Cart from '../../components/Header/Cart'
import Favorite from '../../components/Header/Favorite'
import ListedOnly from '../../components/Header/ListedOnly'
import Logo from '../../components/Logo/Logo'
import MainMenu from '../../components/MainMenu/MainMenu'
import { ICartCollection, ICartMode } from '../../services/api/service/carts/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { IProductListPersistParameters } from '../../services/api/service/products/types'
import { IApplicationRootState } from '../../store'
import { authLogoutAction } from '../../store/auth/actions'
import { makeSelectCartMode, makeSelectMainCartsList } from '../../store/carts/selectors'
import { isOrderAvailable } from '../../store/carts/utils'
import { customerLogoutCurrent, customerSetCurrent, customerSwitchStore } from '../../store/customers/actions'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import { productsPersistParamsAction } from '../../store/products/actions'
import { makeSelectProductsListPersistSettings } from '../../store/products/selectors'
import { sideMenuCloseAction, sideMenuToggleAction } from '../../store/sidemenu/actions'
import makeSelectSideMenuOpen from '../../store/sidemenu/selectors'
import { ICustomerMenuItem, ICustomerMenuItemType } from '../../types/customer'
import AssistanceModal from '../Assistance/AssistanceModal'
import CustomerTopBar from '../CustomerTopBar/CustomerTopBar'
import ProductSearch from '../ProductSearch/ProductSearch'
import TopBar from '../TopBar/TopBar'
import SalesmanTopBar from '../SalesmanTopBar/SalesmanTopBar'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { getPath } from '../../routes'
import { StrictCartMode } from '../../store/carts/types'
import { canViewFavorites } from '../../store/customers/utils'
import { makeSelectOrdersMode } from '../../store/orders/selectors'
import { IOrderMode } from '../../services/api/service/orders/types'
import { ordersSwitchOrderModeAction } from '../../store/orders/actions'
import NetworkReachabilityChecker from '../../components/NetworkReachability/Checker'

export interface IProps {
    sticky?: boolean
}

const stateSelector = createStructuredSelector<any, any>({
    sideMenuOpen: makeSelectSideMenuOpen(),
    carts: makeSelectMainCartsList(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    me: makeSelectAuthMe(),
    listSettings: makeSelectProductsListPersistSettings(),
    cartMode: makeSelectCartMode(),
    orderMode: makeSelectOrdersMode(),
})

function Header(): JSX.Element {
    const dispatch = useDispatch()

    const { locale } = useIntl()
    const history = useHistory()
    const [helpShown, setHelpShown] = useState<boolean>(false)
    const [searchOpen, setSearchOpen] = useState<boolean>(false)
    const handleHelpHide = () => setHelpShown(false)

    const { sideMenuOpen, carts, cartMode, customer, store, listSettings, me, orderMode } = useSelector<
        IApplicationRootState,
        {
            sideMenuOpen: boolean
            carts: ICartCollection
            cartMode: StrictCartMode
            customer: ICustomer
            store?: ICustomer
            me?: IMe
            listSettings: IProductListPersistParameters
            orderMode: IOrderMode
        }
    >(stateSelector)

    const salesmanCustomersUrl = useMemo(() => {
        return generatePath(
            getPath(customer && customer.prospect ? 'salesmanProspects' : 'salesmanCustomers', locale),
            { lang: locale }
        )
    }, [locale, customer])

    const handleSideMenuButtonClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            dispatch(sideMenuToggleAction())
            setSearchOpen(false)
        },
        [setSearchOpen, dispatch]
    )

    const handleListedOnlyChange = useCallback(() => {
        const val = !(listSettings.listed_only === 1)
        dispatch(productsPersistParamsAction({ listed_only: val ? 1 : 0 }))
    }, [listSettings, dispatch])

    const handleProductSearchStateChange = useCallback(
        (open: boolean) => {
            dispatch(sideMenuCloseAction())
            setSearchOpen(open)
        },
        [setSearchOpen, dispatch]
    )

    const handleCustomerMenuItemClick = useCallback(
        (e: MouseEvent, item: ICustomerMenuItem) => {
            if (item.type === ICustomerMenuItemType.Logout) {
                e.preventDefault()
                e.stopPropagation()
                dispatch(authLogoutAction(false))
            } else if (item.type === ICustomerMenuItemType.Assistance) {
                e.preventDefault()
                e.stopPropagation()
                setHelpShown(true)
            }
            dispatch(sideMenuCloseAction())
            setSearchOpen(false)
        },
        [setHelpShown, setSearchOpen, dispatch]
    )

    const handleStoreChangeClick = useCallback(
        (customer: ICustomer, store?: ICustomer, storeId?: string) => {
            dispatch(customerSwitchStore(storeId, true, true))
        },
        [dispatch]
    )

    const handleSalesmanCustomerLogout = useCallback(() => {
        dispatch(customerLogoutCurrent(salesmanCustomersUrl))
    }, [dispatch, salesmanCustomersUrl])

    const handleSalesmanOrderModeChange = useCallback(
        (orderMode: IOrderMode) => {
            dispatch(ordersSwitchOrderModeAction(orderMode))
            dispatch(customerSetCurrent(customer.id, true))
        },
        [dispatch, customer]
    )

    const handleSalesmanAreaAsk = useCallback(() => {
        history.push(generatePath(getPath('salesmanArea', locale), { lang: locale }))
    }, [history, locale])

    return (
        <>
            <TopBar />
            <NetworkReachabilityChecker />
            {customer &&
                customer.stores &&
                customer.stores.length > 0 &&
                cartMode === ICartMode.Default &&
                isOrderAvailable(customer, store, cartMode) && (
                    <CustomerTopBar customer={customer} store={store} onStoreChange={handleStoreChangeClick} />
                )}
            {isSalesmanResource(me) && customer && (
                <SalesmanTopBar
                    me={me}
                    orderMode={orderMode}
                    customer={customer}
                    store={store}
                    onLogout={handleSalesmanCustomerLogout}
                    onOrderModeChange={handleSalesmanOrderModeChange}
                    onSalesmanArea={handleSalesmanAreaAsk}
                />
            )}
            <header id={'main-header'}>
                {(!customer || (customer && !isOrderAvailable(customer, store, cartMode))) && (
                    <nav className={'head-bar'}>
                        <div className={'head-bar-top'}>
                            <Container>
                                <Row>
                                    <Col xs={8} />
                                    <Col>
                                        <div className={'head-bar-top-center'}>
                                            <NavLink to={`/${locale}`}>
                                                <Logo />
                                            </NavLink>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <div className={'head-bar-top-last'}>
                                            <div className={'icon-nav'}>
                                                {customer && (
                                                    <Account
                                                        me={me}
                                                        customer={customer}
                                                        store={store}
                                                        onCustomerMenuItemClick={handleCustomerMenuItemClick}
                                                        onStoreChange={handleStoreChangeClick}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </nav>
                )}
                {customer && isOrderAvailable(customer, store, cartMode) && (
                    <nav className={'head-bar'}>
                        <div className={'head-bar-top'}>
                            <Container>
                                <Row>
                                    <Col xs={8}>
                                        <div className={'head-bar-top-first'}>
                                            <button
                                                onClick={handleSideMenuButtonClick}
                                                className={classNames('hamburger', 'hamburger--collapse', {
                                                    'is-active': sideMenuOpen,
                                                })}
                                                type="button"
                                            >
                                                <span className="hamburger-box">
                                                    <span className="hamburger-inner" />
                                                </span>
                                            </button>
                                            <ProductSearch
                                                isOpen={searchOpen}
                                                onProductSearchStateChange={handleProductSearchStateChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={'head-bar-top-center'}>
                                            <NavLink to={`/${locale}`}>
                                                <Logo />
                                            </NavLink>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <div className={'head-bar-top-last'}>
                                            <div className={'icon-nav'}>
                                                {customer.general_price && (
                                                    <ListedOnly
                                                        isSalesman={isSalesmanResource(me)}
                                                        prefixId={'head-bar-top'}
                                                        listedOnly={
                                                            (typeof listSettings !== 'undefined' &&
                                                                listSettings.listed_only === 1) ||
                                                            false
                                                        }
                                                        onChange={handleListedOnlyChange}
                                                    />
                                                )}
                                                <Account
                                                    me={me}
                                                    customer={customer}
                                                    store={store}
                                                    onCustomerMenuItemClick={handleCustomerMenuItemClick}
                                                    onStoreChange={handleStoreChangeClick}
                                                />
                                                <Cart carts={carts} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className={'head-bar-content'}>
                            <NavLink to={`/${locale}`} className={'logo-container'}>
                                <Logo />
                            </NavLink>
                            <MainMenu />
                            <div className={'icon-nav'}>
                                {customer.general_price && (
                                    <ListedOnly
                                        prefixId={'head-bar-content'}
                                        listedOnly={
                                            (typeof listSettings !== 'undefined' && listSettings.listed_only === 1) ||
                                            false
                                        }
                                        onChange={handleListedOnlyChange}
                                    />
                                )}
                                {customer && me && canViewFavorites(customer, me, store) && <Favorite />}
                                <Account
                                    customer={customer}
                                    store={store}
                                    onCustomerMenuItemClick={handleCustomerMenuItemClick}
                                    onStoreChange={handleStoreChangeClick}
                                />
                                <Cart carts={carts} />
                            </div>
                        </div>
                    </nav>
                )}
                <AssistanceModal show={helpShown} onHide={handleHelpHide} onExit={handleHelpHide} />
            </header>
        </>
    )
}

Header.defaultProps = {
    sticky: false,
} as Partial<IProps>
const Component = memo(Header)

// eslint-disable-next-line react/display-name
export default forwardRef<HTMLDivElement, IProps>((props, ref) => (
    // eslint-disable-next-line react/prop-types
    <div className={classNames('top-head-bar', { sticky: props.sticky })} ref={ref}>
        <Component />
    </div>
))
