import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { IOrderDetailState } from './types'

/**
 * Direct selector to the order state domain
 */

const selectOrderDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.order || initialState
}

const selectOrderDetailDomain = (state: IApplicationRootState): IOrderDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.order.detail || initialState.detail
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Order
 */

const makeSelectOrder = () =>
    createSelector(selectOrderDomain, (substate) => {
        return substate
    })

const makeSelectOrderDetailId = () =>
    createSelector(selectOrderDomain, (substate) => {
        return substate.orderId
    })

const makeSelectOrderDetailFetching = () =>
    createSelector(selectOrderDomain, (substate) => {
        return substate.detail.fetching
    })

const makeSelectOrderDetailError = () =>
    createSelector(selectOrderDomain, (substate) => {
        return substate.detail.error
    })

const makeSelectOrderDetail = () =>
    createSelector(selectOrderDetailDomain, (substate) => {
        return substate.detail
    })

export default makeSelectOrder
export {
    selectOrderDomain,
    makeSelectOrderDetailId,
    makeSelectOrderDetail,
    makeSelectOrderDetailError,
    makeSelectOrderDetailFetching,
}
