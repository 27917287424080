/*
 *
 * CustomerOrders
 *
 */

import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Card } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IOrderList, IOrderListParameters, IOrderType, OrderStates } from '../../services/api/service/orders/types'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import {
    makeSelectOrdersListError,
    makeSelectOrdersListFetching,
    makeSelectOrdersListItems,
    makeSelectOrdersListTotalItems,
} from '../../store/orders/selectors'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import { Filters, SortingRule } from 'react-table'
import Table from './Partial/OrderList'
import { ordersListProcessAction } from '../../store/orders/actions'
import { $PropertyType } from 'utility-types'
import { IBasePaginationParameters } from '../../services/api/types'
import Config from '../../config'
import { canViewAllCustomers } from '../../store/salesmens/utils'

const stateSelector = createStructuredSelector<any, any>({
    listFetching: makeSelectOrdersListFetching(),
    listItems: makeSelectOrdersListItems(),
    listTotalItems: makeSelectOrdersListTotalItems(),
    listError: makeSelectOrdersListError(),
    me: makeSelectAuthMe(),
})

type Props = { type: IOrderType }

function SalesmanOrders({ type = IOrderType.Order }: Props): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const { listFetching, listItems, listError, listTotalItems, me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
            listFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IOrderList>
            listError?: IAppErrorTypes | undefined
        }
    >(stateSelector)

    const handleFetchData = useCallback(
        (page: number, pageSize: number, filters: Filters<IOrderList>, sortBy: Array<SortingRule<IOrderList>>) => {
            const orderBy: $PropertyType<IBasePaginationParameters, 'order'> = {}
            sortBy.forEach((sb) => {
                orderBy[sb.id === 'store_code' ? 'login' : sb.id] = sb.desc ? 'desc' : 'asc'
            })

            // mapping entre les colonne du tableau et la réalité
            const mappingKeys = {
                number: 'external_code',
                // customer: 'customer.business_name',
            }

            // if state = processing, envoyer pending, processing
            const parameters: IOrderListParameters = {
                page,
                type,
                itemsPerPage: pageSize,
                order: orderBy,
            }

            filters.forEach((filter) => {
                const filterId = mappingKeys[filter.id] || filter.id
                parameters[filterId] = filter.value

                if (filterId === 'state' && filter.value === OrderStates.Processing) {
                    parameters[filterId] = [OrderStates.Pending, OrderStates.Processing]
                }
            })

            if (!canViewAllCustomers(me)) {
                delete parameters['all_customers']
            }
            dispatch(ordersListProcessAction(parameters))
        },
        [dispatch, type, me]
    )

    const title = useMemo(() => {
        return formatMessage({ id: type === IOrderType.Asset ? 'order_mode.assets' : 'order_mode.orders' })
    }, [formatMessage, type])

    const detailRouteName = useMemo(() => {
        return type === IOrderType.Order ? 'salesmanOrder' : 'salesmanAsset'
    }, [type])

    return (
        <Card className={classNames('member-card')}>
            <Card.Header>
                <h3 className={'hv'}>{title}</h3>
            </Card.Header>
            <Card.Body>
                <div className={'order-list-section'} id={'order-list-section'}>
                    <div className={'order-list-content'}>
                        <Table
                            totalItems={listTotalItems}
                            data={listItems || []}
                            fetchData={handleFetchData}
                            fetching={listFetching}
                            fetchError={listError}
                            pageSize={Config.ORDER_LIST.ITEMS_PER_PAGE}
                            detailRouteName={detailRouteName}
                            type={type}
                            me={me}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default SalesmanOrders
