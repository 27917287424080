import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CustomerOrders from '../containers/CustomerOrders/Loadable'
import { IOrderType } from '../services/api/service/orders/types'

type Props = { type: IOrderType }
export default function CustomerOrdersPage({ type }: Props): JSX.Element {
    const { formatMessage } = useIntl()

    const title = useMemo(() => {
        return formatMessage({ id: type === IOrderType.Asset ? 'order_mode.assets' : 'order_mode.orders' })
    }, [formatMessage, type])

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={'customer-orders-page'} id={'customer-orders-page'}>
                <CustomerOrders type={type} />
            </div>
        </>
    )
}
