/**
 *
 * Gears
 *
 */
import React, { memo } from 'react'

function Gears(): JSX.Element {
    return (
        <div className="gears">
            <div className="gear one">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>
            <div className="gear two">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>
            <div className="gear three">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>
        </div>
    )
}

export default memo(Gears)
