import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ProductListQueryName, ProductsListDisplayMode } from '../../services/api/service/products/types'
import Qs from 'qs'
import rtrim from '../rtrim'
import { $enum } from 'ts-enum-util'

type ReturnValue = {
    switchDisplayMode: (displayMode: ProductsListDisplayMode, gridId?: string) => void
    displayMode: ProductsListDisplayMode
}

export default function useProductsListDisplayMode(): ReturnValue {
    const history = useHistory()
    const { search, pathname } = useLocation()

    const displayMode = useMemo(() => {
        const parsed = Qs.parse(search.substring(1))

        if (
            parsed[ProductListQueryName.DisplayMode] &&
            typeof parsed[ProductListQueryName.DisplayMode] === 'string' &&
            $enum(ProductsListDisplayMode).isValue(parsed[ProductListQueryName.DisplayMode] as string)
        ) {
            return parsed[ProductListQueryName.DisplayMode] as ProductsListDisplayMode
        }

        return ProductsListDisplayMode.Default
    }, [search])

    const switchDisplayMode = useCallback(
        (displayMode: ProductsListDisplayMode, gridId?: string) => {
            const parsed = Qs.parse(search.substring(1))

            // RESET DU MODE
            delete parsed[ProductListQueryName.ProductGrid]
            delete parsed[ProductListQueryName.DisplayMode]

            if (displayMode === ProductsListDisplayMode.GridData) {
                parsed[ProductListQueryName.DisplayMode] = String(displayMode)
                parsed[ProductListQueryName.ProductGrid] = String(gridId)
            }

            // ON RESET LA PAGINATION
            delete parsed[ProductListQueryName.Page]
            delete parsed[ProductListQueryName.ItemsPerPage]

            // ON MET A JOUR L'URL
            const stringified = Qs.stringify(parsed)
            const qstring = stringified && stringified.length > 0 ? `?${stringified}` : ''
            const baseUrl = rtrim(pathname, '/')
            history.push(`${baseUrl}${qstring}`)
        },
        [history, pathname, search]
    )

    return {
        switchDisplayMode,
        displayMode,
    }
}
