import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects'
// Individual exports for testing
import ActionTypes from './constants'
import ProductsActionTypes from './../products/constants'
import { readyAction, reloadedAction, reloadFailAction, reloadingAction } from './actions'
import { splashScreenHideAction, splashScreenShowAction } from '../splashScreen/actions'
import { retrieveSavedLocale } from '../intl/saga'
import { default as LocaleActionTypes } from '../intl/constants'
import { IntlAction } from 'react-intl-redux'
import { IApiClient } from '../../services/api/types'
import Config from '../../config'
import { isLoggedIn } from '../../services/api/service/authenticate/jwt'
import { getPath, routes } from '../../routes'
import { generatePath } from 'react-router'
import { push } from 'connected-react-router'
import { IApiConfigResponse } from '../../services/api/service/config/types'
import { remoteConfigStoreAction, salesmanConfigSetCartAlertDisplay } from '../config/actions'
import { classificationResetAction, classificationStoreAttributeDefinitionAction } from '../classification/actions'
import { selectLocale, selectLocalizedMessages } from '../intl/selectors'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { customerGetSuccessAction, customerSetCurrent, customersResetAction } from '../customers/actions'
import { IAppErrorAction, IAppErrorTypes, IAppReloadingAction } from './types'
import { authLoggedAction, authLogoutAction, authResetAction } from '../auth/actions'
import { cartLoadCartSuccessAction, cartsRefreshAction, cartsResetAction } from '../carts/actions'
import { AxiosError } from 'axios'
import { formatErrorDescription } from '../http/utils'
import IntlMessageFormat, { PrimitiveType } from 'intl-messageformat'
import { IApiErrorDescription } from '../http/types'
import HttpError from './httpError'
import AppError from './error'
import HttpStatusCode, { HttpAuthErrors } from '../http/codes'
import { productsResetAction } from '../products/actions'
import { productResetAction } from '../product/actions'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { addApocalypse } from 'redux-flash-messages/lib'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import datepickerFr from 'date-fns/locale/fr'
import { ordersResetAction } from '../orders/actions'
import { orderResetAction } from '../order/actions'
import { cmsResetAction } from '../cms/actions'
import CustomersActionTypes from '../customers/constants'
import ClassificationActionTypes from '../classification/constants'
import { ICustomerSwitchStoreAction } from '../customers/types'
import { sideMenuResetAction } from '../sidemenu/actions'
import { makeSelectCustomer, makeSelectCustomerStore } from '../customers/selectors'
import { exportsResetAction } from '../exports/actions'
import { shopImportResetAction } from '../imports/actions'
import { invoicesResetAction } from '../invoices/actions'
import { isOrderAvailable } from '../carts/utils'
import { salesmenResetAction } from '../salesmens/actions'
import localforage from 'localforage'
import { ProductsListMode } from '../../services/api/service/products/types'
import { processClassificationFamilyTreeRequest, processClassificationMenu } from '../classification/saga'
import { IApiMeResponse } from '../../services/api/service/me/types'
import { isSalesmanResource } from '../salesmens/utils'
import makeSelectRemoteConfig from '../config/selectors'
import { makeSelectAuthMe } from '../auth/selectors'
import { findCustomerStoreBy, isCustomerResource } from '../customers/utils'
import { processInitializeProductsSettings, processProductGridsRequest } from '../products/saga'
import { IApiAttributeDefinitionResponse } from '../../services/api/service/classification/types'
import { IApiCartListResponse, ICartMode } from '../../services/api/service/carts/types'
import { processRetrieveCustomer, processCustomerSalesmenRequest } from '../customers/saga'
import { matchPath } from 'react-router-dom'
import { processInitializeCartsSettings } from '../carts/saga'
import { processInitializeOrderCustomers, processInitializeOrderModeApi, processSwitchOrderMode } from '../orders/saga'
import { makeSelectCartMode } from '../carts/selectors'
import { localizationCountryListProcessAction, localizationResetAction } from '../localization/actions'
import isBoolean from 'lodash/isBoolean'
import { paymentModesListProcessAction, paymentModesResetAction } from '../payment-modes/actions'

export function* formatIntlMessage(localizedKey: string, localizedParams?: Record<string, PrimitiveType> | undefined) {
    const locale = yield select(selectLocale)
    const localizedMessages: any = yield select(selectLocalizedMessages)
    try {
        return new IntlMessageFormat(localizedMessages[localizedKey], locale).format(localizedParams)
    } catch (e) {
        console.warn(e)
        return localizedKey
    }
}

export function* formatApiError(
    e: AxiosError,
    localizedKey: string,
    localizedParams?: Record<string, PrimitiveType> | undefined
) {
    // récupération du message en fonction du code
    let mesg = yield call(formatIntlMessage, localizedKey, localizedParams)
    let error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
    if (error.realError === 'Network Error') {
        const localizedMessages: any = yield select(selectLocalizedMessages)
        if (localizedMessages[`default.http.network_error`]) {
            mesg = yield call(formatIntlMessage, `default.http.network_error`)
            error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
        }
    } else if (error.status && error.status !== HttpStatusCode.BAD_REQUEST) {
        const localizedMessages: any = yield select(selectLocalizedMessages)
        if (localizedMessages[`default.http.code.${error.status}`]) {
            mesg = yield call(formatIntlMessage, `default.http.code.${error.status}`)
            error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
        }
    } else if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
        const localizedMessages: any = yield select(selectLocalizedMessages)
        if (localizedMessages[`default.account_disconnected`]) {
            mesg = yield call(formatIntlMessage, `default.account_disconnected`)
            error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
        }
    }

    return error
}

export function* formatHttpError(
    e: AxiosError,
    localizedKey: string,
    localizedParams?: Record<string, PrimitiveType> | undefined
) {
    const debug = Config.DEBUG.ACTIVE
    const desc: IApiErrorDescription = yield call(formatApiError, e, localizedKey, localizedParams)
    return new HttpError(debug ? desc.realError! : desc.error!, desc.status!, undefined, desc.errors, e)
}

export function* formatAppError(
    e: IAppErrorTypes,
    localizedKey: string,
    localizedParams?: Record<string, PrimitiveType> | undefined
) {
    if (e.isAxiosError) {
        return yield call(formatHttpError, e as AxiosError, localizedKey, localizedParams)
    }

    // TODO: Lorsque le refresh ne fonctionne pas, on ne passe pas dans isAxiosError dûe à la lib. On reteste donc ici si on a une http error
    let mesg = yield call(formatIntlMessage, localizedKey, localizedParams)
    let error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
    if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
        mesg = yield call(formatIntlMessage, `default.account_disconnected`)
        error = formatErrorDescription(e, mesg, Config.DEBUG.ACTIVE)
    }

    return new AppError(
        Config.DEBUG.ACTIVE ? (error.realError ? error.realError! : error.error!) : error.error!,
        error.status || 0,
        undefined,
        e
    )
}

export function* appReset() {
    yield put(cartsResetAction())
    yield put(paymentModesResetAction())
    yield put(authResetAction())
    yield put(productsResetAction())
    yield put(productResetAction())
    yield put(classificationResetAction())
    yield put(customersResetAction())
    yield put(ordersResetAction())
    yield put(orderResetAction())
    yield put(invoicesResetAction())
    yield put(cmsResetAction())
    yield put(sideMenuResetAction())
    yield put(exportsResetAction())
    yield put(shopImportResetAction())
    yield put(salesmenResetAction())
    yield put(localizationResetAction())
}

export function* processInitializeSalesmanContext() {
    const customer = yield select(makeSelectCustomer())

    if (!customer) {
        return
    }

    // on intialise le order mode
    yield call(processSwitchOrderMode)

    // on récupère les grids !
    yield call(processProductGridsRequest)
}

export function* processInitializeCustomerContext() {
    const api: IApiClient = yield getContext('api')
    const customer = yield select(makeSelectCustomer())
    let cartMode = yield select(makeSelectCartMode())

    // TODO: refactor this ! Move in another section
    if (customer.cart_mode === ICartMode.Grouped && cartMode === ICartMode.Default) {
        cartMode = customer.cart_mode
    }

    // on récupère les informations nécessaires !
    try {
        let storeId: string | undefined = undefined

        try {
            const storage: typeof localforage = yield getContext('storage')
            storeId = yield call({ context: storage, fn: 'getItem' }, Config.AUTH.STORE_ID_STORAGE_NAME)
            if (customer.account_type !== ICustomerType.MultiStore || cartMode === ICartMode.Grouped) {
                // console.log('auth/saga::processInitializeCustomerContext => remove store id')
                yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.STORE_ID_STORAGE_NAME)
                storeId = undefined
            }
            // on set l'identifiant
            if (storeId) {
                yield call({ context: api, fn: 'setCustomerId' }, storeId)
            }
        } catch (e) {
            if (Config.SENTRY.ACTIVE) {
                Sentry.captureException(e)
            }
        }

        // on initialize les settings de la liste des produits et on récupère les settings
        yield call(processInitializeProductsSettings)

        // on initialize les settings du panier
        yield call(processInitializeCartsSettings)

        // on stocke le client dans les order customers
        yield call(processInitializeOrderCustomers)

        // on récupère les commerciaux
        yield call(processCustomerSalesmenRequest)

        const store = storeId ? findCustomerStoreBy(customer, 'id', storeId) : undefined
        if (!isOrderAvailable(customer, store, cartMode)) {
            return
        }

        // correct, effects will get executed in parallel
        const [resp4, resp5]: Array<IApiAttributeDefinitionResponse | IApiCartListResponse> = yield all([
            call({ context: api.classification, fn: 'attributes' }),
            call({ context: api.carts, fn: 'list' }),
        ])

        // stockage de toutes les informations
        yield put(
            classificationStoreAttributeDefinitionAction(
                (resp4 as IApiAttributeDefinitionResponse).data['hydra:member']
            )
        )

        const carts = (resp5 as IApiCartListResponse).data['hydra:member']

        for (const idx in carts) {
            yield put(cartLoadCartSuccessAction(carts[idx]['@id'], carts[idx], true))
        }
        yield put(cartsRefreshAction(carts, true))
    } catch (e) {
        const error = yield call(formatAppError, e, 'app.bootstrap.error')
        console.error(error)
    }
}

export function* appReloading(action: IAppReloadingAction) {
    yield put(splashScreenShowAction(true))

    // get api & storage
    const api: IApiClient = yield getContext('api')
    const locale = yield select(selectLocale)
    const localeUpdated = action.payload.localeUpdated || false
    const redirectUrl = action.payload.redirectUrl
    const config = yield select(makeSelectRemoteConfig())
    let me = yield select(makeSelectAuthMe())

    try {
        // récupération de la configuration
        if (!config || localeUpdated) {
            const response: IApiConfigResponse = yield call({ context: api.config, fn: 'get' })
            yield put(remoteConfigStoreAction(response.data))
        }

        // on est pas connecté ? on s'arrête ici !
        if (!isLoggedIn()) {
            yield put(reloadedAction())
            yield put(authLogoutAction(true))
            yield put(readyAction())
            return
        }

        // reset application
        yield call(appReset)

        // récupération me si nécessaire
        if (!me) {
            const response: IApiMeResponse = yield call({ context: api.me, fn: 'me' })
            me = response.data
        }

        // on ajoute quelques infos pour log rocket et sentry
        const loggedUserName = isSalesmanResource(me) ? me.name : me.business_name
        const loggedUserCountry = isSalesmanResource(me) ? 'NC' : me.country_name || 'NC'

        // Log Rocket
        if (Config.LOG_ROCKET.ACTIVE) {
            LogRocket.identify({
                locale: locale,
                name: loggedUserName,
                country_name: loggedUserCountry,
            })
        }

        if (Config.SENTRY.ACTIVE) {
            Sentry.setUser({
                username: loggedUserName,
                locale: locale,
                country_name: loggedUserCountry,
            })
        }

        // on est connecté !
        yield put(authLoggedAction(me))

        // est-ce que c'est un client ?
        if (isCustomerResource(me)) {
            // on set le customer
            yield put(customerSetCurrent(me['@id'], false))

            // on récupère le client
            yield call(processRetrieveCustomer, me['@id'])

            // récupération du store id !
            let storeId: string | undefined = undefined

            try {
                const storage: typeof localforage = yield getContext('storage')
                storeId = yield call({ context: storage, fn: 'getItem' }, Config.AUTH.STORE_ID_STORAGE_NAME)
            } catch (e) {
                console.error(e)
                if (Config.SENTRY.ACTIVE) {
                    Sentry.captureException(e)
                }
            }

            // on part du principe qu'on a le meme objet
            yield put(customerGetSuccessAction(me, storeId))

            // on charge les informations du catalogue
            yield call(processInitializeCustomerContext)
        }

        // est ce que c'est le commercial ?
        let salesmanCustomerId: string | undefined = undefined
        if (isSalesmanResource(me)) {
            const storage: typeof localforage = yield getContext('storage')

            // on regarde s'il avait déjà eu la popup d'alerte affichée
            try {
                const cartDisplayed = yield call(
                    { context: storage, fn: 'getItem' },
                    Config.SALESMAN.CART_ALERT_STORAGE_KEY
                )

                if (isBoolean(cartDisplayed)) {
                    yield put(salesmanConfigSetCartAlertDisplay(cartDisplayed))
                }
            } catch (e) {}

            // on récupère l'identifiant du client sélectionné si dispo
            try {
                // récupération du customer id si sélectionné
                salesmanCustomerId = yield call(
                    { context: storage, fn: 'getItem' },
                    Config.AUTH.CUSTOMER_ID_STORAGE_NAME
                )

                // initialisation du mode
                yield call(processInitializeOrderModeApi)

                if (salesmanCustomerId) {
                    // on stocke l'identifiant client et le customer s'il a déjà été chargé au préalable
                    yield put(customerSetCurrent(salesmanCustomerId, false))

                    // on reload le client
                    yield call(processRetrieveCustomer, salesmanCustomerId)

                    // si le client, on initialise l'espace commercial et SEULEMENT ensuite l'espace client (mode devis / commande dans l'espace commercial)
                    yield call(processInitializeSalesmanContext)

                    // on charge les informations du catalogue
                    yield call(processInitializeCustomerContext)
                }
            } catch (e) {
                // stockage erreur !
                if (Config.SENTRY.ACTIVE) {
                    Sentry.captureException(e)
                }
            }
        }

        // récupération des countries
        yield put(localizationCountryListProcessAction())

        // récupération des moyens de paiement
        if (me.internal) {
            yield put(paymentModesListProcessAction())
        }

        // lang check
        const localeMatch = matchPath<{ lang?: string }>(window.location.pathname, {
            path: routes.home.match,
        })

        if (localeMatch && localeMatch.params.lang !== locale) {
            yield put(push(generatePath(getPath('home', locale), { lang: locale })))
        }

        // est ce que c'est un commercial et il n'a pas de customer ?
        // on change d'url si besoin
        const loginMatch = matchPath<{ lang?: string }>(window.location.pathname, {
            path: routes.login.match,
        })

        if (redirectUrl) {
            // redirectUrl
            yield put(push(redirectUrl))
        } else if (isSalesmanResource(me)) {
            const salesmanCustomersMatch = matchPath<{ lang?: string }>(window.location.pathname, {
                path: routes.salesmanCustomers.match,
            })

            if (salesmanCustomersMatch) {
                // pas de client
                if (salesmanCustomerId) {
                    yield put(push(generatePath(getPath('home', locale), { lang: locale })))
                }
            } else if (loginMatch) {
                // pas de client
                if (!salesmanCustomerId) {
                    yield put(push(generatePath(getPath('salesmanArea', locale), { lang: locale })))
                } else {
                    yield put(push(generatePath(getPath('home', locale), { lang: locale })))
                }
            }
        } else if (isCustomerResource(me)) {
            if (loginMatch) {
                // commercial ?
                yield put(push(generatePath(getPath('home', locale), { lang: locale })))
            }
        }

        // l'application est prête !
        yield put(reloadedAction())
        yield put(readyAction())

        return
    } catch (e) {
        const error = yield call(formatAppError, e, 'app.bootstrap.error')
        if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
            yield put(authLogoutAction(true, error))
            yield put(reloadedAction())
            yield put(readyAction())
        } else {
            yield put(reloadFailAction(error))
        }
        yield put(splashScreenHideAction(true))
    }
}

export function* appReloaded() {
    yield put(splashScreenHideAction(true))
}

export function* appBoostrap() {
    // affichage du splash
    yield put(splashScreenShowAction(false))
    // on récupère la bonne locale et on la paramètre
    // celle-ci appUpdateLocale est appelée ci-dessous dû au changement de locale
    yield call(retrieveSavedLocale)
}

export function* appUpdateLocale(action: IntlAction) {
    // récupération de la locale
    const locale = action.payload?.locale || Config.I18N.DEFAULT_LOCALE

    // mise à jour api
    const api: IApiClient = yield getContext('api')
    api.setLocale(locale)

    // register locale and set default locale for datepicker
    registerLocale('fr', datepickerFr)
    setDefaultLocale(locale)

    // on recharge l'application dans la bonne locale
    yield put(reloadingAction(true))
}

export function* appReady() {
    yield put(splashScreenHideAction(false))
}

export function* appError(action: IAppErrorAction) {
    const { error, origin } = action.payload
    yield call(addApocalypse, { text: error.message, data: { error, origin } })
}

export function* processReloadDefaultClassification() {
    const customer: ICustomer = yield select(makeSelectCustomer())
    const store: ICustomer | undefined = yield select(makeSelectCustomerStore())
    const cartMode = yield select(makeSelectCartMode())

    if (!isOrderAvailable(customer, store, cartMode)) {
        return
    }

    // on appel d'abord la main family
    yield call(processClassificationFamilyTreeRequest, {
        type: ClassificationActionTypes.FAMILY_TREE_PROCESS_ACTION,
        payload: {
            mode: ProductsListMode.Default,
        },
    })

    // main menu
    yield call(processClassificationMenu)
}

export function* processSwitchStoreRequest(action: ICustomerSwitchStoreAction) {
    const { storeId, reloadApp, redirect } = action.payload
    // mise à jour api
    const api: IApiClient = yield getContext('api')
    const locale = yield select(selectLocale)

    yield call({ context: api, fn: 'setCustomerId' }, storeId)

    // mise à jour du local storage
    try {
        const storage: typeof localforage = yield getContext('storage')
        // console.log('app/saga::processSwitchStoreRequest', storeId)

        if (storeId) {
            yield call({ context: storage, fn: 'setItem' }, Config.AUTH.STORE_ID_STORAGE_NAME, storeId)
        } else {
            // console.log('app/saga::processSwitchStoreRequest => remove store id')
            yield call({ context: storage, fn: 'removeItem' }, Config.AUTH.STORE_ID_STORAGE_NAME)
        }
    } catch (e) {
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }

    // on charge quelques petites choses en plus
    if (reloadApp) {
        const redirectUrl = redirect ? generatePath(getPath('home', locale), { lang: locale }) : undefined
        yield put(reloadingAction(false, redirectUrl))
    }
}

export default [
    takeLatest(ActionTypes.APP_BOOTSTRAP_ACTION, appBoostrap),
    takeLatest(ActionTypes.APP_RELOADING_ACTION, appReloading),
    takeLatest(ActionTypes.APP_RELOADED_ACTION, appReloaded),
    takeLatest(ActionTypes.APP_READY_ACTION, appReady),
    takeLatest(ActionTypes.APP_ERROR_ACTION, appError),
    takeLatest(LocaleActionTypes.UPDATE_LOCALE, appUpdateLocale),
    takeLatest(CustomersActionTypes.SWITCH_STORE_ACTION, processSwitchStoreRequest),
    takeLatest(ProductsActionTypes.PERSISTED_PARAMS_ACTION, processReloadDefaultClassification),
]
