/*
 *
 * Cms reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, ICmsBlockState, ICmsPageState } from './types'

export const initialPagesState: Array<ICmsPageState> = []
export const initialBlockState: Array<ICmsBlockState> = []

export const initialState: ContainerState = {
    pages: initialPagesState,
    blocks: initialBlockState,
}

function cmsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.RESET_ACTION: {
            return initialState
        }
        case ActionTypes.BLOCK_PROCESS_ACTION: {
            // récupération de l'index si existant
            const idx = state.blocks.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )

            let blocks = [...state.blocks]
            if (idx === -1) {
                blocks.push({
                    identifier: {
                        type: action.payload.identifierType,
                        value: action.payload.identifier,
                    },
                    tms: undefined,
                    fetching: true,
                    error: undefined,
                    block: undefined,
                })
            } else {
                blocks = state.blocks
                    .slice(0, idx)
                    .concat([
                        {
                            identifier: {
                                type: action.payload.identifierType,
                                value: action.payload.identifier,
                            },
                            tms: undefined,
                            fetching: true,
                            error: undefined,
                            block: undefined,
                        },
                    ])
                    .concat(state.blocks.slice(idx + 1))
            }
            return {
                ...state,
                blocks,
            }
        }
        case ActionTypes.BLOCK_FAILURE_ACTION: {
            // récupération de l'index si existant
            const idx = state.blocks.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            const item = {
                ...state.blocks[idx],
                tms: Date.now(),
                fetching: false,
                error: action.payload.error,
                block: undefined,
            }
            const blocks = state.blocks
                .slice(0, idx)
                .concat([item])
                .concat(state.blocks.slice(idx + 1))
            return {
                ...state,
                blocks,
            }
        }
        case ActionTypes.BLOCK_SUCCESS_ACTION: {
            // récupération de l'index si existant
            const idx = state.blocks.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            const item = {
                ...state.blocks[idx],
                tms: Date.now(),
                fetching: false,
                block: action.payload.response.data,
            }
            const blocks = state.blocks
                .slice(0, idx)
                .concat([item])
                .concat(state.blocks.slice(idx + 1))
            return {
                ...state,
                blocks,
            }
        }
        case ActionTypes.BLOCK_RESET_ACTION: {
            // récupération de l'index si existant
            const idx = state.blocks.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            if (idx === -1) {
                return state
            }
            const blocks = [...state.blocks]
            delete blocks[idx]
            return {
                ...state,
                blocks,
            }
        }
        case ActionTypes.PAGE_PROCESS_ACTION: {
            // récupération de l'index si existant
            const idx = state.pages.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )

            let pages = [...state.pages]
            if (idx === -1) {
                pages.push({
                    identifier: {
                        type: action.payload.identifierType,
                        value: action.payload.identifier,
                    },
                    tms: undefined,
                    fetching: true,
                    error: undefined,
                    content: undefined,
                })
            } else {
                pages = state.pages
                    .slice(0, idx)
                    .concat([
                        {
                            identifier: {
                                type: action.payload.identifierType,
                                value: action.payload.identifier,
                            },
                            tms: undefined,
                            fetching: true,
                            error: undefined,
                            content: undefined,
                        },
                    ])
                    .concat(state.pages.slice(idx + 1))
            }

            return {
                ...state,
                pages,
            }
        }
        case ActionTypes.PAGE_FAILURE_ACTION: {
            // récupération de l'index si existant
            const idx = state.pages.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            const item = {
                ...state.pages[idx],
                tms: Date.now(),
                fetching: false,
                error: action.payload.error,
                content: undefined,
            }
            const pages = state.pages
                .slice(0, idx)
                .concat([item])
                .concat(state.pages.slice(idx + 1))
            return {
                ...state,
                pages,
            }
        }
        case ActionTypes.PAGE_SUCCESS_ACTION: {
            // récupération de l'index si existant
            const idx = state.pages.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            const item = {
                ...state.pages[idx],
                tms: Date.now(),
                fetching: false,
                content: action.payload.response.data,
            }
            const pages = state.pages
                .slice(0, idx)
                .concat([item])
                .concat(state.pages.slice(idx + 1))
            return {
                ...state,
                pages,
            }
        }
        case ActionTypes.PAGE_RESET_ACTION: {
            // récupération de l'index si existant
            const idx = state.pages.findIndex(
                (obj) =>
                    obj.identifier?.type === action.payload.identifierType &&
                    obj.identifier?.value === action.payload.identifier
            )
            if (idx === -1) {
                return state
            }
            const pages = [...state.pages]
            delete pages[idx]
            return {
                ...state,
                pages,
            }
        }
        default:
            return state
    }
}

export default cmsReducer
