/**
 *
 * CustomerLayout
 *
 */
import React, { MouseEvent, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { default as SalesmanMenu } from '../../components/Salesman/Menu/List'
import Config from '../../config'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { authLogoutAction } from '../../store/auth/actions'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import { IApplicationRootState } from '../../store'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { ISalesmanMenuItem, ISalesmanMenuItemType } from '../../types/salesman'
import classNames from 'classnames'
import FlatIcon from '../../components/Icon/FlatIcon'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { isMultiStoreHasStoreOrderAvailable, isOrderAvailable } from '../../store/carts/utils'
import { StrictCartMode } from '../../store/carts/types'
import { makeSelectCartMode } from '../../store/carts/selectors'
import isUndefined from 'lodash/isUndefined'
import Button from '../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { customerLogoutCurrent } from '../../store/customers/actions'
import { generatePath, useHistory } from 'react-router-dom'
import { getPath } from '../../routes'

interface IProps {
    children?: ReactNode
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
})

// permet de faire du portal dans les pages enfants
export const SalesmanLayoutRowTopId = 'member-area-row-top'

function SalesmanLayout({ children }: IProps): JSX.Element {
    const { me, customer, store, cartMode } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
            store?: ICustomer
            customer?: ICustomer
            cartMode: StrictCartMode
        }
    >(stateSelector)
    const { locale, formatMessage } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()

    const handleOnItemClick = useCallback(
        (e: MouseEvent, item: ISalesmanMenuItem) => {
            if (item.type === ISalesmanMenuItemType.Logout) {
                e.preventDefault()
                e.stopPropagation()
                dispatch(authLogoutAction(false))
            }
        },
        [dispatch]
    )

    const customerAreaLabel = formatMessage({ id: 'default.my_personal_place' })

    const orderNotAvailableMessageId = useMemo(() => {
        if (!customer || isOrderAvailable(customer, store, cartMode)) {
            return null
        }
        if (
            customer.account_type === ICustomerType.MultiStore &&
            !store &&
            isMultiStoreHasStoreOrderAvailable(customer)
        ) {
            return null
        }

        return 'salesman.customer_order_not_available'
    }, [cartMode, customer, store])

    useEffect(() => {
        document.documentElement.classList.add('salesman-layout')

        if (customer) {
            document.documentElement.classList.add('selected-customer')
        } else {
            document.documentElement.classList.remove('selected-customer')
        }

        return () => {
            document.documentElement.classList.remove('salesman-layout')
            document.documentElement.classList.remove('selected-customer')
        }
    }, [customer])

    const handleLogout = useCallback(() => {
        dispatch(authLogoutAction(false))
    }, [dispatch])

    const salesmanCustomersUrl = useMemo(() => {
        return generatePath(
            getPath(customer && customer.prospect ? 'salesmanProspects' : 'salesmanCustomers', locale),
            { lang: locale }
        )
    }, [locale, customer])

    const handleSwitchAccount = useCallback(() => {
        dispatch(customerLogoutCurrent(salesmanCustomersUrl))
    }, [dispatch, salesmanCustomersUrl])

    const handleBackToCatalog = useCallback(() => {
        history.push('/')
    }, [history])

    return (
        <>
            <Helmet
                titleTemplate={`%s - ${customerAreaLabel} - ${Config.APP_NAME}`}
                defaultTitle={`${customerAreaLabel} - ${Config.APP_NAME}`}
            >
                <html lang={locale} />
            </Helmet>
            <Container className={'member-area'}>
                {orderNotAvailableMessageId && (
                    <Row>
                        <Col>
                            <Alert className="salesman-alert" variant={'info'}>
                                <Alert.Heading>
                                    <FormattedMessage id="default.warning" />
                                </Alert.Heading>
                                <p className="mb-0">
                                    <FormattedMessage id={orderNotAvailableMessageId} />
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                )}
                {!isUndefined(customer) && (
                    <Row>
                        <Col>
                            <div className="callout callout-warning callout-salesman">
                                <Alert.Heading>
                                    <FormattedMessage id="default.warning" />
                                </Alert.Heading>
                                <p className="mb-0">
                                    <FormattedMessage id="salesman.denied_area_access_customer" />
                                </p>
                                <div className="actions">
                                    <Button variant="outline-dark" onClick={handleLogout}>
                                        <FlatIcon icon="logout" />
                                        <span className="lbl">
                                            <FormattedMessage id="default.logout" defaultMessage="Déconnexion" />
                                        </span>
                                    </Button>
                                    <Button variant="outline-primary" onClick={handleSwitchAccount}>
                                        <FontAwesomeIcon icon={['fad', 'random']} className={'app-icon'} />
                                        <span className="lbl">
                                            <FormattedMessage id={'default.switch_account'} />
                                        </span>
                                    </Button>
                                    <Button variant="primary" onClick={handleBackToCatalog}>
                                        <FormattedMessage id={'salesman.continue_with_account'} />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {isUndefined(customer) && (
                    <Row>
                        <Col md={'auto'} className={'member-area-sidebar'}>
                            <SalesmanMenu onItemClick={handleOnItemClick} className={'member-area-menu'} me={me} />
                        </Col>
                        <Col>
                            <Row id={SalesmanLayoutRowTopId}>
                                <Col>
                                    <div className={classNames('account', 'account-banner')}>
                                        <span className={'customer-avatar'}>
                                            <FlatIcon icon={'user'} />
                                        </span>
                                        <span className={'customer-business-name'}>
                                            <FormattedMessage id={'customer.hello'}>
                                                {(txt) => (
                                                    <span className={'value'}>
                                                        {txt}{' '}
                                                        <span className={'business-name'}>
                                                            {isSalesmanResource(me) ? me.name : me?.business_name || ''}
                                                        </span>
                                                    </span>
                                                )}
                                            </FormattedMessage>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {children}
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    )
}

export default SalesmanLayout
