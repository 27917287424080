import { ShopImportListType, ShopImportType } from '../../services/api/service/imports/types'
import rtrim from '../../utils/rtrim'
import { ICustomer, ICustomerType } from '../../services/api/service/customers/types'
import { isMultiStoreHasStoreOrderAvailable, isOrderAvailable } from '../carts/utils'
import { StrictCartMode } from '../carts/types'

export function retrieveShopImportListType(shopImportType?: ShopImportType): ShopImportListType {
    if (!shopImportType) {
        return ShopImportListType.Global
    } else if (shopImportType === ShopImportType.Cart) {
        return ShopImportListType.Cart
    } else if (shopImportType === ShopImportType.CustomerStock) {
        return ShopImportListType.CustomerStock
    }
    return ShopImportListType.Global
}

export function removeShopImportTypePathToPathName(pathName: string): string {
    // eslint-disable-next-line no-useless-escape
    return rtrim(pathName.replace(/(\/imports\/)([\\_a-z0-9A-Z]+)/, '$1'))
}

export function canUseMatrix(customer: ICustomer, store?: ICustomer, cartMode?: StrictCartMode): boolean {
    return (
        isOrderAvailable(customer, store, cartMode) ||
        (customer.account_type === ICustomerType.MultiStore && isMultiStoreHasStoreOrderAvailable(customer))
    )
}
