import React from 'react'
import DefaultStatistics from '../MemberStatistics/Statistics'
import { MemberStatisticsContext } from '../../store/statistics/types'
import YearlyStatistics from '../MemberStatistics/Partial/Yearly/Statistics'
import { createStructuredSelector } from 'reselect'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import { IMe } from '../../services/api/service/me/types'
import { canViewStats } from '../../store/salesmens/utils'
import { FormattedMessage } from 'react-intl'
import { Alert } from 'react-bootstrap'

interface IProps {}

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

function SalesmanDashboard(): JSX.Element {
    const { me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
        }
    >(stateSelector)

    if (!canViewStats(me)) {
        return (
            <Alert variant={'warning'}>
                <FormattedMessage id={'salesman.statistics.no_access'} />
            </Alert>
        )
    }

    return (
        <div className="member-dashboard">
            <YearlyStatistics context={MemberStatisticsContext.Salesman} />
            <DefaultStatistics context={MemberStatisticsContext.Salesman} />
        </div>
    )
}

SalesmanDashboard.defaultProps = {} as Partial<IProps>

export default SalesmanDashboard
