import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the orders state domain
 */

const selectExportsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.exports || initialState
}

const selectExportsListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.exports.list || initialState.list
}

const selectExportsInfoDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.exports.exports || initialState.exports
}
const selectExportsProcessDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.exports.process || initialState.process
}

const makeSelectExportsListFetching = () =>
    createSelector(selectExportsListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectExportsListError = () =>
    createSelector(selectExportsListDomain, (substate) => {
        return substate.error
    })

const makeSelectExportsListItems = () =>
    createSelector(selectExportsListDomain, (substate) => {
        return substate.items
    })

const makeSelectExportsListTotalItems = () =>
    createSelector(selectExportsListDomain, (substate) => {
        return substate.totalItems
    })

const makeSelectExportCreateState = () =>
    createSelector(
        [
            selectExportsInfoDomain,
            // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
            (_: any, resourceId: string, resourceType: string) => resourceId,
            (_: any, resourceId: string, resourceType: string) => resourceType,
        ],
        (state, resourceId, resourceType) => {
            const create = state.create
            return create && create[resourceType] && create[resourceType][resourceId]
                ? create[resourceType][resourceId]
                : undefined
        }
    )

const makeSelectExportProcessState = () =>
    createSelector([selectExportsProcessDomain, (_: any, itemId?: string) => itemId], (state, itemId) => {
        if (!itemId) {
            return undefined
        }
        return state[itemId]
    })

/**
 * Other specific selectors
 */

/**
 * Default selector used by Orders
 */

const makeSelectExports = () =>
    createSelector(selectExportsDomain, (substate) => {
        return substate
    })

export default makeSelectExports
export {
    selectExportsDomain,
    makeSelectExportsListError,
    makeSelectExportsListItems,
    makeSelectExportsListTotalItems,
    makeSelectExportsListFetching,
    makeSelectExportCreateState,
    makeSelectExportProcessState,
}
