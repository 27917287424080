/**
 *
 * CartMinimumAmountAlert
 *
 */
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Alert } from 'react-bootstrap'
import { useCartInfo } from '../../containers/Carts/useCartInfo'
import { ICart, ICartLight, ICartMode, ICartValidationMode } from '../../services/api/service/carts/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import FlatIcon from '../Icon/FlatIcon'
import { StrictCartMode } from '../../store/carts/types'

interface IProps {
    cart: ICartLight | ICart
    cartMode?: StrictCartMode
    cartValidationMode?: ICartValidationMode
    customer: ICustomer
    store?: ICustomer
    minimumAmountMode: ICustomerMinimumAmountMode
    abbreviate: boolean
    reachedIcon?: string
    unreachedIcon?: string
    onClick?: () => void
}

function CartMinimumAmountAlert({
    cart,
    customer,
    store,
    minimumAmountMode,
    abbreviate = false,
    onClick,
    reachedIcon = 'box',
    unreachedIcon = 'box',
    cartMode = ICartMode.Default,
    cartValidationMode = ICartValidationMode.Default,
}: IProps): JSX.Element {
    const {
        message: alertMessage,
        level: alertLevel,
        active,
        reached,
    } = useCartInfo({
        cart,
        customer,
        store,
        minimumAmountMode,
        cartMode,
        cartValidationMode,
        abbreviateMessage: abbreviate,
    })

    const icon = useMemo(() => {
        return reached ? reachedIcon : unreachedIcon
    }, [reachedIcon, unreachedIcon, reached])

    if (!active) {
        return <></>
    }

    return (
        <Alert
            variant={alertLevel}
            className={classNames('minimum-amount-alert', { 'has-callback': typeof onClick !== 'undefined' })}
            onClick={onClick}
        >
            <FlatIcon icon={icon} />
            <span className={'message'}>{alertMessage}</span>
        </Alert>
    )
}

export default CartMinimumAmountAlert
