/*
 *
 * Invoices actions
 *
 */

import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import {
    IApiInvoiceListPagination,
    IApiInvoicePdfResponse,
    IInvoiceListParameters,
} from '../../services/api/service/invoices/types'
import { IAppErrorTypes } from '../app/types'
import { AxiosError } from 'axios'
import {
    IInvoicesDocumentFailurePayload,
    IInvoicesDocumentProcessPayload,
    IInvoicesDocumentResetSinglePayload,
    IInvoicesDocumentSuccessPayload,
    IInvoicesListProcessPayload,
} from './types'

export const invoicesListProcessAction = (params: IInvoiceListParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IInvoicesListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })
export const invoicesListSuccessAction = (response: IApiInvoiceListPagination) => {
    return action<ActionTypes.LIST_SUCCESS_ACTION, IApiInvoiceListPagination>(ActionTypes.LIST_SUCCESS_ACTION, response)
}
export const invoicesListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })

export const invoicesDocumentProcessAction = (invoiceId: string) =>
    action<ActionTypes.DOCUMENT_PROCESS_ACTION, IInvoicesDocumentProcessPayload>(ActionTypes.DOCUMENT_PROCESS_ACTION, {
        invoiceId,
    })
export const invoicesDocumentSuccessAction = (invoiceId: string, response: IApiInvoicePdfResponse) =>
    action<ActionTypes.DOCUMENT_SUCCESS_ACTION, IInvoicesDocumentSuccessPayload>(ActionTypes.DOCUMENT_SUCCESS_ACTION, {
        invoiceId,
        response,
    })
export const invoicesDocumentFailureAction = (invoiceId: string, error: AxiosError) =>
    action<ActionTypes.DOCUMENT_FAILURE_ACTION, IInvoicesDocumentFailurePayload>(ActionTypes.DOCUMENT_FAILURE_ACTION, {
        invoiceId,
        error,
    })
export const invoicesDocumentResetAction = () =>
    action<ActionTypes.DOCUMENT_RESET_ACTION>(ActionTypes.DOCUMENT_RESET_ACTION)

export const invoicesDocumentResetSingleAction = (invoiceId: string) =>
    action<ActionTypes.DOCUMENT_RESET_SINGLE_ACTION, IInvoicesDocumentResetSinglePayload>(
        ActionTypes.DOCUMENT_RESET_SINGLE_ACTION,
        {
            invoiceId,
        }
    )

export const invoicesResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)
