import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { IApplicationRootState } from '../store'
import { makeSelectAuthMe } from '../store/auth/selectors'
import { IMe } from '../services/api/service/me/types'
import SalesmanDashboard from '../containers/SalesmanDashboard/SalesmanDashboard'
import { isSalesmanResource } from '../store/salesmens/utils'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
})

function SalesmanDashboardPage(): JSX.Element {
    const { formatMessage } = useIntl()
    const { me } = useSelector<
        IApplicationRootState,
        {
            me?: IMe
        }
    >(stateSelector)

    if (!isSalesmanResource(me)) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'salesman.dashboard' })}</title>
            </Helmet>
            <div className={'salesman-dashboard-page'} id={'salesman-dashboard-page'}>
                <SalesmanDashboard />
            </div>
        </>
    )
}

export default SalesmanDashboardPage
