/**
 *
 * CartBanner
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useCartInfo } from '../../containers/Carts/useCartInfo'
import { ICart, ICartMode, ICartValidationMode } from '../../services/api/service/carts/types'
import { IShippingLocation } from '../../services/api/service/classification/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import { cartHasErrors } from '../../store/carts/utils'
import ShippingLocation from '../Classification/ShippingLocation'
import FlatIcon from '../Icon/FlatIcon'
import Price from '../Price/Price'
import { StrictCartMode } from '../../store/carts/types'
import { customerShowPrices } from '../../store/customers/utils'
import { IMe } from '../../services/api/service/me/types'

export interface IProps {
    cart: ICart
    cartMode: StrictCartMode
    cartValidationMode: ICartValidationMode
    customer: ICustomer
    me: IMe
    store?: ICustomer
    minimumAmountMode: ICustomerMinimumAmountMode
    shippingLocation?: IShippingLocation | null
    onCartBannerClick?: (cart: ICart) => void
}

function CartBanner({
    me,
    cart,
    customer,
    store,
    minimumAmountMode,
    shippingLocation,
    onCartBannerClick,
    cartMode,
    cartValidationMode,
}: IProps): JSX.Element {
    const [cartUpdated, setCartUpdated] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const cartBannerRef = useRef<HTMLDivElement | null>(null)
    const {
        active: minimumAmountActive,
        reached: remainingAmountReached,
        value: minimumAmountFormatted,
        remainingValue: remainingAmountFormatted,
        message: remainingAmountMessage,
    } = useCartInfo({
        cart,
        customer,
        store,
        minimumAmountMode,
        cartMode,
        cartValidationMode,
        abbreviateMessage: false,
    })
    const showRemainingAmount = minimumAmountActive && customer && cartMode === ICartMode.Default

    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    const handleOnClick = useCallback(() => {
        if (onCartBannerClick) {
            onCartBannerClick(cart)
        }
    }, [cart, onCartBannerClick])

    const handleMouseEnter = useCallback((): void => {
        if ((!cartHasErrors(cart) && remainingAmountReached) || showRemainingAmount) {
            setShowTooltip(false)
            return
        }
        setShowTooltip(true)
    }, [setShowTooltip, cart, remainingAmountReached, showRemainingAmount])
    const handleMouseLeave = useCallback((): void => {
        setShowTooltip(false)
    }, [setShowTooltip])

    useEffect(() => {
        setCartUpdated(true)
        const timeOutId = setTimeout(() => {
            setCartUpdated(false)
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [cart])

    return (
        <div
            ref={cartBannerRef}
            className={classNames('cart-banner', {
                'remaining-amount-reached': remainingAmountReached,
                'cart-updated': cartUpdated,
            })}
            data-id={cart['@id']}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleOnClick}
        >
            {shippingLocation && (
                <ShippingLocation
                    showLabel={false}
                    shippingLocation={shippingLocation}
                    showIcon
                    iconId={'delivery-truck-2'}
                />
            )}
            {showPrices && (
                <>
                    <div className={'cart-banner-resume'}>
                        {showRemainingAmount && minimumAmountFormatted && (
                            <div className={'cart-banner-minimum-amount'}>
                                <span className={'cart-banner-minimum-amount-icon'}>
                                    <FlatIcon icon={'check'} className={'checked'} />
                                    <FlatIcon icon={'box'} />
                                </span>
                                <span className={'lbl'}>
                                    <FormattedMessage
                                        id={'cart.minimum_amount'}
                                        values={{ minimum_amount: minimumAmountFormatted }}
                                    >
                                        {(txt) => <span className={'full'}>{txt}</span>}
                                    </FormattedMessage>
                                    <FormattedMessage
                                        id={'cart.minimum_amount_abbr'}
                                        values={{ minimum_amount: minimumAmountFormatted }}
                                    >
                                        {(txt) => <span className={'abbr'}>{txt}</span>}
                                    </FormattedMessage>
                                </span>
                            </div>
                        )}
                        <div className={'cart-banner-total'}>
                            <FlatIcon icon={'shopping-cart'} />
                            <Price price={cart.total} className={'cart-total-price value'} />
                            {showRemainingAmount && remainingAmountFormatted && (
                                <span className={'cart-remaining-amount'}>
                                    <span className={'lbl'}>
                                        <FormattedMessage
                                            id={'cart.remaining_amount'}
                                            values={{ remaining_amount: remainingAmountFormatted }}
                                        />
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                    <Overlay target={cartBannerRef.current} show={showTooltip}>
                        {(props) => (
                            <Popover id="cart-banner-alert-tooltip" {...props}>
                                <Popover.Content>
                                    {remainingAmountMessage &&
                                        remainingAmountMessage.split('\n').map((val) => (
                                            <p key={val} className={'mb-0'}>
                                                {val}
                                            </p>
                                        ))}
                                </Popover.Content>
                            </Popover>
                        )}
                    </Overlay>
                </>
            )}
        </div>
    )
}

CartBanner.defaultProps = {} as Partial<IProps>

export default memo(CartBanner)
